import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfigDB from "../../config";
import { getwarHouseEntityList } from "../../services/PartsInventory";
import { toast } from "react-toastify";
import { Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  getPartListAssociateLocation,
  getPartUpdateLocation,
} from "../../services/PartLocationAssignServices";
import { useTranslation } from "react-i18next";

const AssociateLocationPart = () => {
  const navigate = useNavigate();
  const tableLengthList = ConfigDB.data.dataTableLength;
  const { t } = useTranslation();

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  const [modifyQrSearchModal, setmodifyQrSearchModal] = useState(false);
  const [selectedEntities, setSelectedEntities] = useState({
    value: "",
    name: "",
  });

  const [entityList, setEntityList] = useState([]);
  const [selectBarcodeValue, setSelectBarcodeValue] = useState({
    location_id: "",
    internal_id: "",
  });

  const [partDetails, setPartDetails] = useState([]);
  const [modifyqtyModal, setmodifyqtyModal] = useState(false);
  const [selectedParts, setSelectedParts] = useState([]);
  const [partqtyDetails, setPartqtyDetails] = useState("");
  const handleCheckboxChange = (partId, id) => {
    setSelectedParts((prev) => {
      const isAlreadySelected = prev.some((item) => item.id === id);

      if (isAlreadySelected) {
        return prev.filter((item) => item.id !== id);
      } else {
        return [...prev, { part_id: partId, id }];
      }
    });
  };

  const handleQtyOpen = (e, item) => {
    setPartqtyDetails(item);
    setmodifyqtyModal(true);
  };

  const handleQtyClose = () => {
    setmodifyqtyModal(false);
  };

  const handleQrSerachClose = () => {
    setmodifyQrSearchModal(false);
    setSelectBarcodeValue({
      location_id: "",
      internal_id: "",
    });
  };
  const handleSelectChange = (event) => {
    const selectedIndex = event.target.selectedIndex;
    const selectedValue = event.target.value;
    const selectedName = event.target.options[selectedIndex].text;

    setSelectedEntities({ value: selectedValue, name: selectedName });

    setParams((prevParams) => ({
      ...prevParams,
      search_warehouse: parseInt(selectedValue) || "",
    }));
  };

  useEffect(() => {
    let data = { ...params };
    getwarHouseEntityList(data)
      .then((response) => {
        setEntityList(response.data.data);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, [params]);

  const renderOptions = (nodes, level = 0) => {
    return nodes?.map((node) => {
      if (node.children && node.children.length > 0) {
        return (
          <>
            <optgroup
              label={"\u00A0\u00A0".repeat(level) + node.warehouse_name}
              key={node.id}
            />
            {renderOptions(node.children, level + 1)}
          </>
        );
      } else {
        return (
          <option value={node.id} key={node.id}>
            {"\u00A0\u00A0".repeat(level) + node.warehouse_name}
          </option>
        );
      }
    });
  };

  useEffect(() => {
    let data = { ...params };
    getPartListAssociateLocation(data)
      .then((response) => {
        setPartDetails(response.data.data);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, [params]);

  const handleUpdateSubmit = async () => {
    if (!selectedEntities?.value && !selectBarcodeValue?.location_id) {
      toast.error("Location ID is required.");
      return;
    }

    if (!selectedParts || selectedParts.length === 0) {
      toast.error("At least one part must be selected.");
      return;
    }

    const partListData = selectedParts?.map((item) => ({
      part_id: item.part_id,
      qty_id: item.id,
    }));

    let data = {
      location_id: selectedEntities?.value
        ? selectedEntities?.value
        : selectBarcodeValue?.location_id,
      part_list: partListData,
    };

    getPartUpdateLocation(data)
      .then((response) => {
        toast.success(response.data.message);

        setSelectBarcodeValue({
          location_id: "",
          internal_id: "",
        });
        setmodifyQrSearchModal(false);
        navigate("/part-location-asssign");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6"></div>
              <div className="col-sm-6"></div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid container-custom-height-new ">
            <div className="row">
              <div className="col-md-12">
                <div className="card fuelCard">
                  <div className="">
                    <div className="">
                      <div className="card-header">
                        <div className="card-title m-0 float-left d-flex">
                          <button
                            type="button"
                            className="btn btn-block btn-primary"
                            onClick={() => navigate("/part-location-asssign")}
                            style={{ width: "100px", marginTop: "10px" }}
                          >
                            {t("back")} <i class="bi bi-arrow-left-short"></i>
                          </button>
                        </div>
                        <div className="card-title m-0 float-right d-flex">
                          <button
                            type="button"
                            className="btn  btn-icon-text mr-2"
                            onClick={() => setmodifyQrSearchModal(true)}
                          >
                            <i class="bi bi-geo-alt-fill"></i>
                            <span className="ms-2">
                              {t("associate_location_part")}{" "}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {partDetails?.length > 0 && (
              <div className="card-body">
                <div className="vehicleThBg table-responsive table-responsive-vehicle">
                  <table className="table table-bordered rounded-table table table-striped table-hover">
                    <thead>
                      <tr>
                        {(selectedEntities?.name ||
                          selectBarcodeValue?.location_id) && (
                          <th
                            className="col-form-label ml-2 Label-my form-label"
                            style={{ width: "10%" }}
                          >
                            {t("warehouse_name")}{" "}
                          </th>
                        )}

                        <th
                          className="col-form-label ml-2 Label-my form-label"
                          style={{ width: "10%" }}
                        >
                          {t("part_name")}
                        </th>
                        <th
                          className="col-form-label ml-2 Label-my form-label"
                          style={{ width: "10%" }}
                        >
                          {t("internal_id")}
                        </th>
                        <th
                          className="col-form-label ml-2 Label-my form-label"
                          style={{ width: "10%" }}
                        >
                          {t("asssign_quantity")}{" "}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {partDetails.map((part, index) => (
                        <tr key={index}>
                          {selectedEntities?.name ||
                            (selectBarcodeValue?.location_id && (
                              <td>
                                {selectedEntities?.name
                                  ? selectedEntities?.name
                                  : selectBarcodeValue?.location_id}
                              </td>
                            ))}
                          <td>{part?.part_name}</td>
                          <td>{part?.parts_internal_id}</td>
                          <td
                            style={{ cursor: "pointer" }}
                            onClick={(e) => handleQtyOpen(e, part)}
                          >
                            <i class="bi bi-clipboard-check"></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <button
                    type="button"
                    className="btn btn-block btn-primary"
                    onClick={handleUpdateSubmit}
                    style={{ width: "100px", marginTop: "10px" }}
                  >
                    {t("submit")} <i className="bi bi-x-lg"></i>
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>

      <Modal isOpen={modifyQrSearchModal}>
        <ModalHeader>{t("serach_warehouse")}</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <Label>Warehouse</Label>

              <select
                className="form-select"
                value={selectedEntities.value || ""}
                onChange={handleSelectChange}
              >
                <option value="" disabled>
                  Select
                </option>
                {renderOptions(entityList)}{" "}
              </select>
            </div>
            <div className="col-md-12 mt-3">
              <Label>Warehouse Barcode</Label>
              <input
                className="form-control mt-2 input-search"
                type="text"
                value={selectBarcodeValue.location_id}
                onChange={(e) => {
                  const value = e.target.value;
                  setSelectBarcodeValue((prev) => ({
                    ...prev,
                    location_id: value,
                  }));
                }}
                maxLength={50}
                placeholder="Search Warehouse Barcode"
                onClick={(e) => e.stopPropagation()}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-block btn-primary"
            onClick={handleQrSerachClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i className="bi bi-x-lg"></i>
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyqtyModal}>
        <ModalHeader>{`Quantity`}</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <div
                className="row"
                style={{
                  overflowX: "auto",
                  maxHeight: "250px",
                }}
              >
                {partqtyDetails?.part_qty_list?.length > 0 ? (
                  partqtyDetails?.part_qty_list?.map((part) => {
                    return (
                      <>
                        <div key={part.id} className="col-md-6">
                          <div className="col-custom-check">
                            <label
                              className="form-check-label"
                              style={{
                                fontWeight: "bold",
                                fontSize: "16px",
                                marginLeft: "20px",
                              }}
                            >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={selectedParts.some(
                                  (item) => item.id === part.id
                                )}
                                onChange={() =>
                                  handleCheckboxChange(part.parts_id, part.id)
                                }
                              />

                              <span
                                style={{
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  marginLeft: "10px",
                                }}
                              >{`Quantity: ${part.qty}`}</span>

                              <span
                                style={{
                                  fontWeight: "500",
                                  fontSize: "14px",
                                  marginLeft: "10px",
                                }}
                              >{`Location: ${
                                part?.location_name
                                  ? part?.location_name
                                  : selectedEntities?.name
                              }`}</span>
                            </label>
                          </div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <p>No parts available.</p>
                )}
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-block btn-primary"
            onClick={handleQtyClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Back <i className="bi bi-x-lg"></i>
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AssociateLocationPart;
