import axiosInstance from "./AxiosInstance";

export function createVehicleOwner(data) {
  return axiosInstance.post(`/vehicle-process/add-vehicle-owner`, data);
}
export function createVehicle(data) {
  return axiosInstance.post(`/vehicle-process/add-vehicle`, data);
}

export function createVehicleImage(data) {
  return axiosInstance.post(`/vehicle-process/add-vehicle-images`, data);
}

export function createVehicleDocument(data) {
  return axiosInstance.post(`/vehicle-process/add-vehicle-documents`, data);
}

export function createVehicleExtraInfo(data) {
  return axiosInstance.post(`/vehicle-process/add-vehicle-extra-info`, data);
}

export function createVehicleAmortization(data) {
  return axiosInstance.post(`/vehicle-process/add-vehicle-amortization`, data);
}

export function deleteVehicleImage(data) {
  return axiosInstance.post(`vehicle-process/delete-vehicle-images`, data);
}

export function getVehicleCategoryAllList(data) {
  return axiosInstance.get(`/get-vehicle-category`, data);
}
export function getVehicleModelAllList(data) {
  return axiosInstance.post(`/get-vehicle-model`, data);
}

export function getAddForVehicleModelAllList(data) {
  return axiosInstance.post(`/part-vehicle-search-list`, data);
}

export function getVehicleFuelAllList(data) {
  return axiosInstance.get(`/get-vehicle-fuel-type`, data);
}

export function getVehicleAmortizationList(data) {
  return axiosInstance.post(
    `/vehicle-process/get-vehicle-amortization-list`,
    data
  );
}

export function getVehicleProcessDetails(data) {
  return axiosInstance.post(`/vehicle-process/get-vehicle-details`, data);
}

export function getVehicleAllProcessList(data) {
  return axiosInstance.post(`/vehicle-process/get-vehicle-list`, data);
}
export function printVehicleQr(data) {
  return axiosInstance.post(`/print-vehicle-label`, data);
}
export function getCountryAllist(data) {
  return axiosInstance.post(`/country/list`, data);
}

export function getVehicleVinAllist(data) {
  return axiosInstance.post(`/get-vehicle/vin-number`, data);
}

export function getVehiclePlateAllist(data) {
  return axiosInstance.post(`/get-vehicle/plate-number`, data);
}

export function getVehiclePartList(data) {
  return axiosInstance.post(`/part-list-by-vehicle`, data);
}

export function getVehicleDetailsList(data) {
  return axiosInstance.post(`/vehicle-data`, data);
}

export function getVehicleSentToWastage(data) {
  return axiosInstance.post(`/sent-to-wastage`, data);
}

export function getVehicleTecEtaiDetails(data) {
  return axiosInstance.post(`/vehicle-process/tec-etai-details`, data);
}

export function updateVehiclePartImageSort(data) {
  return axiosInstance.post(`/part-images/update-part-images-sort`, data);
}

export function updateVehicleImageProcessSort(data) {
  return axiosInstance.post(
    `/vehicle-process/update-vehicle-images-sort`,
    data
  );
}

export function VehicleETAISerachMake(data) {
  return axiosInstance.post(`/vehicle-make-etai/search-list`, data);
}

export function VehicleETAISerachModel(data) {
  return axiosInstance.post(`/vehicle-model-etai/search-list`, data);
}

export function VehicleETAISerachVariants(data) {
  return axiosInstance.post(`/vehicle-variants-etai/search-list`, data);
}

export function getvehicleYearList(data) {
  return axiosInstance.post(`vehicle-year/list`, data);
}

export function getvehicleMakeAllList(data) {
  return axiosInstance.post(`vehicle-make/search-list`, data);
}

export function getpublicPartList(data) {
  return axiosInstance.post(`/parts-details`, data);
}

export function RemoveVehicleBgImage(data) {
  return axiosInstance.post(
    `https://imagemagic.dailybills.in/remove-bg/`,
    data
  );
}

export function createVehicleVideo(data) {
  return axiosInstance.post(`/vehicle-process/add-vehicle-video`, data);
}

export function getVehicleVideoSort(data) {
  return axiosInstance.post(`/vehicle-process/update-vehicle-video-sort`, data);
}

export function deleteVehiclevideo(data) {
  return axiosInstance.post(`/vehicle-process/delete-vehicle-video`, data);
}
