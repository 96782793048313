import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getEcommerceSalesDetailsList } from "../../services/SalesServices";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useTranslation } from "react-i18next";
import moment from "moment";

const SalesDetails = () => {
  const { uuid } = useParams();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [salesDetails, setSalesDetails] = useState([]);

  useEffect(() => {
    if (uuid) {
      let data = {
        id: uuid,
      };

      getEcommerceSalesDetailsList(data)
        .then((response) => {
          setSalesDetails(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [uuid]);

  return (
    <div>
      <div>
        <div>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6"></div>
                <div className="col-sm-6"></div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="container-fluid">
              <div className="card fuelCard">
                <div className="cardHeader card-header card-header-custom">
                  <div className="row mb-2">
                    <div className="col-sm-6">
                      <p>Sales Details</p>
                    </div>
                    <div className="col-sm-6">
                      <div className="d-flex  justify-content-end">
                        <button
                          type="button"
                          className="next-button btn btn-secondary"
                          onClick={() => navigate("/sales")}
                        >
                          <i className="bi bi-arrow-left"></i> {t("back")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {salesDetails?.map((item) => {
            return (
              <>
                <section className="content">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card fuelCard">
                          <div className="cardHeader card-header card-header-custom">
                            <p>Sales Details</p>
                          </div>
                          <div className="card-body card-body-custom">
                            <div className="row justify-content-start">
                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>Invoice Number:</b>{" "}
                                  <address>
                                    {item.invoice_number || "-"}
                                  </address>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b> Order Status Name:</b>{" "}
                                  <address>
                                    {item.order_status_name || "-"}
                                  </address>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b> Created Date:</b>{" "}
                                  <address>
                                    {item?.created_at
                                      ? moment(item?.created_at).format(
                                          "DD/MM/YYYY"
                                        )
                                      : "-"}
                                  </address>
                                </div>
                              </div>

                              {item?.proudct_list && (
                                <>
                                  <div className="card-custom-heading">
                                    <p>Product List</p>
                                  </div>
                                  <div className="card-body card-body-custom">
                                    <div className="row justify-content-start">
                                      <>
                                        <div className="card-body card-body-custom">
                                          <div className="vehicleThBg table-responsive">
                                            <table className="table table-bordered rounded-table table table-striped table-hover">
                                              <thead>
                                                <tr>
                                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                                    Name{" "}
                                                  </th>
                                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                                    Quantity{" "}
                                                  </th>
                                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                                    Price without vat
                                                  </th>

                                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                                    Tax vat
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {item?.proudct_list?.map(
                                                  (items) => {
                                                    return (
                                                      <>
                                                        <tr>
                                                          <td>
                                                            {
                                                              items?.product_name
                                                            }
                                                          </td>
                                                          <td>
                                                            {items?.quantity}
                                                          </td>
                                                          <td>
                                                            {
                                                              items?.price_without_vat
                                                            }
                                                          </td>
                                                          <td>
                                                            {items?.tax_vat}
                                                          </td>
                                                        </tr>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </>
                                    </div>
                                  </div>
                                </>
                              )}

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b> Number products:</b>{" "}
                                  <address>{item.num_products || "0"}</address>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b> Tax Vat Other Parcel:</b>{" "}
                                  <address>
                                    € {item?.tax_vat_other_parcel || "0"}
                                  </address>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b> Tax Vat Packaging:</b>{" "}
                                  <address>
                                    € {item?.tax_vat_packaging || "0"}
                                  </address>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b> Tax Vat Product:</b>{" "}
                                  <address>
                                    € {item?.tax_vat_products || ""}
                                  </address>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>Tax Vat Shipping:</b>{" "}
                                  <address>
                                    € {item?.tax_vat_shipping || "-"}
                                  </address>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b> Total value other parcel:</b>{" "}
                                  <address>
                                    € {item.total_value_other_parcel || "-"}
                                  </address>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b> Total value packaging:</b>{" "}
                                  <address>
                                    € {item.total_value_packaging || "-"}
                                  </address>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b> Total value products:</b>{" "}
                                  <address>
                                    € {item.total_value_products || "-"}
                                  </address>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b> total value shipping:</b>{" "}
                                  <address>
                                    € {item.total_value_shipping || "-"}
                                  </address>
                                </div>
                              </div>

                              {item?.client_list && (
                                <>
                                  <div className="card-custom-heading">
                                    <p>Client List</p>
                                  </div>
                                  <div className="card-body card-body-custom">
                                    <div className="row justify-content-start">
                                      <>
                                        <div className="card-body card-body-custom">
                                          <div className="vehicleThBg table-responsive">
                                            <table className="table table-bordered rounded-table table table-striped table-hover">
                                              <thead>
                                                <tr>
                                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                                    Name{" "}
                                                  </th>
                                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                                    Shipping Address
                                                  </th>

                                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                                    Country Code{" "}
                                                  </th>

                                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                                    Postal Code{" "}
                                                  </th>

                                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                                    City{" "}
                                                  </th>

                                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                                    VAT Number{" "}
                                                  </th>

                                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                                    Country code VAT number{" "}
                                                  </th>

                                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                                    Phone{" "}
                                                  </th>

                                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                                    Email{" "}
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {item?.client_list?.map(
                                                  (items) => {
                                                    return (
                                                      <>
                                                        <tr>
                                                          <td>{items?.name}</td>
                                                          <td>
                                                            {
                                                              items?.shipping_address
                                                            }
                                                          </td>
                                                          <td>
                                                            {
                                                              items?.country_code
                                                            }
                                                          </td>
                                                          <td>
                                                            {items?.postal_code}
                                                          </td>
                                                          <td>{items?.city}</td>
                                                          <td>
                                                            {items?.vat_number}
                                                          </td>
                                                          <td>
                                                            {
                                                              items?.country_code_vat_number
                                                            }
                                                          </td>
                                                          <td>
                                                            {items?.phone}
                                                          </td>
                                                          <td>
                                                            {items?.email}
                                                          </td>
                                                        </tr>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </>
                                    </div>
                                  </div>
                                </>
                              )}

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>Shipping Company :</b>{" "}
                                  <address>
                                    {item?.shipping_company || "-"}
                                  </address>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>Shipping Label</b>{" "}
                                  <address>
                                    <a
                                      href={item.shipping_label}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <i className="bi bi-file-earmark-pdf"></i>{" "}
                                    </a>
                                  </address>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>Shipping Tracking Code:</b>{" "}
                                  <address>
                                    {item?.shipping_tracking_code || "-"}
                                  </address>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b> Notes Sale:</b>{" "}
                                  <address>{item.notes_sale || "-"}</address>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b> Maximum sale alert date:</b>{" "}
                                  <address>
                                    {item?.maximum_sale_alert_date
                                      ? moment(
                                          item?.maximum_sale_alert_date
                                        ).format("DD/MM/YYYY")
                                      : "-"}
                                  </address>
                                </div>
                              </div>
                            </div>
                          </div>

                          {item?.bill_list && (
                            <>
                              <div className="card-custom-heading">
                                <p>Bill List</p>
                              </div>
                              <div className="card-body card-body-custom">
                                <div className="row justify-content-start">
                                  <>
                                    <div className="card-body card-body-custom">
                                      <div className="vehicleThBg table-responsive">
                                        <table className="table table-bordered rounded-table table table-striped table-hover">
                                          <thead>
                                            <tr>
                                              <th className="col-form-label ml-2 Label-my form-label form-label">
                                                Refund Status{" "}
                                              </th>
                                              <th className="col-form-label ml-2 Label-my form-label form-label">
                                                Provider Type Refund{" "}
                                              </th>
                                              <th className="col-form-label ml-2 Label-my form-label form-label">
                                                Provider Refund Value{" "}
                                              </th>
                                              <th className="col-form-label ml-2 Label-my form-label form-label">
                                                Seller Type Refund{" "}
                                              </th>
                                              <th className="col-form-label ml-2 Label-my form-label form-label">
                                                Seller Refund Value{" "}
                                              </th>

                                              <th className="col-form-label ml-2 Label-my form-label form-label">
                                                Refund Accept Full{" "}
                                              </th>
                                              <th className="col-form-label ml-2 Label-my form-label form-label">
                                                Refund Accept Partial
                                              </th>

                                              <th className="col-form-label ml-2 Label-my form-label form-label">
                                                Refund reason{" "}
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {item?.bill_list?.map((items) => {
                                              return (
                                                <>
                                                  <tr>
                                                    <td>
                                                      {items?.refund_reason ||
                                                        "-"}
                                                    </td>{" "}
                                                    <td>
                                                      {items?.refund_reason ||
                                                        "-"}
                                                    </td>{" "}
                                                    <td>
                                                      {items?.refund_reason ||
                                                        "-"}
                                                    </td>{" "}
                                                    <td>
                                                      {items?.refund_reason ||
                                                        "-"}
                                                    </td>{" "}
                                                    <td>
                                                      {items?.refund_reason ||
                                                        "-"}
                                                    </td>{" "}
                                                    <td>
                                                      {items?.refund_accept_full ===
                                                      true
                                                        ? "Yes"
                                                        : "No"}
                                                    </td>
                                                    <td>
                                                      {items?.refund_accept_partial ===
                                                      true
                                                        ? "Yes"
                                                        : "No"}
                                                    </td>
                                                    <td>
                                                      {items?.refund_reason ||
                                                        "-"}
                                                    </td>
                                                  </tr>
                                                </>
                                              );
                                            })}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SalesDetails;
