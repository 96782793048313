import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import * as XLSX from "xlsx";
import { useLocation, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";

import { useTranslation } from "react-i18next";
import {
  getCompanyRefundList,
  updateCompanyRefundStatus,
} from "../../services/RefundServices";
import Select from "react-select";
import { getCommonSearchList } from "../../services/settingsServices";

const Refund = () => {
  const location = useLocation();
  const RolePermission = Utils.getRecordForCurrentPath(location);
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
    refund_status: 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);

  const [refundList, setRefundList] = useState([]);

  const [statusModal, setStatusModal] = useState(false);
  const [statusPendingModal, setStatusPendingModal] = useState(false);

  const [statusRejectModal, setStatusRejectModal] = useState(false);

  const [delModal, setDelModal] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [delpart, setDelPart] = useState("");
  const [modifyModal, setModifyModal] = useState(false);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [salesViewData, setSalesViewData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [orderStatusNameList, setOrderStatusNameList] = useState([]);
  const [refundData, setRefundData] = useState({
    refund_status: "",
    refund_request_value: "",
    type_refund: "",
    reason_refund: "",
  });

  let errorsObjRefund = {
    refund_request_value: "",
    type_refund: "",
    reason_refund: "",
  };
  const [errorsRefund, setErrorsRefund] = useState(errorsObjRefund);
  const [modifyRefundModal, setModifyRefundModal] = useState(false);
  const [refundTypeList, setRefundTypeList] = useState([]);
  const [modifyCancelModal, setModifyCancelModal] = useState(false);

  const handleRefundClose = () => {
    setModifyRefundModal(false);
  };

  const handleCancelClose = () => {
    setModifyCancelModal(false);
  };

  const handleSelectChangeRefund = (selectedOption) => {
    setRefundData({
      ...refundData,
      type_refund: selectedOption ? selectedOption.value : "",
    });
  };

  const handleStatusClose = () => {
    setStatusModal(false);
    setDelModal(true);
  };

  const handleStatusPendingClose = () => {
    setStatusPendingModal(false);
    setDelModal(false);
  };

  const handleStatusOpen = () => {
    setStatusModal(true);
    setDelModal(false);
  };

  const handleStatusRejectClose = () => {
    setStatusRejectModal(false);
    setDelModal(true);
  };

  const handleStatusRejectOpen = () => {
    setStatusRejectModal(true);
    setDelModal(false);
  };

  const handleCloseModal = () => {
    setModifyModal(false);
  };

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "refund_status",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setOrderStatusNameList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "refund_type",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setRefundTypeList(list);
      })
      .catch((err) => {});
  }, []);

  const handleSelectChange = (selectedOption) => {
    setRefundData({
      ...refundData,
      refund_status: selectedOption ? selectedOption.value : "",
    });

    if (selectedOption.value === 1) {
      setStatusPendingModal(true);
      setDelModal(false);
    }

    if (selectedOption?.value === 2) {
      setModifyRefundModal(true);
      setDelModal(false);
    }

    if (selectedOption?.value === 3) {
      setModifyCancelModal(true);
      setDelModal(false);
    }
  };

  useEffect(() => {
    if (delpart) {
      setRefundData((prevrefundData) => ({
        ...prevrefundData,
        refund_status: delpart?.refund_status,
      }));
    }
  }, [delpart]);

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    } else if (type === "refund_status") {
      paramsObj.refund_status = parseInt(e.target.value);
    }
    setParams(paramsObj);
  };

  const fecthRefundList = () => {
    let data = { ...params };
    getCompanyRefundList(data)
      .then((response) => {
        setRefundList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };

      fecthRefundList(data);
    },
    [params]
  );

  const onDeleteModalClose = () => {
    setDelModal(false);
  };

  const onViewSales = (e, item) => {
    setModifyModal(true);
    setSalesViewData(item);
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelPart(item);
    setDelModal(true);
  };

  const onDestroyPartType = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { id: delpart.id, refund_status: 2 };
    updateCompanyRefundStatus(data)
      .then((response) => {
        let data = { ...params };

        fecthRefundList(data);
        toast.success(response.data.message);
        setDelPart("");
        setStatusModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const onRejectRefundType = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { id: delpart.id, refund_status: 3 };
    updateCompanyRefundStatus(data)
      .then((response) => {
        let data = { ...params };

        fecthRefundList(data);
        toast.success(response.data.message);
        setDelPart("");
        setStatusRejectModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleAddStatusPending = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { id: delpart.id_sale, refund_status: 1 };
    updateCompanyRefundStatus(data)
      .then((response) => {
        let data = { ...params };

        fecthRefundList(data);
        toast.success(response.data.message);

        setStatusModal(false);
        setStatusPendingModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleAddRefundStatusSubmit = (e) => {
    e.preventDefault();

    let error = false;
    let errorObj = { ...errorsObjRefund };

    if (refundData?.type_refund === 2) {
      if (!refundData.refund_request_value) {
        errorObj.refund_request_value = `Refund value is required.`;
        error = true;
      }
    }

    if (!refundData.type_refund) {
      errorObj.type_refund = `Refund type is required.`;
      error = true;
    }

    if (!refundData.reason_refund) {
      errorObj.reason_refund = `Reason is required.`;
      error = true;
    }

    setErrorsRefund(errorObj);
    Utils.focusError();

    if (error) return;
    let data = {
      id: delpart.id_sale,
      refund_status: 2,
      type_refund: refundData.type_refund,
      reason_refund: refundData.reason_refund,
      refund_request_value: refundData.refund_request_value || 0,
    };

    updateCompanyRefundStatus(data)
      .then((response) => {
        let data = { ...params };

        fecthRefundList(data);
        toast.success(response.data.message);

        setStatusModal(false);
        setModifyRefundModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleAddRefundCancelStatusSubmit = (e) => {
    e.preventDefault();

    let error = false;
    let errorObj = { ...errorsObjRefund };

    if (!refundData.reason_refund) {
      errorObj.reason_refund = `Reason is required.`;
      error = true;
    }

    setErrorsRefund(errorObj);
    Utils.focusError();

    if (error) return;
    let data = {
      id: delpart.id_sale,
      refund_status: 3,
      reason_refund: refundData.reason_refund,
    };

    updateCompanyRefundStatus(data)
      .then((response) => {
        let data = { ...params };

        fecthRefundList(data);
        toast.success(response.data.message);

        setStatusModal(false);
        setModifyCancelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      {isLoading ? (
        // <div className="text-center pageLoader">
        //   <div className="spinner-border" role="status">
        //     <span className="visually-hidden">Loading...</span>
        //   </div>
        // </div>
        <div className="skeleton-loader p-3 d-none">
          <div className="card">
            <div className="card-header">
              <table className="table rounded-table table-striped table-hover">
                <thead>
                  <tr>
                    <th
                      style={{ width: 10, borderTop: "0", borderBottom: "0" }}
                    >
                      <div className="skeleton"></div>
                    </th>
                    <th style={{ borderTop: "0", borderBottom: "0" }}>
                      <div className="skeleton"></div>
                    </th>
                    <th style={{ borderTop: "0", borderBottom: "0" }}></th>
                    <th
                      class="d-flex"
                      style={{ borderTop: "0", borderBottom: "0" }}
                    >
                      <div class="skeleton me-1"></div>
                      <div class="skeleton me-1"></div>
                      <div class="skeleton"></div>
                    </th>
                    <th
                      style={{ width: 10, borderTop: "0", borderBottom: "0" }}
                    >
                      <div className="skeleton"></div>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="card-body">
              <div className="vehicleThBg table-responsive">
                <table className="table table-bordered rounded-table table table-striped table-hover">
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: "#c0c0c0" }}>
                        <div className="skeleton"></div>
                      </th>
                      <th style={{ backgroundColor: "#c0c0c0" }}>
                        <div className="skeleton"></div>
                      </th>
                      <th style={{ backgroundColor: "#c0c0c0" }}>
                        <div className="skeleton"></div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {refundList?.map((_, index) => (
                      <tr key={index}>
                        <td>
                          <div className="skeleton"></div>
                        </td>
                        <td>
                          <div className="skeleton"></div>
                        </td>
                        <td>
                          <div className="skeleton"></div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer">
              <div className="skeleton col-lg-3 float-right"></div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  {/* <h1 className="page-title">Part Type</h1> */}
                </div>
                <div className="col-sm-6"></div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>
          <section className="content">
            <div className="container-fluid container-custom-height-new ">
              <div className="row">
                <div className="col-md-12">
                  <div className="card fuelCard">
                    <div className="card-header">
                      <div
                        className="card-title m-0 float-left"
                        style={{ width: "50%" }}
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <input
                              type="text"
                              className="form-control"
                              value={params.search}
                              onChange={(e) => handleParams(e, "search")}
                              placeholder="Search"
                            ></input>
                          </div>
                          <div className="col-md-6">
                            <select
                              className="form-select"
                              type="select"
                              value={params.refund_status}
                              onChange={(e) => {
                                handleParams(e, "refund_status");
                              }}
                            >
                              <option>All</option>
                              {orderStatusNameList?.map((item) => (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="vehicleThBg table-responsive table-responsive-vehicle">
                        <table className="table table-bordered rounded-table table-striped table-hover">
                          <thead>
                            <tr>
                              <th style={{ width: "1%" }}>#</th>
                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: "10%" }}
                              >
                                Invoice Number{" "}
                              </th>

                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: "10%" }}
                              >
                                Price{" "}
                              </th>

                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: "10%" }}
                              >
                                Refund Type{" "}
                              </th>

                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: "10%" }}
                              >
                                Reason Refund{" "}
                              </th>

                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: "10%" }}
                              >
                                Refund Status Name{" "}
                              </th>

                              {/* {refundList?.some(
                                (item) => item.refund_status === 2
                              ) && ( */}
                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ textAlign: "center", width: "0%" }}
                              >
                                {t("action")}
                              </th>
                              {/* )} */}
                            </tr>
                          </thead>
                          <tbody>
                            {refundList?.map((item, index) => (
                              <tr key={item.id}>
                                {" "}
                                <td>{++index}</td>
                                <td
                                  onClick={() =>
                                    navigate(`/sales-details/${item.id_sale}`, {
                                      state: item,
                                    })
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  {item?.invoice_number}
                                </td>
                                <td>{item?.refund_request_value} €</td>
                                <td>{item?.type_refund}</td>
                                <td>{item?.reason_refund}</td>
                                <td
                                  style={{
                                    color:
                                      item?.refund_status_name === "Pending"
                                        ? "orange"
                                        : item?.refund_status_name ===
                                          "Approved"
                                        ? "green"
                                        : item?.refund_status_name ===
                                          "Rejected"
                                        ? "red"
                                        : "black",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item?.refund_status_name}
                                </td>
                                {/* {item.refund_status === 2 && ( */}
                                <td>
                                  <div className="d-flex">
                                    <Dropdown
                                      className="iconDropdown"
                                      onToggle={(isOpen) => {
                                        if (isOpen) {
                                          const tableResponsive =
                                            document.querySelector(
                                              ".table-responsive"
                                            );
                                          if (tableResponsive) {
                                            tableResponsive.style.overflowX =
                                              "inherit !important";
                                            tableResponsive.style.overflowY =
                                              "inherit !important";
                                          }
                                        }
                                      }}
                                    >
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                        className="iconToggle"
                                      >
                                        <i className="bi bi-three-dots-vertical"></i>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          href="#/action-2"
                                          className="d-flex"
                                          onClick={(e) =>
                                            onDeleteModalOpen(e, item)
                                          }
                                        >
                                          <a className="" href="#"></a>

                                          <div className="edit-btn">
                                            <i class="bi bi-arrow-repeat"></i>
                                          </div>
                                          <span>Status Change</span>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </td>
                                {/* )} */}
                              </tr>
                            ))}
                            {refundList && refundList.length === 0 && (
                              <tr>
                                <td colSpan={4} className="text-center">
                                  Record Not Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer card-footer-part clearfix">
              <div className="pagination pagination-sm m-0 float-right">
                <Pagination
                  activePage={params.page}
                  itemsCountPerPage={parseInt(params.limit)}
                  totalItemsCount={parseInt(totalRecords)}
                  pageRangeDisplayed={5}
                  itemClass={`page-item`}
                  linkClass={`page-link`}
                  onChange={(e) => handleParams(e, "pagination")}
                />
              </div>
            </div>
          </section>
        </div>
      )}
      <Modal isOpen={delModal}>
        <ModalHeader>{`Status Change`}</ModalHeader>
        <ModalBody>
          {/* <div className="card-body">
            {" "}
            Are You Sure You want change the Status?
          </div> */}
          <div className="row">
            <div className="col-md-12">
              <FormGroup>
                <Label className="col-form-label ml-2 Label-my form-label form-label">
                  {"Refund Status"}
                </Label>
                <div className="col-xl-12">
                  <div className="input-div SelectV">
                    <Select
                      className="my-Select"
                      options={orderStatusNameList}
                      value={orderStatusNameList.find(
                        (item) => item.value == refundData.refund_status
                      )}
                      onChange={handleSelectChange}
                      placeholder={"Select Refund Status"}
                      isSearchable={true}
                      isClearable={true}
                    />
                  </div>
                </div>
              </FormGroup>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDelButtonLoad}
            onClick={onDeleteModalClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>

          {/* {delpart.refund_status === 1 && (
            <>
              <button
                type="button"
                className="btn btn-block bg-primary"
                style={{ width: "139px" }}
                onClick={handleStatusOpen}
              >
                Approved <i className="bi bi-arrow-right"></i>
              </button>

              <button
                type="button"
                className="btn btn-block bg-red"
                style={{ width: "139px" }}
                onClick={handleStatusRejectOpen}
              >
                Rejected <i className="bi bi-x"></i>
              </button>
            </>
          )} */}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyModal}>
        <ModalHeader>Sales Details</ModalHeader>

        <ModalBody>
          <div className="row">
            <div className="col-md-6">
              <h6>Product Name : {salesViewData?.product_name}</h6>
            </div>
            <div className="col-md-6">
              <h6>Quantity : {salesViewData?.quantity}</h6>
            </div>
            <div className="col-md-6">
              <h6>Order Status Name : {salesViewData?.refund_status_name}</h6>
            </div>
            <div className="col-md-6">
              <h6>price : {salesViewData?.price}</h6>
            </div>
            <div className="col-md-6">
              <h6>Customer Name : {salesViewData?.end_customer_name}</h6>
            </div>
            <div className="col-md-6">
              <h6>Customer Phone : {salesViewData?.end_customer_phone}</h6>
            </div>
            <div className="col-md-12">
              <h6>Customer Email : {salesViewData?.end_customer_email}</h6>
            </div>{" "}
          </div>
        </ModalBody>

        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            onClick={handleCloseModal}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={statusPendingModal}>
        <ModalBody>
          <p className={`mb-0`}>{`Do you want to status update?`}</p>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            onClick={handleStatusPendingClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            No <i class="bi bi-x-lg"></i>
          </button>

          <button
            type="button"
            className="btn btn-block bg-red"
            style={{ width: "100px" }}
            onClick={handleAddStatusPending}
          >
            Yes <i class="bi bi-arrow-repeat"></i>
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={statusModal}>
        <ModalHeader>{`Approved Refund`}</ModalHeader>
        <ModalBody>
          <p className={`mb-0`}>{`Are you sure you want Approved?`}</p>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDelButtonLoad}
            onClick={handleStatusClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            No <i class="bi bi-x-lg"></i>
          </button>

          <button
            type="button"
            className="btn btn-block bg-red"
            style={{ width: "100px" }}
            onClick={onDestroyPartType}
          >
            Yes <i class="bi bi-arrow-repeat"></i>
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={statusRejectModal}>
        <ModalHeader>{`Reject Refund`}</ModalHeader>
        <ModalBody>
          <p className={`mb-0`}>{`Are you sure you want Reject Refund?`}</p>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDelButtonLoad}
            onClick={handleStatusRejectClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            No <i class="bi bi-x-lg"></i>
          </button>

          <button
            type="button"
            className="btn btn-block bg-red"
            style={{ width: "100px" }}
            onClick={onRejectRefundType}
          >
            Yes <i class="bi bi-arrow-repeat"></i>
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyRefundModal} size="lg">
        <ModalBody>
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my form-label form-label">
                    {"Refund Type"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div SelectV">
                      <Select
                        className="my-Select"
                        options={refundTypeList}
                        value={refundTypeList.find(
                          (item) => item.value == refundData.type_refund
                        )}
                        onChange={handleSelectChangeRefund}
                        placeholder={"Select Refund Type"}
                        isSearchable={true}
                        isClearable={true}
                      />
                    </div>
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Reason"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={refundData.reason_refund}
                        onChange={(e) => {
                          setRefundData({
                            ...refundData,
                            reason_refund: e.target.value,
                          });
                        }}
                        name={`reason_refund`}
                        maxLength={50}
                        placeholder="Reason"
                      />
                      {errorsRefund.reason_refund && (
                        <span className="input-error">
                          {errorsRefund.reason_refund}
                        </span>
                      )}
                    </div>
                  </div>
                </FormGroup>
              </div>
              {refundData?.type_refund === 2 ? (
                <div className="col-md-6">
                  <FormGroup>
                    <Label className="col-form-label ml-2 Label-my">
                      {"Refund Value"}
                    </Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <input
                          className="form-control input-search"
                          type="text"
                          value={refundData.refund_request_value}
                          onChange={(e) => {
                            setRefundData({
                              ...refundData,
                              refund_request_value: e.target.value,
                            });
                          }}
                          name={`refund_request_value`}
                          maxLength={50}
                          placeholder="Refund Value"
                        />
                        {errorsRefund.refund_request_value && (
                          <span className="input-error">
                            {errorsRefund.refund_request_value}
                          </span>
                        )}
                      </div>
                    </div>
                  </FormGroup>
                </div>
              ) : (
                <div className="col-md-6"></div>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            onClick={handleRefundClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>

          <button
            type="button"
            className="btn btn-block btn-primary"
            style={{ width: "139px" }}
            onClick={handleAddRefundStatusSubmit}
          >
            Save<i class="bi bi-arrow-repeat"></i>
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyCancelModal} size="lg">
        {/* <ModalHeader>{`Status Change`}</ModalHeader> */}
        <ModalBody>
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Reason"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={refundData.reason_refund}
                        onChange={(e) => {
                          setRefundData({
                            ...refundData,
                            reason_refund: e.target.value,
                          });
                        }}
                        name={`reason_refund`}
                        maxLength={50}
                        placeholder="Reason"
                      />
                      {errorsRefund.reason_refund && (
                        <span className="input-error">
                          {errorsRefund.reason_refund}
                        </span>
                      )}
                    </div>
                  </div>
                </FormGroup>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            onClick={handleCancelClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>

          <button
            type="button"
            className="btn btn-block btn-primary"
            style={{ width: "139px" }}
            onClick={handleAddRefundCancelStatusSubmit}
          >
            Save<i class="bi bi-arrow-repeat"></i>
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Refund;
