import React, { useEffect, useRef, useState } from "react";
import { Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  createVehicleImage,
  deleteVehicleImage,
  updateVehicleImageProcessSort,
} from "../../services/VehicleService";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { useTranslation } from "react-i18next";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import axios from "axios";
import { ReactSketchCanvas } from "react-sketch-canvas";
import { getSettingsCompanyDetails } from "../../services/settingsServices";
import * as fabric from "fabric";

const VehicleImage = ({ setActiveTab, vehicleProcessList, activeTab }) => {
  const [modifyImportModal, setModifyImportModal] = useState(false);
  const [files, setFiles] = useState([]);

  const fabricCanvasRef = useRef(null);
  const { t } = useTranslation();

  const vehicleId = localStorage.getItem("vehicleId");
  const [selectedImage, setSelectedImage] = useState(null);
  const [rotation, setRotation] = useState(0);
  const canvasRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const imgRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [crop, setCrop] = useState({ unit: "%", width: 50, height: 50 });
  const [resizeWidth, setResizeWidth] = useState(300);
  const [resizeHeight, setResizeHeight] = useState(300);
  const [highlights, setHighlights] = useState([]);
  const [selectedColor, setSelectedColor] = useState("#ff0000");
  const [isCropping, setIsCropping] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadings, setIsLoadings] = useState(false);
  const [isLoadingColor, setIsLoadingColor] = useState(false);

  const [settingCompanyDetails, setSettingCompanyDetails] = useState("");
  const [isDrawing, setIsDrawing] = useState(false);

  const [btnLoader, setbtnLoading] = useState(false);

  const handleCrop = () => {
    setIsCropping(!isCropping);
  };

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const companyResponse = await getSettingsCompanyDetails();
        setSettingCompanyDetails(companyResponse.data.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    if (!canvasRef.current) return;

    const fabricCanvas = new fabric.Canvas(canvasRef.current);
    fabricCanvasRef.current = fabricCanvas;

    if (isDrawing) {
      fabricCanvas.isDrawingMode = true;
      fabricCanvas.freeDrawingBrush = new fabric.PencilBrush(fabricCanvas);
      fabricCanvas.freeDrawingBrush.width = 5;
      fabricCanvas.freeDrawingBrush.color = "red";
    } else {
      fabricCanvas.isDrawingMode = false;
    }

    if (selectedImage?.url) {
      fabric.Image.fromURL(selectedImage.url, (img) => {
        img.set({
          scaleX: 1,
          scaleY: 1,
          selectable: false,
          evented: false,
        });

        fabricCanvas.setBackgroundImage(
          img,
          fabricCanvas.renderAll.bind(fabricCanvas)
        );
      });
    }

    return () => {
      fabricCanvas.dispose();
    };
  }, [selectedImage, isDrawing]);

  const handleSaveOrder = () => {
    const validFiles = files.filter((file) => {
      return !String(file.id).startsWith("temp-");
    });

    const simplifiedFiles = validFiles.map((file, index) => ({
      id: file.id,
      sort_order: index + 1,
    }));

    const data = {
      sort_list: simplifiedFiles,
    };

    updateVehicleImageProcessSort(data)
      .then((response) => {
        toast.success(response.data.message);
        // setActiveTab("vehicle_extra_info");
        setModifyImportModal(false);
      })
      .catch((err) => toast.error(Utils.getErrorMessage(err)));
  };

  const onImageClick = (e) => {
    const { offsetX, offsetY } = e.nativeEvent;
    const newHighlight = {
      x: offsetX,
      y: offsetY,
      radius: 20,
      color: selectedColor,
    };

    setHighlights([...highlights, newHighlight]);
  };

  const onImageLoad = (img) => {
    imgRef.current = img;
  };

  useEffect(() => {
    if (
      Array.isArray(vehicleProcessList?.vehicle_image) &&
      activeTab === "vehicle_images"
    ) {
      const initialFiles = vehicleProcessList.vehicle_image.map(
        (document, index) => ({
          url: document.vehicle_image,
          id: document.id,
          sequence: document.sort_order,
        })
      );

      setFiles(initialFiles);
    }
  }, [vehicleProcessList, activeTab]);

  const handleImportModal = () => setModifyImportModal(true);
  const handleImportCloseModal = () => setModifyImportModal(false);

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files).map((file, index) => ({
      url: URL.createObjectURL(file),
      file,
      id: `temp-${Date.now()}-${index}`,
      sequence: files.length + index + 1,
    }));

    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setModifyImportModal(false);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedFiles = Array.from(files);
    const [movedFile] = reorderedFiles.splice(result.source.index, 1);
    reorderedFiles.splice(result.destination.index, 0, movedFile);

    const updatedFiles = reorderedFiles.map((file, index) => ({
      ...file,
      sequence: index + 1,
    }));

    setFiles(updatedFiles);
  };

  const handleRemoveFile = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
    setCrop(null); // Reset crop when selecting a new image
    setRotation(0); // Reset rotation
    setResizeWidth(""); // Default width
    setResizeHeight(""); // Default height
    setHighlights([]); // Reset highlights
    if (fileToRemove?.id && !String(fileToRemove.id).startsWith("temp-")) {
      setFiles((prevFiles) =>
        prevFiles.filter((file) => file.id !== fileToRemove.id)
      );

      const data = { id: fileToRemove.id };

      deleteVehicleImage(data)
        .then((response) => {
          toast.success(response.data.message);
          setActiveTab("vehicle_images");
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      // console.log("Skipping API call for temporary file:", fileToRemove);
    }
  };

  const handleImageSelect = (file) => {
    if (file.file) {
      setFiles((prevFiles) =>
        prevFiles.map((f) => (f.id === file.id ? { ...f } : f))
      );
      setSelectedImage(file);
      setCrop(null);
      setRotation(0);
      setHighlights([]);
      setIsModalOpen(true);
    } else {
      axios({
        url: file.url,
        method: "GET",
        responseType: "blob",
      })
        .then((res) => {
          const myFile = new File([res.data], "image.png", {
            type: res.data.type,
          });

          let fileData = file;
          fileData.url = URL.createObjectURL(myFile);
          fileData.file = myFile;

          setFiles((prevFiles) =>
            prevFiles.map((f) => (f.id === fileData.id ? { ...f } : f))
          );
          setSelectedImage(fileData);
          setCrop(null);
          setRotation(0);
          setHighlights([]);
          setIsModalOpen(true);
        })
        .catch((err) => {
          console.error("Error fetching the file:", err);
        });
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getCroppedImage = async () => {
    if (!imgRef.current || !selectedImage) return;

    const image = imgRef.current;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    let cropX = 0,
      cropY = 0,
      cropWidth = image.naturalWidth,
      cropHeight = image.naturalHeight;

    if (completedCrop) {
      cropX = completedCrop.x * scaleX;
      cropY = completedCrop.y * scaleY;
      cropWidth = completedCrop.width * scaleX;
      cropHeight = completedCrop.height * scaleY;
    }

    const radians = (rotation * Math.PI) / 180;

    canvas.width = cropWidth;
    canvas.height = cropHeight;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.save();

    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate(radians);
    ctx.translate(-canvas.width / 2, -canvas.height / 2);

    ctx.drawImage(
      image,
      cropX,
      cropY,
      cropWidth,
      cropHeight,
      0,
      0,
      cropWidth,
      cropHeight
    );

    ctx.restore();

    if (canvasRef.current) {
      try {
        const drawingDataURL = await canvasRef.current.exportImage("png");

        if (drawingDataURL) {
          const drawingImage = new Image();
          drawingImage.src = drawingDataURL;
          await new Promise((resolve) => (drawingImage.onload = resolve));

          // Draw the sketch overlay on top of the cropped image
          ctx.drawImage(drawingImage, 0, 0, cropWidth, cropHeight);
        }
      } catch (error) {
        console.error("Error exporting drawing:", error);
      }
    }

    let editedImage = canvas.toDataURL("image/jpeg");

    console.log(editedImage);

    setFiles((prevFiles) =>
      prevFiles.map((file) =>
        file.id === selectedImage.id ? { ...file, url: editedImage } : file
      )
    );

    setSelectedImage(null);
    setIsModalOpen(false);
    setCompletedCrop(null);
    setHighlights([]);
  };

  const handleRemoveBgImageeForm = async (fileId) => {
    const selectedImage = files.find((file) => file.id === fileId);

    if (!selectedImage) {
      toast.error("Please select an image first.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedImage.file);

    setIsLoading(true);

    try {
      const response = await fetch(
        "https://imagemagic.dailybills.in/remove-bg/",
        {
          method: "POST",
          body: formData,
        }
      );

      let imageBlob;

      if (!response.ok) throw new Error("Failed to remove background");
      imageBlob = await response.blob();
      console.log("Blob Type:", imageBlob.type); // Should be "image/png"
      console.log("Blob Size:", imageBlob.size); // Should be non-zero

      const imageUrl = URL.createObjectURL(imageBlob);
      console.log("imageUrl", imageUrl);
      setFiles((prevFiles) =>
        prevFiles.map((file) =>
          file.id === fileId
            ? { ...file, url: imageUrl, file: imageBlob }
            : file
        )
      );

      toast.success("Background removed successfully!");
      setSelectedImage({ url: imageUrl });
      setIsModalOpen(false);
    } catch (error) {
      console.log("Error Removing Background");
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddBgImageForm = async (fileId) => {
    const selectedImage = files.find((file) => file.id === fileId);
    const proxyUrl = "https://api.allorigins.win/raw?url=";

    const response = await fetch(
      proxyUrl + encodeURIComponent(settingCompanyDetails?.default_image)
    ); // Use proxy
    if (!response.ok) throw new Error("Default image is not available");

    const blob = await response.blob();
    const file = new File([blob], "background.jpg", { type: blob.type });

    if (!selectedImage) {
      toast.error("Please select an image first.");
      return;
    }

    const formData = new FormData();
    formData.append("main_image", selectedImage?.file);
    formData.append("bg_image", file);

    setIsLoadings(true);

    try {
      const response = await fetch("https://imagemagic.dailybills.in/add-bg/", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) throw new Error("Failed to remove background");

      const imageBlob = await response.blob();
      const imageUrl = URL.createObjectURL(imageBlob);
      setFiles((prevFiles) =>
        prevFiles.map((file) =>
          file.id === fileId
            ? { ...file, url: imageUrl, file: imageBlob }
            : file
        )
      );

      toast.success("Background add successfully!");
      setSelectedImage({ url: imageUrl });
      setIsModalOpen(false);
    } catch (error) {
    } finally {
      setIsLoadings(false);
    }
  };

  const handleAddBgColoreForm = async (fileId) => {
    const selectedImage = files.find((file) => file.id === fileId);

    if (!selectedImage) {
      toast.error("Please select an image first.");
      return;
    }

    const encodedColor = encodeURIComponent(
      settingCompanyDetails?.default_color
    );

    const formData = new FormData();
    formData.append("main_image", selectedImage.file);

    setIsLoadingColor(true);

    try {
      const response = await fetch(
        `https://imagemagic.dailybills.in/add-bg-color/?hex_color=${encodedColor}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) throw new Error("Failed to add background color");

      const imageBlob = await response.blob();
      const imageUrl = URL.createObjectURL(imageBlob);
      setFiles((prevFiles) =>
        prevFiles.map((file) =>
          file.id === fileId
            ? { ...file, url: imageUrl, file: imageBlob }
            : file
        )
      );

      toast.success("Background color added successfully!");
      setSelectedImage({ url: imageUrl });
      setIsModalOpen(false);
    } catch (error) {
      toast.error(error.message || "Something went wrong");
    } finally {
      setIsLoadingColor(false);
    }
  };

  const handleRotate = async (fileId) => {
    setRotation((prev) => (prev + 90) % 360);
  };

  const handleVehicleImageForm = (e) => {
    e.preventDefault();

    const newFiles = files.filter((file) => file.file);

    if (newFiles.length > 0) {
      const formData = new FormData();

      newFiles.forEach((file, index) => {
        let finalFile = file.file;

        if (file.url.startsWith("blob") || file.url.startsWith("data:image")) {
          fetch(file.url)
            .then((res) => res.blob())
            .then((blob) => {
              finalFile = new File([blob], `edited_image_${index}.jpg`, {
                type: "image/jpeg",
              });

              formData.append(`files[${index}]`, finalFile);
              formData.append(`sort_order[${index}]`, file.sequence);

              if (index === newFiles.length - 1) {
                submitVehicleImage(formData);
              }
            });
        } else {
          formData.append(`files[${index}]`, finalFile);
          formData.append(`sort_order[${index}]`, file.sequence);
        }
      });

      formData.append("vehicle_id", vehicleId);

      if (
        !newFiles.some(
          (file) =>
            file.url.startsWith("blob") || file.url.startsWith("data:image")
        )
      ) {
        submitVehicleImage(formData);
      }
    } else {
      setActiveTab("vehicle_video");
    }
  };

  const submitVehicleImage = (formData) => {
    setbtnLoading(true);

    createVehicleImage(formData)
      .then((response) => {
        toast.success(response.data.message);

        setActiveTab("vehicle_video");
      })
      .catch((err) => toast.error(Utils.getErrorMessage(err)))
      .finally(() => {
        setbtnLoading(false);
      });
  };

  const saveDrawing = async (fileId) => {
    setIsDrawing((prev) => !prev);
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          {vehicleProcessList?.vehicle_image?.length > 0 ? (
            <>
              <div className="card card-primary">
                <Form method="post" onSubmit={handleVehicleImageForm}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12 ms-3">
                        <button
                          type="button"
                          onClick={() =>
                            document.getElementById("file-input").click()
                          }
                          className={`btn btn-sm btn-primary mb-2 ${
                            files.length === 0 ? "import-button-empty" : ""
                          }`}
                        >
                          <i class="bi bi-cloud-arrow-up me-2"></i> Upload
                          {t("images")}
                        </button>
                        <input
                          id="file-input"
                          className="form-control"
                          type="file"
                          onChange={handleFileChange}
                          name="file"
                          accept=".jpg,.png"
                          multiple
                          style={{ display: "none" }}
                        />
                        <div className="file-preview file-preview-custom">
                          <div className="file-list">
                            {files.length === 0 ? (
                              <div className="file-item file-item-custom ">
                                <div
                                  className="preview-images-size"
                                  style={{ height: "254px" }}
                                >
                                  <div
                                    className="card"
                                    style={{ width: "340px" }}
                                  >
                                    <div className="card-body">
                                      <div className="">
                                        <div className="btn-removeImg">
                                          <div className="btn-Remove">
                                            <i
                                              className="bi bi-x"
                                              style={{
                                                color: "red",
                                                display: "flex",
                                                justifyContent: "end",
                                              }}
                                            ></i>
                                          </div>
                                        </div>
                                        <p className="noImg">
                                          No image Selected
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              files?.map((file, index) => (
                                <div
                                  key={index}
                                  className={"file-item file-item-custom"}
                                >
                                  <i
                                    className="editImageBtn bi bi-pen-fill"
                                    style={{
                                      position: "absolute",
                                      top: "33px",
                                      cursor: "pointer",
                                      color: "blue",
                                      left: "13px",
                                    }}
                                    onClick={() => handleImageSelect(file)}
                                  ></i>
                                  <img
                                    src={file.url}
                                    alt="preview"
                                    style={{
                                      height: "100px",
                                      width: "100px",
                                      objectFit: "cover",
                                    }}
                                  />

                                  <i
                                    className="removeImageBtn bi bi-x"
                                    style={{
                                      position: "absolute",
                                      top: "33px",
                                      right: "13px",
                                      cursor: "pointer",
                                      color: "red",
                                    }}
                                    onClick={() => handleRemoveFile(file)}
                                  ></i>
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12"></div>
                    </div>

                    <div className="buttonsB">
                      <div className="col">
                        <button
                          type="button"
                          className="back-btn"
                          onClick={() => setActiveTab("vehicle")}
                        >
                          <i class="bi bi-arrow-left"></i> {t("back")}
                        </button>
                      </div>
                      <div className="col">
                        <div className="btnNxtFns">
                          <button
                            type="button"
                            className="btn btn-primary nxt-btn"
                            onClick={handleImportModal}
                          >
                            Sort By Order <i class="bi bi-arrow-right"></i>
                          </button>
                          {btnLoader === true ? (
                            <button type="submit" className="btn  btn-primary">
                              Loading...
                            </button>
                          ) : (
                            <button type="submit" className="btn  btn-primary">
                              {t("save")}

                              <i class="bi bi-arrow-right"></i>
                            </button>
                          )}

                          <button
                            type="button"
                            className="btn btn-primary nxt-btn"
                            onClick={() => setActiveTab("vehicle_extra_info")}
                          >
                            {t("next")}
                            <i class="bi bi-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>{" "}
              </div>{" "}
            </>
          ) : (
            <>
              <div className="card card-primary">
                <Form method="post" onSubmit={handleVehicleImageForm}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <button
                          type="button"
                          onClick={() =>
                            document.getElementById("file-input").click()
                          }
                          className={`btn btn-sm btn-primary mb-2 ${
                            files.length === 0 ? "import-button-empty" : ""
                          }`}
                        >
                          <i className="bi bi-cloud-arrow-up me-2"></i>
                          {t("upload_multiple_images")}
                        </button>
                        <input
                          id="file-input"
                          className="form-control"
                          type="file"
                          onChange={handleFileChange}
                          name="file"
                          accept=".jpg,.png"
                          multiple
                          style={{ display: "none" }}
                        />
                      </div>
                    </div>

                    <DragDropContext onDragEnd={handleDragEnd}>
                      <Droppable droppableId="fileList" direction="horizontal">
                        {(provided) => (
                          <div
                            className="file-list"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                              display: "flex",
                              gap: "10px",
                              overflowX: "auto",
                            }}
                          >
                            {files.map((file, index) => (
                              <Draggable
                                key={file.id || file.url}
                                draggableId={file.id || file.url}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    className="file-item"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={{
                                      ...provided.draggableProps.style,
                                      border: "1px solid #ddd",
                                      padding: "5px",
                                      borderRadius: "5px",
                                      position: "relative",
                                    }}
                                  >
                                    <i
                                      className="editImageBtn bi bi-pen-fill"
                                      style={{
                                        position: "absolute",
                                        top: "33px",
                                        cursor: "pointer",
                                        color: "blue",
                                        left: "13px",
                                      }}
                                      onClick={() => handleImageSelect(file)}
                                    ></i>
                                    <img
                                      src={file.url}
                                      alt="preview"
                                      style={{
                                        height: "100px",
                                        objectFit: "cover",
                                        width: "100px",
                                      }}
                                    />

                                    <i
                                      className="removeImageBtn bi bi-x"
                                      style={{
                                        position: "absolute",
                                        top: "33px",
                                        right: "13px",
                                        cursor: "pointer",
                                        color: "red",
                                      }}
                                      onClick={() => handleRemoveFile(file)}
                                    ></i>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <div className="back-footer">
                      <div className="buttonsB">
                        <button
                          type="button"
                          className="back-btn"
                          onClick={() => setActiveTab("vehicle")}
                        >
                          <i className="bi bi-arrow-left"></i> {t("back")}
                        </button>
                        {btnLoader === true ? (
                          <button type="submit" className="btn  btn-primary">
                            Loading...
                          </button>
                        ) : (
                          <button type="submit" className="btn  btn-primary">
                            {t("finish")}

                            <i class="bi bi-arrow-right"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </>
          )}
        </div>
      </div>

      <Modal isOpen={modifyImportModal} toggle={handleImportCloseModal}>
        <Form method="post">
          <ModalHeader toggle={handleImportCloseModal}>
            Sort By Order
          </ModalHeader>
          <ModalBody>
            <DragDropContext
              onDragEnd={(result) => {
                if (!result.destination) return;

                const reorderedFiles = Array.from(files);
                const [movedFile] = reorderedFiles.splice(
                  result.source.index,
                  1
                );
                reorderedFiles.splice(result.destination.index, 0, movedFile);

                const updatedFiles = reorderedFiles.map((file, index) => ({
                  ...file,
                  sequence: index + 1,
                }));

                setFiles(updatedFiles);
              }}
            >
              <Droppable droppableId="fileList" direction="vertical">
                {(provided) => (
                  <div
                    className="file-list"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    {files.map((file, index) => (
                      <Draggable
                        key={file.id.toString()}
                        draggableId={file.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            className="file-item"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              border: "1px solid #ddd",
                              padding: "10px",
                              borderRadius: "5px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={file.url}
                              alt="Preview"
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                                borderRadius: "5px",
                              }}
                            />{" "}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </ModalBody>

          <ModalFooter>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleImportCloseModal}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                handleSaveOrder();
                handleImportCloseModal();
              }}
            >
              {t("save_order")}
            </button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={isModalOpen} toggle={closeModal} size="lg">
        <Form method="post">
          <ModalHeader toggle={closeModal}>Image Edit</ModalHeader>
          {selectedImage && (
            <ModalBody>
              <div className="modal-body" style={{ marginBottom: "100px" }}>
                <div
                  style={{
                    width: "100%",
                    height: "400px",
                    textAlign: "center",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      width: "100%",
                      height: "394px",
                    }}
                  >
                    {/* Cropping Section */}
                    <ReactCrop
                      crop={crop}
                      onImageLoaded={onImageLoad}
                      onChange={(newCrop) => setCrop(newCrop)}
                      onComplete={(c) => setCompletedCrop(c)}
                      disabled={!isCropping}
                    >
                      <img
                        src={selectedImage?.url}
                        ref={imgRef}
                        style={{
                          transform: `rotate(${rotation}deg)`,
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "cover",
                          height: "394px",
                          display: selectedImage ? "block" : "none",
                        }}
                        alt="Crop preview"
                        onClick={onImageClick}
                      />
                    </ReactCrop>
                    {isDrawing && !isCropping && (
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <ReactSketchCanvas
                          ref={canvasRef}
                          style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            top: 0,
                            left: 0,
                          }}
                          canvasColor="transparent"
                          allowOnlyPointerType="all"
                        />
                      </div>
                    )}
                  </div>

                  <div className="row" style={{ marginTop: "40px" }}>
                    <div className="col-md-2">
                      <i
                        class="bi bi-crop"
                        style={{
                          fontSize: "22px",
                          cursor: "pointer",
                          color: isCropping ? "blue" : "black",
                        }}
                        onClick={handleCrop}
                      ></i>
                    </div>

                    <div className="col-md-2">
                      <i
                        className="bi bi-brush"
                        style={{
                          fontSize: "22px",
                          cursor: "pointer",
                          color: isDrawing ? "blue" : "black",
                        }}
                        onClick={() => saveDrawing(selectedImage.id)}
                      ></i>
                    </div>

                    <div className="col-md-2">
                      <i
                        class="bi bi-arrow-counterclockwise"
                        style={{ fontSize: "22px", cursor: "pointer" }}
                        onClick={() => handleRotate(selectedImage.id)}
                      ></i>
                    </div>

                    <div className="col-md-2">
                      <i
                        class="bi bi-image"
                        style={{ fontSize: "22px", cursor: "pointer" }}
                        onClick={() =>
                          handleRemoveBgImageeForm(selectedImage.id)
                        }
                        disabled={isLoading}
                      ></i>
                    </div>

                    <div className="col-md-2">
                      <i
                        class="bi bi-image"
                        style={{ fontSize: "22px", cursor: "pointer" }}
                        onClick={(e) => handleAddBgImageForm(selectedImage.id)}
                        disabled={isLoadings}
                      ></i>
                    </div>

                    <div className="col-md-2">
                      <i
                        class="bi bi-paint-bucket"
                        style={{ fontSize: "22px", cursor: "pointer" }}
                        onClick={() => handleAddBgColoreForm(selectedImage.id)}
                      ></i>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-2">
                      <span>Crop</span>
                    </div>

                    <div className="col-md-2">
                      <span>Brush</span>
                    </div>

                    <div className="col-md-2">
                      <span>Rotate</span>
                    </div>

                    <div className="col-md-2">
                      <span>{isLoading ? "Processing..." : "Remove Back"}</span>
                    </div>

                    <div className="col-md-2">
                      <span> {isLoadings ? "Processing..." : "Add Back"}</span>
                    </div>

                    <div className="col-md-2">
                      <span>
                        {isLoadingColor ? "Processing..." : "Add Color"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
          )}

          <ModalFooter>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={closeModal}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={getCroppedImage}
            >
              Apply Changes
            </button>
          </ModalFooter>
        </Form>
      </Modal>
      {/*
      <Modal isOpen={modifyBgAdd} toggle={handleAddBgImageClose}>
        <Form method="post">
          <ModalHeader toggle={handleAddBgImageClose}>
            Background Add
          </ModalHeader>
          <ModalBody>
            <div className="avatar-upload">
              <div className="avatar-edit">
                <div className="input-div">
                  <input
                    className="inputFile"
                    type="file"
                    id="imageUploads"
                    onChange={handleFileChangeDefualt}
                    name="file"
                    accept=".jpg,.jpeg,.png"
                  />
                </div>
                <label htmlFor="imageUploads"></label>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleAddBgImageClose}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleAddBgImageeForm}
            >
              Save Add Bg
            </button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modifyColorAdd} toggle={handleAddBgColorClose}>
        <Form method="post">
          <ModalHeader toggle={handleAddBgColorClose}>
            Add Background Color
          </ModalHeader>
          <ModalBody>
            <div>
              <Label className="col-form-label ml-2 Label-my form-label form-label">
                Color Picker{" "}
              </Label>
              <SketchPicker color={color} onChange={handleChangeColor} />
            </div>
          </ModalBody>

          <ModalFooter>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleAddBgColorClose}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleAddBgColoreForm}
            >
              Save Add Bg Color
            </button>
          </ModalFooter>
        </Form>
      </Modal> */}
    </div>
  );
};

export default VehicleImage;
