import React, { forwardRef, useEffect, useRef } from "react";

export const ComponentToPrint = forwardRef((props, ref) => {
  const { text } = props;

  const canvasEl = useRef(null);
  const shadowRootHostEl = useRef(null);

  useEffect(() => {
    const ctx = canvasEl.current?.getContext("2d");

    if (ctx) {
      ctx.beginPath();
      ctx.arc(95, 50, 40, 0, 2 * Math.PI);
      ctx.stroke();
      ctx.fillStyle = "rgb(200, 0, 0)";
      ctx.fillRect(85, 40, 20, 20);
      ctx.save();
    }
  }, []);

  return (
    <div className="relativeCSS" ref={ref}>
      <table className="testClass">
        <thead>
          <tr>
            <th className="column1">Test Name</th>
            <th>Test</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Test loading {"<link>"}s with multi-value `rel`</td>
            <td>
              <div className="multi-rel">Purple Background</div>
            </td>
          </tr>
          <tr>
            <td>Test loading {"<link>"}s with `as="style"`</td>
            <td>
              <div className="as-style">Blue Background</div>
            </td>
          </tr>
        </tbody>
      </table>
      <div ref={shadowRootHostEl} />
    </div>
  );
});
