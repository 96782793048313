import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const PartLocationAssign = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6"></div>
              <div className="col-sm-6"></div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid container-custom-height-new ">
            <div className="row">
              <div className="col-md-12">
                <div className="card fuelCard">
                  <div className="">
                    <div className="">
                      <div className="card-header">
                        <div className="card-title m-0 float-right d-flex">
                          <button
                            type="button"
                            className="btn  btn-icon-text mr-2"
                            onClick={() => navigate("/associate-multi-part")}
                          >
                            <i class="bi bi-geo-alt-fill"></i>
                            <span className="ms-2">Associate Multi Part</span>
                          </button>

                          <button
                            type="button"
                            className="btn  btn-icon-text mr-2"
                            onClick={() => navigate("/associate-location-part")}
                          >
                            <i class="bi bi-geo-alt-fill"></i>
                            <span className="ms-2">
                              Associate Location Part
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PartLocationAssign;
