import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { profileDetailForWeb } from "../../services/AuthServices";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { getSettingsCompanyDetails } from "../../services/settingsServices";

const Header = ({ isOpenS, setIsOpens }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modifyPrint, setModifyPrint] = useState(false);
  const [profileDetails, setProfileDetails] = useState("");
  const navigate = useNavigate();
  const [settingCompanyDetails, setSettingCompanyDetails] = useState("");

  const location = useLocation();
  const { t } = useTranslation();
  const UserImages = "https://tp.software/libs/img/logo-letters.png";

  let title;
  if (location.pathname === "/dashboard") {
    title = t("dashboard");
  } else if (location.pathname === "/vehicle-process") {
    title = t("vehicle");
  } else if (location.pathname === "/parts-inventory") {
    title = t("Parts Inventory");
  } else if (location.pathname === "/ler-catalog") {
    title = t("LER Catalog");
  } else if (location.pathname === "/depollution-operation") {
    title = t("Depollution Opration");
  } else if (location.pathname === "/User") {
    title = t("User");
  } else if (location.pathname === "/Roles") {
    title = t("Roles");
  } else if (location.pathname === "/fuel-type") {
    title = t("Fuel");
  } else if (location.pathname === "/part-type") {
    title = t("Part Type");
  } else if (location.pathname === "/part-location") {
    title = t("Part Location");
  } else if (location.pathname === "/part-group") {
    title = t("Part Group");
  } else if (location.pathname === "/part-sides") {
    title = t("Part Sides");
  } else if (location.pathname === "/vehicle-category") {
    title = t("vehicle_category");
  } else if (location.pathname === "/vehicle-type") {
    title = t("Vehicle Type");
  } else if (location.pathname === "/vehicle-model") {
    title = t("vehicle_model");
  } else if (location.pathname === "/faqs") {
    title = t("FAQ");
  } else if (location.pathname === "/terms-condition-admin") {
    title = t("Terms & Condition");
  } else if (location.pathname === "/privacy-policy-admin") {
    title = t("Privacy & Policy");
  } else if (location.pathname === "/part-catalog") {
    title = t("Part Catalog");
  } else if (location.pathname === "/park") {
    title = t("Park");
  } else if (location.pathname === "/shelf") {
    title = t("Shelf");
  } else if (location.pathname === "/certificate") {
    title = t("Certificate");
  } else if (location.pathname === "/warehouse-entity-type") {
    title = t("Warehouse Entity Type");
  } else if (location.pathname === "/warehouse") {
    title = t("Warehouse");
  } else if (location.pathname === "/pool-printing") {
    title = t("Pool Printing");
  } else if (location.pathname === "/vehicle-make") {
    title = t("Vehicle Make");
  } else if (location.pathname === "/vehicle-engine") {
    title = t("vehicle_engine");
  } else if (location.pathname === "/vehicle-variants") {
    title = t("Vehicle Variants");
  } else if (location.pathname === "/sales") {
    title = t("Sales");
  } else if (location.pathname === "/part-location-asssign") {
    title = t("part_location_assign");
  } else if (location.pathname === "/print") {
    title = "Print";
  }

  const handlePrintClose = () => {
    setModifyPrint(false);
  };

  const handlePrintOpen = () => {
    setModifyPrint(true);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("@secure.@data");
    navigate("/");
  };

  useEffect(() => {
    profileDetailForWeb()
      .then((response) => {
        setProfileDetails(response.data.data);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  }, []);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const companyResponse = await getSettingsCompanyDetails();
        setSettingCompanyDetails(companyResponse.data.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchSettings();
  }, []);

  const toggleSidebar = () => {
    setIsOpens(!isOpenS);
  };
  return (
    <div className="wrapper-dashboard">
      <nav
        className="main-header navbar navbar-expand navbar-white navbar-light"
        style={{ marginLeft: isOpenS ? "250px" : "60px" }}
      >
        {isOpenS && (
          <div
            style={{ padding: "3px 5px", fontSize: "25px" }}
            onClick={toggleSidebar}
            className="nav-link d-lg-none"
          >
            <i className="bi bi-list text-dark"></i>
          </div>
        )}
        <h5>{title}</h5>
        <ul className="navbar-nav ml-auto ">
          <li
            className="nav-item dropdown hoverDropdown"
            style={{ marginRight: "8px" }}
          >
            <button
              type="button"
              onClick={handlePrintOpen}
              class="btn btn-block btn-primary"
              style={{ width: "125px" }}
            >
              <span>Print Help</span>
              <i class="bi bi-info"></i>
            </button>
          </li>
          <li
            className="nav-item dropdown hoverDropdown"
            style={{ marginRight: "8px" }}
          >
            <LanguageSwitcher />
          </li>
          <li
            className="nav-item dropdown hoverDropdown"
            style={{ position: "relative" }}
          >
            <div className="profile">
              <div className="dropdown">
                <img
                  src={
                    profileDetails?.profile
                      ? profileDetails?.profile
                      : UserImages
                  }
                  alt="user"
                  className="img-thumbnail"
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                  onClick={toggleDropdown}
                />
                <ul
                  className={`dropdown-menu dropdown-menu-md dropdown-menu-right ${
                    isOpen ? "show" : ""
                  }`}
                  style={{ display: isOpen ? "block" : "none" }}
                >
                  <li>
                    <a className="dropdown-item" href="/profile">
                      Profile
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="/settings">
                      Settings
                    </a>
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={handleLogout}
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </nav>

      <Modal isOpen={modifyPrint} size="lg">
        <ModalHeader>
          <div style={{ marginBottom: "-20px" }}>
            <i className="bi bi-printer"></i> Label Printing Application
            Installation & Setup Guide
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <h6 className="mb-3">
              Step-by-Step Installation Process
              <button
                type="submit"
                className="btn btn-primary"
                style={{
                  marginLeft: "55px",
                }}
              >
                <a
                  style={{ color: "white" }}
                  href={settingCompanyDetails?.download_printer_url}
                  download
                >
                  Download PrintServer.exe
                </a>
              </button>
            </h6>
            <ul
              className="list-group list-group-flush"
              style={{
                marginTop: "-12px",
                fontSize: "15px",
                fontWeight: "500",
                color: "#333",
              }}
            >
              <li className="list-group-item">
                <strong>Step 1: Download the PrintServer App</strong>
                <ul>
                  <li>
                    Click the <b>Download PrintServer App</b> button below.
                  </li>
                  <li>
                    Save the installer file to your preferred location on your
                    local server .
                  </li>
                </ul>
              </li>
              <li className="list-group-item" style={{ marginTop: "-11px" }}>
                <strong>Step 2: Install the Application</strong>
                <ul>
                  <li>
                    Once the download is complete, double-click the installer
                    file.
                  </li>
                  <li>
                    Follow the on-screen installation steps to complete the
                    process.
                  </li>
                </ul>
              </li>
              <li className="list-group-item" style={{ marginTop: "-11px" }}>
                <strong>Step 3: Run as Administrator</strong>
                <ul>
                  <li>
                    After installation, right-click the PrintServer app icon.
                  </li>
                  <li>
                    Select <b>Run as Administrator</b> to launch the
                    application.
                  </li>
                </ul>
              </li>
              <li className="list-group-item" style={{ marginTop: "-11px" }}>
                <strong>Step 4: Login Using Company Primary Account</strong>
                <ul>
                  <li>
                    Upon launching the app, you will be prompted to log in.
                  </li>
                  <li>
                    Use your <b>Company's Primary Account </b> credentials to
                    sign in.
                  </li>
                </ul>
              </li>
              <li className="list-group-item" style={{ marginTop: "-11px" }}>
                <strong>Step 5: Sync Label Print Queue </strong>
                <ul>
                  <li>
                    The PrintServer app will automatically sync with your web
                    app's print label queue every
                    <b>15 minutes.</b>
                  </li>

                  <li>
                    You also have the option to sync manually by clicking the{" "}
                    <b>Sync Now </b> button if you want to immediately update
                    the print queue.
                  </li>
                </ul>
              </li>
              <li className="list-group-item" style={{ marginTop: "-11px" }}>
                <strong>Step 6: Select Printer & Print the Label</strong>
                <ul>
                  <li>
                    Once the queue is updated, you will see the available labels
                    in the PrintServer app.
                  </li>
                  <li>
                    From the <b>Printer dropdown,</b> select the printer you'd
                    like to use.
                  </li>
                </ul>
              </li>

              <li className="list-group-item" style={{ marginTop: "-11px" }}>
                <strong>Step 7: Important Notes & Troubleshooting</strong>
                <ul>
                  <li>
                    <b>Run the app as Administrator </b> to ensure full access
                    and functionality.
                  </li>
                  <li>
                    Ensure the printer is connected to the server, powered on,
                    and available in{" "}
                    <b>Windows Settings - Printers & Devices</b>
                  </li>
                  <li>
                    The <b>primary company account </b> login must be used to
                    ensure proper syncing of the print queue.
                  </li>
                  <li>
                    The queue will be updated automatically every{" "}
                    <b>15 minutes,</b> but you can always click <b>Sync Now </b>{" "}
                    for immediate updates.
                  </li>
                  <li>
                    If the queue doesn’t appear or there’s an issue printing,
                    ensure the
                    <b> PrintServer</b> app is running and synced.
                  </li>
                </ul>
              </li>
            </ul>

            <h6>Ready to Install? Click Below to Download</h6>

            <button
              type="submit"
              className="btn btn-primary"
              style={{
                marginLeft: "280px",
                marginTop: "4px",
              }}
            >
              <a
                style={{
                  color: "white",
                }}
                href={settingCompanyDetails?.download_printer_url}
                download
              >
                Download PrintServer.exe
              </a>
            </button>
          </div>
        </ModalBody>
        <ModalFooter>
          {" "}
          <Button variant="secondary" onClick={handlePrintClose}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Header;
