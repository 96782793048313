import React, { useEffect, useRef, useState } from "react";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { getSettingsDetails } from "../../services/settingsServices";
import {
  createPartVideo,
  deletePartvideo,
  getPartVideoSortDetails,
  updatePartVideoSort,
} from "../../services/PartsInventory";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useNavigate } from "react-router-dom";

const SortableItem = SortableElement(({ video }) => {
  const videoSrc =
    video instanceof File ? URL.createObjectURL(video) : video.url;
  return (
    <div className="m-2 p-2 bg-light border rounded d-flex align-items-center">
      <span className="me-2">☰</span>
      <video width="150" controls>
        <source src={videoSrc} type={video.type || "video/mp4"} />
        Your browser does not support the video tag.
      </video>
    </div>
  );
});

const SortableList = SortableContainer(({ videos }) => (
  <div>
    {videos.map((video, index) => (
      <SortableItem key={`item-${index}`} index={index} video={video} />
    ))}
  </div>
));

const AddPartVideo = ({
  currentPart,
  vehicleAddDetails,
  setCurrentStep,
  setCurrentPartIndex,
  isLastPart,
  setShowSkip,
  showSkip,
  setPartVideoDataList,
  partsData,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const fileInputRef = useRef(null);
  const [videos, setVideos] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDelete, setIsModalDelete] = useState(false);
  const [videoDataId, setvideoDataId] = useState("");
  const [deleteIndex, setDeleteIndex] = useState("");

  const [settingDetails, setSettingDetails] = useState("");
  const [partImageDetails, setPartImageDetails] = useState("");
  const [btnLoader, setbtnLoading] = useState(false);

  useEffect(() => {
    if (vehicleAddDetails) {
      let data = {
        id: vehicleAddDetails.id,
      };

      getPartVideoSortDetails(data)
        .then((response) => {
          setPartImageDetails(response.data.data);
        })
        .catch((err) => {});
    }
  }, [vehicleAddDetails]);

  useEffect(() => {
    if (Array.isArray(partImageDetails)) {
      const initialFiles = partImageDetails?.map((document) => ({
        url: document.video_url,
        id: document.id,
        sequence: document.sort_order,
      }));
      setVideos(initialFiles);
    }
  }, [partImageDetails]);

  const handleDeleteClose = () => {
    setIsModalDelete(false);
  };

  useEffect(() => {
    getSettingsDetails()
      .then((response) => {
        setSettingDetails(response.data.data);
      })
      .catch((err) => {});
  }, []);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files).map((file) => ({
      file,
      id: 0,
      sequence: videos.length + 1,
    }));
    setVideos((prevVideos) => [...prevVideos, ...files]);
  };

  const handleRemoveVideo = (index, id) => {
    if (id) {
      setvideoDataId(id);
      setIsModalDelete(true);
      setDeleteIndex(index);
    } else {
      const newVideos = videos.filter((_, i) => i !== index);
      setVideos(newVideos);

      if (newVideos.length === 0 && fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const handleEditRemoveVideo = async () => {
    let data = { id: videoDataId };

    deletePartvideo(data)
      .then((response) => {
        toast.success(response.data.message);

        setVideos((prevVideos) =>
          prevVideos.filter((_, i) => i !== deleteIndex)
        );

        setIsModalDelete(false);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setVideos((prevVideos) =>
      arrayMoveImmutable(prevVideos, oldIndex, newIndex)
    );
  };

  const handleSaveOrder = async () => {
    const sortList = videos.map((video, index) => ({
      id: video.id || 0,
      sort_order: index + 1,
    }));

    try {
      await updatePartVideoSort({ sort_list: sortList });
      toast.success("Sort order updated successfully!");
      setIsModalOpen(false);
    } catch (err) {
      toast.error(Utils.getErrorMessage(err));
    }
  };

  const handleVehicleVideoSaveForm = async (e) => {
    e.preventDefault();

    const newFiles = videos.filter((video) => video.file);
    const part_video_size = settingDetails?.part_video_size * 1024 * 1024;

    if (newFiles.length > settingDetails?.part_video_limit) {
      toast.error(
        `You can only upload up to ${settingDetails?.part_video_limit} videos.`
      );
      return;
    }

    const oversizedFiles = newFiles.filter(
      (video) => video.file.size > part_video_size
    );
    if (oversizedFiles.length > 0) {
      toast.error(
        `Each video must be under ${settingDetails?.part_video_limit}MB.`
      );
      return;
    }

    if (newFiles.length === 0) {
      return;
    }

    setbtnLoading(true);
    const isEditing = !!vehicleAddDetails?.id;
    const partId = isEditing ? vehicleAddDetails?.id : currentPart?.id;

    const formData = new FormData();
    newFiles.forEach((video) => formData.append("files[]", video.file));
    formData.append("part_id", partId);

    try {
      const response = await createPartVideo(formData);

      toast.success(response.data.message);
      setPartVideoDataList(response.data.data);
      setVideos([]);

      if (
        partsData?.some((part) => part.parts_damage.length > 0) ||
        vehicleAddDetails?.parts_damage.length > 0
      ) {
        setCurrentStep(6);
      } else {
        navigate("/parts-inventory");
      }
    } catch (err) {
      setbtnLoading(false);

      toast.error(Utils.getErrorMessage(err));
    }
  };

  const handleSkip = () => {
    if (!isLastPart) {
      setCurrentPartIndex((prevIndex) => prevIndex + 1);
    } else {
      setCurrentStep(6);
    }
    setShowSkip(false);
  };

  const handleBackButtonClick = () => {
    setCurrentStep(2);
  };

  const handleCloseSkip = () => {
    setShowSkip(false);
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-6">
          <Card className="text-justify">
            <Card.Header className="cardHeader">
              <div className="d-flex">
                <div className="AddPh6">
                  <h6>Part Information</h6>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="cardBody" style={{ padding: "0" }}>
              <div class="container-fluid text-justify">
                <div class="row pt-2 pb-2 border-bottom">
                  <div class="col-lg-6">Part Name:</div>
                  <div class="col-lg-6">
                    {vehicleAddDetails?.part_name
                      ? vehicleAddDetails?.part_name
                      : currentPart?.part_name}
                  </div>
                </div>
                <div class="row pt-2 pb-2 border-bottom">
                  <div class="col-lg-6">Part Description:</div>
                  <div class="col-lg-6" style={{ lineHeight: "25px" }}>
                    {vehicleAddDetails?.part_description
                      ? vehicleAddDetails?.part_description
                      : currentPart?.part_description}
                  </div>
                </div>

                <div class="row pt-2 pb-2 border-bottom">
                  <div class="col-lg-6">Part Quantity:</div>
                  <div class="col-lg-6">
                    {vehicleAddDetails?.quantity
                      ? vehicleAddDetails?.quantity
                      : currentPart?.quantity}
                  </div>
                </div>
                <div class="row pt-2 pb-2 border-bottom">
                  <div class="col-lg-6">Part Price:</div>
                  <div class="col-lg-6">
                    {vehicleAddDetails?.part_price
                      ? vehicleAddDetails?.part_price
                      : currentPart?.part_price}
                  </div>
                </div>

                <div class="row pt-2 pb-2 border-bottom">
                  <div class="col-lg-6">Part Original Price:</div>
                  <div class="col-lg-6">
                    {vehicleAddDetails?.part_original_price
                      ? vehicleAddDetails?.part_original_price
                      : currentPart?.part_original_price}
                  </div>
                </div>

                <div class="row pt-2 pb-2 border-bottom">
                  <div class="col-lg-6">Sponsor Price: </div>
                  <div class="col-lg-6">
                    {vehicleAddDetails?.sponsor_price
                      ? vehicleAddDetails?.sponsor_price
                      : currentPart?.sponsor_price}
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>

        <div className="col-lg-6">
          <div className="col-lg-12">
            <Card className="text-center">
              <Card.Header className="cardHeader">
                <div className="d-flex">
                  <div className="AddPh6">
                    <h6>Upload Part Multiple Videos</h6>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className="cardBody">
                <div className="card p-3">
                  <input
                    type="file"
                    accept="video/*"
                    multiple
                    onChange={handleFileChange}
                    className="form-control mt-2"
                    ref={fileInputRef}
                    style={{ width: "213px" }}
                  />

                  {videos?.length > 0 && (
                    <div className="mt-3">
                      <div className="d-flex flex-wrap">
                        {videos.map((video, index) => (
                          <div key={index} className="m-2 position-relative">
                            <button
                              className="btn btn-danger btn-sm position-absolute"
                              style={{ top: "5px", right: "5px", zIndex: 10 }}
                              onClick={() => handleRemoveVideo(index, video.id)}
                              type="button"
                            >
                              ✖
                            </button>
                            <video width="200" controls>
                              <source
                                src={
                                  video.url || URL.createObjectURL(video.file)
                                }
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                {/* 
                  <div className="back-footer">
                    <div className="buttonsB">
                      <Button
                        type="button"
                        className="back-btn"
                        // onClick={() => setActiveTab("vehicle_images")}
                      >
                        <i className="bi bi-arrow-left"></i> {t("back")}
                      </Button>
                      <div className="d-flex justify-content-end gap-2 mt-3">
                        <Button
                          color="primary"
                          onClick={() => setIsModalOpen(true)}
                          type="button"
                        >
                          Sort Order
                        </Button>
                        <Button type="submit" color="primary">
                          {t("finish")}
                          <i className="bi bi-arrow-right"></i>
                        </Button>
                      </div>
                    </div>
                  </div> */}
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      <div className="back-next back-next-custom">
        <div className="row">
          <div className="col">
            <button
              type="button"
              className="next-button btn btn-secondary ms-0"
              onClick={handleBackButtonClick}
            >
              <i class="bi bi-arrow-left"></i> {"back"}
            </button>
          </div>

          {/* <div className="col nxt-btn mr-0">
            {!isLastPart && (
              <Button
                className="me-2 mb-2 next-button"
                onClick={hanldeSkipOpen}
              >
                {t("skip")} & {t("next")}
              </Button>
            )}

            {!isLastPart && (
              <Button
                className="me-2 mb-2 btn-primary"
                type="button"
                onClick={handleVehicleVideoForm}
              >
                {t("save")}& {t("next")}
              </Button>
            )}

            {!isLastPart && (
              <Button
                color="primary"
                onClick={() => setIsModalOpen(true)}
                type="button"
              >
                Sort Order
              </Button>
            )}
          </div>
          {isLastPart && ( */}
          <div className="col nxt-btn mr-0">
            <div className="d-flex justify-content-end gap-2">
              <Button
                color="primary"
                onClick={() => setIsModalOpen(true)}
                type="button"
              >
                Sort Order
              </Button>
              <Button
                className="next-button"
                type="button"
                onClick={handleVehicleVideoSaveForm}
              >
                {t("save")} <i class="bi bi-arrow-right"></i>
              </Button>

              {btnLoader === true ? (
                <button type="submit" className="btn  btn-primary">
                  Loading...
                </button>
              ) : (
                <button type="submit" className="btn  btn-primary">
                  {t("finish")}

                  <i class="bi bi-arrow-right"></i>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)}>
        <ModalHeader toggle={() => setIsModalOpen(false)}>
          Sort Videos
        </ModalHeader>
        <ModalBody>
          <SortableList videos={videos} onSortEnd={onSortEnd} axis="y" />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSaveOrder}>
            Save Order
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={isModalDelete} toggle={handleDeleteClose}>
        <ModalHeader toggle={handleDeleteClose}>Delete Video</ModalHeader>
        <ModalBody>
          <div>{"Are you sure you want to delete this video?"}</div>
        </ModalBody>

        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            onClick={handleDeleteClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            {t("close")}
          </button>

          <button
            type="button"
            className="btn btn-block bg-red"
            style={{ width: "100px" }}
            onClick={handleEditRemoveVideo}
          >
            {t("delete")}
          </button>
        </ModalFooter>
      </Modal>{" "}
      <Modal isOpen={showSkip}>
        <ModalHeader></ModalHeader>
        <ModalBody>
          <div className="row">Are you sure you want to skip this part?</div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-block btn-primary"
            onClick={handleCloseSkip}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>

          <button
            type="button"
            className="btn btn-block btn-primary"
            onClick={handleSkip}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Yes <i class="bi bi-x-lg"></i>
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddPartVideo;
