import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import * as XLSX from "xlsx";
import {
  createVehicleEngine,
  deleteVehicleEngine,
  exportVehicleEngine,
  getVehicleEngineList,
  importVehicleEngine,
  updateVehicleEngine,
} from "../../services/VehicleEngineService";
import { getVehicleSerachVariantList } from "../../services/VehicleVariantsService";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const VehicleEngine = () => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);
  const { t } = useTranslation();

  const location = useLocation();
  const RolePermission = Utils.getRecordForCurrentPath(location);
  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
  });

  const [totalRecords, setTotalRecords] = useState(0);
  const [typeList, setTypeList] = useState([]);
  const [delModal, setDelModal] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [delType, setDelType] = useState("");
  const [modifyModal, setModifyModal] = useState(false);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [vehicleEngine, setVehicleEngine] = useState({
    uuid: "",
    manufacturer_engine: "",
    motor_code: "",
    month_end_production: "",
    month_home_production: "",
    motor_code_spaces: "",
    year_end_production: "",
    year_home_production: "",
    month_initial: "",
    month_final: "",
    kw: 0,
    hp: 0,
    vehicle_start_year: "",
    vehicle_end_year: "",
    variants_id: [],
  });

  let errorsObj = {
    manufacturer_engine: "",
    motor_code: "",
    month_initial: "",
    month_final: "",
    vehicle_start_year: "",
    vehicle_end_year: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [modifyImportModal, setModifyImportModal] = useState(false);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  let errorsObjImport = {
    file: "",
  };
  const [errorsImport, setErrorsImport] = useState(errorsObjImport);

  const [viewModal, setViewModal] = useState(false);
  const [VehicleVariantList, setVehicleVariantList] = useState([]);
  const [inputSelctededVariantValue, setInputSelctedVariantValue] =
    useState("");

  const onCloseViewEngineype = () => {
    setViewModal(false);
    setVehicleEngine({
      uuid: "",
      manufacturer_engine: "",
      motor_code: "",
      month_end_production: "",
      month_home_production: "",
      motor_code_spaces: "",
      year_end_production: "",
      year_home_production: "",
      month_initial: "",
      month_final: "",
      vehicle_start_year: "",
      vehicle_end_year: "",
    });
  };

  const onViewEngineType = (e, item) => {
    setViewModal(true);
    setVehicleEngine({
      uuid: item?.uuid,
      manufacturer_engine: item?.manufacturer_engine,
      motor_code: item?.motor_code,
      month_end_production: item?.month_end_production,
      month_home_production: item?.month_home_production,
      motor_code_spaces: item?.motor_code_spaces,
      year_end_production: item?.year_end_production,
      year_home_production: item?.year_home_production,
    });
  };

  useEffect(function () {
    let data = {
      limit: params.limit,
      page: params.page,
      search: "",
      model_id: 0,
      is_reload: true,
    };
    getVehicleSerachVariantList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i]?.id,
            label: response.data[i]?.variants_name,
          });
        }
        setVehicleVariantList(list);
      })
      .catch((err) => {});
  }, []);

  const handleInputSelectedChangeVariantList = (inputSelctededVariantValue) => {
    setInputSelctedVariantValue(inputSelctededVariantValue);

    if (inputSelctededVariantValue.length > 0) {
      const obj = {
        limit: params.limit,
        page: params.page,
        search: inputSelctededVariantValue,
        model_id: 0,
        is_reload: true,
      };

      getVehicleSerachVariantList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i]?.id,
              label: response.data[i]?.variants_name,
            });
          }
          setVehicleVariantList(list);
        })
        .catch((err) => {});
    }
  };

  const handleImportModal = () => {
    setModifyImportModal(true);
  };

  const handleImportCloseModal = () => {
    setModifyImportModal(false);
    setErrorsImport("");
    setFile(null);
  };

  const handleAddModal = () => {
    setModifyModal(true);
  };

  const handleCloseModal = () => {
    setModifyModal(false);
    setVehicleEngine("");
    setErrors("");
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const fetchType = () => {
    let data = { ...params };
    getVehicleEngineList(data)
      .then((response) => {
        setTypeList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };

      fetchType(data);
    },
    [params]
  );

  const onDeleteModalClose = () => {
    setDelModal(false);
  };
  const onEditVehicleType = (e, item) => {
    setModifyModal(true);
    setVehicleEngine({
      uuid: item?.uuid,
      manufacturer_engine: item?.manufacturer_engine,
      motor_code: item?.motor_code,
      month_final: item.month_final,
      month_initial: item.month_initial,
      kw: item.kwFrom,
      hp: item.hpFrom,
      vehicle_start_year: item.year_home_production,
      vehicle_end_year: item.year_end_production,
      variants_id: item.variant_list,
    });
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelType(item.uuid);
    setDelModal(true);
  };

  const onDestroyType = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delType };
    deleteVehicleEngine(data)
      .then((response) => {
        let data = { ...params };

        fetchType(data);
        toast.success(response.data.message);
        setDelType("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleTypeForm = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };
    if (!vehicleEngine.manufacturer_engine) {
      errorObj.manufacturer_engine = `Manufacturer Engine is required.`;
      error = true;
    }

    if (!vehicleEngine.motor_code) {
      errorObj.motor_code = `Motor code is required.`;
      error = true;
    }

    // if (
    //   !vehicleEngine.vehicle_start_year ||
    //   isNaN(vehicleEngine.vehicle_start_year)
    // ) {
    //   errorObj.vehicle_start_year =
    //     "Vehicle start year is required and must be a number.";
    //   error = true;
    // }

    // if (
    //   !vehicleEngine.vehicle_end_year ||
    //   isNaN(vehicleEngine.vehicle_end_year)
    // ) {
    //   errorObj.vehicle_end_year =
    //     "Vehicle end year is required and must be a number.";
    //   error = true;
    // }

    // if (!vehicleEngine.month_initial) {
    //   errorObj.month_initial = `Month initial is required.`;
    //   error = true;
    // }

    // if (!vehicleEngine.month_final) {
    //   errorObj.month_final = `Month final is required.`;
    //   error = true;
    // }

    setErrors(errorObj);
    Utils.focusError();

    if (error) return;

    setIsModifyButtonLoad(true);

    let data = {
      manufacturer_engine: vehicleEngine.manufacturer_engine,
      motor_code: vehicleEngine.motor_code,
      month_final: vehicleEngine.month_final,
      month_initial: vehicleEngine.month_initial,
      kw: vehicleEngine.kw,
      hp: vehicleEngine.hp,
      vehicle_start_year: vehicleEngine.vehicle_start_year,
      vehicle_end_year: vehicleEngine.vehicle_end_year,
      variants_id: vehicleEngine.variants_id,
    };

    let Updatedata = {
      uuid: vehicleEngine?.uuid,
      manufacturer_engine: vehicleEngine.manufacturer_engine,
      motor_code: vehicleEngine.motor_code,
      month_final: vehicleEngine.month_final,
      month_initial: vehicleEngine.month_initial,
      kw: vehicleEngine.kw,
      hp: vehicleEngine.hp,
      vehicle_start_year: vehicleEngine.vehicle_start_year,
      vehicle_end_year: vehicleEngine.vehicle_end_year,
      variants_id: vehicleEngine.variants_id,
    };

    if (vehicleEngine?.uuid) {
      updateVehicleEngine(Updatedata)
        .then((response) => {
          toast.success(response.data.message);
          let data = { ...params };
          fetchType(data);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
          setVehicleEngine({
            uuid: "",
            manufacturer_engine: "",
            motor_code: "",
            month_end_production: "",
            month_home_production: "",
            motor_code_spaces: "",
            year_end_production: "",
            year_home_production: "",
            month_initial: "",
            month_final: "",
            kw: 0,
            hp: 0,
            vehicle_start_year: "",
            vehicle_end_year: "",
            variants_id: "",
          });
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    } else {
      createVehicleEngine(data)
        .then((response) => {
          toast.success(response.data.message);
          let data = { ...params };
          fetchType(data);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
          setVehicleEngine({
            uuid: "",
            manufacturer_engine: "",
            motor_code: "",
            month_end_production: "",
            month_home_production: "",
            motor_code_spaces: "",
            year_end_production: "",
            year_home_production: "",
            month_initial: "",
            month_final: "",
            kw: 0,
            hp: 0,
            vehicle_start_year: "",
            vehicle_end_year: "",
          });
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    }
  };

  const handleImportGroup = (e) => {
    e.preventDefault();

    let error = false;
    let errorObjImport = { ...errorsObjImport };
    if (!file) {
      errorObjImport.file = `File is required.`;
      error = true;
    }

    setErrorsImport(errorObjImport);
    if (error) return;

    setIsModifyButtonLoad(true);
    const formData = new FormData();
    formData.append("files", file);

    importVehicleEngine(formData)
      .then((response) => {
        toast.success(response.data.message);
        let data = { ...params };
        fetchType(data);
        setModifyImportModal(false);
        setIsModifyButtonLoad(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };

  const fetchExportVehicleType = () => {
    let data = { ...params };
    exportVehicleEngine(data)
      .then((response) => {
        const worksheet = XLSX.utils.json_to_sheet(response.data.data);
        const csv = XLSX.utils.sheet_to_csv(worksheet);

        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "VehicleEngine.csv");
        link.style.visibility = "hidden";

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleExport = () => {
    let data = { ...params };
    fetchExportVehicleType(data);
  };

  const handleSelectChange = (selectedOption) => {
    setVehicleEngine({
      ...vehicleEngine,
      vehicle_type_id: selectedOption ? selectedOption.value : "",
    });
  };

  return (
    <div>
      {isLoading ? (
        <></>
      ) : (
        <div>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  {/* <h1 className="page-title">Vehicle Type</h1> */}
                </div>

                <div className="col-sm-6"></div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>

          <section className="content">
            <div className="container-fluid container-custom-height-new">
              <div className="row">
                <div className="col-md-12">
                  <div className="card fuelCard">
                    <div className="card-header">
                      <div
                        className="card-title m-0 float-left"
                        style={{ width: "50%" }}
                      >
                        <input
                          type="text"
                          className="form-control input-search"
                          value={params.search}
                          onChange={(e) => handleParams(e, "search")}
                          placeholder="Search"
                        ></input>
                      </div>

                      <div className="card-title m-0 float-right d-flex">
                        {RolePermission?.findIndex(
                          (e) => e.permission_name === "import_vehicle_engine"
                        ) > -1 && (
                          <button
                            type="button"
                            className="btn  btn-icon-text mr-2"
                            onClick={handleImportModal}
                          >
                            <i className="bi bi-file-earmark-excel-fill"></i>
                            <span className="ms-2">Import</span>
                          </button>
                        )}
                        {RolePermission?.findIndex(
                          (e) => e.permission_name === "export_vehicle_engine"
                        ) > -1 && (
                          <button
                            type="button"
                            className="btn  btn-icon-text mr-2"
                            onClick={handleExport}
                          >
                            <i className="bi bi-file-earmark-excel-fill"></i>
                            <span className="ms-2">Export</span>
                          </button>
                        )}
                        {RolePermission?.findIndex(
                          (e) => e.permission_name === "add_vehicle_engine"
                        ) > -1 && (
                          <button
                            type="button"
                            className="btn  btn-icon-text mr-2"
                            onClick={handleAddModal}
                          >
                            <i className="bi bi-plus-lg"></i>
                            <span className="ms-2">{t("Add")}</span>
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="card-body fuelCardB">
                      <div className="vehicleThBg table-responsive table-responsive-vehicle">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: "1%" }}
                              >
                                #
                              </th>
                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: "10%" }}
                              >
                                Manufacturer Engine
                              </th>

                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: "10%" }}
                              >
                                Motor Code
                              </th>

                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: "0%" }}
                              >
                                {t("action")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {typeList?.map((item, index) => {
                              return (
                                <>
                                  <tr>
                                    <td>{++index}</td>
                                    <td>{item.manufacturer_engine}</td>
                                    <td>{item.motor_code}</td>

                                    <td className="d-flex">
                                      <Dropdown className="iconDropdown">
                                        <Dropdown.Toggle
                                          variant="success"
                                          id="dropdown-basic"
                                          className="iconToggle"
                                        >
                                          <i className="bi bi-three-dots-vertical"></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          {item?.is_import_record === 1 ? (
                                            <>
                                              <Dropdown.Item
                                                href="#/action-1"
                                                className="d-flex "
                                                onClick={(e) =>
                                                  onViewEngineType(e, item)
                                                }
                                              >
                                                <div className="edit-btn">
                                                  <i class="bi bi-eye-fill"></i>
                                                </div>
                                                <span>View</span>
                                              </Dropdown.Item>
                                            </>
                                          ) : (
                                            <>
                                              <Dropdown.Item
                                                href="#/action-1"
                                                className="d-flex"
                                                onClick={(e) =>
                                                  onEditVehicleType(e, item)
                                                }
                                              >
                                                {(item.company_id > 0 ||
                                                  companyId.role_id == 1) &&
                                                  RolePermission?.findIndex(
                                                    (e) =>
                                                      e.permission_name ===
                                                      "modify_vehicle_engine"
                                                  ) > -1 && (
                                                    <a className=""></a>
                                                  )}
                                                <div className="edit-btn">
                                                  <i className="bi bi-pencil"></i>
                                                </div>
                                                <span>Edit</span>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                href="#/action-2"
                                                className="d-flex"
                                                onClick={(e) =>
                                                  onDeleteModalOpen(e, item)
                                                }
                                              >
                                                {(item.company_id > 0 ||
                                                  companyId.role_id == 1) &&
                                                  RolePermission?.findIndex(
                                                    (e) =>
                                                      e.permission_name ===
                                                      "delete_vehicle_engine"
                                                  ) > -1 && (
                                                    <div className="">
                                                      <a className=""></a>
                                                    </div>
                                                  )}
                                                <div className="trash-btn">
                                                  <a className="delete-btn">
                                                    <i className="bi bi-trash3"></i>
                                                  </a>
                                                </div>
                                                <span>Delete</span>
                                              </Dropdown.Item>
                                            </>
                                          )}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                          {typeList && typeList?.length === 0 && (
                            <tr>
                              <td colSpan={7} className={`text-center`}>
                                {" "}
                                {t("no_record_found")}
                              </td>
                            </tr>
                          )}
                        </table>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                </div>
              </div>
            </div>
            <div className="fuelCF">
              <div className="card-footer card-footer-part clearfix card-footer">
                <div className="pagination pagination-sm m-0 float-right">
                  <Pagination
                    activePage={params.page}
                    itemsCountPerPage={parseInt(params.limit)}
                    totalItemsCount={parseInt(totalRecords)}
                    pageRangeDisplayed={5}
                    itemClass={`page-item`}
                    linkClass={`page-link`}
                    onChange={(e) => handleParams(e, "pagination")}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Vehicle Make`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this vehicle engine?`}</p>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDelButtonLoad}
            onClick={onDeleteModalClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>

          {!isDelButtonLoad && (
            <button
              type="button"
              className="btn btn-block bg-red"
              style={{ width: "100px" }}
              onClick={onDestroyType}
            >
              Delete <i class="bi bi-trash3"></i>
            </button>
          )}
          {isDelButtonLoad && (
            <button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
            </button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyModal}>
        <ModalHeader>{t("vehicle_engine")}</ModalHeader>
        <Form method={`post`} onSubmit={handleTypeForm}>
          <ModalBody>
            <div className="row">
              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Manufacturer Engine"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={vehicleEngine.manufacturer_engine}
                        onChange={(e) => {
                          setVehicleEngine({
                            ...vehicleEngine,
                            manufacturer_engine: e.target.value,
                          });
                        }}
                        name={`manufacturer_engine`}
                        maxLength={50}
                        placeholder="Manufacturer Engine"
                      />
                    </div>
                    {errors.manufacturer_engine && (
                      <span className="input-error">
                        {errors.manufacturer_engine}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Motor Code"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={vehicleEngine.motor_code}
                        onChange={(e) => {
                          setVehicleEngine({
                            ...vehicleEngine,
                            motor_code: e.target.value,
                          });
                        }}
                        name={`motor_code`}
                        maxLength={50}
                        placeholder="Motor Code"
                      />
                    </div>
                    {errors.motor_code && (
                      <span className="input-error">{errors.motor_code}</span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Vehicle Start Year"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="number"
                        value={vehicleEngine.vehicle_start_year}
                        onChange={(e) => {
                          setVehicleEngine({
                            ...vehicleEngine,
                            vehicle_start_year: e.target.value,
                          });
                        }}
                        name={`vehicle_start_year`}
                        maxLength={4}
                        placeholder="Vehicle Start Year"
                      />
                    </div>
                    {errors.vehicle_start_year && (
                      <span className="input-error">
                        {errors.vehicle_start_year}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Vehicle End Year"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="number"
                        value={vehicleEngine.vehicle_end_year}
                        onChange={(e) => {
                          setVehicleEngine({
                            ...vehicleEngine,
                            vehicle_end_year: e.target.value,
                          });
                        }}
                        name={`vehicle_end_year`}
                        maxLength={4}
                        placeholder="Vehicle End Year"
                      />
                    </div>
                    {errors.vehicle_end_year && (
                      <span className="input-error">
                        {errors.vehicle_end_year}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Vehicle KW"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="number"
                        value={vehicleEngine.kw}
                        onChange={(e) => {
                          setVehicleEngine({
                            ...vehicleEngine,
                            kw: e.target.value,
                          });
                        }}
                        name={`kw`}
                        maxLength={2}
                        placeholder="0"
                      />
                    </div>
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Vehicle HP"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="number"
                        value={vehicleEngine.hp}
                        onChange={(e) => {
                          setVehicleEngine({
                            ...vehicleEngine,
                            hp: e.target.value,
                          });
                        }}
                        name={`hp`}
                        maxLength={2}
                        placeholder="0"
                      />
                    </div>
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-12">
                <div className="col-md-12">
                  <FormGroup>
                    <Label className="col-form-label ml-2 Label-my form-label form-label">
                      {"Select Vehicle Variants"}
                    </Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <Select
                          className="my-select-v"
                          options={VehicleVariantList}
                          value={VehicleVariantList?.filter((item) =>
                            vehicleEngine?.variants_id?.includes(item.value)
                          )} // Matches selected variants_id with options
                          onChange={(selectedOptions) =>
                            setVehicleEngine({
                              ...vehicleEngine,
                              variants_id: selectedOptions
                                ? selectedOptions.map((option) => option.value)
                                : [],
                            })
                          }
                          isSearchable={true}
                          isMulti={true}
                          placeholder={"Select Vehicle Variant"}
                        />
                      </div>
                    </div>
                  </FormGroup>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-primary"
              disabled={isModifyButtonLoad}
              onClick={handleCloseModal}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-x-lg"></i>
            </button>

            <button
              type="submit"
              class="btn btn-block btn-primary"
              style={{ width: "100px", marginTop: "10px" }}
            >
              Submit <i class="bi bi-check2"></i>
            </button>
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modifyImportModal}>
        <Form method={`post`} onSubmit={handleImportGroup}>
          <ModalHeader>{t("import_vehicle_engine")}</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label">{t("select_file")}</Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="file"
                        onChange={(e) => setFile(e.target.files[0])}
                        name="file"
                        accept=".csv,.xlsx"
                      />
                    </div>
                    {errorsImport.file && (
                      <span className="input-error">{errorsImport.file}</span>
                    )}
                  </div>
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-secondary"
              disabled={isModifyButtonLoad}
              onClick={handleImportCloseModal}
              style={{ width: "100px", marginTop: "10px" }}
            >
              {t("close")}
            </button>
            {!isModifyButtonLoad && (
              <button
                type="submit"
                class="btn btn-block btn-primary"
                style={{ width: "100px", marginTop: "10px" }}
              >
                {t("import")}
              </button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={viewModal} size="lg">
        <div className="border m-3 p-3">
          <ModalHeader className="p-0 pb-2">
            {t("vehicle_type_details")}
          </ModalHeader>

          <ModalBody>
            {/*<div className="row">*/}
            {/*<div className="col-md-6">*/}
            {/*Manufacturer Engine : {vehicleEngine?.manufacturer_engine}*/}
            {/*</div>*/}
            {/*<div className="col-md-6">*/}
            {/*Motor Code : {vehicleEngine?.motor_code}*/}
            {/*</div>*/}
            {/*<div className="col-md-6">*/}
            {/*Month end production : {vehicleEngine?.month_end_production}*/}
            {/*</div>*/}
            {/*<div className="col-md-6">*/}
            {/*Month home production : {vehicleEngine?.month_home_production}*/}
            {/*</div>*/}
            {/*<div className="col-md-6">*/}
            {/*Motor code spaces : {vehicleEngine?.motor_code_spaces}*/}
            {/*</div>*/}
            {/*<div className="col-md-6">*/}
            {/*Year end production : {vehicleEngine?.year_end_production}*/}
            {/*</div>*/}
            {/*<div className="col-md-6">*/}
            {/*Year end production : {vehicleEngine?.year_end_production}*/}
            {/*</div>*/}
            {/*<div className="col-md-6"></div>*/}
            {/*</div>*/}
            <table className="fuelTable">
              <tbody className="row">
                <tr className="col-md-6">
                  <td>Manufacturer Engine</td>
                  <td>:</td>
                  <td>{vehicleEngine?.manufacturer_engine}</td>
                </tr>
                <tr className="col-md-6">
                  <td>Motor Code</td>
                  <td>:</td>
                  <td>{vehicleEngine?.motor_code}</td>
                </tr>
                <tr className="col-md-6">
                  <td>Month end production</td>
                  <td>:</td>
                  <td>{vehicleEngine?.month_end_production}</td>
                </tr>
                <tr className="col-md-6">
                  <td>Month home production</td>
                  <td>:</td>
                  <td>{vehicleEngine?.month_home_production}</td>
                </tr>
                <tr className="col-md-6">
                  <td>Motor code spaces</td>
                  <td>:</td>
                  <td>{vehicleEngine?.motor_code_spaces}</td>
                </tr>
                <tr className="col-md-6">
                  <td>Year end production</td>
                  <td>:</td>
                  <td>{vehicleEngine?.year_end_production}</td>
                </tr>
                <tr className="col-md-6">
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </ModalBody>
          <ModalFooter className="p-0">
            <button
              type="button"
              class="btn btn-block btn-primary"
              disabled={isModifyButtonLoad}
              onClick={onCloseViewEngineype}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-x-lg"></i>
            </button>

            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
              </Button>
            )}
          </ModalFooter>
        </div>
      </Modal>
    </div>
  );
};

export default VehicleEngine;
