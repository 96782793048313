import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import {
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { toast } from "react-toastify";
import Utils from "../../utils";
import { useNavigate } from "react-router-dom";
import {
  actionDelDepollutionOpration,
  actionUpdateDepollutionOpration,
  DepollutionRemoveWastageOpration,
  getDepollutionActualLerCodePdf,
  getDepollutionALLLerCodePdf,
  getDepollutionLerCodeDetails,
  getDepollutionOprationList,
} from "../../services/DepollutionService";
import Dropdown from "react-bootstrap/Dropdown";
import { getCommonSearchList } from "../../services/settingsServices";
import { useTranslation } from "react-i18next";
import moment from "moment";

const DepollutionOpration = () => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [delModal, setDelModal] = useState(false);
  const [delPartId, setDelPartId] = useState("");
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [modifyModal, setModifyModal] = useState(false);
  const [depollutionData, setdepollutionData] = useState({
    uuid: "",
    ler_code_id: "",
    wastege_value: "",
    description: "",
    vehicle_make: "",
    vehicle_model: "",
    vehicle_start_year: "",
    vehicle_year: "",
    vehicle_id: "",
    vehicle_make1: "",
    vehicle_model1: "",
    vehicle_year1: "",
    ler_code: "",
  });

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
    depollution_type: 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [depollutionList, setDepollutionList] = useState([]);
  const [partColumnList, setPartColumnList] = useState([]);
  const [modifyModalLerCode, setModifyModalLerCode] = useState(false);
  const [modifyModalRemoveWaste, setModifyModalRemoveWaste] = useState(false);

  const [lerCodeHisList, setLerCodeHisList] = useState("");
  const [depollutionLerCodeList, setDepollutionLerCodeList] = useState([]);
  const [modifyModalAllLerCode, setModifyModalAllLerCode] = useState(false);
  const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [weightUnits, setWeightUnits] = useState({});

  useEffect(() => {
    const initialWeights = depollutionLerCodeList.reduce((acc, item) => {
      acc[item.id] = item?.wastege_value || "";
      return acc;
    }, {});
    setWeightUnits(initialWeights);
  }, [depollutionLerCodeList]);

  const handleChangeLER = (e, id) => {
    setWeightUnits((prev) => ({
      ...prev,
      [id]: e.target.value,
    }));
  };

  const handleAllLerCodeDetailsOpen = () => {
    setModifyModalAllLerCode(true);
  };

  const handleAllLerCodeDetailsClose = () => {
    setModifyModalAllLerCode(false);
  };

  const handleRemoveWasteOpen = (e, item) => {
    setLerCodeHisList(item);
    setModifyModalRemoveWaste(true);
  };

  const handleRemoveWasteClose = () => {
    setModifyModalRemoveWaste(false);
  };

  const handleLerCodeDetailsOpen = (e, item) => {
    setLerCodeHisList(item);
    setModifyModalLerCode(true);
  };

  const handleLerCodeDetailsClose = () => {
    setModifyModalLerCode(false);
  };

  const handleCloseModal = () => {
    setModifyModal(false);
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };

    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    } else if (type === "depollution_type") {
      paramsObj.depollution_type = parseInt(e.target.value);
    }

    setParams(paramsObj);
  };

  useEffect(() => {
    if (lerCodeHisList?.ler_code_id) {
      let data = {
        ler_code_id: lerCodeHisList?.ler_code_id,
      };

      getDepollutionLerCodeDetails(data)
        .then((response) => {
          setDepollutionLerCodeList(response.data.data);
        })
        .catch((err) => {
          toast.dismiss();

          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [lerCodeHisList]);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "depollution_filter",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setPartColumnList(list);
      })
      .catch((err) => {});
  }, []);

  const fetchPartInventory = () => {
    let data = { ...params };
    getDepollutionOprationList(data)
      .then((response) => {
        setDepollutionList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.dismiss();

        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };
      fetchPartInventory(data);
    },
    [params]
  );

  const handlePartsInventory = () => {
    navigate("/add-vehicle-depollution-operation");
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelPartId(item.uuid);
    setDelModal(true);
  };
  const onDeleteModalClose = () => {
    setDelModal(false);
  };
  const onDestroyPartInventory = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delPartId };
    actionDelDepollutionOpration(data)
      .then((response) => {
        let data = { ...params };
        fetchPartInventory(data);
        toast.success(response.data.message);
        setDelPartId("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const onDepollutionAllLerCodePdf = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { start_date: startDate, end_date: endDate };
    getDepollutionALLLerCodePdf(data)
      .then((response) => {
        setModifyModalAllLerCode(false);
        window.open(response.data.data.url, "_blank");
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const onDepollutionActualLerCodePdf = async (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);

    const loadingToastId = toast.info("Please wait...", { autoClose: false }); // Show toast while loading

    try {
      let data = { company_id: companyId.company_id || 0 };
      const response = await getDepollutionActualLerCodePdf(data);

      if (response?.data?.data?.url) {
        window.open(response.data.data.url, "_blank");
        toast.success("PDF opened successfully!");
      } else {
        toast.error("Failed to get PDF URL");
      }
    } catch (err) {
      toast.error(Utils.getErrorMessage(err));
    } finally {
      toast.dismiss(loadingToastId);
      setIsDelButtonLoad(false);
    }
  };

  const handleEditDepollution = (e) => {
    e.preventDefault();

    let data = {
      uuid: depollutionData?.uuid,
      vehicle_id: depollutionData?.vehicle_id,
      ler_code_id: depollutionData?.ler_code_id,
      wastege_value: depollutionData?.wastege_value,
      description: depollutionData?.description,
    };

    actionUpdateDepollutionOpration(data)
      .then((response) => {
        toast.success(response.data.message);
        setModifyModal(false);
        let data = { ...params };
        fetchPartInventory(data);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleSubmitWaste = (e) => {
    e.preventDefault();

    const updatedData = Object.entries(weightUnits)
      .filter(
        ([id, value]) =>
          value.toString() !==
          depollutionLerCodeList
            .find((item) => item.id.toString() === id)
            ?.wastege_value.toString()
      )
      .map(([id, value]) => ({
        id: Number(id),
        wastege_value: parseFloat(value),
      }));

    let data = {
      wastegeValue: updatedData,
    };

    DepollutionRemoveWastageOpration(data)
      .then((response) => {
        if (response.data.status === 400) {
          toast.error(response.data.message);
          return;
        }

        toast.success(response.data.message);

        setModifyModalRemoveWaste(false);
        let data = {
          ler_code_id: lerCodeHisList?.ler_code_id,
        };
        getDepollutionLerCodeDetails(data)
          .then((response) => {
            setDepollutionLerCodeList(response.data.data);
          })
          .catch((err) => {
            toast.dismiss();

            toast.error(Utils.getErrorMessage(err));
          });
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6"></div>
              <div className="col-sm-6"></div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid container-custom-height-new">
            <div className="row">
              <div className="col-md-12">
                <div className="card fuelCard">
                  <div className="card-header">
                    <div
                      className="card-title m-0 float-left fuelCT"
                      style={{ width: "50%" }}
                    >
                      <div className="row">
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control"
                            value={params.search}
                            onChange={(e) => handleParams(e, "search")}
                            placeholder="Search"
                          ></input>
                        </div>

                        <div className="col-md-6">
                          <select
                            className="form-select"
                            type="select"
                            value={params.depollution_type}
                            onChange={(e) => {
                              handleParams(e, "depollution_type");
                            }}
                          >
                            <option>All</option>
                            {partColumnList?.map((item) => (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="card-title m-0 float-right fuelCT">
                      <button
                        type="button"
                        className="btn-icon-text mr-2 mr-2"
                        onClick={handlePartsInventory}
                      >
                        <i className="bi bi-plus-lg"></i>
                        <span className="ms-2">{t("add_for_depollution")}</span>
                      </button>
                    </div>

                    <div className="card-title m-0 float-right fuelCT">
                      <button
                        type="button"
                        className="btn-icon-text mr-2 mr-2"
                        onClick={handleAllLerCodeDetailsOpen}
                      >
                        <i class="bi bi-file-pdf-fill"></i>
                        <span className="ms-2">{t("downLoad_all_stock")}</span>
                      </button>
                    </div>

                    <div className="card-title m-0 float-right fuelCT">
                      <button
                        type="button"
                        className="btn-icon-text mr-2 mr-2"
                        onClick={onDepollutionActualLerCodePdf}
                      >
                        <i class="bi bi-file-pdf-fill"></i>
                        <span className="ms-2">
                          {t("downLoad_actual_stock")}
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="card-body fuelCardB">
                    <div className="vehicleThBg table-responsive table-responsive-vehicle">
                      <table className="table table-bordered rounded-table table table-striped table-hover">
                        <thead>
                          <tr>
                            <th className="Label-my">{t("LER Code")} </th>
                            <th className="Label-my">{t("ler_name")}</th>
                            <th className="Label-my">{t("actual_value")}</th>
                            <th className="Label-my">{t("wastage_value")}</th>
                            <th className="Label-my">{t("type")}</th>
                            <th className="Label-my">{t("description")}</th>
                            <th className="Label-my">{t("action")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {depollutionList?.map((item) => {
                            return (
                              <>
                                <tr key={item.id}>
                                  <td>{item.ler_code}</td>
                                  <td>{item.ler_name}</td>
                                  <td>{item.item_weight_unit}</td>
                                  <td>{item.wastege_value}</td>
                                  <td>{item.type}</td>
                                  <td>{item.description}</td>
                                  <td className="d-flex">
                                    <Dropdown
                                      className="iconDropdown"
                                      onToggle={(isOpen) => {
                                        if (isOpen) {
                                          // This logic runs when the dropdown is opened
                                          const tableResponsive =
                                            document.querySelector(
                                              ".table-responsive"
                                            );
                                          if (tableResponsive) {
                                            tableResponsive.style.overflowX =
                                              "inherit !important";
                                            tableResponsive.style.overflowY =
                                              "inherit !important";
                                          }
                                        }
                                      }}
                                    >
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                        className="iconToggle"
                                      >
                                        <i className="bi bi-three-dots-vertical"></i>{" "}
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          href="#"
                                          className="d-flex align-items-center"
                                          onClick={(e) =>
                                            handleLerCodeDetailsOpen(e, item)
                                          }
                                        >
                                          <div className="edit-btn">
                                            <i className="bi bi-eye-fill"></i>
                                          </div>
                                          <span className="ms-2">
                                            {t("LER Code")}
                                          </span>
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                          href="#/action-2"
                                          className="d-flex"
                                          onClick={(e) =>
                                            onDeleteModalOpen(e, item)
                                          }
                                        >
                                          <div className="">
                                            <a className="" href="#"></a>
                                          </div>
                                          <div className="trash-btn delete-btn">
                                            <i className="bi bi-trash3"></i>
                                          </div>
                                          <span>{t("delete")}</span>
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                          href="#/action-1"
                                          className="d-flex"
                                          onClick={(e) =>
                                            handleRemoveWasteOpen(e, item)
                                          }
                                        >
                                          <div className="edit-btn">
                                            <i class="bi bi-x-square-fill"></i>{" "}
                                          </div>

                                          <span>{t("remove_waste")}</span>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                        {depollutionList && depollutionList?.length === 0 && (
                          <tr>
                            <td colSpan={7} className={`text-center`}>
                              {" "}
                              {t("no_record_found")}
                            </td>
                          </tr>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fuelCF">
            <div className="pagination pagination-sm m-0 float-right card-footer-part">
              <Pagination
                activePage={params.page}
                itemsCountPerPage={parseInt(params.limit)}
                totalItemsCount={parseInt(totalRecords)}
                pageRangeDisplayed={5}
                itemClass={`page-item`}
                linkClass={`page-link`}
                onChange={(e) => handleParams(e, "pagination")}
              />
            </div>
          </div>
        </section>
      </div>

      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Ler Code`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this ler code?`}</p>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDelButtonLoad}
            onClick={onDeleteModalClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            {t("close")} <i class="bi bi-x-lg"></i>
          </button>

          {!isDelButtonLoad && (
            <button
              type="button"
              className="btn btn-block bg-red"
              style={{ width: "100px" }}
              onClick={onDestroyPartInventory}
            >
              {t("delete")} <i class="bi bi-trash3"></i>
            </button>
          )}
          {isDelButtonLoad && (
            <button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
            </button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyModal}>
        <ModalHeader>{t("depollution_opration")}</ModalHeader>
        <Form method={`post`} onSubmit={handleEditDepollution}>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                {depollutionData?.vehicle_id === 0 ? (
                  <>
                    <ul style={{ listStyleType: "square" }}>
                      <li>
                        {t("vehicle_make")} : {depollutionData?.vehicle_make}
                      </li>
                      <li>
                        {t("vehicle_model")} : {depollutionData?.vehicle_model}
                      </li>
                      <li>
                        {t("vehicle_year")} : {depollutionData?.vehicle_year}
                      </li>
                      <li>
                        {t("ler_code")} : {depollutionData?.ler_code}
                      </li>
                    </ul>
                  </>
                ) : (
                  <>
                    <ul style={{ listStyleType: "square" }}>
                      <li>
                        {t("vehicle_start_year")} :{" "}
                        {depollutionData?.vehicle_start_year}
                      </li>
                      <li>
                        {t("vehicle_make")} : {depollutionData?.vehicle_make1}
                      </li>
                      <li>
                        {t("vehicle_model")} : {depollutionData?.vehicle_model1}
                      </li>
                      <li>
                        {t("ler_code")} : {depollutionData?.ler_code}
                      </li>
                    </ul>
                  </>
                )}
              </div>
              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my form-label form-label">
                    {t("wastage_value")}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search form-control"
                        type="number"
                        value={depollutionData.wastege_value}
                        onChange={(e) => {
                          setdepollutionData({
                            ...depollutionData,
                            wastege_value: e.target.value,
                          });
                        }}
                        name={`wastege_value`}
                        maxLength={50}
                        placeholder="Wastege Value"
                      />
                    </div>
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my form-label form-label">
                    {t("description")}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <textarea
                        className="form-control input-search form-control"
                        type="text"
                        value={depollutionData.description}
                        onChange={(e) => {
                          setdepollutionData({
                            ...depollutionData,
                            description: e.target.value,
                          });
                        }}
                        name={`description`}
                        maxLength={50}
                        placeholder="Description"
                        rows={3}
                      />
                    </div>
                  </div>
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-primary"
              onClick={handleCloseModal}
              style={{ width: "100px", marginTop: "10px" }}
            >
              {t("close")} <i class="bi bi-x-lg"></i>
            </button>
            <button
              type="submit"
              class="btn btn-block btn-primary"
              style={{ width: "100px", marginTop: "10px" }}
            >
              {t("submit")} <i class="bi bi-check2"></i>
            </button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modifyModalLerCode} size="lg">
        <ModalHeader>{t("ler_code_list")}</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <div className="vehicleThBg table-responsive">
                <table className="rounded-table table table-striped table-bordered table-hover verticle-scroll-table">
                  <thead>
                    <tr>
                      <th>{t("LER Code")}</th>
                      <th>{t("ler_name")}</th>
                      <th>{t("actual_value")}</th>
                      <th>{t("old_wastege_value")}</th>
                      <th>{t("wastage_value")}</th>
                      <th>{t("deduct_ler_unit_value")}</th>
                      <th>{t("type")}</th>
                      <th>{t("date")}</th>
                      <th>{t("vehicle")}</th>
                      <th>{t("part")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {depollutionLerCodeList?.length > 0 ? (
                      depollutionLerCodeList?.map((item, index) => (
                        <tr key={index}>
                          <td>{item.ler_code}</td>
                          <td>{item.ler_name}</td>
                          <td>{item.item_weight_unit}</td>
                          <td>{item.old_wastege_value}</td>
                          <td>{item.wastege_value}</td>
                          <td>{item.deduct_ler_unit_value}</td>

                          <td>{item.type}</td>
                          <td>
                            {moment(item.created_at).format("DD/MM/YYYY")}
                          </td>
                          {item.type === "Vehicle" ? (
                            <td>
                              {item.vehicle_start_year} {item.vehicle_make_name}{" "}
                              {item.vehicle_model_name}
                            </td>
                          ) : (
                            <>-</>
                          )}

                          {item.type === "Part" ? (
                            <td>{item.part_name}</td>
                          ) : (
                            <></>
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="100%">{t("no_results_found")}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            onClick={handleLerCodeDetailsClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            {t("close")} <i class="bi bi-x-lg"></i>
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyModalAllLerCode}>
        <ModalHeader>{`All Stock LER Code`}</ModalHeader>
        <Form method={`post`}>
          <ModalBody>
            <div className="row">
              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {t("start_date")}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {t("end_date")}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                  </div>
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-primary"
              disabled={isDelButtonLoad}
              onClick={handleAllLerCodeDetailsClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              {t("close")} <i class="bi bi-x-lg"></i>
            </button>

            <button
              type="button"
              class="btn btn-block btn-primary"
              disabled={isDelButtonLoad}
              onClick={onDepollutionAllLerCodePdf}
              style={{ width: "130px", marginTop: "10px" }}
            >
              {t("downLoad")} <i class="bi bi-file-pdf-fill"></i>
            </button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modifyModalRemoveWaste} size="lg">
        <ModalHeader>{t("ler_code_list")}</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <div className="vehicleThBg table-responsive tableScroll table-scroll-modal">
                <table className="rounded-table table table-striped table-bordered table-hover ">
                  <thead>
                    <tr>
                      <th>{t("ler_name")}</th>
                      <th>{t("LER Code")}</th>
                      <th>{t("wastage_value")}</th>
                    </tr>
                  </thead>

                  <tbody>
                    {depollutionLerCodeList?.length > 0 ? (
                      depollutionLerCodeList?.map((item, index) => (
                        <tr key={index}>
                          <td>{item?.ler_name}</td>
                          <td>{item.ler_code}</td>
                          <td>
                            <input
                              type="text"
                              value={weightUnits[item.id] || ""}
                              onChange={(e) => handleChangeLER(e, item.id)}
                              className="form-control"
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="scrollBrdr">
                        <td colSpan="100%">{t("no_record_found")}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            onClick={handleRemoveWasteClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            {t("close")} <i class="bi bi-x-lg"></i>
          </button>

          <button
            type="button"
            class="btn btn-block btn-primary"
            style={{ width: "100px", marginTop: "10px" }}
            onClick={handleSubmitWaste}
          >
            {t("submit")} <i class="bi bi-check2"></i>
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DepollutionOpration;
