import React, { useState, useEffect, useRef, useCallback } from "react";
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";
import { useReactToPrint } from "react-to-print";
import { ComponentToPrint } from "./ComponentToPrint";

const Print = () => {
  //   const mockPrint = (zpl) => {
  //     console.log("Simulating print...");
  //     console.log("ZPL Code:", zpl);
  //   };

  const [printers, setPrinters] = useState([]);
  const [selectedPrinter, setSelectedPrinter] = useState("");
  const [printData, setPrintData] = useState("");

  const componentRef = useRef(null);

  const handleAfterPrint = useCallback(() => {
    console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = useCallback(() => {
    return Promise.resolve();
  }, []);

  const printFn = useReactToPrint({
    contentRef: componentRef,
    documentTitle: "AwesomeFileName",
    onAfterPrint: handleAfterPrint,
    onBeforePrint: handleBeforePrint,
  });

  const printBarcode = async (serial) => {
    try {
      const browserPrint = new ZebraBrowserPrintWrapper();
      const printers = await browserPrint.getAvailablePrinters();
      console.log("Available Printers:", printers);

      const defaultPrinter = await browserPrint.getDefaultPrinter();
      console.log("Default Printer:", defaultPrinter);

      browserPrint.setPrinter(defaultPrinter || printers[0]);

      const printerStatus = await browserPrint.checkPrinterStatus();
      console.log("Printer Status:", printerStatus);

      if (printerStatus.isReadyToPrint) {
        // Proceed with sending the label
      } else {
        alert(`Printer is not ready: ${printerStatus.errors}`);
      }
    } catch (err) {
      console.error("Print error:", err);
    }
  };

  // const handlePrint = () => {
  //   const serial = "0123456789";
  //   const zpl = `^XA
  //                  ^BY2,2,100
  //                  ^FO20,20^BC^FD${serial}^FS
  //                  ^XZ`;
  //   mockPrint(zpl);
  // };

  const handlePrint = () => {
    const serial = "0123456789";
    printBarcode(serial);
  };

  // useEffect(() => {
  //   fetchPrinters();
  // }, []);

  // const fetchPrinters = async () => {
  //   if (window.BrowserPrint) {
  //     window.BrowserPrint.getLocalDevices((devices) => {
  //       setPrinters(devices.map((d) => d.name));
  //     }, "printer");
  //   } else {
  //     // Fetch Brother printers via QZ Tray
  //     try {
  //       const qz = await import("qz-tray");
  //       await qz.websocket.connect();
  //       const printerList = await qz.printers.find();
  //       setPrinters(printerList);
  //     } catch (err) {
  //       console.error("QZ Tray Error:", err);
  //     }
  //   }
  // };

  // const handlePrints = async () => {
  //   if (!selectedPrinter) return alert("Select a printer first!");

  //   if (window.BrowserPrint) {
  //     // Zebra Printing
  //     let zebraPrinter = new window.BrowserPrint.Printer(selectedPrinter);
  //     zebraPrinter.send(
  //       printData,
  //       () => alert("Printed Successfully"),
  //       (err) => alert("Print Error: " + err)
  //     );
  //   } else {
  //     // Brother Printing via QZ Tray
  //     try {
  //       const qz = await import("qz-tray");
  //       await qz.printers.set(selectedPrinter);
  //       await qz.print([printData]);
  //       alert("Printed Successfully");
  //     } catch (err) {
  //       alert("Brother Print Error: " + err);
  //     }
  //   }
  // };

  // const fetchPrinterIP = async () => {
  //   console.log("window.BrowserPrint", window.BrowserPrint);
  //   if (window.BrowserPrint) {
  //     window.BrowserPrint.getLocalDevices((devices) => {
  //       const printersWithIP = devices.map((d) => ({
  //         name: d.name,
  //         connection: d.connection, // network details or USB
  //       }));
  //       setPrinters(printersWithIP);
  //     }, "printer");
  //   } else {
  //     // Fetch Brother printers via QZ Tray
  //     try {
  //       const qz = await import("qz-tray");
  //       await qz.websocket.connect();
  //       const printerList = await qz.printers.find();
  //       const printersWithDetails = await Promise.all(
  //         printerList.map(async (printerName) => {
  //           const details = await qz.printers.details(printerName);
  //           return {
  //             name: printerName,
  //             port: details.port,
  //             driver: details.driver,
  //           };
  //         })
  //       );
  //       setPrinters(printersWithDetails);
  //     } catch (err) {
  //       console.error("QZ Tray Error:", err);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   fetchPrinterIP();
  // }, []);

  const printRef = useRef();

  const handlePrintLable = () => {
    const printContents = printRef.current.innerHTML;
    const printWindow = window.open("", "", "height=400,width=600");
    printWindow.document.write(
      "<html><head><title>Print Label</title></head><body>"
    );
    printWindow.document.write(printContents);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  useEffect(() => {
    const loadPrinters = async () => {
      if (window.qz) {
        try {
          await window.qz.websocket.connect();
          const printerList = await window.qz.printers.find();
          setPrinters(printerList);
          setSelectedPrinter(printerList[0]);
        } catch (err) {
          console.error("QZ Tray error:", err);
        }
      } else {
        console.error("QZ Tray not loaded.");
      }
    };

    loadPrinters();
    return () => {
      if (window.qz) window.qz.websocket.disconnect();
    };
  }, []);

  const printLabel = async () => {
    if (!selectedPrinter) return alert("No printer selected.");
    try {
      await window.qz.websocket.connect();

      // Example raw ESC/POS commands (you might need to check your printer’s manual)
      const data = [
        { type: "raw", format: "hex", data: "1B40" }, // Initialize printer
        { type: "raw", format: "plain", data: "**** Shipping Label ****\n" },
        {
          type: "raw",
          format: "plain",
          data: "Order #12345\nJohn Doe\n123 Main St\n\n\n\n",
        },
        { type: "raw", format: "hex", data: "0A" }, // Line feed
        { type: "raw", format: "hex", data: "1D5601" }, // Cut paper (optional if supported)
      ];

      await window.qz.print({
        printer: selectedPrinter,
        options: { encoding: "UTF-8" },
        data,
      });

      alert("Label sent to printer!");
    } catch (err) {
      console.error("Print error:", err);
    }
  };

  return (
    <div>
      <h6>Zebra Printer(Mock Test)</h6>
      <button onClick={handlePrint}>Print Zebra</button>
      <h6>Brother</h6>

      <button onClick={printFn}>Print Brother</button>
      <ComponentToPrint ref={componentRef} />

      <div>
        <h2>Zebra & Brother Printer</h2>
        <select
          value={selectedPrinter}
          onChange={(e) => setSelectedPrinter(e.target.value)}
        >
          <option value="">Select Printer</option>
          {printers.map((printer, index) => (
            <option key={index} value={printer}>
              {printer}
            </option>
          ))}
        </select>
        <br />
        <br />
        <input
          type="text"
          value={printData}
          onChange={(e) => setPrintData(e.target.value)}
        />
        <br />
        <br />
      </div>

      <div>
        <select
          value={selectedPrinter}
          onChange={(e) => setSelectedPrinter(e.target.value)}
        >
          <option value="">Select Printer</option>
          {printers.map((printer, index) => (
            <option key={index} value={printer.name}>
              {printer.name}
              {printer.connection
                ? ` (${printer.connection})`
                : printer.port
                ? ` (${printer.port})`
                : ""}
            </option>
          ))}
        </select>
      </div>
    </div>

    // <div>
    //   <div
    //     ref={printRef}
    //     style={{
    //       width: "300px",
    //       height: "150px",
    //       padding: "10px",
    //       border: "1px dashed gray",
    //     }}
    //   >
    //     <h2>Shipping Label</h2>
    //     <p>Name: John Doe</p>
    //     <p>Order #: 12345</p>
    //     <p>Address: 123 Main St, City, ZIP</p>
    //   </div>
    //   <button onClick={handlePrintLable}>Print Label</button>
    // </div>

    // <div className="p-4">
    //   <h2 className="text-xl mb-4">Available Sewoo Printers:</h2>
    //   {printers.length > 0 ? (
    //     <select
    //       value={selectedPrinter}
    //       onChange={(e) => setSelectedPrinter(e.target.value)}
    //       className="border p-2 mb-4"
    //     >
    //       {printers.map((p) => (
    //         <option key={p} value={p}>
    //           {p}
    //         </option>
    //       ))}
    //     </select>
    //   ) : (
    //     <p>No printers detected. Make sure QZ Tray is running.</p>
    //   )}
    //   <button
    //     onClick={printLabel}
    //     className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
    //   >
    //     Print Label
    //   </button>
    // </div>
  );
};

export default Print;
