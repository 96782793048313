import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Layout from "./components/Layout";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./view/Home";
import Login from "./view/auth/Login";
import Signup from "./view/auth/Signup";
import "./asset/style/style.css";
import "./asset/style/custome.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { store } from "./store";
import { Provider } from "react-redux";
import DashboardLayout from "./components/Layout/DashboardLayout";
import { PrivateRoute, PublicRoute } from "./utils/function";
import Dashboard from "./view/dashboard";
import FuelType from "./view/fuelType";
import LerCatalog from "./view/lerCatalog";
import PartType from "./view/PartType";
import PartLocation from "./view/partLocation";
import PartGroup from "./view/partGroup";
import PartSides from "./view/partSides";
import Profile from "./view/profile";
import PartCatalog from "./view/partCatalog";
import AddCatalog from "./view/partCatalog/AddCatalog";
import Subscription from "./view/subscription";
import VehicleCategory from "./view/vehicleCategory";
import VehicleType from "./view/vehicleType";
import VehicleModel from "./view/vehicleModel";
import Vehicle from "./view/vehicle";
import PartsInventory from "./view/PartsInventory";

import VehicleProcess from "./view/vehicleProcess";
import AddParts from "./view/PartsInventory/AddParts";
import AddForParts from "./view/PartsInventory/AddForParts";
import RolePermission from "./view/RolePermission/Roles";
import AddRolePermission from "./view/RolePermission/AddRolePermission";

import User from "./view/user";
import TermsCondition from "./view/TermsCondition";
import PrivacyPolicy from "./view/privacyPolicy";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FAQ from "./view/FAQ";
import Settings from "./view/settings";
import FaqAdmin from "./view/FaqAdmin";
import TermsConditionAdmin from "./view/TermsConditionAdmin";
import PrivacyPolicyAdmin from "./view/privacyPolicyAdmin";
import DepollutionOpration from "./view/DepollutionOpration";
import AddForVehicleDepollution from "./view/DepollutionOpration/AddForVehicleDepollution";
import AddOtherDepollution from "./view/DepollutionOpration/AddOtherDepollution";
import Park from "./view/park";
import Shelf from "./view/shelf";
import Certificate from "./view/certificate";
import Labels from "./view/label";
import WarehouseEntityType from "./view/WarehouseEntityType";
import WareHouseMain from "./view/WareHouse";
import PollPrinting from "./view/PollPrinting";
import PartInventoryDetails from "./view/PartsInventory/PartInventoryDetails";
import AddLabel from "./view/label/AddLabel";
import PartBrand from "./view/PartBrand";
import VehicleMake from "./view/vehicleMake";
import VehicleEngine from "./view/vehicleEngine";
import VehicleVariants from "./view/vehicleVariants";
import VehicleDetails from "./view/vehicle/VehicleDetails";
import PartDetailsVehicle from "./view/vehicle/PartDetailsVehicle";
import PartDetailsPublic from "./view/vehicle/PartDetailsPublic";
import PartVehicledetails from "./view/PartsInventory/PartVehicledetails";
import PartdetailsInventory from "./view/PartsInventory/PartdetailsInventory";
import Dismantle from "./view/dismantle/Dismantle";
import AddDistmental from "./view/dismantle/AddDistmental";
import CompanyEcommerceHash from "./view/CompanyEcommerceHash";
import APIDocumentation from "./view/API Documentation";
import MainPartDetails from "./view/PartsInventory/MainPartDetails";
import PartExportPdfList from "./view/PartsInventory/PartExportPdfList";
import Sales from "./view/sales";
import EcommarceCompany from "./view/EcommarceCompany";
import Refund from "./view/refund";
import SalesDetails from "./view/sales/salesDetails";
import PartLocationAssign from "./view/PartLocationAssign";
import AssociateLocationPart from "./view/PartLocationAssign/AssociateLocationPart";
import AssociateMultiPart from "./view/PartLocationAssign/AssociateMultiPart";
import Print from "./view/Print";
import PrintLabel from "./view/Print/PrintLabel";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Layout>
                  <Home />
                </Layout>
              }
            />
            <Route
              path="/reset-password/:token"
              element={
                <Layout>
                  <Home />
                </Layout>
              }
            />

            <Route
              path="/terms-condition"
              element={
                <Layout>
                  <TermsCondition />
                </Layout>
              }
            />

            <Route
              path="/privacy-policy"
              element={
                <Layout>
                  <PrivacyPolicy />
                </Layout>
              }
            />
            <Route
              path="/faq"
              element={
                <Layout>
                  <FAQ />
                </Layout>
              }
            />

            <Route
              path="/api-documentation"
              element={
                <Layout>
                  {" "}
                  <APIDocumentation />{" "}
                </Layout>
              }
            />

            <Route path="/part-details/:uuid" element={<PartDetailsPublic />} />
            <Route path={"/"} element={<PublicRoute />}>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
            </Route>

            <Route path="/" element={<PrivateRoute />}>
              <Route path={"/"} element={<DashboardLayout />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/fuel-type" element={<FuelType />} />
                <Route path="/part-type" element={<PartType />} />
                <Route path="/part-location" element={<PartLocation />} />
                <Route path="/part-group" element={<PartGroup />} />
                <Route path="/part-sides" element={<PartSides />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/ler-catalog" element={<LerCatalog />} />
                <Route path="/subscription" element={<Subscription />} />
                <Route path="/part-catalog" element={<PartCatalog />} />
                <Route path="/add-part-catalog" element={<AddCatalog />} />
                <Route
                  path="/add-part-catalog/:uuid"
                  element={<AddCatalog />}
                />
                <Route path="/vehicle-category" element={<VehicleCategory />} />
                <Route path="/vehicle-type" element={<VehicleType />} />
                <Route path="/vehicle-model" element={<VehicleModel />} />
                <Route path="/vehicle" element={<Vehicle />} />
                <Route path="/vehicle-process" element={<VehicleProcess />} />
                <Route path="/vehicle/:uuid" element={<Vehicle />} />
                <Route
                  path="/vehicle/details/:uuid"
                  element={<VehicleDetails />}
                />

                <Route
                  path="/part/details/main/:uuid"
                  element={<MainPartDetails />}
                />

                <Route
                  path="/part/export/list"
                  element={<PartExportPdfList />}
                />

                <Route
                  path="/vehicle/details/part/:uuid"
                  element={<PartVehicledetails />}
                />

                <Route
                  path="/part/details/inven/:uuid"
                  element={<PartdetailsInventory />}
                />

                <Route path="/parts-inventory" element={<PartsInventory />} />
                <Route path="/parts-inventory" element={<PartsInventory />} />
                <Route
                  path="/parts-inventory/AddParts"
                  element={<AddParts />}
                />
                <Route
                  path="/parts-inventory/AddParts/:uuid"
                  element={<AddParts />}
                />
                <Route
                  path="/parts-inventory-addparts"
                  element={<AddForParts />}
                />
                <Route
                  path="/parts-inventory/details/:uuid"
                  element={<PartInventoryDetails />}
                />

                <Route path="/Roles" element={<RolePermission />} />
                <Route
                  path="/add-role-permssion"
                  element={<AddRolePermission />}
                />
                <Route
                  path="/add-role-permssion/:uuid"
                  element={<AddRolePermission />}
                />

                <Route
                  path="/part-details-vehicle/:uuid"
                  element={<PartDetailsVehicle />}
                />

                <Route path="/User" element={<User />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/faqs" element={<FaqAdmin />} />
                <Route
                  path="/terms-condition-admin"
                  element={<TermsConditionAdmin />}
                />
                <Route
                  path="/privacy-policy-admin"
                  element={<PrivacyPolicyAdmin />}
                />
                <Route
                  path="/depollution-operation"
                  element={<DepollutionOpration />}
                />

                <Route
                  path="/add-vehicle-depollution-operation"
                  element={<AddForVehicleDepollution />}
                />

                <Route
                  path="/add-vehicle-depollution-operation/:uuid"
                  element={<AddForVehicleDepollution />}
                />

                <Route
                  path="/other-depollution-operation"
                  element={<AddOtherDepollution />}
                />
                <Route path="/park" element={<Park />} />
                <Route path="/shelf" element={<Shelf />} />
                <Route path="/certificate" element={<Certificate />} />
                <Route path="/label" element={<Labels />} />
                <Route path="/print" element={<Print />} />
                <Route path="/printLabel" element={<PrintLabel />} />

                <Route
                  path="/warehouse-entity-type"
                  element={<WarehouseEntityType />}
                />
                <Route path="/warehouse" element={<WareHouseMain />} />
                <Route path="/pool-printing" element={<PollPrinting />} />
                <Route path="/add-label" element={<AddLabel />} />
                <Route path="/brand" element={<PartBrand />} />
                <Route path="/vehicle-make" element={<VehicleMake />} />
                <Route path="/vehicle-engine" element={<VehicleEngine />} />
                <Route path="/vehicle-variants" element={<VehicleVariants />} />
                <Route path="/dismantle" element={<Dismantle />} />
                <Route path="/add-dismental" element={<AddDistmental />} />
                <Route
                  path="/add-dismental/:uuid"
                  element={<AddDistmental />}
                />

                <Route
                  path="/company-ecommerce-hash"
                  element={<CompanyEcommerceHash />}
                />
                <Route path="/sales" element={<Sales />} />
                <Route path="/refund" element={<Refund />} />

                <Route path="/sales-details/:uuid" element={<SalesDetails />} />

                <Route
                  path="/ecommerce-company-lists"
                  element={<EcommarceCompany />}
                />

                <Route
                  path="/part-location-asssign"
                  element={<PartLocationAssign />}
                />

                <Route
                  path="/associate-multi-part"
                  element={<AssociateMultiPart />}
                />

                <Route
                  path="/associate-location-part"
                  element={<AssociateLocationPart />}
                />
              </Route>
            </Route>
          </Routes>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
