import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ConfigDB from "../../config";
import {
  getpartInventoryQuentityDetails,
  getwarHouseEntityList,
  partInvenToryLocationUpdate,
} from "../../services/PartsInventory";
import { toast } from "react-toastify";
import {
  Button,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Barcode from "react-barcode";
import Utils from "../../utils";
import { useTranslation } from "react-i18next";

const PartInventoryDetails = () => {
  const { uuid } = useParams();
  const tableLengthList = ConfigDB.data.dataTableLength;
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });
  const [entityList, setEntityList] = useState([]);

  const [partDetails, setPartDetails] = useState([]);
  const [qrDetails, setQrDetails] = useState(false);

  const [DelPartQRId, setDelPartQRId] = useState("");

  const [selectedEntities, setSelectedEntities] = useState({});

  const [partLocationDetails, setPartLocationDetails] = useState("");

  const randomStrings = `${DelPartQRId.parts_id}-${DelPartQRId.parts_internal_id}`;

  const handleSelectChange = (event, item) => {
    const value = event.target.value;

    setSelectedEntities((prevState) => ({
      ...prevState,
      [item.id]: value,
    }));

    setPartLocationDetails(item);
  };

  const generateRandomString = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 4; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const onQrDetailsOpen = (e, item) => {
    e.preventDefault();
    setDelPartQRId(item);
    setQrDetails(true);
  };

  const handleQrDetailsClose = () => {
    setQrDetails(false);
  };

  useEffect(() => {
    let data = { ...params };
    getwarHouseEntityList(data)
      .then((response) => {
        setEntityList(response.data.data);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, [params]);

  useEffect(() => {
    let data = {
      part_id: uuid,
    };
    getpartInventoryQuentityDetails(data)
      .then((response) => {
        setPartDetails(response.data.data);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, [uuid]);

  const renderOptions = (nodes, level = 0) => {
    return nodes?.map((node) => {
      if (node.children && node.children.length > 0) {
        return (
          <>
            <optgroup
              label={"\u00A0\u00A0".repeat(level) + node.warehouse_name}
              key={node.id}
            />
            {renderOptions(node.children, level + 1)}
          </>
        );
      } else {
        return (
          <option value={node.id} key={node.id}>
            {"\u00A0\u00A0".repeat(level) + node.warehouse_name}
          </option>
        );
      }
    });
  };

  const handleSubmitLocation = (e) => {
    e.preventDefault();

    const payload = {
      part_id: uuid,
      location_list: [
        {
          id: partLocationDetails.id,
          qty: partLocationDetails.qty,
          location_ids: selectedEntities[partLocationDetails?.id],
        },
      ],
    };

    partInvenToryLocationUpdate(payload)
      .then((response) => {
        toast.success(response.data.message);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6"></div>
              <div className="col-sm-6"></div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid container-custom-height-new">
            <div className="row">
              <div className="col-md-12">
                <div className="card fuelCard">
                  <div className="">
                    <div className="card-header"></div>
                    <Form method={`post`} onSubmit={handleSubmitLocation}>
                      <div className="card-body fuelCardB">
                        <div className="vehicleThBg table-responsive">
                          <table className="table table-bordered rounded-table table-striped table-hover">
                            <thead>
                              <tr>
                                <th>Part Name</th>
                                <th>Quantity</th>
                                <th style={{ width: "20%" }}>Entity</th>
                                {selectedEntities && (
                                  <th style={{ width: "10%" }}>Bar Code</th>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {partDetails?.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{item.part_name}</td>

                                    <td>{item.qty}</td>
                                    <td>
                                      <select
                                        className="form-select"
                                        value={
                                          selectedEntities[item.id] ||
                                          item.location_ids ||
                                          ""
                                        }
                                        onChange={(e) =>
                                          handleSelectChange(e, item)
                                        }
                                      >
                                        <option value="" disabled>
                                          Select
                                        </option>
                                        {renderOptions(entityList)}{" "}
                                      </select>
                                    </td>
                                    {selectedEntities && (
                                      <td>
                                        <a
                                          href="#"
                                          onClick={(e) =>
                                            onQrDetailsOpen(e, item)
                                          }
                                        >
                                          <i class="bi bi-qr-code-scan"></i>
                                        </a>
                                      </td>
                                    )}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        <div className="col mt-2">
                          <div className="d-flex justify-content-end nxt-btn">
                            <div className="col">
                              <Button
                                type="button"
                                className="back-btn ms-0 "
                                onClick={() => navigate("/parts-inventory")}
                              >
                                <i class="bi bi-arrow-left"></i> {t("back")}
                              </Button>
                            </div>
                            <button type="submit" className="btn btn-primary">
                              Submit <i class="bi bi-check2"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal isOpen={qrDetails}>
        <ModalHeader>{`QR Details`}</ModalHeader>
        <ModalBody>
          <div className="">
            <div className="">
              <Label className="d-flex justify-content-center">Part Code</Label>
              <div className="svg-barshelf">
                <Barcode value={randomStrings} width={3} height={100} />
              </div>
            </div>

            <div className="">
              <Label className="d-flex justify-content-center">Location</Label>
              <div className="svg-barshelf">
                <Barcode
                  value={`${DelPartQRId.warehouse_name}:${DelPartQRId.location_ids}`}
                  format="CODE128"
                  width={3}
                  height={100}
                  displayValue={true}
                  margin={10}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            className="btn btn-block btn-primary"
            onClick={handleQrDetailsClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PartInventoryDetails;
