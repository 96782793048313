import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import * as XLSX from "xlsx";
import { useLocation, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";

import Select from "react-select";

import { useTranslation } from "react-i18next";
import {
  getCompanySalesList,
  updateCompanySalesStatus,
} from "../../services/SalesServices";
import { getCommonSearchList } from "../../services/settingsServices";
import { getCompanyEcommerceHashList } from "../../services/CompanyEcommerceHashService";
import moment from "moment";

const Sales = () => {
  const location = useLocation();
  const RolePermission = Utils.getRecordForCurrentPath(location);
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
    order_status: 0,
    market_place_id: 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);

  const [salesList, setSalesList] = useState([]);
  const [statusModal, setStatusModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [delpart, setDelPart] = useState("");
  console.log("delpart", delpart);

  const [modifyModal, setModifyModal] = useState(false);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [salesViewData, setSalesViewData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [orderStatusNameList, setOrderStatusNameList] = useState([]);
  const [companyEcommerceList, setCompanyEcommerceList] = useState([]);
  const [salesData, setSalesData] = useState({
    order_status: "",
  });
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  let errorsObj = {
    shipping_company: "",
    shipping_tracking_code: "",
    reason_failed_sale: "",
    add_file: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const [failedData, setFailedData] = useState({
    shipping_company: "",
    shipping_tracking_code: "",
    reason_failed_sale: "",
    add_file: "",
  });

  let errorsObjRefund = {
    refund_value: "",
    refund_type: "",
    refund_reason: "",
  };
  const [errorsRefund, setErrorsRefund] = useState(errorsObjRefund);

  const [refundData, setRefundData] = useState({
    refund_value: "",
    refund_type: "",
    refund_reason: "",
  });

  const [modifyAcceptedModal, setModifyAcceptedModal] = useState(false);
  const [modifyCancelModal, setModifyCancelModal] = useState(false);
  const [modifyRefundModal, setModifyRefundModal] = useState(false);
  const [refundTypeList, setRefundTypeList] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChangeImage = (event) => {
    const files = Array.from(event.target.files);
    const imageFiles = files.filter((file) => file.type.startsWith("image/"));

    if (imageFiles.length === 0) {
      return;
    }

    setSelectedFiles((prevFiles) => [...prevFiles, ...imageFiles]);
  };

  const removeImage = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleCancelClose = () => {
    setModifyCancelModal(false);
  };

  const handleAcceptedClose = () => {
    setModifyAcceptedModal(false);
  };

  const handleRefundClose = () => {
    setModifyRefundModal(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setSelectedFile(file);
    } else {
      event.target.value = null;
    }
  };

  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.value);
  };

  const handleStatusClose = () => {
    setStatusModal(false);
    setDelModal(true);
  };

  const handleStatusOpen = () => {
    setStatusModal(true);
    setDelModal(false);
  };

  const handleCloseModal = () => {
    setModifyModal(false);
  };

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "order_status",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setOrderStatusNameList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "order_status",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setOrderStatusNameList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    let data = { ...params };
    getCompanyEcommerceHashList(data)
      .then((response) => {
        setCompanyEcommerceList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "refund_type",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setRefundTypeList(list);
      })
      .catch((err) => {});
  }, []);

  const handleSelectChangeRefund = (selectedOption) => {
    setRefundData({
      ...refundData,
      refund_type: selectedOption ? selectedOption.value : "",
    });
  };

  const handleSelectChange = (selectedOption) => {
    setSalesData({
      ...salesData,
      order_status: selectedOption ? selectedOption.value : "",
    });
    if (selectedOption?.value === 1) {
      const formData = new FormData();
      formData.append("id", delpart.id);
      formData.append("order_status", 1);
      updateCompanySalesStatus(formData)
        .then((response) => {
          let data = { ...params };

          featchSalesList(data);
          toast.success(response.data.message);

          setStatusModal(false);
          setIsDelButtonLoad(false);
        })
        .catch((err) => {
          setIsDelButtonLoad(false);
          toast.dismiss();
          toast.error(Utils.getErrorMessage(err));
        });
    }

    if (selectedOption?.value === 2) {
      const formData = new FormData();
      formData.append("id", delpart.id);
      formData.append("order_status", 2);
      updateCompanySalesStatus(formData)
        .then((response) => {
          let data = { ...params };

          featchSalesList(data);
          toast.success(response.data.message);
          setStatusModal(false);
          setIsDelButtonLoad(false);
          setModifyAcceptedModal(true);
          setDelModal(false);
        })
        .catch((err) => {
          setIsDelButtonLoad(false);
          toast.dismiss();
          toast.error(Utils.getErrorMessage(err));
        });
    }

    if (selectedOption?.value === 3) {
      setModifyAcceptedModal(true);
      setDelModal(false);
    }

    if (selectedOption?.value === 4) {
      setModifyCancelModal(true);
      setDelModal(false);
    }

    if (selectedOption?.value === 5) {
      setModifyRefundModal(true);
      setDelModal(false);
    }

    if (selectedOption?.value === 6) {
      setModifyCancelModal(true);
      setDelModal(false);
    }

    if (selectedOption?.value === 7) {
      setModifyCancelModal(true);
      setDelModal(false);
    }
  };

  const handleAddAccpetedStatusSubmit = (e) => {
    e.preventDefault();

    if (selectedRadio === "1") {
      const formData = new FormData();
      formData.append("id", delpart.id);
      formData.append("order_status", 3);
      if (delpart?.seller_takes_care_shipping === true) {
        formData.append("shipping_label", selectedFile);
        formData.append("shipping_company", failedData.shipping_company);
        formData.append(
          "shipping_tracking_code",
          failedData.shipping_tracking_code
        );
      }

      selectedFiles.forEach((file) => {
        formData.append("order_images[]", file);
      });
      updateCompanySalesStatus(formData)
        .then((response) => {
          let data = { ...params };

          featchSalesList(data);
          toast.success(response.data.message);
          setDelPart("");
          setModifyAcceptedModal(false);
        })
        .catch((err) => {
          toast.dismiss();
          toast.error(Utils.getErrorMessage(err));
        });
      setModifyAcceptedModal(true);
      setDelModal(false);
    }

    if (selectedRadio === "2") {
      let error = false;
      let errorObj = { ...errorsObj };
      if (!failedData.reason_failed_sale) {
        errorObj.reason_failed_sale = `Reason is required.`;
        error = true;
      }

      setErrors(errorObj);
      Utils.focusError();

      const formData = new FormData();
      formData.append("id", delpart.id);
      formData.append("order_status", 4);
      formData.append("reason_failed_sale", failedData.reason_failed_sale);

      selectedFiles.forEach((file) => {
        formData.append("images[]", file);
      });
      updateCompanySalesStatus(formData)
        .then((response) => {
          let data = { ...params };

          featchSalesList(data);
          toast.success(response.data.message);
          setDelPart("");
          setModifyAcceptedModal(false);
        })
        .catch((err) => {
          toast.dismiss();
          toast.error(Utils.getErrorMessage(err));
        });
      setModifyAcceptedModal(true);
      setDelModal(false);
    }
  };

  const handleAddCancelStatusSubmit = (e) => {
    e.preventDefault();

    let error = false;
    let errorObj = { ...errorsObj };
    if (!failedData.reason_failed_sale) {
      errorObj.reason_failed_sale = `Reason is required.`;
      error = true;
    }

    setErrors(errorObj);
    Utils.focusError();

    if (error) return;
    const formData = new FormData();
    formData.append("id", delpart.id);
    formData.append("order_status", salesData?.order_status);
    formData.append("reason_failed_sale", failedData.reason_failed_sale);

    updateCompanySalesStatus(formData)
      .then((response) => {
        let data = { ...params };

        featchSalesList(data);
        toast.success(response.data.message);
        setDelPart("");
        setModifyCancelModal(false);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
    setModifyCancelModal(true);
    setDelModal(false);
  };

  const handleAddRefundStatusSubmit = (e) => {
    e.preventDefault();

    let error = false;
    let errorObj = { ...errorsObj };

    if (refundData?.refund_type === 2) {
      if (!refundData.refund_value) {
        errorObj.refund_value = `Refund value is required.`;
        error = true;
      }
    }

    if (!refundData.refund_type) {
      errorObj.refund_type = `Refund type is required.`;
      error = true;
    }

    if (!refundData.refund_reason) {
      errorObj.refund_reason = `Reason is required.`;
      error = true;
    }

    setErrorsRefund(errorObj);
    Utils.focusError();

    if (error) return;
    const formData = new FormData();
    formData.append("id", delpart.id);
    formData.append("order_status", 5);
    formData.append("refund_type", refundData.refund_type);
    formData.append("refund_reason", refundData.refund_reason);
    formData.append("refund_value", refundData.refundData || 0);

    updateCompanySalesStatus(formData)
      .then((response) => {
        let data = { ...params };

        featchSalesList(data);
        toast.success(response.data.message);
        setDelPart("");
        setModifyRefundModal(false);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
    setModifyRefundModal(true);
    setDelModal(false);
  };

  useEffect(() => {
    if (delpart) {
      setSalesData((prevSalesData) => ({
        ...prevSalesData, // Keep previous values
        order_status: delpart?.order_status, // Update only order_status
      }));
    }
  }, [delpart]);

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    } else if (type === "order_status") {
      paramsObj.order_status = parseInt(e.target.value);
    } else if (type === "market_place_id") {
      paramsObj.market_place_id = parseInt(e.target.value);
    }
    setParams(paramsObj);
  };

  const featchSalesList = () => {
    let data = { ...params };
    getCompanySalesList(data)
      .then((response) => {
        setSalesList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };

      featchSalesList(data);
    },
    [params]
  );

  const onDeleteModalClose = () => {
    setDelModal(false);
  };

  const onViewSales = (e, item) => {
    setModifyModal(true);
    setSalesViewData(item);
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelPart(item);
    setDelModal(true);
  };

  // const onDestroyPartType = (e) => {
  //   e.preventDefault();
  //   setIsDelButtonLoad(true);
  //   let data = { id: delpart.id, order_status: salesData?.order_status };
  //   updateCompanySalesStatus(data)
  //     .then((response) => {
  //       let data = { ...params };

  //       featchSalesList(data);
  //       toast.success(response.data.message);
  //       setDelPart("");
  //       setStatusModal(false);
  //       setIsDelButtonLoad(false);
  //     })
  //     .catch((err) => {
  //       setIsDelButtonLoad(false);
  //       toast.dismiss();
  //       toast.error(Utils.getErrorMessage(err));
  //     });
  // };

  return (
    <div>
      {isLoading ? (
        // <div className="text-center pageLoader">
        //   <div className="spinner-border" role="status">
        //     <span className="visually-hidden">Loading...</span>
        //   </div>
        // </div>
        <div className="skeleton-loader p-3 d-none">
          <div className="card">
            <div className="card-header">
              <table className="table rounded-table table-striped table-hover">
                <thead>
                  <tr>
                    <th
                      style={{ width: 10, borderTop: "0", borderBottom: "0" }}
                    >
                      <div className="skeleton"></div>
                    </th>
                    <th style={{ borderTop: "0", borderBottom: "0" }}>
                      <div className="skeleton"></div>
                    </th>
                    <th style={{ borderTop: "0", borderBottom: "0" }}></th>
                    <th
                      class="d-flex"
                      style={{ borderTop: "0", borderBottom: "0" }}
                    >
                      <div class="skeleton me-1"></div>
                      <div class="skeleton me-1"></div>
                      <div class="skeleton"></div>
                    </th>
                    <th
                      style={{ width: 10, borderTop: "0", borderBottom: "0" }}
                    >
                      <div className="skeleton"></div>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="card-body">
              <div className="vehicleThBg table-responsive">
                <table className="table table-bordered rounded-table table table-striped table-hover">
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: "#c0c0c0" }}>
                        <div className="skeleton"></div>
                      </th>
                      <th style={{ backgroundColor: "#c0c0c0" }}>
                        <div className="skeleton"></div>
                      </th>
                      <th style={{ backgroundColor: "#c0c0c0" }}>
                        <div className="skeleton"></div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {salesList?.map((_, index) => (
                      <tr key={index}>
                        <td>
                          <div className="skeleton"></div>
                        </td>
                        <td>
                          <div className="skeleton"></div>
                        </td>
                        <td>
                          <div className="skeleton"></div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer">
              <div className="skeleton col-lg-3 float-right"></div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  {/* <h1 className="page-title">Part Type</h1> */}
                </div>
                <div className="col-sm-6"></div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>
          <section className="content">
            <div className="container-fluid container-custom-height-new ">
              <div className="row">
                <div className="col-md-12">
                  <div className="card fuelCard">
                    <div className="card-header">
                      <div
                        className="card-title m-0 float-left"
                        style={{ width: "50%" }}
                      >
                        <div className="row">
                          <div className="col-md-4">
                            <input
                              type="text"
                              className="form-control"
                              value={params.search}
                              onChange={(e) => handleParams(e, "search")}
                              placeholder="Search"
                            ></input>
                          </div>

                          <div className="col-md-4">
                            <select
                              className="form-select"
                              type="select"
                              value={params.order_status}
                              onChange={(e) => {
                                handleParams(e, "order_status");
                              }}
                            >
                              <option>All</option>
                              {orderStatusNameList?.map((item) => (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="col-md-4">
                            <select
                              className="form-select"
                              type="select"
                              value={params.market_place_id}
                              onChange={(e) => {
                                handleParams(e, "market_place_id");
                              }}
                            >
                              <option>All</option>
                              {companyEcommerceList?.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.ecommerce_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="vehicleThBg table-responsive table-responsive-vehicle">
                        <table className="table table-bordered rounded-table table-striped table-hover">
                          <thead>
                            <tr>
                              <th style={{ width: "1%" }}>#</th>
                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: "10%" }}
                              >
                                Invoice Number{" "}
                              </th>

                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: "10%" }}
                              >
                                Quantity{" "}
                              </th>

                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: "10%" }}
                              >
                                Price{" "}
                              </th>

                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: "10%" }}
                              >
                                Created Date{" "}
                              </th>

                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ width: "10%" }}
                              >
                                Order Status Name{" "}
                              </th>

                              <th
                                className="col-form-label ml-2 Label-my form-label form-label"
                                style={{ textAlign: "center", width: "0%" }}
                              >
                                {t("action")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {salesList?.map((item, index) => (
                              <tr key={item.id}>
                                {" "}
                                <td>{++index}</td>
                                <td>{item?.invoice_number}</td>
                                <td>{item?.num_products}</td>
                                <td>{item?.total_value_products} €</td>
                                <td>
                                  {item?.created_at
                                    ? moment(item?.created_at).format(
                                        "DD/MM/YYYY"
                                      )
                                    : "-"}
                                </td>
                                <td>{item?.order_status_name}</td>
                                <td>
                                  <div className="d-flex">
                                    <Dropdown
                                      className="iconDropdown"
                                      onToggle={(isOpen) => {
                                        if (isOpen) {
                                          const tableResponsive =
                                            document.querySelector(
                                              ".table-responsive"
                                            );
                                          if (tableResponsive) {
                                            tableResponsive.style.overflowX =
                                              "inherit !important";
                                            tableResponsive.style.overflowY =
                                              "inherit !important";
                                          }
                                        }
                                      }}
                                    >
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                        className="iconToggle"
                                      >
                                        <i className="bi bi-three-dots-vertical"></i>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          href="#/action-1"
                                          className="d-flex"
                                          onClick={() =>
                                            navigate(
                                              `/sales-details/${item.id}`,
                                              { state: item }
                                            )
                                          }
                                        >
                                          <a className="" href="#"></a>
                                          <div className="edit-btn">
                                            <i class="bi bi-eye"></i>{" "}
                                          </div>
                                          <span>Details</span>
                                        </Dropdown.Item>
                                        {/* {item?.order_status == 1 && ( */}
                                        <Dropdown.Item
                                          href="#/action-2"
                                          className="d-flex"
                                          onClick={(e) =>
                                            onDeleteModalOpen(e, item)
                                          }
                                        >
                                          <a className="" href="#"></a>

                                          <div className="edit-btn">
                                            <i class="bi bi-arrow-repeat"></i>
                                          </div>
                                          <span>Status Change</span>
                                        </Dropdown.Item>
                                        {/* )} */}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </td>
                              </tr>
                            ))}
                            {salesList && salesList.length === 0 && (
                              <tr>
                                <td colSpan={4} className="text-center">
                                  Record Not Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer card-footer-part clearfix">
              <div className="pagination pagination-sm m-0 float-right">
                <Pagination
                  activePage={params.page}
                  itemsCountPerPage={parseInt(params.limit)}
                  totalItemsCount={parseInt(totalRecords)}
                  pageRangeDisplayed={5}
                  itemClass={`page-item`}
                  linkClass={`page-link`}
                  onChange={(e) => handleParams(e, "pagination")}
                />
              </div>
            </div>
          </section>
        </div>
      )}
      <Modal isOpen={delModal} size="lg">
        <ModalHeader>{`Status Change`}</ModalHeader>
        <ModalBody>
          <div className="col-md-12">
            <FormGroup>
              <Label className="col-form-label ml-2 Label-my form-label form-label">
                {"Order Status"}
              </Label>
              <div className="col-xl-12">
                <div className="input-div SelectV">
                  <Select
                    className="my-Select"
                    options={orderStatusNameList}
                    value={orderStatusNameList.find(
                      (item) => item.value == salesData.order_status
                    )}
                    onChange={handleSelectChange}
                    placeholder={"Select Order Status"}
                    isSearchable={true}
                    isClearable={true}
                  />
                </div>
              </div>
            </FormGroup>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDelButtonLoad}
            onClick={onDeleteModalClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyModal}>
        <ModalHeader>Sales Details</ModalHeader>

        <ModalBody>
          <div className="row">
            <div className="col-md-6">
              <h6>Product Name : {salesViewData?.product_name}</h6>
            </div>
            <div className="col-md-6">
              <h6>Quantity : {salesViewData?.quantity}</h6>
            </div>
            <div className="col-md-6">
              <h6>Order Status Name : {salesViewData?.order_status_name}</h6>
            </div>
            <div className="col-md-6">
              <h6>price : {salesViewData?.price}</h6>
            </div>
            <div className="col-md-6">
              <h6>Customer Name : {salesViewData?.end_customer_name}</h6>
            </div>
            <div className="col-md-6">
              <h6>Customer Phone : {salesViewData?.end_customer_phone}</h6>
            </div>
            <div className="col-md-12">
              <h6>Customer Email : {salesViewData?.end_customer_email}</h6>
            </div>{" "}
          </div>
        </ModalBody>

        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            onClick={handleCloseModal}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyAcceptedModal} size="lg">
        {/* <ModalHeader>{`Status Change`}</ModalHeader> */}
        <ModalBody>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadio"
                  id="flexRadio1"
                  value="1"
                  checked={selectedRadio === "1"}
                  onChange={handleRadioChange}
                />
                <label className="form-check-label" htmlFor="flexRadio1">
                  Add Shipping Details
                </label>
              </div>

              <div className="col-md-6">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadio"
                  id="flexRadio2"
                  value="2"
                  checked={selectedRadio === "2"}
                  onChange={handleRadioChange}
                />
                <label className="form-check-label" htmlFor="flexRadio2">
                  Cancelled
                </label>
              </div>

              {selectedRadio === "1" && (
                <>
                  {delpart?.seller_takes_care_shipping === true ? (
                    <>
                      <div className="col-md-12" style={{ marginTop: "12px" }}>
                        <div className="p-4 border rounded-lg">
                          <input
                            type="file"
                            accept="application/pdf"
                            onChange={handleFileChange}
                            className="border p-2 rounded-md"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my">
                            {"Shipping Company"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="text"
                                value={failedData.shipping_company}
                                onChange={(e) => {
                                  setFailedData({
                                    ...failedData,
                                    shipping_company: e.target.value,
                                  });
                                }}
                                name={`shipping_company`}
                                maxLength={50}
                                placeholder="Shipping Company"
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my">
                            {"Shipping tracking code"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="text"
                                value={failedData.shipping_tracking_code}
                                onChange={(e) => {
                                  setFailedData({
                                    ...failedData,
                                    shipping_tracking_code: e.target.value,
                                  });
                                }}
                                name={`shipping_tracking_code`}
                                maxLength={50}
                                placeholder="Shipping tracking code"
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </div>
                      <div className="col-md-12">
                        <div className="p-4 border rounded-lg">
                          <input
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={handleFileChangeImage}
                            className="border p-2 rounded-md"
                          />

                          {selectedFiles.length > 0 && (
                            <div className="mt-4 grid grid-cols-3 gap-2">
                              {selectedFiles.map((file, index) => (
                                <div key={index} className="relative">
                                  <img
                                    src={URL.createObjectURL(file)}
                                    alt={`preview-${index}`}
                                    style={{ width: "100px" }}
                                    className="object-cover rounded-lg shadow-md"
                                  />
                                  <button
                                    onClick={() => removeImage(index)}
                                    className="absolute top-0 right-0 bg-red-500 text-white text-xs p-1 rounded-full"
                                  >
                                    ✖
                                  </button>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-md-12" style={{ marginTop: "10px" }}>
                        <a
                          href={delpart.shipping_label}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="bi bi-file-earmark-pdf"></i> View PDF
                        </a>
                      </div>

                      <div className="col-md-12" style={{ marginTop: "10px" }}>
                        <div className="p-4 border rounded-lg">
                          <input
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={handleFileChangeImage}
                            className="border p-2 rounded-md"
                          />

                          {selectedFiles.length > 0 && (
                            <div className="mt-4 grid grid-cols-3 gap-2">
                              {selectedFiles.map((file, index) => (
                                <div key={index} className="relative">
                                  <img
                                    src={URL.createObjectURL(file)}
                                    alt={`preview-${index}`}
                                    style={{ width: "100px" }}
                                    className="object-cover rounded-lg shadow-md"
                                  />
                                  <button
                                    onClick={() => removeImage(index)}
                                    className="absolute top-0 right-0 bg-red-500 text-white text-xs p-1 rounded-full"
                                  >
                                    ✖
                                  </button>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}

              {selectedRadio === "2" && (
                <>
                  <div className="col-md-12">
                    <FormGroup>
                      <Label className="col-form-label ml-2 Label-my">
                        {"Reason Failed Sale"}
                      </Label>
                      <div className="col-xl-12">
                        <div className="input-div">
                          <input
                            className="form-control input-search"
                            type="text"
                            value={failedData.reason_failed_sale}
                            onChange={(e) => {
                              setFailedData({
                                ...failedData,
                                reason_failed_sale: e.target.value,
                              });
                            }}
                            name={`reason_failed_sale`}
                            maxLength={50}
                            placeholder="Reason Failed Sale"
                          />
                          {errors.reason_failed_sale && (
                            <span className="input-error">
                              {errors.reason_failed_sale}
                            </span>
                          )}
                        </div>
                      </div>
                    </FormGroup>
                  </div>

                  <div className="col-md-12">
                    <div className="p-4 border rounded-lg">
                      <input
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleFileChangeImage}
                        className="border p-2 rounded-md"
                      />

                      {selectedFiles.length > 0 && (
                        <div className="mt-4 grid grid-cols-3 gap-2">
                          {selectedFiles.map((file, index) => (
                            <div key={index} className="relative">
                              <img
                                src={URL.createObjectURL(file)}
                                alt={`preview-${index}`}
                                style={{ width: "100px" }}
                                className="object-cover rounded-lg shadow-md"
                              />
                              <button
                                onClick={() => removeImage(index)}
                                className="absolute top-0 right-0 bg-red-500 text-white text-xs p-1 rounded-full"
                              >
                                ✖
                              </button>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            onClick={handleAcceptedClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>

          <button
            type="button"
            className="btn btn-block btn-primary"
            style={{ width: "139px" }}
            onClick={handleAddAccpetedStatusSubmit}
          >
            Save<i class="bi bi-arrow-repeat"></i>
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyCancelModal} size="lg">
        {/* <ModalHeader>{`Status Change`}</ModalHeader> */}
        <ModalBody>
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Reason"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={failedData.reason_failed_sale}
                        onChange={(e) => {
                          setFailedData({
                            ...failedData,
                            reason_failed_sale: e.target.value,
                          });
                        }}
                        name={`reason_failed_sale`}
                        maxLength={50}
                        placeholder="Reason"
                      />
                      {errors.reason_failed_sale && (
                        <span className="input-error">
                          {errors.reason_failed_sale}
                        </span>
                      )}
                    </div>
                  </div>
                </FormGroup>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            onClick={handleCancelClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>

          <button
            type="button"
            className="btn btn-block btn-primary"
            style={{ width: "139px" }}
            onClick={handleAddCancelStatusSubmit}
          >
            Save<i class="bi bi-arrow-repeat"></i>
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyRefundModal} size="lg">
        <ModalBody>
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my form-label form-label">
                    {"Refund Type"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div SelectV">
                      <Select
                        className="my-Select"
                        options={refundTypeList}
                        value={refundTypeList.find(
                          (item) => item.value == refundData.refund_type
                        )}
                        onChange={handleSelectChangeRefund}
                        placeholder={"Select Refund Type"}
                        isSearchable={true}
                        isClearable={true}
                      />
                    </div>
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Reason"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={refundData.refund_reason}
                        onChange={(e) => {
                          setRefundData({
                            ...refundData,
                            refund_reason: e.target.value,
                          });
                        }}
                        name={`refund_reason`}
                        maxLength={50}
                        placeholder="Reason"
                      />
                      {errorsRefund.refund_reason && (
                        <span className="input-error">
                          {errorsRefund.refund_reason}
                        </span>
                      )}
                    </div>
                  </div>
                </FormGroup>
              </div>
              {refundData?.refund_type === 2 ? (
                <div className="col-md-6">
                  <FormGroup>
                    <Label className="col-form-label ml-2 Label-my">
                      {"Refund Value"}
                    </Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <input
                          className="form-control input-search"
                          type="text"
                          value={refundData.refund_value}
                          onChange={(e) => {
                            setRefundData({
                              ...refundData,
                              refund_value: e.target.value,
                            });
                          }}
                          name={`refund_value`}
                          maxLength={50}
                          placeholder="Refund Value"
                        />
                        {errorsRefund.refund_value && (
                          <span className="input-error">
                            {errorsRefund.refund_value}
                          </span>
                        )}
                      </div>
                    </div>
                  </FormGroup>
                </div>
              ) : (
                <div className="col-md-6"></div>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            onClick={handleRefundClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>

          <button
            type="button"
            className="btn btn-block btn-primary"
            style={{ width: "139px" }}
            onClick={handleAddRefundStatusSubmit}
          >
            Save<i class="bi bi-arrow-repeat"></i>
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Sales;
