import React, { useEffect, useState } from "react";
import { getVehicleList } from "../../services/PartsInventory";
import ConfigDB from "../../config";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  actionAddDepollutionOpration,
  getLerCodeHistoryList,
  getLerCodeList,
} from "../../services/DepollutionService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useTranslation } from "react-i18next";

const AddForVehicleDepollution = () => {
  const tableLengthList = ConfigDB?.data?.dataTableLength;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [vehiclePartsData, setVehiclePartsData] = useState({
    vehicle_id: "",
    ler_code_id: "",
    wastege_value: "",
    description: "",
  });
  const [inputValue, setInputValue] = useState("");
  const [VehicleModelList, setVehicleModelList] = useState([]);
  const [LerCatalogList, setLerCatalogList] = useState([]);
  const [LerCatalogHistoryList, setLerCatalogHistoryList] = useState([]);
  const [addedParts, setAddedParts] = useState([]);
  let errorsObj = {
    vehicle_id: "",
    ler_code_id: "",
    wastege_value: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [selectedOption, setSelectedOption] = useState("1");
  const [partHistoryQtyModal, setPartHistoryQtyModal] = useState(false);
  const [weightUnits, setWeightUnits] = useState({});
  const [search, setSearch] = useState("");

  useEffect(() => {
    const initialWeights = LerCatalogList.reduce((acc, item) => {
      acc[item.id] = item?.unit_value || "";
      return acc;
    }, {});
    setWeightUnits(initialWeights);
  }, [LerCatalogList]);

  const handleChangeLER = (e, id) => {
    setWeightUnits((prev) => ({
      ...prev,
      [id]: e.target.value,
    }));
  };

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const dataWaste = addedParts?.map((item) => item?.unit_value);

  const ontpartHistoryQtyDetailsOpen = () => {
    setPartHistoryQtyModal(true);
  };

  const onpartHistoryQtyDetailsClose = () => {
    setPartHistoryQtyModal(false);
  };

  const sumWastageValue = dataWaste?.reduce((acc, curr) => {
    const num = Number(curr);
    return !isNaN(num) ? acc + num : acc;
  }, 0);

  const handleAddClick = (id, ler_code, unit_value, ler_name) => {
    const isAdded = addedParts.some((part) => part.id === id);

    if (isAdded) {
      setAddedParts((prev) => prev.filter((part) => part.id !== id));
    } else {
      setAddedParts((prev) => [
        ...prev,
        { id, ler_code, unit_value: weightUnits[id] || "", ler_name },
      ]);
    }
  };

  const filteredPartAssignList = LerCatalogList.filter(
    (item) => !addedParts.some((addedPart) => addedPart?.id === item?.id)
  );

  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    let error = false;
    let errorObj = { ...errorsObj };

    setErrors(errorObj);
    Utils.focusError();

    if (error) return;

    const ler_code_id = addedParts?.map((item) => ({
      id: item.id,
      unit_value: item?.unit_value,
    }));

    let data = {
      vehicle_id: selectedOption == "1" ? vehiclePartsData.vehicle_id : 0,
      ler_code_id: ler_code_id,
      wastege_value: sumWastageValue,
      description: vehiclePartsData?.description,
    };

    actionAddDepollutionOpration(data)
      .then((response) => {
        toast.success(response.data.message);
        navigate("/depollution-operation");
      })
      .catch((err) => {
        setLoading(false);
        toast.dismiss();

        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      if (vehiclePartsData?.ler_code_id) {
        let data = {
          id: vehiclePartsData?.ler_code_id,
        };
        getLerCodeHistoryList(data)
          .then((response) => {
            setLerCatalogHistoryList(response.data.data);
          })
          .catch((err) => {
            setLoading(false);
            toast.dismiss();

            toast.error(Utils.getErrorMessage(err));
          });
      }
    },
    [vehiclePartsData]
  );
  useEffect(() => {
    if (selectedOption === "2") {
      getLerCodeList({
        search: search ? search : "",
        vehicle_id: 0,
        limit: tableLengthList[0],
        page: 1,
      })
        .then((response) => {
          setLerCatalogList(response.data.data);
        })
        .catch((err) => {});
    } else if (selectedOption === "1") {
      if (!vehiclePartsData?.vehicle_id) {
        console.log("Clearing list as vehicle_id is missing");
        setLerCatalogList([]);
      } else {
        getLerCodeList({
          search: search ? search : "",
          vehicle_id: vehiclePartsData.vehicle_id,
          limit: tableLengthList[0],
          page: 1,
        })
          .then((response) => {
            setLerCatalogList(response.data.data);
          })
          .catch((err) => {});
      }
    }
  }, [selectedOption, vehiclePartsData?.vehicle_id, search]);

  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };
    getVehicleList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: `${response.data[i].vehicle_model_name} (${response.data[i].vehicle_year}) (${response.data[i].vehicle_internal_id})`,
            vehicle_year: response.data[i].vehicle_year,
            vehicle_make: response.data[i].vehicle_make,
            vehicle_model: response.data[i].vehicle_model,
          });
        }
        setVehicleModelList(list);
      })
      .catch((err) => {});
  }, []);

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);

    const obj = {
      search: inputValue,
      limit: tableLengthList[0],
      page: 1,
    };

    getVehicleList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: `${response.data[i].vehicle_model_name} (${response.data[i].vehicle_year}) (${response.data[i].vehicle_internal_id})`,
            vehicle_year: response.data[i].vehicle_year,
            vehicle_make: response.data[i].vehicle_make,
            vehicle_model: response.data[i].vehicle_model,
          });
        }

        setVehicleModelList(list);
      })
      .catch((err) => {
        console.error("Error fetching vehicle model list", err);
      });
  };

  const handleSelectChange = (selectedOption) => {
    setVehiclePartsData({
      ...vehiclePartsData,
      vehicle_id: selectedOption ? selectedOption.value : "",
    });
  };

  return (
    <div>
      <div>
        <div>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6"></div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"></li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-primary">
                    <Form method={`post`} onSubmit={handleSubmit}>
                      <div className="card-body">
                        <div className="row">
                          <div className="d-flex ms-3">
                            <div>
                              <label>
                                <input
                                  type="radio"
                                  name="options"
                                  value="1"
                                  checked={selectedOption === "1"}
                                  onChange={handleChange}
                                  className="me-1 ms-1"
                                />
                                {t("vehicle_parts")}
                              </label>
                            </div>
                            <div className="ms-2">
                              <label>
                                <input
                                  type="radio"
                                  name="options"
                                  value="2"
                                  checked={selectedOption === "2"}
                                  onChange={handleChange}
                                  className="me-1"
                                />
                                {t("other")}
                              </label>
                            </div>
                          </div>

                          {selectedOption == "1" && (
                            <>
                              <div className="row justify-content-start">
                                <div className="col-md-5">
                                  <div className="col-xl-12">
                                    <Label className="col-form-label ml-2 Label-my form-label form-label">
                                      {t("select_vehicle")}
                                    </Label>
                                    <Select
                                      className="my-Select"
                                      options={VehicleModelList}
                                      value={VehicleModelList.find(
                                        (item) =>
                                          item.value ==
                                          vehiclePartsData.vehicle_id
                                      )}
                                      onChange={handleSelectChange}
                                      placeholder={"Select Vehicle"}
                                      isSearchable={true}
                                      onInputChange={handleInputChange}
                                      inputValue={inputValue}
                                      isClearable={true}
                                    />
                                  </div>
                                </div>
                                {vehiclePartsData?.vehicle_id && (
                                  <div
                                    className="col-md-2"
                                    style={{
                                      marginTop: "48px",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={ontpartHistoryQtyDetailsOpen}
                                    >
                                      {t("vehicle_history")}
                                      <i class="bi bi-car-front-fill ms-1"></i>
                                    </button>
                                  </div>
                                )}

                                <div className="col-md-5">
                                  <FormGroup>
                                    <Label className="col-form-label ml-2 Label-my form-label form-label">
                                      {t("wastage_value")}
                                    </Label>
                                    <div className="col-xl-12">
                                      <div className="input-div">
                                        <input
                                          className="form-control input-search"
                                          type="number"
                                          value={
                                            vehiclePartsData.wastege_value
                                              ? vehiclePartsData.wastege_value
                                              : sumWastageValue
                                          }
                                          onChange={(e) => {
                                            setVehiclePartsData({
                                              ...vehiclePartsData,
                                              wastege_value: e.target.value,
                                            });
                                          }}
                                          name={`wastege_value`}
                                          maxLength={100}
                                          placeholder="Wastage Value"
                                          disabled={
                                            vehiclePartsData?.vehicle_id
                                          }
                                        />
                                        {errors.wastege_value && (
                                          <span className="input-error">
                                            {errors.wastege_value}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </FormGroup>
                                </div>
                                <div className="col-md-12">
                                  <FormGroup>
                                    <Label className="col-form-label ml-2 Label-my form-label form-label">
                                      {t("description")}
                                    </Label>
                                    <div className="col-xl-12">
                                      <div className="input-div">
                                        <textarea
                                          className="form-control input-search"
                                          type="text"
                                          value={vehiclePartsData.description}
                                          onChange={(e) => {
                                            setVehiclePartsData({
                                              ...vehiclePartsData,
                                              description: e.target.value,
                                            });
                                          }}
                                          name={`description`}
                                          maxLength={100}
                                          placeholder="Description"
                                          rows={3}
                                        />
                                      </div>
                                    </div>
                                  </FormGroup>
                                </div>
                              </div>
                            </>
                          )}

                          <div className="row">
                            <div
                              className="col-md-6"
                              style={{
                                marginBottom: "10px",
                              }}
                            >
                              <div
                                className="card-title m-0 float-left"
                                style={{ width: "50%" }}
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  value={search}
                                  onChange={(e) => setSearch(e.target.value)}
                                  placeholder="Search"
                                ></input>
                              </div>
                            </div>
                            <div className="col-md-6"></div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <div className="vehicleThBg table-responsive tableScroll table-scroll-modal">
                                <table className="rounded-table table table-striped table-bordered table-hover ">
                                  <thead>
                                    <tr>
                                      <th>{t("ler_name")}</th>
                                      <th>{t("LER Code")}</th>
                                      <th>{t("unit_value")}</th>
                                      <th>{t("action")}</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {filteredPartAssignList?.length > 0 ? (
                                      filteredPartAssignList?.map(
                                        (item, index) => (
                                          <tr key={index}>
                                            <td>{item?.ler_name}</td>
                                            <td>{item.ler_code}</td>
                                            <td>
                                              <input
                                                type="text"
                                                value={
                                                  weightUnits[item.id] || ""
                                                }
                                                onChange={(e) =>
                                                  handleChangeLER(e, item.id)
                                                }
                                                className="form-control"
                                              />
                                            </td>

                                            <td>
                                              <Button
                                                className="addBtn"
                                                onClick={() =>
                                                  handleAddClick(
                                                    item.id,
                                                    item.ler_code,
                                                    item.unit_value,
                                                    item.ler_name
                                                  )
                                                }
                                                style={{
                                                  backgroundColor:
                                                    addedParts.some(
                                                      (part) =>
                                                        part.id === item.id
                                                    )
                                                      ? "red"
                                                      : "green",
                                                  color: "white",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <i className="bi bi-plus-lg"></i>{" "}
                                              </Button>
                                            </td>
                                          </tr>
                                        )
                                      )
                                    ) : (
                                      <tr className="scrollBrdr">
                                        <td colSpan="100%">
                                          {t("no_record_found")}
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="vehicleThBg table-responsive tableScroll table-scroll-modal">
                                <table className="rounded-table table table-striped table-bordered table-hover">
                                  <thead>
                                    <tr>
                                      <th>{t("ler_name")}</th>
                                      <th>{t("LER Code")}</th>
                                      <th>{t("unit_value")}</th>
                                      <th>{t("action")}</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {addedParts?.length > 0 ? (
                                      addedParts?.map((item, index) => (
                                        <tr key={index}>
                                          <td>{item?.ler_name}</td>
                                          <td>{item.ler_code}</td>
                                          <td>{item.unit_value}</td>

                                          <div className="trash-btn">
                                            <a
                                              className="delete-btn"
                                              href="#"
                                              onClick={() =>
                                                handleAddClick(
                                                  item.id,
                                                  // item.namePartPT,
                                                  item.ler_code,
                                                  item.unit_value,
                                                  item.ler_name
                                                )
                                              }
                                            >
                                              <i className="bi bi-trash3"></i>
                                            </a>
                                          </div>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr className="scrollBrdr">
                                        <td colSpan="100%">
                                          {t("no_record_found")}
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card-footer">
                        <div className="back-footer">
                          <div className="row">
                            <div className="col">
                              <button
                                type="button"
                                className="next-button btn btn-secondary"
                                onClick={() =>
                                  navigate("/depollution-operation")
                                }
                              >
                                <i class="bi bi-arrow-left"> </i> {t("back")}
                              </button>
                            </div>
                            <div className="col">
                              <div className="d-flex justify-content-end">
                                {loading === true ? (
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    Loading...
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    {t("submit")}{" "}
                                    <i className="bi bi-check2"></i>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Modal isOpen={partHistoryQtyModal} size="lg">
            <ModalHeader>{`Vehicle History Details`}</ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-md-12">
                  <div className="vehicleThBg table-responsive">
                    <table className="rounded-table table table-striped table-bordered table-hover">
                      <thead>
                        <tr>
                          <th>{t("wastage_value")}</th>
                          <th>{t("LER Code")}</th>
                        </tr>
                      </thead>

                      <tbody>
                        {LerCatalogHistoryList?.map((item) => {
                          return (
                            <tr key={item.id}>
                              <td>{item.wastege_value}</td>
                              <td>{item.ler_code || "-"}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                className="btn btn-block btn-primary"
                onClick={onpartHistoryQtyDetailsClose}
                style={{ width: "100px", marginTop: "10px" }}
              >
                {t("close")} <i className="bi bi-x-lg"></i>
              </button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AddForVehicleDepollution;
