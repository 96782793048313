import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import VehicleOwner from "./VehicleOwner";
import Vehicles from "./Vehicles";
import VehicleImage from "./VehicleImage";
import VehicleDocument from "./VehicleDocument";
import VehicleInfoExtra from "./VehicleInfoExtra";
import {
  getCountryAllist,
  getVehicleProcessDetails,
} from "../../services/VehicleService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useLocation } from "react-router-dom";
import { geCertificateDetails } from "../../services/CertificateServices";
import { useTranslation } from "react-i18next";
import VehicleVideo from "./VehicleVideo";
const Vehicle = () => {
  const [activeTab, setActiveTab] = useState("vehicle");
  const [vehicleProcessList, setVehicleProcessList] = useState({});
  const [AllvehicleSerachData, setAllVehicleSerachData] = useState({
    vin: "",
    plate: "",
    country: "",
    serach_vehicle_id: 1,
  });

  const [vehicleVinData, setVehicleVinData] = useState({});
  const [vehicleVinTecData, setVehicleVinTecData] = useState({});

  const [vehiclePlateData, setVehiclePlateData] = useState({});
  const [countryAllData, setCountryAllData] = useState([]);
  const [certificateDetails, SetCertificateDetails] = useState({});

  let ownerId = localStorage.getItem("ownerList");

  let vehicleId = localStorage.getItem("vehicleId");
  const { t } = useTranslation();

  const state = useLocation();

  const toggleTab = (tab) => {
    let isvalid = false;
    let storedVehicleId = vehicleId || state?.state?.id;

    if (storedVehicleId) {
      isvalid = true;
      localStorage.setItem("vehicleId", storedVehicleId);
    }

    if (isvalid) {
      if (activeTab !== tab) setActiveTab(tab);
    }
  };

  useEffect(() => {
    if (vehicleId) {
      let data = {
        vehicle_id: vehicleId ? vehicleId : "",
        data_type: activeTab,
        owner_id: ownerId,
      };

      getVehicleProcessDetails(data)
        .then((response) => {
          setVehicleProcessList(response.data.data);
        })
        .catch((err) => {});
    }
  }, [activeTab]);

  useEffect(() => {
    if (state?.state?.certificate_id) {
      let data = {
        certificate_id: state.state.certificate_id,
      };

      geCertificateDetails(data)
        .then((response) => {
          SetCertificateDetails(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [state.state?.certificate_id]);

  useEffect(() => {
    if (state?.state) {
      let data = {
        vehicle_id: state?.state?.id,
        data_type: activeTab,
        owner_id: state?.state?.vehicle_owner_id,
      };

      getVehicleProcessDetails(data)
        .then((response) => {
          setVehicleProcessList(response.data.data);
        })
        .catch((err) => {});
    }
  }, [activeTab, state]);

  useEffect(function () {
    getCountryAllist()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].code,
            label: response.data[i].country,
          });
        }
        setCountryAllData(list);
      })
      .catch((err) => {});
  }, []);

  return (
    <div>
      <section className="content-header content-header-vehcial">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6"></div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <div className="vehicle-taps-content">
            <Nav tabs className="navPills steps-horizontal-tabs">
              <NavItem
                className={`vehicle-m-l ${activeTab ? "vehicle-b" : ""}`}
              >
                <NavLink
                  className={activeTab === "vehicle" ? "active" : ""}
                  onClick={() => toggleTab("vehicle")}
                  style={{ cursor: "pointer" }}
                >
                  <i class="bi bi-car-front-fill me-1"></i>
                  {t("vehicle")}
                </NavLink>
              </NavItem>

              <NavItem className="vehicle-m-l vehicle-b">
                <NavLink
                  className={activeTab === "vehicle_images" ? "active" : ""}
                  onClick={() => toggleTab("vehicle_images")}
                  style={{ cursor: "pointer" }}
                >
                  <i class="bi bi-images me-1"></i> {t("images")}
                </NavLink>
              </NavItem>

              <NavItem className="vehicle-m-l vehicle-b">
                <NavLink
                  className={activeTab === "vehicle_video" ? "active" : ""}
                  onClick={() => toggleTab("vehicle_video")}
                  style={{ cursor: "pointer" }}
                >
                  <i class="bi bi-camera-reels me-2"></i> {t("video")}
                </NavLink>
              </NavItem>
              <NavItem className="vehicle-m-l vehicle-b">
                <NavLink
                  className={activeTab === "vehicle_extra_info" ? "active" : ""}
                  onClick={() => toggleTab("vehicle_extra_info")}
                  style={{ cursor: "pointer" }}
                >
                  <i class="bi bi-info-lg">{t("extra_info")}</i>
                </NavLink>
              </NavItem>
              <NavItem className="vehicle-m-l vehicle-b">
                <NavLink
                  className={activeTab === "vehicle_owner" ? "active" : ""}
                  onClick={() => toggleTab("vehicle_owner")}
                  style={{ cursor: "pointer" }}
                >
                  <i class="bi bi-person me-1"></i> {t("owner")}
                </NavLink>
              </NavItem>

              <NavItem className="vehicle-m-l vehicle-b">
                <NavLink
                  className={activeTab === "vehicle_documents" ? "active" : ""}
                  onClick={() => toggleTab("vehicle_documents")}
                  style={{ cursor: "pointer" }}
                >
                  <i class="bi bi-file-earmark-medical me-1"></i>{" "}
                  {t("document")}
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="vehicle">
                <Vehicles
                  setActiveTab={setActiveTab}
                  vehicleProcessList={vehicleProcessList}
                  setVehicleVinData={setVehicleVinData}
                  vehicleVinData={vehicleVinData}
                  vehicleVinTecDat={vehicleVinTecData}
                  setVehicleVinTecData={setVehicleVinTecData}
                  vehiclePlateData={vehiclePlateData}
                  setVehiclePlateData={setVehiclePlateData}
                  countryAllData={countryAllData}
                  AllvehicleSerachData={AllvehicleSerachData}
                  setAllVehicleSerachData={setAllVehicleSerachData}
                  certificateDetails={certificateDetails}
                />
              </TabPane>

              <TabPane tabId="vehicle_images">
                <VehicleImage
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  vehicleProcessList={vehicleProcessList}
                  certificateDetails={certificateDetails}
                />
              </TabPane>

              <TabPane tabId="vehicle_video">
                <VehicleVideo
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  vehicleProcessList={vehicleProcessList}
                  certificateDetails={certificateDetails}
                />
              </TabPane>
              <TabPane tabId="vehicle_extra_info">
                <VehicleInfoExtra
                  setActiveTab={setActiveTab}
                  vehicleProcessList={vehicleProcessList}
                  activeTab={activeTab}
                  vehicleVinData={vehicleVinData}
                  vehiclePlateData={vehiclePlateData}
                  certificateDetails={certificateDetails}
                />
              </TabPane>
              <TabPane tabId="vehicle_owner">
                <VehicleOwner
                  setActiveTab={setActiveTab}
                  vehicleProcessList={vehicleProcessList}
                  certificateDetails={certificateDetails}
                />
              </TabPane>

              <TabPane tabId="vehicle_documents">
                <VehicleDocument
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  vehicleProcessList={vehicleProcessList}
                  certificateDetails={certificateDetails}
                />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Vehicle;
