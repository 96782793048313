import React from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import googleImG from "../../src/asset/images/google-play.png";
import appImG from "../../src/asset/images/app-store.png";

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <div className="row">
          <Col xl="6">
            <h3>Download The App</h3>
          </Col>
          <Col xl="6">
            <div className="button-group store-buttons store-black">
              <a
                href="https://play.google.com/store/apps/details?id=com.waytoweb.tpsoft&pli=1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="img-fluid" src={googleImG} alt="Google Play" />
              </a>

              <a
                href="https://apps.apple.com/us/app/tp-software/id6741154925"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="img-fluid" src={appImG} alt="App Store" />
              </a>
            </div>
          </Col>
        </div>
        <div className="footercoopyright">
          <div className="row">
            <div className="col-xl-6">
              <div className="copyright">
                Copyright <span> © 2024</span>TP Software - Auto Parts
                Management. All Rights Reserved
              </div>
            </div>
            <div className="col-xl-6">
              <ul className="terms-naves">
                <li>
                  <Link to={"/terms-condition"}>Terms and Conditions</Link>
                </li>
                <li>
                  <Link to={"/privacy-policy"}>Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
