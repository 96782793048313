import axiosInstance from "./AxiosInstance";

export function getCompanyEcommerceHashList(data) {
  return axiosInstance.post(`/company-ecommerce-hash/list`, data);
}

export function deleteCompanyEcommerceHash(data) {
  return axiosInstance.post(`/company-ecommerce-hash/delete`, data);
}

export function createCompanyEcommerceHash(data) {
  return axiosInstance.post(`/company-ecommerce-hash/create`, data);
}

export function updateCompanyEcommerceHash(data) {
  return axiosInstance.post(`/company-ecommerce-hash/update`, data);
}

export function viewCompanyEcommerceHash(data) {
  return axiosInstance.post(`/company-ecommerce-hash/view`, data);
}
