import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import { useLocation } from "react-router-dom";

import { toast } from "react-toastify";
import Utils from "../../utils";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  WareHouseEntitySerach,
  deleteWareHouseEntity,
  getWareHouseEntityList,
} from "../../services/WareHouseServices";
import { useTranslation } from "react-i18next";

const PollPrinting = () => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const location = useLocation();
  const { t } = useTranslation();

  const RolePermission = Utils.getRecordForCurrentPath(location);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [PollPrinting, setPollPrinting] = useState([]);

  const [delModal, setDelModal] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [delpart, setDelPart] = useState("");

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const fetchWareHouse = () => {
    let data = { ...params };
    getWareHouseEntityList(data)
      .then((response) => {
        setPollPrinting(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };

      fetchWareHouse(data);
    },
    [params]
  );

  const onDeleteModalClose = () => {
    setDelModal(false);
  };

  const onDestroyPartType = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { id: delpart };
    deleteWareHouseEntity(data)
      .then((response) => {
        let data = { ...params };

        fetchWareHouse(data);
        toast.success(response.data.message);
        setDelPart("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {/* <h1 className="page-title">Part Group</h1> */}
              </div>
              <div className="col-sm-6"></div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        <section className="content">
          <div className="container-fluid container-custom-height-new">
            <div className="row">
              <div className="col-md-12">
                <div className="card fuelCard">
                  <div className="card-header">
                    <div className="card-title m-0 float-left">
                      <input
                        type="text"
                        className="form-control"
                        value={params.search}
                        onChange={(e) => handleParams(e, "search")}
                        placeholder="Search"
                      ></input>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="vehicleThBg table-responsive table-responsive-vehicle ">
                      <table className="table table-bordered rounded-table table table-striped table-hover">
                        <thead>
                          <tr>
                            <th style={{ width: 10 }}>#</th>
                            <th className="col-form-label ml-2 Label-my form-label form-label">
                              Label
                            </th>
                            <th className="col-form-label ml-2 Label-my form-label form-label">
                              Price €
                            </th>
                            <th className="col-form-label ml-2 Label-my form-label form-label">
                              Part
                            </th>

                            <th className="col-form-label ml-2 Label-my form-label form-label">
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {PollPrinting?.map((item, index) => {
                            return (
                              <>
                                <tr>
                                  <td>{++index}</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                        {PollPrinting && PollPrinting?.length === 0 && (
                          <tr>
                            <td colSpan={7} className={`text-center`}>
                              {" "}
                              {t("no_record_found")}
                            </td>
                          </tr>
                        )}
                      </table>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
              </div>
            </div>
          </div>
          <div className="fuelCF">
            <div className="card-footer clearfix card-footer-part ">
              <div className="pagination pagination-sm m-0 float-right">
                <Pagination
                  activePage={params.page}
                  itemsCountPerPage={parseInt(params.limit)}
                  totalItemsCount={parseInt(totalRecords)}
                  pageRangeDisplayed={5}
                  itemClass={`page-item`}
                  linkClass={`page-link`}
                  onChange={(e) => handleParams(e, "pagination")}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete WareHouse Entity`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this entity?`}</p>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDelButtonLoad}
            onClick={onDeleteModalClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>

          {!isDelButtonLoad && (
            <button
              type="button"
              className="btn btn-block bg-red"
              style={{ width: "100px" }}
              onClick={onDestroyPartType}
            >
              Delete <i class="bi bi-trash3"></i>
            </button>
          )}
          {isDelButtonLoad && (
            <button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
            </button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PollPrinting;
