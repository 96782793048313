import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import ConfigDB from "../../config";

const APIDocumentation = () => {
  const urlToCopy = `${ConfigDB.data.api_url}ecommerce-all-company-list`;
  const urlToCopyProduct = `${ConfigDB.data.api_url}ecommerce-get-product-inventory?tokens=xxxxxxx&limit=50&page=1&search=&order_field=created_at&order_type=asc&language=en`;
  const urlToCopyProductCheck = `${ConfigDB.data.api_url}ecommerce-check-product-inventory?company_id=2&product_id=302&qty=10`;
  const urlToCopyGenerateSales = `${ConfigDB.data.api_url}ecommerce-generate-sales-order`;
  const urlToCopyGenerateSalesList = `${ConfigDB.data.api_url}ecommerce-get-sales?tokens=xxxxxxx&limit=50&page=1&search=&order_field=created_at&order_type=asc&language=en&status=0`;
  const urlToCopyRefundList = `${ConfigDB.data.api_url}refund-notification?token=xxxxxx&id_sale=1024&type_refund=FULL&refund_request_value=499.99&reason_refund=Received incorrect product variant`;

  const [tooltip, setTooltip] = useState("Copy");
  const [tooltipRes, setTooltipRes] = useState("Copy");
  const [tooltipProduct, setTooltipProduct] = useState("Copy");
  const [tooltipProductCheck, setTooltipProductCheck] = useState("Copy");

  const [tooltipGenrateSales, setTooltipGenrateSales] = useState("Copy");
  const [tooltipGenrateSalesList, setTooltipGenrateSalesList] =
    useState("Copy");
  const [tooltipRefundList, setTooltipRefundList] = useState("Copy");

  const [response] = useState({
    message: "success",
    data: [
      {
        data: [
          {
            id: 74,
            uuid: "d613a4a0-90f7-4117-b63b-d6f956342548",
            name: "Lisbon Tech Solutions",
            address: "Avenida da Liberdade 150, Lisbon, Portugal",
            state: "Lisbon",
            city: "Lisbon",
            zipcode: "1250-146",
            company_registration_number: "LTS-202403",
            address_proof: "uploads/logos/lisbontech.jpg",
            is_subscribed: 1,
            subscription_expiry: "2025-04-30T00:00:00.000Z",
            status: 1,
            created_at: "2025-03-11T06:12:55.000Z",
            updated_at: "2025-03-11T06:13:57.000Z",
            deleted_at: null,
            country: "Portugal",
            created_by: "86",
            updated_by: "86",
            deleted_by: null,
            is_enable_ecommerce_access: 1,
            phone_number: "+351-21-345-6789",
            company_id: "79",
            is_force_cron_job: 0,
            label_machine: 1,
            state_id: 1,
            condition_id: 1,
            discount_percentage: 12,
            print_label: 1,
            park_id: 0,
            part_column_ids: "1,2,3,4,5,6,7,8,9",
            vat_included: 1,
            reg_number_dismantler: "LTS-DISM-001",
            default_image: null,
            default_color: "#C33434",
          },
        ],
        total: "1",
      },
    ],
  });

  const [responseProduct] = useState({
    message: "success",
    data: [
      {
        id: 309,
        company_id: "2",
        vehicle_id: "96",
        part_code: "ACB-4821-X",
        quantity: 3,
        price_1: 250.0,
        price_2: 230.0,
        part_description: "Front Left Headlight Assembly",
        is_custom_size: 1,
        parts_weight: 4.5,
        parts_length: 60,
        parts_width: 25,
        parts_height: 20,
        vat_included: 1,
        cc: "1998",
        cv: "150",
        kw: "110",
        parts_internal_id: "FH-789654",
        reg_number_dismantler: "REG-2025-789",
        state_id: "1",
        condition_id: "1",
        product_name: "Front Left Headlight",
        vehicle_model_name: "Ford Focus 2022",
        vehicle_make_name: "Ford",
        vehicle_variant_name: "2.0 EcoBoost ST (206 kW / 280 hp)",
        brand_name: "Bosch",
        motor_code: "4G63T",
        inventory_status: "In Stock",
        created_at: "2025-03-06T06:25:41.000Z",
        updated_at: "2025-03-05T09:08:24.136Z",
        vehicle_year: "2022",
        bar_code_parts: "1234567890123",
        is_master_part: "0",
        master_part_id: "17",
        master_part_inventory_id: "0",
        state_name: "New",
        condition_name: "OEM",
        token: "xxxxxxxxxxxxxxxxxxxxxx",
        sync_id: 1,

        vehicle: {
          vin_number: "1FA6P8TH9K5123456",
          registration: "AB-123-CD",
          registration_plate: "82-83-HM",
          vehicle_brand: "Ford",
          vehicle_model: "Focus ST",
          vehicle_year: "2022",
          vehicle_kms: "12000",
          vehicle_doors: "2",
          vehicle_fuel: "Petrol",
          vehicle_motorization: "2.0 EcoBoost",
          vehicle_model_id: "22",
          make_id: "4",
          variants_id: "105946",
          vehicle_uuid: "58725682-b564-4a01-86a2-ed75626b8809",
          vehicle_type_name: "Car",
          fuel_type_name: "Petrol",
          variants_name: "2.0 EcoBoost ST (206 kW / 280 hp)",
          motor_code: "4G63T",
          vehicle_category: "Hatchback",
          vehicle_color: "Blue",
          vehicle_cyllinder: "4",
          vehicle_tare: "1400",
          vehicle_approval: "Yes",
          vehicle_total_gross_weight: "1800",
          vehicle_parts: "52",
          status: 1,
          vehicle_process_end_date: "2025-03-27T18:30:00.000Z",
          is_vehicle_depollution: 0,
          created_by: "2",
          updated_by: "2",
          vehicle_internal_id: "12",
          state_parking: "1",
          vehicle_image: [
            {
              vehicle_image:
                "https://example.com/uploads/vehicle/images/focus1.jpg",
              sort_order: "1",
            },
            {
              vehicle_image:
                "https://example.com/uploads/vehicle/images/focus2.jpg",
              sort_order: "2",
            },
          ],
        },
        part_qty_list: [
          {
            parts_id: "309",
            uuid: "c970a0c2-8fbf-4a68-9d2a-3a35fdeafe85",
            id: 264,
            qty: 1,
            location_ids: "",
            part_rating: 5,
            location_name: "Shelf A1",
          },
          {
            parts_id: "309",
            uuid: "6b909262-910a-42c2-99c8-04fce0bf7cf2",
            id: 265,
            qty: 2,
            location_ids: "",
            part_rating: 5,
            location_name: "Shelf A2",
          },
        ],
        parts_reference: [
          {
            id: 16,
            parts_id: "309",
            is_main: 0,
            condition_id: "1",
            type_id: "1",
            reference_code: "OEM-12345",
            type_name: "Compatible",
            condition_name: "OEM",
          },
          {
            id: 17,
            parts_id: "309",
            is_main: 1,
            condition_id: "2",
            type_id: "2",
            reference_code: "AF-67890",
            type_name: "Written on the label",
            condition_name: "Aftermarket",
          },
        ],

        part_damage: [
          {
            damage_description: "1111",
            location_damage: "Internal",
            type_damage: "Not affect function",
            damage_rating: 1.5,
          },
        ],
        image_list: [
          {
            image_url:
              "https://tpsoftware.s3.eu-west-1.amazonaws.com/uploads/vehicle/parts/1742277347163-12.jpg",
            part_name: "Distribuidor",
            image_uuid: "adae8407-cb7f-4614-b270-2d5b2b6683fc",
            part_id: "309",
            uuid: "a83884fc-86f8-4fab-a041-3be250b14eab",
            part_code: "pvfmnjhmog",
            quantity: 1,
            part_price: 0,
            inventory_status: 1,
            part_description: "",
            id: 202,
            damage_list: [
              {
                id: 34,
                video_url: null,
                damage_description: "1111",
                vehicle_part_id: "574",
                img_video_id: "202",
                damage_id: "39",
              },
            ],
          },
          {
            image_url:
              "https://tpsoftware.s3.eu-west-1.amazonaws.com/uploads/vehicle/parts/1742277347982-14.jpg",
            part_name: "Distribuidor",
            image_uuid: "95e296bb-7f1c-4f27-8b2c-da62e0a58d0b",
            part_id: "309",
            uuid: "a83884fc-86f8-4fab-a041-3be250b14eab",
            part_code: "pvfmnjhmog",
            quantity: 1,
            part_price: 0,
            inventory_status: 1,
            part_description: "",
            id: 203,
            damage_list: [],
          },
        ],

        video_list: [
          {
            image_url:
              "https://tpsoftware.s3.eu-west-1.amazonaws.com/uploads/vehicle/parts/thumb_1742277359082.jpg",
            video_url:
              "https://tpsoftware.s3.eu-west-1.amazonaws.com/uploads/vehicle/parts/1742277357393-4114797-sd_640_360_25fps.mp4",
            part_name: "Distribuidor",
            image_uuid: "d6717bcd-684a-4549-95a7-45484c55db64",
            part_id: "309",
            uuid: "a83884fc-86f8-4fab-a041-3be250b14eab",
            part_code: "pvfmnjhmog",
            quantity: 1,
            part_price: 0,
            inventory_status: 1,
            part_description: "",
            id: 49,
            damage_list: [],
          },
        ],
      },
    ],
    total: "1",
  });

  const [responseProductError] = useState({
    message: "Product quantity not available!",
    status: 404,
  });

  const [responseProductCheck] = useState({
    message: "Product quantity available",
    status: 200,
  });

  const [responseGenerate] = useState({
    data: [
      {
        sync_id: 7,
        num_products: 2,
        total_value_products: 320.0,
        total_value_shipping: 15.0,
        total_value_packaging: 4.5,
        total_value_other_parcel: 2.0,
        tax_vat_products: 48.0,
        tax_vat_shipping: 2.25,
        tax_vat_packaging: 0.68,
        tax_vat_other_parcel: 0.3,
        exemption_vat_products: 0,
        exemption_vat_shipping: 0,
        exemption_vat_packaging: 0,
        exemption_vat_other_parcel: 0,
        maximum_sale_alert_date: "2025-03-15",
        notes_sale: "Fragile items. Deliver between 9 AM - 12 PM.",
        seller_takes_care_shipping: 1,
        provider_takes_care_shipping: 0,
        seller_invoice_provider: 0,
        seller_invoice_client: 1,
        shipping_label:
          "https://example.com/documents/shipping_label_12345.pdf",
        client: [
          {
            name: "Michael Johnson",
            country_code: "US",
            shipping_address: "789 Sunset Blvd",
            postal_code: "90028",
            city: "Los Angeles",
            invoice_address: "123 Business Lane",
            vat_number: "US987654321",
            country_code_vat_number: "US",
            phone: "+13235551234",
            email: "michael.johnson@example.com",
            whatsapp: "+13235551234",
          },
        ],
        provider: [
          {
            name: "Elite Auto Parts Ltd.",
            country_code: "DE",
            shipping_address: "42 Autobahnstrasse",
            postal_code: "60314",
            city: "Frankfurt",
            invoice_address: "10 Industrial Park",
            vat_number: "DE876543210",
            country_code_vat_number: "DE",
            phone: "+492215554321",
            email: "sales@eliteautoparts.de",
            whatsapp: "+492215554321",
          },
        ],
        products: [
          {
            product_id: 380,
            product_name: "Front Brake Pads - Bosch",
            qty: 2,
            price_without_vat: 95.0,
            tax_vat: 14.25,
            exemption_vat: 0,
            images: [
              "https://example.com/products/brake_pads_1.jpg",
              "https://example.com/products/brake_pads_2.jpg",
            ],
          },
          {
            product_id: 395,
            product_name: "Air Filter - Mann Filter",
            qty: 1,
            price_without_vat: 130.0,
            tax_vat: 19.5,
            exemption_vat: 0,
            images: ["https://example.com/products/air_filter_1.jpg"],
          },
        ],
      },
    ],
  });

  const [responseGenerateList] = useState({
    data: [
      {
        id: 1024,
        ecommerce_id: "ORD-20250311-001",
        order_status: 2,
        num_products: 2,
        total_value_products: "499.99",
        total_value_shipping: "15.00",
        total_value_packaging: "3.50",
        total_value_other_parcel: "0.00",
        tax_vat_products: "39.99",
        tax_vat_shipping: "1.50",
        tax_vat_packaging: "0.25",
        tax_vat_other_parcel: "0.00",
        exemption_vat_products: false,
        exemption_vat_shipping: false,
        exemption_vat_packaging: false,
        exemption_vat_other_parcel: false,
        maximum_sale_alert_date: "2025-03-15T00:00:00.000Z",
        notes_sale:
          "Customer requested express shipping. Contact before delivery.",
        seller_takes_care_shipping: true,
        provider_takes_care_shipping: false,
        seller_invoice_provider: false,
        seller_invoice_client: true,
        shipping_label: "https://example.com/shipping/ORD-20250311-001.pdf",
        shipping_company: "FedEx",
        shipping_tracking_code: "FDX123456789",
        reason_failed_sale: null,
        created_at: "2025-03-11T10:45:32.714Z",
        updated_at: "2025-03-11T12:01:10.512Z",
        invoice_number: "INV-20250311-001",
        order_status_name: "Shipped",
        product_list: [
          {
            id: 341,
            product_id: 7654,
            quantity: 1,
            price_without_vat: 299.99,
            tax_vat: 23.99,
            exemption_vat: false,
            product_name: "Gaming Laptop - 15.6 inch",
            image_list: [
              "https://example.com/products/laptop1.jpg",
              "https://example.com/products/laptop2.jpg",
            ],
          },
          {
            id: 342,
            product_id: 3421,
            quantity: 1,
            price_without_vat: 200.0,
            tax_vat: 16.0,
            exemption_vat: false,
            product_name: "Wireless Mechanical Keyboard",
            image_list: ["https://example.com/products/keyboard1.jpg"],
          },
        ],
        client_list: [
          {
            name: "Michael Johnson",
            shipping_address: "45 Greenview Ave",
            country_code: "US",
            postal_code: "90210",
            city: "Los Angeles",
            vat_number: "US987654321",
            country_code_vat_number: "US",
            phone: "+13235551234",
            email: "michael.johnson@example.com",
            whatsapp: "+13235551234",
          },
        ],
        provider_list: [
          {
            name: "Tech Distributors Inc.",
            shipping_address: "500 Tech Park, Suite 42",
            country_code: "US",
            postal_code: "30303",
            city: "Atlanta",
            vat_number: "US223344556",
            country_code_vat_number: "US",
            phone: "+14045557890",
            email: "support@techdistributors.com",
            whatsapp: "+14045557890",
          },
        ],
        bill_list: [
          {
            proposal_seller_refund_value: null,
            refund_reason: null,
            proposal_provider_refund_value: null,
            proposal_seller_type_refund: null,
            proposal_provider_type_refund: null,
            refund_state_provider: null,
            refund_accept_full: false,
            refund_accept_partial: false,
            refund_accept_value: null,
            arrived_to_warehouse: null,
            billing_document:
              "https://example.com/invoices/INV-20250311-001.pdf",
            id_billing_document: 1024,
            billing_credit_note: null,
            id_billing_credit_note: null,
            billing_other_document: null,
            id_other_document: null,
            type_other_document: null,
            refund_status_name: "",
          },
        ],
      },
    ],
  });

  const [responseRefundList] = useState({
    data: {
      token: "xxxxxxxxxxxx",
      id_sale: 12,
      type_refund: "FULL, PARTIAL",
      refund_request_value: 0,
      reason_refund: "Wrong articles",
    },
  });

  const handleCopy = () => {
    navigator.clipboard
      .writeText(urlToCopy)
      .then(() => {
        setTooltip("Copied");
        setTimeout(() => setTooltip("Copy"), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
  };

  const handleCopyProduct = () => {
    navigator.clipboard
      .writeText(urlToCopyProduct)
      .then(() => {
        setTooltipProduct("Copied");
        setTimeout(() => setTooltipProduct("Copy"), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
  };

  const handleCopyReponse = () => {
    navigator.clipboard
      .writeText(JSON.stringify(response, null, 2))
      .then(() => {
        setTooltipRes("Copied");
        setTimeout(() => setTooltipRes("Copy"), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
  };

  const handleCopyReponseProduct = () => {
    navigator.clipboard
      .writeText(JSON.stringify(response, null, 2))
      .then(() => {
        setTooltipProduct("Copied");
        setTimeout(() => setTooltipProduct("Copy"), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
  };

  const handleCopyProductCheck = () => {
    navigator.clipboard
      .writeText(urlToCopyProductCheck)
      .then(() => {
        setTooltipProductCheck("Copied");
        setTimeout(() => setTooltipProductCheck("Copy"), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
  };

  const handleCopyReponseCheck = () => {
    navigator.clipboard
      .writeText(JSON.stringify(responseProductCheck, null, 2))
      .then(() => {
        setTooltipProductCheck("Copied");
        setTimeout(() => setTooltipProductCheck("Copy"), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
  };

  const handleCopyGenerate = () => {
    navigator.clipboard
      .writeText(urlToCopyGenerateSales)
      .then(() => {
        setTooltipGenrateSales("Copied");
        setTimeout(() => setTooltipGenrateSales("Copy"), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
  };

  const handleCopyGenerateReponse = () => {
    navigator.clipboard
      .writeText(JSON.stringify(responseGenerate, null, 2))
      .then(() => {
        setTooltipGenrateSales("Copied");
        setTimeout(() => setTooltipGenrateSales("Copy"), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
  };

  const handleCopyGenerateSalesList = () => {
    navigator.clipboard
      .writeText(urlToCopyGenerateSalesList)
      .then(() => {
        setTooltipGenrateSalesList("Copied");
        setTimeout(() => setTooltipGenrateSalesList("Copy"), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
  };

  const handleCopyGenerateSalesListReponse = () => {
    navigator.clipboard
      .writeText(JSON.stringify(responseGenerateList, null, 2))
      .then(() => {
        setTooltipGenrateSalesList("Copied");
        setTimeout(() => setTooltipGenrateSalesList("Copy"), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
  };

  const handleCopyRefundList = () => {
    navigator.clipboard
      .writeText(urlToCopyRefundList)
      .then(() => {
        setTooltipRefundList("Copied");
        setTimeout(() => setTooltipRefundList("Copy"), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
  };

  const handleCopyRefundListReponse = () => {
    navigator.clipboard
      .writeText(JSON.stringify(responseRefundList, null, 2))
      .then(() => {
        setTooltipRefundList("Copied");
        setTimeout(() => setTooltipRefundList("Copy"), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
  };
  return (
    <div>
      <div id="Home">
        <div className="hero-bg-api d-flex align-items-center mt-5">
          <Container>
            <section className="contact-area">
              <div className="container">
                <div className="row mb-5">
                  <div className="col-md-12">
                    <div className="aside-section">
                      <div
                        className="section-title"
                        style={{
                          marginBottom: "0",
                          textAlign: "left",
                          paddingBottom: "15px",
                          marginLeft: "0px",
                          fontWeight: "700",
                        }}
                      >
                        Language Code Summary
                      </div>

                      <div className="table-container table-responsive">
                        <table className="api-reference-table reference-api-table ">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Code</th>
                              <th>Name</th>
                              <th>Code</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>English (UK)</td>
                              <td>en</td>
                              <td>French</td>
                              <td>fr</td>
                            </tr>

                            <tr>
                              <td>German</td>
                              <td>de</td>
                              <td>Italian</td>
                              <td>it</td>
                            </tr>

                            <tr>
                              <td>Portuguese</td>
                              <td>pt</td>
                              <td>Spanish</td>
                              <td>es</td>
                            </tr>

                            <tr>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="aside-section aside-section-2 ">
                      <h2 className="heading">Not a developer?</h2>
                      <p>get started with Token Generate.</p>

                      <p>
                        This API allows users to generate an authentication
                        token from the Super Admin panel. Users must log in with
                        their credentials before generating a token.
                      </p>

                      <p>Once logged in, the user can generate an API token.</p>
                      <p>
                        Once you have the API token, include it in the
                        Authorization header for all protected API calls.
                      </p>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="aside-section aside-section-2">
                      <h2 className="heading" style={{ marginTop: "10px" }}>
                        Get started with Ecommerce Company List
                      </h2>
                      <p>
                        Explore available companies and their details below.
                      </p>
                    </div>
                  </div>

                  <div className="col-xl-12">
                    <div className="base-url-section p-0 mb-4">
                      <div className="base-url-header">
                        <div className="base-url-title">
                          Base URL Method:POST
                        </div>
                        <button
                          className="copy-button copy-button-new relative inline-flex items-center"
                          onClick={handleCopy}
                          aria-label="Copy URL"
                        >
                          {/* Icon */}
                          <i className="bi bi-copy icon-copy relative">
                            <span className="tooltips tooltips-custom absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-2 py-1 bg-gray-800 text-white text-sm rounded shadow-md">
                              {tooltip}
                            </span>
                          </i>
                        </button>
                      </div>
                      <pre className="base-url">
                        <code>{urlToCopy}</code>
                      </pre>
                    </div>

                    <div className="base-url-section p-0 mb-4">
                      <div className="base-url-header">
                        <div className="base-url-title">
                          Ecommerce Company List
                        </div>
                        <button
                          className="copy-button copy-button-new relative inline-flex items-center"
                          onClick={handleCopyReponse}
                          aria-label="Copy URL"
                        >
                          {/* Icon */}
                          <i className="bi bi-copy icon-copy relative">
                            <span className="tooltips tooltips-custom absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-2 py-1 bg-gray-800 text-white text-sm rounded shadow-md">
                              {tooltip}
                            </span>
                          </i>
                        </button>
                      </div>

                      <pre className="base-url">
                        <code>{JSON.stringify(response, null, 2)}</code>
                      </pre>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="aside-section aside-section-3">
                      <p>get started with Product Inventory.</p>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="aside-section">
                      <div
                        className="section-title"
                        style={{
                          marginBottom: "0",
                          textAlign: "left",
                          paddingBottom: "15px",
                          marginLeft: "0px",
                          fontWeight: "700",
                        }}
                      >
                        Please include a sorting criterion in this endpoint to
                        allow ordering the results based on a specified field{" "}
                      </div>

                      <div className="table-container table-responsive">
                        <table className="api-reference-table reference-api-table ">
                          <thead>
                            <tr>
                              <th>order_field=id</th>
                              <th>order_field=updated_at</th>
                              <th>order_field=created_at</th>
                              <th>order_type=ASC</th>
                              <th>order_type=DESC</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-12">
                    <div className="base-url-section p-0 mb-5">
                      <div className="base-url-header">
                        <div className="base-url-title">
                          Base URL Method:GET
                        </div>
                        <button
                          className="copy-button copy-button-new relative inline-flex items-center"
                          onClick={handleCopyProduct}
                          aria-label="Copy URL"
                        >
                          {/* Icon */}
                          <i className="bi bi-copy icon-copy relative">
                            <span className="tooltips tooltips-custom absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-2 py-1 bg-gray-800 text-white text-sm rounded shadow-md">
                              {tooltipProduct}
                            </span>
                          </i>
                        </button>
                      </div>
                      <pre className="base-url">
                        <code>{urlToCopyProduct}</code>
                      </pre>
                    </div>

                    <div className="base-url-section p-0 mb-4">
                      <div className="base-url-header">
                        <div className="base-url-title">
                          Company List Reponse
                        </div>
                        <button
                          className="copy-button copy-button-new relative inline-flex items-center"
                          onClick={handleCopyReponseProduct}
                          aria-label="Copy URL"
                        >
                          {/* Icon */}
                          <i className="bi bi-copy icon-copy relative">
                            <span className="tooltips tooltips-custom absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-2 py-1 bg-gray-800 text-white text-sm rounded shadow-md">
                              {tooltipRes}
                            </span>
                          </i>
                        </button>
                      </div>

                      <pre className="base-url">
                        <code>{JSON.stringify(responseProduct, null, 2)}</code>
                      </pre>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="aside-section aside-section-2">
                      <p>get started with Ecommerce Product Inventory.</p>
                    </div>
                  </div>

                  <div className="col-xl-12">
                    <div className="base-url-section p-0 mb-4">
                      <div className="base-url-header">
                        <div className="base-url-title">
                          Base URL Method:GET
                        </div>
                        <button
                          className="copy-button copy-button-new relative inline-flex items-center"
                          onClick={handleCopyProductCheck}
                          aria-label="Copy URL"
                        >
                          {/* Icon */}
                          <i className="bi bi-copy icon-copy relative">
                            <span className="tooltips tooltips-custom absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-2 py-1 bg-gray-800 text-white text-sm rounded shadow-md">
                              {tooltipProductCheck}
                            </span>
                          </i>
                        </button>
                      </div>
                      <pre className="base-url">
                        <code>{urlToCopyProductCheck}</code>
                      </pre>
                    </div>

                    <div className="base-url-section p-0 mb-4">
                      <div className="base-url-header">
                        <div className="base-url-title">
                          Product quantity Reponse
                        </div>
                        <button
                          className="copy-button copy-button-new relative inline-flex items-center"
                          onClick={handleCopyReponseCheck}
                          aria-label="Copy URL"
                        >
                          <i className="bi bi-copy icon-copy relative">
                            <span className="tooltips tooltips-custom absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-2 py-1 bg-gray-800 text-white text-sm rounded shadow-md">
                              {tooltipProductCheck}
                            </span>
                          </i>
                        </button>
                      </div>

                      <pre className="base-url">
                        <code>
                          {JSON.stringify(responseProductCheck, null, 2)}
                        </code>
                        <div>
                          <code>
                            {JSON.stringify(responseProductError, null, 2)}
                          </code>
                        </div>
                      </pre>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="aside-section aside-section-3">
                      <p>get started with Generate Sales.</p>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="base-url-section p-0 mb-4">
                      <div className="base-url-header">
                        <div className="base-url-title">
                          Base URL Method:GET
                        </div>
                        <button
                          className="copy-button copy-button-new relative inline-flex items-center"
                          onClick={handleCopyGenerate}
                          aria-label="Copy URL"
                        >
                          {/* Icon */}
                          <i className="bi bi-copy icon-copy relative">
                            <span className="tooltips tooltips-custom absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-2 py-1 bg-gray-800 text-white text-sm rounded shadow-md">
                              {tooltipGenrateSales}
                            </span>
                          </i>
                        </button>
                      </div>
                      <pre className="base-url">
                        <code>{urlToCopyGenerateSales}</code>
                      </pre>
                    </div>

                    <div className="base-url-section p-0 ">
                      <div className="base-url-header">
                        <div className="base-url-title">
                          Generate Sales Payload
                        </div>
                        <button
                          className="copy-button copy-button-new relative inline-flex items-center"
                          onClick={handleCopyGenerateReponse}
                          aria-label="Copy URL"
                        >
                          {/* Icon */}
                          <i className="bi bi-copy icon-copy relative">
                            <span
                              placement="top"
                              className="tooltips tooltips-custom absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-2 py-1 bg-gray-800 text-white text-sm rounded shadow-md"
                            >
                              {tooltipGenrateSales}
                            </span>
                          </i>
                        </button>
                      </div>

                      <pre className="base-url">
                        <code>{JSON.stringify(responseGenerate, null, 2)}</code>
                      </pre>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="aside-section aside-section-3">
                      <p>get started with Generate Sales List.</p>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="base-url-section p-0 mb-4">
                      <div className="base-url-header">
                        <div className="base-url-title">
                          Base URL Method:GET
                        </div>
                        <button
                          className="copy-button copy-button-new relative inline-flex items-center"
                          onClick={handleCopyGenerateSalesList}
                          aria-label="Copy URL"
                        >
                          {/* Icon */}
                          <i className="bi bi-copy icon-copy relative">
                            <span className="tooltips tooltips-custom absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-2 py-1 bg-gray-800 text-white text-sm rounded shadow-md">
                              {tooltipGenrateSalesList}
                            </span>
                          </i>
                        </button>
                      </div>
                      <pre className="base-url">
                        <code>{urlToCopyGenerateSalesList}</code>
                      </pre>
                    </div>

                    <div className="base-url-section p-0 ">
                      <div className="base-url-header">
                        <div className="base-url-title">
                          Generate Sales List
                        </div>
                        <button
                          className="copy-button copy-button-new relative inline-flex items-center"
                          onClick={handleCopyGenerateSalesListReponse}
                          aria-label="Copy URL"
                        >
                          {/* Icon */}
                          <i className="bi bi-copy icon-copy relative">
                            <span
                              placement="top"
                              className="tooltips tooltips-custom absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-2 py-1 bg-gray-800 text-white text-sm rounded shadow-md"
                            >
                              {tooltipGenrateSalesList}
                            </span>
                          </i>
                        </button>
                      </div>

                      <pre className="base-url">
                        <code>
                          {JSON.stringify(responseGenerateList, null, 2)}
                        </code>
                      </pre>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="aside-section aside-section-3">
                      <p>get started with Refund.</p>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="base-url-section p-0 mb-4">
                      <div className="base-url-header">
                        <div className="base-url-title">
                          Base URL Method:GET
                        </div>
                        <button
                          className="copy-button copy-button-new relative inline-flex items-center"
                          onClick={handleCopyRefundList}
                          aria-label="Copy URL"
                        >
                          {/* Icon */}
                          <i className="bi bi-copy icon-copy relative">
                            <span className="tooltips tooltips-custom absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-2 py-1 bg-gray-800 text-white text-sm rounded shadow-md">
                              {tooltipRefundList}
                            </span>
                          </i>
                        </button>
                      </div>
                      <pre className="base-url">
                        <code>{urlToCopyRefundList}</code>
                      </pre>
                    </div>

                    <div className="base-url-section p-0 ">
                      <div className="base-url-header">
                        <div className="base-url-title">
                          Request (Form Data)
                        </div>
                        <button
                          className="copy-button copy-button-new relative inline-flex items-center"
                          onClick={handleCopyRefundListReponse}
                          aria-label="Copy URL"
                        >
                          {/* Icon */}
                          <i className="bi bi-copy icon-copy relative">
                            <span
                              placement="top"
                              className="tooltips tooltips-custom absolute top-full left-1/2 transform -translate-x-1/2 mt-2 px-2 py-1 bg-gray-800 text-white text-sm rounded shadow-md"
                            >
                              {tooltipRefundList}
                            </span>
                          </i>
                        </button>
                      </div>

                      <pre className="base-url">
                        <code>
                          {JSON.stringify(responseRefundList, null, 2)}
                        </code>
                      </pre>
                    </div>
                  </div>
                </div>

                <div className="row mb-5">
                  <div className="col-sm-12">
                    <div className="api-reference api-reference-custom">
                      {/* HTTP Status Code Summary Section */}
                      <div className="api-reference-box">
                        <div
                          className="section-title"
                          style={{
                            marginBottom: "0",
                            textAlign: "left",
                            paddingBottom: "15px",
                            marginLeft: "0px",
                          }}
                        >
                          HTTP Status Code Summary
                        </div>
                        <div className="table-container table-container-api table-responsive table-container-api-mob">
                          <table className="api-reference-table">
                            <thead>
                              <tr>
                                <th>Status Code</th>
                                <th style={{ width: "170px" }}>
                                  Short Description
                                </th>
                                <th>Detailed Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>200</td>
                                <td>OK</td>
                                <td>Everything worked as expected.</td>
                              </tr>
                              <tr>
                                <td>400</td>
                                <td>Bad Request</td>
                                <td>
                                  The request was unacceptable, often due to
                                  missing a required parameter.
                                </td>
                              </tr>
                              <tr>
                                <td>401</td>
                                <td>Unauthorized</td>
                                <td>No valid API key provided.</td>
                              </tr>
                              <tr>
                                <td>402</td>
                                <td>Request Failed</td>
                                <td>
                                  The parameters were valid but the request
                                  failed.
                                </td>
                              </tr>
                              <tr>
                                <td>403</td>
                                <td>Forbidden</td>
                                <td>
                                  The API key doesn’t have permissions to
                                  perform the request.
                                </td>
                              </tr>
                              <tr>
                                <td>404</td>
                                <td>Not Found</td>
                                <td>The requested resource doesn’t exist.</td>
                              </tr>
                              <tr>
                                <td>409</td>
                                <td>Conflict</td>
                                <td>
                                  The request conflicts with another request
                                  (perhaps due to using the same idempotent
                                  key).
                                </td>
                              </tr>
                              <tr>
                                <td>429</td>
                                <td>Too Many Requests</td>
                                <td>
                                  Too many requests hit the API too quickly. We
                                  recommend an exponential backoff of your
                                  requests.
                                </td>
                              </tr>
                              <tr>
                                <td>500, 502, 503, 504</td>
                                <td>Server Errors</td>
                                <td>
                                  Something went wrong on Stripe’s end. (These
                                  are rare.)
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>{" "}
          </Container>
        </div>
      </div>
    </div>
  );
};

export default APIDocumentation;
