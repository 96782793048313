import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Utils from "../../utils";
import { getVehiclePartList } from "../../services/VehicleService";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  getPartVideoSortDetails,
  partInvenoryPartDelete,
  PartInventoryImageDetails,
} from "../../services/PartsInventory";
import { useTranslation } from "react-i18next";
import ReactStars from "react-rating-stars-component";

const PartdetailsInventory = () => {
  const state = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { uuid } = useParams();

  const [vehiclePartDetails, setVehiclePartDetails] = useState([]);
  const [delModal, setDelModal] = useState(false);
  const [delpart, setDelPart] = useState("");

  const [vehicleImageDetails, setVehicleImageDetails] = useState([]);
  const [vehicleVideoDetails, setVehicleVideoDetails] = useState([]);

  useEffect(
    function () {
      if (uuid) {
        var obj = {
          id: uuid,
        };
        getPartVideoSortDetails(obj)
          .then((response) => {
            response = response.data.data;
            setVehicleVideoDetails(response);
          })
          .catch((err) => {});
      }
    },

    [uuid]
  );

  useEffect(
    function () {
      if (uuid) {
        var obj = {
          id: uuid,
        };
        PartInventoryImageDetails(obj)
          .then((response) => {
            response = response.data.data;
            setVehicleImageDetails(response);
          })
          .catch((err) => {});
      }
    },

    [uuid]
  );

  useEffect(() => {
    if (state?.state?.id) {
      let data = {
        vehicle_id: 0,
        part_id: state?.state?.id,
      };

      getVehiclePartList(data)
        .then((response) => {
          setVehiclePartDetails(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [state?.state?.id]);
  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelPart(item.id);
    setDelModal(true);
  };

  const onDestroyPartType = (e) => {
    e.preventDefault();

    let data = { id: delpart, delete_type: 1 };
    partInvenoryPartDelete(data)
      .then((response) => {
        if (state?.state?.id) {
          let data = {
            vehicle_id: 0,
            part_id: state?.state?.id,
          };

          getVehiclePartList(data)
            .then((response) => {
              setVehiclePartDetails(response.data.data);
            })
            .catch((err) => {
              toast.error(Utils.getErrorMessage(err));
            });
        }
        toast.success(response.data.message);
        setDelPart("");
        setDelModal(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const onDestroyassociatePartType = (e) => {
    e.preventDefault();

    let data = { id: delpart, delete_type: 2 };
    partInvenoryPartDelete(data)
      .then((response) => {
        if (state?.state?.id) {
          let data = {
            vehicle_id: 0,
            part_id: state?.state?.id,
          };

          getVehiclePartList(data)
            .then((response) => {
              setVehiclePartDetails(response.data.data);
            })
            .catch((err) => {
              toast.error(Utils.getErrorMessage(err));
            });
        }
        toast.success(response.data.message);
        setDelPart("");
        setDelModal(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const onDeleteModalClose = () => {
    setDelModal(false);
  };

  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">{/* <h1>Vehicle</h1> */}</div>
              <div className="col-sm-6"></div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card fuelCard">
              <div className="cardHeader card-header card-header-custom">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <p>Part Details</p>
                  </div>
                  <div className="col-sm-6">
                    <div className="d-flex  justify-content-end">
                      <button
                        type="button"
                        className="next-button btn btn-secondary"
                        onClick={() => navigate("/parts-inventory")}
                      >
                        <i className="bi bi-arrow-left"></i> {t("back")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          {vehiclePartDetails?.parts?.length > 0 ? (
            vehiclePartDetails?.parts?.map((item) => {
              console.log("item", item);
              const publicPageUrl = `${window.location.origin}/part-details/${item.uuid}`;
              const mailto = `mailto:recipient@example.com?subject=Part Details Link&body=Here is the link to the part details:%0D%0A${publicPageUrl}`;
              const whatsappUrl = `https://wa.me/?text=Here is the link to the part details:%20${encodeURIComponent(
                publicPageUrl
              )}`;

              return (
                <>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card fuelCard">
                          <div className="cardHeader card-header card-header-custom">
                            <div className="row">
                              <div className="col-md-4">
                                <p>Part Details</p>
                              </div>
                              <div className="col-md-8">
                                <div className="float-end">
                                  <a href={mailto} className="btn btn-email">
                                    <i className="bi bi-envelope"></i>
                                  </a>

                                  <a
                                    href={whatsappUrl}
                                    className="btn btn-success btn-whats"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <i className="bi bi-whatsapp"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-body card-body-custom">
                            <div className="row justify-content-start">
                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b> Part Name:</b>{" "}
                                  <address>{item?.part_name}</address>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>{t("Internal Id")}:</b>{" "}
                                  <address>{item?.parts_internal_id}</address>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b> Part Code:</b>{" "}
                                  <address>{item?.part_code}</address>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b> Description:</b>{" "}
                                  <address>
                                    {item?.part_description || "-"}
                                  </address>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b> Original Price:</b>{" "}
                                  <address>
                                    € {item?.part_original_price || "0"}
                                  </address>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b> Cost Price:</b>{" "}
                                  <address>
                                    {" "}
                                    € {item?.part_cost_price || "0"}
                                  </address>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b> Part Price:</b>{" "}
                                  <address>
                                    {" "}
                                    € {item?.part_price || "0"}
                                  </address>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>
                                    {" "}
                                    {t("brand")}
                                    {t("name")}:
                                  </b>{" "}
                                  <address>{item.brand_name || "-"}</address>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>Condition Name:</b>{" "}
                                  <address>
                                    {item?.condition_name || "-"}
                                  </address>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>Inventory Status Name:</b>{" "}
                                  <address>
                                    {item?.inventory_status_name || "-"}
                                  </address>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>Height:</b>{" "}
                                  <address>{item?.parts_height || "-"}</address>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>Length:</b>{" "}
                                  <address>{item?.parts_length || "-"}</address>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>Width:</b>{" "}
                                  <address>{item?.parts_width || "-"}</address>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>Weight:</b>{" "}
                                  <address>{item?.parts_weight || "-"}</address>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>Print Label:</b>{" "}
                                  <address>{item?.print_label || "-"}</address>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>Modal Name:</b>{" "}
                                  <address>
                                    {item?.vehicle_model_name || "-"}
                                  </address>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>Year:</b>{" "}
                                  <address>{item?.initial_year || "-"}</address>
                                </div>
                              </div>

                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>Make Name:</b>{" "}
                                  <address>
                                    {item?.vehicle_make_name || "-"}
                                  </address>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="invoice-col">
                                  <b>Part Rating:</b>{" "}
                                  <address>
                                    <ReactStars
                                      count={5}
                                      value={item?.part_rating} // Show rating
                                      size={40}
                                      activeColor="#ffd700"
                                      edit={false} // Disable editing
                                    />
                                  </address>
                                </div>
                              </div>
                            </div>
                          </div>
                          {item?.parts_damage.length > 0 && (
                            <>
                              <div className="card-custom-heading">
                                <p>Part Damage List</p>
                              </div>
                              <div className="card-body card-body-custom">
                                <div className="vehicleThBg table-responsive">
                                  <table className="table table-bordered rounded-table table table-striped table-hover">
                                    <thead>
                                      <tr>
                                        <th className="col-form-label ml-2 Label-my form-label form-label">
                                          Damage Description
                                        </th>

                                        <th className="col-form-label ml-2 Label-my form-label form-label">
                                          Location Damage
                                        </th>

                                        <th className="col-form-label ml-2 Label-my form-label form-label">
                                          Type Damage
                                        </th>

                                        <th className="col-form-label ml-2 Label-my form-label form-label">
                                          Damage Rating
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item?.parts_damage?.map((items) => {
                                        return (
                                          <>
                                            <tr>
                                              {/* <td>{items?.part_rating}</td> */}
                                              <td>
                                                {items?.damage_description ||
                                                  "-"}
                                              </td>
                                              <td>
                                                {items?.location_damage_name ||
                                                  "-"}
                                              </td>
                                              <td>
                                                {items?.type_damage_name || "-"}
                                              </td>
                                              <td>
                                                {items?.damage_rating || "-"}
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </>
                          )}

                          {item?.part_qty_list.length > 0 && (
                            <>
                              <div className="card-custom-heading">
                                <p>Part Quntity List</p>
                              </div>
                              <div className="card-body card-body-custom">
                                <div className="vehicleThBg table-responsive">
                                  <table className="table table-bordered rounded-table table table-striped table-hover">
                                    <thead>
                                      <tr>
                                        {/* <th className="col-form-label ml-2 Label-my form-label form-label">
                                          Rating
                                        </th> */}
                                        <th className="col-form-label ml-2 Label-my form-label form-label">
                                          Location Name
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item?.part_qty_list?.map((items) => {
                                        return (
                                          <>
                                            <tr>
                                              {/* <td>{items?.part_rating}</td> */}
                                              <td>
                                                {items?.location_name || "-"}
                                              </td>
                                            </tr>
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </>
                          )}

                          {item?.parts_reference?.length > 0 && (
                            <>
                              <div className="card-custom-heading">
                                <p>Part Reference List</p>
                              </div>
                              <div className="card-body card-body-custom">
                                <div className="vehicleThBg table-responsive">
                                  <table className="table table-bordered rounded-table table table-striped table-hover">
                                    <thead>
                                      <tr>
                                        <th className="col-form-label ml-2 Label-my form-label form-label">
                                          Condition Name
                                        </th>
                                        <th className="col-form-label ml-2 Label-my form-label form-label">
                                          Type Name
                                        </th>

                                        <th className="col-form-label ml-2 Label-my form-label form-label">
                                          Is Main
                                        </th>

                                        <th className="col-form-label ml-2 Label-my form-label form-label">
                                          Reference Code{" "}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item?.parts_reference?.map((items) => {
                                        return (
                                          <>
                                            <tr>
                                              <td>{items?.condition_name}</td>
                                              <td>{items?.type_name}</td>
                                              <td>{items?.is_main}</td>
                                              <td>{items?.reference_code}</td>
                                            </tr>
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </>
                          )}

                          {item?.parts_associate?.length > 0 && (
                            <>
                              <div className="card-custom-heading">
                                <p>Part Associate</p>
                              </div>
                              <div className="card-body card-body-custom">
                                <div className="vehicleThBg table-responsive">
                                  <table className="table table-bordered rounded-table table table-striped table-hover">
                                    <thead>
                                      <tr>
                                        <th className="col-form-label ml-2 Label-my form-label form-label">
                                          Modal Name
                                        </th>
                                        <th className="col-form-label ml-2 Label-my form-label form-label">
                                          Make Name
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item?.parts_associate?.map((items) => {
                                        return (
                                          <>
                                            <tr>
                                              <td>{items?.model_name}</td>
                                              <td>{items?.make_name}</td>
                                            </tr>
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </>
                          )}

                          {item?.parts_assoicate_list?.length > 0 && (
                            <>
                              <div className="card-custom-heading">
                                <p>Part Associate List</p>
                              </div>
                              <div className="card-body card-body-custom">
                                <div className="vehicleThBg table-responsive">
                                  <table className="table table-bordered rounded-table table table-striped table-hover">
                                    <thead>
                                      <tr>
                                        <th className="col-form-label ml-2 Label-my form-label form-label">
                                          Part Name
                                        </th>
                                        <th className="col-form-label ml-2 Label-my form-label form-label">
                                          Modal Name
                                        </th>
                                        <th className="col-form-label ml-2 Label-my form-label form-label">
                                          Make Name
                                        </th>
                                        <th className="col-form-label ml-2 Label-my form-label form-label">
                                          {t("action")}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {item?.parts_assoicate_list?.map(
                                        (items) => {
                                          return (
                                            <>
                                              <tr>
                                                <td>{items?.part_name}</td>

                                                <td>
                                                  {items?.vehicle_model_name}
                                                </td>
                                                <td>
                                                  {items?.vehicle_make_name}
                                                </td>

                                                <td>
                                                  <div
                                                    className="button-group"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      gap: "10px",
                                                    }}
                                                  >
                                                    <div className="trash-btn">
                                                      <a
                                                        className="delete-btn"
                                                        onClick={(e) =>
                                                          onDeleteModalOpen(
                                                            e,
                                                            item
                                                          )
                                                        }
                                                        style={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                        }}
                                                      >
                                                        <i className="bi bi-trash3"></i>
                                                      </a>
                                                    </div>
                                                    <a
                                                      className="edit-btn"
                                                      href="#"
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                      }}
                                                      onClick={() =>
                                                        navigate(
                                                          `/part/details/main/${item.id}`,
                                                          { state: item }
                                                        )
                                                      }
                                                    >
                                                      <i className="bi bi-card-checklist"></i>
                                                    </a>
                                                  </div>
                                                </td>
                                              </tr>
                                            </>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </>
                          )}

                          {item?.image_list?.length > 0 && (
                            <>
                              <div className="card-custom-heading">
                                <p>Part images</p>
                              </div>
                              <div className="card-body card-body-custom">
                                <div className="row justify-content-start">
                                  {item?.image_list?.map((image) => {
                                    return (
                                      <>
                                        <div className="col-sm-3">
                                          <div className="column-img">
                                            <img
                                              src={image?.image_url}
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </>
                          )}

                          {vehicleVideoDetails?.length > 0 && (
                            <>
                              <div className="card-custom-heading">
                                <p>Part Video</p>
                              </div>
                              <div className="card-body card-body-custom">
                                <div className="row justify-content-start">
                                  {vehicleVideoDetails?.map((items) => {
                                    return (
                                      <>
                                        <div className="col-sm-3">
                                          <div className="column-img">
                                            <video
                                              src={items.video_url}
                                              width="200"
                                              controls
                                              style={{
                                                borderRadius: "5px",
                                                margin: "5px",
                                              }}
                                            >
                                              Your browser does not support the
                                              video tag.
                                            </video>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              </div>
                            </>
                          )}

                          {vehicleImageDetails?.length > 0 && (
                            <>
                              <div className="card-custom-heading">
                                <p>Part Damage Image List</p>
                              </div>
                              <div className="card-body card-body-custom">
                                <div className="vehicleThBg table-responsive">
                                  <table className="table table-bordered rounded-table table table-striped table-hover">
                                    <tbody>
                                      {vehicleImageDetails
                                        ?.filter(
                                          (item) => item.damage_list.length > 0
                                        )
                                        .map((item, index) => (
                                          <React.Fragment key={index}>
                                            <tr>
                                              <td>
                                                <div className="col-sm-3">
                                                  <div className="column-img">
                                                    <img
                                                      src={item?.image_url}
                                                      alt=""
                                                    />
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>

                                            {item?.damage_list?.map(
                                              (damage, damageIndex) => (
                                                <tr key={damageIndex}>
                                                  <td
                                                    colSpan="2"
                                                    style={{
                                                      paddingLeft: "50px",
                                                    }}
                                                  >
                                                    <strong>
                                                      Damage Description:
                                                    </strong>{" "}
                                                    {damage.damage_description}{" "}
                                                    <br />
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </React.Fragment>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </>
                          )}

                          {vehicleVideoDetails &&
                            vehicleVideoDetails.some(
                              (item) => item.damage_list.length > 0
                            ) && (
                              <>
                                <div className="card-custom-heading">
                                  <p>Part Damage Video List</p>
                                </div>
                                <div className="card-body card-body-custom">
                                  <div className="vehicleThBg table-responsive">
                                    <table className="table table-bordered rounded-table table table-striped table-hover">
                                      <tbody>
                                        {vehicleVideoDetails
                                          ?.filter(
                                            (item) =>
                                              item.damage_list.length > 0
                                          )
                                          .map((item, index) => (
                                            <React.Fragment key={index}>
                                              <tr>
                                                <td>
                                                  <div className="col-sm-3">
                                                    <div className="column-img">
                                                      <video
                                                        src={item.video_url}
                                                        width="200"
                                                        controls
                                                        style={{
                                                          borderRadius: "5px",
                                                          margin: "5px",
                                                        }}
                                                      >
                                                        Your browser does not
                                                        support the video tag.
                                                      </video>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>

                                              {item?.damage_list?.map(
                                                (damage, damageIndex) => (
                                                  <tr key={damageIndex}>
                                                    <td
                                                      colSpan="2"
                                                      style={{
                                                        paddingLeft: "50px",
                                                      }}
                                                    >
                                                      <strong>
                                                        Damage Description:
                                                      </strong>{" "}
                                                      {
                                                        damage.damage_description
                                                      }{" "}
                                                      <br />
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </React.Fragment>
                                          ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <p>No details Found</p>
          )}
        </section>
      </div>

      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Part`}</ModalHeader>
        <ModalBody>
          <p className={`mb-0`}>{`Are you sure you want to delete?`}</p>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            onClick={onDeleteModalClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>

          <button
            type="button"
            className="btn btn-block bg-red"
            style={{ width: "153px" }}
            onClick={onDestroyPartType}
          >
            Delete From Inventory <i class="bi bi-trash3"></i>
          </button>

          <button
            type="button"
            className="btn btn-block bg-red"
            style={{ width: "197px" }}
            onClick={onDestroyassociatePartType}
          >
            Delete from Associate Part <i class="bi bi-trash3"></i>
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PartdetailsInventory;
