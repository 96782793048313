import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import Barcode from "react-barcode";

import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Select from "react-select";

import { useLocation } from "react-router-dom";
import { getViewAllParkList } from "../../services/ParkServices";

import {
  actionAddShelf,
  actionDelShelf,
  actionUpdateShelf,
  getShelfList,
} from "../../services/ShelfServices";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const fromIELS = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
  { value: "31", label: "31" },
  { value: "32", label: "32" },
  { value: "33", label: "33" },
  { value: "34", label: "34" },
  { value: "35", label: "35" },
  { value: "36", label: "36" },
  { value: "37", label: "37" },
  { value: "38", label: "38" },
  { value: "39", label: "39" },
  { value: "40", label: "40" },
  { value: "41", label: "41" },
  { value: "42", label: "42" },
  { value: "43", label: "43" },
  { value: "44", label: "44" },
  { value: "45", label: "45" },
  { value: "46", label: "46" },
  { value: "47", label: "47" },
  { value: "48", label: "48" },
  { value: "49", label: "49" },
  { value: "50", label: "50" },
];

const toIELS = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
  { value: "31", label: "31" },
  { value: "32", label: "32" },
  { value: "33", label: "33" },
  { value: "34", label: "34" },
  { value: "35", label: "35" },
  { value: "36", label: "36" },
  { value: "37", label: "37" },
  { value: "38", label: "38" },
  { value: "39", label: "39" },
  { value: "40", label: "40" },
  { value: "41", label: "41" },
  { value: "42", label: "42" },
  { value: "43", label: "43" },
  { value: "44", label: "44" },
  { value: "45", label: "45" },
  { value: "46", label: "46" },
  { value: "47", label: "47" },
  { value: "48", label: "48" },
  { value: "49", label: "49" },
  { value: "50", label: "50" },
  { value: "51", label: "51" },
  { value: "55", label: "55" },
  { value: "56", label: "56" },
  { value: "57", label: "57" },
  { value: "58", label: "58" },
];

const Shelf = () => {
  const location = useLocation();
  const RolePermission = Utils.getRecordForCurrentPath(location);
  const { t } = useTranslation();

  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });
  const [totalRecords, setTotalRecords] = useState(0);

  const [shelfList, setShelfList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [delModal, setDelModal] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [delpart, setDelPart] = useState("");
  const [modifyModal, setModifyModal] = useState(false);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [shelfData, setShelfData] = useState({
    uuid: "",
    park_id: "",
    name: "",
    park_name: "",
    from_iels: "1",
    to_iels: "2",
  });

  const [parkAllList, setParkAllList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [barcodeValue, setBarcodeValue] = useState("");
  const [generate, setGenerate] = useState(false);

  // const handleGenerateBarcode = () => {
  //   const value = Math.random().toString(36).substring(2, 10).toUpperCase();
  //   setBarcodeValue(value);
  //   setGenerate(true);
  // };

  const generateRandomString = (length = 8) => {
    return Math.random()
      .toString(36)
      .substring(2, 2 + length)
      .toUpperCase();
  };

  const handleGenerateBarcode = () => {
    if (!shelfData.park_id || !shelfData.name) {
      toast.error(
        "Please provide both location and shelf name before generating the barcode."
      );

      return;
    }

    const randomStr = generateRandomString(8);
    // const barcode = `${shelfData.park_name}-${shelfData.name}-${randomStr}`;
    const barcode = `${shelfData.park_name}-${shelfData.name}`;

    setBarcodeValue(barcode);
    setGenerate(true);
  };

  const handleAddModal = () => {
    setModifyModal(true);
    setGenerate(false);
  };

  const handleCloseModal = () => {
    setModifyModal(false);
    setShelfData({
      uuid: "",
      park_id: "",
      name: "",
      park_name: "",
      from_iels: "1",
      to_iels: "2",
    });
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const fetchDataShelf = () => {
    let data = { ...params };
    getShelfList(data)
      .then((response) => {
        setShelfList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };

      fetchDataShelf(data);
    },
    [params]
  );

  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };
    getViewAllParkList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].place,
          });
        }
        setParkAllList(list);
      })
      .catch((err) => {});
  }, []);

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);

    if (inputValue.length > 2) {
      const obj = {
        search: inputValue,
        limit: tableLengthList[0],
        page: 1,
      };

      getViewAllParkList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].place,
            });
          }
          setParkAllList(list);
        })
        .catch((err) => {});
    } else {
      setParkAllList([]);
      let obj = {
        search: "",
        limit: tableLengthList[0],
        page: 1,
      };
      getViewAllParkList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].place,
            });
          }
          setParkAllList(list);
        })
        .catch((err) => {});
    }
  };

  const handleSelectChange = (selectedOption) => {
    setShelfData({
      ...shelfData,
      park_id: selectedOption ? selectedOption.value : "",
    });
  };

  useEffect(() => {
    const nextToIELS = toIELS.find((item) => item.value > shelfData.from_iels);

    if (nextToIELS) {
      setShelfData((prevState) => ({
        ...prevState,
        to_iels: nextToIELS.value,
      }));
    }
  }, [shelfData.from_iels]);
  const handleSelectFromIELSChange = (selectedOption) => {
    setShelfData({
      ...shelfData,
      from_iels: selectedOption.value,
    });
  };

  const handleSelectToIELSChange = (selectedOption) => {
    setShelfData({
      ...shelfData,
      to_iels: selectedOption ? selectedOption.value : "",
    });
  };

  const filteredToIELS = toIELS.filter(
    (item) => item.value > shelfData.from_iels
  );

  const onDeleteModalClose = () => {
    setDelModal(false);
  };

  const onEditPark = (e, item) => {
    setModifyModal(true);
    setShelfData({
      uuid: item?.uuid,
      park_id: item?.park_id,
      name: item?.name,
      from_iels: item?.from_iels,
      to_iels: item?.to_iels,
    });
    setBarcodeValue(item?.bar_code);
    setGenerate(true);
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelPart(item.uuid);
    setDelModal(true);
  };

  const onDestroyPartType = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delpart };
    actionDelShelf(data)
      .then((response) => {
        let data = { ...params };
        fetchDataShelf(data);
        toast.success(response.data.message);
        setDelPart("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleShelfForm = (e) => {
    e.preventDefault();

    if (!barcodeValue) {
      toast.error("Please Genrate the barcode");
      return;
    }

    let data = {
      park_id: shelfData?.park_id,
      name: shelfData?.name,
      bar_code: barcodeValue,
      from_iels: shelfData?.from_iels,
      to_iels: shelfData?.to_iels,
    };

    let Updatedata = {
      uuid: shelfData?.uuid,
      park_id: shelfData?.park_id,
      name: shelfData?.name,
      bar_code: barcodeValue,
      from_iels: shelfData?.from_iels,
      to_iels: shelfData?.to_iels,
    };

    if (shelfData?.uuid) {
      actionUpdateShelf(Updatedata)
        .then((response) => {
          toast.success(response.data.message);
          let data = { ...params };
          fetchDataShelf(data);
          setModifyModal(false);
          setShelfData({
            uuid: "",
            park_id: "",
            name: "",
            park_name: "",
            from_iels: "1",
            to_iels: "2",
          });
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      actionAddShelf(data)
        .then((response) => {
          toast.success(response.data.message);
          let data = { ...params };
          fetchDataShelf(data);
          setModifyModal(false);
          setShelfData({
            uuid: "",
            park_id: "",
            name: "",
            park_name: "",
            from_iels: "1",
            to_iels: "2",
          });
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  const handleParkSelect = (selectedOption) => {
    setShelfData({
      ...shelfData,
      park_id: selectedOption?.value,
      park_name: selectedOption?.label,
    });
  };

  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {/* <h1 className="page-title">Part Side</h1> */}
              </div>
              <div className="col-sm-6"></div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>

        <section className="content">
          <div className="container-fluid container-custom-height-new">
            <div className="row">
              <div className="col-md-12">
                <div className="card fuelCard">
                  <div className="">
                    <div className="">
                      <div className="card-header">
                        <div
                          className="card-title m-0 float-left"
                          style={{ width: "50%" }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={params.search}
                            onChange={(e) => handleParams(e, "search")}
                            placeholder="Search"
                          ></input>
                        </div>
                        <div className="card-title m-0 float-right d-flex">
                          {" "}
                          {RolePermission?.findIndex(
                            (e) => e.permission_name === "add_shelf"
                          ) > -1 && (
                            <button
                              type="button"
                              className="btn  btn-icon-text mr-2"
                              onClick={handleAddModal}
                            >
                              <i className="bi bi-plus-lg"></i>
                              <span className="ms-2">{t("Add")}</span>
                            </button>
                          )}{" "}
                        </div>
                      </div>
                      <div className="card-body fuelCardB">
                        <div className="vehicleThBg table-responsive">
                          <table className="table table-bordered rounded-table table table-striped table-hover">
                            <thead>
                              <tr>
                                <th style={{ width: "1%" }}>#</th>
                                <th
                                  className="col-form-label ml-2 Label-my form-label form-label"
                                  style={{ width: "10%" }}
                                >
                                  Park Name
                                </th>

                                <th
                                  className="col-form-label ml-2 Label-my form-label form-label"
                                  style={{ width: "10%" }}
                                >
                                  {t("name")}
                                </th>

                                <th
                                  className="col-form-label ml-2 Label-my form-label form-label"
                                  style={{ width: "10%" }}
                                >
                                  Bar Code{" "}
                                </th>

                                <th
                                  className="col-form-label ml-2 Label-my form-label form-label"
                                  style={{ width: "10%" }}
                                >
                                  Total IELS{" "}
                                </th>
                                {/* <th className="col-form-label ml-2 Label-my form-label form-label">
                                    Vehicle
                                  </th> */}
                                <th
                                  className="col-form-label ml-2 Label-my form-label form-label"
                                  style={{ width: "10%" }}
                                >
                                  {" "}
                                  {t("action")}{" "}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {" "}
                              {shelfList?.map((item, index) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{++index}</td>
                                      <td>{item.place}</td>
                                      <td>{item.name}</td>
                                      <td>{item.bar_code}</td>
                                      <td>{item.total_iels}</td>

                                      {/* <td>{item.park_id}</td> */}
                                      <td className="d-flex">
                                        <Dropdown
                                          className="iconDropdown"
                                          onToggle={(isOpen) => {
                                            if (isOpen) {
                                              // This logic runs when the dropdown is opened
                                              const tableResponsive =
                                                document.querySelector(
                                                  ".table-responsive"
                                                );
                                              if (tableResponsive) {
                                                tableResponsive.style.overflowX =
                                                  "inherit !important";
                                                tableResponsive.style.overflowY =
                                                  "inherit !important";
                                              }
                                            }
                                          }}
                                        >
                                          <Dropdown.Toggle
                                            variant="success"
                                            id="dropdown-basic"
                                            className="iconToggle"
                                          >
                                            <i className="bi bi-three-dots-vertical"></i>
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu>
                                            <Dropdown.Item
                                              href="#/action-1"
                                              className="d-flex"
                                              onClick={(e) =>
                                                onEditPark(e, item)
                                              }
                                            >
                                              {" "}
                                              {RolePermission?.findIndex(
                                                (e) =>
                                                  e.permission_name ===
                                                  "modify_shelf"
                                              ) > -1 && (
                                                <a
                                                  className="edit-btn"
                                                  href="#"
                                                >
                                                  {" "}
                                                  <i className="bi bi-pencil"></i>
                                                </a>
                                              )}
                                              <span>Edit</span>
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              href="#/action-2"
                                              className="d-flex"
                                              onClick={(e) =>
                                                onDeleteModalOpen(e, item)
                                              }
                                            >
                                              {" "}
                                              {RolePermission?.findIndex(
                                                (e) =>
                                                  e.permission_name ===
                                                  "delete_shelf"
                                              ) > -1 && (
                                                <div className="trash-btn">
                                                  <a
                                                    className="delete-btn"
                                                    href="#"
                                                  >
                                                    {" "}
                                                    <i className="bi bi-trash3"></i>
                                                  </a>
                                                </div>
                                              )}
                                              <span>Delete</span>{" "}
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}{" "}
                            </tbody>{" "}
                            {shelfList && shelfList?.length === 0 && (
                              <tr>
                                <td colSpan={7} className={`text-center`}>
                                  {" "}
                                  {t("no_record_found")}
                                </td>
                              </tr>
                            )}
                          </table>
                        </div>
                      </div>{" "}
                      {/* /.card-body */}{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fuelCF">
            <div className="card-footer card-footer-part clearfix">
              <div className="pagination pagination-sm m-0 float-right">
                <Pagination
                  activePage={params.page}
                  itemsCountPerPage={parseInt(params.limit)}
                  totalItemsCount={parseInt(totalRecords)}
                  pageRangeDisplayed={5}
                  itemClass={`page-item`}
                  linkClass={`page-link`}
                  onChange={(e) => handleParams(e, "pagination")}
                />
              </div>
            </div>
          </div>
        </section>

        <Modal isOpen={delModal}>
          <ModalHeader>{`Delete Park`}</ModalHeader>
          <ModalBody>
            <p
              className={`mb-0`}
            >{`Are you sure you want to delete this shelf?`}</p>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-primary"
              disabled={isDelButtonLoad}
              onClick={onDeleteModalClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-x-lg"></i>
            </button>

            {!isDelButtonLoad && (
              <button
                type="button"
                className="btn btn-block bg-red"
                style={{ width: "100px" }}
                onClick={onDestroyPartType}
              >
                Delete <i class="bi bi-trash3"></i>
              </button>
            )}
            {isDelButtonLoad && (
              <button
                color="secondary"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
              </button>
            )}
          </ModalFooter>
        </Modal>

        <Modal isOpen={modifyModal}>
          <ModalHeader>Shelf</ModalHeader>
          <Form method={`post`} onSubmit={handleShelfForm}>
            <ModalBody>
              <div className="row">
                <div className="col-md-6">
                  <FormGroup>
                    <Label className="col-form-label ml-2 Label-my form-label form-label">
                      {"Select Park"}
                    </Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        {/* <Select
                            className="my-Select"
                            options={parkAllList}
                            value={parkAllList.find(
                              (item) => item.value == shelfData.park_id
                            )}
                            onChange={handleSelectChange}
                            placeholder={"Select Park"}
                            isSearchable={true}
                            onInputChange={handleInputChange}
                          /> */}

                        <Select
                          value={parkAllList.find(
                            (park) => park.value === Number(shelfData.park_id)
                          )}
                          onChange={handleParkSelect}
                          options={parkAllList}
                          onInputChange={handleInputChange}
                          placeholder="Search and select a park"
                          inputValue={inputValue}
                          isClearable={true}
                        />
                      </div>
                    </div>
                  </FormGroup>
                </div>

                <div className="col-md-6">
                  <FormGroup>
                    <Label className="col-form-label ml-2 Label-my">
                      {"Shelf Name"}
                    </Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <input
                          className="form-control input-search"
                          type="text"
                          value={shelfData.name}
                          onChange={(e) => {
                            setShelfData({
                              ...shelfData,
                              name: e.target.value,
                            });
                          }}
                          name={`name`}
                          maxLength={100}
                          placeholder="Shelf Name"
                        />
                      </div>
                    </div>
                  </FormGroup>
                </div>

                <div className="col-md-6">
                  <FormGroup>
                    <Label className="col-form-label ml-2 Label-my form-label form-label">
                      {"From IELS"}
                    </Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <Select
                          className="my-Select"
                          options={fromIELS}
                          value={fromIELS.find(
                            (item) => item.value === shelfData.from_iels
                          )}
                          onChange={handleSelectFromIELSChange}
                          placeholder={"From IELS"}
                        />
                      </div>
                    </div>
                  </FormGroup>
                </div>

                <div className="col-md-6">
                  <FormGroup>
                    <Label className="col-form-label ml-2 Label-my form-label form-label">
                      {"To IELS"}
                    </Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <Select
                          className="my-Select"
                          options={filteredToIELS}
                          value={filteredToIELS.find(
                            (item) => item.value === shelfData.to_iels
                          )}
                          onChange={handleSelectToIELSChange}
                          placeholder={"To IELS"}
                        />
                      </div>
                    </div>
                  </FormGroup>
                </div>
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn  btn-icon-text mr-2"
                    onClick={handleGenerateBarcode}
                  >
                    <i class="bi bi-upc"></i>
                    <span className="ms-2">Generate Code</span>
                  </button>

                  {generate && (
                    <div style={{ width: "100%" }} className="sv-genrate">
                      <Barcode value={barcodeValue} />
                    </div>
                  )}
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                class="btn btn-block btn-primary"
                onClick={handleCloseModal}
                style={{ width: "100px", marginTop: "10px" }}
              >
                Close <i class="bi bi-x-lg"></i>
              </button>
              <button
                type="submit"
                class="btn btn-block btn-primary"
                style={{ width: "100px", marginTop: "10px" }}
              >
                Submit <i class="bi bi-check2"></i>
              </button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default Shelf;
