import axiosInstance from "./AxiosInstance";

export function createPartsInventory(data) {
  return axiosInstance.post(`/vehicle-inventory-store`, data);
}
export function updatePartsInventory(data) {
  return axiosInstance.post(`/vehicle-inventory-update`, data);
}

export function getVehicleList(data) {
  return axiosInstance.post(`/vehicle-search-list`, data);
}

export function getCatalogList(data) {
  return axiosInstance.post(`/catalog-search-list`, data);
}
export function getCatalogListAuto(data) {
  return axiosInstance.post(`/catalog-search-list-auto`, data);
}

export function getPartsInventoryList(data) {
  return axiosInstance.post(`/part-inventory-list`, data);
}

export function getPartInventoryDetails(data) {
  return axiosInstance.post(`/part-inventory-details`, data);
}

export function actionPartInventoryTempStore(data) {
  return axiosInstance.post(`/vehicle-inventory-temp-store`, data);
}

export function getPartInventoryTempList(data) {
  return axiosInstance.post(`/vehicle-inventory-temp-list`, data);
}

export function DestoryPartInventoryTemp(data) {
  return axiosInstance.post(`/vehicle-inventory-temp-delete`, data);
}

export function deletePartInventory(data) {
  return axiosInstance.post(`/vehicle-inventory-delete`, data);
}

export function getPartInventoryMakeList(data) {
  return axiosInstance.post(`/make-search-list`, data);
}

export function getPartInventoryYearList(data) {
  return axiosInstance.post(`/year-search-list`, data);
}

export function getPartInventoryModelList(data) {
  return axiosInstance.post(`/model-search-list`, data);
}

export function getModelAllList(data) {
  return axiosInstance.post(`/model-search-all-list`, data);
}

export function actionPartInventoryImage(data) {
  return axiosInstance.post(`/part-images`, data);
}

export function destoryPartInventoryImage(data) {
  return axiosInstance.post(`/part-images-delete`, data);
}

export function PartInventoryImageDetails(data) {
  return axiosInstance.post(`/part-images-details`, data);
}

export function VehiclePartList(data) {
  return axiosInstance.post(`/vehicle-parts/view/list`, data);
}

export function VehiclePartQRDetails(data) {
  return axiosInstance.post(`/qr-details`, data);
}

export function getwarHouseEntityList(data) {
  return axiosInstance.post(`/warehouse-entity/entity-list`, data);
}

export function getpartInventoryQuentityDetails(data) {
  return axiosInstance.post(`/part-qty-list`, data);
}

export function deletePartInventoryHard(data) {
  return axiosInstance.post(`/part-record-status-update`, data);
}

export function partInvenToryLocationUpdate(data) {
  return axiosInstance.post(`/part-qty-location-update`, data);
}

export function partVehicleProcessUpdateExpiryDate(data) {
  return axiosInstance.post(`/vehicle-process/update-expiry-date`, data);
}

export function getpartQuantityHistoryList(data) {
  return axiosInstance.post(`/part-qty-history-list`, data);
}

export function getpartQuantityList(data) {
  return axiosInstance.post(`/part-qty-list`, data);
}

export function actionPartStatusUpdate(data) {
  return axiosInstance.post(`/part-status-update`, data);
}

export function getPartVehicleLogHistory(data) {
  return axiosInstance.post(`/ler-vehicle-log/history`, data);
}

export function partInvenoryVehicleSearch(data) {
  return axiosInstance.post(`/part-vehicle-search-list`, data);
}

export function partInvenoryPartETAI(data) {
  return axiosInstance.post(`/part-etai-details`, data);
}

export function partInvenoryPartETAIPrice(data) {
  return axiosInstance.post(`/part-etai-details-price`, data);
}

export function partInvenoryPartRefDelete(data) {
  return axiosInstance.post(`/part-ref-delete`, data);
}

export function partInvenoryPartPdf(data) {
  return axiosInstance.post(`/part-list-pdf`, data);
}

export function partInvenoryAllPartPdf(data) {
  return axiosInstance.post(`/part-all-list-pdf`, data);
}

export function partInvenoryPartDelete(data) {
  return axiosInstance.post(`/part-associate-delete`, data);
}

export function partDamageDelete(data) {
  return axiosInstance.post(`/part-damage-delete`, data);
}

export function partRefDelete(data) {
  return axiosInstance.post(`/part-ref-delete`, data);
}

export function getpartDamageList(data) {
  return axiosInstance.post(`/damage-list`, data);
}

export function createPartDamageAssigned(data) {
  return axiosInstance.post(`/assign-damage`, data);
}

export function deletePartDamageAssigned(data) {
  return axiosInstance.post(`/delete-damage`, data);
}

export function createPartVideo(data) {
  return axiosInstance.post(`/part-video`, data);
}

export function updatePartVideoSort(data) {
  return axiosInstance.post(`/part-video/update-part-video-sort`, data);
}

export function deletePartvideo(data) {
  return axiosInstance.post(`/part-video-delete`, data);
}

export function getPartVideoSortDetails(data) {
  return axiosInstance.post(`/part-video-details`, data);
}

export function getCatalogModalStatsHistoryDetails(data) {
  return axiosInstance.post(`/catalog-modal-stats-history`, data);
}

export function getPartStatiscalPriceDetails(data) {
  return axiosInstance.post(`/part-statiscal-price`, data);
}

export function getPartEstimatePriceDetails(data) {
  return axiosInstance.post(`/estimate-price`, data);
}

export function getPartRefcodeConditionDetails(data) {
  return axiosInstance.post(`/get-refcode-condition-stats`, data);
}
