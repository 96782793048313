import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import quetion from "../../asset/images/questions.svg";
import Accordion from "react-bootstrap/Accordion";
import { getViewfaqList } from "../../services/FaqService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { Link } from "react-router-dom";

const Faq = () => {
  const [faqDataList, setFAQdataList] = useState([]);
  const [params, setParams] = useState({
    limit: 4,
    page: 1,
    search: "",
    is_reload: false,
  });

  const [activeKey, setActiveKey] = useState("0");

  const handleToggle = (eventKey) => {
    setActiveKey(activeKey === eventKey ? "0" : eventKey);
  };

  useEffect(
    function () {
      let data = { ...params };
      getViewfaqList(data)
        .then((response) => {
          setFAQdataList(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    },
    [params]
  );

  const Faq = [
    {
      heading: "Frequently Asked Questions",
      text: "See the answers to some of our most commonly asked questions below.",
    },
  ];

  return (
    <>
      {faqDataList?.length > 0 && (
        <div className="faq-section">
          <Container>
            <div className="section-heading">
              {Faq?.map((item, index) => (
                <div className="section-title" key={index}>
                  <h2>{item.heading}</h2>
                  <p>{item.text}</p>
                </div>
              ))}
            </div>
            <Row className="justify-content-center align-items-center">
              <Col lg="3">
                <div className="questions">
                  <img src={quetion} alt="questions" />
                </div>
              </Col>
              <Col lg="5">
                {faqDataList?.map((item, index) => {
                  const eventKey = index.toString();

                  return (
                    <>
                      <Accordion
                        activeKey={activeKey}
                        className="accordion-block"
                      >
                        <Accordion.Item eventKey={eventKey} key={eventKey}>
                          <Accordion.Header
                            onClick={() => handleToggle(eventKey)}
                          >
                            {item.question}
                          </Accordion.Header>
                          <Accordion.Body>{item.answer}</Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </>
                  );
                })}
                {faqDataList?.length > 4 && (
                  <Link class="btn-contact" to={"/faq"}>
                    View more<i class="bi bi-arrow-right-short icon-arrow"></i>
                  </Link>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default Faq;
