import React, { useState, useEffect, useRef } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  getVehicleAllProcessList,
  getVehiclePartList,
  getVehicleSentToWastage,
  printVehicleQr,
} from "../../services/VehicleService";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Barcode from "react-barcode";
import DesmeImage from "../../asset/images/dismantle_icon.svg";
import { getLerCodeList } from "../../services/DepollutionService";
import Select from "react-select";
import moment from "moment";
import Dropdown from "react-bootstrap/Dropdown";
import { getCommonSearchList } from "../../services/settingsServices";
import { getVehicleAllMakeList } from "../../services/VehicleModelService";
import { partInvenoryPartPdf } from "../../services/PartsInventory";
import { useTranslation } from "react-i18next";

const VehicleProcess = () => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);
  const navigate = useNavigate();
  const location = useLocation();
  const RolePermission = Utils.getRecordForCurrentPath(location);
  const { t } = useTranslation();

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
    registration_plate: "",
    vehicle_make: "",
    vehicle_internl_ref: "",
    inventory_status: 0,
    dismental_status: 0,
    deplllution_status: 0,
    internal_id: "",
    state_parking: "",
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [vehicleProcessList, setVehicleProcessList] = useState([]);
  const [registration, setRegistration] = useState("");
  const [model, setModel] = useState("");
  const [year, SetYear] = useState("");
  const [variant, SetVariant] = useState("");
  const [make, SetMake] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [registrationPlate, setRegistrationPlate] = useState("");
  const [vehicleMake, SetVehicleMake] = useState("");

  const [qrDetails, setQrDetails] = useState(false);
  const [DelPartQRId, setDelPartQRId] = useState("");
  const [vehiclePartDetails, setVehiclePartDetails] = useState([]);
  const [vehiclePartDetailsId, setVehiclePartDetailsId] = useState("");
  const [vehiclePartDetailsModal, setVehiclePartDetailsModal] = useState(false);
  const [sentWasteModal, setsentWasteModal] = useState(false);

  const [addedParts, setAddedParts] = useState([]);

  const [LerCatalogList, setLerCatalogList] = useState([]);

  const [inputValueLer, setInputValueLer] = useState("");

  const [vehiclePartsData, setVehiclePartsData] = useState({
    ler_code_id: [],
    unit_value: [],
  });

  const [show, setShow] = useState(false);
  const [partInventoryList, setPartInventoryList] = useState([]);
  const [dismentalList, setDismentalList] = useState([]);
  const [depollutionList, setDepollutionList] = useState([]);
  const [partInventoryFilter, setpartInventoryFilter] = useState(0);
  const [dismentalFilter, setDismentalFilter] = useState(0);
  const [depollutionFilter, setdepollutionFilter] = useState(0);
  const [stateParkingFilter, setStateParkingFilter] = useState(0);

  const [filteredSuggestionsMake, setFilteredSuggestionsMake] = useState([]);
  const [activeSuggestionIndexMake, setActiveSuggestionIndexMake] = useState(0);
  const [showSuggestionsMake, setShowSuggestionsMake] = useState(false);
  const [catalogMake, setCatalogMake] = useState("");
  const [vehicleMakeId, setVehicleMakeId] = useState("");
  const [pdfGenerateId, setPdfGenerateId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [partCatalogData, setPartCatalogData] = useState({
    ler_codes_id: "",
  });
  const [internalId, SetInternalId] = useState("");
  const [stateParkingList, setStateParkingList] = useState([]);

  const sumWastageValue = vehiclePartsData.unit_value.reduce((acc, curr) => {
    const num = parseFloat(curr); // Use parseFloat to handle decimals
    return !isNaN(num) ? acc + num : acc;
  }, 0);

  const handleShowOpen = () => {
    setShow(true);
  };

  const handleShowClose = () => {
    setShow(false);
  };

  const reportRef = useRef();

  const generatePDF = (e, item) => {
    setPdfGenerateId(item.id);
  };

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "state_parking",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setStateParkingList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (pdfGenerateId) {
      let data = {
        vehicle_id: pdfGenerateId,
      };

      partInvenoryPartPdf(data)
        .then((response) => {
          if (
            response.data &&
            response.data.record &&
            response.data.record.url &&
            response.status === 200
          ) {
            window.open(response.data.record.url, "_blank");
          } else {
            toast.error(response.data.message);
          }
          setTotalRecords(response.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [pdfGenerateId]);

  const handleAddClick = (id, registration, vehicle_model, vehicle_year) => {
    const isAdded = addedParts.some((part) => part.id === id);

    if (isAdded) {
      setAddedParts((prev) => prev.filter((part) => part.id !== id));
    } else {
      setAddedParts((prev) => [
        ...prev,
        { id, registration, vehicle_model, vehicle_year },
      ]);
    }
  };

  const handleChangeMake = (e) => {
    const userInput = e.target.value;

    let data = {
      limit: params.limit,
      page: params.page,
      search: userInput,
      is_reload: true,
    };
    getVehicleAllMakeList(data)
      .then((response) => {
        setFilteredSuggestionsMake(response.data.data);
      })
      .catch((err) => {});

    setCatalogMake(userInput);
    setActiveSuggestionIndexMake(0);
    setShowSuggestionsMake(true);
  };

  const handleClickMake = (suggestion) => {
    setVehicleMakeId(suggestion?.id);
    setCatalogMake(suggestion.make_name);
    setFilteredSuggestionsMake([]);
    setShowSuggestionsMake(false);
  };

  const handleKeyDownMake = (e) => {
    if (e.key === "ArrowDown") {
      if (
        filteredSuggestionsMake &&
        activeSuggestionIndexMake < filteredSuggestionsMake?.length - 1
      ) {
        setActiveSuggestionIndexMake(activeSuggestionIndexMake + 1);
      }
    } else if (e.key === "ArrowUp") {
      if (activeSuggestionIndexMake > 0) {
        setActiveSuggestionIndexMake(activeSuggestionIndexMake - 1);
      }
    } else if (e.key === "Enter") {
      setCatalogMake(filteredSuggestionsMake[activeSuggestionIndexMake]);
      setShowSuggestionsMake(false);
      setFilteredSuggestionsMake([]);
    }
  };

  const filteredPartAssignList = vehicleProcessList.filter(
    (item) =>
      !addedParts.some((addedPart) => addedPart.id === item.id) &&
      (item?.registration?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        item?.vehicle_model
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase()) ||
        item?.vehicle_year?.toString().includes(searchQuery))
  );

  const sentWasteOpenDetail = () => {
    setsentWasteModal(true);
  };

  const sentWasteCloseDetail = () => {
    setsentWasteModal(false);
  };

  const onvehiclePartDetailsOpen = (e, item) => {
    e.preventDefault();
    setVehiclePartDetailsId(item.id);
    setVehiclePartDetailsModal(true);
  };

  const onvehiclePartDetailsClose = () => {
    setVehiclePartDetailsModal(false);
  };

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "inventory",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setPartInventoryList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "dismental",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setDismentalList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "depollution",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setDepollutionList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };
    getLerCodeList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].ler_code,
            unit_value: response.data[i].unit_value || 0,
          });
        }
        setLerCatalogList(list);
      })
      .catch((err) => {});
  }, []);

  const handleInputChangeLER = (inputValueLer) => {
    setInputValueLer(inputValueLer);

    if (inputValueLer.length > 2) {
      const obj = {
        search: inputValueLer,
        limit: tableLengthList[0],
        page: 1,
      };

      getLerCodeList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].ler_code,
              unit_value: response.data[i].unit_value || 0,
            });
          }
          setLerCatalogList(list);
        })
        .catch((err) => {});
    } else {
      setLerCatalogList([]);
      const obj = {
        search: "",
        limit: tableLengthList[0],
        page: 1,
      };

      getLerCodeList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].ler_code,
              unit_value: response.data[i].unit_value || 0,
            });
          }
          setLerCatalogList(list);
        })
        .catch((err) => {});
    }
  };

  const handleSelectLERChange = (selectedOptions) => {
    const selectedValues = selectedOptions?.map((option) => option.value) || [];
    const unitValues =
      selectedOptions?.map((option) => option.unit_value || 0) || [];

    setVehiclePartsData((prevData) => ({
      ...prevData,
      ler_code_id: selectedValues, // Update selected IDs
      unit_value: unitValues, // Update unit values
    }));

    setPartCatalogData((prevData) => ({
      ...prevData,
      ler_codes_id: selectedOptions, // Update selected IDs
    }));
  };

  useEffect(() => {
    if (vehiclePartDetailsId) {
      let data = {
        vehicle_id: vehiclePartDetailsId,
      };

      getVehiclePartList(data)
        .then((response) => {
          setVehiclePartDetails(response.data.data);
          setTotalRecords(response.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [vehiclePartDetailsId]);

  const generateRandomString = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 4; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    paramsObj.vehicle_model = model;
    paramsObj.vehicle_year = year;
    paramsObj.registration = registration;
    paramsObj.registration_plate = registrationPlate;
    paramsObj.vehicle_make = vehicleMake;
    paramsObj.inventory_status = parseInt(partInventoryFilter);
    paramsObj.deplllution_status = parseInt(depollutionFilter);
    paramsObj.dismental_status = parseInt(dismentalFilter);
    paramsObj.vehicle_make = make;
    paramsObj.vehicle_variant = variant;
    paramsObj.internal_id = internalId;
    paramsObj.state_parking = stateParkingFilter;
    setParams(paramsObj);
    setShow(false);
  };

  const ResetModal = () => {
    setParams({
      limit: tableLengthList[0],
      page: 1,
      search: "",
      is_reload: false,
      company_id: companyId.company_id || 0,
      registration_plate: "",
      vehicle_internl_ref: "",
      vehicle_status: 0,
      vehicle_make: "",
      internal_id: "",
    });
    setModel("");
    SetYear("");
    SetMake("");
    SetVariant("");
    setRegistrationPlate("");
    SetVehicleMake("");
    setShow(false);
    setpartInventoryFilter("");
    setDismentalFilter(0);
    setdepollutionFilter(0);
    setStateParkingFilter(0);
    setVehicleMakeId("");
    SetInternalId("");
  };

  const fetchLerCatLog = () => {
    let data = {
      ...params,
    };
    setIsLoading(true);
    getVehicleAllProcessList(data)
      .then((response) => {
        setVehicleProcessList(response.data.data);
        setTotalRecords(response.data.total);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsLoading(false);
      });
  };

  const handleQrDetailsClose = () => {
    setQrDetails(false);
  };

  const handleQrDetailsPrint = () => {
    let data = {
      uuid: DelPartQRId.uuid,
      vehicle_internal_id: DelPartQRId.vehicle_internal_id,
    };
    setIsLoading(true);
    printVehicleQr(data)
      .then((response) => {
        setQrDetails(false);
        setIsLoading(false);
        toast.success(response.data.message);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsLoading(false);
      });
  };

  const onQrDetailsOpen = (e, item) => {
    e.preventDefault();
    setDelPartQRId(item);
    setQrDetails(true);
  };

  useEffect(
    function () {
      let data = { ...params };

      fetchLerCatLog(data);
    },
    [params]
  );

  const handleVehicle = () => {
    localStorage.removeItem("ownerList");
    localStorage.removeItem("vehicleId");

    navigate("/vehicle");
  };

  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const vehicle_ids = addedParts?.map((item) => item.id);
    const ler_code_id = partCatalogData?.ler_codes_id?.map((item) => ({
      id: item.value,
      unit_value: item?.unit_value || "",
    }));

    const payload = {
      vehicle_ids: vehicle_ids,
      ler_code_id: ler_code_id,
      wastege_value: sumWastageValue,
      description: "",
    };

    getVehicleSentToWastage(payload)
      .then((response) => {
        toast.dismiss();
        toast.success(response.data.message);
        setsentWasteModal(false);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">{/* <h1>Vehicle</h1> */}</div>
              <div className="col-sm-6"></div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid container-custom-height-new">
            <div className="row">
              <div className="col-md-12">
                <div className="card fuelCard">
                  <div className="card-header">
                    <div
                      className="card-title m-0 float-left fuelCT"
                      style={{ width: "60%" }}
                    >
                      <input
                        className="form-control"
                        type="text"
                        value={params.search}
                        onChange={(e) => handleParams(e, "search")}
                        // maxLength={50}
                        placeholder="Search references like Registration Plate,Vehicle Make,Vehicle Internl Ref etc."
                      />
                    </div>

                    {show && (
                      <div
                        className="offcanvas-backdrop show"
                        onClick={(e) => e.stopPropagation()}
                      ></div>
                    )}

                    <div className="card-title m-0 float-right rightBtn">
                      <button
                        type="button"
                        className="btn btn-icon-text mr-2"
                        onClick={sentWasteOpenDetail}
                      >
                        <i className="bi bi-archive"></i>
                        <span className="ms-2">{t("sent_to_waste")}</span>
                      </button>
                      {RolePermission?.findIndex(
                        (e) => e.permission_name === "add_vehicle"
                      ) > -1 && (
                        <button
                          type="button"
                          className="btn  btn-icon-text mr-2"
                          onClick={handleVehicle}
                        >
                          <i className="bi bi-plus-lg"></i>
                          <span className="ms-2">{t("Add")}</span>
                        </button>
                      )}
                      <button
                        type="button"
                        className="btn-icon-text mr-2 mr-2"
                        onClick={handleShowOpen}
                      >
                        <i className="bi bi-funnel"></i>
                        {t("filter")}
                      </button>
                    </div>
                    {/* )} */}
                  </div>
                  <div className="card-body">
                    <div className="vehicleThBg table-responsive table-responsive-vehicle">
                      <table className="table table-bordered rounded-table table table-striped table-hover">
                        <thead>
                          <tr>
                            <th>#</th>
                            {companyId?.role_id === "1" && (
                              <th className="col-form-label ml-2 Label-my form-label form-label">
                                {t("company_name")}
                              </th>
                            )}
                            <th className="col-form-label ml-2 Label-my form-label form-label">
                              {t("Internal Id")}{" "}
                            </th>
                            <th className="col-form-label ml-2 Label-my form-label form-label">
                              {t("registration_no")}{" "}
                            </th>
                            <th className="col-form-label ml-2 Label-my form-label form-label">
                              {t("model")}{" "}
                            </th>
                            <th
                              className="col-form-label ml-2 Label-my form-label form-label"
                              style={{ width: "14%" }}
                            >
                              {" "}
                              {t("year")}
                            </th>

                            <th
                              className="col-form-label ml-2 Label-my form-label form-label"
                              style={{ width: "14%" }}
                            >
                              {" "}
                              {t("created_date")}{" "}
                            </th>

                            <th
                              className="col-form-label ml-2 Label-my from-label form-label"
                              style={{ width: "14%" }}
                            >
                              {t("expiry_date")}{" "}
                            </th>
                            <th
                              className="col-form-label ml-2 Label-my form-label form-label"
                              style={{ width: "0%" }}
                            >
                              {t("action")}{" "}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {vehicleProcessList?.map((item, index) => {
                            return (
                              <tr key={item.id}>
                                <td>{++index}</td>
                                {companyId?.role_id === "1" && (
                                  <td>{item.company_name || "-"}</td>
                                )}

                                <td>{item.vehicle_internal_id || "-"}</td>
                                <td>{item.registration || "-"}</td>
                                <td>{item.vehicle_model || "-"}</td>
                                <td>{item.vehicle_year || "-"}</td>
                                <td>{item.created_at || "-"}</td>

                                <td>
                                  <span
                                    style={{
                                      display: "inline-block",
                                      padding: "5px 10px",
                                      borderRadius: "16px",
                                      width: "max-content",
                                      backgroundColor:
                                        item.vehicle_process_end_date
                                          ? "#d50000"
                                          : "#e0e0e0",
                                      color: item.vehicle_process_end_date
                                        ? "white"
                                        : "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {moment(
                                      item.vehicle_process_end_date
                                    ).format("DD-MM-YYYY") || "-"}
                                  </span>
                                </td>

                                <td className="d-flex">
                                  <Dropdown
                                    className="iconDropdown"
                                    onToggle={(isOpen) => {
                                      if (isOpen) {
                                        // This logic runs when the dropdown is opened
                                        const tableResponsive =
                                          document.querySelector(
                                            ".table-responsive"
                                          );
                                        if (tableResponsive) {
                                          tableResponsive.style.overflowX =
                                            "inherit !important";
                                          tableResponsive.style.overflowY =
                                            "inherit !important";
                                        }
                                      }
                                    }}
                                  >
                                    <Dropdown.Toggle
                                      variant="success"
                                      id="dropdown-basic"
                                      className="iconToggle"
                                    >
                                      <i className="bi bi-three-dots-vertical"></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        className="d-flex"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          if (item) {
                                            navigate(`/vehicle/${item.uuid}`, {
                                              state: item,
                                            });
                                          }
                                        }}
                                      >
                                        {RolePermission?.findIndex(
                                          (e) =>
                                            e.permission_name ===
                                            "modify_vehicle"
                                        ) > -1 && (
                                          <button
                                            className="edit-btn"
                                            onClick={(e) => e.preventDefault()}
                                          >
                                            <i className="bi bi-pencil"></i>
                                          </button>
                                        )}
                                        <span>{t("edit")}</span>
                                      </Dropdown.Item>

                                      <Dropdown.Item
                                        className="d-flex"
                                        onClick={(e) =>
                                          onQrDetailsOpen(e, item)
                                        }
                                      >
                                        <button
                                          className="edit-btn"
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          <i className="bi bi-qr-code-scan"></i>
                                        </button>
                                        <span>{t("qr")}</span>
                                      </Dropdown.Item>

                                      <Dropdown.Item
                                        className="d-flex"
                                        onClick={(e) =>
                                          navigate(
                                            "/parts-inventory/AddParts",
                                            { state: { item } }
                                          )
                                        }
                                      >
                                        <button
                                          className="edit-btn"
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          <i className="bi bi-car-front-fill"></i>
                                        </button>
                                        <span className="ms-2">
                                          {t("dismetal_car")}
                                        </span>
                                      </Dropdown.Item>

                                      <Dropdown.Item
                                        className="d-flex"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          if (item) {
                                            navigate(
                                              `/part-details-vehicle/${item.uuid}`,
                                              {
                                                state: item,
                                              }
                                            );
                                          }
                                        }}
                                      >
                                        <button
                                          className="edit-btn"
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          <img
                                            src={DesmeImage}
                                            style={{ width: "66%" }}
                                          />
                                        </button>
                                        <span>{t("part_details")}</span>
                                      </Dropdown.Item>

                                      <Dropdown.Item
                                        className="d-flex"
                                        onClick={(e) => generatePDF(e, item)}
                                      >
                                        <div className="pdf-btn">
                                          <i class="bi bi-file-earmark-pdf"></i>
                                          Download PDF Report
                                        </div>
                                      </Dropdown.Item>

                                      <Dropdown.Item
                                        className="d-flex"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          if (item) {
                                            navigate(
                                              `/vehicle/details/${item.uuid}`,
                                              {
                                                state: item,
                                              }
                                            );
                                          }
                                        }}
                                      >
                                        <button
                                          className="edit-btn"
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          <i className="bi bi-pencil"></i>
                                        </button>
                                        <span>Vehicle Details</span>
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>

                        {vehicleProcessList &&
                          vehicleProcessList?.length === 0 && (
                            <tr>
                              <td colSpan={7} className={`text-center`}>
                                {" "}
                                {t("no_record_found")}
                              </td>
                            </tr>
                          )}
                      </table>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer clearfix card-footer-part fuelCF">
            <div className="pagination pagination-sm m-0 float-right">
              <Pagination
                activePage={params.page}
                itemsCountPerPage={parseInt(params.limit)}
                totalItemsCount={parseInt(totalRecords)}
                pageRangeDisplayed={5}
                itemClass={`page-item`}
                linkClass={`page-link`}
                onChange={(e) => handleParams(e, "pagination")}
              />
            </div>
          </div>
        </section>

        <Modal isOpen={qrDetails}>
          <ModalHeader>{`Vehicle QR Details`}</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-6">
                <div className="svg-barshelf">
                  <Barcode
                    value={`${DelPartQRId.uuid}-${DelPartQRId.vehicle_internal_id}`}
                    format="CODE128"
                    width={1}
                    height={200}
                    displayValue={true}
                    margin={10}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={handleQrDetailsClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i className="bi bi-x-lg"></i>
            </button>
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={handleQrDetailsPrint}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Print <i className="bi bi-printer"></i>
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={vehiclePartDetailsModal} size="lg">
          <ModalHeader>{t("part_details")}</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="vehicleThBg table-responsive">
                  <table className="rounded-table table table-striped table-bordered table-hover">
                    <thead>
                      <tr>
                        <th>Part Name</th>
                        <th>Part quantity</th>
                        <th>Part Price</th>
                        <th>Part Original Price</th>
                        <th>Part Code</th>
                      </tr>
                    </thead>

                    <tbody>
                      {vehiclePartDetails?.length > 0 ? (
                        vehiclePartDetails?.map((item, index) => (
                          <tr key={index}>
                            <td>{item.part_name}</td>
                            <td>{item.quantity}</td>
                            <td>€ {item.part_price}</td>
                            <td>€ {item.part_original_price}</td>
                            <td>{item.part_code}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="100%">No results found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={onvehiclePartDetailsClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i className="bi bi-x-lg"></i>
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={sentWasteModal} size="xl">
          <ModalHeader>Sent To Waste</ModalHeader>
          <Form method={`post`} onSubmit={handleSubmit}>
            <ModalBody>
              <div className="row">
                <div className="row">
                  <div className="col-md-6">
                    <FormGroup>
                      <Label className="col-form-label ml-2 Label-my form-label form-label">
                        {"Select LER Catalog"}
                      </Label>
                      <div className="col-xl-13">
                        <div className="input-div">
                          <Select
                            className="my-Select"
                            options={LerCatalogList}
                            value={LerCatalogList.filter((item) =>
                              vehiclePartsData.ler_code_id.includes(item.value)
                            )}
                            onChange={handleSelectLERChange}
                            placeholder={"Select LER catalog"}
                            isSearchable={true}
                            onInputChange={handleInputChangeLER}
                            inputValue={inputValueLer}
                            isClearable={true}
                            isMulti
                          />
                        </div>
                      </div>
                    </FormGroup>
                  </div>
                  <div className="col-md-6" style={{ marginTop: "44px" }}>
                    {vehiclePartsData.ler_code_id.length > 0 && (
                      <div className="col-xl-12">
                        <p className="wastage">
                          wastage Value : {sumWastageValue}
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="col-md-6">
                    <div className="">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        maxLength={50}
                        placeholder="Search"
                      />
                    </div>
                    <div
                      className="vehicleThBg table-responsive tableScroll table-scroll-modal"
                      style={{ marginTop: "10px" }}
                    >
                      <table className="rounded-table table table-striped table-bordered table-hover">
                        <thead>
                          <tr>
                            <th style={{ width: "10%" }}>
                              {t("registration")}
                            </th>
                            <th style={{ width: "10%" }}>
                              {t("vehicle_model")}
                            </th>
                            <th style={{ width: "10%" }}>
                              {t("vehicle_year")}
                            </th>
                            <th style={{ width: "0%" }}>{t("action")}</th>
                          </tr>
                        </thead>

                        <tbody>
                          {filteredPartAssignList?.length > 0 ? (
                            filteredPartAssignList?.map((item, index) => (
                              <tr key={index}>
                                <td>{item?.registration}</td>
                                <td>{item.vehicle_model}</td>
                                <td>{item.vehicle_year}</td>
                                <td>
                                  <Button
                                    className="addBtn"
                                    onClick={() =>
                                      handleAddClick(
                                        item.id,
                                        item.registration,
                                        item.vehicle_model,
                                        item.vehicle_year
                                      )
                                    }
                                    style={{
                                      backgroundColor: addedParts.some(
                                        (part) => part.id === item.id
                                      )
                                        ? "red"
                                        : "green",
                                      color: "white",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <i className="bi bi-plus-lg"></i>{" "}
                                  </Button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="scrollBrdr">
                              <td colSpan="100%">No results found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="card-footer clearfix card-footer-part">
                      <div className="pagination pagination-sm m-0  paginationRight">
                        <Pagination
                          activePage={params.page}
                          itemsCountPerPage={parseInt(params.limit)}
                          totalItemsCount={parseInt(totalRecords)}
                          pageRangeDisplayed={5}
                          itemClass={`page-item`}
                          linkClass={`page-link`}
                          onChange={(e) => handleParams(e, "pagination")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6" style={{ marginTop: "56px" }}>
                    <div className="vehicleThBg table-responsive tableScroll table-scroll-modal">
                      <table className="rounded-table table table-striped table-bordered table-hover">
                        <thead>
                          <tr>
                            <th style={{ width: "10%" }}>
                              {t("registration")}
                            </th>
                            <th style={{ width: "10%" }}>
                              {t("vehicle_model")}
                            </th>
                            <th style={{ width: "10%" }}>
                              {" "}
                              {t("vehicle_year")}
                            </th>

                            <th style={{ width: "0%" }}>{t("action")}</th>
                          </tr>
                        </thead>

                        <tbody>
                          {addedParts?.length > 0 ? (
                            addedParts?.map((item, index) => (
                              <tr key={index}>
                                <td>{item?.registration}</td>
                                <td>{item.vehicle_model}</td>
                                <td>{item.vehicle_year}</td>

                                <td className="">
                                  <button
                                    className="btn-danger-delete"
                                    href="#"
                                    onClick={() =>
                                      handleAddClick(item.id, item.registration)
                                    }
                                  >
                                    <i className="bi bi-trash3"></i>
                                  </button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="scrollBrdr">
                              <td colSpan="100%">No results found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                className="btn btn-block btn-primary"
                onClick={sentWasteCloseDetail}
                style={{ width: "100px", marginTop: "10px" }}
              >
                Close <i className="bi bi-x-lg"></i>
              </button>
              {loading === true ? (
                <button
                  type="submit"
                  className="btn btn-block btn-primary"
                  style={{ width: "100px", marginTop: "10px" }}
                >
                  Loading...
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-block btn-primary"
                  style={{ width: "100px", marginTop: "10px" }}
                >
                  Submit <i className="bi bi-check2"></i>
                </button>
              )}
            </ModalFooter>
          </Form>
        </Modal>

        <div
          className={`offcanvas offcanvas-end ${show ? "show" : ""}`}
          tabIndex="-1"
          id="staticBackdrop"
          aria-labelledby="staticBackdropLabel"
          style={{ visibility: show ? "visible" : "hidden" }}
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="staticBackdropLabel">
              Filter
            </h5>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={handleShowClose}
            ></button>
          </div>

          <div className="offcanvas-body">
            <div>
              <div href="#/action-2">
                <div className="Label-my">
                  Part Inventory
                  <select
                    className="form-select mt-2 input-search"
                    type="select"
                    value={partInventoryFilter}
                    onChange={(e) => {
                      setpartInventoryFilter(e.target.value);
                    }}
                  >
                    <option>All</option>
                    {partInventoryList?.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div href="#/action-2">
                <div className="Label-my">
                  Dismetal
                  <select
                    className="form-select mt-2 input-search"
                    type="select"
                    value={dismentalFilter}
                    onChange={(e) => {
                      setDismentalFilter(e.target.value);
                    }}
                  >
                    <option>All</option>
                    {dismentalList?.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div href="#/action-2">
                <div className="Label-my">
                  Depollution
                  <select
                    className="form-select mt-2 input-search"
                    type="select"
                    value={depollutionFilter}
                    onChange={(e) => {
                      setdepollutionFilter(e.target.value);
                    }}
                  >
                    <option>All</option>
                    {depollutionList?.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div href="#/action-2">
                <div className="Label-my">
                  State Parking
                  <select
                    className="form-select mt-2 input-search"
                    type="select"
                    value={stateParkingFilter}
                    onChange={(e) => {
                      setStateParkingFilter(e.target.value);
                    }}
                  >
                    <option>All</option>
                    {stateParkingList?.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                <div className="Label-my">
                  {t("registration_no")}{" "}
                  <input
                    className="form-control mt-2 input-search"
                    type="text"
                    value={registration}
                    onChange={(e) => {
                      setRegistration(e.target.value);
                    }}
                    maxLength={50}
                    placeholder="Registration No."
                  />
                </div>
              </div>

              <div>
                <div className="Label-my">
                  Year
                  <input
                    className="form-control mt-2 input-search"
                    type="text"
                    value={year}
                    onChange={(e) => {
                      SetYear(e.target.value);
                    }}
                    maxLength={50}
                    placeholder="Year"
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
              </div>

              <div>
                <div className="Label-my">
                  Make
                  <input
                    className="form-control mt-2 input-search"
                    type="text"
                    value={make}
                    onChange={(e) => {
                      SetMake(e.target.value);
                    }}
                    maxLength={50}
                    placeholder="Make"
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
              </div>
              <div>
                <div className="Label-my">
                  Model
                  <input
                    className="form-control mt-2 input-search"
                    type="text"
                    value={model}
                    onChange={(e) => {
                      setModel(e.target.value);
                    }}
                    maxLength={50}
                    placeholder="Model"
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
              </div>
              <div>
                <div className="Label-my">
                  Variant
                  <input
                    className="form-control mt-2 input-search"
                    type="text"
                    value={variant}
                    onChange={(e) => {
                      SetVariant(e.target.value);
                    }}
                    maxLength={50}
                    placeholder="Variant"
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
              </div>

              <div>
                <div className="Label-my">
                  {t("Internal Id")}{" "}
                  <input
                    className="form-control mt-2 input-search"
                    type="number"
                    value={internalId}
                    onChange={(e) => {
                      SetInternalId(e.target.value);
                    }}
                    maxLength={50}
                    placeholder="Internal Id"
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-around mb-3">
              <button
                style={{ marginTop: "9px", display: "flex" }}
                type="button"
                className="btn  btn-primary btn-filter"
                onClick={(e) => handleParams(e, "search")}
              >
                <span className="ms-1">Filter</span>
              </button>
              <button
                style={{ marginTop: "9px", display: "flex" }}
                type="button"
                className="btn  btn-primary ms-1 btn-filter"
                onClick={ResetModal}
              >
                <span className="ms-1 ">Reset</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleProcess;
