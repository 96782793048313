import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import planBg from "../../asset/images/hero-bg.png";
import { Tabs, Tab } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getSubscriptionList } from "../../services/AuthServices";
import Utils from "../../utils";
import { toast } from "react-toastify";
import Signup from "../auth/Signup";

const Plan = () => {
  const plan = [
    {
      heading: "The right plan for ",
      subHeading: "your business.",
    },
  ];

  const [SubscriptionList, setSubscriptionList] = useState([]);
  const [key, setKey] = useState("monthly");
  const [showMore, setShowMore] = useState({});
  const [loginModal, setLoginModal] = useState(false);
  const [signUpModal, setSignUpModal] = useState(false);

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: Math.min(SubscriptionList.length, 3), // Show only available items, max 3
    slidesToScroll: Math.min(SubscriptionList.length, 3),
    infinite: SubscriptionList.length > 3, // Only enable infinite if more than 3 SubscriptionList

    autoplay: false,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const onSignUpModal = (e) => {
    e.preventDefault();
    setLoginModal(false);
    setSignUpModal(true);
  };

  useEffect(() => {
    getSubscriptionList()
      .then((response) => {
        setSubscriptionList(response.data.data);
        const initialShowMore = response.data.data.reduce((acc, item) => {
          acc[item.uuid] = false;
          return acc;
        }, {});
        setShowMore(initialShowMore);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  }, []);

  const toggleShowMore = (uuid) => {
    setShowMore((prev) => ({
      ...prev,
      [uuid]: !prev[uuid],
    }));
  };

  return (
    <>
      {plan.length > 0 && (
        <div className="plan-section">
          <img
            src={planBg}
            alt="Background"
            className="background-image position-absolute w-100 h-100"
            style={{ objectFit: "cover", zIndex: -1 }}
          />
          <Container fluid className="padding-top">
            <Row className="align-items-end">
              <Col xl="4">
                {plan.map((item, index) => (
                  <div key={index} className="plan-content">
                    <h3 className="h3">
                      {item.heading} <span>{item.subHeading}</span>
                    </h3>
                  </div>
                ))}
              </Col>

              <Col xl="7">
                <div className="container mt-5 planTab">
                  <Tabs
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    id="subscription-tabs"
                    className="mb-3"
                  >
                    <Tab eventKey="monthly" title="Monthly">
                      <Slider {...settings}>
                        {SubscriptionList.map((item) => (
                          <div key={item.uuid} className="box-plan">
                            <h4>{item.name}</h4>
                            <h3>
                              € {item.monthly_price} /<span>Month</span>
                            </h3>
                            <div className="mt-5 mb-5">
                              <ul>
                                <li className="cstm-scroll">
                                  {showMore[item.uuid]
                                    ? item.subscription_desc
                                    : `${item.subscription_desc.substring(
                                        0,
                                        99
                                      )}`}
                                </li>
                              </ul>
                              <button
                                className="btn"
                                style={{
                                  color: "#1f2d3d",
                                  textDecoration: "underline",
                                  fontWeight: "900",
                                }}
                                onClick={() => toggleShowMore(item.uuid)}
                              >
                                {showMore[item.uuid]
                                  ? "Read less"
                                  : "Read more"}
                              </button>
                            </div>
                            <div className="mt-3 d-flex justify-content-center">
                              <button
                                className="login-btn prch-btn"
                                type="button"
                                onClick={onSignUpModal}
                              >
                                <span className="text">
                                  {item.is_free ? "Free" : "Purchase"}
                                </span>
                                <span className="icon">€</span>
                              </button>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </Tab>

                    <Tab eventKey="yearly" title="Yearly">
                      <Slider {...settings}>
                        {SubscriptionList.map((item) => (
                          <div key={item.uuid} className="box-plan">
                            <h4>{item.name}</h4>
                            <h3>
                              € {item.yearly_price} /<span>Year</span>
                            </h3>
                            <div className="mt-5 mb-5">
                              <ul>
                                <li className="cstm-scroll">
                                  {showMore[item.uuid]
                                    ? item.subscription_desc
                                    : `${item.subscription_desc.substring(
                                        0,
                                        99
                                      )}`}
                                </li>
                              </ul>
                              <button
                                className="btn"
                                style={{
                                  color: "#1f2d3d",
                                  textDecoration: "underline",
                                  fontWeight: "900",
                                }}
                                onClick={() => toggleShowMore(item.uuid)}
                              >
                                {showMore[item.uuid]
                                  ? "Read less"
                                  : "Read more"}
                              </button>
                            </div>
                            <div className="mt-3 d-flex justify-content-center">
                              <button
                                className="login-btn prch-btn"
                                type="button"
                                onClick={onSignUpModal}
                              >
                                <span className="text">
                                  {item.is_free ? "Free" : "Purchase"}
                                </span>
                                <span className="icon">€</span>
                              </button>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    </Tab>
                  </Tabs>
                </div>
              </Col>
            </Row>
          </Container>
          <Signup
            signUpModal={signUpModal}
            setSignUpModal={setSignUpModal}
            setLoginModal={setLoginModal}
          />
        </div>
      )}
    </>
  );
};

export default Plan;
