import React from "react";
import { useLocation } from "react-router-dom";
import Utils from "../../utils";

const Dashboard = () => {
  const location = useLocation();
  const record = Utils.getRecordForCurrentPath(location);
  return (
    <>
      {record && record[0]?.display_name === "View" && (
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark"></h1>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
