import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  createPartCatalog,
  deletePartCatalog,
  getPartCatalogList,
  importPartCatalog,
  getExportPartCatalogList,
  getEtaiSearchList,
  actionPartAssignEtai,
} from "../../services/LerCatalogService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { actionPartCatalogList } from "../../services/ParkServices";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";

const PartCatalog = () => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const navigate = useNavigate();
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);
  const location = useLocation();
  const RolePermission = Utils.getRecordForCurrentPath(location);
  const LangData = localStorage.getItem("lng");
  const { t } = useTranslation();

  const [totalRecords, setTotalRecords] = useState(0);
  const [partList, setPartList] = useState([]);
  const [partAssignList, setPartAssignList] = useState([]);
  const [totalRecordsAssign, setTotalRecordsAssign] = useState(0);

  const [delModal, setDelModal] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [delpart, setDelPart] = useState("");
  const [modifyModal, setModifyModal] = useState(false);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [partLocationData, setPartLocationData] = useState({
    uuid: "",
    ler_code: "",
    item_weight: "",
    item_weight_unit: "",
  });

  const [modifyImportModal, setModifyImportModal] = useState(false);
  const [file, setFile] = useState(null);

  let errorsObj = {
    ler_code: "",
    item_weight: "",
    item_weight_unit: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  let errorsObjImport = {
    file: "",
  };
  const [errorsImport, setErrorsImport] = useState(errorsObjImport);
  const [percentageModal, setPercentageModal] = useState(false);
  const [percentageData, setPercentageData] = useState({
    percentage: "",
    parts_catalog_id: "",
  });

  const [addedParts, setAddedParts] = useState([]);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
    ...(percentageModal === true ? { pages: 1 } : { page: 1 }), // Conditionally add pages or page
  });

  const [filteredSuggestionsVariants, setFilteredSuggestionsVariants] =
    useState([]);
  const [activeSuggestionIndexVariants, setActiveSuggestionIndexVariants] =
    useState(0);
  const [showSuggestionsVariants, setShowSuggestionsVariants] = useState(false);
  const [vehicleVariantsAuto, setVehicleVariantsAuto] = useState("");
  const [vehicleVariantsId, setVehicleVariantsId] = useState("");
  const [variantModal, setVariantModal] = useState(false);
  const [partETAIVariant, setPartETAIVariant] = useState("");

  const handleChangeVariants = (e) => {
    const userInput = e.target.value;

    let data = {
      limit: params.limit,
      page: params.page,
      search: userInput,
      is_reload: true,
      // model_id: 7,
    };
    if (userInput.length > 0) {
      getEtaiSearchList(data)
        .then((response) => {
          setFilteredSuggestionsVariants(response.data.data);
        })
        .catch((err) => {});
    }

    setVehicleVariantsAuto(userInput);
    setActiveSuggestionIndexVariants(0);
    setShowSuggestionsVariants(true);
  };

  const handleClickVariants = (suggestion) => {
    setVehicleVariantsId(suggestion?.id);
    setVehicleVariantsAuto(suggestion.product_label);
    setFilteredSuggestionsVariants([]);
    setShowSuggestionsVariants(false);
  };

  const handleKeyDownVariants = (e) => {
    if (e.key === "ArrowDown") {
      if (
        filteredSuggestionsVariants &&
        activeSuggestionIndexVariants < filteredSuggestionsVariants?.length - 1
      ) {
        setActiveSuggestionIndexVariants(activeSuggestionIndexVariants + 1);
      }
    } else if (e.key === "ArrowUp") {
      if (activeSuggestionIndexVariants > 0) {
        setActiveSuggestionIndexVariants(activeSuggestionIndexVariants - 1);
      }
    } else if (e.key === "Enter") {
      setVehicleVariantsAuto(
        filteredSuggestionsVariants[activeSuggestionIndexVariants]
      );
      setShowSuggestionsVariants(false);
      setFilteredSuggestionsVariants([]);
    }
  };

  const handleVariantOpen = (e, item) => {
    setVariantModal(true);
    setPartETAIVariant(item);
  };

  const handleVariantClose = () => {
    setVariantModal(false);
    setVehicleVariantsAuto("");
  };

  const handleAddClick = (id, name) => {
    const isAdded = addedParts.some((part) => part.id === id);

    if (isAdded) {
      setAddedParts((prev) => prev.filter((part) => part.id !== id));
    } else {
      setAddedParts((prev) => [...prev, { id, name }]);
    }
  };

  const filteredPartAssignList = partAssignList.filter(
    (item) => !addedParts.some((addedPart) => addedPart.id === item.id)
  );

  useEffect(() => {
    if (partETAIVariant) {
      setVehicleVariantsAuto(partETAIVariant?.etai_part_name);
    }
  }, [partETAIVariant]);

  const handleOpenPercentage = () => {
    setPercentageModal(true);
  };

  const handleClosePercentage = () => {
    setPercentageModal(false);
  };

  const handleImportModal = () => {
    setModifyImportModal(true);
  };

  const handleImportCloseModal = () => {
    setModifyImportModal(false);
  };

  const handleCloseModal = () => {
    setModifyModal(false);
    setPartLocationData("");
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    } else if (type === "paginations") {
      paramsObj.pages = e;
    }
    setParams(paramsObj);
  };

  const fetchPartCatLog = () => {
    let data = { ...params };
    getPartCatalogList(data)
      .then((response) => {
        setPartList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(() => {
    if (percentageModal === true) {
      let data = {
        ...params,
      };
      getPartCatalogList(data)
        .then((response) => {
          setPartAssignList(response.data.data); // Data for the current page
          setTotalRecordsAssign(response.data.total); // Total records available
        })
        .catch((err) => {
          toast.dismiss();
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [params.pages, params.limit, percentageModal]);

  const fetchExportPartCatLog = () => {
    let data = { ...params };

    getExportPartCatalogList(data)
      .then((response) => {
        const worksheet = XLSX.utils.json_to_sheet(response.data.data);
        const csv = XLSX.utils.sheet_to_csv(worksheet);

        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "PartCatalog.csv");
        link.style.visibility = "hidden";

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };
      fetchPartCatLog(data);
    },
    [params]
  );

  const onDeleteModalClose = () => {
    setDelModal(false);
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelPart(item.id);
    setDelModal(true);
  };

  const onDestroyPartType = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { id: delpart };
    deletePartCatalog(data)
      .then((response) => {
        let datas = { ...params };
        fetchPartCatLog(datas);
        toast.success(response.data.message);
        setDelPart("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handlePartLocationForm = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };
    if (!partLocationData.ler_code) {
      errorObj.ler_code = `LER Code required.`;
      error = true;
    }

    if (!partLocationData.item_weight) {
      errorObj.item_weight = `Item Weight required.`;
      error = true;
    }

    if (!partLocationData.item_weight_unit) {
      errorObj.item_weight_unit = `Item Weight Unit required.`;
      error = true;
    }

    setErrors(errorObj);
    Utils.focusError();
    if (error) return;

    setIsModifyButtonLoad(true);
    let data = {
      ler_code: partLocationData.ler_code,
      item_weight: partLocationData.item_weight,
      item_weight_unit: partLocationData.item_weight_unit,
    };

    let Updateddata = {
      uuid: partLocationData?.uuid,
      ler_code: partLocationData.ler_code,
      item_weight: partLocationData.item_weight,
      item_weight_unit: partLocationData.item_weight_unit,
    };

    if (partLocationData?.uuid) {
      createPartCatalog(Updateddata)
        .then((response) => {
          toast.success(response.data.message);
          let datas = { ...params };
          fetchPartCatLog(datas);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    } else {
      createPartCatalog(data)
        .then((response) => {
          toast.success(response.data.message);
          let datas = { ...params };
          fetchPartCatLog(datas);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    }
  };

  const handleImportLocation = (e) => {
    e.preventDefault();

    let error = false;
    let errorObjImport = { ...errorsObjImport };
    if (!file) {
      errorObjImport.file = `File is required.`;
      error = true;
    }

    setErrorsImport(errorObjImport);
    if (error) return;
    setIsModifyButtonLoad(true);
    const formData = new FormData();
    formData.append("files", file);

    importPartCatalog(formData)
      .then((response) => {
        toast.success(response.data.message);
        let datas = { ...params };
        fetchPartCatLog(datas);
        setModifyImportModal(false);
        setIsModifyButtonLoad(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };
  const handleExport = () => {
    let data = { ...params };
    fetchExportPartCatLog(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsModifyButtonLoad(true);

    const partsCatalogId = addedParts.map((item) => item.id).join(",");

    const payload = {
      parts_catalog_id: partsCatalogId,
      percentage: percentageData.percentage,
    };

    actionPartCatalogList(payload)
      .then((response) => {
        toast.success(response.data.message);
        setPercentageModal(false);
        setIsModifyButtonLoad(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };

  const handlePartEtaiVariantForm = (e) => {
    e.preventDefault();

    let data = {
      part_catalog_id: partETAIVariant?.id,
      etai_part_id: vehicleVariantsId
        ? vehicleVariantsId
        : partETAIVariant?.etai_part_id,
    };

    actionPartAssignEtai(data)
      .then((response) => {
        toast.success(response.data.message);
        let datas = { ...params };
        fetchPartCatLog(datas);
        setVariantModal(false);
        setVehicleVariantsAuto("");
        setIsModifyButtonLoad(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };

  return (
    <div>
      <>
        <div>
          {/* Your existing content goes here */}
          <section className="content-header">
            <div className="container-fluid ">
              <div className="row mb-2">
                <div className="col-sm-6">{/* <h1>Part Catalog</h1> */}</div>
                <div className="col-sm-6"></div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid container-custom-height-new">
              <div className="row">
                <div className="col-md-12">
                  <div className="card fuelCard">
                    <div className="card-header">
                      <div
                        className="card-title m-0 float-left"
                        style={{ width: "50%" }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          value={params.search}
                          onChange={(e) => handleParams(e, "search")}
                          placeholder="Search"
                        />
                      </div>
                      <div className="card-title m-0 float-right d-flex">
                        <button
                          type="button"
                          className="btn btn-icon-text mr-2"
                          onClick={handleOpenPercentage}
                        >
                          <i className="bi bi-percent"></i>
                          <span className="ms-2">{t("assign")}</span>
                        </button>
                        {RolePermission?.findIndex(
                          (e) => e.permission_name === "import_part_catalog"
                        ) > -1 && (
                          <button
                            type="button"
                            className="btn btn-icon-text mr-2"
                            onClick={handleImportModal}
                          >
                            <i className="bi bi-file-earmark-excel-fill"></i>
                            <span className="ms-2">{t("import")}</span>
                          </button>
                        )}
                        {RolePermission?.findIndex(
                          (e) => e.permission_name === "export_part_catalog"
                        ) > -1 && (
                          <button
                            type="button"
                            className="btn btn-icon-text mr-2"
                            onClick={handleExport}
                          >
                            <i className="bi bi-file-earmark-excel-fill"></i>
                            <span className="ms-2">{t("export")}</span>
                          </button>
                        )}
                        {RolePermission?.findIndex(
                          (e) => e.permission_name === "add_part_catalog"
                        ) > -1 && (
                          <button
                            type="button"
                            className="btn btn-icon-text mr-2"
                            onClick={() => navigate("/add-part-catalog")}
                          >
                            <i className="bi bi-plus-lg"></i>
                            <span className="ms-2">{t("Add")}</span>
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="vehicleThBg table-responsive table-responsive-vehicle">
                        <table className="table table-bordered rounded-table table table-striped table-hover">
                          <thead>
                            <tr>
                              <th style={{ width: "0%" }}>#</th>
                              <th style={{ width: "10%" }}>
                                {LangData === "pt"
                                  ? "Part Catalog Description"
                                  : LangData === "fr"
                                  ? "Part Catalog Description"
                                  : LangData === "es"
                                  ? "Part Catalog Description"
                                  : LangData === "it"
                                  ? "Part Catalog Description"
                                  : LangData === "de"
                                  ? "Part Catalog Description"
                                  : "Part Catalog Description"}
                              </th>

                              <th style={{ width: "10%" }}>{t("body_car")}</th>
                              <th style={{ width: "10%" }}>{t("car_doors")}</th>
                              <th style={{ width: "0%" }}>{t("percentage")}</th>
                              <th style={{ width: "0%" }}>{t("action")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {partList?.map((item, index) => (
                              <tr key={item.id}>
                                <td>{++index}</td>
                                <td>
                                  {LangData === "pt"
                                    ? item.namePartPT
                                    : LangData === "fr"
                                    ? item.namePartFR
                                    : LangData === "es"
                                    ? item.namePartES
                                    : LangData === "it"
                                    ? item.namePartIT
                                    : LangData === "de"
                                    ? item.namePartDE
                                    : item.namePartEN}
                                </td>
                                <td>{item.dependsBodyCar}</td>
                                <td>{item.numCarDoors}</td>
                                <td>{item.percentage}</td>

                                <td>
                                  <div className="d-flex">
                                    <Dropdown
                                      className="iconDropdown"
                                      onToggle={(isOpen) => {
                                        if (isOpen) {
                                          // This logic runs when the dropdown is opened
                                          const tableResponsive =
                                            document.querySelector(
                                              ".table-responsive"
                                            );
                                          if (tableResponsive) {
                                            tableResponsive.style.overflowX =
                                              "inherit !important";
                                            tableResponsive.style.overflowY =
                                              "inherit !important";
                                          }
                                        }
                                      }}
                                    >
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                        className="iconToggle"
                                      >
                                        <i className="bi bi-three-dots-vertical"></i>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          href="#/action-1"
                                          className="d-flex"
                                          onClick={() =>
                                            navigate(
                                              `/add-part-catalog/${item.id}`,
                                              { state: item }
                                            )
                                          }
                                        >
                                          {(item.company_id > 0 ||
                                            companyId.role_id === "1") &&
                                            RolePermission?.findIndex(
                                              (e) =>
                                                e.permission_name ===
                                                "modify_part_catalog"
                                            ) > -1 && (
                                              <a className="" href="#"></a>
                                            )}
                                          <div className="edit-btn">
                                            <i className="bi bi-pencil"></i>
                                          </div>
                                          <span>{t("edit")}</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#/action-2"
                                          className="d-flex"
                                          onClick={(e) =>
                                            onDeleteModalOpen(e, item)
                                          }
                                        >
                                          {(item.company_id > 0 ||
                                            companyId.role_id === "1") &&
                                            RolePermission?.findIndex(
                                              (e) =>
                                                e.permission_name ===
                                                "delete_part_catalog"
                                            ) > -1 && (
                                              <div className="">
                                                <a className="" href="#"></a>
                                              </div>
                                            )}
                                          <div className="trash-btn">
                                            <a className="delete-btn">
                                              <i className="bi bi-trash3"></i>
                                            </a>
                                          </div>
                                          <span>{t("delete")}</span>
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                          href="#/action-2"
                                          className="d-flex"
                                          onClick={(e) =>
                                            handleVariantOpen(e, item)
                                          }
                                        >
                                          {(item.company_id > 0 ||
                                            companyId.role_id === "1") &&
                                            RolePermission?.findIndex(
                                              (e) =>
                                                e.permission_name ===
                                                "delete_part_catalog"
                                            ) > -1 && (
                                              <div className="">
                                                <a className="" href="#"></a>
                                              </div>
                                            )}
                                          <div className="edit-btn">
                                            <i class="bi bi-bag"></i>
                                          </div>
                                          <span>{t("assign_part_etai")}</span>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </td>
                              </tr>
                            ))}
                            {partList && partList.length === 0 && (
                              <tr>
                                <td colSpan={5} className="text-center">
                                  {t("no_record_found")}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer clearfix">
              <div className="pagination pagination-sm m-0 float-right card-footer-part">
                <Pagination
                  activePage={params.page}
                  itemsCountPerPage={parseInt(params.limit)}
                  totalItemsCount={parseInt(totalRecords)} // Total available records
                  pageRangeDisplayed={5} // Number of page numbers to show
                  itemClass={`page-item`}
                  linkClass={`page-link`}
                  onChange={(e) => handleParams(e, "pagination")}
                />
              </div>
            </div>
          </section>
        </div>
        {/* )} */}

        <Modal isOpen={delModal}>
          <ModalHeader>{`Delete Part Catalog`}</ModalHeader>
          <ModalBody>
            <p
              className={`mb-0`}
            >{`Are you sure you want to delete this Part Catalog?`}</p>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              disabled={isDelButtonLoad}
              onClick={onDeleteModalClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              {t("close")} <i className="bi bi-x-lg"></i>
            </button>

            {!isDelButtonLoad && (
              <button
                type="button"
                className="btn btn-block bg-red"
                style={{ width: "100px" }}
                onClick={onDestroyPartType}
              >
                {t("delete")} <i className="bi bi-trash3"></i>
              </button>
            )}
            {isDelButtonLoad && (
              <button
                color="secondary"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
              </button>
            )}
          </ModalFooter>
        </Modal>

        <Modal isOpen={modifyModal}>
          <ModalHeader>{t("part_location")}</ModalHeader>
          <Form method={`post`} onSubmit={handlePartLocationForm}>
            <ModalBody>
              <div className="row">
                <div className="col-md-12">
                  <FormGroup>
                    <Label className="col-form-label">{t("ler_code")}</Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="text"
                          value={partLocationData.ler_code}
                          onChange={(e) => {
                            setPartLocationData({
                              ...partLocationData,
                              ler_code: e.target.value,
                            });
                          }}
                          name={`ler_code`}
                          maxLength={50}
                          placeholder="LER Code"
                        />
                      </div>
                      {errors.ler_code && (
                        <span className="input-error">{errors.ler_code}</span>
                      )}
                    </div>
                  </FormGroup>
                </div>

                <div className="col-md-12">
                  <FormGroup>
                    <Label className="col-form-label">{t("item_weight")}</Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="text"
                          value={partLocationData.item_weight}
                          onChange={(e) => {
                            setPartLocationData({
                              ...partLocationData,
                              item_weight: e.target.value,
                            });
                          }}
                          name={`item_weight`}
                          maxLength={50}
                          placeholder="Item Weight"
                        />
                      </div>
                      {errors.item_weight && (
                        <span className="input-error">
                          {errors.item_weight}
                        </span>
                      )}
                    </div>
                  </FormGroup>
                </div>

                <div className="col-md-12">
                  <FormGroup>
                    <Label className="col-form-label">
                      {t("item_weight_unit")}
                    </Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="text"
                          value={partLocationData.item_weight_unit}
                          onChange={(e) => {
                            setPartLocationData({
                              ...partLocationData,
                              item_weight_unit: e.target.value,
                            });
                          }}
                          name={`item_weight_unit`}
                          maxLength={50}
                          placeholder="Item Weight Unit"
                        />
                      </div>
                      {errors.item_weight_unit && (
                        <span className="input-error">
                          {errors.item_weight_unit}
                        </span>
                      )}
                    </div>
                  </FormGroup>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                className="btn btn-block btn-secondary"
                disabled={isModifyButtonLoad}
                onClick={handleCloseModal}
                style={{ width: "100px", marginTop: "10px" }}
              >
                {t("close")}
              </button>
              {!isModifyButtonLoad && (
                <button
                  type="submit"
                  className="btn btn-block btn-primary"
                  style={{ width: "100px", marginTop: "10px" }}
                >
                  {t("submit")}
                </button>
              )}
              {isModifyButtonLoad && (
                <Button
                  color="success"
                  type={`button`}
                  disabled={true}
                  className={`btn-square`}
                >
                  <i className="fa fa-circle-o-notch fa-spin"></i>{" "}
                  {`Loading...`}
                </Button>
              )}
            </ModalFooter>
          </Form>
        </Modal>

        <Modal isOpen={modifyImportModal}>
          <Form method={`post`} onSubmit={handleImportLocation}>
            <ModalHeader>{t("import_part_catalog")}</ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-md-12">
                  <FormGroup>
                    <Label className="col-form-label">{t("select_file")}</Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <input
                          className="form-control"
                          type="file"
                          onChange={(e) => setFile(e.target.files[0])}
                          name="file"
                          accept=".csv,.xlsx"
                        />
                      </div>
                      {errorsImport.file && (
                        <span className="input-error">{errorsImport.file}</span>
                      )}
                    </div>
                  </FormGroup>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                className="btn btn-block btn-secondary"
                disabled={isModifyButtonLoad}
                onClick={handleImportCloseModal}
                style={{ width: "100px", marginTop: "10px" }}
              >
                {t("close")}
              </button>
              {!isModifyButtonLoad && (
                <button
                  type="submit"
                  className="btn btn-block btn-primary"
                  style={{ width: "100px", marginTop: "10px" }}
                >
                  {t("import")}
                </button>
              )}
              {isModifyButtonLoad && (
                <Button
                  color="success"
                  type={`button`}
                  disabled={true}
                  className={`btn-square`}
                >
                  <i className="fa fa-circle-o-notch fa-spin"></i>{" "}
                  {`Loading...`}
                </Button>
              )}
            </ModalFooter>
          </Form>
        </Modal>

        <Modal isOpen={percentageModal} size="xl">
          <ModalHeader>{t("part_catalog")}</ModalHeader>
          <Form method={`post`} onSubmit={handleSubmit}>
            <ModalBody>
              <div className="row">
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3 ">
                      <Label className="col-form-label ml-2 Label-my form-label form-label">
                        {t("search")}
                      </Label>
                      <input
                        type="text"
                        className="form-control"
                        value={params.search}
                        onChange={(e) => handleParams(e, "search")}
                        placeholder="Search"
                      />
                    </div>
                    <div className="vehicleThBg tableScroll table-scroll-modal">
                      <table className="rounded-table table table-striped table-bordered table-hover">
                        <thead>
                          <tr>
                            <th style={{ width: "10%" }}>
                              {t("part_catalog")}
                            </th>

                            <th style={{ width: "0%" }}>{t("action")}</th>
                          </tr>
                        </thead>

                        <tbody>
                          {filteredPartAssignList?.length > 0 ? (
                            filteredPartAssignList?.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  {LangData === "pt"
                                    ? item.namePartPT
                                    : LangData === "fr"
                                    ? item.namePartFR
                                    : LangData === "es"
                                    ? item.namePartES
                                    : LangData === "it"
                                    ? item.namePartIT
                                    : LangData === "de"
                                    ? item.namePartDE
                                    : item.namePartEN}
                                </td>

                                <td>
                                  <Button
                                    className="addBtn"
                                    onClick={() =>
                                      handleAddClick(item.id, item.namePartPT)
                                    }
                                    style={{
                                      backgroundColor: addedParts.some(
                                        (part) => part.id === item.id
                                      )
                                        ? "red"
                                        : "green",
                                      color: "white",
                                    }}
                                  >
                                    <i className="bi bi-plus-lg"></i>{" "}
                                  </Button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="scrollBrdr">
                              <td colSpan="100%">{t("no_record_found")}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="card-footer clearfix p-0">
                      <div
                        className="pagination pagination-sm m-0 card-footer-part"
                        style={{ padding: "10px 0 0 0" }}
                      >
                        <Pagination
                          activePage={params.pages}
                          itemsCountPerPage={parseInt(params.limit)}
                          totalItemsCount={parseInt(totalRecordsAssign)}
                          pageRangeDisplayed={5}
                          itemClass={`page-item`}
                          linkClass={`page-link`}
                          onChange={(e) => handleParams(e, "paginations")}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <FormGroup>
                      <Label className="col-form-label ml-2 Label-my form-label form-label">
                        {t("percentage")}(%)
                      </Label>
                      <div className="col-xl-12">
                        <div className="input-div">
                          <input
                            className="form-control"
                            type="text"
                            value={percentageData.percentage}
                            onChange={(e) => {
                              setPercentageData({
                                ...percentageData,
                                percentage: e.target.value,
                              });
                            }}
                            name={`percentage`}
                            maxLength={5}
                            placeholder="Percentage(%)"
                          />
                        </div>
                      </div>
                    </FormGroup>
                    <div className="vehicleThBg tableScroll table-scroll-modal">
                      <table className="rounded-table table table-striped table-bordered table-hover">
                        <thead>
                          <tr>
                            <th style={{ width: "10%" }}>
                              {t("part_catalog")}
                            </th>

                            <th style={{ width: "0%" }}>{t("action")}</th>
                          </tr>
                        </thead>

                        <tbody>
                          {addedParts?.length > 0 ? (
                            addedParts?.map((item, index) => (
                              <tr key={index}>
                                <td>{item.name}</td>

                                <td>
                                  {/* <div className="trash-btn"> */}
                                  {/* <Button
                                    className="delete-btn"
                                    onClick={() =>
                                      handleAddClick(item.id, item.namePartPT)
                                    }
                                    style={{
                                      backgroundColor: addedParts.some(
                                        (part) => part.id === item.id
                                      )
                                        ? "red"
                                        : "green",
                                      color: "white",
                                    }}
                                  >
                                    <i className="bi bi-trash3"></i>
                                 
                                  </Button> */}

                                  <div className="trash-btn">
                                    <a
                                      className="delete-btn"
                                      href="#"
                                      onClick={() =>
                                        handleAddClick(item.id, item.namePartPT)
                                      }
                                    >
                                      <i className="bi bi-trash3"></i>
                                    </a>
                                  </div>
                                  {/* </div> */}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="scrollBrdr">
                              <td colSpan="100%">{t("no_record_found")}</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                className="btn btn-block btn-primary"
                disabled={isModifyButtonLoad}
                onClick={handleClosePercentage}
                style={{ width: "100px", marginTop: "10px" }}
              >
                {t("close")} <i className="bi bi-x-lg"></i>
              </button>
              {!isModifyButtonLoad && (
                <button
                  type="submit"
                  className="btn btn-block btn-primary nxt-btn"
                  style={{ width: "100px", marginTop: "10px" }}
                >
                  {t("submit")} <i className="bi bi-check2"></i>
                </button>
              )}
              {isModifyButtonLoad && (
                <Button
                  color="success"
                  type={`button`}
                  disabled={true}
                  className={`btn-square`}
                >
                  <i className="fa fa-circle-o-notch fa-spin"></i>{" "}
                  {`Loading...`}
                </Button>
              )}
            </ModalFooter>
          </Form>
        </Modal>

        <Modal isOpen={variantModal}>
          <Form method={`post`} onSubmit={handlePartEtaiVariantForm}>
            <ModalHeader>{t("assign_part_etai")}</ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-md-12">
                  <FormGroup>
                    <Label className="col-form-label ml-2 Label-my form-label form-label">
                      {t("select_etai")}
                    </Label>
                    <div className="col-xl-12">
                      <input
                        className="form-control mt-2 input-search"
                        type="text"
                        maxLength={50}
                        placeholder="Search..."
                        value={vehicleVariantsAuto}
                        onChange={handleChangeVariants}
                        onKeyDown={handleKeyDownVariants}
                        onClick={(e) => e.stopPropagation()}
                      />
                      {showSuggestionsVariants && vehicleVariantsAuto && (
                        <ul className="suggestions">
                          {filteredSuggestionsVariants?.length > 0 ? (
                            filteredSuggestionsVariants.map(
                              (suggestion, index) => (
                                <li
                                  key={suggestion}
                                  className={
                                    index === activeSuggestionIndexVariants
                                      ? "active"
                                      : ""
                                  }
                                  onClick={() =>
                                    handleClickVariants(suggestion)
                                  }
                                >
                                  {suggestion.product_label}
                                </li>
                              )
                            )
                          ) : (
                            <li>{t("no_suggestions_available")}</li>
                          )}
                        </ul>
                      )}
                    </div>
                  </FormGroup>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                className="btn btn-block btn-secondary"
                disabled={isModifyButtonLoad}
                onClick={handleVariantClose}
                style={{ width: "100px", marginTop: "10px" }}
              >
                {t("close")}
              </button>
              {!isModifyButtonLoad && (
                <button
                  type="submit"
                  className="btn btn-block btn-primary"
                  style={{ width: "100px", marginTop: "10px" }}
                >
                  {t("submit")}
                </button>
              )}
              {isModifyButtonLoad && (
                <Button
                  color="success"
                  type={`button`}
                  disabled={true}
                  className={`btn-square`}
                >
                  <i className="fa fa-circle-o-notch fa-spin"></i>{" "}
                  {`Loading...`}
                </Button>
              )}
            </ModalFooter>
          </Form>
        </Modal>
      </>
    </div>
  );
};

export default PartCatalog;
