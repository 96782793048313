import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import * as XLSX from "xlsx";
import {
  DestoryPartBrand,
  createPartsBrand,
  exportPartBrandList,
  getPartBrandList,
  importPartBrand,
  updatePartsBrand,
} from "../../services/PartBrandServices";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";

const PartBrand = () => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);
  const LangData = localStorage.getItem("lng");
  const { t } = useTranslation();

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const location = useLocation();
  const RolePermission = Utils.getRecordForCurrentPath(location);
  const [partBrandList, setPartBrandList] = useState([]);

  const [delModal, setDelModal] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [delpart, setDelPart] = useState("");
  const [modifyModal, setModifyModal] = useState(false);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [partBrandData, setPartBrandData] = useState({
    id: "",
    descriptionPT: "",
    descriptionES: "",
    descriptionFR: "",
    descriptionIT: "",
    descriptionDE: "",
    descriptionUK: "",
  });

  const [modifyImportModal, setModifyImportModal] = useState(false);
  const [file, setFile] = useState(null);
  let errorsObj = {
    descriptionPT: "",
    descriptionES: "",
    descriptionFR: "",
    descriptionIT: "",
    descriptionDE: "",
    descriptionUK: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  let errorsObjImport = {
    file: "",
  };
  const [errorsImport, setErrorsImport] = useState(errorsObjImport);

  const handleImportModal = () => {
    setModifyImportModal(true);
  };

  const handleImportCloseModal = () => {
    setModifyImportModal(false);
  };

  const handleAddModal = () => {
    setModifyModal(true);
  };

  const handleCloseModal = () => {
    setModifyModal(false);
    setPartBrandData({
      id: "",
      descriptionPT: "",
      descriptionES: "",
      descriptionFR: "",
      descriptionIT: "",
      descriptionDE: "",
      descriptionUK: "",
    });
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const ferchPartBrand = () => {
    let data = { ...params };
    getPartBrandList(data)
      .then((response) => {
        setPartBrandList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const fetchExportPartLocation = () => {
    let data = { ...params };

    exportPartBrandList(data)
      .then((response) => {
        const worksheet = XLSX.utils.json_to_sheet(response.data.data);
        const csv = XLSX.utils.sheet_to_csv(worksheet);

        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "PartBrand.csv");
        link.style.visibility = "hidden";

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };

      ferchPartBrand(data);
    },
    [params]
  );

  const onDeleteModalClose = () => {
    setDelModal(false);
  };

  const onEditPartGroup = (e, item) => {
    setModifyModal(true);
    setPartBrandData({
      id: item?.id,
      descriptionPT: item?.descriptionPT,
      descriptionES: item?.descriptionES,
      descriptionFR: item?.descriptionFR,
      descriptionIT: item?.descriptionIT,
      descriptionDE: item?.descriptionDE,
      descriptionUK: item?.descriptionUK,
    });
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelPart(item.id);
    setDelModal(true);
  };

  const onDestroyPartType = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { id: delpart };
    DestoryPartBrand(data)
      .then((response) => {
        let data = { ...params };

        ferchPartBrand(data);
        toast.success(response.data.message);
        setDelPart("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handlePartGroupForm = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };
    if (!partBrandData.descriptionPT) {
      errorObj.descriptionPT = `Description PT required.`;
      error = true;
    }

    if (!partBrandData.descriptionES) {
      errorObj.descriptionES = `Description ES required.`;
      error = true;
    }
    if (!partBrandData.descriptionFR) {
      errorObj.descriptionFR = `Description FR required.`;
      error = true;
    }
    if (!partBrandData.descriptionIT) {
      errorObj.descriptionIT = `Description IT required.`;
      error = true;
    }
    if (!partBrandData.descriptionDE) {
      errorObj.descriptionDE = `Description DE required.`;
      error = true;
    }
    if (!partBrandData.descriptionUK) {
      errorObj.descriptionUK = `Description UK required.`;
      error = true;
    }

    setErrors(errorObj);
    Utils.focusError();
    if (error) return;

    setIsModifyButtonLoad(true);
    let data = {
      descriptionPT: partBrandData.descriptionPT,
      descriptionES: partBrandData.descriptionES,
      descriptionFR: partBrandData.descriptionFR,
      descriptionIT: partBrandData.descriptionIT,
      descriptionDE: partBrandData.descriptionDE,
      descriptionUK: partBrandData.descriptionUK,
    };

    let updateData = {
      id: partBrandData?.id,
      descriptionPT: partBrandData.descriptionPT,
      descriptionES: partBrandData.descriptionES,
      descriptionFR: partBrandData.descriptionFR,
      descriptionIT: partBrandData.descriptionIT,
      descriptionDE: partBrandData.descriptionDE,
      descriptionUK: partBrandData.descriptionUK,
    };

    if (partBrandData?.id) {
      updatePartsBrand(updateData)
        .then((response) => {
          toast.success(response.data.message);
          let data = { ...params };
          ferchPartBrand(data);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
          setPartBrandData({
            id: "",
            descriptionPT: "",
            descriptionES: "",
            descriptionFR: "",
            descriptionIT: "",
            descriptionDE: "",
            descriptionUK: "",
          });
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    } else {
      createPartsBrand(data)
        .then((response) => {
          toast.success(response.data.message);
          let data = { ...params };
          ferchPartBrand(data);
          setPartBrandData({
            id: "",
            descriptionPT: "",
            descriptionES: "",
            descriptionFR: "",
            descriptionIT: "",
            descriptionDE: "",
            descriptionUK: "",
          });
          setModifyModal(false);
          setIsModifyButtonLoad(false);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    }
  };

  const handleImportGroup = (e) => {
    e.preventDefault();
    let error = false;
    let errorObjImport = { ...errorsObjImport };
    if (!file) {
      errorObjImport.file = `File is required.`;
      error = true;
    }

    setErrorsImport(errorObjImport);
    if (error) return;
    setIsModifyButtonLoad(true);
    const formData = new FormData();
    formData.append("files", file);

    importPartBrand(formData)
      .then((response) => {
        toast.success(response.data.message);
        let data = { ...params };
        ferchPartBrand(data);
        setModifyImportModal(false);
        setIsModifyButtonLoad(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };

  const handleExport = () => {
    let data = { ...params };
    fetchExportPartLocation(data);
  };
  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6"></div>
              <div className="col-sm-6"></div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid container-custom-height-new ">
            <div className="row">
              <div className="col-md-12">
                <div className="card fuelCard ">
                  <div className="card-header">
                    <div
                      className="card-title m-0 float-left"
                      style={{ width: "50%" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        value={params.search}
                        onChange={(e) => handleParams(e, "search")}
                        placeholder="Search"
                      ></input>
                    </div>
                    <div className="card-title m-0 float-right d-flex">
                      {RolePermission?.findIndex(
                        (e) => e.permission_name === "import_part_brand"
                      ) > -1 && (
                        <button
                          type="button"
                          className="btn  btn-icon-text mr-2"
                          onClick={handleImportModal}
                        >
                          <i className="bi bi-file-earmark-excel-fill"></i>
                          <span className="ms-2">{t("import")}</span>
                        </button>
                      )}

                      {RolePermission?.findIndex(
                        (e) => e.permission_name === "export_part_brand"
                      ) > -1 && (
                        <button
                          type="button"
                          className="btn  btn-icon-text mr-2"
                          onClick={handleExport}
                        >
                          <i className="bi bi-file-earmark-excel-fill"></i>
                          <span className="ms-2">{t("export")}</span>
                        </button>
                      )}

                      {RolePermission?.findIndex(
                        (e) => e.permission_name === "add_part_brand"
                      ) > -1 && (
                        <button
                          type="button"
                          className="btn  btn-icon-text mr-2"
                          onClick={handleAddModal}
                        >
                          <i className="bi bi-plus-lg"></i>
                          <span className="ms-2">{t("Add")}</span>
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="vehicleThBg table-responsive table-responsive-vehicle">
                      <table className="table table-bordered rounded-table table table-striped table-hover">
                        <thead>
                          <tr>
                            <th style={{ width: "1%" }}>#</th>
                            <th
                              className="col-form-label ml-2 Label-my form-label form-label"
                              style={{ width: "10%" }}
                            >
                              {LangData === "pt"
                                ? "Part Brand Description"
                                : LangData === "fr"
                                ? "Part Brand Description"
                                : LangData === "es"
                                ? "Part Brand Description"
                                : LangData === "it"
                                ? "Part Brand Description"
                                : LangData === "de"
                                ? "Part Brand Description"
                                : "Part Brand Description"}
                            </th>
                            <th
                              className="col-form-label ml-2 Label-my form-label form-label"
                              style={{ textAlign: "center", width: "0%" }}
                            >
                              {t("action")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {partBrandList?.map((item, index) => {
                            return (
                              <>
                                <tr>
                                  <td>{++index}</td>
                                  <td>
                                    {LangData === "pt"
                                      ? item.descriptionPT
                                      : LangData === "fr"
                                      ? item.descriptionFR
                                      : LangData === "es"
                                      ? item.descriptionES
                                      : LangData === "it"
                                      ? item.descriptionIT
                                      : LangData === "de"
                                      ? item.descriptionDE
                                      : item.descriptionUK}
                                  </td>

                                  <td className="d-flex">
                                    <Dropdown
                                      className="iconDropdown"
                                      onToggle={(isOpen) => {
                                        if (isOpen) {
                                          // This logic runs when the dropdown is opened
                                          const tableResponsive =
                                            document.querySelector(
                                              ".table-responsive"
                                            );
                                          if (tableResponsive) {
                                            tableResponsive.style.overflowX =
                                              "inherit !important";
                                            tableResponsive.style.overflowY =
                                              "inherit !important";
                                          }
                                        }
                                      }}
                                    >
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                        className="iconToggle"
                                      >
                                        <i className="bi bi-three-dots-vertical"></i>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          href="#/action-1"
                                          className="d-flex"
                                          onClick={(e) =>
                                            onEditPartGroup(e, item)
                                          }
                                        >
                                          {(item.company_id > 0 ||
                                            companyId.role_id == 1) &&
                                            RolePermission?.findIndex(
                                              (e) =>
                                                e.permission_name ===
                                                "modify_part_brand"
                                            ) > -1 && (
                                              <>
                                                <div className="edit-btn">
                                                  <i className="bi bi-pencil"></i>
                                                </div>
                                                <span>{t("edit")}</span>
                                              </>
                                            )}
                                          <div className="edit-btn">
                                            <i className="bi bi-pencil"></i>
                                          </div>
                                          <span>{t("edit")}</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#/action-2"
                                          className="d-flex"
                                          onClick={(e) =>
                                            onDeleteModalOpen(e, item)
                                          }
                                        >
                                          {(item.company_id > 0 ||
                                            companyId.role_id == 1) &&
                                            RolePermission?.findIndex(
                                              (e) =>
                                                e.permission_name ===
                                                "delete_part_brand"
                                            ) > -1 && (
                                              <>
                                                <div className="trash-btn delete-btn">
                                                  <i className="bi bi-trash3"></i>
                                                </div>

                                                <span>{t("delete")}</span>
                                              </>
                                            )}
                                          <div className="trash-btn delete-btn">
                                            <i className="bi bi-trash3"></i>
                                          </div>

                                          <span>{t("delete")}</span>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                        {partBrandList && partBrandList?.length === 0 && (
                          <tr>
                            <td colSpan={7} className={`text-center`}>
                              {" "}
                              {t("no_record_found")}
                            </td>
                          </tr>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fuelCF">
            <div className="card-footer clearfix card-footer-part ">
              <div className="pagination pagination-sm m-0 float-right">
                <Pagination
                  activePage={params.page}
                  itemsCountPerPage={parseInt(params.limit)}
                  totalItemsCount={parseInt(totalRecords)}
                  pageRangeDisplayed={5}
                  itemClass={`page-item`}
                  linkClass={`page-link`}
                  onChange={(e) => handleParams(e, "pagination")}
                />
              </div>
            </div>
          </div>
        </section>
      </div>

      <Modal isOpen={delModal}>
        <ModalHeader>
          {t("delete_part")}
          {t("brand")}
        </ModalHeader>
        <ModalBody>
          <p className={`mb-0`}>
            {t("are_you_sure_you_want_to_delete_this_part_brand")}
          </p>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDelButtonLoad}
            onClick={onDeleteModalClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            {t("close")} <i className="bi bi-x-lg"></i>
          </button>

          {!isDelButtonLoad && (
            <button
              type="button"
              className="btn btn-block bg-red"
              style={{ width: "100px" }}
              onClick={onDestroyPartType}
            >
              {t("delete")} <i className="bi bi-trash3"></i>
            </button>
          )}
          {isDelButtonLoad && (
            <button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
            </button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyModal}>
        <ModalHeader>
          {t("part")} {t("brand")}
        </ModalHeader>
        <Form method={`post`} onSubmit={handlePartGroupForm}>
          <ModalBody>
            <div className="row">
              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {t("description")}PT
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={partBrandData.descriptionPT}
                        onChange={(e) => {
                          setPartBrandData({
                            ...partBrandData,
                            descriptionPT: e.target.value,
                          });
                        }}
                        name={`descriptionPT`}
                        maxLength={50}
                        placeholder="Description PT"
                      />
                    </div>
                    {errors.descriptionPT && (
                      <span className="input-error">
                        {errors.descriptionPT}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {t("description")}ES
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={partBrandData.descriptionES}
                        onChange={(e) => {
                          setPartBrandData({
                            ...partBrandData,
                            descriptionES: e.target.value,
                          });
                        }}
                        name={`descriptionES`}
                        maxLength={50}
                        placeholder="Description ES"
                      />
                    </div>
                    {errors.descriptionES && (
                      <span className="input-error">
                        {errors.descriptionES}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {t("description")}FR
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={partBrandData.descriptionFR}
                        onChange={(e) => {
                          setPartBrandData({
                            ...partBrandData,
                            descriptionFR: e.target.value,
                          });
                        }}
                        name={`descriptionFR`}
                        maxLength={50}
                        placeholder="Description FR"
                      />
                    </div>
                    {errors.descriptionFR && (
                      <span className="input-error">
                        {errors.descriptionFR}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {t("description")}IT
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={partBrandData.descriptionIT}
                        onChange={(e) => {
                          setPartBrandData({
                            ...partBrandData,
                            descriptionIT: e.target.value,
                          });
                        }}
                        name={`descriptionIT`}
                        maxLength={50}
                        placeholder="Description IT"
                      />
                    </div>
                    {errors.descriptionIT && (
                      <span className="input-error">
                        {errors.descriptionIT}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {t("description")}DE
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={partBrandData.descriptionDE}
                        onChange={(e) => {
                          setPartBrandData({
                            ...partBrandData,
                            descriptionDE: e.target.value,
                          });
                        }}
                        name={`descriptionDE`}
                        maxLength={50}
                        placeholder="Description DE"
                      />
                    </div>
                    {errors.descriptionDE && (
                      <span className="input-error">
                        {errors.descriptionDE}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {t("description")}UK
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={partBrandData.descriptionUK}
                        onChange={(e) => {
                          setPartBrandData({
                            ...partBrandData,
                            descriptionUK: e.target.value,
                          });
                        }}
                        name={`descriptionUK`}
                        maxLength={40}
                        placeholder="Description UK"
                      />
                    </div>
                    {errors.descriptionUK && (
                      <span className="input-error">
                        {errors.descriptionUK}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-primary"
              disabled={isModifyButtonLoad}
              onClick={handleCloseModal}
              style={{ width: "100px", marginTop: "10px" }}
            >
              {t("close")} <i class="bi bi-x-lg"></i>
            </button>
            {!isModifyButtonLoad && (
              <button
                type="submit"
                class="btn btn-block btn-primary"
                style={{ width: "100px", marginTop: "10px" }}
              >
                {t("submit")} <i class="bi bi-check2"></i>
              </button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modifyImportModal}>
        <Form method={`post`} onSubmit={handleImportGroup}>
          <ModalHeader>
            {t("import")}
            {t("part")} {t("brand")}
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label">{t("select_file")}</Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control"
                        type="file"
                        onChange={(e) => setFile(e.target.files[0])}
                        name="file"
                        accept=".csv,.xlsx" // adjust file types as needed
                      />
                    </div>
                    {errorsImport.file && (
                      <span className="input-error">{errorsImport.file}</span>
                    )}
                  </div>
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-secondary"
              disabled={isModifyButtonLoad}
              onClick={handleImportCloseModal}
              style={{ width: "100px", marginTop: "10px" }}
            >
              {t("close")}
            </button>
            {!isModifyButtonLoad && (
              <button
                type="submit"
                class="btn btn-block btn-primary"
                style={{ width: "100px", marginTop: "10px" }}
              >
                {t("import")}
              </button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default PartBrand;
