import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  createVehicle,
  getCountryAllist,
  getVehicleCategoryAllList,
  getVehicleFuelAllList,
  getvehicleMakeAllList,
  getVehicleModelAllList,
  getVehiclePlateAllist,
  getVehicleTecEtaiDetails,
  getVehicleVinAllist,
  getvehicleYearList,
  printVehicleQr,
  VehicleETAISerachMake,
  VehicleETAISerachModel,
  VehicleETAISerachVariants,
} from "../../services/VehicleService";
import { getVehicleTypeAllList } from "../../services/VehicleType";
import moment from "moment";
import ConfigDB from "../../config";
import {
  actionAddDepollutionOpration,
  getLerCodeList,
} from "../../services/DepollutionService";
import {
  getPartInventoryMakeList,
  getPartInventoryModelList,
  getPartInventoryYearList,
} from "../../services/PartsInventory";
import { getAllViewShelfList } from "../../services/ShelfServices";
import { geCertificateDetails } from "../../services/CertificateServices";
import { getVehicleAllModalSerachList } from "../../services/VehicleModelService";
import { getVehicleSerachVariantList } from "../../services/VehicleVariantsService";
import { getAllVehicleSerachType } from "../../services/VehicleMakeService";
import { getVehicleEngineSerachList } from "../../services/VehicleEngineService";
import { getCommonSearchList } from "../../services/settingsServices";
import { useTranslation } from "react-i18next";
const ProcessType = [
  { value: 1, label: "Depollution" },
  { value: 2, label: "Dismentle" },
  { value: 3, label: "Both" },
];

const VehicleSerachType = [
  { value: 1, label: "VIN Number" },
  { value: 2, label: "Plate Number" },
];

const Vehicles = ({
  setActiveTab,
  vehicleProcessList,
  setVehicleVinData,
  vehicleVinData,
  vehicleVinTecData,
  vehiclePlateData,
  countryAllData,
  AllvehicleSerachData,
  setAllVehicleSerachData,
  setVehiclePlateData,
  setVehicleVinTecData,
  certificateDetails,
}) => {
  const [vehicleEngineId, setVehicleEngineId] = useState({
    vehicle_engine_id: "",
    vehicle_kw: "",
    vehicle_hp: "",
  });
  const { t } = useTranslation();

  const [VehicleEngineData, setVehicleEngineData] = useState("");
  const [vehicleData, setVehicleData] = useState({
    uuid: "",
    vehicle_owner_id: "",
    vehicle_process_start_date: "",
    vehicle_process_end_date: "",
    registration: "",
    registration_plate: "",
    vehicle_frame: AllvehicleSerachData.vin ? AllvehicleSerachData.vin : "",
    vehicle_model_id: "",
    vehicle_brand: "",
    vehicle_model: "",
    vehicle_booklet_model: "",
    vehicle_motorization: "",
    vehicle_category: "",
    vehicle_year: "",
    vehicle_kms: "",
    vehicle_engine: "",
    vehicle_approval: "",
    vehicle_total_gross_weight: "",
    vehicle_fuel: "",
    vehicle_parts: "",
    vehicle_color: "",
    vehicle_cyllinder: "",
    vehicle_type: "",
    vehicle_tare: "",
    vehicle_eei: "",
    vehicle_internl_ref: "",
    vehicle_external_ref: "",
    vehicle_exp_value: "",
    vehicle_provenance: "",
    shelf_id: "",
    status: "",
    certificate_sync_id: "",
    vehicle_make_etai: "",
    vehicle_model_etai: "",

    vehicle_variants_etai: "",
    motorization: "",

    ccm3: "",
    power_kw: "",
    hp: "",
    inventory_status: 1,
    deplllution_status: 1,
    dismental_status: 1,
    certificate_number: "",
    vehicle_year_id: "",
    make_id: "",
    variants_id: "",
    vehicle_make_name: "",
    state_parking: "",
    vehicle_doors: "",
  });

  const tableLengthList = ConfigDB.data.dataTableLength;

  const [serachModal, setSerachModal] = useState(false);
  const [serach, setSerach] = useState("");

  let errorsObj = {
    // vehicle_process_type: "",
    vehicle_process_start_date: "",
    vehicle_process_end_date: "",
    registration: "",
    registration_plate: "",
    vehicle_frame: "",
    vehicle_model_id: "",
    vehicle_brand: "",
    vehicle_booklet_model: "",
    vehicle_motorization: "",
    vehicle_category: "",
    vehicle_year: "",
    vehicle_kms: "",
    vehicle_engine: "",
    vehicle_approval: "",
    vehicle_total_gross_weight: "",
    vehicle_fuel: "",
    vehicle_parts: "",
    vehicle_color: "",
    vehicle_cyllinder: "",
    vehicle_type: "",
    vehicle_tare: "",
    vehicle_eei: "",
    vehicle_internl_ref: "",
    vehicle_external_ref: "",
    vehicle_exp_value: "",
    vehicle_provenance: "",
    shelf_id: "",
    // body_sent_to_waste: "",
    status: "",
    vehicle_model: "",
    vehicle_fuel_Type_description: "",
    vehicle_make_etai: "",
    vehicle_model_etai: "",

    vehicle_variants_etai: "",
    motorization: "",

    ccm3: "",
    power_kw: "",
    hp: "",
    inventory_status: "",
    deplllution_status: "",
    dismental_status: "",
    certificate_number: "",
  };

  const [errors, setErrors] = useState(errorsObj);
  const navigate = useNavigate();
  const [VehicleCategoryList, setVehicleCategoryList] = useState([]);
  const [VehicleFuelList, setVehicleFuelList] = useState([]);
  const [VehicleTypeList, setVehicleTypeList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [btnLoader, setbtnLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [bodyWasteModal, setBodyWasteModal] = useState(false);
  const [vehiclePartsData, setVehiclePartsData] = useState({
    vehicle_id: "",
    ler_code_id: "",
    wastege_value: "",
    description: "",
  });
  const [LerCatalogList, setLerCatalogList] = useState([]);
  const [LerCatalogHistoryList, setLerCatalogHistoryList] = useState([]);
  const [inputValueLer, setInputValueLer] = useState("");
  const [yearList, SetYearList] = useState([]);
  const [modelList, SetModelList] = useState([]);
  const [categoryPartsData, setCategoryPartsData] = useState({
    vehicle_make: "",
    vehicle_year: "",
    vehicle_model: "",
  });
  const [makeList, SetMakeList] = useState([]);
  const [inputValueMake, setInputValueMake] = useState("");
  const [inputValueYear, setInputValueYear] = useState("");
  const [inputValueModal, setInputValueModal] = useState("");
  const [ShelfList, SetShelfList] = useState([]);
  const [filteredSuggestionsModel, setFilteredSuggestionsModel] = useState([]);
  const [activeSuggestionIndexModel, setActiveSuggestionIndexModel] =
    useState(0);
  const [showSuggestionsModel, setShowSuggestionsModel] = useState(false);
  const [vehicleModelAuto, setVehicleModelAuto] = useState("");
  const [vehicleModelId, setVehicleModelId] = useState({
    model_id: "",
    make_id: "",
  });

  const [filteredSuggestionsVariants, setFilteredSuggestionsVariants] =
    useState([]);
  const [activeSuggestionIndexVariants, setActiveSuggestionIndexVariants] =
    useState(0);
  const [showSuggestionsVariants, setShowSuggestionsVariants] = useState(false);
  const [vehicleVariantsAuto, setVehicleVariantsAuto] = useState("");
  const [vehicleVariantsId, setVehicleVariantsId] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [catalog, setCatalog] = useState("");
  const [vehicleTypeId, setVehicleTypeId] = useState("");
  const [filteredSuggestionsEngine, setFilteredSuggestionsEngine] = useState(
    []
  );
  const [activeSuggestionIndexEngine, setActiveSuggestionIndexEngine] =
    useState(0);
  const [showSuggestionsEngine, setShowSuggestionsEngine] = useState(false);
  const [vehicleEngineAuto, setVehicleEngineAuto] = useState("");

  let ownerId = localStorage.getItem("ownerList");
  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  const location = useLocation();
  const state = location.state;
  const [viewModal, setViewModal] = useState(false);
  const [vehicleTecEtaiList, setVehicleTecEtaiList] = useState({});
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedOptionCarTec, setSelectedOptionCarTec] = useState("");
  const [SelectedVehicleData, setSelectedVehicleData] = useState("");

  const [SelectedVehicleCarTecData, setSelectedVehicleCarTecData] =
    useState("");

  const [filteredSuggestionsMake, setFilteredSuggestionsMake] = useState([]);
  const [activeSuggestionIndexMake, setActiveSuggestionIndexMake] = useState(0);
  const [showSuggestionsMake, setShowSuggestionsMake] = useState(false);
  const [catalogMake, setCatalogMake] = useState("");
  const [vehicleMakeId, setVehicleMakeId] = useState("");

  const [filteredSuggestionsModalEtai, setFilteredSuggestionsModalEtai] =
    useState([]);
  const [activeSuggestionIndexModalEtai, setActiveSuggestionIndexModalEtai] =
    useState(0);
  const [showSuggestionsModalEtai, setShowSuggestionsModalEtai] =
    useState(false);
  const [catalogModalEtai, setCatalogModalEtai] = useState("");
  const [vehicleModalEtaiId, setVehicleModalEtaiId] = useState("");

  const [filteredSuggestionsVariantsEtai, setFilteredSuggestionsVariantsEtai] =
    useState([]);
  const [
    activeSuggestionIndexVariantsEtai,
    setActiveSuggestionIndexVariantsEtai,
  ] = useState(0);
  const [showSuggestionsVariantsEtai, setShowSuggestionsVariantsEtai] =
    useState(false);
  const [catalogVariantsEtai, setCatalogVariantsEtai] = useState("");
  const [vehicleVariantsEtaiId, setVehicleVariantsEtaiId] = useState("");
  const [inventoryList, setInventoryList] = useState([]);
  const [deplllutionList, setDeplllutionList] = useState([]);
  const [dismentalList, setDismentalList] = useState([]);

  const [filteredSuggestionsYear, setFilteredSuggestionsYear] = useState([]);
  const [activeSuggestionIndexYear, setActiveSuggestionIndexYear] = useState(0);
  const [showSuggestionsYear, setShowSuggestionsYear] = useState(false);
  const [vehicleYearAuto, setVehicleYearAuto] = useState("");
  const [filteredSuggestionsMakeAll, setFilteredSuggestionsMakeAll] = useState(
    []
  );
  const [activeSuggestionIndexMakeAll, setActiveSuggestionIndexMakeAll] =
    useState(0);
  const [showSuggestionsMakeAll, setShowSuggestionsMakeAll] = useState(false);
  const [catalogMakeAll, setCatalogMakeAll] = useState("");
  const [vehicleMakeIdAll, setVehicleMakeIdAll] = useState("");

  const [VehicleYearList, setVehicleYearList] = useState([]);
  const [inputSelctedeYearValue, setInputSelctedYearValue] = useState("");
  const [VehicleMakeList, setVehicleMakeList] = useState([]);
  const [inputSelctedeMakeValue, setInputSelctedMakeValue] = useState("");
  const [VehicleModalList, setVehicleModalList] = useState([]);
  const [inputSelctedeModalValue, setInputSelctedModalValue] = useState("");
  const [VehicleVariantList, setVehicleVariantList] = useState([]);
  const [inputSelctededVariantValue, setInputSelctedVariantValue] =
    useState("");
  const [VehicleMakeETAIList, setVehicleMakeETAIList] = useState([]);
  const [inputSelctedeMakeETAIValue, setInputSelctedMakeETAIValue] =
    useState("");
  const [VehicleModalETAIList, setVehicleModalETAIList] = useState([]);
  const [inputSelctedeModalETAIValue, setInputSelctedModalETAIValue] =
    useState("");
  const [VehicleVariantETAIList, setVehicleVariantETAIList] = useState([]);
  const [inputSelctededVariantETAIValue, setInputSelctedVariantETAIValue] =
    useState("");

  const [VehicleEngineList, setVehicleEngineList] = useState([]);
  const [inputSelctededEngineValue, setInputSelctedEngineValue] = useState("");
  const [stateParkingList, setStateParkingList] = useState([]);
  const [vehicleDoorList, setVehicleDoorList] = useState([]);

  const vehicleTypeMapping = {
    1: "Car",
    2: "Van",
    3: "Motorcycle",
  };

  const vehicleCategoryMapping = {
    1: "L",
    2: "M1",
    3: "M2",
    4: "M3",
    5: "N1",
    6: "N2",
    7: "N3",
    8: "O",
  };

  const vehicleFuelTypeMapping = {
    1: "Gasolina",
    2: "Diesel",
    3: "Electric",
    4: "LPG",
  };

  const [selectedVehicleCategory, setSelectedVehicleCategory] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [selectedVehicleFuel, setSelectedVehicleFuel] = useState(null);

  useEffect(() => {
    if (certificateDetails?.vehicle_type_id) {
      const mappedType = vehicleTypeMapping[certificateDetails.vehicle_type_id];
      const foundType = VehicleTypeList.find(
        (item) =>
          mappedType && item.label.toLowerCase() === mappedType.toLowerCase()
      );

      setSelectedVehicle(foundType || null);
    } else if (vehicleProcessList?.vehicle_type) {
      const foundType = VehicleTypeList.find(
        (item) => item.value === Number(vehicleProcessList.vehicle_type)
      );

      setSelectedVehicle(foundType || null);
    }
  }, [certificateDetails, vehicleProcessList]);

  useEffect(() => {
    if (selectedVehicle) {
      setVehicleData((prevData) => ({
        ...prevData,
        vehicle_type: selectedVehicle.value || "",
      }));
    }
  }, [selectedVehicle]);

  const handleVehicleChange = (selectedOption) => {
    setSelectedVehicle(selectedOption || null);
    setVehicleData((prevData) => ({
      ...prevData,
      vehicle_type: selectedOption ? selectedOption.value : "",
    }));
  };

  useEffect(() => {
    if (certificateDetails?.vehicle_category_id) {
      const mappedCategory =
        vehicleCategoryMapping[certificateDetails.vehicle_category_id];
      const foundCategory = VehicleCategoryList.find(
        (item) =>
          mappedCategory &&
          item.label.toLowerCase() === mappedCategory.toLowerCase()
      );

      setSelectedVehicleCategory(foundCategory || null);
    } else if (vehicleProcessList?.vehicle_category) {
      const foundCategory = VehicleCategoryList.find(
        (item) => item.value === Number(vehicleProcessList.vehicle_category)
      );

      setSelectedVehicleCategory(foundCategory || null);
    }
  }, [certificateDetails, vehicleProcessList]);

  useEffect(() => {
    if (selectedVehicleCategory) {
      setVehicleData((prevData) => ({
        ...prevData,
        vehicle_category: selectedVehicleCategory.value || "",
      }));
    }
  }, [selectedVehicleCategory]);

  const handleVehicleCategoryChange = (selectedOption) => {
    setSelectedVehicleCategory(selectedOption || null);
    setVehicleData((prevData) => ({
      ...prevData,
      vehicle_category: selectedOption ? selectedOption.value : "",
    }));
  };

  useEffect(() => {
    if (certificateDetails?.vehicle_fuel_Type_id) {
      const mappedFuel =
        vehicleFuelTypeMapping[certificateDetails.vehicle_fuel_Type_id];
      const foundFuel = VehicleFuelList.find(
        (item) =>
          mappedFuel && item.label.toLowerCase() === mappedFuel.toLowerCase()
      );

      setSelectedVehicleFuel(foundFuel || null);
    } else if (vehicleProcessList?.vehicle_fuel) {
      const foundFuel = VehicleFuelList.find(
        (item) => item.value === Number(vehicleProcessList.vehicle_fuel)
      );

      setSelectedVehicleFuel(foundFuel || null);
    }
  }, [certificateDetails, vehicleProcessList]);

  useEffect(() => {
    if (selectedVehicleFuel) {
      setVehicleData((prevData) => ({
        ...prevData,
        vehicle_fuel: selectedVehicleFuel.value || "",
      }));
    }
  }, [selectedVehicleFuel]);

  const handleVehicleFuelChange = (selectedOption) => {
    setSelectedVehicleFuel(selectedOption);
    setVehicleData({
      ...vehicleData,
      vehicle_fuel: selectedOption ? selectedOption.value : "",
    });
  };

  useEffect(
    function () {
      if (vehicleData?.variants_id) {
        let data = {
          limit: 10,
          page: params.page,
          search: "",
          is_reload: true,
          variants_id: vehicleData?.variants_id,
        };
        getVehicleEngineSerachList(data)
          .then((response) => {
            response = response.data;
            let list = [];
            for (let i = 0; i < response.data.length; i++) {
              list.push({
                value: response.data[i].id,
                label: response.data[i].motor_code,
                kw: response.data[i]?.kw,
                hp: response.data[i]?.hp,
                cyl: response.data[i]?.cyl,
              });
            }
            setVehicleEngineList(list);
          })
          .catch((err) => {});
      }
    },
    [vehicleData?.variants_id]
  );

  const handleInputSelectedChangeEngineList = (inputSelctededEngineValue) => {
    setInputSelctedEngineValue(inputSelctededEngineValue);

    if (inputSelctededEngineValue.length > 0) {
      const obj = {
        search: inputSelctededEngineValue,
        limit: tableLengthList[0],
        page: 1,
      };

      getVehicleEngineSerachList(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].motor_code,
              kw: response.data[i]?.kw,
              hp: response.data[i]?.hp,
              cyl: response.data[i]?.cyl,
            });
          }
          setVehicleEngineList(list);
        })
        .catch((err) => {});
    }
  };

  useEffect(function () {
    let data = {
      limit: 10,
      page: params.page,
      search: "",
      is_reload: true,
    };
    getvehicleYearList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i],
            label: response.data[i],
          });
        }
        setVehicleYearList(list);
      })
      .catch((err) => {});
  }, []);

  const handleInputSelectedChangeYearList = (inputSelctedeYearValue) => {
    setInputSelctedYearValue(inputSelctedeYearValue);
    const obj = {
      search: inputSelctedeYearValue,
      limit: tableLengthList[0],
      page: 1,
    };

    getvehicleYearList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i],
            label: response.data[i],
          });
        }
        setVehicleYearList(list);
      })
      .catch((err) => {});
  };

  useEffect(
    function () {
      if (vehicleData?.vehicle_year_id) {
        let data = {
          limit: params.limit,
          page: params.page,
          search: vehicleProcessList?.vehicle_make_name
            ? vehicleProcessList?.vehicle_make_name
            : "",
          year: vehicleData?.vehicle_year_id,
          is_reload: true,
        };
        getvehicleMakeAllList(data)
          .then((response) => {
            response = response.data;
            let list = [];
            for (let i = 0; i < response.data.length; i++) {
              list.push({
                value: response.data[i]?.id,
                label: response.data[i]?.description,
              });
            }
            setVehicleMakeList(list);
          })
          .catch((err) => {});
      }
    },
    [vehicleData?.vehicle_year_id]
  );

  const handleInputSelectedChangeMakeList = (inputSelctedeMakeValue) => {
    setInputSelctedMakeValue(inputSelctedeMakeValue);
    if (inputSelctedeMakeValue.length > 0) {
      const obj = {
        limit: params.limit,
        page: params.page,
        search: inputSelctedeMakeValue,
        year: vehicleData?.vehicle_year_id,
        is_reload: true,
      };

      getvehicleMakeAllList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i]?.id,
              label: response.data[i]?.description,
            });
          }
          setVehicleMakeList(list);
        })
        .catch((err) => {});
    }
  };

  useEffect(
    function () {
      if (vehicleData?.make_id) {
        let data = {
          limit: params.limit,
          page: params.page,
          search: "",
          vehicle_year: vehicleData?.vehicle_year_id,
          make_id: vehicleData?.make_id,
          is_reload: true,
        };

        getVehicleAllModalSerachList(data)
          .then((response) => {
            response = response.data;
            let list = [];
            for (let i = 0; i < response.data.length; i++) {
              list.push({
                value: response.data[i]?.id,
                label: response.data[i]?.model_name,
              });
            }
            setVehicleModalList(list);
          })
          .catch((err) => {});
      }
    },
    [vehicleData?.make_id]
  );

  const handleInputSelectedChangeModalList = (inputSelctedeModalValue) => {
    setInputSelctedModalValue(inputSelctedeModalValue);
    if (inputSelctedeModalValue.length > 0) {
      const obj = {
        limit: params.limit,
        page: params.page,
        search: inputSelctedeModalValue,
        make_id: vehicleData?.make_id,
        vehicle_year: vehicleData?.vehicle_year_id,
        is_reload: true,
      };

      getVehicleAllModalSerachList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i]?.id,
              label: response.data[i]?.model_name,
            });
          }
          setVehicleModalList(list);
        })
        .catch((err) => {});
    }
  };

  useEffect(
    function () {
      if (vehicleData?.vehicle_model_id) {
        let data = {
          limit: params.limit,
          page: params.page,
          search: "",
          vehicle_year: vehicleData?.vehicle_year_id,
          model_id: vehicleData.vehicle_model_id,

          is_reload: true,
        };
        getVehicleSerachVariantList(data)
          .then((response) => {
            response = response.data;
            let list = [];
            for (let i = 0; i < response.data.length; i++) {
              list.push({
                value: response.data[i]?.id,
                label: response.data[i]?.variants_name,
              });
            }
            setVehicleVariantList(list);
          })
          .catch((err) => {});
      }
    },
    [vehicleData?.vehicle_model_id]
  );

  const handleInputSelectedChangeVariantList = (inputSelctededVariantValue) => {
    setInputSelctedVariantValue(inputSelctededVariantValue);
    if (inputSelctededVariantValue.length > 0) {
      const obj = {
        limit: params.limit,
        page: params.page,
        search: inputSelctededVariantValue,
        vehicle_year: vehicleData?.vehicle_year_id,
        model_id: vehicleData.vehicle_model_id,
        is_reload: true,
      };

      getVehicleSerachVariantList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i]?.id,
              label: response.data[i]?.variants_name,
            });
          }
          setVehicleVariantList(list);
        })
        .catch((err) => {});
    }
  };

  useEffect(function () {
    let data = {
      limit: params.limit,
      page: params.page,
      search: "",
      is_reload: true,
    };
    VehicleETAISerachMake(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i]?.id,
            label: response.data[i]?.label,
          });
        }
        setVehicleMakeETAIList(list);
      })
      .catch((err) => {});
  }, []);

  const handleInputSelectedChangeMakeETAIList = (
    inputSelctedeMakeETAIValue
  ) => {
    setInputSelctedMakeETAIValue(inputSelctedeMakeETAIValue);

    if (inputSelctedeMakeETAIValue.length > 0) {
      const obj = {
        limit: params.limit,
        page: params.page,
        search: inputSelctedeMakeETAIValue,
        is_reload: true,
      };

      VehicleETAISerachMake(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i]?.id,
              label: response.data[i]?.label,
            });
          }
          setVehicleMakeETAIList(list);
        })
        .catch((err) => {});
    }
  };

  useEffect(
    function () {
      if (vehicleData?.vehicle_make_etai) {
        let data = {
          limit: params.limit,
          page: params.page,
          search: "",
          is_reload: true,
          make_id: vehicleData?.vehicle_make_etai,
        };
        VehicleETAISerachModel(data)
          .then((response) => {
            response = response.data;
            let list = [];
            for (let i = 0; i < response.data.length; i++) {
              list.push({
                value: response.data[i]?.id,
                label: response.data[i]?.label,
              });
            }
            setVehicleModalETAIList(list);
          })
          .catch((err) => {});
      }
    },
    [vehicleData?.vehicle_make_etai]
  );

  const handleInputSelectedChangeModalETAIList = (
    inputSelctedeModalETAIValue
  ) => {
    setInputSelctedModalETAIValue(inputSelctedeModalETAIValue);

    if (inputSelctedeModalETAIValue.length > 0) {
      const obj = {
        limit: params.limit,
        page: params.page,
        search: inputSelctedeModalETAIValue,
        is_reload: true,
        make_id: vehicleData?.vehicle_make_etai,
      };

      VehicleETAISerachModel(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i]?.id,
              label: response.data[i]?.label,
            });
          }
          setVehicleModalETAIList(list);
        })
        .catch((err) => {});
    }
  };

  useEffect(
    function () {
      if (vehicleData?.vehicle_model_etai) {
        let data = {
          limit: params.limit,
          page: params.page,
          search: "",
          is_reload: true,
          modal_id: vehicleData?.vehicle_model_etai,
        };
        VehicleETAISerachVariants(data)
          .then((response) => {
            response = response.data;
            let list = [];
            for (let i = 0; i < response.data.length; i++) {
              list.push({
                value: response.data[i]?.id,
                label: response.data[i]?.label,
              });
            }
            setVehicleVariantETAIList(list);
          })
          .catch((err) => {});
      }
    },
    [vehicleData?.vehicle_model_etai]
  );

  const handleInputSelectedChangeVariantETAIList = (
    inputSelctededVariantETAIValue
  ) => {
    setInputSelctedVariantETAIValue(inputSelctededVariantETAIValue);

    if (inputSelctededVariantETAIValue.length > 0) {
      const obj = {
        limit: params.limit,
        page: params.page,
        search: inputSelctededVariantETAIValue,
        is_reload: true,
        modal_id: vehicleData?.vehicle_model_etai,
      };

      VehicleETAISerachVariants(data)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i]?.id,
              label: response.data[i]?.label,
            });
          }
          setVehicleVariantETAIList(list);
        })
        .catch((err) => {});
    }
  };

  const handleViewOpen = () => {
    setViewModal(true);
  };
  const handleViewClose = () => {
    setViewModal(false);
  };

  const handleOptionChange = (event, data) => {
    setSelectedOption(data?.vehicle_type_description);
    setSelectedVehicleData(data);
    // setSerachModal(false);
  };

  const handleOptionChangeCarsTec = (event, data) => {
    setSelectedOptionCarTec(data?.vehicle_type_description);
    setSelectedVehicleCarTecData(data);
    // setSerachModal(false);
  };

  const handleSelectEtaiDetails = () => {
    if (!selectedOption) {
      toast.error("Please select a cars ETAI details.");
      return;
    } else if (!selectedOptionCarTec) {
      toast.error("Please select a cars tecDoc details.");
      return;
    }

    if (selectedOption && selectedOptionCarTec) {
      let data = {
        id_modelo_variante: SelectedVehicleData?.vehicle_type_model_id_catalog,
        etai_enternal_id: SelectedVehicleData?.vehicle_type_model_id_external,
      };

      getVehicleTecEtaiDetails(data)
        .then((response) => {
          setVehicleTecEtaiList(response.data.data);
          setSerachModal(false);
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {}, [vehicleTecEtaiList]);

  const handleChangeModel = (e) => {
    const userInput = e.target.value;
    if (vehicleMakeIdAll) {
      let data = {
        limit: params.limit,
        page: params.page,
        search: userInput,
        is_reload: true,
        make_id: vehicleMakeIdAll,
      };
      if (userInput.length > 0) {
        getVehicleAllModalSerachList(data)
          .then((response) => {
            setFilteredSuggestionsModel(response.data.data);
          })
          .catch((err) => {
            toast.error(Utils.getErrorMessage(err));
          });
      }

      setVehicleModelAuto(userInput);
      setActiveSuggestionIndexModel(0);
      setShowSuggestionsModel(true);
    }
  };

  const handleClickModel = (suggestion) => {
    setVehicleModelId({
      model_id: suggestion?.id,
      make_id: suggestion?.make_id,
    });
    setVehicleModelAuto(suggestion.model_name);
    setFilteredSuggestionsModel([]);
    setShowSuggestionsModel(false);
  };

  const handleKeyDownModel = (e) => {
    if (e.key === "ArrowDown") {
      if (
        filteredSuggestionsModel &&
        activeSuggestionIndexModel < filteredSuggestionsModel?.length - 1
      ) {
        setActiveSuggestionIndexModel(activeSuggestionIndexModel + 1);
      }
    } else if (e.key === "ArrowUp") {
      if (activeSuggestionIndexModel > 0) {
        setActiveSuggestionIndexModel(activeSuggestionIndexModel - 1);
      }
    } else if (e.key === "Enter") {
      setVehicleModelAuto(filteredSuggestionsModel[activeSuggestionIndexModel]);
      setShowSuggestionsModel(false);
      setFilteredSuggestionsModel([]);
    }
  };

  const handleInputClickModel = () => {
    if (!vehicleModelAuto && vehicleMakeIdAll) {
      let data = {
        limit: 10,
        page: params.page,
        search: "",
        is_reload: true,
        make_id: vehicleMakeIdAll,
      };

      getVehicleAllModalSerachList(data)
        .then((response) => {
          setFilteredSuggestionsModel(response.data.data);
        })
        .catch((err) => {
          console.error(err);
        });

      setShowSuggestionsModel(true);
    }
  };

  const handleChangeVariants = (e) => {
    const userInput = e.target.value;

    let data = {
      limit: params.limit,
      page: params.page,
      search: userInput,
      is_reload: true,
      model_id: vehicleModelId.model_id ? vehicleModelId.model_id : 0,
      // model_id: 7,
    };
    if (vehicleModelId.model_id) {
      getVehicleSerachVariantList(data)
        .then((response) => {
          setFilteredSuggestionsVariants(response.data.data);
        })
        .catch((err) => {});
    }
    if (serachModal === true) {
      let data = {
        limit: params.limit,
        page: params.page,
        search: userInput,
        is_reload: true,
        model_id: 0,
      };
      getVehicleSerachVariantList(data)
        .then((response) => {
          setFilteredSuggestionsVariants(response.data.data);
        })
        .catch((err) => {});
    }
    setVehicleVariantsAuto(userInput);
    setActiveSuggestionIndexVariants(0);
    setShowSuggestionsVariants(true);
  };

  const handleClickVariants = (suggestion) => {
    setVehicleVariantsId(suggestion?.id);
    setVehicleVariantsAuto(suggestion.variants_name);
    setFilteredSuggestionsVariants([]);
    setShowSuggestionsVariants(false);
  };

  const handleKeyDownVariants = (e) => {
    if (e.key === "ArrowDown") {
      if (
        filteredSuggestionsVariants &&
        activeSuggestionIndexVariants < filteredSuggestionsVariants?.length - 1
      ) {
        setActiveSuggestionIndexVariants(activeSuggestionIndexVariants + 1);
      }
    } else if (e.key === "ArrowUp") {
      if (activeSuggestionIndexVariants > 0) {
        setActiveSuggestionIndexVariants(activeSuggestionIndexVariants - 1);
      }
    } else if (e.key === "Enter") {
      setVehicleVariantsAuto(
        filteredSuggestionsVariants[activeSuggestionIndexVariants]
      );
      setShowSuggestionsVariants(false);
      setFilteredSuggestionsVariants([]);
    }
  };

  const handleInputClickVariants = () => {
    if (!vehicleVariantsAuto && vehicleModelId?.model_id) {
      let data = {
        limit: 10,
        page: params.page,
        search: "",
        is_reload: true,
        model_id: vehicleModelId.model_id ? vehicleModelId.model_id : 0,
      };

      getVehicleSerachVariantList(data)
        .then((response) => {
          setFilteredSuggestionsVariants(response.data.data);
        })
        .catch((err) => {});

      setShowSuggestionsVariants(true);
    }
  };

  const handleChange = (e) => {
    const userInput = e.target.value;

    let data = {
      limit: params.limit,
      page: params.page,
      search: userInput,
      is_reload: true,
    };
    getAllVehicleSerachType(data)
      .then((response) => {
        setFilteredSuggestions(response.data.data);
      })
      .catch((err) => {});

    setCatalog(userInput);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };

  const handleInputVehicleType = () => {
    if (!catalog) {
      let data = {
        limit: params.limit,
        page: params.page,
        search: "",
        is_reload: true,
      };
      getAllVehicleSerachType(data)
        .then((response) => {
          setFilteredSuggestions(response.data.data);
        })
        .catch((err) => {});
      setShowSuggestions(true);
    }
  };

  const handleClick = (suggestion) => {
    setVehicleTypeId(suggestion?.id);
    setCatalog(suggestion.vehicle_type_name);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      if (
        filteredSuggestions &&
        activeSuggestionIndex < filteredSuggestions?.length - 1
      ) {
        setActiveSuggestionIndex(activeSuggestionIndex + 1);
      }
    } else if (e.key === "ArrowUp") {
      if (activeSuggestionIndex > 0) {
        setActiveSuggestionIndex(activeSuggestionIndex - 1);
      }
    } else if (e.key === "Enter") {
      setCatalog(filteredSuggestions[activeSuggestionIndex]);
      setShowSuggestions(false);
      setFilteredSuggestions([]);
    }
  };

  const handleChangeEngine = (e) => {
    const userInput = e.target.value;

    let data = {
      limit: params.limit,
      page: params.page,
      search: userInput,
      is_reload: true,
    };
    if (userInput.length > 0) {
      getVehicleEngineSerachList(data)
        .then((response) => {
          setFilteredSuggestionsEngine(response.data.data);
        })
        .catch((err) => {});
    }

    setVehicleEngineAuto(userInput);
    setActiveSuggestionIndexEngine(0);
    setShowSuggestionsEngine(true);
  };

  const handleInputVehicleEngine = () => {
    if (!vehicleEngineAuto) {
      let data = {
        limit: 10,
        page: 1,
        search: "",
        is_reload: true,
      };
      getVehicleEngineSerachList(data)
        .then((response) => {
          setFilteredSuggestionsEngine(response.data.data);
        })
        .catch((err) => {});
      setShowSuggestionsEngine(true);
    } else {
      setShowSuggestionsEngine(false);
    }
  };

  const handleClickEngine = (suggestion) => {
    setVehicleEngineId({
      vehicle_engine_id: suggestion?.id,
      vehicle_kw: suggestion?.kw,
      vehicle_hp: suggestion?.hp,
    });
    setVehicleEngineAuto(suggestion.motor_code);
    setFilteredSuggestionsEngine([]);
    setShowSuggestionsEngine(false);
  };

  const handleKeyDownEngine = (e) => {
    if (e.key === "ArrowDown") {
      if (
        filteredSuggestionsEngine &&
        activeSuggestionIndexEngine < filteredSuggestionsEngine?.length - 1
      ) {
        setActiveSuggestionIndexEngine(activeSuggestionIndexEngine + 1);
      }
    } else if (e.key === "ArrowUp") {
      if (activeSuggestionIndexEngine > 0) {
        setActiveSuggestionIndexEngine(activeSuggestionIndexEngine - 1);
      }
    } else if (e.key === "Enter") {
      setVehicleEngineAuto(
        filteredSuggestionsEngine[activeSuggestionIndexEngine]
      );
      setShowSuggestionsEngine(false);
      setFilteredSuggestionsEngine([]);
    }
  };

  const handleBodyWasteOpen = () => {
    setBodyWasteModal(true);
  };

  const handleBodyWasteClose = () => {
    setBodyWasteModal(false);
  };

  const handleSelectLERChange = (selectedOption) => {
    setVehiclePartsData({
      ...vehiclePartsData,
      ler_code_id: selectedOption ? selectedOption.value : "",
    });
  };

  useEffect(() => {
    const obj = {
      search: "",
      limit: 10,
      page: 1,
      park_id: 0,
    };

    getAllViewShelfList(obj)
      .then((response) => {
        response = response.data;
        let list = [];

        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        SetShelfList(list);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    const obj = {
      search: "",
      limit: 5,
      page: 1,
    };

    getPartInventoryYearList(obj)
      .then((response) => {
        response = response.data;
        let list = [];

        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].vehicle_start_year,
            label: response.data[i].vehicle_start_year,
          });
        }
        SetYearList(list);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  useEffect(() => {
    if (categoryPartsData?.vehicle_year) {
      const obj = {
        search: "",
        vehicle_year: categoryPartsData?.vehicle_year,
        limit: 5,
        page: 1,
      };

      getPartInventoryMakeList(obj)
        .then((response) => {
          response = response.data;
          let list = [];

          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].vehicle_make,
              label: response.data[i].vehicle_make,
            });
          }
          SetMakeList(list);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [categoryPartsData?.vehicle_year]);

  useEffect(() => {
    if (categoryPartsData?.vehicle_make && categoryPartsData?.vehicle_year) {
      const obj = {
        search: "",
        vehicle_make: categoryPartsData?.vehicle_make,
        vehicle_start_year: categoryPartsData?.vehicle_year,
        limit: 10,
        page: 1,
      };

      getPartInventoryModelList(obj)
        .then((response) => {
          response = response.data;
          let list = [];

          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].vehicle_model,
              label: response.data[i].vehicle_model,
            });
          }
          SetModelList(list);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [categoryPartsData?.vehicle_make, categoryPartsData?.vehicle_year]);

  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };
    getLerCodeList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].ler_code,
          });
        }
        setLerCatalogList(list);
      })
      .catch((err) => {});
  }, []);

  const handleInputChangeLER = (inputValueLer) => {
    setInputValueLer(inputValueLer);

    if (inputValueLer.length > 2) {
      const obj = {
        search: inputValueLer,
        limit: tableLengthList[0],
        page: 1,
      };

      getLerCodeList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].ler_code,
            });
          }
          setLerCatalogList(list);
        })
        .catch((err) => {});
    } else {
      setLerCatalogList([]);
      const obj = {
        search: "",
        limit: tableLengthList[0],
        page: 1,
      };

      getLerCodeList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].ler_code,
            });
          }
          setLerCatalogList(list);
        })
        .catch((err) => {});
    }
  };

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "part_inventory",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setInventoryList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "state_parking",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setStateParkingList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "vehicle_no_door",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setVehicleDoorList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "depollution",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setDeplllutionList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "dismental",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setDismentalList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    getVehicleCategoryAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].category,
          });
        }
        setVehicleCategoryList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    getVehicleFuelAllList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].fuel_type,
          });
        }
        setVehicleFuelList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    getAllVehicleSerachType()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].vehicle_type_name,
          });
        }
        setVehicleTypeList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (vehicleProcessList) {
      setVehicleData((prevData) => ({
        ...prevData,
        certificate_sync_id: vehicleProcessList.certificate_sync_id,
        vehicle_owner_id: vehicleProcessList.vehicle_owner_id,
        // vehicle_process_type: vehicleProcessList.vehicle_process_type,
        vehicle_process_start_date: moment(
          vehicleProcessList.vehicle_process_start_date
        ).format("YYYY-MM-DD"),
        vehicle_process_end_date: moment(
          vehicleProcessList.vehicle_process_end_date
        ).format("YYYY-MM-DD"),
        registration: vehicleProcessList.registration,
        registration_plate: vehicleProcessList.registration_plate,
        vehicle_frame: vehicleProcessList.vehicle_frame,
        vehicle_model_id: vehicleProcessList.vehicle_model_id,
        vehicle_year_id: vehicleProcessList.vehicle_year_id,
        make_id: vehicleProcessList.make_id,
        variants_id: vehicleProcessList.variants_id,
        vehicle_brand: vehicleProcessList.vehicle_brand,
        vehicle_model: vehicleProcessList.vehicle_model,
        vehicle_booklet_model: vehicleProcessList.vehicle_booklet_model,
        vehicle_motorization: vehicleProcessList.vehicle_motorization,
        vehicle_category: vehicleProcessList.vehicle_category,
        vehicle_year: vehicleProcessList.vehicle_year,
        vehicle_kms: vehicleProcessList.vehicle_kms,
        vehicle_engine: vehicleProcessList.vehicle_engine,
        vehicle_approval: vehicleProcessList.vehicle_approval,
        vehicle_total_gross_weight:
          vehicleProcessList.vehicle_total_gross_weight,
        vehicle_fuel: vehicleProcessList.vehicle_fuel,
        vehicle_parts: vehicleProcessList.vehicle_parts,
        vehicle_color: vehicleProcessList.vehicle_color,
        vehicle_cyllinder: vehicleProcessList.vehicle_cyllinder,
        vehicle_type: vehicleProcessList.vehicle_type,
        vehicle_tare: vehicleProcessList.vehicle_tare,
        vehicle_eei: vehicleProcessList.vehicle_eei,
        vehicle_internl_ref: vehicleProcessList.vehicle_internl_ref,
        vehicle_external_ref: vehicleProcessList.vehicle_external_ref,
        vehicle_exp_value: vehicleProcessList.vehicle_exp_value,
        vehicle_provenance: vehicleProcessList.vehicle_provenance,
        shelf_id: vehicleProcessList.shelf_id,
        vehicle_make_etai: vehicleProcessList.vehicle_make_etai,
        vehicle_model_etai: vehicleProcessList.vehicle_model_etai,
        vehicle_variants_etai: vehicleProcessList.vehicle_variants_etai,
        motorization: vehicleProcessList.motorization,
        ccm3: vehicleProcessList.ccm3,
        power_kw: vehicleProcessList.power_kw,
        hp: vehicleProcessList.hp,
        inventory_status: vehicleProcessList.inventory_status,
        deplllution_status: vehicleProcessList.deplllution_status,
        dismental_status: vehicleProcessList.dismental_status,
        certificate_number: vehicleProcessList?.certificate_number,
        state_parking: vehicleProcessList?.state_parking,
        vehicle_doors: vehicleProcessList?.vehicle_doors,
        // body_sent_to_waste: vehicleProcessList.body_sent_to_waste,
      }));

      setVehicleEngineAuto(vehicleProcessList?.engine_name);
      setVehicleEngineId({
        vehicle_engine_id: vehicleProcessList?.vehicle_engine,
        vehicle_kw: vehicleProcessList?.power_kw,
        vehicle_hp: vehicleProcessList?.hp,
        vehicle_cyllinder: vehicleProcessList?.vehicle_cyllinder,
      });
    }
    if (vehicleTecEtaiList?.tecDoc) {
      const tecDocData = {
        vehicle_model_id: vehicleTecEtaiList.tecDoc.id_modelo,
        variants_id: vehicleTecEtaiList.tecDoc.id_modelo_variante,
        make_id: vehicleTecEtaiList.tecDoc.id_marca,
      };

      setVehicleData((prevData) => ({
        ...prevData,
        ...tecDocData, // Update TecDoc-related fields
      }));
    } else if (vehicleTecEtaiList?.Etai) {
      const etaiData = {
        vehicle_model_etai: vehicleTecEtaiList.Etai.model_etai_id,
        vehicle_variants_etai: vehicleTecEtaiList.Etai.variants_etai_id,
        vehicle_make_etai: vehicleTecEtaiList.Etai.make_etai_id,
      };

      setVehicleData((prevData) => ({
        ...prevData,
        ...etaiData, // Update Etai-related fields
      }));
    } else if (SelectedVehicleData && SelectedVehicleCarTecData) {
      setVehicleData((prevData) => ({
        ...prevData,
        vehicle_year_id: SelectedVehicleData.vehicle_model_fromYear,
        vehicle_total_gross_weight:
          SelectedVehicleCarTecData.vehicle_type_weightEmpty,
        vehicle_color: SelectedVehicleCarTecData.vehicle_type_colour,
        vehicle_cyllinder: SelectedVehicleData.vehicle_type_cyl,
      }));

      setVehicleYearAuto(SelectedVehicleData.vehicle_model_fromYear);

      //       setVehicleModelAuto(SelectedVehicleCarTecData?.vehicle_type_model);
      // setVehicleModelId({
      //   model_id:SelectedVehicleCarTecData?.vehicle_type_model_id_catalog,

      // })
    }

    if (AllvehicleSerachData?.vin) {
      setVehicleData((prevData) => ({
        ...prevData,
        // vehicle_frame: AllvehicleSerachData.vin,
      }));
    }

    if (AllvehicleSerachData.plate) {
      setVehicleData((prevData) => ({
        ...prevData,
        registration_plate: AllvehicleSerachData?.plate,
        registration: AllvehicleSerachData?.country,
      }));
    }
  }, [
    vehicleProcessList,
    vehicleTecEtaiList,

    SelectedVehicleData,
    SelectedVehicleCarTecData,
    AllvehicleSerachData?.palte,
    AllvehicleSerachData?.vin,
  ]);

  useEffect(() => {
    if (certificateDetails) {
      const formattedDate = certificateDetails?.certificate_date
        ? new Date(certificateDetails.certificate_date)
            .toISOString()
            .split("T")[0]
        : "";

      const processEndDate = certificateDetails?.certificate_date
        ? new Date(
            new Date(certificateDetails.certificate_date).setFullYear(
              new Date(certificateDetails.certificate_date).getFullYear() + 1
            )
          )
            .toISOString()
            .split("T")[0]
        : "";

      setVehicleData((prevData) => ({
        ...prevData,
        registration: certificateDetails.is_foreign
          ? certificateDetails.registration
          : "Portugal",
        registration_plate: certificateDetails.registration_plate || "",
        certificate_number: certificateDetails.certificate_id || "",
        vehicle_process_start_date: formattedDate,
        vehicle_process_end_date: processEndDate,
        vehicle_total_gross_weight: certificateDetails.weight || "",
        vehicle_fuel_Type_description:
          certificateDetails.vehicle_fuel_Type_description || "",
        vehicle_parts: certificateDetails.vehicle_parts || "",
        vehicle_color: certificateDetails.vehicle_color || "",
        vehicle_cyllinder: certificateDetails.vehicle_cyllinder || "",
        vehicle_tare: certificateDetails.weight || "",
        vehicle_eei: certificateDetails.vehicle_eei || "",
        vehicle_internl_ref: certificateDetails.vehicle_internl_ref || "",
        vehicle_external_ref: certificateDetails.vehicle_external_ref || "",
        vehicle_exp_value: certificateDetails.vehicle_exp_value || "",
        vehicle_provenance: certificateDetails.vehicle_provenance || "",
        shelf_id: certificateDetails.shelf_id || "",
        vehicle_frame: certificateDetails?.chassis_serial_number || "",
        vehicle_booklet_model:
          certificateDetails.vehicle_model_description || "",
        vehicle_brand:
          certificateDetails.vehicle_manufacturer_description || "",
        vehicle_model: `${
          certificateDetails.vehicle_manufacturer_description || ""
        } ${certificateDetails.vehicle_model_description || ""}`.trim(),
        vehicle_year: certificateDetails?.registration_plate_date
          ? new Date(certificateDetails.registration_plate_date).getFullYear()
          : "",
      }));

      setAllVehicleSerachData({
        ...AllvehicleSerachData,
        vin: certificateDetails?.chassis_serial_number
          ? certificateDetails?.chassis_serial_number
          : AllvehicleSerachData.vin,
      });
    }
  }, [certificateDetails]);

  // useEffect(() => {
  //   if (AllvehicleSerachData?.vin) {
  //     setVehicleData((prevData) => ({
  //       ...prevData,
  //       vehicle_frame: AllvehicleSerachData.vin,
  //     }));
  //   }

  //   if (AllvehicleSerachData.plate) {
  //     setVehicleData((prevData) => ({
  //       ...prevData,
  //       registration_plate: AllvehicleSerachData?.plate,
  //       registration: AllvehicleSerachData?.country,
  //     }));
  //   }
  // }, [AllvehicleSerachData]);

  // useEffect(() => {
  //   if (SelectedVehicleData && SelectedVehicleCarTecData) {
  //     setVehicleData((prevData) => ({
  //       ...prevData,
  //       vehicle_year_id: SelectedVehicleData.vehicle_model_fromYear,
  //       vehicle_total_gross_weight:
  //         SelectedVehicleCarTecData.vehicle_type_weightEmpty,
  //       vehicle_color: SelectedVehicleCarTecData.vehicle_type_colour,
  //       vehicle_cyllinder: SelectedVehicleData.vehicle_type_cyl,
  //     }));

  //     setVehicleYearAuto(SelectedVehicleData.vehicle_model_fromYear);

  //     //       setVehicleModelAuto(SelectedVehicleCarTecData?.vehicle_type_model);
  //     // setVehicleModelId({
  //     //   model_id:SelectedVehicleCarTecData?.vehicle_type_model_id_catalog,

  //     // })
  //   }
  // }, [SelectedVehicleData, SelectedVehicleCarTecData]);

  const handleVehicleForm = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };
    setbtnLoading(true);

    // if (isNaN(vehicleData.vehicle_year) || vehicleData.vehicle_year <= 0) {
    //   errorObj.vehicle_year = `Year must be a valid number`;
    //   error = true;
    //   setbtnLoading(false);
    // }

    setErrors(errorObj);
    Utils.focusError();

    if (error) return;

    let data = {
      vehicle_owner_id: ownerId,
      // vehicle_process_type: vehicleData.vehicle_process_type,
      vehicle_process_start_date:
        vehicleData.vehicle_process_start_date ||
        new Date().toISOString().split("T")[0] ||
        "",

      vehicle_process_end_date:
        vehicleData.vehicle_process_end_date ||
        new Date().toISOString().split("T")[0] ||
        "",
      registration:
        certificateDetails.is_foreign === false
          ? "Portugal"
          : vehicleData.registration || "",
      registration_plate: vehicleData.registration_plate || "",
      vehicle_frame: vehicleData.vehicle_frame || "",
      vehicle_model_id: vehicleData?.vehicle_model_id || "",
      vehicle_year_id: vehicleData?.vehicle_year_id || "",
      make_id: vehicleData?.make_id,
      vehicle_brand: vehicleData.vehicle_brand || "",
      vehicle_model: vehicleData.vehicle_model || "",
      vehicle_booklet_model: vehicleData.vehicle_booklet_model || "",
      vehicle_motorization: vehicleData.vehicle_motorization || "",
      vehicle_category: selectedVehicleCategory
        ? selectedVehicleCategory?.value
        : vehicleData.vehicle_category || "",
      vehicle_year: vehicleData.vehicle_year_id || "",
      vehicle_kms: vehicleData.vehicle_kms || "",
      vehicle_engine: vehicleData?.vehicle_engine || "",
      vehicle_approval: vehicleData.vehicle_approval || "",
      vehicle_total_gross_weight: vehicleData.vehicle_total_gross_weight || "",
      vehicle_fuel: selectedVehicleFuel
        ? selectedVehicleFuel.value
        : vehicleData.vehicle_fuel || "",
      vehicle_parts: vehicleData.vehicle_parts || "",
      vehicle_color: vehicleData.vehicle_color || "",
      vehicle_type: vehicleData?.vehicle_type || "",
      vehicle_tare: vehicleData.vehicle_tare || "",
      vehicle_eei: vehicleData.vehicle_eei || "",
      vehicle_internl_ref: vehicleData.vehicle_internl_ref || "",
      vehicle_external_ref: vehicleData.vehicle_external_ref || "",
      vehicle_exp_value: vehicleData.vehicle_exp_value || "",
      vehicle_provenance: vehicleData.vehicle_provenance || "",
      shelf_id: vehicleData.shelf_id || "",
      // body_sent_to_waste: vehicleData.body_sent_to_waste,
      certificate_sync_id: certificateDetails?.id
        ? certificateDetails?.id
        : null,
      variants_id: vehicleData?.variants_id,
      etai_id: SelectedVehicleData?.vehicle_type_model_id_external
        ? SelectedVehicleData?.vehicle_type_model_id_external
        : 0,
      tec_doc_id: SelectedVehicleData?.vehicle_type_model_id_catalog
        ? SelectedVehicleData?.vehicle_type_model_id_catalog
        : 0,
      vin_number: AllvehicleSerachData ? AllvehicleSerachData.vin : 0,
      vehicle_make_etai: vehicleData?.vehicle_make_etai || "",
      vehicle_model_etai: vehicleData?.vehicle_model_etai || "",
      vehicle_variants_etai: vehicleData?.vehicle_variants_etai || "",
      ccm3: vehicleData.ccm3 || "",
      vehicle_cyllinder: VehicleEngineData
        ? VehicleEngineData?.cyl
        : vehicleData.vehicle_cyllinder || "",

      power_kw: VehicleEngineData
        ? VehicleEngineData?.kw
        : vehicleData.power_kw || 0,
      hp: VehicleEngineData ? VehicleEngineData?.hp : vehicleData.hp || 0,
      inventory_status: vehicleData.inventory_status
        ? vehicleData.inventory_status
        : 1,
      deplllution_status: vehicleData.deplllution_status || "",
      dismental_status: vehicleData.dismental_status || "",
      certificate_number: vehicleData?.certificate_number || "",
      state_parking: vehicleData?.state_parking,
      vehicle_doors: vehicleData?.vehicle_doors,
    };

    let updateData = {
      vehicle_owner_id: vehicleProcessList?.vehicle_owner_id,
      uuid: vehicleProcessList?.uuid,
      // vehicle_process_type: vehicleData.vehicle_process_type,
      vehicle_process_start_date: vehicleData.vehicle_process_start_date,
      vehicle_process_end_date: vehicleData.vehicle_process_end_date,
      registration: vehicleData.registration,
      registration_plate: vehicleData.registration_plate,
      vehicle_frame: vehicleData.vehicle_frame,
      vehicle_model_id: vehicleData?.vehicle_model_id,
      make_id: vehicleData?.make_id,
      vehicle_brand: vehicleData.vehicle_brand,
      vehicle_model: vehicleData.vehicle_model,
      vehicle_booklet_model: vehicleData.vehicle_booklet_model,
      vehicle_motorization: vehicleData.vehicle_motorization,
      vehicle_category: vehicleData.vehicle_category,
      vehicle_year: vehicleData.vehicle_year,
      vehicle_kms: vehicleData.vehicle_kms,
      vehicle_engine: vehicleData?.vehicle_engine,
      vehicle_approval: vehicleData.vehicle_approval,
      vehicle_total_gross_weight: vehicleData.vehicle_total_gross_weight,
      vehicle_fuel: vehicleData.vehicle_fuel,
      vehicle_parts: vehicleData.vehicle_parts,
      vehicle_color: vehicleData.vehicle_color,
      vehicle_cyllinder: VehicleEngineData
        ? VehicleEngineData?.cyl
        : vehicleData.vehicle_cyllinder,
      vehicle_type: vehicleData?.vehicle_type,
      vehicle_tare: vehicleData.vehicle_tare,
      vehicle_eei: vehicleData.vehicle_eei,
      vehicle_internl_ref: vehicleData.vehicle_internl_ref,
      vehicle_external_ref: vehicleData.vehicle_external_ref,
      vehicle_exp_value: vehicleData.vehicle_exp_value,
      vehicle_provenance: vehicleData.vehicle_provenance,
      shelf_id: vehicleData.shelf_id,
      certificate_sync_id: vehicleData.certificate_sync_id
        ? vehicleData.certificate_sync_id
        : null,
      variants_id: vehicleData?.variants_id,
      etai_id: SelectedVehicleData?.vehicle_type_model_id_external
        ? SelectedVehicleData?.vehicle_type_model_id_external
        : 0,
      tec_doc_id: SelectedVehicleData?.vehicle_type_model_id_catalog
        ? SelectedVehicleData?.vehicle_type_model_id_catalog
        : 0,
      vin_number: vehicleProcessList ? vehicleProcessList.vin_number : 0,

      motorization: vehicleData.motorization,
      ccm3: vehicleData.ccm3,
      power_kw: VehicleEngineData
        ? VehicleEngineData?.kw
        : vehicleData.power_kw,
      hp: VehicleEngineData ? VehicleEngineData?.hp : vehicleData.hp,
      inventory_status: vehicleData.inventory_status,
      inventory_status: vehicleData.inventory_status,
      deplllution_status: vehicleData.deplllution_status,
      dismental_status: vehicleData.dismental_status,
      certificate_number: vehicleData?.certificate_number,
      vehicle_year_id: vehicleData?.vehicle_year_id,

      vehicle_make_etai: vehicleData?.vehicle_make_etai,
      vehicle_model_etai: vehicleData?.vehicle_model_etai,
      vehicle_variants_etai: vehicleData?.vehicle_variants_etai,
      state_parking: vehicleData?.state_parking,
      vehicle_doors: vehicleData?.vehicle_doors,
    };

    if (vehicleProcessList?.uuid) {
      createVehicle(updateData)
        .then((response) => {
          toast.success(response.data.message);
          setActiveTab("vehicle_images");
          localStorage.setItem("vehicleId", response.data.data.id);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        })
        .finally(() => {
          setbtnLoading(false);
        });
    } else {
      createVehicle(data)
        .then((response) => {
          toast.success(response.data.message);
          setActiveTab("vehicle_images");
          localStorage.setItem("vehicleId", response.data.data.id);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        })
        .finally(() => {
          setbtnLoading(false);
        });
    }
  };

  const handleSelectChange = (selectedOption) => {
    setVehicleData({
      ...vehicleData,
      vehicle_model_id: selectedOption ? selectedOption.value : "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true); // Set loading state to true (start the loader)

    // Simulate an async operation like form processing or API call
    setTimeout(() => {
      setLoading(false); // Stop loading state after the async operation
    }, 1000);
    let data = {
      vehicle_id: vehicleProcessList?.id,
      ler_code_id: vehiclePartsData?.ler_code_id,
      wastege_value: vehiclePartsData?.wastege_value,
      description: vehiclePartsData?.description,
    };

    actionAddDepollutionOpration(data)
      .then((response) => {
        toast.success(response.data.message);
        setBodyWasteModal(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const data = Number(vehicleData.vehicle_model_id);

  const handleVehicleVinForm = (e) => {
    e.preventDefault();
    setLoading(true);

    if (AllvehicleSerachData?.serach_vehicle_id === 2) {
      let data = {
        country: AllvehicleSerachData.country,
        plate: AllvehicleSerachData.plate,
        language: "EN",
      };
      getVehiclePlateAllist(data)
        .then((response) => {
          setVehiclePlateData(response.data.data.vehicles_decoded);
          setSerachModal(true);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      let data = {
        vin: AllvehicleSerachData.vin,
        language: "EN",
      };
      getVehicleVinAllist(data)
        .then((response) => {
          setVehicleVinData(response.data.data.vehicles_decoded);

          setSerachModal(true);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (vehicleProcessList?.vin_number) {
      let data = {
        vin: vehicleProcessList?.vin_number,
        language: "EN",
      };
      getVehicleVinAllist(data)
        .then((response) => {
          setVehicleVinData(response.data.data.vehicles_decoded);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [vehicleProcessList?.vin_number]);

  const updateFilterVinData = vehicleVinData?.cars_etai?.filter(
    (data) =>
      data.vehicle_type_model_id_catalog ===
      SelectedVehicleData?.vehicle_type_model_id_catalog
  );

  const filteredVehicleVinData = vehicleVinData?.cars_etai?.filter((data) =>
    [
      data?.vehicle_type_description,
      data?.vehicle_model_fromYear,
      data?.vehicle_type_model,
    ].some((field) =>
      field?.toString().toLowerCase().includes(serach.toLowerCase())
    )
  );

  const filteredVehiclePlateData = vehiclePlateData?.cars_etai?.filter((data) =>
    [
      data?.vehicle_type_description,
      data?.vehicle_model_fromYear,
      data?.vehicle_type_model,
    ].some((field) =>
      field?.toString().toLowerCase().includes(serach.toLowerCase())
    )
  );

  const filteredVehicleVinTecDoc = vehicleVinData?.cars_tecdoc?.filter((data) =>
    [
      data?.vehicle_type_description,
      data?.vehicle_model_fromYear,
      data?.vehicle_type_model,
    ].some((field) =>
      field?.toString().toLowerCase().includes(serach.toLowerCase())
    )
  );

  const filteredVehiclePlateTecDoc = vehiclePlateData?.cars_tecdoc?.filter(
    (data) =>
      [
        data?.vehicle_type_description,
        data?.vehicle_model_fromYear,
        data?.vehicle_type_model,
      ].some((field) =>
        field?.toString().toLowerCase().includes(serach.toLowerCase())
      )
  );

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary">
            {!vehicleProcessList?.uuid && (
              <div className="row">
                <Form method={`post`} onSubmit={handleVehicleVinForm}>
                  <div className="vehicle-owner-form">
                    <div className="row mt-3 justify-content-start align-items-center">
                      <div className="col-md-3">
                        <FormGroup>
                          <Label className="col-form-label">
                            {t("search_vehicle_by")}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <Select
                                className="my-select-v"
                                options={VehicleSerachType}
                                value={VehicleSerachType.find(
                                  (option) =>
                                    option.value ===
                                    AllvehicleSerachData.serach_vehicle_id
                                )}
                                onChange={(selectedOption) =>
                                  setAllVehicleSerachData({
                                    ...AllvehicleSerachData,
                                    serach_vehicle_id: selectedOption.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </div>

                      {AllvehicleSerachData.serach_vehicle_id === 2 ? (
                        <>
                          <div className="col-md-3">
                            <FormGroup>
                              <Label className="col-form-label">
                                {AllvehicleSerachData.serach_vehicle_id === 2
                                  ? t("plate_number")
                                  : t("vin_number")}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={AllvehicleSerachData.plate}
                                    onChange={(e) => {
                                      setAllVehicleSerachData({
                                        ...AllvehicleSerachData,
                                        plate: e.target.value,
                                      });
                                    }}
                                    name={`plate`}
                                    maxLength={100}
                                    placeholder="Enter Number"
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </div>
                        </>
                      ) : (
                        <div className="col-md-3">
                          <FormGroup>
                            <Label className="col-form-label">
                              {AllvehicleSerachData.serach_vehicle_id === 2
                                ? t("plate_number")
                                : t("vin_number")}
                            </Label>
                            <div className="col-xl-12">
                              <div className="input-div">
                                <input
                                  className="form-control"
                                  type="text"
                                  value={AllvehicleSerachData.vin}
                                  onChange={(e) => {
                                    setAllVehicleSerachData((prev) => ({
                                      ...prev,
                                      vin: e.target.value || "", // Ensure vin is never undefined
                                    }));
                                  }}
                                  name={`vin`}
                                  maxLength={100}
                                  placeholder="Enter Number"
                                />
                              </div>
                            </div>
                          </FormGroup>
                        </div>
                      )}

                      {AllvehicleSerachData.serach_vehicle_id === 2 && (
                        <div className="col-md-3">
                          <FormGroup>
                            <Label className="col-form-label">
                              {t("country")}
                            </Label>
                            <div className="col-xl-12">
                              <div className="input-div">
                                <Select
                                  className="my-select-v"
                                  options={countryAllData}
                                  value={countryAllData.find(
                                    (option) =>
                                      option.value ===
                                      AllvehicleSerachData.country
                                  )}
                                  onChange={(selectedOption) =>
                                    setAllVehicleSerachData({
                                      ...AllvehicleSerachData,
                                      country: selectedOption.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </FormGroup>
                        </div>
                      )}

                      <div className="col-md-3 vehicleSearch">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          disabled={loading}
                        >
                          {loading ? "Searching..." : "Search"}
                        </button>
                      </div>

                      {/* {SelectedVehicleCarTecData && SelectedVehicleData && (
                        <div className="col-md-3 vehicleSearch">
                          <button
                            type="button"
                            className="btn btn-primary"
                            disabled={loading}
                            onClick={handleViewOpen}
                          >
                            <i class="bi bi-car-front"></i>{" "}
                            {loading ? "Searching..." : "ssss"}
                          </button>
                        </div>
                      )} */}
                    </div>
                  </div>
                  {loading && (
                    <div
                      className="d-flex justify-content-center align-items-center position-fixed top-0 bottom-0 start-0 end-0 z-3"
                      style={{ zIndex: "99", backgroundColor: "#ffffffc2" }}
                    >
                      <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            )}
            <Form method={`post`} onSubmit={handleVehicleForm}>
              <div className="vehicle-owner-form">
                <div className="card-body">
                  {updateFilterVinData?.length > 0 && (
                    <div
                      className="col-md-12 vehicleSearch"
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <button
                        type="button"
                        className="btn btn-primary"
                        disabled={loading}
                        onClick={handleViewOpen}
                      >
                        <i class="bi bi-car-front"></i>{" "}
                        {loading ? "Searching..." : "View"}
                      </button>
                    </div>
                  )}
                  <div className="row justify-content-start">
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label ml-2 Label-my form-label form-label">
                          {t("select_vehicle_year")}
                        </Label>

                        <div className="col-xl-12">
                          <div className="input-div">
                            <Select
                              className="my-select-v"
                              options={VehicleYearList}
                              value={VehicleYearList.find(
                                (item) =>
                                  item.value == vehicleData.vehicle_year_id
                              )}
                              onChange={(selectedOption) => {
                                setVehicleData((prev) => ({
                                  ...prev,
                                  vehicle_year_id: selectedOption
                                    ? selectedOption.value
                                    : "",
                                  make_id: "",
                                  vehicle_model_id: "",
                                  variants_id: "",
                                }));
                                setVehicleMakeList([]);
                                setVehicleModalList([]);
                                setVehicleVariantList([]);
                              }}
                              placeholder={"Select Vehicle Year"}
                              onInputChange={handleInputSelectedChangeYearList}
                              inputSelctedValue={inputSelctedeYearValue}
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label ml-2 Label-my form-label form-label">
                          {"Select Vehicle Make"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <Select
                              className="my-select-v"
                              options={VehicleMakeList}
                              value={
                                VehicleMakeList.find(
                                  (item) => item.value == vehicleData.make_id
                                ) || null
                              }
                              onChange={(selectedOption) => {
                                setVehicleData((prev) => ({
                                  ...prev,
                                  make_id: selectedOption
                                    ? selectedOption.value
                                    : "",
                                  vehicle_model_id: "",
                                  variants_id: "",
                                }));

                                setVehicleModalList([]);
                                setVehicleVariantList([]);
                              }}
                              isSearchable={true}
                              onInputChange={handleInputSelectedChangeMakeList}
                              inputSelctedValue={inputSelctedeMakeValue}
                              placeholder={"Select Vehicle Make"}
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <div className="col-md-12">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {t("select_vehicle_model")}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <Select
                                className="my-select-v"
                                options={VehicleModalList}
                                value={
                                  VehicleModalList.find(
                                    (item) =>
                                      item.value == vehicleData.vehicle_model_id
                                  ) || null
                                }
                                onChange={(selectedOption) => {
                                  setVehicleData((prev) => ({
                                    ...prev,
                                    vehicle_model_id: selectedOption
                                      ? selectedOption.value
                                      : "",
                                    variants_id: "",
                                  }));

                                  setVehicleVariantList([]);
                                }}
                                isSearchable={true}
                                onInputChange={
                                  handleInputSelectedChangeModalList
                                }
                                inputSelctedValue={inputSelctedeModalValue}
                                placeholder={"Select Vehicle Modal"}
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="col-md-12">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {"Select Vehicle Variants"}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <Select
                                className="my-select-v"
                                options={VehicleVariantList}
                                value={
                                  VehicleVariantList.find(
                                    (item) =>
                                      item.value == vehicleData.variants_id
                                  ) || null
                                }
                                onChange={(selectedOption) =>
                                  setVehicleData({
                                    ...vehicleData,
                                    variants_id: selectedOption
                                      ? selectedOption.value
                                      : "",
                                  })
                                }
                                isSearchable={true}
                                onInputChange={
                                  handleInputSelectedChangeVariantList
                                }
                                inputSelctedValue={inputSelctededVariantValue}
                                placeholder={"Select Vehicle Variant"}
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label ml-2 Label-my form-label form-label">
                          {"Select Vehicle Make ETAI"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <Select
                              className="my-select-v"
                              options={VehicleMakeETAIList}
                              value={VehicleMakeETAIList.find(
                                (item) =>
                                  item.value == vehicleData.vehicle_make_etai
                              )}
                              onChange={(selectedOption) =>
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_make_etai: selectedOption
                                    ? selectedOption.value
                                    : "",
                                })
                              }
                              isSearchable={true}
                              onInputChange={
                                handleInputSelectedChangeMakeETAIList
                              }
                              inputSelctedValue={inputSelctedeMakeETAIValue}
                              placeholder={"Select Vehicle Make ETAI"}
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label ml-2 Label-my form-label form-label">
                          {t("select_vehicle_model_Etai")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <Select
                              className="my-select-v"
                              options={VehicleModalETAIList}
                              value={VehicleModalETAIList.find(
                                (item) =>
                                  item.value == vehicleData.vehicle_model_etai
                              )}
                              onChange={(selectedOption) =>
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_model_etai: selectedOption
                                    ? selectedOption.value
                                    : "",
                                })
                              }
                              isSearchable={true}
                              onInputChange={
                                handleInputSelectedChangeModalETAIList
                              }
                              inputSelctedValue={inputSelctedeModalETAIValue}
                              placeholder={"Select Vehicle Modal ETAI"}
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label ml-2 Label-my form-label form-label">
                          {"Select Vehicle Variants ETAI"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <Select
                              className="my-select-v"
                              options={VehicleVariantETAIList}
                              value={VehicleVariantETAIList.find(
                                (item) =>
                                  item.value ==
                                  vehicleData.vehicle_variants_etai
                              )}
                              onChange={(selectedOption) =>
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_variants_etai: selectedOption
                                    ? selectedOption.value
                                    : "",
                                })
                              }
                              isSearchable={true}
                              onInputChange={
                                handleInputSelectedChangeVariantETAIList
                              }
                              inputSelctedValue={inputSelctededVariantETAIValue}
                              placeholder={"Select Vehicle Variants ETAI"}
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("registration_plate")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.registration_plate}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  registration_plate: e.target.value,
                                });
                              }}
                              name={`registration_plate`}
                              maxLength={100}
                              placeholder="Registration Plate"
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("registration")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.registration}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  registration: e.target.value,
                                });
                              }}
                              name={`registration`}
                              maxLength={50}
                              placeholder="Registration"
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("process_start_date")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              type="date"
                              id="vehicle_process_start_date"
                              className="form-control"
                              value={
                                vehicleData.vehicle_process_start_date ||
                                new Date().toISOString().split("T")[0]
                              }
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_process_start_date: e.target.value,
                                });
                              }}
                              disabled={!!vehicleProcessList?.uuid}
                              placeholder="Process Start Date"
                            />
                          </div>
                          {errors.vehicle_process_start_date && (
                            <span className="input-error">
                              {errors.vehicle_process_start_date}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("process_end_date")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              type="date"
                              id="vehicle_process_end_date"
                              className="form-control"
                              value={
                                vehicleData.vehicle_process_end_date ||
                                new Date().toISOString().split("T")[0]
                              }
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_process_end_date: e.target.value,
                                });
                              }}
                              disabled={!!vehicleProcessList?.uuid}
                              placeholder="Process End Date"
                            />
                          </div>
                          {errors.vehicle_process_end_date && (
                            <span className="input-error">
                              {errors.vehicle_process_end_date}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Vehicle VIN"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.vehicle_frame}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_frame: e.target.value,
                                });
                              }}
                              name={`vehicle_frame`}
                              maxLength={100}
                              placeholder="Vehicle VIN"
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("certificate_number")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.certificate_number}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  certificate_number: e.target.value,
                                });
                              }}
                              name={`certificate_number`}
                              maxLength={100}
                              placeholder="Certificate Number"
                              // disabled={!!vehicleData?.certificate_number}
                              disabled={certificateDetails?.certificate_id}
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("vehicle_park")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <Select
                              className="my-select-v"
                              options={ShelfList}
                              value={ShelfList.find(
                                (option) =>
                                  option.value === Number(vehicleData.shelf_id)
                              )}
                              onChange={(selectedOption) =>
                                setVehicleData({
                                  ...vehicleData,
                                  shelf_id: selectedOption.value,
                                })
                              }
                              placeholder={"Select Vehicle Park"}
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">{t("brand")}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.vehicle_brand}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_brand: e.target.value,
                                });
                              }}
                              name={`vehicle_brand`}
                              maxLength={30}
                              placeholder="Brand"
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("booklet_model")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.vehicle_booklet_model}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_booklet_model: e.target.value,
                                });
                              }}
                              name={`vehicle_booklet_model`}
                              maxLength={100}
                              placeholder="Booklet Model"
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("vehicle_model")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.vehicle_model}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_model: e.target.value,
                                });
                              }}
                              name={`vehicle_model`}
                              maxLength={30}
                              placeholder="Vehicle Model"
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("vehicle_tare")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.vehicle_tare}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_tare: e.target.value,
                                });
                              }}
                              name={`vehicle_tare`}
                              maxLength={100}
                              placeholder="Vehicle Tare"
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("vehicle_category")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            {/* <Select
                              className="my-select-v"
                              options={VehicleCategoryList}
                              value={selectedCategory}
                              onChange={(selectedOption) =>
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_category: selectedOption
                                    ? selectedOption.value
                                    : "",
                                })
                              }
                              placeholder={"Select Vehicle Category"}
                            /> */}

                            <Select
                              className="my-select-v"
                              options={VehicleCategoryList}
                              value={selectedVehicleCategory}
                              onChange={handleVehicleCategoryChange}
                              isSearchable={true}
                              placeholder={"Select Vehicle Category"}
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("vehicle_year")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleData.vehicle_year}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_year: e.target.value,
                                });
                              }}
                              name={`vehicle_year`}
                              maxLength={100}
                              placeholder="Vehicle Year"
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("vehicle_kms")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleData.vehicle_kms}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_kms: e.target.value,
                                });
                              }}
                              name={`vehicle_kms`}
                              maxLength={100}
                              placeholder="Vehicle KMS"
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-3">
                      <FormGroup className="mt-3">
                        <Label>{"Vehicle Doors"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <Select
                              options={vehicleDoorList}
                              value={vehicleDoorList.find(
                                (option) =>
                                  option.value ===
                                  Number(vehicleData.vehicle_doors)
                              )}
                              onChange={(selectedOption) =>
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_doors: selectedOption.value,
                                })
                              }
                              placeholder={"Select Vehicle Doors"}
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("vehicle_approval")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.vehicle_approval}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_approval: e.target.value,
                                });
                              }}
                              name={`vehicle_approval`}
                              maxLength={100}
                              placeholder="Vehicle Approval"
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("vehicle_total_gross_weight")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleData.vehicle_total_gross_weight}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_total_gross_weight: e.target.value,
                                });
                              }}
                              name={`vehicle_total_gross_weight`}
                              maxLength={100}
                              placeholder="Vehicle Total Gross Weight"
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("vehicle_fuel")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            {/* <Select
                              className="my-select-v"
                              options={VehicleFuelList}
                              value={selectedFuel}
                              onChange={(selectedOption) =>
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_fuel: selectedOption
                                    ? selectedOption.value
                                    : "",
                                })
                              }
                              placeholder={"Select Vehicle Fuel"}
                            /> */}

                            <Select
                              className="my-select-v"
                              options={VehicleFuelList}
                              value={selectedVehicleFuel}
                              onChange={handleVehicleFuelChange}
                              isSearchable={true}
                              placeholder={"Select Vehicle Fuel"}
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("vehicle_parts")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleData.vehicle_parts}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_parts: e.target.value,
                                });
                              }}
                              name={`vehicle_parts`}
                              maxLength={100}
                              placeholder="Vehicle Parts"
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Vehicle Color"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.vehicle_color}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_color: e.target.value,
                                });
                              }}
                              name={`vehicle_color`}
                              maxLength={100}
                              placeholder="Vehicle Color"
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-3">
                      <div className="col-md-12">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {t("select_vehicle_type")}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <Select
                                className="my-select-v"
                                options={VehicleTypeList}
                                value={selectedVehicle}
                                onChange={handleVehicleChange}
                                isSearchable={true}
                                placeholder={"Select Vehicle Types"}
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("vehicle_eei")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleData.vehicle_eei}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_eei: e.target.value,
                                });
                              }}
                              name={`vehicle_eei`}
                              maxLength={100}
                              placeholder="Vehicle EEI"
                            />
                          </div>
                          {errors.vehicle_eei && (
                            <span className="input-error">
                              {errors.vehicle_eei}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Vehicle Internal Ref"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.vehicle_internl_ref}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_internl_ref: e.target.value,
                                });
                              }}
                              name={`vehicle_internl_ref`}
                              maxLength={100}
                              placeholder="Vehicle Internal Ref"
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Vehicle External ref"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.vehicle_external_ref}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_external_ref: e.target.value,
                                });
                              }}
                              name={`vehicle_external_ref`}
                              maxLength={100}
                              placeholder="Vehicle External ref"
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Vehicle exp value"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleData.vehicle_exp_value}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_exp_value: e.target.value,
                                });
                              }}
                              name={`vehicle_exp_value`}
                              maxLength={100}
                              placeholder="Vehicle exp value"
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("vehicle_provenance")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.vehicle_provenance}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_provenance: e.target.value,
                                });
                              }}
                              name={`vehicle_provenance`}
                              maxLength={100}
                              placeholder="Vehicle Provenance"
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <div className="col-md-12">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {t("select_vehicle_engine")}
                          </Label>

                          <div className="col-xl-12">
                            <div className="input-div">
                              <Select
                                className="my-select-v"
                                options={VehicleEngineList}
                                value={VehicleEngineList.find(
                                  (item) =>
                                    item.value == vehicleData.vehicle_engine
                                )}
                                onChange={(selectedOption) => {
                                  setVehicleData({
                                    ...vehicleData,
                                    vehicle_engine: selectedOption
                                      ? selectedOption.value
                                      : "",
                                  });
                                  setVehicleEngineData(selectedOption);
                                }}
                                isSearchable={true}
                                onInputChange={
                                  handleInputSelectedChangeEngineList
                                }
                                inputSelctedValue={inputSelctededEngineValue}
                                placeholder={"Select Vehicle Engine"}
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("motorization")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.vehicle_motorization}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_motorization: e.target.value,
                                });
                              }}
                              name={`vehicle_motorization`}
                              maxLength={100}
                              placeholder="Motorization"
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">{"Ccm3"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleData.ccm3}
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  ccm3: e.target.value,
                                });
                              }}
                              name={`ccm3`}
                              maxLength={100}
                              placeholder="Ccm3"
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">{"Power Kw"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={
                                VehicleEngineData
                                  ? VehicleEngineData?.kw
                                  : vehicleData.power_kw
                              }
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  power_kw: e.target.value,
                                });
                              }}
                              name={`power_kw`}
                              maxLength={100}
                              placeholder="Power Kw"
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">{"HP"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={
                                VehicleEngineData
                                  ? VehicleEngineData?.hp
                                  : vehicleData.hp
                              }
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  hp: e.target.value,
                                });
                              }}
                              name={`hp`}
                              maxLength={100}
                              placeholder="HP"
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Vehicle Cyllinder"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={
                                VehicleEngineData
                                  ? VehicleEngineData?.cyl
                                  : vehicleData.vehicle_cyllinder
                              }
                              onChange={(e) => {
                                setVehicleData({
                                  ...vehicleData,
                                  vehicle_cyllinder: e.target.value,
                                });
                              }}
                              name={`vehicle_cyllinder`}
                              maxLength={100}
                              placeholder="Vehicle Cyllinder"
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-3">
                      <FormGroup>
                        <Label>{"Inventory Status"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <Select
                              options={inventoryList}
                              value={
                                inventoryList.find(
                                  (option) =>
                                    option.value ===
                                    vehicleData.inventory_status
                                ) || { value: 1, label: "In Stock" }
                              }
                              onChange={(selectedOption) =>
                                setVehicleData({
                                  ...vehicleData,
                                  inventory_status: selectedOption.value,
                                })
                              }
                              placeholder={"Select Inventory Status"}
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-3">
                      <FormGroup>
                        <Label>{"Depolution Status"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <Select
                              options={deplllutionList}
                              value={
                                deplllutionList.find(
                                  (option) =>
                                    option.value ===
                                    vehicleData.deplllution_status
                                ) || { value: 1, label: "Pending" }
                              }
                              onChange={(selectedOption) =>
                                setVehicleData({
                                  ...vehicleData,
                                  deplllution_status: selectedOption.value,
                                })
                              }
                              placeholder={"Select Depolution Status"}
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-3">
                      <FormGroup>
                        <Label>{"Dismental Status"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <Select
                              options={dismentalList}
                              value={
                                dismentalList.find(
                                  (option) =>
                                    option.value ===
                                    vehicleData.dismental_status
                                ) || { value: 1, label: "Pending" }
                              }
                              onChange={(selectedOption) =>
                                setVehicleData({
                                  ...vehicleData,
                                  dismental_status: selectedOption.value,
                                })
                              }
                              placeholder={"Select Dismental Status"}
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-3">
                      <FormGroup>
                        <Label>{"State Parking"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <Select
                              options={stateParkingList}
                              value={stateParkingList.find(
                                (option) =>
                                  option.value ===
                                  Number(vehicleData.state_parking)
                              )}
                              onChange={(selectedOption) =>
                                setVehicleData({
                                  ...vehicleData,
                                  state_parking: selectedOption.value,
                                })
                              }
                              placeholder={"Select State Parking"}
                            />
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="back-footer">
                    <div className="row">
                      <div className="col">
                        <button
                          type="button"
                          className="next-button btn btn-secondary"
                          onClick={() => navigate("/vehicle-process")}
                        >
                          <i class="bi bi-arrow-left"></i> Cancel
                        </button>
                      </div>
                      <div className="col">
                        <div className="d-flex justify-content-end nxt-btn">
                          {btnLoader === true ? (
                            <button type="submit" className="btn  btn-primary">
                              Loading...
                            </button>
                          ) : (
                            <button type="submit" className="btn  btn-primary">
                              {t("finish")}
                              <i class="bi bi-arrow-right"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-footer"></div>
            </Form>
          </div>
        </div>
      </div>

      <Modal className="modal-xl" isOpen={serachModal}>
        <ModalHeader>
          {"Please select the Vehicle Information"}
          <button
            type="button"
            className="btn-closes-1"
            onClick={() => setSerachModal(false)}
          >
            <i className="bi bi-x"></i>
          </button>
        </ModalHeader>
        <ModalBody>
          <div className="row justify-content-start">
            <div className="row mb-3">
              <div className="col-lg-6">
                <div className="col-xl-12">
                  <input
                    className="form-control"
                    type="text"
                    value={serach}
                    onChange={(e) => setSerach(e.target.value)}
                    // maxLength={50}
                    placeholder="Search like description, model, year"
                  />
                </div>
              </div>
            </div>
            <div className="card-body vehicleparts-table">
              <h4>Cars ETAI Details</h4>
              <div className="vehicleparts-table-inner vehicle-serach">
                <div className="vehicleparts-table-modal">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th></th>
                        <th>{t("description")}</th>
                        <th>{t("images")}</th>
                        <th>{t("year")}</th>
                        <th>{t("model")}</th>
                        <th>{t("range")}</th>
                        <th>{t("body_type")}</th>
                        <th>{t("CCM")}</th>
                        <th>{t("CYL")}</th>
                        <th>{t("Doors")}</th>
                        <th>{t("box_type")}</th>
                        <th>{t("manufacturer")}</th>
                        <th>{t("vehicle_type")}</th>
                        <th>{t("oil_capacity")}</th>
                      </tr>
                    </thead>

                    {AllvehicleSerachData.serach_vehicle_id === 1 ? (
                      <tbody>
                        {filteredVehicleVinData?.length > 0 ? (
                          filteredVehicleVinData?.map((data, index) => {
                            const optionValue =
                              data?.vehicle_type_description ||
                              `option-${index}`;
                            return (
                              <tr key={index}>
                                <td>
                                  {/* <button
                                    type="button"
                                    className={`btn ${
                                      selectedOption === optionValue
                                        ? "btn-success"
                                        : "btn-primary"
                                    }`}
                                  >
                                    Select
                                  </button> */}

                                  <input
                                    type="radio"
                                    value={optionValue}
                                    checked={selectedOption === optionValue}
                                    onChange={(e) =>
                                      handleOptionChange(e, data)
                                    }
                                  />
                                </td>
                                <td>{data?.vehicle_type_description}</td>
                                <td>
                                  <a
                                    className="eye-box1"
                                    href={data?.vehicle_model_image}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="View vehicle model image"
                                  >
                                    <i className="bi bi-eye"></i>
                                  </a>
                                </td>
                                <td>{data?.vehicle_model_fromYear}</td>
                                <td>{data?.vehicle_type_model}</td>
                                <td>{data?.vehicle_model_range}</td>
                                <td>{data?.vehicle_type_bodyType}</td>
                                <td>{data?.vehicle_type_ccm}</td>
                                <td>{data?.vehicle_type_cyl}</td>
                                <td>{data?.vehicle_type_doors}</td>
                                <td>{data?.vehicle_type_gearboxType}</td>
                                <td>{data?.vehicle_type_manufacter}</td>
                                <td>{data?.vehicle_type_vehicleType}</td>
                                <td>{data?.vehicle_type_oilCapacity}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={14} className="text-center">
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    ) : (
                      <tbody>
                        {filteredVehiclePlateData?.map((data, index) => {
                          const optionValue =
                            data?.vehicle_type_description || `option-${index}`;

                          return (
                            <tr key={index}>
                              <td>
                                <input
                                  type="radio"
                                  value={optionValue}
                                  checked={selectedOption === optionValue}
                                  onChange={(e) => handleOptionChange(e, data)}
                                />
                              </td>
                              {/* <td>
                                <button
                                  type="button"
                                  className={`btn ${
                                    selectedOption === optionValue
                                      ? "btn-success"
                                      : "btn-primary"
                                  }`}
                                  onClick={(e) => handleOptionChange(e, data)}
                                >
                                  Select
                                </button>
                              </td> */}
                              <td>{data?.vehicle_type_description}</td>
                              <td>
                                <td>
                                  <a
                                    className="eye-box1"
                                    href={data?.vehicle_make_image}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="View vehicle model image"
                                  >
                                    <i className="bi bi-eye"></i>
                                  </a>
                                </td>
                              </td>
                              <td>{data?.vehicle_model_fromYear}</td>
                              <td>{data?.vehicle_type_model}</td>
                              <td>{data?.vehicle_model_range}</td>
                              <td>{data?.vehicle_type_bodyType}</td>
                              <td>{data?.vehicle_type_ccm}</td>
                              <td>{data?.vehicle_type_cyl}</td>
                              <td>{data?.vehicle_type_doors}</td>
                              <td>{data?.vehicle_type_gearboxType}</td>
                              <td>{data?.vehicle_type_manufacter}</td>
                              <td>{data?.vehicle_type_vehicleType}</td>
                              <td>{data?.vehicle_type_oilCapacity}</td>
                            </tr>
                          );
                        })}
                        {vehiclePlateData?.cars_etai?.filter((data) =>
                          data?.vehicle_type_description
                            ?.toLowerCase()
                            .includes(searchQuery.toLowerCase())
                        ).length === 0 && (
                          <tr>
                            <td colSpan={14} className="text-center">
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
              <h4 style={{ marginTop: "20px" }}>Cars TecDoc Details</h4>

              <div
                className="vehicleparts-table-inner vehicle-serach"
                style={{ marginTop: "20px" }}
              >
                <div className="vehicleparts-table-modal">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th></th>
                        <th>{t("description")}</th>
                        <th>{t("model")}</th>
                        <th>{t("body_type")}</th>
                        <th>{t("CCM")}</th>
                        <th>{t("CYL")}</th>
                        <th>{t("Doors")}</th>
                        <th>{t("manufacturer")}</th>
                      </tr>
                    </thead>

                    {AllvehicleSerachData.serach_vehicle_id === 1 ? (
                      <tbody>
                        {filteredVehicleVinTecDoc?.map((data, index) => {
                          const optionValue =
                            data?.vehicle_type_description || `option-${index}`;

                          return (
                            <tr key={index}>
                              <td>
                                <input
                                  type="radio"
                                  value={optionValue}
                                  checked={selectedOptionCarTec === optionValue}
                                  onChange={(e) =>
                                    handleOptionChangeCarsTec(e, data)
                                  }
                                />
                              </td>
                              <td>{data?.vehicle_type_description}</td>

                              <td>{data?.vehicle_type_model}</td>
                              <td>{data?.vehicle_type_bodyType}</td>
                              <td>{data?.vehicle_type_ccm}</td>
                              <td>{data?.vehicle_type_cyl}</td>
                              <td>{data?.vehicle_type_doors}</td>
                              <td>{data?.vehicle_type_manufacter}</td>
                            </tr>
                          );
                        })}
                        {vehicleVinData?.cars_tecdoc?.filter((data) =>
                          data?.vehicle_type_description
                            ?.toLowerCase()
                            .includes(searchQuery.toLowerCase())
                        ).length === 0 && (
                          <tr>
                            <td colSpan={14} className="text-center">
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    ) : (
                      <tbody>
                        {filteredVehiclePlateTecDoc?.map((data, index) => {
                          const optionValue =
                            data?.vehicle_type_description || `option-${index}`;

                          return (
                            <tr key={index}>
                              <td>
                                <input
                                  type="radio"
                                  value={optionValue}
                                  checked={selectedOptionCarTec === optionValue}
                                  onChange={(e) =>
                                    handleOptionChangeCarsTec(e, data)
                                  }
                                />
                              </td>
                              <td>{data?.vehicle_type_description}</td>
                              <td>{data?.vehicle_model_fromYear}</td>
                              <td>{data?.vehicle_type_model}</td>
                              <td>{data?.vehicle_model_range}</td>
                              <td>{data?.vehicle_type_bodyType}</td>
                              <td>{data?.vehicle_type_ccm}</td>
                              <td>{data?.vehicle_type_cyl}</td>
                            </tr>
                          );
                        })}
                        {vehiclePlateData?.cars_etai?.filter((data) =>
                          data?.vehicle_type_description
                            ?.toLowerCase()
                            .includes(searchQuery.toLowerCase())
                        ).length === 0 && (
                          <tr>
                            <td colSpan={14} className="text-center">
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>

            <div
              className="d-flex justify-content-end"
              style={{ padding: "10px" }}
            ></div>
          </div>
        </ModalBody>
        <ModalFooter className="border-0" style={{ marginTop: "-18px" }}>
          <button
            type="button"
            class="btn btn-block btn-payment"
            onClick={handleSelectEtaiDetails}
            style={{ width: "auto", margin: "auto", padding: "5px 12px" }}
          >
            Select <i class="bi bi-x-lg"></i>
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={bodyWasteModal}>
        <ModalHeader>Depollution</ModalHeader>
        <Form method={`post`} onSubmit={handleSubmit}>
          <ModalBody>
            <div className="row">
              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my form-label form-label">
                    {"Select LER Catalog"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <Select
                        className="my-Select"
                        options={LerCatalogList}
                        value={LerCatalogList.find(
                          (item) => item.value == vehiclePartsData.ler_code_id
                        )}
                        onChange={handleSelectLERChange}
                        placeholder={"Select LER catalog"}
                        isSearchable={true}
                        onInputChange={handleInputChangeLER}
                        inputValue={inputValueLer}
                        isClearable={true}
                      />
                    </div>
                  </div>
                </FormGroup>
              </div>
              {vehiclePartsData?.ler_code_id && (
                <div className="row">
                  <div className="col-md-6">
                    <ul style={{ listStyle: "square", marginBottom: "0" }}>
                      <li>LER Code: {LerCatalogHistoryList?.ler_code}</li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul style={{ listStyle: "square", marginBottom: "0" }}>
                      <li>Unit Type: {LerCatalogHistoryList?.item_weight}</li>
                    </ul>
                  </div>

                  <div className="col-md-6">
                    <ul style={{ listStyle: "square", marginBottom: "0" }}>
                      <li>
                        Unit Value: {LerCatalogHistoryList?.item_weight_unit}
                      </li>
                    </ul>
                  </div>

                  <div className="col-md-6">
                    <ul style={{ listStyle: "square", marginBottom: "0" }}>
                      <li>
                        Old Value: {LerCatalogHistoryList?.old_value ?? 0}
                      </li>
                    </ul>
                  </div>
                </div>
              )}
              <div className="col-md-6">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my form-label form-label">
                    {"Wastege Value"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="number"
                        value={vehiclePartsData.wastege_value}
                        onChange={(e) => {
                          setVehiclePartsData({
                            ...vehiclePartsData,
                            wastege_value: e.target.value,
                          });
                        }}
                        name={`wastege_value`}
                        maxLength={100}
                        placeholder="Wastege Value"
                      />
                    </div>
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my form-label form-label">
                    {"Description"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <textarea
                        className="form-control input-search"
                        type="text"
                        value={vehiclePartsData.description}
                        onChange={(e) => {
                          setVehiclePartsData({
                            ...vehiclePartsData,
                            description: e.target.value,
                          });
                        }}
                        name={`description`}
                        maxLength={100}
                        placeholder="Description"
                        rows={3}
                      />
                    </div>
                  </div>
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-secondary"
              onClick={handleBodyWasteClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-x-lg"></i>
            </button>
            <button
              type="submit"
              class="btn btn-block btn-primary"
              style={{ width: "100px", marginTop: "10px" }}
            >
              Submit <i class="bi bi-check2"></i>
            </button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={viewModal} size="lg">
        <div className="border m-3 p-3">
          <ModalHeader className="p-0 pb-2">Vehicle Details</ModalHeader>

          <ModalBody>
            {updateFilterVinData?.length > 0 ? (
              <>
                <div className="com-md-12">
                  <div className="col-md-12">
                    <h4>Cars ETAI Details</h4>
                    <table className="fuelTable">
                      {updateFilterVinData?.map((item) => {
                        return (
                          <>
                            <tbody className="row">
                              <tr className="col-md-6">
                                <td>Description</td>
                                <td>:</td>
                                <td>{item?.vehicle_type_description}</td>
                              </tr>
                              <tr className="col-md-6">
                                <td>Year</td>
                                <td>:</td>
                                <td>{item?.vehicle_model_fromYear}</td>
                              </tr>
                              <tr className="col-md-6">
                                <td>Range</td>
                                <td>:</td>
                                <td>{item?.vehicle_model_range}</td>
                              </tr>
                              <tr className="col-md-6">
                                <td>Type</td>
                                <td>:</td>
                                <td>{item?.vehicle_type_vehicleType}</td>
                              </tr>
                              <tr className="col-md-6">
                                <td>Model</td>
                                <td>:</td>
                                <td>{item?.vehicle_type_model}</td>
                              </tr>
                              <tr className="col-md-6">
                                <td>Manufacter</td>
                                <td>:</td>
                                <td>{item?.vehicle_type_manufacter}</td>
                              </tr>
                              <tr className="col-md-6">
                                <td>GgearboxType</td>
                                <td>:</td>
                                <td>{item?.vehicle_type_gearboxType}</td>
                              </tr>

                              <tr className="col-md-6"></tr>
                            </tbody>
                          </>
                        );
                      })}
                    </table>
                  </div>

                  <div className="col-md-12" style={{ marginTop: "43px" }}>
                    <h4>Cars TecDoc Details</h4>
                    {vehicleVinData?.cars_tecdoc?.map((item) => {
                      return (
                        <>
                          <table className="fuelTable">
                            <tbody className="row">
                              <tr className="col-md-6">
                                <td>Description</td>
                                <td>:</td>
                                <td>{item?.vehicle_type_description}</td>
                              </tr>
                              <tr className="col-md-6">
                                <td>Year</td>
                                <td>:</td>
                                <td>{item?.vehicle_model_fromYear}</td>
                              </tr>
                              <tr className="col-md-6">
                                <td>Range</td>
                                <td>:</td>
                                <td>{item?.vehicle_model_range}</td>
                              </tr>
                              <tr className="col-md-6">
                                <td>Type</td>
                                <td>:</td>
                                <td>{item?.vehicle_type_vehicleType}</td>
                              </tr>
                              <tr className="col-md-6">
                                <td>Model</td>
                                <td>:</td>
                                <td>{item?.vehicle_type_model}</td>
                              </tr>
                              <tr className="col-md-6">
                                <td>Manufacter</td>
                                <td>:</td>
                                <td>{item?.vehicle_type_manufacter}</td>
                              </tr>
                              <tr className="col-md-6">
                                <td>GgearboxType</td>
                                <td>:</td>
                                <td>{item?.vehicle_type_gearboxType}</td>
                              </tr>

                              <tr className="col-md-6"></tr>
                            </tbody>
                          </table>
                        </>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="com-md-12">
                  <div className="col-md-12">
                    <h4>Cars ETAI Details</h4>
                    <table className="fuelTable">
                      <tbody className="row">
                        <tr className="col-md-6">
                          <td>Description</td>
                          <td>:</td>
                          <td>
                            {SelectedVehicleData?.vehicle_type_description}
                          </td>
                        </tr>
                        <tr className="col-md-6">
                          <td>Year</td>
                          <td>:</td>
                          <td>{SelectedVehicleData?.vehicle_model_fromYear}</td>
                        </tr>
                        <tr className="col-md-6">
                          <td>Range</td>
                          <td>:</td>
                          <td>{SelectedVehicleData?.vehicle_model_range}</td>
                        </tr>
                        <tr className="col-md-6">
                          <td>Type</td>
                          <td>:</td>
                          <td>
                            {SelectedVehicleData?.vehicle_type_vehicleType}
                          </td>
                        </tr>
                        <tr className="col-md-6">
                          <td>Model</td>
                          <td>:</td>
                          <td>{SelectedVehicleData?.vehicle_type_model}</td>
                        </tr>
                        <tr className="col-md-6">
                          <td>Manufacter</td>
                          <td>:</td>
                          <td>
                            {SelectedVehicleData?.vehicle_type_manufacter}
                          </td>
                        </tr>
                        <tr className="col-md-6">
                          <td>GgearboxType</td>
                          <td>:</td>
                          <td>
                            {SelectedVehicleData?.vehicle_type_gearboxType}
                          </td>
                        </tr>

                        <tr className="col-md-6"></tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="col-md-12" style={{ marginTop: "43px" }}>
                    <h4>Cars TecDoc Details</h4>
                    <table className="fuelTable">
                      <tbody className="row">
                        <tr className="col-md-6">
                          <td>Description</td>
                          <td>:</td>
                          <td>
                            {SelectedVehicleData?.vehicle_type_description}
                          </td>
                        </tr>
                        <tr className="col-md-6">
                          <td>Year</td>
                          <td>:</td>
                          <td>{SelectedVehicleData?.vehicle_model_fromYear}</td>
                        </tr>
                        <tr className="col-md-6">
                          <td>Range</td>
                          <td>:</td>
                          <td>{SelectedVehicleData?.vehicle_model_range}</td>
                        </tr>
                        <tr className="col-md-6">
                          <td>Type</td>
                          <td>:</td>
                          <td>
                            {SelectedVehicleData?.vehicle_type_vehicleType}
                          </td>
                        </tr>
                        <tr className="col-md-6">
                          <td>Model</td>
                          <td>:</td>
                          <td>{SelectedVehicleData?.vehicle_type_model}</td>
                        </tr>
                        <tr className="col-md-6">
                          <td>Manufacter</td>
                          <td>:</td>
                          <td>
                            {SelectedVehicleData?.vehicle_type_manufacter}
                          </td>
                        </tr>
                        <tr className="col-md-6">
                          <td>GgearboxType</td>
                          <td>:</td>
                          <td>
                            {SelectedVehicleData?.vehicle_type_gearboxType}
                          </td>
                        </tr>

                        <tr className="col-md-6"></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter className="p-0">
            <button
              type="button"
              class="btn btn-block btn-primary"
              onClick={handleViewClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-x-lg"></i>
            </button>
          </ModalFooter>
        </div>
      </Modal>
    </div>
  );
};

export default Vehicles;
