import axiosInstance from "./AxiosInstance";

export function getVehicleModelList(data) {
  return axiosInstance.post(`/vehicle-model/list`, data);
}

export function deleteVehicleModel(data) {
  return axiosInstance.post(`/vehicle-model/delete`, data);
}

export function createVehicleModel(data) {
  return axiosInstance.post(`/vehicle-model/create`, data);
}

export function updateVehicleModel(data) {
  return axiosInstance.post(`/vehicle-model/update`, data);
}

export function importVehiclemodel(data) {
  return axiosInstance.post(`/vehicle-model/import`, data);
}

export function exportVehiclemodel(data) {
  return axiosInstance.post(`/vehicle-model/export-list`, data);
}

export function getVehicleAllMakeList(data) {
  return axiosInstance.post(`/vehicle-make/search-list`, data);
}

export function getVehicleAllModalSerachList(data) {
  return axiosInstance.post(`/vehicle-model/search-list`, data);
}

export function getVehicleSerachMakeList(data) {
  return axiosInstance.post(`/vehicle-make/search-list-make`, data);
}
