import React, { useEffect, useState } from "react";
import VehiclePartsAdd from "./VehiclePartsAdd";
import { useParams } from "react-router-dom";
import {
  PartInventoryImageDetails,
  getPartInventoryDetails,
} from "../../services/PartsInventory";

const AddParts = () => {
  const { uuid } = useParams();
  const [vehicleAddDetails, setvehicleAddDetails] = useState({});
  const [vehicleImageDetails, setvehicleImageDetails] = useState([]);

  useEffect(
    function () {
      if (uuid) {
        var obj = {
          id: uuid,
        };
        getPartInventoryDetails(obj)
          .then((response) => {
            response = response.data.data;
            setvehicleAddDetails(response);
          })
          .catch((err) => {});
      }
    },

    []
  );

  useEffect(
    function () {
      if (uuid) {
        var obj = {
          id: uuid,
        };
        PartInventoryImageDetails(obj)
          .then((response) => {
            response = response.data.data;
            setvehicleImageDetails(response);
          })
          .catch((err) => {});
      }
    },

    [uuid]
  );

  return (
    <>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"></li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <VehiclePartsAdd
              vehicleAddDetails={vehicleAddDetails}
              vehicleImageDetails={vehicleImageDetails}
              setvehicleImageDetails={setvehicleImageDetails}
            />
          </div>
        </section>
      </div>
    </>
  );
};

export default AddParts;
