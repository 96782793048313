import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useLocation } from "react-router-dom";
import validator from "validator";
import Select from "react-select";
import {
  DestoryUser,
  createUser,
  getUserList,
  getRoleList,
  updateUser,
} from "../../services/UserServices";
import { getVehicleList } from "../../services/PartsInventory";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";

const User = () => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);
  const location = useLocation();
  const RolePermission = Utils.getRecordForCurrentPath(location);
  const { t } = useTranslation();

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [userList, setUserList] = useState([]);
  const [delModal, setDelModal] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [delpart, setDelPart] = useState("");
  const [modifyModal, setModifyModal] = useState(false);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [roleList, setRolelList] = useState([]);
  const [userData, setUserData] = useState({
    uuid: "",
    name: "",
    email: "",
    role_id: "",
    profile: "",
    password: "",
    status: "",
  });

  const [image, setImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  let errorsObj = {
    name: "",
    email: "",
    role_id: "",
    profile: "",
    password: "",
    status: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files[0];

    if (selectedFiles) {
      setImage(selectedFiles);
      const imageUrl = URL.createObjectURL(selectedFiles);
      setPreviewUrl(imageUrl);
    }
  };
  const validateImage = (image) => {
    if (!image) {
      return false;
    }
    const allowedTypes = ["image/jpeg", "image/png"];
    const maxSize = 2 * 1024 * 1024; // 2MB
    return allowedTypes.includes(image.type) && image.size <= maxSize;
  };

  const handleAddModal = () => {
    setModifyModal(true);
    LoadRoleList();
  };

  const handleCloseModal = () => {
    setModifyModal(false);
    setUserData({
      name: "",
      email: "",
      role_id: "",
      profile: "",
      password: "",
      status: "",
    });
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const fetchUser = () => {
    let data = { ...params };
    getUserList(data)
      .then((response) => {
        setUserList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };

      fetchUser(data);
    },
    [params]
  );

  function LoadRoleList() {
    getRoleList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setRolelList(list);
      })
      .catch((err) => {});
  }

  const onEditUser = (e, item) => {
    setModifyModal(true);
    LoadRoleList();
    setUserData({
      uuid: item?.uuid,
      name: item?.user_name,
      email: item?.email,
      role_id: item?.role_id,
      profile: item?.profile,
      // password: item?.password,
    });
    setPreviewUrl(item?.profile);
  };

  const onDeleteModalClose = () => {
    setDelModal(false);
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelPart(item.uuid);
    setDelModal(true);
  };

  const onDestroyPartType = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delpart };
    DestoryUser(data)
      .then((response) => {
        fetchUser();
        toast.success(response.data.message);
        setDelPart("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleUserForm = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };
    if (!userData.name) {
      errorObj.name = `User name is required.`;
      error = true;
    }

    if (!userData.email) {
      errorObj.email = "Email address is required";
      error = true;
    } else if (!validator.isEmail(userData.email)) {
      errorObj.email = "Please enter valid email address";
      error = true;
    } else if (!userData.role_id) {
      errorObj.role_id = `Role is required.`;
      error = true;
    }

    if (!userData?.uuid) {
      if (!userData.password) {
        errorObj.password = "password is required";
        error = true;
      }
    }

    setErrors(errorObj);
    Utils.focusError();

    if (error) return;

    setIsModifyButtonLoad(true);

    const formData = new FormData();
    formData.append("profile", image);
    formData.append("password", userData?.password);
    formData.append("name", userData?.name);
    formData.append("email", userData?.email);
    formData.append("role_id", userData?.role_id);

    const formUpdateData = new FormData();
    formUpdateData.append("uuid", userData?.uuid);
    formUpdateData.append("profile", image);
    formUpdateData.append("password", userData?.password);
    formUpdateData.append("name", userData?.name);
    formUpdateData.append("email", userData?.email);
    formUpdateData.append("role_id", userData?.role_id);

    if (userData?.uuid) {
      updateUser(formUpdateData)
        .then((response) => {
          toast.success(response.data.message);
          let datas = { ...params };

          fetchUser(datas);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    } else {
      createUser(formData)
        .then((response) => {
          toast.success(response.data.message);
          let datas = { ...params };

          fetchUser(datas);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div>
      <>
        {isLoading ? (
          // <div className="text-center pageLoader">
          //   <div className="spinner-border" role="status">
          //     <span className="visually-hidden">Loading...</span>
          //   </div>
          // </div>
          <div className="skeleton-loader p-3 d-none">
            <div className="card">
              <div className="card-header">
                <table className="table rounded-table table-striped table-hover">
                  <thead>
                    <tr>
                      <th
                        style={{ width: 10, borderTop: "0", borderBottom: "0" }}
                      >
                        <div className="skeleton"></div>
                      </th>
                      <th style={{ borderTop: "0", borderBottom: "0" }}>
                        <div className="skeleton"></div>
                      </th>
                      <th style={{ borderTop: "0", borderBottom: "0" }}></th>
                      <th
                        class="d-flex"
                        style={{ borderTop: "0", borderBottom: "0" }}
                      >
                        <div class="skeleton me-1"></div>
                        <div class="skeleton me-1"></div>
                        <div class="skeleton"></div>
                      </th>
                      <th
                        style={{ width: 10, borderTop: "0", borderBottom: "0" }}
                      >
                        <div className="skeleton"></div>
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div className="card-body">
                <div className="vehicleThBg table-responsive">
                  <table className="table table-bordered rounded-table table table-striped table-hover">
                    <thead>
                      <tr>
                        <th style={{ backgroundColor: "#c0c0c0" }}>
                          <div className="skeleton"></div>
                        </th>
                        <th style={{ backgroundColor: "#c0c0c0" }}>
                          <div className="skeleton"></div>
                        </th>
                        <th style={{ backgroundColor: "#c0c0c0" }}>
                          <div className="skeleton"></div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {userList?.map((_, index) => (
                        <tr key={index}>
                          <td>
                            <div className="skeleton"></div>
                          </td>
                          <td>
                            <div className="skeleton"></div>
                          </td>
                          <td>
                            <div className="skeleton"></div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="card-footer">
                <div className="skeleton col-lg-3 float-right"></div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">{/* <h1>User</h1> */}</div>
                  <div className="col-sm-6"></div>
                </div>
              </div>
              {/* /.container-fluid */}
            </section>
            <section className="content">
              <div className="container-fluid container-custom-height-new">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card fuelCard">
                      <div className="card-header">
                        <div
                          className="card-title m-0 float-left"
                          style={{ width: "50%" }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={params.search}
                            onChange={(e) => handleParams(e, "search")}
                            placeholder="Search"
                          ></input>
                        </div>
                        <div className="card-title m-0 float-right d-flex">
                          {RolePermission?.findIndex(
                            (e) => e.permission_name === "add_user"
                          ) > -1 && (
                            <button
                              type="button"
                              className="btn  btn-icon-text mr-2"
                              onClick={handleAddModal}
                            >
                              <i className="bi bi-plus-lg"></i>
                              <span className="ms-2">{t("Add")}</span>
                            </button>
                          )}

                          {/* )} */}
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="vehicleThBg table-responsive">
                          <table className="table table-bordered rounded-table table table-striped table-hover">
                            <thead>
                              <tr>
                                <th style={{ width: "10%" }}>#</th>
                                <th
                                  className="col-form-label ml-2 Label-my form-label form-label"
                                  style={{ width: "10%" }}
                                >
                                  {t("name")}
                                </th>
                                <th
                                  className="col-form-label ml-2 Label-my form-label form-label"
                                  style={{ width: "10%" }}
                                >
                                  {t("e_mail")}
                                </th>
                                <th
                                  className="col-form-label ml-2 Label-my form-label form-label"
                                  style={{ width: "10%" }}
                                >
                                  Role
                                </th>
                                {/* <th className="col-form-label ml-2 Label-my form-label form-label">
                                Status
                              </th> */}
                                <th
                                  className="col-form-label ml-2 Label-my form-label form-label"
                                  style={{ width: "0%" }}
                                >
                                  {t("action")}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {userList?.map((item, index) => {
                                return (
                                  <>
                                    <tr key={item.id}>
                                      <td>{++index}</td>
                                      <td>{item.user_name}</td>
                                      <td>{item.email}</td>
                                      <td>{item.role_name}</td>

                                      <td>
                                        {/* {companyId.role_id == 1 ? ( */}
                                        <td className="d-flex">
                                          <Dropdown className="iconDropdown"
                                  onToggle={(isOpen) => {
                                      if (isOpen) {
                                          // This logic runs when the dropdown is opened
                                          const tableResponsive =
                                              document.querySelector(
                                                  ".table-responsive"
                                              );
                                          if (tableResponsive) {
                                              tableResponsive.style.overflowX =
                                                  "inherit !important";
                                              tableResponsive.style.overflowY =
                                                  "inherit !important";
                                          }
                                      }
                                  }}
                                      >

                                            <Dropdown.Toggle
                                              variant="success"
                                              id="dropdown-basic"
                                              className="iconToggle"
                                            >
                                              <i className="bi bi-three-dots-vertical"></i>{" "}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item
                                                href="#/action-1"
                                                className="d-flex"
                                                onClick={(e) =>
                                                  onEditUser(e, item)
                                                }
                                              >
                                                {RolePermission?.findIndex(
                                                  (e) =>
                                                    e.permission_name ===
                                                    "modify_user"
                                                ) > -1 && (
                                                  <a
                                                    className="edit-btn"
                                                    href="#"
                                                  >
                                                    <i className="bi bi-pencil"></i>
                                                  </a>
                                                )}
                                                <span>Edit</span>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                href="#/action-2"
                                                className="d-flex"
                                                onClick={(e) =>
                                                  onDeleteModalOpen(e, item)
                                                }
                                              >
                                                {RolePermission?.findIndex(
                                                  (e) =>
                                                    e.permission_name ===
                                                    "delete_user"
                                                ) > -1 && (
                                                  <div className="trash-btn">
                                                    <a
                                                      className="delete-btn"
                                                      href="#"
                                                    >
                                                      <i className="bi bi-trash3"></i>
                                                    </a>
                                                  </div>
                                                )}
                                                <span>Delete</span>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </td>
                                        {/* ) : null} */}
                                      </td>
                                      {/* <a
                                    className="btn btn-app"
                                    href="#"
                                    onClick={(e) => onEditPartType(e, item)}
                                  >
                                    <i class="bi bi-pen"></i>
                                  </a> */}
                                    </tr>
                                  </>
                                );
                              })}
                            </tbody>
                            {userList && userList?.length === 0 && (
                              <tr>
                                <td colSpan={7} className={`text-center`}>
                                  {" "}
                                  {t("no_record_found")}
                                </td>
                              </tr>
                            )}
                          </table>
                        </div>
                      </div>
                      {/* /.card-body */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="fuelCF">
                <div className="card-footer clearfix card-footer-part">
                  <div className="pagination pagination-sm m-0 float-right">
                    <Pagination
                      activePage={params.page}
                      itemsCountPerPage={parseInt(params.limit)}
                      totalItemsCount={parseInt(totalRecords)}
                      pageRangeDisplayed={5}
                      itemClass={`page-item`}
                      linkClass={`page-link`}
                      onChange={(e) => handleParams(e, "pagination")}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
        <Modal isOpen={delModal}>
          <ModalHeader>{`Delete User`}</ModalHeader>
          <ModalBody>
            <p
              className={`mb-0`}
            >{`Are you sure you want to delete this User?`}</p>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-primary"
              disabled={isDelButtonLoad}
              onClick={onDeleteModalClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-x-lg"></i>
            </button>

            {!isDelButtonLoad && (
              <button
                type="button"
                className="btn btn-block bg-red"
                style={{ width: "100px" }}
                onClick={onDestroyPartType}
              >
                Delete <i class="bi bi-trash3"></i>
              </button>
            )}
            {isDelButtonLoad && (
              <button
                color="secondary"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
              </button>
            )}
          </ModalFooter>
        </Modal>

        <Modal isOpen={modifyModal}>
          <ModalHeader>User</ModalHeader>
          <Form method={`post`} onSubmit={handleUserForm}>
            <ModalBody>
              <div className="row">
                <div className="col-md-6">
                  <FormGroup>
                    <Label className="col-form-label ml-2 Label-my form-label form-label">
                      {"Name"}
                    </Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <input
                          className="form-control input-search form-control"
                          type="text"
                          value={userData.name}
                          onChange={(e) => {
                            setUserData({
                              ...userData,
                              name: e.target.value,
                            });
                          }}
                          name={`name`}
                          maxLength={50}
                          placeholder="Name"
                        />
                      </div>
                      {errors.name && (
                        <span className="input-error">{errors.name}</span>
                      )}
                    </div>
                  </FormGroup>
                </div>

                <div className="col-md-6">
                  <FormGroup>
                    <Label className="col-form-label ml-2 Label-my form-label form-label">
                      {t("e_mail")}
                    </Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <input
                          className="form-control input-search form-control"
                          type="text"
                          value={userData.email}
                          onChange={(e) => {
                            setUserData({
                              ...userData,
                              email: e.target.value,
                            });
                          }}
                          name={`email`}
                          maxLength={40}
                          placeholder="Email"
                        />
                      </div>
                      {errors.email && (
                        <span className="input-error">{errors.email}</span>
                      )}
                    </div>
                  </FormGroup>
                </div>

                <div className="col-md-6">
                  <FormGroup>
                    <Label className="col-form-label ml-2 Label-my form-label form-label">
                      {"Password"}
                    </Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <input
                          type="password"
                          className="form-control input-search form-control"
                          id="password"
                          placeholder="Enter new password"
                          value={userData.password}
                          onChange={(e) => {
                            setUserData({
                              ...userData,
                              password: e.target.value,
                            });
                          }}
                        />
                      </div>
                      {errors.password && (
                        <span className="input-error">{errors.password}</span>
                      )}
                    </div>
                  </FormGroup>
                </div>
                <div className="col-md-6">
                  <FormGroup>
                    <Label className="col-form-label ml-2 Label-my form-label form-label">
                      {"User Role"}
                    </Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <Select
                          className="my-select-v"
                          options={roleList}
                          value={roleList?.find(
                            (x) => x.value === parseInt(userData.role_id)
                          )}
                          onChange={(selectedOption) =>
                            setUserData({
                              ...userData,
                              role_id: selectedOption.value,
                            })
                          }
                          placeholder={"Select Role"}
                        />
                      </div>
                      {errors.role_id && (
                        <span className="input-error">{errors.role_id}</span>
                      )}
                    </div>
                  </FormGroup>
                </div>
                <div className="col-md-12">
                  <div className="col-md-12">
                    <Label className="col-form-label ml-2 Label-my form-label form-label">
                      {"Profile"}
                    </Label>
                    <div className="avatar-upload">
                      <div className="avatar-edit">
                        <div className="input-div">
                          <input
                            className="inputFile"
                            type="file"
                            id="imageUpload"
                            onChange={handleFileChange}
                            name="file"
                            accept=".jpg,.jpeg,.png"
                          />
                        </div>
                        <label htmlFor="imageUpload"></label>
                      </div>
                      <div className="avatar-preview profile-user">
                        <img src={previewUrl} className="preview-image" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6"></div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                class="btn btn-block btn-primary"
                disabled={isModifyButtonLoad}
                onClick={handleCloseModal}
                style={{ width: "100px", marginTop: "10px" }}
              >
                Close <i class="bi bi-x-lg"></i>
              </button>
              {!isModifyButtonLoad && (
                <button
                  type="submit"
                  class="btn btn-block btn-primary"
                  style={{ width: "100px", marginTop: "10px" }}
                >
                  Submit <i class="bi bi-check2"></i>
                </button>
              )}
              {isModifyButtonLoad && (
                <Button
                  color="success"
                  type={`button`}
                  disabled={true}
                  className={`btn-square`}
                >
                  <i className="fa fa-circle-o-notch fa-spin"></i>{" "}
                  {`Loading...`}
                </Button>
              )}
            </ModalFooter>
          </Form>
        </Modal>
      </>
    </div>
  );
};

export default User;
