import React, { useEffect, useRef, useState } from "react";
import {
  getCatalogList,
  actionPartInventoryTempStore,
  getVehicleList,
  getPartInventoryTempList,
  DestoryPartInventoryTemp,
  createPartsInventory,
  updatePartsInventory,
  actionPartInventoryImage,
  partInvenoryPartETAI,
  partInvenoryPartETAIPrice,
  getCatalogModalStatsHistoryDetails,
  getPartStatiscalPriceDetails,
  getPartEstimatePriceDetails,
  getPartRefcodeConditionDetails,
} from "../../services/PartsInventory";
import Select from "react-select";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getVehicleModelAllList,
  getvehicleMakeAllList,
  getvehicleYearList,
  updateVehiclePartImageSort,
} from "../../services/VehicleService";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Pagination from "react-js-pagination";
import { getAllViewShelfList } from "../../services/ShelfServices";
import {
  getCommonSearchList,
  getSettingsCompanyDetails,
} from "../../services/settingsServices";

import { getAllBrandSerachList } from "../../services/PartBrandServices";
import { getVehicleAllModalSerachList } from "../../services/VehicleModelService";

import {
  Modal,
  Button,
  FormGroup,
  Label,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { getVehicleSerachVariantList } from "../../services/VehicleVariantsService";
import { getVehicleEngineSerachList } from "../../services/VehicleEngineService";
import {
  actionTempStoreDismental,
  getDismentalList,
} from "../../services/DismentalServices";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { useTranslation } from "react-i18next";
import ReactStars from "react-rating-stars-component";
import AssignedDamageImage from "./AssignedDamageImage";
import AddPartVideo from "./AddPartVideo";
import { profileDetailForWeb } from "../../services/AuthServices";
import AssignedDamageVideo from "./AssignedDamageVideo";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { ReactSketchCanvas } from "react-sketch-canvas";

const AddForParts = ({ vehicleAddDetails }) => {
  const tableLengthList = ConfigDB?.data?.dataTableLength;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const location = useLocation();
  const selectedRowsEdit = location?.state?.data?.temp_id || null;
  const LangData = localStorage.getItem("lng");

  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryTemp, setSearchQueryTemp] = useState("");
  const [VehicleModelList, setVehicleModelList] = useState([]);
  const [VehicleModelAllList, setVehicleModelAllList] = useState([]);
  const [CatalogList, setCatalogList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [tempData, setTempData] = useState({});
  const [partsData, setPartsData] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [inputSelctedValue, setInputSelctedValue] = useState("");
  const [categoryPartList, setCategoryPartList] = useState([]);
  const [vehicleYearPartList, setvehicleYearPartList] = useState([]);
  const [inputSelctedYearValue, setInputSelctedYearValue] = useState("");
  const [vehicleMakePartList, setvehicleMakePartList] = useState([]);
  const [inputSelctedMakeValue, setInputSelctedMakeValue] = useState("");
  const [vehicleModalPartList, setvehicleModalPartList] = useState([]);
  const [inputSelctedModalValue, setInputSelctedModalValue] = useState("");
  const [vehicleVariantPartList, setvehicleVariantPartList] = useState([]);
  const [inputSelctedVariantValue, setInputSelctedVariantValue] = useState("");
  const [vehicleEngineList, setvehicleEngineList] = useState([]);
  const [inputSelctedEngineValue, setInputSelctedEngineValue] = useState("");
  const [DismetalList, setDismetalList] = useState([]);
  const [inputSelctedDismetalValue, setInputSelctedDismetalValue] =
    useState("");
  const [dismentalData, setDismentalData] = useState({
    dismantle_id: "",
  });
  const [dismantleTempData, setDismantleTempData] = useState("");
  const [categoryPartsData, setCategoryPartsData] = useState({
    catalog_id: "",
    vehicle_year: "",
    vehicle_make: "",
    vehicle_model: "",
    vehicle_variant: "",
    is_master_id: "",
    vehicle_make_name: "",
    vehicle_model_name: "",
    vehicle_variant_name: "",
  });
  const [categoryMasterPart, setCategoryMasterPart] = useState({});

  const [modifyMasterPart, setModifyMasterPart] = useState(false);

  const [EngineData, setEngineData] = useState({
    engine_code: "",
    kw: "",
    hp: "",
  });

  const [selectedVehicleModel, setSelectedVehicleModel] = useState({});
  const [selectedInventoryStatus, setselectedInventoryStatus] = useState({});

  const [vehiclePartsData, setVehiclePartsData] = useState({
    vehicle_model_id: "",
    vehicle_make: "",
    vehicle_year: "",
    vehicle_model: "",
    vehicle_variants: "",
  });

  let errorsObj = {
    part_code: "",
    part_name: "",
    quantity: "",
    part_price: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const [images, setImage] = useState([]);
  const [previewUrl, SetPreviewUrl] = useState([]);
  const [showModalAbout, setShowModalAbout] = useState(false);
  const [detailsForImages, SetDetailsForImages] = useState([]);
  const [currentPartIndex, setCurrentPartIndex] = useState(0);
  const [showSkip, setShowSkip] = useState(false);
  const [enlargedIndexes, setEnlargedIndexes] = useState([]);
  const [ShelfList, SetShelfList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [conditionList, setConditionList] = useState([]);
  const [inventoryList, setInventoryList] = useState([]);
  const [modifyLabel, setmodifyLabel] = useState(false);
  const [settingDetails, setSettingDetails] = useState("");
  const [allBrandList, SetAllBrandList] = useState([]);

  //save as label
  const [btnLoader, setBtnLoader] = useState(false);
  const [btnLoading, setbtnLoading] = useState(false);
  const [btnClosed, setBtnClosed] = useState(false);
  const [btnSaved, setBtnSaved] = useState(false);

  const [showSuggestionsMake, setShowSuggestionsMake] = useState(false);
  const [catalogMake, setCatalogMake] = useState("");
  const [vehicleMakeId, setVehicleMakeId] = useState("");

  const [filteredSuggestionsModel, setFilteredSuggestionsModel] = useState([]);
  const [activeSuggestionIndexModel, setActiveSuggestionIndexModel] =
    useState(0);
  const [showSuggestionsModel, setShowSuggestionsModel] = useState(false);
  const [vehicleModelAuto, setVehicleModelAuto] = useState("");
  const [vehicleModelId, setVehicleModelId] = useState("");
  const [selectedModels, setSelectedModels] = useState([]);

  const [catalogPart, setCatalogPart] = useState("");
  const [vehiclePartId, setVehiclePartId] = useState("");
  const [referenceList, setReferenceList] = useState([]);
  const [partETAIRerList, setPartETAIRerList] = useState("");
  const [partEtaiModify, setPartEtaiModify] = useState(false);
  const [partEtaiPriceModify, setPartEtaiPriceModify] = useState(false);
  const [partETAIPriceList, setPartETAIPriceList] = useState("");
  const [partETAIRefDetails, setPartETAIRefDetails] = useState([]);
  const [partETAIPriceDetail, setPartETAIPriceDetail] = useState([]);
  const [selectedReferenceCode, setSelectedReferenceCode] = useState(null);
  const [modifyImportModal, setModifyImportModal] = useState(false);

  const [selectedItemsCheckBox, setSelectedItemsCheckBox] = useState([]);
  const [locationDamageList, setLocationDamageList] = useState([]);
  const [typeDamageList, setTypeDamageList] = useState([]);
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [partImageDataList, setPartImageDataList] = useState([]);
  const [partVideoDataList, setPartVideoDataList] = useState([]);

  const [catalogModalStateDataList, setCatalogModalStateDataList] = useState(
    []
  );
  const [catalogModalStateDataId, setCatalogModalStateDataId] = useState("");

  const [modalStateHistory, setModalStateHistory] = useState(false);
  const [modalSponsorPrice, setModalSponsorPrice] = useState(false);
  const [sponsorPriceDataId, setSponsorPriceDataId] = useState("");
  const [sponsorPricDataList, setSponsorPricDataList] = useState([]);

  const [modalEstimatePrice, setModalEstimatePrice] = useState(false);
  const [estimatePriceDataId, setEstimatePriceDataId] = useState("");
  const [estimatePricDataList, setEstimatePricDataList] = useState([]);
  const [profileDetails, setProfileDetails] = useState("");

  const [modalRefCodeCondition, setModalRefCodeCondition] = useState(false);
  const [modalRefCodeEstimate, setModalRefCodeEstimate] = useState(false);
  const [refCodeEstimateDataList, setRefCodeEstimateDataList] = useState("");

  const [refCodeConditionDataId, setRefCodeConditionDataId] = useState("");
  const [refCodeConditionDataList, setRefCodeConditionDataList] = useState([]);

  const [selectedImage, setSelectedImage] = useState(null);

  const [rotation, setRotation] = useState(0);
  const canvasRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const imgRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [crop, setCrop] = useState({ unit: "%", width: 50, height: 50 });
  const [highlights, setHighlights] = useState([]);
  const [selectedColor, setSelectedColor] = useState("#ff0000");
  const [isCropping, setIsCropping] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadings, setIsLoadings] = useState(false);
  const [isLoadingColor, setIsLoadingColor] = useState(false);
  const [settingCompanyDetails, setSettingCompanyDetails] = useState("");
  const [isDrawing, setIsDrawing] = useState(false);

  const onImageLoad = (img) => {
    imgRef.current = img;
  };

  const onImageClick = (e) => {
    const { offsetX, offsetY } = e.nativeEvent;
    const newHighlight = {
      x: offsetX,
      y: offsetY,
      radius: 20,
      color: selectedColor,
    };

    setHighlights([...highlights, newHighlight]);
  };

  const handleCrop = () => {
    setIsCropping(!isCropping);
  };

  const saveDrawing = async (fileId) => {
    setIsDrawing((prev) => !prev);
  };

  const handleImageSelect = (file) => {
    setImage((prevFiles) =>
      prevFiles.map((f) => (f.id === file.id ? { ...f } : f))
    );
    setSelectedImage(file);
    setCrop(null);
    setRotation(0);
    setHighlights([]);
    setIsModalOpen(true);
  };

  const getCroppedImage = async () => {
    if (!imgRef.current || !selectedImage) return;

    const image = imgRef.current;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    let cropX = 0,
      cropY = 0,
      cropWidth = image.naturalWidth,
      cropHeight = image.naturalHeight;

    if (completedCrop) {
      cropX = completedCrop.x * scaleX;
      cropY = completedCrop.y * scaleY;
      cropWidth = completedCrop.width * scaleX;
      cropHeight = completedCrop.height * scaleY;
    }

    const radians = (rotation * Math.PI) / 180;
    canvas.width = cropWidth;
    canvas.height = cropHeight;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.save();

    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate(radians);
    ctx.translate(-canvas.width / 2, -canvas.height / 2);

    ctx.drawImage(
      image,
      cropX,
      cropY,
      cropWidth,
      cropHeight,
      0,
      0,
      cropWidth,
      cropHeight
    );

    ctx.restore();

    if (canvasRef.current) {
      try {
        const drawingDataURL = await canvasRef.current.exportImage("png");

        if (drawingDataURL) {
          const drawingImage = new Image();
          drawingImage.src = drawingDataURL;
          await new Promise((resolve) => (drawingImage.onload = resolve));

          ctx.drawImage(drawingImage, 0, 0, cropWidth, cropHeight);
        }
      } catch (error) {
        console.error("Error exporting drawing:", error);
      }
    }

    const editedImage = canvas.toDataURL("image/jpeg");

    setImage((prevFiles) =>
      prevFiles.map((file) =>
        file.id === selectedImage.id ? { ...file, url: editedImage } : file
      )
    );
    // Cleanup
    setSelectedImage(null);
    setIsModalOpen(false);
    setCompletedCrop(null);
    setHighlights([]);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const companyResponse = await getSettingsCompanyDetails();
        setSettingCompanyDetails(companyResponse.data.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchSettings();
  }, []);

  const handleRemoveBgImageeForm = async (fileId) => {
    const selectedImage = images.find((file) => file.id === fileId);

    if (!selectedImage) {
      toast.error("Please select an image first.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedImage.file);

    setIsLoading(true);

    try {
      const response = await fetch(
        "https://imagemagic.dailybills.in/remove-bg/",
        {
          method: "POST",
          body: formData,
        }
      );

      let imageBlob;

      if (!response.ok) throw new Error("Failed to remove background");
      imageBlob = await response.blob();

      const imageUrl = URL.createObjectURL(imageBlob);
      setImage((prevFiles) =>
        prevFiles.map((file) =>
          file.id === fileId
            ? { ...file, url: imageUrl, file: imageBlob }
            : file
        )
      );

      toast.success("Background removed successfully!");
      setSelectedImage({ url: imageUrl });
      setIsModalOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddBgImageForm = async (fileId) => {
    const selectedImage = images.find((file) => file.id === fileId);
    const proxyUrl = "https://api.allorigins.win/raw?url=";

    const response = await fetch(
      proxyUrl + encodeURIComponent(settingCompanyDetails?.default_image)
    );
    if (!response.ok) throw new Error("Default image is not available");

    const blob = await response.blob();
    const file = new File([blob], "background.jpg", { type: blob.type });

    if (!selectedImage) {
      toast.error("Please select an image first.");
      return;
    }

    const formData = new FormData();
    formData.append("main_image", selectedImage?.file);
    formData.append("bg_image", file);

    setIsLoadings(true);

    try {
      const response = await fetch("https://imagemagic.dailybills.in/add-bg/", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) throw new Error("Failed to remove background");

      const imageBlob = await response.blob();
      const imageUrl = URL.createObjectURL(imageBlob);
      setImage((prevFiles) =>
        prevFiles.map((file) =>
          file.id === fileId
            ? { ...file, url: imageUrl, file: imageBlob }
            : file
        )
      );

      toast.success("Background add successfully!");
      setSelectedImage({ url: imageUrl });
      setIsModalOpen(false);
    } catch (error) {
    } finally {
      setIsLoadings(false);
    }
  };

  const handleAddBgColoreForm = async (fileId) => {
    const selectedImage = images.find((file) => file.id === fileId);

    if (!selectedImage) {
      toast.error("Please select an image first.");
      return;
    }

    const encodedColor = encodeURIComponent(
      settingCompanyDetails?.default_color
    );

    const formData = new FormData();
    formData.append("main_image", selectedImage.file);

    setIsLoadingColor(true);

    try {
      const response = await fetch(
        `https://imagemagic.dailybills.in/add-bg-color/?hex_color=${encodedColor}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) throw new Error("Failed to add background color");

      const imageBlob = await response.blob();
      const imageUrl = URL.createObjectURL(imageBlob);
      setImage((prevFiles) =>
        prevFiles.map((file) =>
          file.id === fileId
            ? { ...file, url: imageUrl, file: imageBlob }
            : file
        )
      );

      toast.success("Background color added successfully!");
      setSelectedImage({ url: imageUrl });
      setIsModalOpen(false);
    } catch (error) {
      toast.error(error.message || "Something went wrong");
    } finally {
      setIsLoadingColor(false);
    }
  };

  const handleRotate = async (fileId) => {
    setRotation((prev) => (prev + 90) % 360);
  };

  const handleOpenPartHistory = (e, item) => {
    setCatalogModalStateDataId(item);
    setModalStateHistory(true);
  };

  const handleClosePartHistory = () => {
    setModalStateHistory(false);
  };

  const handleOpenSponsorPrice = (e, item) => {
    setSponsorPriceDataId(item);
    setModalSponsorPrice(true);
  };

  const handleCloseSponsorPrice = () => {
    setModalSponsorPrice(false);
  };

  const handleOpenEstimatePrice = (e, item) => {
    setEstimatePriceDataId(item);
    setModalEstimatePrice(true);
  };

  const handleCloseEstimatePrice = () => {
    setModalEstimatePrice(false);
  };

  const handleOpenRefCodeCondition = (e, item) => {
    setRefCodeConditionDataId(item);
    setModalRefCodeCondition(true);
  };

  const handleCloseRefCodeCondition = () => {
    setModalRefCodeCondition(false);
  };

  const handleOpenRefCodeEstimate = (e, item) => {
    setRefCodeConditionDataId(item);
    setModalRefCodeEstimate(true);
  };

  const handleCloseRefCodeEstimate = () => {
    setModalRefCodeEstimate(false);
  };

  const handleCheckboxChangeMaster = (index, item) => {
    setSelectedItemsCheckBox((prevSelectedItems) => {
      if (prevSelectedItems.some((selected) => selected.index === index)) {
        return prevSelectedItems.filter((selected) => selected.index !== index);
      } else {
        return [...prevSelectedItems, { index, item }];
      }
    });
  };

  const handleImportModal = () => setModifyImportModal(true);
  const handleImportCloseModal = () => setModifyImportModal(false);

  const handleRatingChange = (newRating, partId) => {
    setPartsData((prevParts) =>
      prevParts.map((part) =>
        part.tempId === partId ? { ...part, part_rating: newRating } : part
      )
    );
  };

  const handleMasterPartClose = () => {
    setModifyMasterPart(false);
  };

  const handleRadioChange = (referenceCode) => {
    setSelectedReferenceCode(referenceCode);
  };

  const handleEtaiDetails = (event, etaiPartIds, index) => {
    event.preventDefault();
    setPartETAIRerList(etaiPartIds);
    setPartEtaiModify(true);
  };

  const handleEtaiPriceDetails = (event, etaiPartIds, index) => {
    event.preventDefault();
    setPartETAIPriceList(etaiPartIds);

    setPartEtaiPriceModify(true);
  };

  const handlePartEtaiClose = () => {
    setPartEtaiModify(false);
  };

  const handlePartEtaiPriceClose = () => {
    setPartEtaiPriceModify(false);
  };

  useEffect(() => {
    if (catalogModalStateDataId) {
      const data = {
        search: "",
        limit: 10,
        page: 1,
        part_catalog_id: catalogModalStateDataId?.id,
        vehicle_model_id: catalogModalStateDataId?.vehicle_model,
      };

      getCatalogModalStatsHistoryDetails(data)
        .then((response) => {
          setCatalogModalStateDataList(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [catalogModalStateDataId]);

  useEffect(() => {
    if (sponsorPriceDataId) {
      const data = {
        search: "",
        limit: 10,
        page: 1,
        part_catelog: sponsorPriceDataId?.id,
        condition_id: settingDetails?.condition_id,
        state_id: settingDetails?.state_id,
      };

      getPartStatiscalPriceDetails(data)
        .then((response) => {
          setSponsorPricDataList(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [sponsorPriceDataId]);

  useEffect(() => {
    profileDetailForWeb()
      .then((response) => {
        setProfileDetails(response.data.data);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  }, []);

  useEffect(() => {
    if (estimatePriceDataId) {
      const data = {
        regNumberCompany:
          profileDetails?.company_details?.company_registration_number,
        // regNumberCompany: "508040710",
        language: LangData,
        ktype: "",
        tecDocModel: "",
        engineCode: "",
        condition: settingDetails?.condition_name,
        state: settingDetails?.state_name,
        partMakeDescription: "",
        references: [],
      };

      getPartEstimatePriceDetails(data)
        .then((response) => {
          setEstimatePricDataList(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [estimatePriceDataId]);

  useEffect(() => {
    if (!refCodeConditionDataId?.addedData?.length) return;

    const refCodeConditionData = refCodeConditionDataId?.addedData?.map(
      (item) => ({
        ref_code: item?.reference_code,
        condition: item?.condition_id_ref,
      })
    );

    const requestData = {
      search: "",
      limit: 10,
      page: 1,
      refcode_condition: refCodeConditionData,
    };

    getPartRefcodeConditionDetails(requestData)
      .then((response) => setRefCodeConditionDataList(response.data.data))
      .catch((err) => toast.error(Utils.getErrorMessage(err)));
  }, [refCodeConditionDataId]);

  useEffect(() => {
    if (!refCodeConditionDataId?.addedData?.length) return;

    const refCodeConditionData = refCodeConditionDataId?.addedData?.map(
      (item) => ({
        ref_code: item?.reference_code,
        condition: item?.condition_id_ref,
      })
    );

    const requestData = {
      // regNumberCompany:
      //   profileDetails?.company_details?.company_registration_number,
      regNumberCompany: "508040710",
      language: LangData,
      ktype: "",
      tecDocModel: "",
      engineCode: "",
      condition: settingDetails?.condition_name,
      state: settingDetails?.state_name,
      partMakeDescription: "",
      refcode_condition: refCodeConditionData,
    };
    getPartEstimatePriceDetails(requestData)
      .then((response) => {
        setRefCodeEstimateDataList(response.data.data);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  }, [refCodeConditionDataId]);

  useEffect(() => {
    if (partETAIRerList) {
      const data = {
        etai_part_id: partETAIRerList,
      };

      partInvenoryPartETAI(data)
        .then((response) => {
          setPartETAIRefDetails(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [partETAIRerList]);

  useEffect(() => {
    if (partETAIPriceList) {
      const data = {
        etai_part_id: partETAIPriceList,
      };

      partInvenoryPartETAIPrice(data)
        .then((response) => {
          setPartETAIPriceDetail(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [partETAIPriceList]);

  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };

    getDismentalList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setDismetalList(list);
      })
      .catch((err) => {});
  }, []);

  const handleInputSelectedChangeDismetalList = (inputSelctedDismetalValue) => {
    setInputSelctedDismetalValue(inputSelctedDismetalValue);

    if (inputSelctedDismetalValue.length > 0) {
      const obj = {
        search: inputSelctedDismetalValue,
        limit: tableLengthList[0],
        page: 1,
      };

      getDismentalList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }
          setDismetalList(list);
        })
        .catch((err) => {});
    }
  };

  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };

    getCatalogList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].namePartPT,
            is_master_part: response.data[i].is_master_part,
            part_list: response.data[i].part_list,
          });
        }
        setCategoryPartList(list);
      })
      .catch((err) => {});
  }, []);

  const handleInputSelectedChangeCategoryList = (inputSelctedValue) => {
    setInputSelctedValue(inputSelctedValue);

    if (inputSelctedValue.length > 0) {
      const obj = {
        search: inputSelctedValue,
        limit: tableLengthList[0],
        page: 1,
      };

      getCatalogList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].namePartPT,
              is_master_part: response.data[i].is_master_part,
              part_list: response.data[i].part_list,
            });
          }
          setCategoryPartList(list);
        })
        .catch((err) => {});
    }
  };

  const handleSelectWithoutChangeCategoryList = (option) => {
    setCategoryPartsData((prevData) => ({
      ...prevData,
      catalog_id: option.value,
    }));

    setCategoryMasterPart(option);
  };

  const handleSelectWithoutChangeDismetalList = (option) => {
    setDismentalData((prevData) => ({
      ...prevData,
      dismantle_id: option.value,
    }));
  };

  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };

    getvehicleYearList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i],
            label: response.data[i],
          });
        }
        setvehicleYearPartList(list);
      })
      .catch((err) => {});
  }, []);

  const handleInputSelectedChangeYearList = (inputSelctedYearValue) => {
    setInputSelctedYearValue(inputSelctedYearValue);

    const obj = {
      search: inputSelctedYearValue,
      limit: tableLengthList[0],
      page: 1,
    };

    getvehicleYearList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i],
            label: response.data[i],
          });
        }
        setvehicleYearPartList(list);
      })
      .catch((err) => {});
  };

  const handleSelectWithoutChangeYearList = (option) => {
    setCategoryPartsData((prevData) => ({
      ...prevData,
      vehicle_year: option.value,
      vehicle_make: "",
      vehicle_model: "",
      vehicle_variant: "",
    }));
    setvehicleMakePartList([]);
    setvehicleModalPartList([]);
    setvehicleVariantPartList([]);
  };

  useEffect(
    function () {
      if (categoryPartsData?.vehicle_year) {
        var obj = {
          search: "",
          limit: tableLengthList[0],
          page: 1,
          year: categoryPartsData?.vehicle_year,
        };

        getvehicleMakeAllList(obj)
          .then((response) => {
            response = response.data;
            let list = [];
            for (let i = 0; i < response.data.length; i++) {
              list.push({
                value: response.data[i].id,
                label: response.data[i].description,
              });
            }
            setvehicleMakePartList(list);
          })
          .catch((err) => {});
      }
    },

    [categoryPartsData?.vehicle_year]
  );

  const handleInputSelectedChangeMakeList = (inputSelctedMakeValue) => {
    setInputSelctedMakeValue(inputSelctedMakeValue);

    if (inputSelctedMakeValue.length > 0) {
      const obj = {
        search: inputSelctedMakeValue,
        limit: tableLengthList[0],
        page: 1,
        year: categoryPartsData?.vehicle_year,
      };

      getvehicleMakeAllList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].description,
            });
          }
          setvehicleMakePartList(list);
        })
        .catch((err) => {});
    }
  };

  const handleSelectWithoutChangeMakeList = (option) => {
    setCategoryPartsData((prevData) => ({
      ...prevData,
      vehicle_make: option.value,
      vehicle_make_name: option.label,
      vehicle_model: "",
      vehicle_variant: "",
    }));

    setvehicleModalPartList([]);
    setvehicleVariantPartList([]);
  };

  useEffect(
    function () {
      if (categoryPartsData?.vehicle_make) {
        var obj = {
          search: "",
          limit: tableLengthList[0],
          page: 1,
          vehicle_year: categoryPartsData?.vehicle_year,
          make_id: categoryPartsData?.vehicle_make,
        };

        getVehicleAllModalSerachList(obj)
          .then((response) => {
            response = response.data;
            let list = [];
            for (let i = 0; i < response.data.length; i++) {
              list.push({
                value: response.data[i].id,
                label: response.data[i].description,
              });
            }
            setvehicleModalPartList(list);
          })
          .catch((err) => {});
      }
    },
    [categoryPartsData?.vehicle_make]
  );

  const handleInputSelectedChangeModelList = (inputSelctedModalValue) => {
    setInputSelctedModalValue(inputSelctedModalValue);

    if (inputSelctedModalValue.length > 0) {
      const obj = {
        search: inputSelctedModalValue,
        limit: tableLengthList[0],
        page: 1,
        vehicle_year: categoryPartsData?.vehicle_year,
        make_id: categoryPartsData?.vehicle_make,
      };

      getVehicleAllModalSerachList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].model_name,
            });
          }
          setvehicleModalPartList(list);
        })
        .catch((err) => {});
    }
  };

  const handleSelectWithoutChangeModelList = (option) => {
    setCategoryPartsData((prevData) => ({
      ...prevData,
      vehicle_model: option.value,
      vehicle_model_name: option.label,
      vehicle_variant: "",
    }));
    setvehicleVariantPartList([]);
  };

  useEffect(
    function () {
      if (categoryPartsData?.vehicle_model) {
        var obj = {
          search: "",
          limit: tableLengthList[0],
          page: 1,
          vehicle_year: categoryPartsData?.vehicle_year,
          model_id: categoryPartsData?.vehicle_model,
        };

        getVehicleSerachVariantList(obj)
          .then((response) => {
            response = response.data;
            let list = [];
            for (let i = 0; i < response.data.length; i++) {
              list.push({
                value: response.data[i].id,
                label: response.data[i].variants_name,
              });
            }
            setvehicleVariantPartList(list);
          })
          .catch((err) => {});
      }
    },
    [categoryPartsData?.vehicle_model]
  );

  const handleInputSelectedChangeVariantList = (inputSelctedVariantValue) => {
    setInputSelctedVariantValue(inputSelctedVariantValue);

    if (inputSelctedVariantValue.length > 0) {
      const obj = {
        serach: inputSelctedVariantValue,
        limit: tableLengthList[0],
        page: 1,
        vehicle_year: categoryPartsData?.vehicle_year,
        model_id: categoryPartsData?.vehicle_model,
      };

      getVehicleSerachVariantList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].variants_name,
            });
          }
          setvehicleVariantPartList(list);
        })
        .catch((err) => {});
    }
  };

  const handleSelectWithoutChangeVariantList = (option) => {
    setCategoryPartsData((prevData) => ({
      ...prevData,
      vehicle_variant: option.value,
      vehicle_variant_name: option.label,
    }));
  };

  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };

    getVehicleEngineSerachList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].motor_code,
            kw: response.data[i].kw,
            hp: response.data[i].hp,
          });
        }
        setvehicleEngineList(list);
      })
      .catch((err) => {});
  }, []);

  const handleInputSelectedChangeEngineList = (inputSelctedEngineValue) => {
    setInputSelctedEngineValue(inputSelctedEngineValue);

    if (inputSelctedEngineValue.length > 0) {
      const obj = {
        serach: inputSelctedEngineValue,
        limit: tableLengthList[0],
        page: 1,
      };

      getVehicleEngineSerachList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].motor_code,
              kw: response.data[i].kw,
              hp: response.data[i].hp,
            });
          }
          setvehicleEngineList(list);
        })
        .catch((err) => {});
    }
  };

  const handleSelectWithoutChangeEngineList = (option) => {
    setEngineData((prevData) => ({
      ...prevData,
      engine_code: option.value,
      kw: option.kw,
      hp: option.hp,
    }));
  };

  const handleChangeModel = (e) => {
    const userInput = e.target.value;

    let data = {
      limit: params.limit,
      page: params.page,
      search: userInput,
      is_reload: true,
      make_id: vehicleMakeId ? vehicleMakeId : 0,
    };
    if (userInput.length > 0 && vehicleMakeId) {
      getVehicleAllModalSerachList(data)
        .then((response) => {
          setFilteredSuggestionsModel(response.data.data);
        })
        .catch((err) => {});
    }
    if (currentStep === 2) {
      let data = {
        limit: params.limit,
        page: params.page,
        search: userInput,
        is_reload: true,
        make_id: 0,
      };
      getVehicleAllModalSerachList(data)
        .then((response) => {
          setFilteredSuggestionsModel(response.data.data);
        })
        .catch((err) => {});
    }

    setVehicleModelAuto(userInput);
    setActiveSuggestionIndexModel(0);
    setShowSuggestionsModel(true);
  };

  const handleClickModel = (suggestion, pIndex) => {
    setVehicleModelId(suggestion?.id);
    setVehicleModelAuto(suggestion.model_name);
    setFilteredSuggestionsModel([]);
    setShowSuggestionsModel(false);

    if (currentStep === 2) {
      setPartsData((prevParts) =>
        prevParts.map((part, index) => {
          if (index === pIndex) {
            const isAlreadySelected = part.parts_associate.some(
              (model) => model.vehicle_model === suggestion.id
            );

            let updatedAssociates = [...part.parts_associate];

            if (isAlreadySelected) {
              updatedAssociates = updatedAssociates.filter(
                (model) => model.vehicle_model !== suggestion.id
              );
            } else {
              const newModel = {
                vehicle_make: suggestion.make_id,
                vehicle_year: suggestion.initial_year,
                vehicle_model: suggestion.id,
                model_name: suggestion.model_name,
              };
              updatedAssociates.push(newModel);
            }

            return { ...part, parts_associate: updatedAssociates };
          }
          return part;
        })
      );

      setVehicleModelAuto("");
      setFilteredSuggestionsModel([]);
      setShowSuggestionsModel(false);
    }
  };

  const handleKeyDownModel = (e) => {
    if (e.key === "ArrowDown") {
      if (
        filteredSuggestionsModel &&
        activeSuggestionIndexModel < filteredSuggestionsModel?.length - 1
      ) {
        setActiveSuggestionIndexModel(activeSuggestionIndexModel + 1);
      }
    } else if (e.key === "ArrowUp") {
      if (activeSuggestionIndexModel > 0) {
        setActiveSuggestionIndexModel(activeSuggestionIndexModel - 1);
      }
    } else if (e.key === "Enter") {
      setVehicleModelAuto(filteredSuggestionsModel[activeSuggestionIndexModel]);
      setShowSuggestionsModel(false);
      setFilteredSuggestionsModel([]);
    }
  };

  const handleSkip = () => {
    setCurrentPartIndex((prevIndex) => prevIndex + 1);
    setShowSkip(false);
  };

  const handleOpenSkip = () => {
    setShowSkip(true);
  };

  const handleCloseSkip = () => {
    setShowSkip(false);
  };

  const handleFinish = (event) => {
    event.preventDefault();

    const formData = new FormData();
    setBtnSaved(true);
    formData.append("part_id", currentPart?.id);
    images.forEach((item) => {
      if (item.file instanceof File) {
        formData.append("files", item.file);
      } else {
        console.error("Invalid file object:", item.file);
      }
    });

    actionPartInventoryImage(formData)
      .then((response) => {
        toast.success(response.data.message);
        setPartImageDataList(response.data.data);
        if (partsData?.some((part) => part.parts_damage.length > 0)) {
          setCurrentStep(4);
        } else {
          setCurrentStep(5);
        }

        setImage([]);
        SetPreviewUrl([]);
      })
      .catch((err) => {
        setBtnSaved(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const currentPart = detailsForImages[currentPartIndex];
  const fileInputRef = useRef(null);

  const handleSubmits = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("part_id", currentPart?.id);

    images.forEach((item) => {
      if (item.file instanceof File) {
        formData.append("files", item.file);
      } else {
        console.error("Invalid file object:", item.file);
      }
    });
    actionPartInventoryImage(formData)
      .then((response) => {
        toast.success(response.data.message);
        setImage([]);
        SetPreviewUrl([]);
        setCurrentPartIndex((prevIndex) => prevIndex + 1);
        if (images.length === 1) {
          fileInputRef.current.value = "";
        }
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleAboutFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);

    if (selectedFiles.length > 0) {
      const formattedFiles = selectedFiles.map((file, index) => ({
        id: `${Date.now()}-${index}`,
        file,
        url: URL.createObjectURL(file),
      }));

      setImage((prevImages) => [...prevImages, ...formattedFiles]);

      const newPreviewUrls = formattedFiles.map((file) => file.url);
      SetPreviewUrl((prevUrls) => [...prevUrls, ...newPreviewUrls]);
    }
  };

  const isLastPart = currentPartIndex === detailsForImages?.length - 1;

  const handleRemoveFile = (indexToRemove) => {
    setImage((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
    SetPreviewUrl((prevUrls) =>
      prevUrls.filter((_, index) => index !== indexToRemove)
    );

    if (images.length === 1) {
      fileInputRef.current.value = "";
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedFiles = Array.from(images);
    const [movedFile] = reorderedFiles.splice(result.source.index, 1);
    reorderedFiles.splice(result.destination.index, 0, movedFile);

    setImage(reorderedFiles); // Update state with new order
  };

  const handleSaveOrder = () => {
    const simplifiedFiles = images.map((file, index) => ({
      id: 0,
      sort_order: index + 1,
    }));

    const data = {
      sort_list: simplifiedFiles,
    };

    updateVehiclePartImageSort(data)
      .then((response) => {
        toast.success(response.data.message);
        setModifyImportModal(false);
      })
      .catch((err) => toast.error(Utils.getErrorMessage(err)));
  };

  useEffect(() => {
    const obj = {
      search: "",
      limit: 10,
      page: 1,
      placeName: "",
    };

    getAllBrandSerachList(obj)
      .then((response) => {
        response = response.data;
        let list = [];

        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].descriptionPT,
          });
        }
        SetAllBrandList(list);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleShowAbout = () => setShowModalAbout(true);

  useEffect(() => {
    const obj = {
      search: "",
      limit: 10,
      page: 1,
      placeName: "",
    };

    getAllViewShelfList(obj)
      .then((response) => {
        response = response.data;
        let list = [];

        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
            bar_code: response.data[i].bar_code,
            place: response.data[i].place,
            from_iels: response.data[i].from_iels,
            to_iels: response.data[i].to_iels,
          });
        }
        SetShelfList(list);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    const isDuplicatePart = (part, newPart) => {
      return (
        part.vehicle_make === newPart.vehicle_make &&
        part.vehicle_model === newPart.vehicle_model &&
        part.vehicle_year === newPart.vehicle_year
      );
    };

    if (vehicleAddDetails?.id) {
      const newPart = {
        catalog_id: vehicleAddDetails.catalog_id,
        namePartPT: vehicleAddDetails.namePartPT,
        part_name: vehicleAddDetails?.part_name,
        quantity: vehicleAddDetails?.quantity,
        part_price: vehicleAddDetails?.part_price,
        inventory_status: vehicleAddDetails?.inventory_status,
        parts_associate: vehicleAddDetails?.parts_associate,
        part_description: vehicleAddDetails?.part_description,
        vehicle_make: vehicleAddDetails.vehicle_make,
        vehicle_model: vehicleAddDetails.vehicle_model,
        vehicle_model_id: vehicleAddDetails.vehicle_parts_id,
        vehicle_year: vehicleAddDetails.vehicle_year,
        print_label: vehicleAddDetails?.print_label,
        state_id: vehicleAddDetails?.state_id,
        condition_id: vehicleAddDetails?.condition_id,
        part_original_price: vehicleAddDetails?.part_original_price,
        part_cost_price: vehicleAddDetails?.part_cost_price,
        is_custom_size: vehicleAddDetails?.is_custom_size,
        parts_weight: vehicleAddDetails?.parts_weight,
        parts_width: vehicleAddDetails?.parts_width,
        parts_height: vehicleAddDetails?.parts_height,
        parts_length: vehicleAddDetails?.parts_length,
        brand_id: vehicleAddDetails?.brand_id,
        vat_included: vehicleAddDetails?.vat_included,
        is_master_part: vehicleAddDetails?.is_master_part,
        master_part_id: vehicleAddDetails?.master_part_id,
        part_rating: vehicleAddDetails?.part_rating,
        associate_location: vehicleAddDetails?.associate_location,
      };

      setPartsData((prevData) => {
        if (!prevData.some((part) => isDuplicatePart(part, newPart))) {
          return [...prevData, newPart];
        }
        return prevData;
      });
    } else if (selectedItems && selectedItems.length > 0) {
      const selectedParts = selectedItems.map((item) => ({
        tempId: item.id,
        temp_id: item.temp_id,
        id: item.catalog_id,
        part_model_stats: item?.part_model_stats,
        is_master_part: item.is_master_part,
        master_part_id: item.master_part_id,
        parts_internal_id: item.parts_internal_id,
        etai_part_id: item?.etai_part_id,
        namePartPT: item.namePartPT,
        part_name: item.namePartPT,
        quantity: "1",
        part_price: "0",

        state_id: settingDetails?.state_id ? settingDetails?.state_id : "",
        condition_id: settingDetails?.condition_id
          ? settingDetails?.condition_id
          : "",
        part_original_price: "0",
        part_cost_price: "0",
        is_custom_size: "0",
        parts_weight: "0",
        parts_width: "0",
        parts_height: "0",
        parts_length: "0",
        inventory_status: "",
        print_label: settingDetails?.print_label
          ? settingDetails?.print_label
          : "0",
        vat_included: settingDetails?.vat_included
          ? settingDetails?.vat_included
          : "0",
        sponsor_price: "",
        part_description: "",
        parts_associate: [],
        parts_damage: [],
        images: [],
        vehicle_make: item.vehicle_make,
        vehicle_model: item.vehicle_model,
        vehicle_model_id: item.vehicle_model_id,
        vehicle_year: item.vehicle_year,
        part_qty_location_assign: [],
        brand_id: "",
        reference_code: "",
        type_id: null,
        is_main: 1,
        condition_id_ref: "",
        addedData: [],
        vehicle_model_name: item?.vehicle_model_name,
        vehicle_make_name: item?.vehicle_make_name,
        is_show_engine_record: item?.is_show_engine_record,

        engine_code: "",
        variants_id: item?.variants_id,
        cv: "",
        cc: "",
        kw: "",
        reg_number_dismantler: settingDetails?.reg_number_dismantler
          ? settingDetails?.reg_number_dismantler
          : "0",
        part_rating: "0",
        associate_location: "0",
      }));

      setPartsData(selectedParts);
    }
  }, [vehicleAddDetails, selectedItems]);

  const handleInputChangePartListRef = (e, pIndex) => {
    const { name, value } = e.target;
    setPartsData((prev) =>
      prev.map((part, index) =>
        index === pIndex ? { ...part, [name]: value } : part
      )
    );
  };

  const handleAddPartRef = (pIndex) => {
    setPartsData((prev) =>
      prev?.map((part, index) => {
        if (index === pIndex) {
          const referenceCode = selectedReferenceCode || part?.reference_code;

          if (!referenceCode) {
            toast.error("Reference Code is required!");
            return part;
          }

          const isDuplicate = part?.addedData?.some(
            (data) => data?.reference_code === referenceCode
          );
          if (isDuplicate) {
            toast.error("Reference Code already added!");
            return part;
          }

          const selectedCondition = conditionList.find(
            (cond) => cond?.value === part?.condition_id_ref
          ) || { label: "Not Selected", value: "null" };

          const selectedType = referenceList.find(
            (cond) => cond?.value === part?.type_id
          ) || {
            label: "Not Selected",
            value: "null",
          };

          return {
            ...part,
            addedData: [
              ...(part?.addedData || []),
              {
                reference_code: referenceCode,
                type_id: part?.type_id,
                is_main: part?.is_main,
                condition_id_ref: part?.condition_id_ref,
                selectedCondition,
                selectedType,
              },
            ],
            reference_code: "",
            type_id: null,
            condition_id_ref: null,
            is_main: 0,
          };
        }
        return part;
      })
    );
    setSelectedReferenceCode(null);
  };

  const handleUpdatePartRef = (pIndex, rowIndex, key, value) => {
    setPartsData((prev) =>
      prev.map((part, index) => {
        if (index !== pIndex) return part;

        const updatedAddedData = part?.addedData?.map((data, idx) => {
          if (key === "is_main") {
            return { ...data, is_main: idx === rowIndex ? value : 0 };
          }

          if (idx !== rowIndex) return data;
          return { ...data, [key]: value };
        });

        return {
          ...part,
          addedData: updatedAddedData || [],
        };
      })
    );
  };

  const handleDeletePartRef = (pIndex, rowIndex) => {
    setPartsData((prev) =>
      prev.map((part, index) =>
        index === pIndex
          ? {
              ...part,
              addedData:
                part.addedData?.filter((_, idx) => idx !== rowIndex) || [],
            }
          : part
      )
    );
  };

  const handleAddPartDamage = (pIndex) => {
    setPartsData((prev) =>
      prev?.map((part, index) => {
        if (index === pIndex) {
          const damageDescription =
            selectedReferenceCode || part?.damage_description;

          if (!damageDescription) {
            toast.error("Damage Description is required!");
            return part;
          }

          const selectedTypeDamage = typeDamageList.find(
            (cond) => cond?.value === part?.type_damage_id
          ) || { label: "Not Selected", value: "null" };

          const selectedLocationDamage = locationDamageList.find(
            (cond) => cond?.value === part?.location_damage_id
          ) || {
            label: "Not Selected",
            value: "null",
          };

          const updatedPart = {
            ...part,
            parts_damage: [
              ...(part?.parts_damage || []),
              {
                damage_description: part?.damage_description,
                type_damage_id: part?.type_damage_id,
                location_damage_id: part?.location_damage_id,
                damage_rating: part?.damage_rating,
                selectedTypeDamage,
                selectedLocationDamage,
              },
            ],
            damage_description: "",
            type_damage_id: null,
            location_damage_id: null,
            damage_rating: 0, // Reset damage rating
          };

          return updatedPart;
        }
        return part;
      })
    );
  };

  const handleUpdatePartDamage = (pIndex, rowIndex, key, value) => {
    setPartsData((prev) =>
      prev.map((part, index) => {
        if (index !== pIndex) return part;

        const updatedAddedData = part?.parts_damage?.map((data, idx) => {
          if (idx !== rowIndex) return data;
          return { ...data, [key]: value };
        });

        return {
          ...part,
          parts_damage: updatedAddedData || [],
        };
      })
    );
  };

  const handleDeletePartDamage = (pIndex, rowIndex) => {
    setPartsData((prev) =>
      prev.map((part, index) =>
        index === pIndex
          ? {
              ...part,
              parts_damage:
                part.parts_damage?.filter((_, idx) => idx !== rowIndex) || [],
            }
          : part
      )
    );
  };

  const handleConditionChangeRef = (pIndex, selectedOption) => {
    setPartsData((prev) =>
      prev.map((part, index) =>
        index === pIndex
          ? { ...part, type_id: selectedOption?.value || null }
          : part
      )
    );
  };

  const handleConditionSChangeRefs = (pIndex, selectedOption) => {
    setPartsData((prev) =>
      prev.map((part, index) =>
        index === pIndex
          ? { ...part, condition_id_ref: selectedOption?.value || null }
          : part
      )
    );
  };

  const handleTypeDamageChange = (pIndex, selectedOption) => {
    setPartsData((prev) =>
      prev.map((part, index) =>
        index === pIndex
          ? { ...part, type_damage_id: selectedOption?.value || null }
          : part
      )
    );
  };

  const hanldeLocationDamageChange = (pIndex, selectedOption) => {
    setPartsData((prev) =>
      prev.map((part, index) =>
        index === pIndex
          ? { ...part, location_damage_id: selectedOption?.value || null }
          : part
      )
    );
  };

  const handleInputChangePartList = (e, id) => {
    const { name, value, type, checked } = e.target;
    setPartsData((prevData) =>
      prevData.map((part) =>
        part.tempId === id
          ? { ...part, [name]: type === "checkbox" ? (checked ? 1 : 0) : value }
          : part
      )
    );
  };
  const handleAddClick = (e) => {
    e.preventDefault();

    if (categoryMasterPart?.is_master_part === "1") {
      setModifyMasterPart(true);
      return;
    }
    if (modifyMasterPart === true) {
      submitData();
    }
    if (categoryMasterPart?.is_master_part === "0") {
      const data = {
        temp_id: tempData?.temp_id || dismantleTempData?.temp_id || "",
        vehicle_id: 0,
        part_list: [
          {
            vehicle_make: categoryPartsData?.vehicle_make || "",
            vehicle_year: categoryPartsData?.vehicle_year || "",
            vehicle_model: categoryPartsData?.vehicle_model || "",
            catalog_id: categoryPartsData?.catalog_id || "",
            vehicle_variant: categoryPartsData?.vehicle_variant || "",
            master_part_id: 0,
          },
        ],
      };

      actionPartInventoryTempStore(data)
        .then((response) => {
          setTempData(response.data.data);
          resetFormState();
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  const submitData = () => {
    const selectedData = selectedItemsCheckBox?.map(({ item, index }) => ({
      catalog_id: item?.reference_id || "",
      vehicle_make: categoryPartsData?.vehicle_make || "",
      vehicle_year: categoryPartsData?.vehicle_year || "",
      vehicle_model: categoryPartsData?.vehicle_model || "",
      vehicle_variant: categoryPartsData?.vehicle_variant || "",
      master_part_id: item?.parts_catalog_id || 0,
    }));

    const data = {
      temp_id: tempData?.temp_id || dismantleTempData?.temp_id || "",
      vehicle_id: 0,
      part_list: [
        ...(selectedData?.length > 0 ? selectedData : []),
        ...(categoryMasterPart?.is_master_part === "1"
          ? [
              {
                vehicle_make: categoryPartsData?.vehicle_make || "",
                vehicle_year: categoryPartsData?.vehicle_year || "",
                vehicle_model: categoryPartsData?.vehicle_model || "",
                catalog_id: categoryPartsData?.catalog_id || "",
                vehicle_variant: categoryPartsData?.vehicle_variant || "",
                master_part_id: 0,
              },
            ]
          : []),
      ],
    };

    actionPartInventoryTempStore(data)
      .then((response) => {
        setTempData(response.data.data);
        setModifyMasterPart(false);
        resetFormState();
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleModalSubmit = (e) => {
    e.preventDefault();
    submitData();
  };

  const resetFormState = () => {
    setCategoryPartsData({
      catalog_id: "",
      vehicle_make: "",
      vehicle_year: "",
      vehicle_model: "",
      part_name: "",
      vehicle_variant: "",
    });

    setVehicleModelId("");
    setVehicleModelAuto("");
    setCatalogMake("");
    setVehicleMakeId("");
    setCatalogPart("");
    setVehiclePartId("");
  };

  const handleTempDismetalAddClick = (e) => {
    e.preventDefault();

    let data = {
      dismantle_id: dismentalData?.dismantle_id,
      temp_id: dismantleTempData?.temp_id || tempData?.temp_id || "",
      vehicle_id: 0,
    };

    actionTempStoreDismental(data)
      .then((response) => {
        setDismantleTempData(response.data.data);

        setDismentalData({
          dismantle_id: "",
        });

        setInputSelctedDismetalValue("");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleDeleteClick = (item) => {
    setBtnClosed(true);
    let data = {
      id: item.tempId ? item.tempId : item?.id,
    };

    setTempData(item);

    DestoryPartInventoryTemp(data)
      .then((response) => {
        toast.success(response.data.message);

        const fetchData = async () => {
          try {
            const obj = {
              search: searchQueryTemp,
              limit: tableLengthList[0],
              page: 1,
              temp_id: item?.temp_id,
            };
            const response = await getPartInventoryTempList(obj);
            setSelectedItems(response.data.data);
          } catch (err) {
            console.error("Error fetching part inventory:", err);
          }
        };

        fetchData();
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      })
      .finally(() => {
        setBtnClosed(false);
      });
  };

  const handleCheckboxPrintChanges = (pIndex, event) => {
    const updatedParts = [...partsData];
    const partToUpdate = updatedParts[pIndex];

    partToUpdate.print_label = event.target.checked ? 1 : 0;

    setPartsData(updatedParts);
  };

  const handleCheckboxAssociateLocationChanges = (pIndex, event) => {
    const updatedParts = [...partsData];
    const partToUpdate = updatedParts[pIndex];

    partToUpdate.associate_location = event.target.checked ? 1 : 0;

    setPartsData(updatedParts);
  };

  const handleCheckboxVatChanges = (pIndex, event) => {
    const updatedParts = [...partsData];
    const partToUpdate = updatedParts[pIndex];

    partToUpdate.vat_included = event.target.checked ? 1 : 0;

    setPartsData(updatedParts);
  };

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const companyResponse = await getSettingsCompanyDetails();

        setSettingDetails(companyResponse.data.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchSettings();
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "state",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setStateList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "type_damage",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setTypeDamageList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "location_damage",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setLocationDamageList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "reference",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setReferenceList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "part_inventory",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setInventoryList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "condition",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setConditionList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (selectedItems?.length === 0) {
      setCurrentStep(1);
    }
  }, [selectedItems]);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = "";
    } else if (type === "pagination") {
      paramsObj.page = e;
    }

    setParams(paramsObj);
  };

  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };
    getVehicleList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: `${response.data[i].vehicle_make}/ ${response.data[i].vehicle_model} /${response.data[i].vehicle_year}`,
            vehicle_year: response.data[i].vehicle_year,
            vehicle_make: response.data[i].vehicle_make,
            vehicle_model: response.data[i].vehicle_model,
          });
        }
        setVehicleModelList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    var obj = {
      text: "",
      limit: tableLengthList[0],
      page: 1,
    };

    getVehicleModelAllList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].vehicle_model,
            vehicle_make: response.data[i].vehicle_make,
            vehicle_year: response.data[i].vehicle_end_year,
            vehicle_model: response.data[i].vehicle_model,
          });
        }
        setVehicleModelAllList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    const obj = {
      text: "",
      limit: tableLengthList[0],
      page: 1,
    };

    getCatalogList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        const LangData = localStorage.getItem("lng") || "en";

        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label:
              LangData === "pt"
                ? response.data[i].namePartPT
                : LangData === "fr"
                ? response.data[i].namePartFR
                : LangData === "es"
                ? response.data[i].namePartES
                : LangData === "it"
                ? response.data[i].namePartIT
                : LangData === "de"
                ? response.data[i].namePartDE
                : response.data[i].namePartEN,

            is_master_part: response.data[i].is_master_part,
            part_list: response.data[i].part_list,
          });
        }
        setCategoryPartList(list);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleConditionChange = (index, selectedOption) => {
    const updatedParts = [...partsData];

    updatedParts[index] = {
      ...updatedParts[index],
      condition_id: selectedOption.value,
    };

    setPartsData(updatedParts);
  };

  const handleStateListChange = (index, selectedOption) => {
    const updatedParts = [...partsData];

    updatedParts[index] = {
      ...updatedParts[index],
      state_id: selectedOption.value,
    };

    setPartsData(updatedParts);
  };

  const handleInventoryIdChange = (index, selectedOption) => {
    const updatedParts = [...partsData];

    updatedParts[index] = {
      ...updatedParts[index],
      inventory_status: selectedOption.value,
    };

    setPartsData(updatedParts);
  };

  const handleBrandIdChange = (index, selectedOption) => {
    const updatedParts = [...partsData];

    updatedParts[index] = {
      ...updatedParts[index],
      brand_id: selectedOption.value,
    };

    setPartsData(updatedParts);
  };

  useEffect(
    function () {
      var obj = {
        search: searchQuery,
        limit: tableLengthList[0],
        page: 1,
      };
      getCatalogList(obj)
        .then((response) => {
          response = response.data;
          setCatalogList(response);
        })
        .catch((err) => {});
    },
    [searchQuery]
  );

  useEffect(() => {
    if (tempData?.temp_id || dismantleTempData?.temp_id) {
      const fetchData = async () => {
        try {
          const tempIdToSend = tempData?.temp_id || dismantleTempData?.temp_id;

          const obj = {
            search: searchQueryTemp,
            limit: tableLengthList[0],
            page: 1,
            temp_id: tempIdToSend,
          };

          const response = await getPartInventoryTempList(obj);
          setSelectedItems(response.data.data);
          setTotalRecords(response.data.total);
        } catch (err) {
          console.error("Error fetching part inventory:", err);
        }
      };

      fetchData();
    }
  }, [searchQueryTemp, tempData, dismantleTempData]);

  useEffect(() => {
    if (selectedRowsEdit) {
      const fetchData = async () => {
        try {
          const tempIdToSend = selectedRowsEdit;

          const obj = {
            search: searchQueryTemp,
            limit: tableLengthList[0],
            page: 1,
            temp_id: tempIdToSend,
          };

          const response = await getPartInventoryTempList(obj);
          setSelectedItems(response.data.data);
          setTotalRecords(response.data.total);
          setCurrentStep(2);
        } catch (err) {
          console.error("Error fetching part inventory:", err);
        }
      };

      fetchData();
    }
  }, [searchQueryTemp, selectedRowsEdit]);

  const createPayload = () => {
    return partsData.map((part) => ({
      vehicle_id: vehiclePartsData?.vehicle_model_id || 0,
      part_catelog: part.id || "",
      part_name: part?.part_name,
      quantity: part?.quantity,
      part_price: part?.part_price,
      condition_id: part?.condition_id,
      part_original_price: part?.part_original_price,
      state_id: part?.state_id,
      is_custom_size: part?.is_custom_size,
      parts_weight: part?.parts_weight,
      parts_width: part?.parts_width,
      parts_height: part?.parts_height,
      parts_length: part?.parts_length,
      print_label: part?.print_label,
      associate_location: part?.associate_location,
      vat_included: part?.vat_included,
      inventory_status: part.inventory_status ? part.inventory_status : 1,
      vehicle_make: part?.vehicle_make,
      vehicle_year: part?.vehicle_year,
      vehicle_model: part?.vehicle_model,
      sponsor_price: part?.sponsor_price,
      part_description: part?.part_description,
      brand_id: part?.brand_id,
      part_cost_price: part?.part_cost_price,
      parts_associate: part?.parts_associate?.map((model) => ({
        vehicle_make: model.vehicle_make,
        vehicle_year: model.vehicle_year,
        vehicle_model: model.vehicle_model,
      })),

      parts_reference: part?.addedData?.map((item) => ({
        reference_code: item?.reference_code,
        is_main: item?.is_main,
        type_id: item?.type_id,
        condition_id: item?.condition_id_ref,
      })),

      engine_code: part?.engine_code,
      variants_id: part?.variants_id,
      cv: part?.cv,
      cc: part?.cc,
      kw: part?.kw,
      reg_number_dismantler: part?.reg_number_dismantler,
      is_master_part: part?.is_master_part,
      master_part_id: part?.master_part_id,
      master_part_inventory_id: null,
      part_rating: part?.part_rating,

      parts_damage: part?.parts_damage?.map((item) => ({
        damage_description: item?.damage_description,
        damage_rating: item?.damage_rating,
        location_damage_id: item?.location_damage_id,
        type_damage_id: item?.type_damage_id,
      })),
    }));
  };

  const createPayloads = () => {
    const part = partsData[0];

    return {
      uuid: vehicleAddDetails?.uuid,
      id: vehicleAddDetails?.id,
      vehicle_id: part?.vehicle_model_id ? part?.vehicle_model_id : 0,
      part_catelog: part.id || vehicleAddDetails?.part_catelog,
      part_name: part?.part_name,
      quantity: part?.quantity,
      part_price: part?.part_price,
      shelf_id: part?.shelf_id,
      condition_id: part?.condition_id,
      part_original_price: part?.part_original_price,
      state_id: part?.state_id,
      is_custom_size: part?.is_custom_size,
      parts_weight: part?.parts_weight,
      parts_width: part?.parts_width,
      parts_height: part?.parts_height,
      parts_length: part?.parts_length,
      print_label: part?.print_label,
      associate_location: part?.associate_location,

      vat_included: part?.vat_included,
      inventory_status: part.inventory_status,
      vehicle_make: part?.vehicle_make,
      vehicle_year: part?.vehicle_year,
      vehicle_model: part?.vehicle_model,
      part_description: part?.part_description,
      sponsor_price: part?.sponsor_price,
      brand_id: part?.brand_id,
      part_cost_price: part?.part_cost_price,
      parts_associate:
        part?.parts_associate?.map((model) => ({
          uuid: model?.uuid,
          vehicle_make: model.vehicle_make,
          vehicle_year: model.vehicle_year,
          vehicle_model: model.vehicle_model,
        })) || [],

      parts_reference: part?.addedData?.map((item) => ({
        reference_code: item?.reference_code,
        is_main: item?.is_main,
        type_id: item?.type_id,
        condition_id: part?.condition_id_ref,
      })),

      engine_code: part?.engine_code,
      variants_id: part?.variants_id,
      cv: part?.cv,
      cc: part?.cc,
      kw: part?.kw,
      reg_number_dismantler: part?.reg_number_dismantler,

      is_master_part: part?.is_master_part,
      master_part_id: part?.master_part_id,
      master_part_inventory_id: null,
      part_rating: part?.part_rating,
      parts_damage: part?.parts_damage?.map((item) => ({
        damage_description: item?.damage_description,
        damage_rating: item?.damage_rating,
        location_damage_id: item?.location_damage_id,
        type_damage_id: item?.type_damage_id,
      })),
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let error = false;
    let errorObj = { ...errorsObj };
    partsData.forEach((part) => {
      if (!part.quantity) {
        errorObj.quantity = `Quantity is required.`;
        error = true;
      }
    });

    setErrors(errorObj);
    if (error) return;

    const payloads = createPayload();
    const payloadsUpdated = createPayloads();

    setbtnLoading(true);

    if (vehicleAddDetails?.id) {
      updatePartsInventory(payloadsUpdated)
        .then((response) => {
          toast.success(response.data.message);
          setCurrentStep(2);
          setbtnLoading(false);
          setIsSaveClicked(true);
        })
        .catch((err) => {
          setbtnLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      let data = {
        temp_id: tempData?.temp_id || dismantleTempData?.temp_id,
        is_print: 0,
        part_list: payloads,
      };
      createPartsInventory(data)
        .then((response) => {
          SetDetailsForImages(response.data.data);
          toast.success(response.data.message);
          setIsSaveClicked(true);

          setCurrentStep(3);
          setbtnLoading(false);
        })
        .catch((err) => {
          setbtnLoading(false);
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  const handleSubmitAsPrintLabel = (e) => {
    e.preventDefault();

    let error = false;
    let errorObj = { ...errorsObj };
    partsData.forEach((part) => {
      if (!part.quantity) {
        errorObj.quantity = `Quantity is required.`;
        error = true;
      }
    });

    setErrors(errorObj);
    if (error) return;

    const payloads = createPayload();
    const payloadsUpdated = createPayloads();

    setBtnLoader(true);
    if (vehicleAddDetails?.id) {
      updatePartsInventory(payloadsUpdated)
        .then((response) => {
          toast.success(response.data.message);
          setCurrentStep(2);
          setBtnLoader(false);
        })
        .catch((err) => {
          setBtnLoader(false);
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      let data = {
        temp_id: tempData?.temp_id || dismantleTempData?.temp_id,
        is_print: 1,
        part_list: payloads,
      };
      createPartsInventory(data)
        .then((response) => {
          SetDetailsForImages(response.data.data);
          toast.success(response.data.message);

          setCurrentStep(3);
          setBtnLoader(false);
        })
        .catch((err) => {
          setBtnLoader(false);
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  const handleNextClick = () => {
    if (selectedItems?.length === 0) {
      toast.error("Please select a vehicle part.");
    } else {
      setCurrentStep(2);
    }
  };

  const handleRemovePart = (e, index, partIndex) => {
    e.preventDefault();
    let list = [...partsData];
    let partList = list[index]["parts_associate"];

    partList = partList.filter((_, idx) => idx !== partIndex);

    list[index]["parts_associate"] = partList;
    setPartsData(list);
    setSelectedVehicleModel((prev) => ({
      ...prev,
      [index]: null,
    }));
  };

  const handleSelectWithoutChange = (option) => {
    setSelectedVehicleModel(option);
  };
  const handleSelectWithoutChangeStatus = (option) => {
    setselectedInventoryStatus(option);
  };

  const handleBackButtonClick = () => {
    if (currentStep === 2) {
      setCurrentStep(1);
    } else {
      navigate("/parts-inventory");
    }
  };

  const toggleEnlarge = (index) => {
    if (enlargedIndexes.includes(index)) {
      setEnlargedIndexes(enlargedIndexes.filter((i) => i !== index));
    } else {
      setEnlargedIndexes([...enlargedIndexes, index]);
    }
  };

  const handleSelectChange = (index, selectedOption) => {
    setSelectedVehicleModel((prev) => ({
      ...prev,
      [index]: selectedOption,
    }));
  };

  const handleAddPart = (e, index) => {
    e.preventDefault();
    const selectedVehicleModels = selectedVehicleModel[index];

    if (selectedVehicleModels) {
      let list = [...partsData];
      let partList = list[index]["parts_associate"];

      let idx = partList.findIndex(
        (x) => x.value == selectedVehicleModels.value
      );
      if (idx === -1) {
        partList.push(selectedVehicleModels);
        list[index]["parts_associate"] = partList;
        setPartsData(list);
      } else {
        toast.error(selectedVehicleModels.label + ` is already added.`);
      }
    } else {
      console.warn("No vehicle model selected for index " + index);
    }
  };

  const handleCheckboxCustomChange = (index, e) => {
    const updatedParts = [...partsData];

    updatedParts[index] = {
      ...updatedParts[index],
      is_custom_size: e.target.checked ? "1" : "0",
    };

    setPartsData(updatedParts);
  };

  const handleAddPartLocation = (pIndex) => {
    const updatedPartList = [...partsData];
    const part = updatedPartList[pIndex];

    if (!part.shelf_id || !part.quantity || part.quantity <= 0) {
      alert("Please select a shelf and enter a valid quantity.");
      return;
    }

    const quantity = parseInt(part.quantity, 10);
    let totalAssignedQty = 0;

    if (part && part.part_qty_location_assign) {
      totalAssignedQty = part.part_qty_location_assign.reduce(
        (acc, item) => acc + parseInt(item.part_qty, 10),
        0
      );
    }

    if (totalAssignedQty >= quantity) {
      toast.success(
        `The total ${quantity} has already been assigned. You cannot add more parts.`
      );

      return;
    }

    const remainingQuantity = quantity - totalAssignedQty;

    const existingShelf = part.part_qty_location_assign.find(
      (item) => item.shelf_id === part.shelf_id
    );

    if (existingShelf) {
      existingShelf.part_qty = (
        parseInt(existingShelf.part_qty, 10) + remainingQuantity
      ).toString();
    } else {
      part.part_qty_location_assign.push({
        shelf_id: part.shelf_id,
        part_qty: part.part_qty,
      });
    }

    setPartsData(updatedPartList);

    const updatedQuantity = quantity - totalAssignedQty;
    if (updatedQuantity === 0) {
      alert("All parts have been assigned!");
    }
  };

  const setPartCostPrice = (tempId, price1) => {
    const discountPercentage = settingDetails?.discount_percentage / 100;

    const discountedPrice = price1 * (1 - discountPercentage);

    const updatedParts = [...partsData];
    const partToUpdate = updatedParts.find((part) => part.tempId === tempId);

    if (partToUpdate) {
      partToUpdate.part_original_price = discountedPrice;
      setPartsData(updatedParts);
    }
  };

  const handleInputChangePartLists = (e, tempId) => {
    const { name, value } = e.target;
    const updatedParts = [...partsData];
    const partToUpdate = updatedParts.find((part) => part.tempId === tempId);

    if (partToUpdate) {
      partToUpdate[name] = value;
      setPartsData(updatedParts);

      if (name === "part_price") {
        const originalPrice = parseFloat(value) || 0;
        setPartCostPrice(tempId, originalPrice);
      }
    }
  };

  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6"></div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"></li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary card-primary-Vehicle">
                {vehicleAddDetails?.id ? (
                  <>
                    <div className="card-body vehicleparts-table mt-3">
                      <div className="vehicleparts-table-inner">
                        <Form method={`post`} onSubmit={handleSubmit}>
                          <div className="row">
                            {partsData?.map((part, pIndex) => {
                              return (
                                <React.Fragment key={pIndex}>
                                  <div className="col-lg-6" key={part.id}>
                                    <Card className="text-center">
                                      <Card.Header className="cardHeader">
                                        <div className="d-flex">
                                          <div className="AddPh6">
                                            <h6>
                                              Make : {part.vehicle_make}/ Model
                                              : {part.vehicle_model}/ Year :{" "}
                                              {part.vehicle_year}
                                            </h6>
                                          </div>
                                        </div>
                                      </Card.Header>
                                      <Card.Body className="cardBody">
                                        <div className="col-md-4 mb-4">
                                          <button
                                            style={{
                                              marginLeft: "12px",
                                            }}
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              e.preventDefault();
                                              handleOpenPartHistory(e, part);
                                            }}
                                          >
                                            {part ? part?.part_model_stats : 0}
                                          </button>
                                          <button
                                            style={{
                                              marginLeft: "10px",
                                            }}
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              e.preventDefault();
                                              handleOpenSponsorPrice(e, part);
                                            }}
                                          >
                                            Statiscal Price
                                          </button>
                                          <button
                                            style={{
                                              marginLeft: "10px",
                                            }}
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              e.preventDefault();
                                              handleOpenEstimatePrice(e, part);
                                            }}
                                          >
                                            Estimate Price
                                          </button>
                                        </div>
                                        <div className="row">
                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel">
                                              Quantity
                                            </label>
                                            <input
                                              className="form-control mt-2"
                                              type="number"
                                              name="quantity"
                                              value={part.quantity || "1"}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="Quantity"
                                            />
                                          </div>
                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel">
                                              Inventory Status
                                            </label>
                                            <Select
                                              className="my-Select"
                                              options={inventoryList}
                                              name="inventory_status"
                                              value={
                                                inventoryList.find(
                                                  (item) =>
                                                    item.value ===
                                                    partsData[pIndex]
                                                      ?.inventory_status
                                                ) ||
                                                inventoryList.find(
                                                  (item) => item.value === 1
                                                )
                                              }
                                              onChange={
                                                handleSelectWithoutChangeStatus
                                              }
                                              placeholder={
                                                "Select Inventory Status"
                                              }
                                              isSearchable={true}
                                              inputSelctedValue={
                                                inputSelctedValue
                                              }
                                            ></Select>
                                          </div>
                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel">
                                              Part Price €
                                            </label>
                                            <input
                                              className="form-control mt-2"
                                              type="number"
                                              name="part_price"
                                              value={part.part_price || "0"}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="Part Price"
                                            />
                                          </div>
                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel">
                                              Part Original Price €
                                            </label>
                                            <input
                                              className="form-control mt-2"
                                              type="number"
                                              name="part_original_price"
                                              value={
                                                part.part_original_price || "0"
                                              }
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="0"
                                            />
                                          </div>
                                          <div className="col-lg-4 addPartField">
                                            <label className="partsLabel">
                                              Sponsor Price €
                                            </label>
                                            <input
                                              className="form-control mt-2"
                                              type="number"
                                              name="sponsor_price"
                                              value={part.sponsor_price}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="0"
                                            />
                                          </div>

                                          <div className="col-lg-12 addPartField">
                                            <label className="partsLabel">
                                              Part Description
                                            </label>
                                            <textarea
                                              className="form-control mt-2"
                                              type="text"
                                              name="part_description"
                                              value={part.part_description}
                                              onChange={(e) =>
                                                handleInputChangePartList(
                                                  e,
                                                  part.id
                                                )
                                              }
                                              placeholder="Part Description"
                                              rows={3}
                                            />
                                          </div>

                                          <div className="col-lg-12">
                                            <div className="input-div SelectV selectA">
                                              <Select
                                                className="my-Select"
                                                options={VehicleModelAllList}
                                                value={VehicleModelAllList.find(
                                                  (item) =>
                                                    item.value ==
                                                    selectedVehicleModel
                                                )}
                                                onChange={
                                                  handleSelectWithoutChange
                                                }
                                                placeholder={
                                                  "Select Vehicle Model"
                                                }
                                                isSearchable={true}
                                              />
                                            </div>

                                            <button
                                              style={{ marginTop: "8px" }}
                                              type="button"
                                              className="addBtn ms-2"
                                              onClick={(e) =>
                                                handleAddPart(e, pIndex)
                                              }
                                            >
                                              <i className="bi bi-plus-lg"></i>
                                            </button>
                                          </div>
                                          <div className="col-lg-6">
                                            <Table
                                              className="rounded-table striped bordered hover mt-2 table mb-2"
                                              striped
                                              bordered
                                              hover
                                            >
                                              <tbody>
                                                {vehicleAddDetails
                                                  ?.parts_associate.length >
                                                  0 &&
                                                  vehicleAddDetails?.parts_associate?.map(
                                                    (row, index) => (
                                                      <React.Fragment
                                                        key={index}
                                                      >
                                                        <tr>
                                                          <td className="lisatingTable mb-1">
                                                            {row.vehicle_model}
                                                          </td>
                                                        </tr>
                                                      </React.Fragment>
                                                    )
                                                  )}
                                              </tbody>
                                            </Table>
                                          </div>
                                          <div className="col-lg-6"></div>
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </div>
                          <div className="row">
                            <div className="col-md-12 text-end">
                              <button type="submit" className="btn btn-primary">
                                {t("save")}
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>

                    {currentStep === 2 && (
                      <>
                        <div className="card-body vehicleparts-table mt-3">
                          <div className="vehicleparts-table-inner">
                            <div className="row">
                              <>
                                <Form onSubmit={handleSubmits}>
                                  <React.Fragment>
                                    <div className="col-lg-6">
                                      <Card className="text-center">
                                        <Card.Header className="cardHeader">
                                          <div className="d-flex">
                                            <div className="AddPh6">
                                              <h6>Part Information</h6>
                                            </div>
                                          </div>
                                        </Card.Header>
                                        <Card.Body className="cardBody">
                                          <div class="container text-center">
                                            <div class="row">
                                              <div class="col">Part Name</div>
                                              <div class="col">
                                                {currentPart?.part_name}
                                              </div>
                                            </div>
                                            <div class="row">
                                              <div class="col">
                                                Part Description
                                              </div>
                                              <div class="col">
                                                {currentPart?.part_description}
                                              </div>
                                            </div>

                                            <div class="row">
                                              <div class="col">
                                                Part Quantity
                                              </div>
                                              <div class="col">
                                                {currentPart?.quantity}
                                              </div>
                                            </div>
                                            <div class="row">
                                              <div class="col">Part Price</div>
                                              <div class="col">
                                                € {currentPart?.part_price}
                                              </div>
                                            </div>

                                            <div class="row">
                                              <div class="col">
                                                Part Original Price
                                              </div>
                                              <div class="col">
                                                €{" "}
                                                {
                                                  currentPart?.part_original_price
                                                }
                                              </div>
                                            </div>

                                            <div class="row">
                                              <div class="col">
                                                Sponsor Price{" "}
                                              </div>
                                              <div class="col">
                                                € {currentPart?.sponsor_price}
                                              </div>
                                            </div>
                                          </div>
                                        </Card.Body>
                                      </Card>
                                    </div>

                                    <div className="col-lg-6">
                                      <Card className="text-center">
                                        <Card.Header className="cardHeader">
                                          <div className="d-flex">
                                            <div className="AddPh6">
                                              <h6>Upload Part Image</h6>
                                            </div>
                                          </div>
                                        </Card.Header>
                                        <Card.Body className="cardBody">
                                          <div className="row">
                                            <div className="col-lg-12 addPartField">
                                              <div className="col-lg-12 addPartField">
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  {t("upload_images")}{" "}
                                                  {/* {currentPart.part_name} */}
                                                </label>
                                                <div className="input-div">
                                                  <div className="avatar-upload">
                                                    <div className="avatar-edit">
                                                      <div className="input-div">
                                                        <input
                                                          className="form-control"
                                                          type="file"
                                                          id="imageuploadAbout"
                                                          onChange={
                                                            handleAboutFileChange
                                                          }
                                                          name="file"
                                                          accept=".jpg,.jpeg,.png"
                                                          multiple
                                                        />
                                                        {previewUrl && (
                                                          <i
                                                            style={{
                                                              position:
                                                                "absolute",
                                                              top: "46px",
                                                              right: "12px",
                                                              fontSize: "20px",
                                                              color: "#81bb42",
                                                            }}
                                                            className="bi bi-eye"
                                                            onClick={
                                                              handleShowAbout
                                                            }
                                                          ></i>
                                                        )}
                                                      </div>
                                                      <label htmlFor="imageuploadAbout"></label>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Card.Body>
                                      </Card>
                                    </div>
                                  </React.Fragment>
                                  <div className="d-flex flex-row mt-3 justify-content-end">
                                    {!isLastPart && (
                                      <Button
                                        className="me-2 mb-2 btn-primary btn"
                                        onClick={handleOpenSkip}
                                      >
                                        {t("skip")} & {t("next")}
                                      </Button>
                                    )}

                                    {!isLastPart && (
                                      <Button
                                        className="me-2 mb-2 btn-primary"
                                        type="submit"
                                      >
                                        {t("save")}& {t("next")}
                                      </Button>
                                    )}

                                    {isLastPart && (
                                      <Button
                                        className="me-2 mb-2"
                                        onClick={handleFinish}
                                      >
                                        {t("finish")}
                                      </Button>
                                    )}
                                  </div>
                                </Form>
                              </>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {currentStep === 1 && (
                      <>
                        <div className="row">
                          <div
                            className="col-lg-12"
                            style={{ marginBottom: "40px" }}
                          >
                            <div className="input-div SelectV pt-4">
                              <div className="row">
                                <div className="col-md-6">
                                  <Select
                                    className="my-Select"
                                    options={DismetalList}
                                    value={
                                      DismetalList.find(
                                        (item) =>
                                          item.value ===
                                          dismentalData.dismantle_id
                                      ) || null
                                    }
                                    onChange={
                                      handleSelectWithoutChangeDismetalList
                                    }
                                    placeholder={"Select Dismental"}
                                    isSearchable={true}
                                    onInputChange={
                                      handleInputSelectedChangeDismetalList
                                    }
                                    inputSelctedValue={
                                      inputSelctedDismetalValue
                                    }
                                  />{" "}
                                </div>

                                <div
                                  className="col-md-6"
                                  style={{ marginTop: "10px" }}
                                >
                                  <div
                                    className="addBtn"
                                    style={{ cursor: "pointer" }}
                                    onClick={handleTempDismetalAddClick}
                                  >
                                    <i className="bi bi-plus-lg"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="vehicleThBg table-responsive-sm">
                              <Table
                                className="rounded-table"
                                striped
                                bordered
                                hover
                              >
                                <thead>
                                  <tr>
                                    <th>
                                      <div>
                                        <div className="input-div SelectV">
                                          <div className="col-md-12">
                                            <Select
                                              className="my-Select"
                                              options={categoryPartList}
                                              value={
                                                categoryPartList.find(
                                                  (item) =>
                                                    item.value ===
                                                    categoryPartsData.catalog_id
                                                ) || null
                                              }
                                              onChange={
                                                handleSelectWithoutChangeCategoryList
                                              }
                                              placeholder={"Select Part"}
                                              isSearchable={true}
                                              onInputChange={
                                                handleInputSelectedChangeCategoryList
                                              }
                                              inputSelctedValue={
                                                inputSelctedValue
                                              }
                                            />{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </th>

                                    <th>
                                      <div className="input-div SelectV">
                                        <div className="col-md-12">
                                          <Select
                                            className="my-Select"
                                            options={vehicleYearPartList}
                                            value={
                                              vehicleYearPartList.find(
                                                (item) =>
                                                  item.value ===
                                                  categoryPartsData?.vehicle_year
                                              ) || null
                                            }
                                            onChange={
                                              handleSelectWithoutChangeYearList
                                            }
                                            placeholder={"Select Year"}
                                            isSearchable={true}
                                            onInputChange={
                                              handleInputSelectedChangeYearList
                                            }
                                            inputSelctedValue={
                                              inputSelctedYearValue
                                            }
                                          />
                                        </div>
                                      </div>
                                    </th>

                                    <th>
                                      <div className="input-div SelectV">
                                        <div className="col-md-12">
                                          <Select
                                            className="my-Select"
                                            options={vehicleMakePartList}
                                            value={
                                              vehicleMakePartList.find(
                                                (item) =>
                                                  item.value ===
                                                  categoryPartsData?.vehicle_make
                                              ) || null
                                            }
                                            onChange={
                                              handleSelectWithoutChangeMakeList
                                            }
                                            placeholder={"Select Make"}
                                            isSearchable={true}
                                            onInputChange={
                                              handleInputSelectedChangeMakeList
                                            }
                                            inputSelctedValue={
                                              inputSelctedMakeValue
                                            }
                                          />
                                        </div>
                                      </div>
                                    </th>

                                    <th>
                                      <div className="input-div SelectV">
                                        <div className="col-md-12">
                                          <Select
                                            className="my-Select"
                                            options={vehicleModalPartList}
                                            value={
                                              vehicleModalPartList.find(
                                                (item) =>
                                                  item.value ===
                                                  categoryPartsData.vehicle_model
                                              ) || null
                                            }
                                            onChange={
                                              handleSelectWithoutChangeModelList
                                            }
                                            placeholder={"Select modal"}
                                            isSearchable={true}
                                            onInputChange={
                                              handleInputSelectedChangeModelList
                                            }
                                            inputSelctedValue={
                                              inputSelctedModalValue
                                            }
                                          />
                                        </div>
                                      </div>
                                    </th>

                                    <th>
                                      <div className="input-div SelectV">
                                        <div className="col-md-12">
                                          <Select
                                            className="my-Select"
                                            options={vehicleVariantPartList}
                                            value={
                                              vehicleVariantPartList.find(
                                                (item) =>
                                                  item.value ===
                                                  categoryPartsData?.vehicle_variant
                                              ) || null
                                            }
                                            onChange={
                                              handleSelectWithoutChangeVariantList
                                            }
                                            placeholder={"Select Variant"}
                                            isSearchable={true}
                                            onInputChange={
                                              handleInputSelectedChangeVariantList
                                            }
                                            inputSelctedValue={
                                              inputSelctedVariantValue
                                            }
                                          />
                                        </div>
                                      </div>
                                    </th>
                                    <th>
                                      <div
                                        className="addBtn"
                                        style={{ cursor: "pointer" }}
                                        onClick={handleAddClick}
                                      >
                                        <i className="bi bi-plus-lg"></i>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {selectedItems?.length > 0 ? (
                                    selectedItems?.map((row, index) => (
                                      <tr key={index}>
                                        <td>{row.namePartPT}</td>
                                        <td>{row.vehicle_year}</td>

                                        <td>{row.vehicle_make_name}</td>
                                        <td>{row.vehicle_model_name}</td>
                                        <td>{row.vehicle_variant_name}</td>

                                        <td>
                                          <Button
                                            type="button"
                                            className="btn-danger-delete"
                                            onClick={() =>
                                              handleDeleteClick(row)
                                            }
                                          >
                                            <i class="bi bi-trash3"></i>
                                          </Button>
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td
                                        colSpan="100%"
                                        className="text-center"
                                      >
                                        No results found
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                        <div className="back-footer">
                          <div className="back-next back-next-custom">
                            <div className="row">
                              <div className="col">
                                <button
                                  type="button"
                                  className="next-button btn btn-secondary ms-0"
                                  onClick={handleBackButtonClick}
                                >
                                  <i class="bi bi-arrow-left"></i> {t("back")}
                                </button>
                              </div>
                              <div className="col nxt-btn mr-0">
                                <Button
                                  className="next-button"
                                  type="button"
                                  onClick={handleNextClick}
                                >
                                  {t("next")} <i class="bi bi-arrow-right"></i>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="fuelCF">
                          <div className="card-footer clearfix card-footer-part ">
                            <div className="pagination pagination-sm m-0 float-right">
                              <Pagination
                                activePage={params.page}
                                itemsCountPerPage={parseInt(params.limit)}
                                totalItemsCount={parseInt(totalRecords)}
                                pageRangeDisplayed={5}
                                itemClass={`page-item`}
                                linkClass={`page-link`}
                                onChange={(e) => handleParams(e, "pagination")}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {currentStep === 2 && (
                      <>
                        <div className="card-body vehicleparts-table mt-3">
                          <div className="vehicleparts-table-inner">
                            <Form method={`post`} onSubmit={handleSubmit}>
                              <div
                                className="row"
                                style={{ justifyContent: "flex-start" }}
                              >
                                {partsData?.map((part, pIndex) => {
                                  return (
                                    <React.Fragment key={pIndex}>
                                      <div className="col-lg-12" key={pIndex}>
                                        <div className="text-center inventory-addparts-custom">
                                          <Card.Header className="cardHeader partInfoHeader">
                                            <div
                                              className="partInfo"
                                              data-bs-toggle="collapse"
                                              href={`#multiCollapseExample-${part?.tempId}`}
                                              role="button"
                                              aria-expanded="true"
                                              aria-controls={`multiCollapseExample-${part?.tempId}`}
                                              data-bs-parent="#parentCollapseContainer"
                                            >
                                              <div className="AddPh6">
                                                <h6>
                                                  {part?.part_name} |{" "}
                                                  {part?.parts_internal_id} |{" "}
                                                  {part?.vehicle_make_name} |{" "}
                                                  {part?.vehicle_model_name}|
                                                </h6>
                                              </div>
                                              <div className="">
                                                {btnClosed ? (
                                                  <button
                                                    type="button"
                                                    className="btnClose"
                                                  >
                                                    <i className="bi bi-arrow-repeat"></i>
                                                  </button>
                                                ) : (
                                                  <button
                                                    type="button"
                                                    className="btnClose"
                                                    onClick={() =>
                                                      handleDeleteClick(part)
                                                    }
                                                  >
                                                    <i className="bi bi-x-lg"></i>
                                                  </button>
                                                )}
                                              </div>
                                            </div>
                                          </Card.Header>
                                          <div className="row mb-4 mt-4 justify-content-end text-end">
                                            <div className="col-md-4 mb-4">
                                              <button
                                                style={{
                                                  marginLeft: "12px",
                                                }}
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  e.preventDefault();
                                                  handleOpenPartHistory(
                                                    e,
                                                    part
                                                  );
                                                }}
                                              >
                                                {part
                                                  ? part?.part_model_stats
                                                  : 0}
                                              </button>
                                              <button
                                                style={{
                                                  marginLeft: "10px",
                                                }}
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  e.preventDefault();
                                                  handleOpenSponsorPrice(
                                                    e,
                                                    part
                                                  );
                                                }}
                                              >
                                                Statiscal Price
                                              </button>
                                              <button
                                                style={{
                                                  marginLeft: "10px",
                                                }}
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  e.preventDefault();
                                                  handleOpenEstimatePrice(
                                                    e,
                                                    part
                                                  );
                                                }}
                                              >
                                                Estimate Price
                                              </button>
                                            </div>
                                            <div class="clearfix"></div>

                                            <div
                                              className="col "
                                              Reference
                                              Code
                                            >
                                              <Card.Body
                                                className=" cardBody collapse multi-collapse show card"
                                                id={`multiCollapseExample-${part?.tempId}`}
                                                // style={{overflowY:"scroll"}}
                                              >
                                                <div className="vehicleThBg tableScroll  tablescroll-custom-two">
                                                  <div className="row row-mobile-3  justify-content-start">
                                                    <div className="col-lg-3 addPartField col-lg-3-mob">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Quantity
                                                      </label>
                                                      <input
                                                        className="form-control input-search"
                                                        type="number"
                                                        name="quantity"
                                                        value={part?.quantity}
                                                        onChange={(e) =>
                                                          handleInputChangePartList(
                                                            e,
                                                            part?.tempId
                                                          )
                                                        }
                                                        placeholder="0"
                                                      />

                                                      {errors.quantity && (
                                                        <span className="input-error">
                                                          {errors.quantity}
                                                        </span>
                                                      )}
                                                    </div>

                                                    <div className="col-lg-3 addPartField col-lg-3-mob">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Price 1 €
                                                      </label>
                                                      <input
                                                        className="form-control input-search"
                                                        type="number"
                                                        name="part_price"
                                                        value={part?.part_price}
                                                        onChange={(e) => {
                                                          handleInputChangePartLists(
                                                            e,
                                                            part?.tempId
                                                          );
                                                        }}
                                                        placeholder="0"
                                                      />

                                                      {errors.part_price && (
                                                        <span className="input-error">
                                                          {errors.part_price}
                                                        </span>
                                                      )}
                                                    </div>
                                                    <div className="col-lg-3 addPartField col-lg-3-mob">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Price 2 (
                                                        {
                                                          settingDetails?.discount_percentage
                                                        }
                                                        % discount) €
                                                      </label>
                                                      <input
                                                        className="form-control input-search"
                                                        type="number"
                                                        name="part_original_price"
                                                        value={
                                                          part?.part_original_price
                                                        }
                                                        onChange={(e) =>
                                                          handleInputChangePartList(
                                                            e,
                                                            part?.tempId
                                                          )
                                                        }
                                                        placeholder="0"
                                                      />
                                                    </div>

                                                    <div className="col-lg-3 addPartField col-lg-3-mob">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Part Cost Price €
                                                      </label>
                                                      <input
                                                        className="form-control input-search"
                                                        type="number"
                                                        name="part_cost_price"
                                                        value={
                                                          part?.part_cost_price
                                                        }
                                                        onChange={(e) => {
                                                          handleInputChangePartLists(
                                                            e,
                                                            part?.tempId
                                                          );
                                                        }}
                                                        placeholder="0"
                                                      />
                                                    </div>

                                                    <div className="col-lg-3 addPartField col-lg-3-mob">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Inventory Status
                                                      </label>
                                                      <Select
                                                        className="my-Select"
                                                        options={inventoryList}
                                                        name="inventory_status"
                                                        value={
                                                          inventoryList.find(
                                                            (item) =>
                                                              item.value ===
                                                              partsData[pIndex]
                                                                ?.inventory_status
                                                          ) ||
                                                          inventoryList.find(
                                                            (item) =>
                                                              item.value === 1
                                                          )
                                                        }
                                                        onChange={(
                                                          selectedOption
                                                        ) =>
                                                          handleInventoryIdChange(
                                                            pIndex,
                                                            selectedOption
                                                          )
                                                        }
                                                        placeholder={
                                                          "Select Inventory Status"
                                                        }
                                                        isSearchable={true}
                                                      ></Select>
                                                    </div>

                                                    <div className="col-lg-3 addPartField col-lg-3-mob">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Condition
                                                      </label>
                                                      <Select
                                                        className="my-Select"
                                                        options={conditionList}
                                                        value={
                                                          conditionList.find(
                                                            (item) =>
                                                              item.value ===
                                                              partsData[pIndex]
                                                                ?.condition_id
                                                          ) ||
                                                          conditionList.find(
                                                            (item) =>
                                                              item.value ===
                                                              settingDetails?.condition_id
                                                          ) ||
                                                          conditionList.find(
                                                            (item) =>
                                                              item.value === 1
                                                          )
                                                        }
                                                        onChange={(
                                                          selectedOption
                                                        ) =>
                                                          handleConditionChange(
                                                            pIndex,
                                                            selectedOption
                                                          )
                                                        }
                                                        isSearchable
                                                        placeholder={
                                                          "Select Condition"
                                                        }
                                                      ></Select>
                                                    </div>

                                                    <div className="col-lg-3 addPartField col-lg-3-mob">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        State
                                                      </label>
                                                      <Select
                                                        className="my-Select"
                                                        options={stateList}
                                                        value={
                                                          stateList.find(
                                                            (item) =>
                                                              item.value ===
                                                              partsData[pIndex]
                                                                ?.state_id
                                                          ) ||
                                                          stateList.find(
                                                            (item) =>
                                                              item.value ===
                                                              settingDetails?.state_id
                                                          ) ||
                                                          stateList.find(
                                                            (item) =>
                                                              item.value === 1
                                                          )
                                                        }
                                                        onChange={(
                                                          selectedOption
                                                        ) =>
                                                          handleStateListChange(
                                                            pIndex,
                                                            selectedOption
                                                          )
                                                        }
                                                        isSearchable
                                                        placeholder={
                                                          "Select State"
                                                        }
                                                      ></Select>
                                                    </div>

                                                    <div className="col-lg-3 addPartField col-lg-3-mob">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        {t("part")} {t("brand")}
                                                      </label>
                                                      <Select
                                                        className="my-Select"
                                                        options={allBrandList}
                                                        name="brand_id"
                                                        value={allBrandList.find(
                                                          (option) =>
                                                            option.value ===
                                                            part?.brand_id
                                                        )}
                                                        onChange={(
                                                          selectedOption
                                                        ) =>
                                                          handleBrandIdChange(
                                                            pIndex,
                                                            selectedOption
                                                          )
                                                        }
                                                        placeholder={
                                                          "Select Part Brand"
                                                        }
                                                        isSearchable={true}
                                                      ></Select>
                                                    </div>
                                                    <div className="col-lg-12">
                                                      <label class="partsLabel col-form-label ml-2 Label-my form-label">
                                                        Vehicle Modal
                                                      </label>
                                                      <div className="selectDisplay">
                                                        <div className="input-div SelectV selectA">
                                                          <input
                                                            className="form-control input-search"
                                                            type="text"
                                                            maxLength={50}
                                                            placeholder="Search Vehicle Model"
                                                            value={
                                                              vehicleModelAuto
                                                            }
                                                            onChange={
                                                              handleChangeModel
                                                            }
                                                            onKeyDown={
                                                              handleKeyDownModel
                                                            }
                                                            onClick={(e) =>
                                                              e.stopPropagation()
                                                            }
                                                          />
                                                          {showSuggestionsModel &&
                                                            vehicleModelAuto && (
                                                              <ul className="suggestions">
                                                                {filteredSuggestionsModel?.length >
                                                                0 ? (
                                                                  filteredSuggestionsModel.map(
                                                                    (
                                                                      suggestion,
                                                                      index
                                                                    ) => (
                                                                      <li
                                                                        key={
                                                                          suggestion.id
                                                                        }
                                                                        className={
                                                                          index ===
                                                                          activeSuggestionIndexModel
                                                                            ? "active"
                                                                            : ""
                                                                        }
                                                                        onClick={() =>
                                                                          handleClickModel(
                                                                            suggestion,
                                                                            pIndex
                                                                          )
                                                                        }
                                                                      >
                                                                        {
                                                                          suggestion.model_name
                                                                        }{" "}
                                                                        /{" "}
                                                                        {
                                                                          suggestion.initial_year
                                                                        }
                                                                      </li>
                                                                    )
                                                                  )
                                                                ) : (
                                                                  <li>
                                                                    No
                                                                    suggestions
                                                                    available
                                                                  </li>
                                                                )}
                                                              </ul>
                                                            )}
                                                        </div>
                                                      </div>
                                                      {part.parts_associate
                                                        .length > 0 && (
                                                        <div className="tableScroll vehicleThBg Vehicle-Modal-tableScroll">
                                                          <div className="container-fluid">
                                                            <div className="row justify-content-start">
                                                              {part.parts_associate.map(
                                                                (
                                                                  row,
                                                                  index
                                                                ) => (
                                                                  <div
                                                                    className="col-xl-4"
                                                                    key={index}
                                                                  >
                                                                    <div className="d-flex">
                                                                      <div className="lisatingTable mb-1 col vehicleModall text1-line">
                                                                        {
                                                                          row.model_name
                                                                        }{" "}
                                                                        /{" "}
                                                                        {
                                                                          row.vehicle_year
                                                                        }
                                                                      </div>
                                                                      <button
                                                                        style={{
                                                                          borderRadius:
                                                                            "0px",
                                                                          margin:
                                                                            "7px",
                                                                          height:
                                                                            "30px",
                                                                          width:
                                                                            "30px",
                                                                          color:
                                                                            "#dd4b4b",
                                                                          backgroundColor:
                                                                            "#ffe9e9",
                                                                          display:
                                                                            "flex",
                                                                          justifyContent:
                                                                            "center",
                                                                          alignItems:
                                                                            "center",
                                                                          border:
                                                                            "transparent",
                                                                          marginTop:
                                                                            "12px",
                                                                        }}
                                                                        onClick={() =>
                                                                          setPartsData(
                                                                            (
                                                                              prevParts
                                                                            ) =>
                                                                              prevParts.map(
                                                                                (
                                                                                  p,
                                                                                  i
                                                                                ) => {
                                                                                  if (
                                                                                    i ===
                                                                                    pIndex
                                                                                  ) {
                                                                                    return {
                                                                                      ...p,
                                                                                      parts_associate:
                                                                                        p.parts_associate.filter(
                                                                                          (
                                                                                            m
                                                                                          ) =>
                                                                                            m.vehicle_model !==
                                                                                            row.vehicle_model
                                                                                        ),
                                                                                    };
                                                                                  }
                                                                                  return p;
                                                                                }
                                                                              )
                                                                          )
                                                                        }
                                                                      >
                                                                        <i className="bi bi-trash3"></i>
                                                                      </button>
                                                                    </div>
                                                                  </div>
                                                                )
                                                              )}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </div>
                                                    <div className="col-lg-12 addPartField col-lg-6-mob">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Notes
                                                      </label>
                                                      <textarea
                                                        className="form-control input-search"
                                                        type="text"
                                                        name="part_description"
                                                        value={
                                                          part?.part_description
                                                        }
                                                        onChange={(e) =>
                                                          handleInputChangePartList(
                                                            e,
                                                            part?.tempId
                                                          )
                                                        }
                                                        placeholder="Part Description"
                                                        rows={3}
                                                      />
                                                    </div>

                                                    <div className="col-lg-3 addPartField col-lg-3-mob">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Custome Size
                                                      </label>

                                                      <div className="addPartField">
                                                        <div className="form-check text-left">
                                                          <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={
                                                              part?.is_custom_size ===
                                                              "1"
                                                            }
                                                            onChange={(e) =>
                                                              handleCheckboxCustomChange(
                                                                pIndex,
                                                                e
                                                              )
                                                            }
                                                          />
                                                          <label className="form-check-label">
                                                            Custome Size
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-3 addPartField col-lg-3-mob">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Print label
                                                      </label>
                                                      <div className="addPartField">
                                                        <div className="form-check text-left">
                                                          <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={
                                                              part?.print_label ===
                                                              1
                                                            }
                                                            onChange={(e) =>
                                                              handleCheckboxPrintChanges(
                                                                pIndex,
                                                                e
                                                              )
                                                            }
                                                          />
                                                          <label className="form-check-label">
                                                            Print label
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div className="col-lg-3 addPartField col-lg-3-mob">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        VAT included
                                                      </label>
                                                      <div className="addPartField">
                                                        <div className="form-check text-left">
                                                          <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={
                                                              part?.vat_included ===
                                                              1
                                                            }
                                                            onChange={(e) =>
                                                              handleCheckboxVatChanges(
                                                                pIndex,
                                                                e
                                                              )
                                                            }
                                                          />
                                                          <label className="form-check-label">
                                                            VAT included
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div className="col-lg-3 addPartField col-lg-3-mob">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Associate Location
                                                      </label>
                                                      <div className="addPartField">
                                                        <div className="form-check text-left">
                                                          <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={
                                                              part?.associate_location ===
                                                              1
                                                            }
                                                            onChange={(e) =>
                                                              handleCheckboxAssociateLocationChanges(
                                                                pIndex,
                                                                e
                                                              )
                                                            }
                                                          />
                                                          <label className="form-check-label">
                                                            Associate Location
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div className="row row-mobile-3 justify-content-start">
                                                      {part?.is_custom_size ===
                                                        "1" && (
                                                        <>
                                                          <div className="col-lg-3 addPartField col-lg-3-mob">
                                                            <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                              Weight (kg){" "}
                                                            </label>
                                                            <input
                                                              className="form-control input-search"
                                                              type="number"
                                                              name="parts_weight"
                                                              value={
                                                                part?.parts_weight
                                                              }
                                                              onChange={(e) =>
                                                                handleInputChangePartList(
                                                                  e,
                                                                  part?.tempId
                                                                )
                                                              }
                                                              placeholder="0"
                                                            />
                                                          </div>

                                                          <div className="col-lg-3 addPartField col-lg-3-mob">
                                                            <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                              Width(cm){" "}
                                                            </label>
                                                            <input
                                                              className="form-control input-search"
                                                              type="number"
                                                              name="parts_width"
                                                              value={
                                                                part?.parts_width
                                                              }
                                                              onChange={(e) =>
                                                                handleInputChangePartList(
                                                                  e,
                                                                  part?.tempId
                                                                )
                                                              }
                                                              placeholder="0"
                                                            />

                                                            {errors.parts_width && (
                                                              <span className="input-error">
                                                                {
                                                                  errors.parts_width
                                                                }
                                                              </span>
                                                            )}
                                                          </div>

                                                          <div className="col-lg-3 addPartField col-lg-3-mob">
                                                            <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                              Height(cm){" "}
                                                            </label>
                                                            <input
                                                              className="form-control input-search"
                                                              type="number"
                                                              name="parts_height"
                                                              value={
                                                                part?.parts_height
                                                              }
                                                              onChange={(e) =>
                                                                handleInputChangePartList(
                                                                  e,
                                                                  part?.tempId
                                                                )
                                                              }
                                                              placeholder="0"
                                                            />
                                                          </div>

                                                          <div className="col-lg-3 addPartField col-lg-3-mob">
                                                            <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                              Length(cm){" "}
                                                            </label>
                                                            <input
                                                              className="form-control input-search"
                                                              type="number"
                                                              name="parts_length"
                                                              value={
                                                                part?.parts_length
                                                              }
                                                              onChange={(e) =>
                                                                handleInputChangePartList(
                                                                  e,
                                                                  part?.tempId
                                                                )
                                                              }
                                                              placeholder="0"
                                                            />
                                                          </div>
                                                        </>
                                                      )}
                                                    </div>

                                                    <div className="col-lg-3 addPartField col-lg-3-mob">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Part Ratings
                                                      </label>
                                                      <div className="addPartField">
                                                        <div className="form-check text-left">
                                                          <ReactStars
                                                            size={40}
                                                            count={5}
                                                            activeColor="#ffd700"
                                                            value={
                                                              part?.part_rating
                                                            }
                                                            a11y={true}
                                                            isHalf={true}
                                                            emptyIcon={
                                                              <i className="bi bi-star" />
                                                            }
                                                            halfIcon={
                                                              <i className="bi bi-star-half" />
                                                            }
                                                            filledIcon={
                                                              <i className="bi bi-star-fill" />
                                                            }
                                                            onChange={(
                                                              newRating
                                                            ) =>
                                                              handleRatingChange(
                                                                newRating,
                                                                part.tempId
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>

                                                    {part?.state_id === 2 && (
                                                      <div className="col-lg-3 addPartField col-lg-3-mob">
                                                        <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                          Reg Number Dismantler
                                                        </label>
                                                        <input
                                                          className="form-control input-search"
                                                          type="text"
                                                          name="reg_number_dismantler"
                                                          value={
                                                            settingDetails?.reg_number_dismantler
                                                              ? settingDetails?.reg_number_dismantler
                                                              : part?.reg_number_dismantler
                                                          }
                                                          onChange={(e) =>
                                                            handleInputChangePartList(
                                                              e,
                                                              part?.tempId
                                                            )
                                                          }
                                                          placeholder="0"
                                                        />

                                                        {errors.reg_number_dismantler && (
                                                          <span className="input-error">
                                                            {
                                                              errors.reg_number_dismantler
                                                            }
                                                          </span>
                                                        )}
                                                      </div>
                                                    )}

                                                    <div className="col-lg-3 addPartField col-lg-3-mob"></div>

                                                    <div className="col-lg-3 addPartField col-lg-3-mob"></div>

                                                    {part?.is_show_engine_record ===
                                                      true && (
                                                      <>
                                                        <div className="col-lg-3 addPartField col-lg-3-mob">
                                                          <div className="col-md-12">
                                                            <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                              Engine Code{" "}
                                                            </label>
                                                            <Select
                                                              className="my-Select"
                                                              options={
                                                                vehicleEngineList
                                                              }
                                                              value={vehicleEngineList.find(
                                                                (item) =>
                                                                  item.value ===
                                                                  EngineData?.vehicle_variant
                                                              )}
                                                              onChange={
                                                                handleSelectWithoutChangeEngineList
                                                              }
                                                              placeholder={
                                                                "Select Engine"
                                                              }
                                                              isSearchable={
                                                                true
                                                              }
                                                              onInputChange={
                                                                handleInputSelectedChangeEngineList
                                                              }
                                                              inputSelctedValue={
                                                                inputSelctedEngineValue
                                                              }
                                                            />
                                                          </div>
                                                        </div>

                                                        <div className="col-lg-3 addPartField col-lg-3-mob">
                                                          <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                            Cv{" "}
                                                          </label>
                                                          <input
                                                            className="form-control input-search"
                                                            type="text"
                                                            name="cv"
                                                            value={part?.cv}
                                                            onChange={(e) => {
                                                              handleInputChangePartLists(
                                                                e,
                                                                part?.tempId
                                                              );
                                                            }}
                                                            placeholder="0"
                                                          />
                                                        </div>

                                                        <div className="col-lg-3 addPartField col-lg-3-mob">
                                                          <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                            CC
                                                          </label>
                                                          <input
                                                            className="form-control input-search"
                                                            type="text"
                                                            name="cc"
                                                            value={part?.cc}
                                                            onChange={(e) => {
                                                              handleInputChangePartLists(
                                                                e,
                                                                part?.tempId
                                                              );
                                                            }}
                                                            placeholder="0"
                                                          />
                                                        </div>

                                                        <div className="col-lg-3 addPartField col-lg-3-mob">
                                                          <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                            KW
                                                          </label>
                                                          <input
                                                            className="form-control input-search"
                                                            type="text"
                                                            name="kw"
                                                            value={part?.kw}
                                                            onChange={(e) => {
                                                              handleInputChangePartLists(
                                                                e,
                                                                part?.tempId
                                                              );
                                                            }}
                                                            placeholder="0"
                                                          />
                                                        </div>
                                                      </>
                                                    )}

                                                    <div
                                                      className="col-lg-12"
                                                      style={{
                                                        display: "flex",
                                                        justifyContent: "end",
                                                        marginBottom: "10px",
                                                      }}
                                                    >
                                                      {part?.etai_part_id && (
                                                        <>
                                                          <button
                                                            style={{
                                                              marginTop: "8px",
                                                              marginRight:
                                                                "10px",
                                                            }}
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={(e) =>
                                                              handleEtaiDetails(
                                                                e,
                                                                part?.etai_part_id,
                                                                pIndex
                                                              )
                                                            }
                                                          >
                                                            View Reference
                                                          </button>

                                                          <button
                                                            style={{
                                                              marginTop: "8px",
                                                              marginRight:
                                                                "10px",
                                                            }}
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={(e) =>
                                                              handleEtaiPriceDetails(
                                                                e,
                                                                part?.etai_part_id,
                                                                pIndex
                                                              )
                                                            }
                                                          >
                                                            View Price
                                                          </button>
                                                        </>
                                                      )}

                                                      {part?.addedData?.length >
                                                        0 && (
                                                        <>
                                                          <button
                                                            style={{
                                                              marginTop: "8px",
                                                              marginRight:
                                                                "10px",
                                                            }}
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={(e) =>
                                                              handleOpenRefCodeCondition(
                                                                e,
                                                                part
                                                              )
                                                            }
                                                          >
                                                            Parts
                                                          </button>

                                                          <button
                                                            style={{
                                                              marginTop: "8px",
                                                            }}
                                                            type="button"
                                                            className="btn btn-primary"
                                                            onClick={(e) =>
                                                              handleOpenRefCodeEstimate(
                                                                e,
                                                                part
                                                              )
                                                            }
                                                          >
                                                            Estimate Price
                                                          </button>
                                                        </>
                                                      )}
                                                    </div>

                                                    <div className="col-lg-12">
                                                      <div className="table-responsive-custom-q  table-custom-sticky-inherit">
                                                        <table className="table">
                                                          <thead>
                                                            <tr>
                                                              <th scope="col">
                                                                <div className="col-lg-12 addPartField">
                                                                  <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                                    Reference
                                                                    Code
                                                                  </label>
                                                                  <input
                                                                    className="form-control input-search"
                                                                    type="text"
                                                                    name="reference_code"
                                                                    value={
                                                                      selectedReferenceCode
                                                                        ? selectedReferenceCode
                                                                        : part?.reference_code
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleInputChangePartListRef(
                                                                        e,
                                                                        pIndex
                                                                      )
                                                                    }
                                                                    placeholder="Reference Code"
                                                                  />
                                                                </div>
                                                              </th>

                                                              <th scope="col">
                                                                <div className="col-lg-12 addPartField">
                                                                  <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                                    Condition
                                                                  </label>
                                                                  <Select
                                                                    className="my-Select"
                                                                    options={
                                                                      conditionList
                                                                    }
                                                                    value={
                                                                      conditionList.find(
                                                                        (
                                                                          option
                                                                        ) =>
                                                                          option.value ===
                                                                            part?.condition_id_ref ||
                                                                          option.label ===
                                                                            part?.selectedCondition
                                                                      ) || null
                                                                    }
                                                                    onChange={(
                                                                      selectedOption
                                                                    ) =>
                                                                      handleConditionSChangeRefs(
                                                                        pIndex,
                                                                        selectedOption
                                                                      )
                                                                    }
                                                                    isSearchable
                                                                    placeholder={
                                                                      "Select Condition"
                                                                    }
                                                                  ></Select>
                                                                </div>
                                                              </th>
                                                              <th scope="col">
                                                                <div className="col-lg-12 addPartField">
                                                                  <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                                    Reference
                                                                    Type
                                                                  </label>
                                                                  <Select
                                                                    className="my-Select"
                                                                    options={
                                                                      referenceList
                                                                    }
                                                                    value={
                                                                      referenceList.find(
                                                                        (
                                                                          option
                                                                        ) =>
                                                                          option.value ===
                                                                            part?.type_id ||
                                                                          option.label ===
                                                                            part?.selectedType
                                                                      ) || null
                                                                    }
                                                                    onChange={(
                                                                      selectedOption
                                                                    ) =>
                                                                      handleConditionChangeRef(
                                                                        pIndex,
                                                                        selectedOption
                                                                      )
                                                                    }
                                                                    isSearchable
                                                                    placeholder="Select Type"
                                                                  />
                                                                </div>
                                                              </th>
                                                              <th scope="col"></th>
                                                              <th>
                                                                <button
                                                                  style={{
                                                                    marginTop:
                                                                      "77px",
                                                                  }}
                                                                  type="button"
                                                                  className="btn btn-primary"
                                                                  onClick={() =>
                                                                    handleAddPartRef(
                                                                      pIndex
                                                                    )
                                                                  }
                                                                >
                                                                  Add Reference
                                                                </button>
                                                              </th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            {part?.addedData.map(
                                                              (
                                                                data,
                                                                rowIndex
                                                              ) => {
                                                                return (
                                                                  <>
                                                                    <tr
                                                                      key={
                                                                        rowIndex
                                                                      }
                                                                    >
                                                                      <td>
                                                                        <div className="col-lg-12 addPartField">
                                                                          <label className="partsLabel col-form-label ml-2">
                                                                            Reference
                                                                            Code
                                                                          </label>
                                                                          <input
                                                                            className="form-control input-search"
                                                                            type="text"
                                                                            name="reference_code"
                                                                            value={
                                                                              data.reference_code
                                                                            }
                                                                            onChange={(
                                                                              e
                                                                            ) =>
                                                                              handleUpdatePartRef(
                                                                                pIndex,
                                                                                rowIndex,
                                                                                "reference_code",
                                                                                e
                                                                                  .target
                                                                                  .value
                                                                              )
                                                                            }
                                                                            placeholder="Reference Code"
                                                                          />
                                                                        </div>
                                                                      </td>

                                                                      <td>
                                                                        <label className="partsLabel col-form-label ml-2">
                                                                          Condition
                                                                        </label>
                                                                        <Select
                                                                          className="my-Select"
                                                                          options={
                                                                            conditionList
                                                                          }
                                                                          value={conditionList.find(
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              option.value ===
                                                                              data.condition_id_ref
                                                                          )}
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) =>
                                                                            handleUpdatePartRef(
                                                                              pIndex,
                                                                              rowIndex,
                                                                              "condition_id_ref",
                                                                              selectedOption?.value ||
                                                                                null
                                                                            )
                                                                          }
                                                                          isSearchable
                                                                          placeholder="Select Condition"
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <label className="partsLabel col-form-label ml-2">
                                                                          Reference
                                                                          Type
                                                                        </label>
                                                                        <Select
                                                                          className="my-Select"
                                                                          options={
                                                                            referenceList
                                                                          }
                                                                          value={referenceList.find(
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              option.value ===
                                                                              data.type_id
                                                                          )}
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) =>
                                                                            handleUpdatePartRef(
                                                                              pIndex,
                                                                              rowIndex,
                                                                              "type_id",
                                                                              selectedOption?.value ||
                                                                                null
                                                                            )
                                                                          }
                                                                          isSearchable
                                                                          placeholder="Select Type"
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <div className="form-check text-left">
                                                                          <label className="partsLabel col-form-label ml-2">
                                                                            Is
                                                                            Main
                                                                          </label>
                                                                          <input
                                                                            style={{
                                                                              marginLeft:
                                                                                "20px",
                                                                            }}
                                                                            className="form-check-input"
                                                                            type="radio" // Changed from checkbox to radio
                                                                            name={`isMainRadioGroup-${pIndex}-${rowIndex}`} // Unique name for each radio button group (to handle multiple groups)
                                                                            checked={
                                                                              data?.is_main ===
                                                                              1
                                                                            }
                                                                            onChange={() =>
                                                                              handleUpdatePartRef(
                                                                                pIndex,
                                                                                rowIndex,
                                                                                "is_main",
                                                                                1
                                                                              )
                                                                            }
                                                                          />
                                                                        </div>
                                                                      </td>
                                                                      <td>
                                                                        {/* <i
                                                                          className="bi bi-pen-fill"
                                                                          style={{
                                                                            cursor:
                                                                              "pointer",
                                                                            marginRight:
                                                                              "10px",
                                                                          }}
                                                                          onClick={() =>
                                                                            handleEditPartRef(
                                                                              pIndex,
                                                                              rowIndex
                                                                            )
                                                                          }
                                                                        /> */}
                                                                        <i
                                                                          class="bi bi-trash-fill"
                                                                          style={{
                                                                            cursor:
                                                                              "pointer",
                                                                          }}
                                                                          onClick={() =>
                                                                            handleDeletePartRef(
                                                                              pIndex,
                                                                              rowIndex
                                                                            )
                                                                          }
                                                                        ></i>{" "}
                                                                      </td>
                                                                    </tr>
                                                                  </>
                                                                );
                                                              }
                                                            )}
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                      <div className=" table-responsive-custom-q  table-custom-sticky-inherit">
                                                        <table className="table">
                                                          <thead>
                                                            <tr>
                                                              <th scope="col">
                                                                <div
                                                                  className="col-lg-12 addPartField"
                                                                  style={{
                                                                    width:
                                                                      "206px",
                                                                  }}
                                                                >
                                                                  <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                                    Damage
                                                                    Description
                                                                  </label>
                                                                  <input
                                                                    className="form-control input-search"
                                                                    type="text"
                                                                    name="damage_description"
                                                                    value={
                                                                      selectedReferenceCode
                                                                        ? selectedReferenceCode
                                                                        : part?.damage_description
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleInputChangePartListRef(
                                                                        e,
                                                                        pIndex
                                                                      )
                                                                    }
                                                                    placeholder="Damage Description"
                                                                  />
                                                                </div>
                                                              </th>

                                                              <th scope="col">
                                                                <div className="col-lg-12 addPartField">
                                                                  <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                                    Type Damage
                                                                  </label>
                                                                  <Select
                                                                    className="my-Select"
                                                                    options={
                                                                      typeDamageList
                                                                    }
                                                                    value={
                                                                      typeDamageList.find(
                                                                        (
                                                                          option
                                                                        ) =>
                                                                          option.value ===
                                                                            part?.type_damage_id ||
                                                                          option.label ===
                                                                            part?.selectedCondition
                                                                      ) || null
                                                                    }
                                                                    onChange={(
                                                                      selectedOption
                                                                    ) =>
                                                                      handleTypeDamageChange(
                                                                        pIndex,
                                                                        selectedOption
                                                                      )
                                                                    }
                                                                    isSearchable
                                                                    placeholder={
                                                                      "Select Type Damage"
                                                                    }
                                                                  ></Select>
                                                                </div>
                                                              </th>
                                                              <th scope="col">
                                                                <div className="col-lg-12 addPartField">
                                                                  <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                                    Location
                                                                    Damage
                                                                  </label>
                                                                  <Select
                                                                    className="my-Select"
                                                                    options={
                                                                      locationDamageList
                                                                    }
                                                                    value={
                                                                      locationDamageList.find(
                                                                        (
                                                                          option
                                                                        ) =>
                                                                          option.value ===
                                                                            part?.location_damage_id ||
                                                                          option.label ===
                                                                            part?.selectedType
                                                                      ) || null
                                                                    }
                                                                    onChange={(
                                                                      selectedOption
                                                                    ) =>
                                                                      hanldeLocationDamageChange(
                                                                        pIndex,
                                                                        selectedOption
                                                                      )
                                                                    }
                                                                    isSearchable
                                                                    placeholder="Select Location Damage"
                                                                  />
                                                                </div>
                                                              </th>
                                                              <th scope="col">
                                                                <div
                                                                  className="col-lg-12 addPartField"
                                                                  style={{
                                                                    width:
                                                                      "192px",
                                                                  }}
                                                                >
                                                                  <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                                    Damage
                                                                    Rating
                                                                  </label>
                                                                  <ReactStars
                                                                    size={40}
                                                                    count={5}
                                                                    activeColor="#ffd700"
                                                                    value={
                                                                      part?.damage_rating
                                                                    }
                                                                    a11y={true}
                                                                    isHalf={
                                                                      true
                                                                    }
                                                                    emptyIcon={
                                                                      <i className="bi bi-star" />
                                                                    }
                                                                    halfIcon={
                                                                      <i className="bi bi-star-half" />
                                                                    }
                                                                    filledIcon={
                                                                      <i className="bi bi-star-fill" />
                                                                    }
                                                                    onChange={(
                                                                      newRating
                                                                    ) =>
                                                                      handleInputChangePartListRef(
                                                                        {
                                                                          target:
                                                                            {
                                                                              name: "damage_rating",
                                                                              value:
                                                                                newRating,
                                                                            },
                                                                        },
                                                                        pIndex
                                                                      )
                                                                    }
                                                                  />
                                                                </div>
                                                              </th>

                                                              <th scope="col"></th>
                                                              <th>
                                                                <button
                                                                  style={{
                                                                    marginTop:
                                                                      "77px",
                                                                    width:
                                                                      "155px",
                                                                  }}
                                                                  type="button"
                                                                  className="btn btn-primary"
                                                                  onClick={() =>
                                                                    handleAddPartDamage(
                                                                      pIndex
                                                                    )
                                                                  }
                                                                >
                                                                  Add Damage
                                                                  List
                                                                </button>
                                                              </th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            {part?.parts_damage?.map(
                                                              (
                                                                data,
                                                                rowIndex
                                                              ) => {
                                                                return (
                                                                  <>
                                                                    <tr
                                                                      key={
                                                                        rowIndex
                                                                      }
                                                                    >
                                                                      <td>
                                                                        <div className="col-lg-12 addPartField">
                                                                          <label className="partsLabel col-form-label ml-2">
                                                                            Damage
                                                                            Description
                                                                          </label>
                                                                          <input
                                                                            className="form-control input-search"
                                                                            type="text"
                                                                            name="damage_description"
                                                                            value={
                                                                              data.damage_description
                                                                            }
                                                                            onChange={(
                                                                              e
                                                                            ) =>
                                                                              handleUpdatePartDamage(
                                                                                pIndex,
                                                                                rowIndex,
                                                                                "damage_description",
                                                                                e
                                                                                  .target
                                                                                  .value
                                                                              )
                                                                            }
                                                                            placeholder="Damge Description"
                                                                          />
                                                                        </div>
                                                                      </td>

                                                                      <td>
                                                                        <label className="partsLabel col-form-label ml-2">
                                                                          Type
                                                                          Damage
                                                                        </label>
                                                                        <Select
                                                                          className="my-Select"
                                                                          options={
                                                                            typeDamageList
                                                                          }
                                                                          value={typeDamageList.find(
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              option.value ===
                                                                              data.type_damage_id
                                                                          )}
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) =>
                                                                            handleUpdatePartDamage(
                                                                              pIndex,
                                                                              rowIndex,
                                                                              "type_damage_id",
                                                                              selectedOption?.value ||
                                                                                null
                                                                            )
                                                                          }
                                                                          isSearchable
                                                                          placeholder="Select Type Damage"
                                                                        />
                                                                      </td>
                                                                      <td>
                                                                        <label className="partsLabel col-form-label ml-2">
                                                                          Location
                                                                          Damage
                                                                        </label>
                                                                        <Select
                                                                          className="my-Select"
                                                                          options={
                                                                            locationDamageList
                                                                          }
                                                                          value={locationDamageList.find(
                                                                            (
                                                                              option
                                                                            ) =>
                                                                              option.value ===
                                                                              data.location_damage_id
                                                                          )}
                                                                          onChange={(
                                                                            selectedOption
                                                                          ) =>
                                                                            handleUpdatePartDamage(
                                                                              pIndex,
                                                                              rowIndex,
                                                                              "location_damage_id",
                                                                              selectedOption?.value ||
                                                                                null
                                                                            )
                                                                          }
                                                                          isSearchable
                                                                          placeholder="Select Location Damage"
                                                                        />
                                                                      </td>
                                                                      <th scope="col">
                                                                        <div className="col-lg-12 addPartField">
                                                                          <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                                            Damage
                                                                            Rating
                                                                          </label>
                                                                          <ReactStars
                                                                            size={
                                                                              40
                                                                            }
                                                                            count={
                                                                              5
                                                                            }
                                                                            activeColor="#ffd700"
                                                                            a11y={
                                                                              true
                                                                            }
                                                                            isHalf={
                                                                              true
                                                                            }
                                                                            emptyIcon={
                                                                              <i className="bi bi-star" />
                                                                            }
                                                                            halfIcon={
                                                                              <i className="bi bi-star-half" />
                                                                            }
                                                                            filledIcon={
                                                                              <i className="bi bi-star-fill" />
                                                                            }
                                                                            value={
                                                                              data?.damage_rating
                                                                            }
                                                                            onChange={(
                                                                              newRating
                                                                            ) =>
                                                                              handleUpdatePartDamage(
                                                                                pIndex,
                                                                                rowIndex,
                                                                                "damage_rating",
                                                                                newRating
                                                                              )
                                                                            }
                                                                          />
                                                                        </div>
                                                                      </th>
                                                                      <td>
                                                                        <i
                                                                          class="bi bi-trash-fill"
                                                                          style={{
                                                                            cursor:
                                                                              "pointer",
                                                                          }}
                                                                          onClick={() =>
                                                                            handleDeletePartDamage(
                                                                              pIndex,
                                                                              rowIndex
                                                                            )
                                                                          }
                                                                        ></i>{" "}
                                                                      </td>
                                                                    </tr>
                                                                  </>
                                                                );
                                                              }
                                                            )}
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </Card.Body>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  );
                                })}
                              </div>
                              <div className="back-footer">
                                <div class="row">
                                  <div class="col-lg-6">
                                    <button
                                      type="button"
                                      class="next-button btn btn-secondary"
                                      onClick={handleBackButtonClick}
                                    >
                                      <i class="bi bi-arrow-left"></i>{" "}
                                      {t("back")}
                                    </button>
                                  </div>
                                  <div class="col-lg-6 saveasLBtns">
                                    {btnLoader === true ? (
                                      <button
                                        type="button"
                                        disabled={true}
                                        className="btn btn-primary"
                                      >
                                        Loading...
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={handleSubmitAsPrintLabel}
                                        disabled={isSaveClicked}
                                      >
                                        {t("save_as_label")}{" "}
                                        <i className="bi bi-save ms-2" />
                                      </button>
                                    )}
                                    <div>
                                      {btnLoading ? (
                                        <button
                                          type="button"
                                          disabled={true}
                                          className="btn btn-primary"
                                        >
                                          Loading...
                                        </button>
                                      ) : (
                                        <button
                                          type="submit"
                                          className="btn btn-primary addPArtsSave"
                                        >
                                          {t("save")}{" "}
                                          <i className="bi bi-save ms-2"></i>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </>
                    )}

                    {currentStep === 3 && (
                      <>
                        <div className="card-body vehicleparts-table mt-3">
                          <div className="vehicleparts-table-inner">
                            <div className="row">
                              <>
                                <Form className="row" onSubmit={handleSubmits}>
                                  <React.Fragment>
                                    <div className="col-lg-6">
                                      <Card className="text-justify">
                                        <Card.Header className="cardHeader">
                                          <div className="d-flex">
                                            <div className="AddPh6">
                                              <h6>Part Information</h6>
                                            </div>
                                          </div>
                                        </Card.Header>
                                        <Card.Body
                                          className="cardBody"
                                          style={{ padding: "0" }}
                                        >
                                          <div class="container-fluid text-justify">
                                            <div class="row pt-2 pb-2 border-bottom">
                                              <div class="col-lg-6">
                                                Part Name:
                                              </div>
                                              <div class="col-lg-6">
                                                {currentPart?.part_name}
                                              </div>
                                            </div>
                                            <div class="row pt-2 pb-2 border-bottom">
                                              <div class="col-lg-6">
                                                Part Description:
                                              </div>
                                              <div
                                                class="col-lg-6"
                                                style={{ lineHeight: "25px" }}
                                              >
                                                {currentPart?.part_description}
                                              </div>
                                            </div>

                                            <div class="row pt-2 pb-2 border-bottom">
                                              <div class="col-lg-6">
                                                Part Quantity:
                                              </div>
                                              <div class="col-lg-6">
                                                {currentPart?.quantity}
                                              </div>
                                            </div>
                                            <div class="row pt-2 pb-2 border-bottom">
                                              <div class="col-lg-6">
                                                Part Price:
                                              </div>
                                              <div class="col-lg-6">
                                                {currentPart?.part_price}
                                              </div>
                                            </div>

                                            <div class="row pt-2 pb-2 border-bottom">
                                              <div class="col-lg-6">
                                                Part Original Price:
                                              </div>
                                              <div class="col-lg-6">
                                                {
                                                  currentPart?.part_original_price
                                                }
                                              </div>
                                            </div>

                                            <div class="row pt-2 pb-2 border-bottom">
                                              <div class="col-lg-6">
                                                Sponsor Price:{" "}
                                              </div>
                                              <div class="col-lg-6">
                                                {currentPart?.sponsor_price}
                                              </div>
                                            </div>
                                          </div>
                                        </Card.Body>
                                      </Card>
                                    </div>
                                    <div className="col-lg-6">
                                      <Card className="text-center">
                                        <Card.Header className="cardHeader">
                                          <div className="d-flex">
                                            <div className="AddPh6">
                                              <h6>Upload Part Image</h6>
                                            </div>
                                          </div>
                                        </Card.Header>
                                        <Card.Body className="cardBody">
                                          <div className="row">
                                            <div className="col-lg-12 addPartField">
                                              <div
                                                className="col-lg-12 addPartField"
                                                style={{
                                                  height: "221px",
                                                  overflowY: "auto",
                                                  overflowX: "hidden",
                                                }}
                                              >
                                                <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                  {t("upload_images")}
                                                  {/* {currentPart.part_name} */}
                                                </label>
                                                <div className="input-div">
                                                  <div className="avatar-upload">
                                                    <div className="avatar-edit">
                                                      <div className="input-div">
                                                        <input
                                                          ref={fileInputRef}
                                                          className="inputFile"
                                                          type="file"
                                                          id="imageuploadAbout"
                                                          onChange={
                                                            handleAboutFileChange
                                                          }
                                                          name="file"
                                                          accept=".jpg,.jpeg,.png"
                                                          multiple
                                                        />
                                                        <div className="partImages partImages_mob mt-2">
                                                          {images?.map(
                                                            (file, index) => {
                                                              return (
                                                                <>
                                                                  <div
                                                                    key={index}
                                                                    className="preview-item"
                                                                  >
                                                                    <div
                                                                      className="btn-Remove"
                                                                      style={{
                                                                        position:
                                                                          "absolute",
                                                                        width:
                                                                          "20px",
                                                                        height:
                                                                          "20px",
                                                                      }}
                                                                    >
                                                                      <i
                                                                        className="editImageBtn bi bi-pen-fill"
                                                                        style={{
                                                                          position:
                                                                            "absolute",
                                                                          top: "5px",
                                                                          cursor:
                                                                            "pointer",
                                                                          color:
                                                                            "blue",
                                                                        }}
                                                                        onClick={() =>
                                                                          handleImageSelect(
                                                                            file
                                                                          )
                                                                        }
                                                                      ></i>
                                                                      <img
                                                                        onClick={() =>
                                                                          toggleEnlarge(
                                                                            index
                                                                          )
                                                                        }
                                                                        className={`image ${
                                                                          enlargedIndexes.includes(
                                                                            index
                                                                          )
                                                                            ? "enlarged"
                                                                            : ""
                                                                        }`}
                                                                        src={
                                                                          previewUrl[
                                                                            index
                                                                          ]
                                                                        }
                                                                        alt={`Preview ${index}`}
                                                                        style={{
                                                                          width:
                                                                            "100px",
                                                                          margin:
                                                                            "5px",
                                                                          height:
                                                                            "100px",
                                                                          border:
                                                                            "1px solid",
                                                                          borderRadius:
                                                                            "5px",
                                                                          maxWidth:
                                                                            "100px",
                                                                          maxHeight:
                                                                            "100px",
                                                                        }}
                                                                      />

                                                                      <i
                                                                        className="removeImageBtn bi bi-x"
                                                                        style={{
                                                                          position:
                                                                            "absolute",
                                                                          top: "5px",
                                                                          right:
                                                                            "5px",
                                                                          cursor:
                                                                            "pointer",
                                                                          color:
                                                                            "red",
                                                                        }}
                                                                        onClick={() =>
                                                                          handleRemoveFile(
                                                                            index
                                                                          )
                                                                        }
                                                                      ></i>
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      </div>
                                                      <label htmlFor="imageuploadAbout"></label>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Card.Body>
                                      </Card>
                                    </div>
                                  </React.Fragment>

                                  <div className="d-flex flex-row mt-3 justify-content-end">
                                    {!isLastPart && (
                                      <Button
                                        className="me-2 mb-2 next-button"
                                        onClick={handleOpenSkip}
                                      >
                                        {t("skip")} & {t("next")}
                                      </Button>
                                    )}

                                    {!isLastPart && (
                                      <Button
                                        className="me-2 mb-2 btn-primary"
                                        type="submit"
                                      >
                                        {t("save")}& {t("next")}
                                      </Button>
                                    )}

                                    {!isLastPart && (
                                      <Button
                                        type="Button"
                                        className="btn btn-primary nxt-btn"
                                        onClick={handleImportModal}
                                      >
                                        Sort By Order{" "}
                                        <i class="bi bi-arrow-right"></i>
                                      </Button>
                                    )}
                                  </div>
                                  {isLastPart && (
                                    <>
                                      <div className="row">
                                        <div className="col">
                                          <Button
                                            type="button"
                                            className="back-btn ms-0 "
                                            onClick={handleBackButtonClick}
                                          >
                                            <i class="bi bi-arrow-left"></i>{" "}
                                            {t("back")}
                                          </Button>
                                        </div>
                                        <div className="col infobtns">
                                          {btnSaved === true ? (
                                            <button
                                              type="button"
                                              disabled={true}
                                              className="btn btn-primary"
                                            >
                                              Loading...
                                            </button>
                                          ) : (
                                            <Button
                                              className="mb-2 next-button"
                                              onClick={handleFinish}
                                              style={{ marginRight: "10px" }}
                                            >
                                              {t("save")}
                                              <i class="bi bi-save"></i>
                                            </Button>
                                          )}

                                          <Button
                                            type="Button"
                                            className="btn btn-primary nxt-btn"
                                            onClick={handleImportModal}
                                          >
                                            Sort By Order{" "}
                                            <i class="bi bi-arrow-right"></i>
                                          </Button>

                                          <Button
                                            className="mb-2 next-button"
                                            onClick={() =>
                                              // navigate("/parts-inventory")
                                              setCurrentStep(5)
                                            }
                                          >
                                            {t("finish")}{" "}
                                            <i class="bi bi-arrow-right"></i>
                                          </Button>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </Form>
                              </>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {currentStep === 4 && (
                      <AssignedDamageImage
                        partImageDataList={partImageDataList}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                      />
                    )}

                    {currentStep === 5 && (
                      <AddPartVideo
                        currentStep={currentStep}
                        currentPart={currentPart}
                        vehicleAddDetails={vehicleAddDetails}
                        setCurrentStep={setCurrentStep}
                        currentPartIndex={currentPartIndex}
                        setCurrentPartIndex={setCurrentPartIndex}
                        isLastPart={isLastPart}
                        showSkip={showSkip}
                        setShowSkip={setShowSkip}
                        setPartVideoDataList={setPartVideoDataList}
                        partVideoDataList={partVideoDataList}
                      />
                    )}

                    {currentStep === 6 && (
                      <AssignedDamageVideo
                        partVideoDataList={partVideoDataList}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </section>

        <Modal isOpen={showSkip}>
          <ModalHeader></ModalHeader>
          <ModalBody>
            <div className="row">Are you sure you want to skip this part?</div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={handleCloseSkip}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-x-lg"></i>
            </button>

            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={handleSkip}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Yes <i class="bi bi-x-lg"></i>
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modifyImportModal} toggle={handleImportCloseModal}>
          <ModalHeader toggle={handleImportCloseModal}>
            Sort By Order
          </ModalHeader>
          <ModalBody>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="fileList" direction="vertical">
                {(provided) => (
                  <div
                    className="file-list"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    {images.map((file, index) => (
                      <Draggable
                        key={file.id}
                        draggableId={file.id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            className="file-item"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              border: "1px solid #ddd",
                              padding: "10px",
                              borderRadius: "5px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={file.url}
                              alt="Preview"
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                                borderRadius: "5px",
                              }}
                            />
                            {/* <span>{file.file.name}</span> */}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleImportCloseModal}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSaveOrder}
            >
              {t("save_order")}
            </button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={partEtaiModify}>
          <ModalHeader>{`Reference Code`}</ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <FormGroup>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Reference Code</th>
                          <th>Product Label</th>
                          <th>{t("action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {partETAIRefDetails?.map((item, index) => (
                          <tr key={index}>
                            <td>{item?.reference_code}</td>
                            <td>{item?.product_label}</td>
                            <td>
                              <input
                                type="radio"
                                className="form-check-input"
                                name="radio-select" // Group all radio buttons with the same name
                                checked={
                                  selectedReferenceCode === item?.reference_code
                                } // Check if the current item is selected
                                onChange={() =>
                                  handleRadioChange(item?.reference_code)
                                } // Set the selected reference code when changed
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </FormGroup>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={handlePartEtaiClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Submit <i class="bi bi-check2"></i>
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={partEtaiPriceModify}>
          <ModalHeader>{`Price`}</ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <FormGroup>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Price</th>
                          <th>Product Label</th>
                        </tr>
                      </thead>
                      <tbody>
                        {partETAIPriceDetail &&
                        partETAIPriceDetail.length > 0 ? (
                          partETAIPriceDetail.map((item, index) => (
                            <tr key={index}>
                              <td>{item?.source}</td>
                              <td>{item?.label}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="2" className="text-center">
                              No record found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </FormGroup>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={handlePartEtaiPriceClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-check2"></i>
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modifyMasterPart} size="lg">
          <ModalHeader>
            {categoryMasterPart ? categoryMasterPart?.label : ""}
          </ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <div className="vehicleThBg table-responsive white-space-table">
                    <table className="rounded-table table table-striped table-bordered table-hover">
                      <thead>
                        <tr>
                          <th style={{ width: "10%" }}></th>

                          <th style={{ width: "10%" }}>Part Name</th>

                          <th style={{ width: "10%" }}>Year</th>

                          <th style={{ width: "0%" }}>Vehicle Make</th>
                          <th style={{ width: "0%" }}>Vehicle Modal</th>
                          <th style={{ width: "0%" }}>Vehicle Variant</th>
                        </tr>
                      </thead>
                      <tbody>
                        {categoryMasterPart?.part_list?.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedItemsCheckBox.some(
                                  (selected) => selected.index === index
                                )}
                                onChange={() =>
                                  handleCheckboxChangeMaster(index, item)
                                }
                              />
                            </td>
                            <td>
                              <div className="input-div SelectV">
                                <div className="col-md-12">
                                  {item?.namePartPT}
                                </div>
                              </div>
                            </td>

                            <td>{categoryPartsData?.vehicle_year}</td>

                            <td>{categoryPartsData?.vehicle_make_name}</td>

                            <td>{categoryPartsData?.vehicle_model_name}</td>

                            <td>{categoryPartsData?.vehicle_variant_name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-secondary"
              onClick={handleMasterPartClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-block btn-primary"
              style={{ width: "100px", marginTop: "10px" }}
              onClick={handleModalSubmit}
            >
              Submit
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modalStateHistory} size="lg">
          <ModalHeader>{`Part Details`}</ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <FormGroup>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Part Name</th>
                          <th>Make</th>
                          <th>Modal</th>
                          <th>Variant</th>
                          <th>Year</th>
                          <th>quantity</th>
                          <th>action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {catalogModalStateDataList?.map((item, index) => (
                          <tr key={index}>
                            <td>{item?.part_name}</td>
                            <td>{item?.vehicle_make_name}</td>
                            <td>{item?.vehicle_model_name}</td>
                            <td>{item?.vehicle_variant_name}</td>
                            <td>{item?.vehicle_year}</td>
                            <td>{item?.quantity}</td>
                            <td>
                              <div
                                style={{ cursor: "pointer" }}
                                className="edit-btn"
                                onClick={() =>
                                  window.open(
                                    `/part-details/${item.uuid}`,
                                    "_blank"
                                  )
                                }
                              >
                                <i class="bi bi-eye-fill"></i>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </FormGroup>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={handleClosePartHistory}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-check2"></i>
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modalSponsorPrice} size="lg">
          <ModalHeader>{`Statiscal Price`}</ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <FormGroup>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Price1 Avg</th>
                          <th>Price1 Max</th>
                          <th>Price1 Min</th>
                          <th>Price2 Min</th>
                          <th>Price2 Price Avg</th>
                          <th>Price2 Price Max</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sponsorPricDataList?.map((item, index) => (
                          <tr key={index}>
                            <td>€ {item?.price1_avg}</td>
                            <td>€ {item?.price1_max}</td>
                            <td>€ {item?.price1_min}</td>
                            <td>€ {item?.price2_min}</td>
                            <td>€ {item?.price2_price_avg}</td>
                            <td>€ {item?.price2_price_max}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </FormGroup>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={handleCloseSponsorPrice}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-check2"></i>
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modalEstimatePrice} size="lg">
          <ModalHeader>{`Estimate Price`}</ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <FormGroup>
                    <div
                      className="row"
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <h6
                        style={{
                          marginBottom: "-1px",
                          marginLeft: "-8px",
                        }}
                      >
                        Vehicle Model
                      </h6>
                      <div className="col-md-6">
                        Avg :
                        {estimatePricDataList?.stats?.stats_for_model
                          ?.price_avg || 0}
                      </div>

                      <div className="col-md-6">
                        Max :
                        {estimatePricDataList?.stats?.stats_for_model
                          ?.price_max || 0}
                      </div>

                      <div className="col-md-6">
                        Min :
                        {estimatePricDataList?.stats?.stats_for_model
                          ?.price_min || 0}
                      </div>

                      <div className="col-md-6">
                        Quantity. :
                        {estimatePricDataList?.stats?.stats_for_model
                          ?.quantity || 0}
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <h6
                        style={{
                          marginBottom: "-1px",
                          marginLeft: "-8px",
                        }}
                      >
                        Vehicle Variants
                      </h6>

                      <div className="col-md-6">
                        Avg :
                        {estimatePricDataList?.stats?.stats_for_variant
                          ?.price_avg || 0}
                      </div>

                      <div className="col-md-6">
                        Max :
                        {estimatePricDataList?.stats?.stats_for_variant
                          ?.price_max || 0}
                      </div>

                      <div className="col-md-6">
                        Min :
                        {estimatePricDataList?.stats?.stats_for_variant
                          ?.price_min || 0}
                      </div>

                      <div className="col-md-6">
                        Quantity. :
                        {estimatePricDataList?.stats?.stats_for_variant
                          ?.quantity || 0}
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <h6
                        style={{
                          marginBottom: "-1px",
                          marginLeft: "-8px",
                        }}
                      >
                        Part/Model
                      </h6>
                      <div className="col-md-6">
                        Avg :
                        {estimatePricDataList?.stats?.stats_for_part_and_model
                          ?.price_avg || 0}
                      </div>

                      <div className="col-md-6">
                        Max :
                        {estimatePricDataList?.stats?.stats_for_part_and_model
                          ?.price_max || 0}
                      </div>

                      <div className="col-md-6">
                        Min :
                        {estimatePricDataList?.stats?.stats_for_part_and_model
                          ?.price_min || 0}
                      </div>

                      <div className="col-md-6">
                        Quantity. :
                        {estimatePricDataList?.stats?.stats_for_part_and_model
                          ?.quantity || 0}
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <h6
                        style={{
                          marginBottom: "-1px",
                          marginLeft: "-8px",
                        }}
                      >
                        {" "}
                        Engine Code
                      </h6>
                      <div className="col-md-6">
                        Avg :
                        {estimatePricDataList?.stats?.stats_for_engine_code
                          ?.price_avg || 0}
                      </div>

                      <div className="col-md-6">
                        Max :
                        {estimatePricDataList?.stats?.stats_for_engine_code
                          ?.price_max || 0}
                      </div>

                      <div className="col-md-6">
                        Min :
                        {estimatePricDataList?.stats?.stats_for_engine_code
                          ?.price_min || 0}
                      </div>

                      <div className="col-md-6">
                        Quantity. :
                        {estimatePricDataList?.stats?.stats_for_engine_code
                          ?.quantity || 0}
                      </div>
                    </div>
                  </FormGroup>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={handleCloseEstimatePrice}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-check2"></i>
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modalRefCodeCondition} size="lg">
          <ModalHeader>{`Part Details`}</ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <FormGroup>
                    <table className="table table-bordered table-striped align-middle">
                      <thead className="table-dark">
                        <tr>
                          <th>Ref Code</th>
                          <th>Price1 Avg</th>
                          <th>Price1 Max</th>
                          <th>Price1 Min</th>
                          <th>Price2 Min</th>
                          <th>Price2 Avg</th>
                          <th>Price2 Max</th>
                          {/* <th>View</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {refCodeConditionDataList?.partsList?.map(
                          (part, index) => (
                            <tr key={index}>
                              <td>
                                <strong>{part.ref_code}</strong>
                              </td>
                              <td>€ {part?.prices?.price1_avg || 0}</td>
                              <td>€ {part?.prices?.price1_max || 0}</td>
                              <td>€ {part?.prices?.price1_min || 0}</td>
                              <td>€ {part?.prices?.price2_min || 0}</td>
                              <td>€ {part?.prices?.price2_price_avg || 0}</td>
                              <td>€ {part?.prices?.price2_price_max || 0}</td>
                              {/* <td>
                                <div
                                  style={{ cursor: "pointer" }}
                                  className="text-primary"
                                  // onClick={() =>
                                  //   window.open(
                                  //     `/part-details/${part.uuid}`,
                                  //     "_blank"
                                  //   )
                                  // }
                                >
                                  <i className="bi bi-eye-fill fs-5"></i>
                                </div>
                              </td> */}
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </FormGroup>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={handleCloseRefCodeCondition}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-check2"></i>
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={isModalOpen} toggle={closeModal} size="lg">
          <Form method="post">
            <ModalHeader toggle={closeModal}>Image Edit</ModalHeader>
            {selectedImage && (
              <ModalBody>
                <div className="modal-body" style={{ marginBottom: "100px" }}>
                  <div
                    style={{
                      width: "100%",
                      height: "400px",
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        display: "inline-block",
                        width: "100%",
                        height: "394px",
                      }}
                    >
                      <ReactCrop
                        crop={crop}
                        onImageLoaded={onImageLoad}
                        onChange={(newCrop) => setCrop(newCrop)}
                        onComplete={(c) => setCompletedCrop(c)}
                        disabled={!isCropping}
                      >
                        <img
                          src={selectedImage.url}
                          ref={imgRef}
                          style={{
                            transform: `rotate(${rotation}deg)`,
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "cover",
                            height: "394px",
                            display: selectedImage ? "block" : "none",
                          }}
                          alt="Crop preview"
                          onClick={onImageClick}
                        />
                      </ReactCrop>
                      {isDrawing && !isCropping && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <ReactSketchCanvas
                            ref={canvasRef}
                            style={{
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              top: 0,
                              left: 0,
                            }}
                            canvasColor="transparent"
                            allowOnlyPointerType="all"
                          />
                        </div>
                      )}
                    </div>

                    <div className="row" style={{ marginTop: "40px" }}>
                      <div className="col-md-2">
                        <i
                          class="bi bi-crop"
                          style={{
                            fontSize: "22px",
                            cursor: "pointer",
                            color: isCropping ? "blue" : "black",
                          }}
                          onClick={handleCrop}
                        ></i>
                      </div>

                      <div className="col-md-2">
                        <i
                          className="bi bi-brush"
                          style={{
                            fontSize: "22px",
                            cursor: "pointer",
                            color: isDrawing ? "blue" : "black",
                          }}
                          onClick={() => saveDrawing(selectedImage.id)}
                        ></i>
                      </div>

                      <div className="col-md-2">
                        <i
                          class="bi bi-arrow-counterclockwise"
                          style={{ fontSize: "22px", cursor: "pointer" }}
                          onClick={() => handleRotate(selectedImage.id)}
                        ></i>
                      </div>

                      <div className="col-md-2">
                        <i
                          class="bi bi-image"
                          style={{ fontSize: "22px", cursor: "pointer" }}
                          onClick={() =>
                            handleRemoveBgImageeForm(selectedImage.id)
                          }
                          disabled={isLoading}
                        ></i>
                      </div>

                      <div className="col-md-2">
                        <i
                          class="bi bi-image"
                          style={{ fontSize: "22px", cursor: "pointer" }}
                          onClick={(e) =>
                            handleAddBgImageForm(selectedImage.id)
                          }
                          disabled={isLoadings}
                        ></i>
                      </div>

                      <div className="col-md-2">
                        <i
                          class="bi bi-paint-bucket"
                          style={{ fontSize: "22px", cursor: "pointer" }}
                          onClick={() =>
                            handleAddBgColoreForm(selectedImage.id)
                          }
                        ></i>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-2">
                        <span>Crop</span>
                      </div>

                      <div className="col-md-2">
                        <span>Brush</span>
                      </div>

                      <div className="col-md-2">
                        <span>Rotate</span>
                      </div>

                      <div className="col-md-2">
                        <span>
                          {isLoading ? "Processing..." : "Remove Back"}
                        </span>
                      </div>

                      <div className="col-md-2">
                        <span>
                          {" "}
                          {isLoadings ? "Processing..." : "Add Back"}
                        </span>
                      </div>

                      <div className="col-md-2">
                        <span>
                          {isLoadingColor ? "Processing..." : "Add Color"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalBody>
            )}

            <ModalFooter>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={getCroppedImage}
              >
                Apply Changes
              </button>
            </ModalFooter>
          </Form>
        </Modal>

        <Modal isOpen={modalRefCodeEstimate} size="lg">
          <ModalHeader>{`Estimate Price`}</ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <FormGroup>
                    <div
                      className="row"
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <h6
                        style={{
                          marginBottom: "-1px",
                          marginLeft: "-8px",
                        }}
                      >
                        Vehicle Model
                      </h6>
                      <div className="col-md-6">
                        Avg :
                        {refCodeEstimateDataList?.stats?.stats_for_model
                          ?.price_avg || 0}
                      </div>

                      <div className="col-md-6">
                        Max :
                        {refCodeEstimateDataList?.stats?.stats_for_model
                          ?.price_max || 0}
                      </div>

                      <div className="col-md-6">
                        Min :
                        {refCodeEstimateDataList?.stats?.stats_for_model
                          ?.price_min || 0}
                      </div>

                      <div className="col-md-6">
                        Quantity. :
                        {refCodeEstimateDataList?.stats?.stats_for_model
                          ?.quantity || 0}
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <h6
                        style={{
                          marginBottom: "-1px",
                          marginLeft: "-8px",
                        }}
                      >
                        Vehicle Variants
                      </h6>

                      <div className="col-md-6">
                        Avg :
                        {refCodeEstimateDataList?.stats?.stats_for_variant
                          ?.price_avg || 0}
                      </div>

                      <div className="col-md-6">
                        Max :
                        {refCodeEstimateDataList?.stats?.stats_for_variant
                          ?.price_max || 0}
                      </div>

                      <div className="col-md-6">
                        Min :
                        {refCodeEstimateDataList?.stats?.stats_for_variant
                          ?.price_min || 0}
                      </div>

                      <div className="col-md-6">
                        Quantity. :
                        {refCodeEstimateDataList?.stats?.stats_for_variant
                          ?.quantity || 0}
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <h6
                        style={{
                          marginBottom: "-1px",
                          marginLeft: "-8px",
                        }}
                      >
                        Part/Model
                      </h6>
                      <div className="col-md-6">
                        Avg :
                        {refCodeEstimateDataList?.stats
                          ?.stats_for_part_and_model?.price_avg || 0}
                      </div>

                      <div className="col-md-6">
                        Max :
                        {refCodeEstimateDataList?.stats
                          ?.stats_for_part_and_model?.price_max || 0}
                      </div>

                      <div className="col-md-6">
                        Min :
                        {refCodeEstimateDataList?.stats
                          ?.stats_for_part_and_model?.price_min || 0}
                      </div>

                      <div className="col-md-6">
                        Quantity. :
                        {refCodeEstimateDataList?.stats
                          ?.stats_for_part_and_model?.quantity || 0}
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <h6
                        style={{
                          marginBottom: "-1px",
                          marginLeft: "-8px",
                        }}
                      >
                        {" "}
                        Engine Code
                      </h6>
                      <div className="col-md-6">
                        Avg :
                        {refCodeEstimateDataList?.stats?.stats_for_engine_code
                          ?.price_avg || 0}
                      </div>

                      <div className="col-md-6">
                        Max :
                        {refCodeEstimateDataList?.stats?.stats_for_engine_code
                          ?.price_max || 0}
                      </div>

                      <div className="col-md-6">
                        Min :
                        {refCodeEstimateDataList?.stats?.stats_for_engine_code
                          ?.price_min || 0}
                      </div>

                      <div className="col-md-6">
                        Quantity. :
                        {refCodeEstimateDataList?.stats?.stats_for_engine_code
                          ?.quantity || 0}
                      </div>
                    </div>
                  </FormGroup>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={handleCloseRefCodeEstimate}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-check2"></i>
            </button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default AddForParts;
