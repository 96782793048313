import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import Select from "react-select";
import {
  WareHouseEntitySerach,
  WareHouseSerachList,
  createWareHouse,
  deleteWareHouse,
  getWareHouseList,
  updateWareHouse,
} from "../../services/WareHouseServices";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Barcode from "react-barcode";

const WareHouseMain = () => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const { t } = useTranslation();

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [wareHouseList, setWareHouseList] = useState([]);

  const [delModal, setDelModal] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [delpart, setDelPart] = useState("");
  const [modifyModal, setModifyModal] = useState(false);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [wareHouseEntityData, setWareHouseEntityData] = useState({
    id: "",
    uuid: "",
    entity_type_id: "",
    warehouse_name: "",
    parent_id: "",
  });

  let errorsObj = {
    warehouse_name: "",
    entity_type_id: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const [entityList, setEntityList] = useState([]);
  const [parentList, setParentList] = useState([]);

  const [modifyBarcodeModal, setModifyBarcodeModal] = useState(false);
  const [BarcodeData, setBarcodeData] = useState("");

  const handleBarcodeClose = () => {
    setModifyBarcodeModal(false);
  };

  const handleBarcodeOpen = (e, item) => {
    setBarcodeData(item);
    setModifyBarcodeModal(true);
  };

  const handleAddModal = () => {
    setModifyModal(true);
  };

  const handleCloseModal = () => {
    setModifyModal(false);
    setWareHouseEntityData("");
    setErrors({
      warehouse_name: "",
      entity_type_id: "",
    });
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
    };
    WareHouseEntitySerach(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].entity_name,
          });
        }

        setEntityList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(
    function () {
      if (wareHouseEntityData?.entity_type_id) {
        let data = {
          limit: 10,
          page: 1,
          search: "",
          id: wareHouseEntityData?.entity_type_id,
        };
        WareHouseSerachList(data)
          .then((response) => {
            response = response.data;
            let list = [];
            for (let i = 0; i < response.data.length; i++) {
              list.push({
                value: response.data[i].id,
                label: response.data[i].warehouse_name,
              });
            }

            setParentList(list);
            toast.success(response.data.message);
          })
          .catch((err) => {
            toast.error(Utils.getErrorMessage(err));
            setParentList([]);
            // setWareHouseEntityData({
            //   entity_type_id: "",
            //   warehouse_name: "",
            //   parent_id: "",
            // });
          });
      }
    },
    [wareHouseEntityData]
  );

  const fetchWareHouse = () => {
    let data = { ...params };
    getWareHouseList(data)
      .then((response) => {
        setWareHouseList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };

      fetchWareHouse(data);
    },
    [params]
  );

  const onDeleteModalClose = () => {
    setDelModal(false);
  };

  const onEditPartGroup = (e, item) => {
    setModifyModal(true);
    setWareHouseEntityData({
      id: item?.id,
      uuid: item?.uuid,
      entity_type_id: item?.entity_type_id,
      warehouse_name: item?.warehouse_name,
      parent_id: item?.parent_id,
    });
    setErrors({
      warehouse_name: "",
      entity_type_id: "",
    });
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelPart(item.id);
    setDelModal(true);
  };

  const onDestroyPartType = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { id: delpart };
    deleteWareHouse(data)
      .then((response) => {
        let data = { ...params };

        fetchWareHouse(data);
        toast.success(response.data.message);
        setDelPart("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleWarehoueEntity = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };
    if (!wareHouseEntityData.entity_type_id) {
      errorObj.entity_type_id = `Entity type  required.`;
      error = true;
    }

    if (!wareHouseEntityData.warehouse_name) {
      errorObj.warehouse_name = `Warehouse name required.`;
      error = true;
    }

    setErrors(errorObj);
    Utils.focusError();

    if (error) return;

    setIsModifyButtonLoad(true);
    let data = {
      warehouse_name: wareHouseEntityData.warehouse_name,
      entity_type_id: wareHouseEntityData.entity_type_id,
      parent_id: wareHouseEntityData?.parent_id
        ? wareHouseEntityData?.parent_id
        : null,
    };

    let updateData = {
      uuid: wareHouseEntityData?.uuid,
      warehouse_name: wareHouseEntityData.warehouse_name,
      entity_type_id: wareHouseEntityData.entity_type_id,
      parent_id: wareHouseEntityData?.parent_id
        ? wareHouseEntityData?.parent_id
        : null,
    };

    if (wareHouseEntityData?.uuid) {
      updateWareHouse(updateData)
        .then((response) => {
          toast.success(response.data.message);
          let data = { ...params };
          fetchWareHouse(data);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
          setWareHouseEntityData({
            id: "",
            uuid: "",
            entity_type_id: "",
            warehouse_name: "",
            parent_id: "",
          });
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    } else {
      createWareHouse(data)
        .then((response) => {
          toast.success(response.data.message);
          let data = { ...params };
          fetchWareHouse(data);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
          setWareHouseEntityData({
            id: "",
            uuid: "",
            entity_type_id: "",
            warehouse_name: "",
            parent_id: "",
          });
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    }
  };

  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid ">
            <div className="row mb-2">
              <div className="col-sm-6"></div>
              <div className="col-sm-6"></div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card fuelCard container-custom-height">
                  <div className="card-header">
                    <div
                      className="card-title m-0 float-left"
                      style={{ width: "50%" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        value={params.search}
                        onChange={(e) => handleParams(e, "search")}
                        placeholder="Search"
                      ></input>
                    </div>
                    <div className="card-title m-0 float-right d-flex">
                      <button
                        type="button"
                        className="btn  btn-icon-text mr-2"
                        onClick={handleAddModal}
                      >
                        <i className="bi bi-plus-lg"></i>
                        <span className="ms-2">{t("Add")}</span>
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="vehicleThBg table-responsive table-responsive-vehicle">
                      <table className="table table-bordered rounded-table table table-striped table-hover">
                        <thead>
                          <tr>
                            <th style={{ width: "1%" }}>#</th>
                            <th
                              className="col-form-label ml-2 Label-my form-label form-label"
                              style={{ width: "10%" }}
                            >
                              Entity Name
                            </th>
                            <th
                              className="col-form-label ml-2 Label-my form-label form-label"
                              style={{ width: "10%" }}
                            >
                              WareHouse Name
                            </th>
                            <th
                              className="col-form-label ml-2 Label-my form-label form-label"
                              style={{ textAlign: "center", width: "0%" }}
                            >
                              {t("action")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {wareHouseList?.map((item, index) => {
                            return (
                              <>
                                <tr>
                                  <td>{++index}</td>
                                  <td>
                                    <div>{item.entity_name}</div>
                                  </td>
                                  <td>
                                    <div>{item.warehouse_name}</div>
                                  </td>

                                  <td className="d-flex">
                                    <Dropdown
                                      className="iconDropdown"
                                      onToggle={(isOpen) => {
                                        if (isOpen) {
                                          // This logic runs when the dropdown is opened
                                          const tableResponsive =
                                            document.querySelector(
                                              ".table-responsive"
                                            );
                                          if (tableResponsive) {
                                            tableResponsive.style.overflowX =
                                              "inherit !important";
                                            tableResponsive.style.overflowY =
                                              "inherit !important";
                                          }
                                        }
                                      }}
                                    >
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                        className="iconToggle"
                                      >
                                        <i className="bi bi-three-dots-vertical"></i>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          className="d-flex"
                                          onClick={(e) =>
                                            onEditPartGroup(e, item)
                                          }
                                        >
                                          <div className="edit-btn">
                                            <i className="bi bi-pencil"></i>
                                          </div>

                                          <span>Edit</span>
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                          className="d-flex"
                                          onClick={(e) =>
                                            onDeleteModalOpen(e, item)
                                          }
                                        >
                                          <div className="trash-btn delete-btn">
                                            <i className="bi bi-trash3"></i>
                                          </div>

                                          <span>Delete</span>
                                        </Dropdown.Item>

                                        <Dropdown.Item
                                          className="d-flex"
                                          onClick={(e) =>
                                            handleBarcodeOpen(e, item)
                                          }
                                        >
                                          <div className="edit-btn">
                                            <i class="bi bi-upc-scan"></i>
                                          </div>

                                          <span>View Barcode</span>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                        {wareHouseList && wareHouseList?.length === 0 && (
                          <tr>
                            <td colSpan={7} className={`text-center`}>
                              {" "}
                              {t("no_record_found")}
                            </td>
                          </tr>
                        )}
                      </table>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
              </div>
            </div>
          </div>
          <div className="fuelCF">
            <div className="card-footer clearfix card-footer-part ">
              <div className="pagination pagination-sm m-0 float-right">
                <Pagination
                  activePage={params.page}
                  itemsCountPerPage={parseInt(params.limit)}
                  totalItemsCount={parseInt(totalRecords)}
                  pageRangeDisplayed={5}
                  itemClass={`page-item`}
                  linkClass={`page-link`}
                  onChange={(e) => handleParams(e, "pagination")}
                />
              </div>
            </div>
          </div>
        </section>
      </div>

      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete WareHouse`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this WareHouse?`}</p>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDelButtonLoad}
            onClick={onDeleteModalClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>

          {!isDelButtonLoad && (
            <button
              type="button"
              className="btn btn-block bg-red"
              style={{ width: "100px" }}
              onClick={onDestroyPartType}
            >
              Delete <i class="bi bi-trash3"></i>
            </button>
          )}
          {isDelButtonLoad && (
            <button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
            </button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyModal}>
        <ModalHeader>Warehouse</ModalHeader>
        <Form method={`post`} onSubmit={handleWarehoueEntity}>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my form-label form-label">
                    {"Select Entity"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <Select
                        options={entityList}
                        value={entityList.find(
                          (option) =>
                            option.value ===
                            Number(wareHouseEntityData.entity_type_id)
                        )}
                        onChange={(selectedOption) =>
                          setWareHouseEntityData({
                            ...wareHouseEntityData,
                            entity_type_id: selectedOption.value,
                            entity_name: selectedOption.label,
                          })
                        }
                        placeholder={"Select Entity"}
                      />
                    </div>
                    {errors.entity_type_id && (
                      <span className="input-error">
                        {errors.entity_type_id}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>

              {parentList?.length > 0 && (
                <div className="col-md-12">
                  <FormGroup>
                    <Label className="col-form-label ml-2 Label-my form-label form-label">
                      {"Parent Location"}
                    </Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <Select
                          options={parentList}
                          value={parentList.find(
                            (option) =>
                              option.value ===
                              Number(wareHouseEntityData.parent_id)
                          )}
                          onChange={(selectedOption) =>
                            setWareHouseEntityData({
                              ...wareHouseEntityData,
                              parent_id: selectedOption.value,
                            })
                          }
                          placeholder={"Select "}
                        />
                      </div>
                    </div>
                  </FormGroup>
                </div>
              )}

              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {t("name")}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={wareHouseEntityData.warehouse_name}
                        onChange={(e) => {
                          setWareHouseEntityData({
                            ...wareHouseEntityData,
                            warehouse_name: e.target.value,
                          });
                        }}
                        name={`warehouse_name`}
                        maxLength={50}
                        placeholder="Name"
                      />
                    </div>
                    {errors.warehouse_name && (
                      <span className="input-error">
                        {errors.warehouse_name}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-primary"
              disabled={isModifyButtonLoad}
              onClick={handleCloseModal}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-x-lg"></i>
            </button>
            {!isModifyButtonLoad && (
              <button
                type="submit"
                class="btn btn-block btn-primary"
                style={{ width: "100px", marginTop: "10px" }}
              >
                Submit <i class="bi bi-check2"></i>
              </button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modifyBarcodeModal}>
        <ModalHeader>{`Barcode`}</ModalHeader>
        <ModalBody>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Barcode
              value={`${BarcodeData.warehouse_name}:${BarcodeData.id}`}
              width={5}
              height={100}
              displayValue={false}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            onClick={handleBarcodeClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default WareHouseMain;
