import axiosInstance from "./AxiosInstance";

export function actionSettingAdd(data) {
  return axiosInstance.post(`/company/setting-update`, data);
}

export function actionSettingCompanyAdd(data) {
  return axiosInstance.post(`/setting-update`, data);
}

export function deleteCompanySetting(data) {
  return axiosInstance.post(`/setting-delete`, data);
}

export function getSettingsDetails(data) {
  return axiosInstance.post(`/company/setting-details`, data);
}

export function getSettingsCompanyDetails(data) {
  return axiosInstance.post(`/setting-details`, data);
}

export function actionAddContactUs(data) {
  return axiosInstance.post(`/company/contact`, data);
}

export function actionAddSubscribe(data) {
  return axiosInstance.post(`/company-subscribe`, data);
}

export function getCommonSearchList(data) {
  return axiosInstance.post(`/common-search-list`, data);
}

export function getCompanyList(data) {
  return axiosInstance.post(`/company/list`, data);
}

export function getCompanyPlanChange(data) {
  return axiosInstance.post(`/company/update-plan`, data);
}
