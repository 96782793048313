import React, { useState, useEffect } from "react";
import axios from "axios";

const PrintLabel = () => {
  const [printers, setPrinters] = useState([]);
  const [formData, setFormData] = useState({
    printerName: "",
    companyName: "TechnoBrains",
    baleID: "BL123456",
    weight: "250",
    date: new Date().toISOString().split("T")[0],
    printerType: "zebra",
  });

  const handlePrintZebra = () => {
    if (!window.BrowserPrint) {
      alert(
        "Zebra BrowserPrint SDK not loaded. Please make sure Zebra BrowserPrint service is running."
      );
      return;
    }

    window.BrowserPrint.getDefaultDevice("printer", (device) => {
      if (!device) {
        alert("No Zebra printer found");
        return;
      }

      const zpl = `
        ^XA
        ^FO50,50^ADN,36,20^FDHello from React!^FS
        ^FO50,100^BCN,100,Y,N,N
        ^FD1234567890^FS
        ^XZ
      `;

      device.send(zpl, (res) => {
        if (res === true) {
          alert("Printed successfully!");
        } else {
          alert("Print failed.");
        }
      });
    });
  };

  const sendPrintCommand = async () => {
    if ("usb" in navigator) {
      try {
        const device = await navigator.usb.requestDevice({
          filters: [{ vendorId: 0x0525, productId: 0xa701 }],
        });

        if (!device) return alert("No device selected.");

        await device.open();
        if (device.configuration === null) {
          await device.selectConfiguration(1);
        }
        await device.claimInterface(0);

        const encoder = new TextEncoder();
        const data = encoder.encode("Hello, SEWOO printer!\n\n\n");

        await device.transferOut(1, data);

        alert("Print sent successfully!");
      } catch (error) {
        if (error.name === "NotFoundError") {
          alert("No printer selected.");
        } else {
          console.error("Print error:", error);
          alert("Print error: " + error.message);
        }
      }
    } else {
      alert("WebUSB not supported in this browser.");
    }
  };

  //   useEffect(() => {
  //     axios
  //       .get("https://api.tp.software/api/v1/get-printer")
  //       .then((res) => setPrinters(res.data));
  //   }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePrint = async () => {
    try {
      const res = await fetch("http://192.168.4.7:8000/api/v1/print", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ text: "Hello from React to SEWOO printer!" }),
      });

      const data = await res.json();
      if (data.success) {
        alert("Print sent successfully!");
      } else {
        alert("Print failed: " + data.message);
      }
    } catch (err) {
      console.error(err);
      alert("Error connecting to printer server.");
    }
  };

  return (
    <div className="container">
      <h1>Print Bale Label</h1>

      <label>Printer:</label>
      <select
        name="printerName"
        value={formData.printerName}
        onChange={handleChange}
      >
        <option value="">Select a Printer</option>
        {printers.map((printer) => (
          <option key={printer} value={printer}>
            {printer}
          </option>
        ))}
      </select>

      <label>Company Name:</label>
      <input
        type="text"
        name="companyName"
        value={formData.companyName}
        onChange={handleChange}
      />

      <label>Bale ID:</label>
      <input
        type="text"
        name="baleID"
        value={formData.baleID}
        onChange={handleChange}
      />

      <label>Weight (kg):</label>
      <input
        type="number"
        name="weight"
        value={formData.weight}
        onChange={handleChange}
      />

      <label>Date:</label>
      <input
        type="date"
        name="date"
        value={formData.date}
        onChange={handleChange}
      />

      <label>Printer Type:</label>
      <select
        name="printerType"
        value={formData.printerType}
        onChange={handleChange}
      >
        <option value="zebra">Zebra</option>
        <option value="brother">Brother</option>
      </select>

      <button onClick={handlePrint}>Print from React</button>
      <button onClick={sendPrintCommand}>Print via USB</button>
      <button onClick={handlePrintZebra}>Print zebra</button>
    </div>
  );
};

export default PrintLabel;
