import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";

import ConfigDB from "../../config";
import { getCatalogList } from "../../services/PartsInventory";
import {
  getvehicleMakeAllList,
  getvehicleYearList,
} from "../../services/VehicleService";
import { getVehicleAllModalSerachList } from "../../services/VehicleModelService";
import { getVehicleSerachVariantList } from "../../services/VehicleVariantsService";
import Table from "react-bootstrap/Table";
import {
  actionAddDismental,
  actionDetailsDismental,
  actionUpdateDismental,
} from "../../services/DismentalServices";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useTranslation } from "react-i18next";

const AddDistmental = () => {
  const tableLengthList = ConfigDB?.data?.dataTableLength;
  const { uuid } = useParams();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [categoryPartList, setCategoryPartList] = useState([]);
  const [inputSelctedValue, setInputSelctedValue] = useState("");
  const [vehicleYearPartList, setvehicleYearPartList] = useState([]);
  const [inputSelctedYearValue, setInputSelctedYearValue] = useState("");
  const [vehicleMakePartList, setvehicleMakePartList] = useState([]);
  const [inputSelctedMakeValue, setInputSelctedMakeValue] = useState("");
  const [vehicleModalPartList, setvehicleModalPartList] = useState([]);
  const [inputSelctedModalValue, setInputSelctedModalValue] = useState("");
  const [vehicleVariantPartList, setvehicleVariantPartList] = useState([]);
  const [inputSelctedVariantValue, setInputSelctedVariantValue] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [categoryPartsData, setCategoryPartsData] = useState({
    part_catalog_id: "",
    namePartPT: "",
    year_id: "",
    vehicle_variant_name: "",
    vehicle_make_name: "",
    vehicle_model_name: "",
    make_id: "",
    model_id: "",
    variants_id: "",
  });

  const [modifyMasterPart, setModifyMasterPart] = useState(false);

  const [distmetalData, setDistmetalData] = useState({
    id: "",
    name: "",
  });
  const [DistmentalDetails, setDistmentalDetails] = useState({});

  const [categoryMasterPart, setCategoryMasterPart] = useState({});
  const [selectedRows, setSelectedRows] = useState(
    categoryMasterPart?.part_list?.map((item) => ({
      year_id: null,
      make_id: null,
      model_id: null,
      variants_id: null,
      year_id: "",
      vehicle_variant_name: "",
      vehicle_make_name: "",
      vehicle_model_name: "",
      namePartPT: item?.namePartPT,
      part_catalog_id: item?.reference_id,
      master_part_id: item?.parts_catalog_id,
    })) || []
  );

  const [selectedItemsCheckBox, setSelectedItemsCheckBox] = useState([]);

  const handleCheckboxChangeMaster = (index, item) => {
    setSelectedItemsCheckBox((prevSelectedItems) => {
      if (prevSelectedItems.some((selected) => selected.index === index)) {
        return prevSelectedItems.filter((selected) => selected.index !== index);
      } else {
        return [...prevSelectedItems, { index, item }];
      }
    });
  };

  useEffect(() => {
    if (
      categoryMasterPart?.part_list &&
      selectedRows.length !== categoryMasterPart?.part_list.length
    ) {
      setSelectedRows(
        categoryMasterPart?.part_list?.map((item) => ({
          year_id: null,
          make_id: null,
          model_id: null,
          variants_id: null,
          year_id: "",
          vehicle_variant_name: "",
          vehicle_make_name: "",
          vehicle_model_name: "",
          namePartPT: item?.namePartPT,
          part_catalog_id: item?.reference_id,
          master_part_id: item?.parts_catalog_id,
        }))
      );
    }
  }, [categoryMasterPart?.part_list]);

  const handleMasterPartClose = () => {
    setModifyMasterPart(false);
  };

  useEffect(() => {
    let data = {
      id: uuid,
    };
    actionDetailsDismental(data)
      .then((response) => {
        setDistmentalDetails(response.data.data);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  }, []);

  useEffect(() => {
    if (DistmentalDetails) {
      setDistmetalData({
        id: DistmentalDetails?.id,
        name: DistmentalDetails?.name,
      });
      setSelectedItems(DistmentalDetails?.part_list || []);
    }
  }, [DistmentalDetails]);

  const handleSelectWithoutChangeCategoryList = (option) => {
    setCategoryPartsData((prevData) => ({
      ...prevData,
      part_catalog_id: option.value,
      namePartPT: option?.label,
    }));
    setCategoryMasterPart(option);
  };

  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };

    getCatalogList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].namePartPT,
            is_master_part: response.data[i].is_master_part,
            part_list: response.data[i].part_list,
          });
        }
        setCategoryPartList(list);
      })
      .catch((err) => {});
  }, []);

  const handleInputSelectedChangeCategoryList = (inputSelctedValue) => {
    setInputSelctedValue(inputSelctedValue);

    if (inputSelctedValue.length > 0) {
      const obj = {
        search: inputSelctedValue,
        limit: tableLengthList[0],
        page: 1,
      };

      getCatalogList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].namePartPT,
              is_master_part: response.data[i].is_master_part,
              part_list: response.data[i].part_list,
            });
          }
          setCategoryPartList(list);
        })
        .catch((err) => {});
    }
  };
  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };

    getvehicleYearList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i],
            label: response.data[i],
          });
        }
        setvehicleYearPartList(list);
      })
      .catch((err) => {});
  }, []);

  const handleInputSelectedChangeYearList = (inputSelctedYearValue) => {
    setInputSelctedYearValue(inputSelctedYearValue);

    const obj = {
      search: inputSelctedYearValue,
      limit: tableLengthList[0],
      page: 1,
    };

    getvehicleYearList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i],
            label: response.data[i],
          });
        }
        setvehicleYearPartList(list);
      })
      .catch((err) => {});
  };

  const handleSelectWithoutChangeYearList = (option) => {
    setCategoryPartsData((prevData) => ({
      ...prevData,
      year_id: option.value,
      year_id: option.label,
      make_id: "",
      model_id: "",
      variants_id: "",
    }));
    setvehicleMakePartList([]);
    setvehicleModalPartList([]);
    setvehicleVariantPartList([]);
  };

  useEffect(
    function () {
      if (categoryPartsData?.year_id) {
        var obj = {
          search: "",
          limit: tableLengthList[0],
          page: 1,
          year: categoryPartsData?.year_id,
        };

        getvehicleMakeAllList(obj)
          .then((response) => {
            response = response.data;
            let list = [];
            for (let i = 0; i < response.data.length; i++) {
              list.push({
                value: response.data[i].id,
                label: response.data[i].description,
              });
            }
            setvehicleMakePartList(list);
          })
          .catch((err) => {});
      }
    },

    [categoryPartsData?.year_id]
  );

  const handleInputSelectedChangeMakeList = (inputSelctedMakeValue) => {
    setInputSelctedMakeValue(inputSelctedMakeValue);
    if (inputSelctedMakeValue?.length > 0) {
      const obj = {
        search: inputSelctedMakeValue,
        limit: tableLengthList[0],
        page: 1,
        year: categoryPartsData?.year_id,
      };

      getvehicleMakeAllList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].description,
            });
          }
          setvehicleMakePartList(list);
        })
        .catch((err) => {});
    }
  };

  const handleSelectWithoutChangeMakeList = (option) => {
    setCategoryPartsData((prevData) => ({
      ...prevData,
      make_id: option.value,
      vehicle_make_name: option.label,
      // model_id: "",
      // variants_id: "",
    }));
    setvehicleModalPartList([]);
    setvehicleVariantPartList([]);
  };

  useEffect(
    function () {
      if (categoryPartsData?.make_id) {
        var obj = {
          search: "",
          limit: tableLengthList[0],
          page: 1,
          vehicle_year: categoryPartsData?.year_id,
          make_id: categoryPartsData?.make_id,
        };

        getVehicleAllModalSerachList(obj)
          .then((response) => {
            response = response.data;
            let list = [];
            for (let i = 0; i < response.data.length; i++) {
              list.push({
                value: response.data[i].id,
                label: response.data[i].description,
              });
            }
            setvehicleModalPartList(list);
          })
          .catch((err) => {});
      }
    },
    [categoryPartsData?.make_id]
  );

  const handleInputSelectedChangeModelList = (inputSelctedModalValue) => {
    setInputSelctedModalValue(inputSelctedModalValue);
    if (inputSelctedModalValue.length > 0) {
      const obj = {
        search: inputSelctedModalValue,
        limit: tableLengthList[0],
        page: 1,
        vehicle_year: categoryPartsData?.year_id,
        make_id: categoryPartsData?.make_id,
      };

      getVehicleAllModalSerachList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].model_name,
            });
          }
          setvehicleModalPartList(list);
        })
        .catch((err) => {});
    }
  };

  const handleSelectWithoutChangeModelList = (option) => {
    setCategoryPartsData((prevData) => ({
      ...prevData,
      model_id: option.value,
      vehicle_model_name: option.label,
      variants_id: "",
    }));
    setvehicleVariantPartList([]);
  };

  useEffect(
    function () {
      if (categoryPartsData?.model_id) {
        var obj = {
          search: "",
          limit: tableLengthList[0],
          page: 1,
          vehicle_year: categoryPartsData?.year_id,
          model_id: categoryPartsData?.model_id,
        };

        getVehicleSerachVariantList(obj)
          .then((response) => {
            response = response.data;
            let list = [];
            for (let i = 0; i < response.data.length; i++) {
              list.push({
                value: response.data[i].id,
                label: response.data[i].variants_name,
              });
            }
            setvehicleVariantPartList(list);
          })
          .catch((err) => {});
      }
    },
    [categoryPartsData?.model_id]
  );

  const handleInputSelectedChangeVariantList = (inputSelctedVariantValue) => {
    setInputSelctedVariantValue(inputSelctedVariantValue);

    if (inputSelctedVariantValue?.length > 0) {
      const obj = {
        serach: inputSelctedVariantValue,
        limit: tableLengthList[0],
        page: 1,
        vehicle_year: categoryPartsData?.year_id,
        model_id: categoryPartsData?.model_id,
      };

      getVehicleSerachVariantList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].variants_name,
            });
          }
          setvehicleVariantPartList(list);
        })
        .catch((err) => {});
    }
  };

  const handleSelectWithoutChangeVariantList = (option) => {
    setCategoryPartsData((prevData) => ({
      ...prevData,
      variants_id: option.value,
      vehicle_variant_name: option?.label,
    }));
  };

  const handleAddClick = () => {
    const { year_id, make_id, model_id, part_catalog_id } = categoryPartsData;

    if (!part_catalog_id) {
      toast.error("Part is required");

      return;
    }

    if (!year_id) {
      toast.error("Year is required");

      return;
    }

    if (!make_id) {
      toast.error("Make is required");
      return;
    }

    if (!model_id) {
      toast.error("Modal is required");
      return;
    }

    if (categoryMasterPart?.is_master_part === "1") {
      setModifyMasterPart(true);

      setSelectedItems((prevSelectedItems) => [
        ...prevSelectedItems,
        categoryPartsData,
      ]);

      return;
    }

    setSelectedItems((prevSelectedItems) => [
      ...prevSelectedItems,
      categoryPartsData,
    ]);

    setCategoryPartsData({
      part_catalog_id: "",
      year_id: "",
      make_id: "",
      model_id: "",
      variants_id: "",
    });
  };

  const handleModalSubmit = () => {
    const selectedRowss = selectedItemsCheckBox?.map(({ item, index }) => ({
      part_catalog_id: Number(item?.reference_id || ""),
      vehicle_make_name: categoryPartsData?.vehicle_make_name || "",
      year_id: categoryPartsData?.year_id || "",
      vehicle_model_name: categoryPartsData?.vehicle_model_name || "",
      vehicle_variant_name: categoryPartsData?.vehicle_variant_name || "",
      master_part_id: Number(item?.parts_catalog_id || 0),
      namePartPT: item?.namePartPT,
      make_id: categoryPartsData?.make_id || "",
      model_id: categoryPartsData?.model_id || "",
      variants_id: categoryPartsData?.variants_id || "",
    }));

    if (selectedRowss?.length > 0) {
      setSelectedItems((prevSelectedItems) => [
        ...prevSelectedItems,
        ...selectedRowss,
      ]);
    }

    setModifyMasterPart(false);
  };

  const handleDeleteClick = (row) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.filter(
        (item) => item.part_catalog_id !== row.part_catalog_id
      )
    );
  };

  const handleDismetalForm = (e) => {
    e.preventDefault();
    let data = {
      name: distmetalData?.name,
      part_list: selectedItems?.map((item) => ({
        catalog_id: item?.part_catalog_id || "",
        vehicle_make: item?.make_id || "",
        vehicle_year: item?.year_id || "",
        vehicle_model: item?.model_id || "",
        vehicle_variant: item?.variants_id || "",
        master_part_id: item?.master_part_id || 0,
      })),
    };

    let updateData = {
      id: distmetalData?.id,
      name: distmetalData?.name,

      part_list: selectedItems?.map((item) => ({
        catalog_id: item?.part_catalog_id || "",
        vehicle_make: item?.make_id || "",
        vehicle_year: item?.year_id || "",
        vehicle_model: item?.model_id || "",
        vehicle_variant: item?.variants_id || "",
        master_part_id: item?.master_part_id || 0,
      })),
    };

    if (distmetalData?.id) {
      actionUpdateDismental(updateData)
        .then((response) => {
          toast.success(response.data.message);
          navigate("/dismantle");
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        })
        .finally(() => {});
    } else {
      actionAddDismental(data)
        .then((response) => {
          toast.success(response.data.message);
          navigate("/dismantle");
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        })
        .finally(() => {});
    }
  };

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6"></div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item active">
                  {t("add_partCatalog")}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                <Form method={`post`} onSubmit={handleDismetalForm}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="col-form-label ml-2 Label-my form-label form-label">
                            {t("name")}
                          </Label>
                          <div className="col-xl-12">
                            <div className="input-div">
                              <input
                                className="form-control input-search"
                                type="text"
                                value={distmetalData.name}
                                onChange={(e) => {
                                  setDistmetalData({
                                    ...distmetalData,
                                    name: e.target.value,
                                  });
                                }}
                                name={`name`}
                                maxLength={100}
                                placeholder="Name"
                              />
                            </div>
                            {/* {errors.namePartPT && (
                            <span className="input-error">
                              {errors.namePartPT}
                            </span>
                          )} */}
                          </div>
                        </FormGroup>
                      </div>

                      <div className="col-md-6"></div>
                    </div>
                    <div className="row">
                      <div
                        className="col-lg-12"
                        style={{ marginBottom: "10px" }}
                      >
                        <div className="vehicleThBg table-responsive-sm">
                          <Table
                            className="rounded-table"
                            striped
                            bordered
                            hover
                          >
                            <thead>
                              <tr>
                                <th>
                                  <div>
                                    <div className="input-div SelectV">
                                      <div className="col-md-12">
                                        <div className="col-xl-12">
                                          <div className="input-div">
                                            <Select
                                              className="my-Select"
                                              options={categoryPartList}
                                              value={
                                                categoryPartList.find(
                                                  (item) =>
                                                    item.value ===
                                                    categoryPartsData?.part_catalog_id
                                                ) || null
                                              }
                                              onChange={
                                                handleSelectWithoutChangeCategoryList
                                              }
                                              placeholder={"Select Part"}
                                              isSearchable={true}
                                              onInputChange={
                                                handleInputSelectedChangeCategoryList
                                              }
                                              inputSelctedValue={
                                                inputSelctedValue
                                              }
                                            />{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </th>

                                <th>
                                  <div className="input-div SelectV">
                                    <div className="col-md-12">
                                      <Select
                                        className="my-Select"
                                        options={vehicleYearPartList}
                                        value={
                                          vehicleYearPartList.find(
                                            (item) =>
                                              item.value ===
                                              categoryPartsData?.year_id
                                          ) || null
                                        }
                                        onChange={
                                          handleSelectWithoutChangeYearList
                                        }
                                        placeholder={"Select Year"}
                                        isSearchable={true}
                                        onInputChange={
                                          handleInputSelectedChangeYearList
                                        }
                                        inputSelctedValue={
                                          inputSelctedYearValue
                                        }
                                      />
                                    </div>
                                  </div>
                                </th>

                                <th>
                                  <div className="input-div SelectV">
                                    <div className="col-md-12">
                                      <Select
                                        className="my-Select"
                                        options={vehicleMakePartList}
                                        value={
                                          vehicleMakePartList.find(
                                            (item) =>
                                              item.value ===
                                              categoryPartsData?.make_id
                                          ) || null
                                        }
                                        onChange={
                                          handleSelectWithoutChangeMakeList
                                        }
                                        placeholder={"Select Make"}
                                        isSearchable={true}
                                        onInputChange={
                                          handleInputSelectedChangeMakeList
                                        }
                                        inputSelctedValue={
                                          inputSelctedMakeValue
                                        }
                                      />
                                    </div>
                                  </div>
                                </th>

                                <th>
                                  <div className="input-div SelectV">
                                    <div className="col-md-12">
                                      <Select
                                        className="my-Select"
                                        options={vehicleModalPartList}
                                        value={
                                          vehicleModalPartList.find(
                                            (item) =>
                                              item.value ===
                                              categoryPartsData?.model_id
                                          ) || null
                                        }
                                        onChange={
                                          handleSelectWithoutChangeModelList
                                        }
                                        placeholder={"Select modal"}
                                        isSearchable={true}
                                        onInputChange={
                                          handleInputSelectedChangeModelList
                                        }
                                        inputSelctedValue={
                                          inputSelctedModalValue
                                        }
                                      />
                                    </div>
                                  </div>
                                </th>

                                <th>
                                  <div className="input-div SelectV">
                                    <div className="col-md-12">
                                      <Select
                                        className="my-Select"
                                        options={vehicleVariantPartList}
                                        value={
                                          vehicleVariantPartList.find(
                                            (item) =>
                                              item.value ===
                                              categoryPartsData?.variants_id
                                          ) || null
                                        }
                                        onChange={
                                          handleSelectWithoutChangeVariantList
                                        }
                                        placeholder={"Select Variant"}
                                        isSearchable={true}
                                        onInputChange={
                                          handleInputSelectedChangeVariantList
                                        }
                                        inputSelctedValue={
                                          inputSelctedVariantValue
                                        }
                                      />
                                    </div>
                                  </div>
                                </th>
                                <th>
                                  <div
                                    className="addBtn"
                                    style={{ cursor: "pointer" }}
                                    onClick={handleAddClick}
                                  >
                                    <i className="bi bi-plus-lg"></i>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedItems?.length > 0 ? (
                                selectedItems?.map((row, index) => (
                                  <tr key={index}>
                                    <td>{row.namePartPT}</td>
                                    <td>{row.year_id}</td>

                                    <td>{row.vehicle_make_name}</td>
                                    <td>{row.vehicle_model_name}</td>
                                    <td>{row.vehicle_variant_name}</td>

                                    <td>
                                      <Button
                                        type="button"
                                        className="btn-danger-delete"
                                        onClick={() => handleDeleteClick(row)}
                                      >
                                        <i class="bi bi-trash3"></i>
                                      </Button>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="100%" className="text-center">
                                    {t("no_record_found")}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col ms-2">
                        <button
                          type="button"
                          className="back-btn"
                          onClick={() => navigate("/dismantle")}
                        >
                          <i className="bi bi-arrow-left"> </i>
                          {t("back")}
                        </button>
                      </div>
                      <div className="col">
                        <div className="d-flex justify-content-end nxt-btn">
                          {/* {btnLoader === true ? (
                          <button type="submit" className="btn btn-primary">
                            Loading...
                          </button>
                        ) : ( */}
                          <button type="submit" className="btn btn-primary">
                            {t("submit")} <i className="bi bi-check2"></i>
                          </button>
                          {/* )} */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-footer"></div>
                </Form>
              </div>
            </div>
          </div>
        </div>

        <Modal isOpen={modifyMasterPart} size="lg">
          <ModalHeader>
            {categoryMasterPart ? categoryMasterPart?.label : ""}
          </ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <FormGroup>
                    {categoryMasterPart?.part_list?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedItemsCheckBox.some(
                              (selected) => selected.index === index
                            )}
                            onChange={() =>
                              handleCheckboxChangeMaster(index, item)
                            }
                          />
                        </td>
                        <td>
                          <div className="input-div SelectV">
                            <div className="col-md-12">{item?.namePartPT}</div>
                          </div>
                        </td>

                        <td>
                          <div className="input-div SelectV">
                            <div className="col-md-12">
                              {categoryPartsData?.year_id}
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className="input-div SelectV">
                            <div className="col-md-12">
                              {categoryPartsData?.vehicle_make_name}
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className="input-div SelectV">
                            <div className="col-md-12">
                              {categoryPartsData?.vehicle_model_name}
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className="input-div SelectV">
                            <div className="col-md-12">
                              {categoryPartsData?.vehicle_variant_name}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </FormGroup>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-secondary"
              onClick={handleMasterPartClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              {t("close")}
            </button>
            <button
              type="button"
              className="btn btn-block btn-primary"
              style={{ width: "100px", marginTop: "10px" }}
              onClick={handleModalSubmit}
            >
              {t("submit")}
            </button>
          </ModalFooter>
        </Modal>
      </section>
    </div>
  );
};

export default AddDistmental;
