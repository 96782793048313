import React, { useEffect, useState } from "react";
import ConfigDB from "../../config";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  actionAddDepollutionOpration,
  getLerCodeHistoryList,
  getLerCodeList,
  getVehicleInventoryOtherSerachList,
} from "../../services/DepollutionService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { Form, FormGroup, Label } from "reactstrap";
import { useTranslation } from "react-i18next";

const AddOtherDepollution = () => {
  const tableLengthList = ConfigDB?.data?.dataTableLength;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [vehiclePartsData, setVehiclePartsData] = useState({
    vehicle_id: "",
    ler_code_id: "",
    wastege_value: "",
    vehicle_make: "",
    vehicle_year: "",
    vehicle_model: "",
    description: "",
  });
  const [inputValue, setInputValue] = useState("");
  const [VehicleModelList, setVehicleModelList] = useState([]);
  const [LerCatalogList, setLerCatalogList] = useState([]);
  const [LerCatalogHistoryList, setLerCatalogHistoryList] = useState([]);
  const [inputValueLer, setInputValueLer] = useState("");

  let errorsObj = {
    vehicle_id: "",
    ler_code_id: "",
    wastege_value: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const handleSubmit = (e) => {
    e.preventDefault();

    let error = false;
    let errorObj = { ...errorsObj };

    if (!vehiclePartsData.vehicle_id) {
      errorObj.vehicle_id = `Vehicle is required.`;
      error = true;
    }
    if (!vehiclePartsData.ler_code_id) {
      errorObj.ler_code_id = `LER Code  is required.`;
      error = true;
    }
    if (!vehiclePartsData.wastege_value) {
      errorObj.wastege_value = `Wastage Value is required.`;
      error = true;
    }

    setErrors(errorObj);
    Utils.focusError();

    if (error) return;

    let data = {
      vehicle_id: 0,
      ler_code_id: vehiclePartsData?.ler_code_id,
      wastege_value: vehiclePartsData?.wastege_value,
      description: vehiclePartsData?.description,
      vehicle_make: vehiclePartsData?.vehicle_make,
      vehicle_year: vehiclePartsData?.vehicle_year,
      vehicle_model: vehiclePartsData?.vehicle_model,
    };

    actionAddDepollutionOpration(data)
      .then((response) => {
        toast.success(response.data.message);
        navigate("/depollution-operation");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      if (vehiclePartsData?.ler_code_id) {
        let data = {
          id: vehiclePartsData?.ler_code_id,
        };
        getLerCodeHistoryList(data)
          .then((response) => {
            setLerCatalogHistoryList(response.data.data);
          })
          .catch((err) => {
            toast.error(Utils.getErrorMessage(err));
          });
      }
    },
    [vehiclePartsData]
  );

  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };
    getLerCodeList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].ler_code,
          });
        }
        setLerCatalogList(list);
      })
      .catch((err) => {});
  }, []);

  const handleInputChangeLER = (inputValueLer) => {
    setInputValueLer(inputValueLer);

    if (inputValueLer.length > 2) {
      const obj = {
        search: inputValueLer,
        limit: tableLengthList[0],
        page: 1,
      };

      getLerCodeList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].ler_code,
            });
          }
          setLerCatalogList(list);
        })
        .catch((err) => {});
    } else {
      setLerCatalogList([]);
      const obj = {
        search: "",
        limit: tableLengthList[0],
        page: 1,
      };

      getLerCodeList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].ler_code,
            });
          }
          setLerCatalogList(list);
        })
        .catch((err) => {});
    }
  };

  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };
    getVehicleInventoryOtherSerachList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].vehicle_model,
            label: response.data[i].vehicle_model,
            vehicle_year: response.data[i].vehicle_year,
            vehicle_make: response.data[i].vehicle_make,
            vehicle_model: response.data[i].vehicle_model,
          });
        }
        setVehicleModelList(list);
      })
      .catch((err) => {});
  }, []);

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);

    if (inputValue.length > 2) {
      const obj = {
        search: inputValue,
        limit: tableLengthList[0],
        page: 1,
      };

      getVehicleInventoryOtherSerachList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].vehicle_model,
              label: response.data[i].vehicle_model,
            });
          }

          setVehicleModelList(list);
        })
        .catch((err) => {
          console.error("Error fetching vehicle model list", err);
        });
    } else {
      setVehicleModelList([]);
      var obj = {
        search: "",
        limit: tableLengthList[0],
        page: 1,
      };
      getVehicleInventoryOtherSerachList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].vehicle_model,
              label: response.data[i].vehicle_model,
              vehicle_year: response.data[i].vehicle_year,
              vehicle_make: response.data[i].vehicle_make,
              vehicle_model: response.data[i].vehicle_model,
            });
          }
          setVehicleModelList(list);
        })
        .catch((err) => {});
    }
  };

  const handleSelectChange = (selectedOption) => {
    setVehiclePartsData({
      ...vehiclePartsData,
      vehicle_id: selectedOption ? selectedOption.vehicle_model : "",
      vehicle_make: selectedOption ? selectedOption.vehicle_make : "",
      vehicle_year: selectedOption ? selectedOption.vehicle_year : "",
      vehicle_model: selectedOption ? selectedOption.vehicle_model : "",
    });
  };

  const handleSelectLERChange = (selectedOption) => {
    setVehiclePartsData({
      ...vehiclePartsData,
      ler_code_id: selectedOption ? selectedOption.value : "",
    });
  };
  return (
    <div>
      <div>
        <div>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6"></div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      {/* <a href="#">Home</a> */}
                    </li>
                    {/* <li className="breadcrumb-item active">Fuel</li> */}
                  </ol>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-primary">
                    {/* <div className="card-header">
                  <h3 className="card-title">Update Profile</h3>
                </div> */}

                    <Form method={`post`} onSubmit={handleSubmit}>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                {"Select Vehicle"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div SelectV">
                                  <Select
                                    className="my-Select"
                                    options={VehicleModelList}
                                    value={VehicleModelList.find(
                                      (item) =>
                                        item.value ==
                                        vehiclePartsData.vehicle_id
                                    )}
                                    onChange={handleSelectChange}
                                    placeholder={"Select Vehicle"}
                                    isSearchable={true}
                                    onInputChange={handleInputChange}
                                    inputValue={inputValue}
                                    isClearable={true}
                                  />
                                  {errors.vehicle_id && (
                                    <span className="input-error">
                                      {errors.vehicle_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </FormGroup>
                          </div>

                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                {"Select LER Catalog"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <Select
                                    className="my-Select"
                                    options={LerCatalogList}
                                    value={LerCatalogList.find(
                                      (item) =>
                                        item.value ==
                                        vehiclePartsData.ler_code_id
                                    )}
                                    onChange={handleSelectLERChange}
                                    placeholder={"Select LER catalog"}
                                    isSearchable={true}
                                    onInputChange={handleInputChangeLER}
                                    inputValue={inputValueLer}
                                    isClearable={true}
                                    isMulti
                                  />
                                  {errors.ler_code_id && (
                                    <span className="input-error">
                                      {errors.ler_code_id}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </FormGroup>
                          </div>
                        </div>
                        {vehiclePartsData?.ler_code_id && (
                          <div className="row">
                            <div className="col-md-6">
                              <ul
                                style={{
                                  listStyle: "square",
                                  marginBottom: "0",
                                }}
                              >
                                <li>
                                  LER Code: {LerCatalogHistoryList?.ler_code}
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-6">
                              <ul
                                style={{
                                  listStyle: "square",
                                  marginBottom: "0",
                                }}
                              >
                                <li>
                                  Unit Type:{" "}
                                  {LerCatalogHistoryList?.item_weight}
                                </li>
                              </ul>
                            </div>

                            <div className="col-md-6">
                              <ul
                                style={{
                                  listStyle: "square",
                                  marginBottom: "0",
                                }}
                              >
                                <li>
                                  Unit Value:{" "}
                                  {LerCatalogHistoryList?.item_weight_unit}
                                </li>
                              </ul>
                            </div>

                            <div className="col-md-6">
                              <ul
                                style={{
                                  listStyle: "square",
                                  marginBottom: "0",
                                }}
                              >
                                <li>
                                  Old Value:{" "}
                                  {LerCatalogHistoryList?.old_value ?? 0}
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                        <div className="row">
                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                {"wastage Value"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <input
                                    className="form-control input-search"
                                    type="number"
                                    value={vehiclePartsData.wastege_value}
                                    onChange={(e) => {
                                      setVehiclePartsData({
                                        ...vehiclePartsData,
                                        wastege_value: e.target.value,
                                      });
                                    }}
                                    name={`wastege_value`}
                                    maxLength={100}
                                    placeholder="wastage Value"
                                    isClearable={true}
                                  />
                                  {errors.wastege_value && (
                                    <span className="input-error">
                                      {errors.wastege_value}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </FormGroup>
                          </div>

                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                {"Description"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <textarea
                                    className="form-control input-search"
                                    type="text"
                                    value={vehiclePartsData.description}
                                    onChange={(e) => {
                                      setVehiclePartsData({
                                        ...vehiclePartsData,
                                        description: e.target.value,
                                      });
                                    }}
                                    name={`description`}
                                    maxLength={100}
                                    placeholder="Description"
                                    rows={3}
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col">
                            <button
                              type="button"
                              className="back-btn"
                              onClick={() => navigate("/depollution-operation")}
                            >
                              <i class="bi bi-arrow-left"> </i> {t("back")}
                            </button>
                          </div>
                          <div className="col">
                            <div className="d-flex justify-content-end">
                              <button type="submit" className="btn btn-primary">
                                Submit <i class="bi bi-check2"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card-footer"></div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default AddOtherDepollution;
