import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label } from "reactstrap";
import {
  actionAddRole,
  actionUpdateRole,
  getDetailsRole,
} from "../../services/RoleServices";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AddRolePermission = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const { t } = useTranslation();
  const [roleData, setRoleData] = useState({
    uuid: "",
    name: "",
  });
  let errorsObj = {
    name: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const [roleDetails, setRoleDetails] = useState({});
  const [rolePermissionList, setRolePermissionList] = useState([]);

  const [permissions, setPermissions] = useState([]);
  const [permissionsData, setPermissionsData] = useState([]);

  useEffect(() => {
    setRoleData({
      uuid: roleDetails?.uuid,
      name: roleDetails?.name,
    });
    if (roleDetails?.company_permission) {
      const initialPermissions = roleDetails.company_permission
        .filter((item) => item.is_checked === 1)
        .map((item) => item.permission_id);

      setPermissions(initialPermissions);

      const data = roleDetails?.company_permission?.filter((x) =>
        initialPermissions.includes(x.permission_id)
      );
      const extractedData = data?.map((item) => ({
        menu_id: item.menu_id,
        permission_id: item.permission_id,
      }));

      setPermissionsData(extractedData);
    }
  }, [roleDetails]);

  const handleCheckboxChange = (permissionId) => {
    setPermissions((prev) => {
      const updatedPermissions = prev.includes(permissionId)
        ? prev.filter((id) => id !== permissionId)
        : [...prev, permissionId];

      const updatedRoleDetails = roleDetails?.company_permission?.map(
        (item) => {
          if (item.permission_id === permissionId) {
            return {
              ...item,
              is_checked: updatedPermissions.includes(permissionId) ? 1 : 0,
            };
          }
          return item;
        }
      );

      const checkedData = updatedRoleDetails?.filter((item) =>
        updatedPermissions.includes(item?.permission_id)
      );

      const extractedData = checkedData?.map((item) => ({
        menu_id: item.menu_id,
        permission_id: item.permission_id,
      }));

      setPermissionsData(extractedData);
      return updatedPermissions;
    });
  };

  useEffect(() => {
    let data = {
      uuid: uuid ? uuid : "",
    };

    getDetailsRole(data)
      .then((response) => {
        let list = response.data.data.company_permission;

        let newList = [];
        for (let i = 0; i < list.length; i++) {
          let index = newList.findIndex(
            (x) => x.menu_id === list[i]["menu_id"]
          );
          if (index > -1) {
            newList[index]["list"].push(list[i]);
          } else {
            newList.push({
              menu_id: list[i]["menu_id"],
              parent_id: list[i]["parent_id"],
              name: list[i]["name"],
              list: [list[i]],
            });
          }
        }
        setRolePermissionList(newList);
        setRoleDetails(response.data.data);
      })
      .catch((err) => {
        // toast.error(Utils.getErrorMessage(err));
      });
  }, []);
  const [loading, setLoading] = useState(false);

  const handleRoleForm = (e) => {
    e.preventDefault();
    setLoading(true);

    let error = false;
    let errorObj = { ...errorsObj };
    if (!roleData.name) {
      errorObj.name = `Role is  required.`;
      error = true;
    }

    setErrors(errorObj);
    Utils.focusError();

    if (error) return;

    let data = {
      name: roleData.name,
      menus_data: permissionsData,
    };

    let updatedData = {
      uuid: roleData.uuid,
      name: roleDetails?.name,
      menus_data: permissionsData,
    };

    if (uuid) {
      actionUpdateRole(updatedData)
        .then((response) => {
          toast.success(response.data.message);
          navigate("/Roles");
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      actionAddRole(data)
        .then((response) => {
          toast.success(response.data.message);
          navigate("/Roles");
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleCheckAll = (isChecked) => {
    setPermissions((prev) => {
      const allPermissionIds = rolePermissionList.flatMap((item) =>
        item.list.map((permission) => permission.permission_id)
      );

      const updatedPermissions = isChecked ? allPermissionIds : [];

      const updatedRoleDetails = roleDetails?.company_permission?.map(
        (item) => ({
          ...item,
          is_checked: updatedPermissions.includes(item.permission_id) ? 1 : 0,
        })
      );

      const extractedData = updatedRoleDetails
        ?.filter((item) => updatedPermissions.includes(item.permission_id))
        .map((item) => ({
          menu_id: item.menu_id,
          permission_id: item.permission_id,
        }));

      setPermissionsData(extractedData);

      return updatedPermissions;
    });
  };

  const isAllChecked = rolePermissionList.every((item) =>
    item.list.every((permission) =>
      permissions.includes(permission.permission_id)
    )
  );

  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"></li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary card-primary-Vehicle">
                  <Form method={`post`} onSubmit={handleRoleForm}>
                    <div className="vehicle-owner-form">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="col-form-label">{"Role"}</Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={roleData?.name}
                                    onChange={(e) => {
                                      setRoleData({
                                        ...roleData,
                                        name: e.target.value,
                                      });
                                    }}
                                    name={`name`}
                                    maxLength={50}
                                    placeholder="Role Name"
                                  />
                                </div>
                                {errors.name && (
                                  <span className="input-error">
                                    {errors.name}
                                  </span>
                                )}
                              </div>
                            </FormGroup>
                          </div>

                          <div className="col-md-6">
                            <div class="form-check form-check-inline">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                id="checkAll"
                                value="checkAll"
                                checked={isAllChecked}
                                onChange={(e) =>
                                  handleCheckAll(e.target.checked)
                                }
                              />
                              <label class="form-check-label ps-1" for="">
                                Check All
                              </label>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="card">
                              <div className="card-body p-4">
                                {rolePermissionList?.map((item, index) => {
                                  const dataa = rolePermissionList.filter(
                                    (child) => child.parent_id === item.menu_id
                                  );
                                  const isParent = item.parent_id === "0";

                                  return (
                                    <React.Fragment key={index}>
                                      {isParent && (
                                        <div className="mb-3 border-bottom pb-3">
                                          <h5
                                            className="card-title w-100 mb-3"
                                            style={{ fontWeight: "bold" }}
                                          >
                                            {item.name}
                                          </h5>

                                          {item?.list?.map(
                                            (permission, idx) => {
                                              const permissionId =
                                                permission.permission_id;

                                              return (
                                                <div
                                                  className="form-check form-check-inline"
                                                  key={idx}
                                                >
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id={`inlineCheckbox_${permissionId}`}
                                                    value={
                                                      permission.display_name
                                                    }
                                                    checked={permissions.includes(
                                                      permissionId
                                                    )}
                                                    onChange={() =>
                                                      handleCheckboxChange(
                                                        permissionId
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label ps-1"
                                                    htmlFor={`inlineCheckbox_${permissionId}`}
                                                  >
                                                    {permission.display_name}
                                                  </label>
                                                </div>
                                              );
                                            }
                                          )}

                                          {dataa?.length > 0 &&
                                            dataa?.map(
                                              (childItem, childIndex) => (
                                                <div
                                                  className="mb-3 border-bottom pb-3"
                                                  key={childIndex}
                                                >
                                                  <h5 className="card-title w-100 mb-3">
                                                    {childItem.name}{" "}
                                                  </h5>
                                                  {childItem?.list?.map(
                                                    (permission, idx) => {
                                                      const permissionId =
                                                        permission.permission_id;

                                                      if (
                                                        item.list.some(
                                                          (p) =>
                                                            p.permission_id ===
                                                            permissionId
                                                        )
                                                      ) {
                                                        return null;
                                                      }

                                                      return (
                                                        <div
                                                          className="form-check form-check-inline"
                                                          key={idx}
                                                        >
                                                          <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={`inlineCheckbox_${permissionId}`}
                                                            value={
                                                              permission.display_name
                                                            }
                                                            checked={permissions.includes(
                                                              permissionId
                                                            )}
                                                            onChange={() =>
                                                              handleCheckboxChange(
                                                                permissionId
                                                              )
                                                            }
                                                          />
                                                          <label
                                                            className="form-check-label ps-1"
                                                            htmlFor={`inlineCheckbox_${permissionId}`}
                                                          >
                                                            {
                                                              permission.display_name
                                                            }
                                                          </label>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              )
                                            )}
                                        </div>
                                      )}
                                    </React.Fragment>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <button
                          type="button"
                          className="next-button btn btn-secondary"
                          onClick={() => navigate("/Roles")}
                        >
                          <i class="bi bi-arrow-left"> </i> {t("back")}
                        </button>
                      </div>
                      <div className="col roleSubmit">
                        {loading === true ? (
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ width: "94px" }}
                          >
                            Loading...
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-primary"
                            style={{ width: "94px" }}
                          >
                            Submit <i className="bi bi-check2"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AddRolePermission;
