import axiosInstance from "./AxiosInstance";

export function getCompanySalesList(data) {
  return axiosInstance.post(`/sales/list`, data);
}

export function detailsCompanySales(data) {
  return axiosInstance.post(`/sales/details`, data);
}

export function updateCompanySalesStatus(data) {
  return axiosInstance.post(`/sales/status-update`, data);
}

export function getEcommerceCompanyList(data) {
  return axiosInstance.post(`/ecommerce-company-list`, data);
}

export function getEcommerceSalesDetailsList(data) {
  return axiosInstance.post(`/sales/details`, data);
}
