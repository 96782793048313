import React, { useEffect, useState } from "react";
import {
  getPartsInventoryList,
  partInvenoryAllPartPdf,
} from "../../services/PartsInventory";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import Pagination from "react-js-pagination";
import { useTranslation } from "react-i18next";

const PartExportPdfList = () => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);
  const { t } = useTranslation();

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
    vehicle_model: "",
    vehicle_year: "",
    catalog_name: "",
    part_name: "",
    inventory_status: 0,
    record_show_type: 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [partsInventoryList, setPartsInventoryList] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleRowCheckboxChange = (id) => {
    setSelectedIds((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedIds(partsInventoryList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }

    setParams(paramsObj);
  };

  const fetchPartInventory = () => {
    setLoading(true); // Show loader
    let data = { ...params };
    getPartsInventoryList(data)
      .then((response) => {
        setPartsInventoryList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      })
      .finally(() => {
        setLoading(false); // Hide loader
      });
  };

  useEffect(
    function () {
      let data = { ...params };
      fetchPartInventory(data);
    },
    [params]
  );

  const handleSubmitPdfAll = (e) => {
    e.preventDefault();
    setLoading(true); // Show loader
    let data = {
      part_list: selectedIds ? selectedIds : [],
    };
    partInvenoryAllPartPdf(data)
      .then((response) => {
        if (
          response.data &&
          response.data.record &&
          response.data.record.url &&
          response.status === 200
        ) {
          window.open(response.data.record.url, "_blank");
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      })
      .finally(() => {
        setLoading(false); // Hide loader
      });
  };

  return (
    <div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card fuelCard">
                <div className="card-header">
                  <div className="card-title m-0 float-right fuelCT rightBtn">
                    <button
                      type="button"
                      className="btn-icon-text mr-2"
                      onClick={handleSubmitPdfAll}
                      disabled={loading} // Disable button when loading
                    >
                      <i className="bi bi-filetype-pdf"></i>
                      <span className="ms-2">Submit</span>
                    </button>
                  </div>
                </div>
                <div className="card-body fuelCardB">
                  {loading ? (
                    <div className="text-center my-5">
                      <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <div className="vehicleThBg table-responsive vehicle-responsive">
                      <table className="table table-bordered rounded-table table table-striped table-hover">
                        <thead>
                          <tr>
                            <th>
                              <input
                                type="checkbox"
                                checked={selectAll}
                                onChange={handleSelectAllChange}
                              />
                            </th>
                            <th className="Label-my" style={{ width: "18%" }}>
                              {t("Internal Id")}{" "}
                            </th>
                            <th className="Label-my" style={{ width: "18%" }}>
                              Catalog
                            </th>
                            <th className="Label-my">Model</th>
                            <th className="Label-my">Make</th>
                            <th className="Label-my" style={{ width: "20%" }}>
                              Inventory Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {partsInventoryList?.map((item) => (
                            <tr key={item.id}>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={selectedIds.includes(item.id)}
                                  onChange={() =>
                                    handleRowCheckboxChange(item.id)
                                  }
                                />
                              </td>
                              <td>{item.id}</td>
                              <td>{item.part_name}</td>
                              <td>{item.vehicle_model_name}</td>
                              <td>{item.vehicle_make_name}</td>
                              <td>{item.inventory_status_name}</td>
                            </tr>
                          ))}
                          {partsInventoryList &&
                            partsInventoryList?.length === 0 && (
                              <tr>
                                <td colSpan={7} className={`text-center`}>
                                  {t("no_record_found")}
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>

              <div className="fuelCF">
                <div className="pagination pagination-sm m-0 float-right card-footer-part">
                  <Pagination
                    activePage={params.page}
                    itemsCountPerPage={parseInt(params.limit)}
                    totalItemsCount={parseInt(totalRecords)}
                    pageRangeDisplayed={5}
                    itemClass={`page-item`}
                    linkClass={`page-link`}
                    onChange={(e) => handleParams(e, "pagination")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PartExportPdfList;
