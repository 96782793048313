import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label } from "reactstrap";
import { createPartCatalog } from "../../services/LerCatalogService";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useNavigate } from "react-router-dom";
import {
  createVehicleExtraInfo,
  createVehicleOwner,
} from "../../services/VehicleService";
import { useTranslation } from "react-i18next";

const VehicleInfoExtra = ({
  setActiveTab,
  vehicleProcessList,
  activeTab,
  vehicleVinData,
  vehiclePlateData,
  certificateDetails,
}) => {
  const [vehicleInfoExtraData, setVehicleInfoExtraData] = useState({
    uuid: "",
    tires_front: "",
    tires_rear: "",
    max_weight_front: "",
    max_weight_rear: "",
    is_towable: 0,
    with_brake: 1,
    without_brake: 0,
    power_lifting: "",
    box_type: "",
    comp_max_box: "",
    box_width: "",
    axel_distance: "",
    co2_g_km: "",
    particles_g_km: "",
    no_of_right_doors: "",
    no_of_left_doors: "",
    no_of_rear_doors: "",
    capacity: "",
    previous_registration: "",
    registration_date: new Date().toISOString().split("T")[0],
    origin_country: "",
    origin_state: "",
    comments: "",
    special_notes: "",
  });

  const navigate = useNavigate();

  let errorsObj = {
    tires_front: "",
    tires_rear: "",
    max_weight_front: "",
    max_weight_rear: "",
    is_towable: "",
    with_brake: "",
    without_brake: "0",
    power_lifting: "",
    box_type: "",
    comp_max_box: "",
    box_width: "",
    axel_distance: "",
    co2_g_km: "",
    particles_g_km: "",
    no_of_right_doors: "",
    no_of_left_doors: "",
    no_of_rear_doors: "",
    capacity: "",
    previous_registration: "",
    registration_date: "",
    origin_country: "",
    origin_state: "",
    comments: "",
    special_notes: "",
  };

  const [errors, setErrors] = useState(errorsObj);
  let vehicleId = localStorage.getItem("vehicleId");

  const { t } = useTranslation();

  useEffect(() => {
    if (vehicleProcessList && activeTab === "vehicle_extra_info") {
      setVehicleInfoExtraData((prevData) => ({
        ...prevData,
        uuid: vehicleProcessList?.uuid,
        tires_front: vehicleProcessList?.tires_front,
        tires_rear: vehicleProcessList?.tires_rear,
        max_weight_front: vehicleProcessList?.max_weight_front,
        max_weight_rear: vehicleProcessList?.max_weight_rear,
        is_towable: vehicleProcessList?.is_towable,
        with_brake: vehicleProcessList?.with_brake,
        without_brake: vehicleProcessList?.without_brake,
        power_lifting: vehicleProcessList?.power_lifting,
        box_type: vehicleProcessList?.box_type,
        comp_max_box: vehicleProcessList?.comp_max_box,
        box_width: vehicleProcessList?.box_width,
        axel_distance: vehicleProcessList?.axel_distance,
        co2_g_km: vehicleProcessList?.co2_g_km,
        particles_g_km: vehicleProcessList?.particles_g_km,
        no_of_right_doors: vehicleProcessList?.no_of_right_doors,
        no_of_left_doors: vehicleProcessList?.no_of_left_doors,
        no_of_rear_doors: vehicleProcessList?.no_of_rear_doors,
        capacity: vehicleProcessList?.capacity,
        previous_registration: vehicleProcessList?.previous_registration,
        registration_date: vehicleProcessList?.registration_date,
        origin_country: vehicleProcessList?.origin_country,
        origin_state: vehicleProcessList?.origin_state,
        comments: vehicleProcessList?.comments,
        special_notes: vehicleProcessList?.special_notes,
      }));
    }
  }, [vehicleProcessList, activeTab]);

  useEffect(() => {
    if (certificateDetails) {
      setVehicleInfoExtraData((prevData) => ({
        ...prevData,
        origin_country:
          certificateDetails?.country_text || prevData.origin_country,
        registration_date: certificateDetails?.registration_plate_date
          ? `${new Date(
              certificateDetails.registration_plate_date
            ).getFullYear()}-${(
              new Date(certificateDetails.registration_plate_date).getMonth() +
              1
            )
              .toString()
              .padStart(2, "0")}-${new Date(
              certificateDetails.registration_plate_date
            )
              .getDate()
              .toString()
              .padStart(2, "0")}`
          : prevData.registration_date,
      }));
    }
  }, [certificateDetails]);

  useEffect(() => {
    if (
      vehicleVinData &&
      vehicleVinData?.vehicle_general_info &&
      vehicleVinData?.vehicle_general_info?.length > 0
    ) {
      setVehicleInfoExtraData((prevData) => ({
        ...prevData,
        capacity: vehiclePlateData?.vehicle_general_info[0]?.engine_capacity,
        registration_date:
          vehiclePlateData?.vehicle_general_info[0]?.plate_registration,

        box_type: vehiclePlateData?.vehicle_general_info[0]?.gear_box,
      }));
    }

    if (vehiclePlateData && vehiclePlateData?.vehicle_general_info) {
      setVehicleInfoExtraData((prevData) => ({
        ...prevData,
        capacity: vehiclePlateData?.vehicle_general_info?.engine_capacity,
        registration_date:
          vehiclePlateData?.vehicle_general_info?.plate_registration,
        box_type: vehiclePlateData?.vehicle_general_info?.gear_box,
      }));
    }
  }, [vehicleVinData, vehiclePlateData]);

  const [btnLoader, setBtnLoader] = useState(false);

  const handleCheckboxChange = (e) => {
    setVehicleInfoExtraData({
      ...vehicleInfoExtraData,
      is_towable: e.target.checked ? 1 : 0,
    });
  };

  const handleRadioChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setVehicleInfoExtraData({
      ...vehicleInfoExtraData,
      with_brake: value === 1 ? 1 : 0,
      without_brake: value === 0 ? 1 : 0,
    });
  };

  const handleVehicleExtraForm = (e) => {
    e.preventDefault();
    setBtnLoader(true);

    let data = {
      vehicle_id: vehicleId,
      tires_front: vehicleInfoExtraData?.tires_front,
      tires_rear: vehicleInfoExtraData?.tires_rear,
      max_weight_front: vehicleInfoExtraData?.max_weight_front,
      max_weight_rear: vehicleInfoExtraData?.max_weight_rear,
      is_towable: vehicleInfoExtraData?.is_towable,
      with_brake: vehicleInfoExtraData?.with_brake
        ? vehicleProcessList?.with_brake
        : 1,
      without_brake: vehicleInfoExtraData?.without_brake,
      power_lifting: vehicleInfoExtraData?.power_lifting,
      box_type: vehicleInfoExtraData?.box_type,
      comp_max_box: vehicleInfoExtraData?.comp_max_box,
      box_width: vehicleInfoExtraData?.box_width,
      axel_distance: vehicleInfoExtraData?.axel_distance,
      co2_g_km: vehicleInfoExtraData?.co2_g_km,
      particles_g_km: vehicleInfoExtraData?.particles_g_km,
      no_of_right_doors: vehicleInfoExtraData?.no_of_right_doors,
      no_of_left_doors: vehicleInfoExtraData?.no_of_left_doors,
      no_of_rear_doors: vehicleInfoExtraData?.no_of_rear_doors,
      capacity: vehicleInfoExtraData?.capacity,
      previous_registration: vehicleInfoExtraData?.previous_registration,
      registration_date: certificateDetails?.registration_plate_date
        ? `${new Date(
            certificateDetails.registration_plate_date
          ).getFullYear()}-${(
            new Date(certificateDetails.registration_plate_date).getMonth() + 1
          )
            .toString()
            .padStart(2, "0")}-${new Date(
            certificateDetails.registration_plate_date
          )
            .getDate()
            .toString()
            .padStart(2, "0")}`
        : vehicleInfoExtraData?.registration_date,
      origin_country: certificateDetails?.country_text
        ? certificateDetails?.country_text
        : vehicleInfoExtraData?.origin_country,
      origin_state: vehicleInfoExtraData?.origin_state,
      comments: vehicleInfoExtraData?.comments,
      special_notes: vehicleInfoExtraData?.special_notes,
    };

    let updateData = {
      uuid: vehicleInfoExtraData?.uuid,
      vehicle_id: vehicleId,
      tires_front: vehicleInfoExtraData?.tires_front,
      tires_rear: vehicleInfoExtraData?.tires_rear,
      max_weight_front: vehicleInfoExtraData?.max_weight_front,
      max_weight_rear: vehicleInfoExtraData?.max_weight_rear,
      is_towable: vehicleInfoExtraData?.is_towable,
      with_brake: vehicleInfoExtraData?.with_brake,
      without_brake: vehicleInfoExtraData?.without_brake,
      power_lifting: vehicleInfoExtraData?.power_lifting,
      box_type: vehicleInfoExtraData?.box_type,
      comp_max_box: vehicleInfoExtraData?.comp_max_box,
      box_width: vehicleInfoExtraData?.box_width,
      axel_distance: vehicleInfoExtraData?.axel_distance,
      co2_g_km: vehicleInfoExtraData?.co2_g_km,
      particles_g_km: vehicleInfoExtraData?.particles_g_km,
      no_of_right_doors: vehicleInfoExtraData?.no_of_right_doors,
      no_of_left_doors: vehicleInfoExtraData?.no_of_left_doors,
      no_of_rear_doors: vehicleInfoExtraData?.no_of_rear_doors,
      capacity: vehicleInfoExtraData?.capacity,
      previous_registration: vehicleInfoExtraData?.previous_registration,
      registration_date: vehicleInfoExtraData?.registration_date,
      origin_country: vehicleInfoExtraData?.origin_country,
      origin_state: vehicleInfoExtraData?.origin_state,
      comments: vehicleInfoExtraData?.comments,
      special_notes: vehicleInfoExtraData?.special_notes,
    };

    if (vehicleInfoExtraData?.uuid) {
      createVehicleExtraInfo(updateData)
        .then((response) => {
          toast.success(response.data.message);
          setActiveTab("vehicle_owner");
          // localStorage.setItem("ownerList", response.data.data.id);
          // navigate("/vehicle-process");
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        })
        .finally(() => {
          setBtnLoader(false);
        });
    } else {
      createVehicleExtraInfo(data)
        .then((response) => {
          toast.success(response.data.message);
          setActiveTab("vehicle_owner");
          // localStorage.setItem("ownerList", response.data.data.id);
          // navigate("/vehicle-process");
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        })
        .finally(() => {
          setBtnLoader(false);
        });
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary">
            <Form method={`post`} onSubmit={handleVehicleExtraForm}>
              <div className="vehicle-owner-form">
                <div className="card-body">
                  <div className="row justify-content-start">
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Tires Front"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleInfoExtraData.tires_front}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  tires_front: e.target.value,
                                });
                              }}
                              name={`tires_front`}
                              maxLength={100}
                              placeholder="Tires Front"
                            />
                          </div>
                          {/* {errors.tires_front && (
                            <span className="input-error">
                              {errors.tires_front}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">{"Tires Rear"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleInfoExtraData.tires_rear}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  tires_rear: e.target.value,
                                });
                              }}
                              name={`tires_rear`}
                              maxLength={100}
                              placeholder="Tires Rear"
                            />
                          </div>
                          {/* {errors.tires_rear && (
                            <span className="input-error">
                              {errors.tires_rear}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Max Weight Front"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleInfoExtraData.max_weight_front}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  max_weight_front: e.target.value,
                                });
                              }}
                              name={`max_weight_front`}
                              maxLength={100}
                              placeholder="Max Weight Front"
                            />
                          </div>
                          {/* {errors.max_weight_front && (
                            <span className="input-error">
                              {errors.max_weight_front}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Max Weight Rear"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleInfoExtraData.max_weight_rear}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  max_weight_rear: e.target.value,
                                });
                              }}
                              name={`max_weight_rear`}
                              maxLength={40}
                              placeholder="Max Weight Rear"
                            />
                          </div>
                          {/* {errors.max_weight_rear && (
                            <span className="input-error">
                              {errors.max_weight_rear}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("power_lifting")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleInfoExtraData.power_lifting}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  power_lifting: e.target.value,
                                });
                              }}
                              name={`power_lifting`}
                              maxLength={100}
                              placeholder="Power Lifting"
                            />
                          </div>
                          {/* {errors.power_lifting && (
                            <span className="input-error">
                              {errors.power_lifting}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">{"Box Type"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleInfoExtraData.box_type}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  box_type: e.target.value,
                                });
                              }}
                              name={`box_type`}
                              maxLength={100}
                              placeholder="Box Type"
                            />
                          </div>
                          {/* {errors.box_type && (
                            <span className="input-error">
                              {errors.box_type}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("comp_max_box")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleInfoExtraData.comp_max_box}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  comp_max_box: e.target.value,
                                });
                              }}
                              name={`comp_max_box`}
                              maxLength={100}
                              placeholder="Comp Max Box"
                            />
                          </div>
                          {/* {errors.comp_max_box && (
                            <span className="input-error">
                              {errors.comp_max_box}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">{"Box Width"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleInfoExtraData.box_width}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  box_width: e.target.value,
                                });
                              }}
                              name={`box_width`}
                              maxLength={20}
                              placeholder="Box Width"
                            />
                          </div>
                          {/* {errors.box_width && (
                            <span className="input-error">
                              {errors.box_width}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Axel Distance"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleInfoExtraData.axel_distance}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  axel_distance: e.target.value,
                                });
                              }}
                              name={`axel_distance`}
                              maxLength={30}
                              placeholder="Axel Distance"
                            />
                          </div>
                          {/* {errors.axel_distance && (
                            <span className="input-error">
                              {errors.axel_distance}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">{"Co2 gkm"}</Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleInfoExtraData.co2_g_km}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  co2_g_km: e.target.value,
                                });
                              }}
                              name={`co2_g_km`}
                              maxLength={50}
                              placeholder="Co2 gkm"
                            />
                          </div>
                          {/* {errors.co2_g_km && (
                            <span className="input-error">
                              {errors.co2_g_km}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Particles gkm"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleInfoExtraData.particles_g_km}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  particles_g_km: e.target.value,
                                });
                              }}
                              name={`particles_g_km`}
                              maxLength={100}
                              placeholder="Particles gkm"
                            />
                          </div>
                          {/* {errors.particles_g_km && (
                            <span className="input-error">
                              {errors.particles_g_km}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"No Of Right Doors"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleInfoExtraData.no_of_right_doors}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  no_of_right_doors: e.target.value,
                                });
                              }}
                              name={`no_of_right_doors`}
                              maxLength={9}
                              placeholder="No Of Right Doors"
                            />
                          </div>
                          {/* {errors.no_of_right_doors && (
                            <span className="input-error">
                              {errors.no_of_right_doors}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"No Of Left Doors"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleInfoExtraData.no_of_left_doors}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  no_of_left_doors: e.target.value,
                                });
                              }}
                              name={`no_of_left_doors`}
                              maxLength={9}
                              placeholder="No Of Left Doors"
                            />
                          </div>
                          {/* {errors.no_of_left_doors && (
                            <span className="input-error">
                              {errors.no_of_left_doors}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"No Of Rear Doors"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleInfoExtraData.no_of_rear_doors}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  no_of_rear_doors: e.target.value,
                                });
                              }}
                              name={`no_of_rear_doors`}
                              maxLength={9}
                              placeholder="No Of Rear Doors"
                            />
                          </div>
                          {/* {errors.no_of_rear_doors && (
                            <span className="input-error">
                              {errors.no_of_rear_doors}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("capacity")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="number"
                              value={vehicleInfoExtraData.capacity}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  capacity: e.target.value,
                                });
                              }}
                              name={`capacity`}
                              maxLength={9}
                              placeholder="Capacity"
                            />
                          </div>
                          {/* {errors.capacity && (
                            <span className="input-error">
                              {errors.capacity}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("previous_registration")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleInfoExtraData.previous_registration}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  previous_registration: e.target.value,
                                });
                              }}
                              name={`previous_registration`}
                              placeholder="Previous Registration"
                            />
                          </div>
                          {/* {errors.previous_registration && (
                            <span className="input-error">
                              {errors.previous_registration}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("registration_date")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              type="date"
                              id="registration_date"
                              className="form-control"
                              value={vehicleInfoExtraData.registration_date}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  registration_date: e.target.value,
                                });
                              }}
                            ></input>
                          </div>
                          {/* {errors.registration_date && (
                            <span className="input-error">
                              {errors.registration_date}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Origin Country"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleInfoExtraData.origin_country}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  origin_country: e.target.value,
                                });
                              }}
                              name={`origin_country`}
                              placeholder="Origin Country"
                            />
                          </div>
                          {/* {errors.origin_country && (
                            <span className="input-error">
                              {errors.origin_country}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("origin_state")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleInfoExtraData.origin_state}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  origin_state: e.target.value,
                                });
                              }}
                              name={`origin_state`}
                              placeholder="Origin State"
                            />
                          </div>
                          {/* {errors.origin_state && (
                            <span className="input-error">
                              {errors.origin_state}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("comments")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleInfoExtraData.comments}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  comments: e.target.value,
                                });
                              }}
                              name={`comments`}
                              placeholder="Comments"
                            />
                          </div>
                          {/* {errors.comments && (
                            <span className="input-error">
                              {errors.comments}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("special_notes")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="text"
                              value={vehicleInfoExtraData.special_notes}
                              onChange={(e) => {
                                setVehicleInfoExtraData({
                                  ...vehicleInfoExtraData,
                                  special_notes: e.target.value,
                                });
                              }}
                              name={`special_notes`}
                              placeholder="Special Notes"
                            />
                          </div>
                          {/* {errors.special_notes && (
                            <span className="input-error">
                              {errors.special_notes}
                            </span>
                          )} */}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {"Brake Option"}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div radiobtns mt-12">
                            <div className="form-check form-check-radio">
                              <input
                                className="form-check-input custom-radio"
                                type="radio"
                                id="with_brake"
                                name="brake_option"
                                checked={vehicleInfoExtraData.with_brake}
                                onChange={handleRadioChange}
                                value="1"
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="with_brake"
                              >
                                {t("with_brake")}{" "}
                              </Label>
                            </div>
                            <div className="form-check form-check-radio">
                              <input
                                className="form-check-input custom-radio"
                                type="radio"
                                id="without_brake"
                                name="brake_option"
                                value="0"
                                checked={vehicleInfoExtraData.without_brake}
                                onChange={handleRadioChange}
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="without_brake"
                              >
                                {t("without_brake")}
                              </Label>
                            </div>
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-md-3">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("is_towable")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div mt-12">
                            <div className="form-check">
                              <input
                                className="my-checkbox-1 form-check-input"
                                type="checkbox"
                                checked={vehicleInfoExtraData.is_towable}
                                onChange={handleCheckboxChange}
                              />
                              <label className="form-check-label pl-6">
                                {t("is_towable")}
                              </label>
                            </div>
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="back-footer">
                    <div className="buttonsB">
                      <div className="col">
                        <button
                          type="button"
                          className="next-button btn btn-secondary"
                          onClick={() => setActiveTab("vehicle_images")}
                        >
                          <i class="bi bi-arrow-left"></i> {t("back")}
                        </button>
                      </div>
                      <div className="col">
                        <div className="btnNxtFns">
                          {btnLoader === true ? (
                            <button
                              type="submit"
                              className="btn btn-primary nxt-btn"
                            >
                              Loading...
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-primary nxt-btn"
                            >
                              {t("finish")}
                              <i class="bi bi-arrow-right"></i>
                            </button>
                          )}

                          <button
                            type="button"
                            className="btn btn-primary nxt-btn"
                            onClick={() => setActiveTab("vehicle_owner")}
                          >
                            {t("next")}
                            <i class="bi bi-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleInfoExtra;
