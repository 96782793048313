import React, { useEffect, useState } from "react";
import {
  createPartDamageAssigned,
  deletePartDamageAssigned,
  getpartDamageList,
  PartInventoryImageDetails,
} from "../../services/PartsInventory";
import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Select from "react-select";
import ConfigDB from "../../config";
import { useTranslation } from "react-i18next";
import Utils from "../../utils";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

const AssignedDamageImage = ({ partImageDataList, setCurrentStep }) => {
  const tableLengthList = ConfigDB?.data?.dataTableLength;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uuid } = useParams();

  const [vehicleImageDetails, setVehicleImageDetails] = useState([]);
  const [damageList, setDamageList] = useState([]);
  const [selectedDamages, setSelectedDamages] = useState([]);
  const [delModal, setDelModal] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [delpart, setDelPart] = useState("");
  const [existingDamages, setExistingDamages] = useState({});

  const handleChange = (selectedOptions, imgVideoId) => {
    const formattedDamages = selectedOptions.map((option) => ({
      damage_id: option.value,
    }));

    setSelectedDamages((prevState) => ({
      ...prevState,
      [imgVideoId]: formattedDamages,
    }));
  };

  const onDeleteModalOpen = (e, item) => {
    const data = item?.find((item) => item.id)?.id;
    e.preventDefault();
    setDelPart(data);
    setDelModal(true);
  };

  const onDeleteModalClose = () => {
    setDelModal(false);
  };

  const onDestroyPartType = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { id: delpart };
    deletePartDamageAssigned(data)
      .then((response) => {
        toast.success(response.data.message);

        var obj = {
          id: uuid,
        };
        PartInventoryImageDetails(obj)
          .then((response) => {
            response = response.data.data;
            setVehicleImageDetails(response);
          })
          .catch((err) => {});

        setDelPart("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(() => {
    if (
      vehicleImageDetails &&
      vehicleImageDetails.some(
        (item) => item.damage_list && item.damage_list.length > 0
      )
    ) {
      const formattedDamages = {};

      vehicleImageDetails.forEach((item) => {
        if (item.damage_list && item.damage_list.length > 0) {
          formattedDamages[item.id] = item.damage_list.map((d) => ({
            damage_id: Number(d.damage_id),
          }));
        }
      });

      setExistingDamages(formattedDamages);
      setSelectedDamages(formattedDamages);
    }
  }, [vehicleImageDetails]);

  useEffect(function () {
    const data = partImageDataList?.find(
      (item) => item.vehicle_part_id
    )?.vehicle_part_id;
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
      part_id: uuid ? uuid : data,
    };
    getpartDamageList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].damage_description,
          });
        }
        setDamageList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(
    function () {
      const data = partImageDataList?.find(
        (item) => item.vehicle_part_id
      )?.vehicle_part_id;
      if (data || uuid) {
        var obj = {
          id: uuid ? uuid : data,
        };
        PartInventoryImageDetails(obj)
          .then((response) => {
            response = response.data.data;
            setVehicleImageDetails(response);
          })
          .catch((err) => {});
      }
    },

    [uuid]
  );

  const handleFinish = (event) => {
    event.preventDefault();
    const dataPart = partImageDataList?.find(
      (item) => item.vehicle_part_id
    )?.vehicle_part_id;

    const damageList = Object.entries(selectedDamages).map(
      ([imgVideoId, damages]) => ({
        img_video_id: parseInt(imgVideoId),
        damages,
      })
    );

    const isEdit = !!uuid;

    const hasNewDamages =
      JSON.stringify(selectedDamages) !== JSON.stringify(existingDamages);

    if (isEdit && !hasNewDamages) {
      toast.error("No new damages added.");
      return;
    }

    let data = {
      img_video_type: 1, //1= image ,2= video
      vehicle_part_id: uuid ? uuid : dataPart,
      damage_list: damageList,
    };

    createPartDamageAssigned(data)
      .then((response) => {
        toast.success(response.data.message);

        setCurrentStep(5);
        // navigate("/parts-inventory");
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleBackButtonClick = () => {
    setCurrentStep(2);
  };

  const handleNextButtonClick = () => {
    setCurrentStep(5);
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="col-md-12">
            <FormGroup>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Assign Damage</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {vehicleImageDetails?.map((item, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td>
                            <img
                              src={item.image_url}
                              style={{
                                width: "100px",
                              }}
                              alt="vehicle"
                            />
                          </td>
                          <td>
                            <Select
                              className="my-Select"
                              options={damageList}
                              value={selectedDamages[item.id]?.map((d) =>
                                damageList.find(
                                  (opt) => opt.value === d.damage_id
                                )
                              )}
                              onChange={(selected) =>
                                handleChange(selected, item.id)
                              }
                              placeholder="Select Damage"
                              isSearchable
                              isMulti
                              isClearable
                            />
                          </td>
                          {item?.damage_list?.length > 0 && (
                            <td>
                              <div
                                className="trash-btn"
                                onClick={(e) =>
                                  onDeleteModalOpen(e, item.damage_list)
                                }
                              >
                                <a className="delete-btn" href="#">
                                  <i className="bi bi-trash3"></i>
                                </a>
                              </div>
                            </td>
                          )}
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
              <div className="back-footer">
                <div className="back-next back-next-custom">
                  <div className="row">
                    <div className="col">
                      <button
                        type="button"
                        className="next-button btn btn-secondary ms-0"
                        onClick={handleBackButtonClick}
                      >
                        <i class="bi bi-arrow-left"></i> {t("back")}
                      </button>
                    </div>
                    <div
                      className="col nxt-btn mr-0"
                      style={{ marginLeft: "847px" }}
                    >
                      <Button
                        className="next-button"
                        type="button"
                        onClick={handleFinish}
                      >
                        {t("save")} <i class="bi bi-arrow-right"></i>
                      </Button>
                    </div>

                    <div className="col nxt-btn mr-0">
                      <Button
                        className="next-button"
                        type="button"
                        onClick={handleNextButtonClick}
                      >
                        {t("next")} <i class="bi bi-arrow-right"></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </FormGroup>
          </div>
        </div>
      </div>

      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Damage`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this Damage part?`}</p>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDelButtonLoad}
            onClick={onDeleteModalClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>

          {!isDelButtonLoad && (
            <button
              type="button"
              className="btn btn-block bg-red"
              style={{ width: "100px" }}
              onClick={onDestroyPartType}
            >
              Delete <i class="bi bi-trash3"></i>
            </button>
          )}
          {isDelButtonLoad && (
            <button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
            </button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AssignedDamageImage;
