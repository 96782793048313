import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  actionFaqAddUpdate,
  destroyfaqList,
  getfaqList,
} from "../../services/FaqService";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";

const FaqAdmin = () => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);
  const { t } = useTranslation();
  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
  });

  const [totalRecords, setTotalRecords] = useState(0);
  const [faqDataList, setFAQdataList] = useState([]);
  const [delModal, setDelModal] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [delpart, setDelPart] = useState("");
  const [modifyModal, setModifyModal] = useState(false);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [faqData, setFaqList] = useState({
    uuid: "",
    question: "",
    answer: "",
    sequence: "",
  });

  let errorsObj = {
    question: "",
    answer: "",
    sequence: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const handleAddModal = () => {
    setModifyModal(true);
  };

  const handleCloseModal = () => {
    setModifyModal(false);
    setFaqList("");
    setErrors("");
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const fetchFaqList = () => {
    let data = { ...params };
    getfaqList(data)
      .then((response) => {
        setFAQdataList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };
      fetchFaqList(data);
    },
    [params]
  );

  const onDeleteModalClose = () => {
    setDelModal(false);
  };

  const onEditFAQ = (e, item) => {
    setModifyModal(true);
    setFaqList({
      uuid: item?.uuid,
      question: item?.question,
      answer: item?.answer,
      sequence: item?.sequence,
    });
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelPart(item.uuid);
    setDelModal(true);
  };

  const onDestroyFAQ = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delpart };
    destroyfaqList(data)
      .then((response) => {
        let data = { ...params };

        fetchFaqList(data);
        toast.success(response.data.message);
        setDelPart("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);

        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handlePartLocationForm = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };
    if (!faqData.question) {
      errorObj.question = `Question is required.`;
      error = true;
    }

    if (!faqData.answer) {
      errorObj.answer = `Answer is required.`;
      error = true;
    }

    if (!faqData.sequence) {
      errorObj.sequence = `sequence is required.`;
      error = true;
    }

    setErrors(errorObj);
    Utils.focusError();

    if (error) return;

    setIsModifyButtonLoad(true);
    let data = {
      answer: faqData.answer,
      sequence: faqData.sequence,
      question: faqData.question,
    };

    let Updatedata = {
      uuid: faqData?.uuid,
      answer: faqData.answer,
      sequence: faqData.sequence,
      question: faqData.question,
    };

    if (faqData?.uuid) {
      actionFaqAddUpdate(Updatedata)
        .then((response) => {
          toast.dismiss();

          toast.success(response.data.message);

          let data = { ...params };
          fetchFaqList(data);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
        })
        .catch((err) => {
          toast.dismiss();
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    } else {
      actionFaqAddUpdate(data)
        .then((response) => {
          toast.dismiss();
          toast.success(response.data.message);
          let data = { ...params };
          fetchFaqList(data);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
        })
        .catch((err) => {
          toast.dismiss();
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    }
  };
  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6"></div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right"></ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid container-custom-height-new">
            <div className="row">
              <div className="col-md-12">
                <div className="card fuelCard">
                  <div className="card-header">
                    <div
                      className="card-title m-0 float-left"
                      style={{ width: "50%" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        value={params.search}
                        onChange={(e) => handleParams(e, "search")}
                        placeholder="Search"
                      ></input>
                    </div>
                    <div className="card-title m-0 float-right d-flex">
                      <button
                        type="button"
                        className="btn  btn-icon-text mr-2"
                        onClick={handleAddModal}
                      >
                        <i className="bi bi-plus-lg"></i>
                        <span className="ms-2">{t("Add")}</span>
                      </button>
                    </div>
                  </div>
                  <div className="card-body fuelCardB">
                    <div className="vehicleThBg table-responsive">
                      <table className="table table-bordered rounded-table table table-striped table-hover">
                        <thead>
                          <tr>
                            <th style={{ width: 10 }}>#</th>
                            <th className="col-form-label ml-2 Label-my form-label form-label">
                              {t("sequence")}
                            </th>
                            <th className="col-form-label ml-2 Label-my form-label form-label">
                              {t("question")}
                            </th>
                            <th className="col-form-label ml-2 Label-my form-label form-label">
                              {t("answer")}
                            </th>

                            <th
                              className="col-form-label ml-2 Label-my form-label form-label"
                              style={{ textAlign: "center", width: 10 }}
                            >
                              {t("action")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {faqDataList?.map((item, index) => {
                            return (
                              <>
                                <tr>
                                  <td>{++index}</td>
                                  <td>{item?.sequence}</td>
                                  <td>{item?.question}</td>
                                  <td>{item?.answer}</td>

                                  <td className="">
                                    <Dropdown
                                      className="iconDropdown"
                                      onToggle={(isOpen) => {
                                        if (isOpen) {
                                          // This logic runs when the dropdown is opened
                                          const tableResponsive =
                                            document.querySelector(
                                              ".table-responsive"
                                            );
                                          if (tableResponsive) {
                                            tableResponsive.style.overflowX =
                                              "inherit !important";
                                            tableResponsive.style.overflowY =
                                              "inherit !important";
                                          }
                                        }
                                      }}
                                    >
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                        className="iconToggle"
                                      >
                                        <i className="bi bi-three-dots-vertical"></i>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          href="#/action-1"
                                          className="d-flex"
                                          onClick={(e) => onEditFAQ(e, item)}
                                        >
                                          <div className="edit-btn">
                                            <i className="bi bi-pencil"></i>
                                          </div>
                                          <span>{t("edit")}</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#/action-2"
                                          className="d-flex"
                                          onClick={(e) =>
                                            onDeleteModalOpen(e, item)
                                          }
                                        >
                                          <div className="trash-btn delete-btn">
                                            <i className="bi bi-trash3"></i>
                                          </div>
                                          <span>{t("delete")}</span>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                        {faqDataList && faqDataList?.length === 0 && (
                          <tr>
                            <td colSpan={7} className={`text-center`}>
                              {" "}
                              {t("no_record_found")}
                            </td>
                          </tr>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fuelCF">
            <div className="card-footer clearfix card-footer-part">
              <div className="pagination pagination-sm m-0 float-right">
                <Pagination
                  activePage={params.page}
                  itemsCountPerPage={parseInt(params.limit)}
                  totalItemsCount={parseInt(totalRecords)}
                  pageRangeDisplayed={5}
                  itemClass={`page-item`}
                  linkClass={`page-link`}
                  onChange={(e) => handleParams(e, "pagination")}
                />
              </div>
            </div>
          </div>
        </section>
      </div>

      <Modal isOpen={delModal}>
        <ModalHeader>{t("delete_faq")}</ModalHeader>
        <ModalBody>
          <p className={`mb-0`}>
            {t("are_you_sure_you_want_to_delete_this_faq")}
          </p>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDelButtonLoad}
            onClick={onDeleteModalClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            {t("close")} <i class="bi bi-x-lg"></i>
          </button>

          {!isDelButtonLoad && (
            <button
              type="button"
              className="btn btn-block bg-red"
              style={{ width: "100px" }}
              onClick={onDestroyFAQ}
            >
              {t("delete")} <i class="bi bi-trash3"></i>
            </button>
          )}
          {isDelButtonLoad && (
            <button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
            </button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyModal}>
        <ModalHeader>{t("faq")}</ModalHeader>
        <Form method={`post`} onSubmit={handlePartLocationForm}>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label Label-my ml-2">
                    {t("question")}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={faqData.question}
                        onChange={(e) => {
                          setFaqList({
                            ...faqData,
                            question: e.target.value,
                          });
                        }}
                        name={`question`}
                        placeholder="Question"
                      />
                    </div>
                    {errors.question && (
                      <span className="input-error">{errors.question}</span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {t("answer")}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <textarea
                        className="form-control input-search"
                        type="text"
                        value={faqData.answer}
                        onChange={(e) => {
                          setFaqList({
                            ...faqData,
                            answer: e.target.value,
                          });
                        }}
                        name={`answer`}
                        placeholder="Answer"
                        rows={4}
                      />
                    </div>
                    {errors.answer && (
                      <span className="input-error">{errors.answer}</span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {t("sequence")}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="number"
                        value={faqData.sequence}
                        onChange={(e) => {
                          setFaqList({
                            ...faqData,
                            sequence: e.target.value,
                          });
                        }}
                        name={`sequence`}
                        maxLength={10}
                        placeholder="Sequence"
                      />
                    </div>
                    {errors.sequence && (
                      <span className="input-error">{errors.sequence}</span>
                    )}
                  </div>
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-primary"
              disabled={isModifyButtonLoad}
              onClick={handleCloseModal}
              style={{ width: "100px", marginTop: "10px" }}
            >
              {t("close")} <i class="bi bi-x-lg"></i>
            </button>
            {!isModifyButtonLoad && (
              <button
                type="submit"
                class="btn btn-block btn-primary"
                style={{ width: "100px", marginTop: "10px" }}
              >
                {t("submit")} <i class="bi bi-check2"></i>
              </button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default FaqAdmin;
