import React, { useEffect, useRef, useState } from "react";
import {
  getCatalogList,
  actionPartInventoryTempStore,
  getVehicleList,
  getPartInventoryTempList,
  DestoryPartInventoryTemp,
  createPartsInventory,
  updatePartsInventory,
  actionPartInventoryImage,
  destoryPartInventoryImage,
  partVehicleProcessUpdateExpiryDate,
  partInvenoryVehicleSearch,
  partInvenoryPartETAIPrice,
  partInvenoryPartETAI,
  PartInventoryImageDetails,
  partRefDelete,
  partDamageDelete,
  getCatalogModalStatsHistoryDetails,
  getPartRefcodeConditionDetails,
  getPartEstimatePriceDetails,
  getPartStatiscalPriceDetails,
} from "../../services/PartsInventory";
import {
  Button,
  FormGroup,
  Label,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Select from "react-select";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getvehicleMakeAllList,
  getVehiclePartList,
  getvehicleYearList,
  updateVehiclePartImageSort,
} from "../../services/VehicleService";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Pagination from "react-js-pagination";
import { getAllViewShelfList } from "../../services/ShelfServices";
import {
  getCommonSearchList,
  getSettingsCompanyDetails,
} from "../../services/settingsServices";
import { getAllBrandSerachList } from "../../services/PartBrandServices";
import moment from "moment/moment";
import { getVehicleAllModalSerachList } from "../../services/VehicleModelService";
import { getVehicleEngineSerachList } from "../../services/VehicleEngineService";
import {
  actionTempStoreDismental,
  getDismentalList,
} from "../../services/DismentalServices";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { getVehicleSerachVariantList } from "../../services/VehicleVariantsService";
import { useTranslation } from "react-i18next";
import ReactStars from "react-rating-stars-component";
import AssignedDamageImage from "./AssignedDamageImage";
import AddPartVideo from "./AddPartVideo";
import { profileDetailForWeb } from "../../services/AuthServices";
import AssignedDamageVideo from "./AssignedDamageVideo";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { ReactSketchCanvas } from "react-sketch-canvas";
import axios from "axios";

const VehiclePartsAdd = ({
  vehicleAddDetails,
  vehicleImageDetails,
  setvehicleImageDetails,
}) => {
  const tableLengthList = ConfigDB?.data?.dataTableLength;
  const navigate = useNavigate();
  const LangData = localStorage.getItem("lng");
  const state = useLocation();
  const { t } = useTranslation();
  const { uuid } = useParams();

  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryTemp, setSearchQueryTemp] = useState("");
  const [VehicleModelAllList, setVehicleModelAllList] = useState([]);
  const [CatalogList, setCatalogList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [tempData, setTempData] = useState({});
  const [partsData, setPartsData] = useState([]);

  const [totalRecords, setTotalRecords] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [inputSelctedValue, setInputSelctedValue] = useState("");
  const [images, setImage] = useState([]);
  const [ShelfList, SetShelfList] = useState([]);
  const [enlargedIndexes, setEnlargedIndexes] = useState([]);
  const [vehiclePartsData, setVehiclePartsData] = useState({
    vehicle_model_id: "",
    vehicle_make: "",
    vehicle_year: "",
    vehicle_model: "",
    vehicle_process_end_date: "",
    vehicle_process_start_date: "",
    vehicle_model_ids: "",
    vehicle_make_id: "",
  });

  const [selectedVehicleModel, setSelectedVehicleModel] = useState(null);
  const [detailsForImages, SetDetailsForImages] = useState([]);
  const [currentPartIndex, setCurrentPartIndex] = useState(0);
  const [showSkip, setShowSkip] = useState(false);
  const [previewUrl, SetPreviewUrl] = useState([]);
  const [showModalAbout, setShowModalAbout] = useState(false);
  const [ImageDetailsNot, setImageDetailsNot] = useState({});
  const [stateList, setStateList] = useState([]);
  const [conditionList, setConditionList] = useState([]);
  const [inventoryList, setInventoryList] = useState([]);
  const [modifyExpiry, setModifyExpiry] = useState(false);
  const [settingDetails, setSettingDetails] = useState("");
  const [totalRecordTemp, setTotalRecordsTemp] = useState(0);
  const [vehicleExceedData, setVehicleExceedData] = useState("");
  const [vehiclePartDetailsModal, setVehiclePartDetailsModal] = useState(false);
  const [vehicleData, setVehicleData] = useState({
    date: "",
  });
  const [allBrandList, SetAllBrandList] = useState([]);
  const [btnLoader, setBtnLoader] = useState(false);
  const [btnLoading, setbtnLoading] = useState(false);
  const [btnClosed, setbtnClosed] = useState(false);
  const [btnNext, setbtnNext] = useState(false);
  const [btnSaveNext, setbtnSaveNext] = useState(false);
  const [btnImage, setbtnImage] = useState(false);
  const [btnImageSave, setImageSave] = useState(false);

  const [filteredSuggestionsModel, setFilteredSuggestionsModel] = useState([]);
  const [activeSuggestionIndexModel, setActiveSuggestionIndexModel] =
    useState(0);
  const [showSuggestionsModel, setShowSuggestionsModel] = useState(false);
  const [vehicleModelAuto, setVehicleModelAuto] = useState("");
  const [selectedModels, setSelectedModels] = useState([]);
  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  const [filteredSuggestionsEngine, setFilteredSuggestionsEngine] = useState(
    []
  );
  const [activeSuggestionIndexEngine, setActiveSuggestionIndexEngine] =
    useState(0);
  const [showSuggestionsEngine, setShowSuggestionsEngine] = useState(false);
  const [vehicleEngineAuto, setVehicleEngineAuto] = useState("");
  const [vehicleEngineId, setVehicleEngineId] = useState({
    vehicle_model_id: "",
    make_id: "",
    vehicle_model_name: "",
    vehicle_process_end_date: "",
    id: "",
    vehicle_in_inventory: "",
    variants_id: "",
    vehicle_make_name: "",
    registration_plate: "",
    registration: "",
  });

  const fileInputRef = useRef(null);

  const [referenceList, setReferenceList] = useState([]);
  const [partETAIRerList, setPartETAIRerList] = useState("");
  const [partEtaiModify, setPartEtaiModify] = useState(false);
  const [partEtaiPriceModify, setPartEtaiPriceModify] = useState(false);
  const [partETAIPriceList, setPartETAIPriceList] = useState("");
  const [partETAIRefDetails, setPartETAIRefDetails] = useState([]);
  const [partETAIPriceDetail, setPartETAIPriceDetail] = useState([]);
  const [selectedReferenceCode, setSelectedReferenceCode] = useState(null);

  const [inputSelctedModalValue, setInputSelctedModalValue] = useState("");
  const [EngineData, setEngineData] = useState({
    engine_code: "",
    kw: "",
    hp: "",
  });

  const [vehicleEngineList, setvehicleEngineList] = useState([]);
  const [inputSelctedEngineValue, setInputSelctedEngineValue] = useState("");
  const [vehicleModalSelectedList, setVehicleModalSelectedList] = useState("");
  const [DismetalList, setDismetalList] = useState([]);
  const [inputSelctedDismetalValue, setInputSelctedDismetalValue] =
    useState("");
  const [dismentalData, setDismentalData] = useState({
    dismantle_id: "",
  });
  const [dismantleTempData, setDismantleTempData] = useState("");
  const [categoryMasterPart, setCategoryMasterPart] = useState({});
  const [modifyMasterPart, setModifyMasterPart] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [modifyImportModal, setModifyImportModal] = useState(false);

  const [modifyMasterVehiclePart, setModifyMasterVehiclePart] = useState(false);
  const [modifyMasterVehicleNotPart, setModifyMasterVehicleNotPart] =
    useState(false);

  const [vehicleYearPartList, setvehicleYearPartList] = useState([]);
  const [inputSelctedYearValue, setInputSelctedYearValue] = useState("");
  const [vehicleMakePartList, setvehicleMakePartList] = useState([]);
  const [inputSelctedMakeValue, setInputSelctedMakeValue] = useState("");
  const [vehicleModalPartList, setvehicleModalPartList] = useState([]);
  const [vehicleVariantPartList, setvehicleVariantPartList] = useState("");

  const [isSaveClicked, setIsSaveClicked] = useState(false);

  const [selectedRows, setSelectedRows] = useState(
    categoryMasterPart?.part_list?.map(() => ({
      vehicle_year: null,
      vehicle_make: null,
      vehicle_model: null,
      vehicle_variant: null,
    })) || []
  );

  const [selectedItemsCheckBox, setSelectedItemsCheckBox] = useState([]);
  const [locationDamageList, setLocationDamageList] = useState([]);
  const [typeDamageList, setTypeDamageList] = useState([]);

  const [modifyDelteRef, setModifyDelteRef] = useState(false);
  const [modifyDelteDamage, setModifyDelteDamage] = useState(false);
  const [DelteRefId, setDelteRefId] = useState({
    pIndex: "",
    rowIndex: "",
    id: "",
  });
  const [DelteDamageId, setDelteDamageId] = useState({
    pIndex: "",
    rowIndex: "",
    id: "",
  });

  const [partImageDataList, setPartImageDataList] = useState([]);

  const [catalogModalStateDataList, setCatalogModalStateDataList] = useState(
    []
  );
  const [catalogModalStateDataId, setCatalogModalStateDataId] = useState("");
  const [modalStateHistory, setModalStateHistory] = useState(false);
  const [modalSponsorPrice, setModalSponsorPrice] = useState(false);
  const [sponsorPriceDataId, setSponsorPriceDataId] = useState("");
  const [sponsorPricDataList, setSponsorPricDataList] = useState([]);

  const [modalEstimatePrice, setModalEstimatePrice] = useState(false);
  const [estimatePriceDataId, setEstimatePriceDataId] = useState("");
  const [estimatePricDataList, setEstimatePricDataList] = useState([]);
  const [profileDetails, setProfileDetails] = useState("");

  const [modalRefCodeCondition, setModalRefCodeCondition] = useState(false);
  const [refCodeConditionDataId, setRefCodeConditionDataId] = useState("");
  const [refCodeConditionDataList, setRefCodeConditionDataList] = useState([]);
  const [partVideoDataList, setPartVideoDataList] = useState([]);

  const [selectedImage, setSelectedImage] = useState(null);

  const [rotation, setRotation] = useState(0);
  const canvasRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const imgRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [crop, setCrop] = useState({ unit: "%", width: 50, height: 50 });
  const [resizeWidth, setResizeWidth] = useState(300);
  const [resizeHeight, setResizeHeight] = useState(300);
  const [highlights, setHighlights] = useState([]);
  const [selectedColor, setSelectedColor] = useState("#ff0000");
  const [isCropping, setIsCropping] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadings, setIsLoadings] = useState(false);
  const [isLoadingColor, setIsLoadingColor] = useState(false);
  const [settingCompanyDetails, setSettingCompanyDetails] = useState("");
  const [isDrawing, setIsDrawing] = useState(false);
  const [modalRefCodeEstimate, setModalRefCodeEstimate] = useState(false);
  const [refCodeEstimateDataList, setRefCodeEstimateDataList] = useState("");

  const onImageLoad = (img) => {
    imgRef.current = img;
  };

  const onImageClick = (e) => {
    const { offsetX, offsetY } = e.nativeEvent;
    const newHighlight = {
      x: offsetX,
      y: offsetY,
      radius: 20,
      color: selectedColor,
    };

    setHighlights([...highlights, newHighlight]);
  };

  const handleCrop = () => {
    setIsCropping(!isCropping);
  };

  const saveDrawing = async (fileId) => {
    setIsDrawing((prev) => !prev);
  };

  // const handleImageSelect = (file) => {
  //   setImage((prevFiles) =>
  //     prevFiles.map((f) => (f.id === file.id ? { ...f } : f))
  //   );
  //   setSelectedImage(file);
  //   setCrop(null);
  //   setRotation(0);
  //   setHighlights([]);
  //   setIsModalOpen(true);
  // };

  const handleImageSelect = (file) => {
    if (file.file) {
      setImage((prevFiles) =>
        prevFiles.map((f) => (f.id === file.id ? { ...f } : f))
      );
      setSelectedImage(file);
      setCrop(null);
      setRotation(0);
      setHighlights([]);
      setIsModalOpen(true);
    } else {
      axios({
        url: file.url,
        method: "GET",
        responseType: "blob",
      })
        .then((res) => {
          const myFile = new File([res.data], "image.png", {
            type: res.data.type,
          });

          let fileData = file;
          fileData.url = URL.createObjectURL(myFile);
          fileData.file = myFile;

          setImage((prevFiles) =>
            prevFiles.map((f) => (f.id === fileData.id ? { ...f } : f))
          );
          setSelectedImage(fileData);
          setCrop(null);
          setRotation(0);
          setHighlights([]);
          setIsModalOpen(true);
        })
        .catch((err) => {
          console.error("Error fetching the file:", err);
        });
    }
  };

  const getCroppedImage = async () => {
    if (!imgRef.current || !selectedImage) return;

    const image = imgRef.current;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    let cropX = 0,
      cropY = 0,
      cropWidth = image.naturalWidth,
      cropHeight = image.naturalHeight;

    if (completedCrop) {
      cropX = completedCrop.x * scaleX;
      cropY = completedCrop.y * scaleY;
      cropWidth = completedCrop.width * scaleX;
      cropHeight = completedCrop.height * scaleY;
    }

    const radians = (rotation * Math.PI) / 180;
    canvas.width = cropWidth;
    canvas.height = cropHeight;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.save();

    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate(radians);
    ctx.translate(-canvas.width / 2, -canvas.height / 2);

    ctx.drawImage(
      image,
      cropX,
      cropY,
      cropWidth,
      cropHeight,
      0,
      0,
      cropWidth,
      cropHeight
    );

    ctx.restore();

    // Overlay Drawing if available
    if (canvasRef.current) {
      try {
        const drawingDataURL = await canvasRef.current.exportImage("png");

        if (drawingDataURL) {
          const drawingImage = new Image();
          drawingImage.src = drawingDataURL;
          await new Promise((resolve) => (drawingImage.onload = resolve));

          ctx.drawImage(drawingImage, 0, 0, cropWidth, cropHeight);
        }
      } catch (error) {
        console.error("Error exporting drawing:", error);
      }
    }

    const editedImage = canvas.toDataURL("image/jpeg");

    // Update the image in the state
    setImage((prevFiles) =>
      prevFiles.map((file) =>
        file.id === selectedImage.id ? { ...file, url: editedImage } : file
      )
    );

    // Cleanup
    setSelectedImage(null);
    setIsModalOpen(false);
    setCompletedCrop(null);
    setHighlights([]);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const companyResponse = await getSettingsCompanyDetails();
        setSettingCompanyDetails(companyResponse.data.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchSettings();
  }, []);

  const handleRemoveBgImageeForm = async (fileId) => {
    const selectedImage = images.find((file) => file.id === fileId);

    if (!selectedImage) {
      toast.error("Please select an image first.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedImage.file);

    setIsLoading(true);

    try {
      const response = await fetch(
        "https://imagemagic.dailybills.in/remove-bg/",
        {
          method: "POST",
          body: formData,
        }
      );

      let imageBlob;

      if (!response.ok) throw new Error("Failed to remove background");
      imageBlob = await response.blob();

      const imageUrl = URL.createObjectURL(imageBlob);
      setImage((prevFiles) =>
        prevFiles.map((file) =>
          file.id === fileId
            ? { ...file, url: imageUrl, file: imageBlob }
            : file
        )
      );

      toast.success("Background removed successfully!");
      setSelectedImage({ url: imageUrl });
      setIsModalOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddBgImageForm = async (fileId) => {
    const selectedImage = images.find((file) => file.id === fileId);
    const proxyUrl = "https://api.allorigins.win/raw?url=";

    const response = await fetch(
      proxyUrl + encodeURIComponent(settingCompanyDetails?.default_image)
    );
    if (!response.ok) throw new Error("Default image is not available");

    const blob = await response.blob();
    const file = new File([blob], "background.jpg", { type: blob.type });

    if (!selectedImage) {
      toast.error("Please select an image first.");
      return;
    }

    const formData = new FormData();
    formData.append("main_image", selectedImage?.file);
    formData.append("bg_image", file);

    setIsLoadings(true);

    try {
      const response = await fetch("https://imagemagic.dailybills.in/add-bg/", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) throw new Error("Failed to remove background");

      const imageBlob = await response.blob();
      const imageUrl = URL.createObjectURL(imageBlob);
      setImage((prevFiles) =>
        prevFiles.map((file) =>
          file.id === fileId
            ? { ...file, url: imageUrl, file: imageBlob }
            : file
        )
      );

      toast.success("Background add successfully!");
      setSelectedImage({ url: imageUrl });
      setIsModalOpen(false);
    } catch (error) {
    } finally {
      setIsLoadings(false);
    }
  };

  const handleAddBgColoreForm = async (fileId) => {
    const selectedImage = images.find((file) => file.id === fileId);

    if (!selectedImage) {
      toast.error("Please select an image first.");
      return;
    }

    const encodedColor = encodeURIComponent(
      settingCompanyDetails?.default_color
    );

    const formData = new FormData();
    formData.append("main_image", selectedImage.file);

    setIsLoadingColor(true);

    try {
      const response = await fetch(
        `https://imagemagic.dailybills.in/add-bg-color/?hex_color=${encodedColor}`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) throw new Error("Failed to add background color");

      const imageBlob = await response.blob();
      const imageUrl = URL.createObjectURL(imageBlob);
      setImage((prevFiles) =>
        prevFiles.map((file) =>
          file.id === fileId
            ? { ...file, url: imageUrl, file: imageBlob }
            : file
        )
      );

      toast.success("Background color added successfully!");
      setSelectedImage({ url: imageUrl });
      setIsModalOpen(false);
    } catch (error) {
      toast.error(error.message || "Something went wrong");
    } finally {
      setIsLoadingColor(false);
    }
  };

  const handleRotate = async (fileId) => {
    setRotation((prev) => (prev + 90) % 360);
  };

  const handleOpenRefCodeEstimate = (e, item) => {
    setRefCodeConditionDataId(item);
    setModalRefCodeEstimate(true);
  };

  const handleCloseRefCodeEstimate = () => {
    setModalRefCodeEstimate(false);
  };

  const handleOpenPartHistory = (e, item) => {
    setCatalogModalStateDataId(item);
    setModalStateHistory(true);
  };

  const handleClosePartHistory = () => {
    setModalStateHistory(false);
  };

  const handleOpenSponsorPrice = (e, item) => {
    setSponsorPriceDataId(item);
    setModalSponsorPrice(true);
  };

  const handleCloseSponsorPrice = () => {
    setModalSponsorPrice(false);
  };

  const handleOpenEstimatePrice = (e, item) => {
    setEstimatePriceDataId(item);
    setModalEstimatePrice(true);
  };

  const handleCloseEstimatePrice = () => {
    setModalEstimatePrice(false);
  };

  const handleOpenRefCodeCondition = (e, item) => {
    setRefCodeConditionDataId(item);
    setModalRefCodeCondition(true);
  };

  const handleCloseRefCodeCondition = () => {
    setModalRefCodeCondition(false);
  };

  const handleRefClose = () => {
    setModifyDelteRef(false);
  };

  const handleDamageClose = () => {
    setModifyDelteDamage(false);
  };

  const handleRatingChange = (newRating, partId) => {
    setPartsData((prevParts) =>
      prevParts.map((part) =>
        part.tempId === partId ? { ...part, part_rating: newRating } : part
      )
    );
  };

  const handleCheckboxChangeMaster = (index, item) => {
    setSelectedItemsCheckBox((prevSelectedItems) => {
      if (prevSelectedItems.some((selected) => selected.index === index)) {
        return prevSelectedItems.filter((selected) => selected.index !== index);
      } else {
        return [...prevSelectedItems, { index, item }];
      }
    });
  };

  const handleSubmitAdd = () => {
    if (!selectedRows || Object.keys(selectedRows).length === 0) {
      console.error("selectedRows is null, undefined, or empty");
      return;
    }

    const selectedData = selectedItemsCheckBox?.map(({ item, index }) => ({
      catalog_id: item?.reference_id || "",
      vehicle_make: vehicleAddDetails?.vehicle_make || "",
      vehicle_year: vehicleAddDetails?.vehicle_year || "",
      vehicle_model: vehicleAddDetails?.vehicle_model || "",
      vehicle_variant: vehicleAddDetails?.variants_id || "",
      master_part_id: item?.parts_catalog_id || 0,
    }));

    const data = {
      temp_id: tempData?.temp_id || dismantleTempData?.temp_id || "",
      vehicle_id: 0,
      part_list: selectedData,
    };

    actionPartInventoryTempStore(data)
      .then((response) => {
        const data = response.data.data;
        // toast.success("Data saved successfully!");

        navigate("/parts-inventory-addparts", { state: { data } });
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(() => {
    if (catalogModalStateDataId) {
      const data = {
        search: "",
        limit: 10,
        page: 1,
        part_catalog_id: catalogModalStateDataId?.id,
        vehicle_model_id: catalogModalStateDataId?.vehicle_model,
      };

      getCatalogModalStatsHistoryDetails(data)
        .then((response) => {
          setCatalogModalStateDataList(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [catalogModalStateDataId]);

  useEffect(() => {
    if (sponsorPriceDataId) {
      const data = {
        search: "",
        limit: 10,
        page: 1,
        part_catelog: sponsorPriceDataId?.id,
        condition_id: settingDetails?.condition_id,
        state_id: settingDetails?.state_id,
      };

      getPartStatiscalPriceDetails(data)
        .then((response) => {
          setSponsorPricDataList(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [sponsorPriceDataId]);

  useEffect(() => {
    profileDetailForWeb()
      .then((response) => {
        setProfileDetails(response.data.data);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  }, []);

  useEffect(() => {
    if (estimatePriceDataId) {
      const data = {
        regNumberCompany:
          profileDetails?.company_details?.company_registration_number,
        // regNumberCompany: "508040710",
        language: LangData,
        ktype: "",
        tecDocModel: "",
        engineCode: "",
        condition: settingDetails?.condition_name,
        state: settingDetails?.state_name,
        partMakeDescription: "",
        references: [],
      };

      getPartEstimatePriceDetails(data)
        .then((response) => {
          setEstimatePricDataList(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [estimatePriceDataId]);

  useEffect(() => {
    if (!refCodeConditionDataId?.addedData?.length) return;

    const refCodeConditionData = refCodeConditionDataId?.addedData?.map(
      (item) => ({
        ref_code: item?.reference_code,
        condition: item?.condition_id_ref,
      })
    );

    const requestData = {
      search: "",
      limit: 10,
      page: 1,
      refcode_condition: refCodeConditionData,
    };

    getPartRefcodeConditionDetails(requestData)
      .then((response) => setRefCodeConditionDataList(response.data.data))
      .catch((err) => toast.error(Utils.getErrorMessage(err)));
  }, [refCodeConditionDataId]);

  useEffect(() => {
    if (!refCodeConditionDataId?.addedData?.length) return;

    const refCodeConditionData = refCodeConditionDataId?.addedData?.map(
      (item) => ({
        ref_code: item?.reference_code,
        condition: item?.condition_id_ref,
      })
    );

    const requestData = {
      regNumberCompany:
        profileDetails?.company_details?.company_registration_number,
      language: LangData,
      ktype: "",
      tecDocModel: "",
      engineCode: "",
      condition: settingDetails?.condition_name,
      state: settingDetails?.state_name,
      partMakeDescription: "",
      refcode_condition: refCodeConditionData,
    };
    getPartEstimatePriceDetails(requestData)
      .then((response) => {
        setRefCodeEstimateDataList(response.data.data);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  }, [refCodeConditionDataId]);

  useEffect(() => {
    if (
      categoryMasterPart?.part_list &&
      selectedRows.length !== categoryMasterPart?.part_list.length
    ) {
      setSelectedRows(
        categoryMasterPart?.part_list?.map(() => ({
          vehicle_year: null,
          vehicle_make: null,
          vehicle_model: null,
          vehicle_variant: null,
        }))
      );
    }
  }, [categoryMasterPart?.part_list]);

  const handleSelectChangeMaster = (index, field, value) => {
    if (selectedRows[index]) {
      const updatedRows = [...selectedRows];
      updatedRows[index][field] = value;
      setSelectedRows(updatedRows);
    }
  };

  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };

    getvehicleYearList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i],
            label: response.data[i],
          });
        }
        setvehicleYearPartList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "type_damage",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setTypeDamageList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "location_damage",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setLocationDamageList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(
    function () {
      if (selectedRows?.some((item) => item?.vehicle_year)) {
        var obj = {
          search: "",
          limit: tableLengthList[0],
          page: 1,
          year: selectedRows
            ?.map((item) => item?.vehicle_year)
            .filter((year) => year !== null)[0],
        };

        getvehicleMakeAllList(obj)
          .then((response) => {
            response = response.data;
            let list = [];
            for (let i = 0; i < response.data.length; i++) {
              list.push({
                value: response.data[i].id,
                label: response.data[i].description,
              });
            }
            setvehicleMakePartList(list);
          })
          .catch((err) => {});
      }
    },

    [selectedRows]
  );

  const handleInputSelectedChangeMakeList = (inputSelctedMakeValue) => {
    setInputSelctedMakeValue(inputSelctedMakeValue);

    if (selectedRows?.some((item) => item?.vehicle_year)) {
      var obj = {
        search: inputSelctedMakeValue,
        limit: tableLengthList[0],
        page: 1,
        year: selectedRows
          ?.map((item) => item?.vehicle_year)
          .filter((year) => year !== null)[0], // Filter out null years
      };

      getvehicleMakeAllList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].description,
            });
          }
          setvehicleMakePartList(list);
        })
        .catch((err) => {});
    }
  };

  useEffect(
    function () {
      if (selectedRows?.some((item) => item?.vehicle_make)) {
        var obj = {
          search: "",
          limit: tableLengthList[0],
          page: 1,
          make_id: selectedRows
            ?.map((item) => item?.vehicle_make)
            .filter((make) => make !== null)[0], // Filter out null years
        };

        getVehicleAllModalSerachList(obj)
          .then((response) => {
            response = response.data;
            let list = [];
            for (let i = 0; i < response.data.length; i++) {
              list.push({
                value: response.data[i].id,
                label: response.data[i].description,
              });
            }
            setvehicleModalPartList(list);
          })
          .catch((err) => {});
      }
    },
    [selectedRows]
  );

  useEffect(
    function () {
      if (selectedRows?.some((item) => item?.vehicle_model)) {
        var obj = {
          search: "",
          limit: tableLengthList[0],
          page: 1,
          model_id: selectedRows
            ?.map((item) => item?.vehicle_model)
            .filter((modal) => modal !== null)[0], // Filter out null years
        };

        getVehicleSerachVariantList(obj)
          .then((response) => {
            response = response.data;
            let list = [];
            for (let i = 0; i < response.data.length; i++) {
              list.push({
                value: response.data[i].id,
                label: response.data[i].variants_name,
              });
            }
            setvehicleVariantPartList(list);
          })
          .catch((err) => {});
      }
    },
    [selectedRows]
  );

  const filteredData = vehicleAddDetails?.inventory_part_list?.filter(
    (inventoryItem) =>
      vehicleAddDetails?.part_list?.some(
        (partItem) => partItem?.reference_id === inventoryItem?.part_catalog_id
      )
  );
  const handlMasterVehicleNotOpen = () => {
    setModifyMasterVehicleNotPart(true);
  };

  const handlMasterVehicleNotClose = () => {
    setModifyMasterVehicleNotPart(false);
  };

  const handlMasterVehicleClose = () => {
    setModifyMasterVehiclePart(false);
  };

  const handlMasterVehicleOpen = () => {
    setModifyMasterVehiclePart(true);
  };

  const handleCheck = (itemId) => {
    setCheckedItems((prevState) => {
      if (prevState.includes(itemId)) {
        return prevState.filter((id) => id !== itemId);
      } else {
        return [...prevState, itemId];
      }
    });
  };

  const handleTempDismetalAddClick = (e) => {
    e.preventDefault();

    if (vehicleModalSelectedList?.id) {
      let data = {
        dismantle_id: dismentalData?.dismantle_id,
        temp_id: dismantleTempData?.temp_id || tempData?.temp_id || "",
        vehicle_id: vehicleModalSelectedList?.id,
      };

      actionTempStoreDismental(data)
        .then((response) => {
          setDismantleTempData(response.data.data);

          setDismentalData({
            dismantle_id: "",
          });

          setInputSelctedDismetalValue("");
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      toast.error("Please select Vehicle");
    }
  };

  const handleSelectWithoutChangeDismetalList = (option) => {
    setDismentalData((prevData) => ({
      ...prevData,
      dismantle_id: option.value,
    }));
  };

  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };

    getDismentalList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setDismetalList(list);
      })
      .catch((err) => {});
  }, []);
  useEffect(function () {
    var obj = {
      search: "",
      limit: tableLengthList[0],
      page: 1,
    };

    getVehicleEngineSerachList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].motor_code,
            kw: response.data[i].kw,
            hp: response.data[i].hp,
          });
        }
        setvehicleEngineList(list);
      })
      .catch((err) => {});
  }, []);

  const handleInputSelectedChangeEngineList = (inputSelctedEngineValue) => {
    setInputSelctedEngineValue(inputSelctedEngineValue);

    if (inputSelctedEngineValue.length > 0) {
      const obj = {
        search: inputSelctedEngineValue,
        limit: tableLengthList[0],
        page: 1,
      };

      getVehicleEngineSerachList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].motor_code,
              kw: response.data[i].kw,
              hp: response.data[i].hp,
            });
          }
          setvehicleEngineList(list);
        })
        .catch((err) => {});
    }
  };

  const handleInputSelectedChangeDismetalList = (inputSelctedDismetalValue) => {
    setInputSelctedDismetalValue(inputSelctedDismetalValue);

    if (inputSelctedDismetalValue.length > 0) {
      const obj = {
        search: inputSelctedDismetalValue,
        limit: tableLengthList[0],
        page: 1,
      };

      getDismentalList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }
          setDismetalList(list);
        })
        .catch((err) => {});
    }
  };

  const handleSelectWithoutChangeEngineList = (option) => {
    setEngineData((prevData) => ({
      ...prevData,
      engine_code: option.value,
      kw: option.kw,
      hp: option.hp,
    }));
  };

  const handleRadioChange = (referenceCode) => {
    setSelectedReferenceCode(referenceCode);
  };

  const handleEtaiDetails = (event, etaiPartIds, index) => {
    event.preventDefault();
    setPartETAIRerList(etaiPartIds);
    setPartEtaiModify(true);
  };

  const handleEtaiPriceDetails = (event, etaiPartIds, index) => {
    event.preventDefault();
    setPartETAIPriceList(etaiPartIds);
    setPartEtaiPriceModify(true);
  };

  const handlePartEtaiClose = () => {
    setPartEtaiModify(false);
  };

  const handlePartEtaiPriceClose = () => {
    setPartEtaiPriceModify(false);
  };

  useEffect(() => {
    if (partETAIRerList) {
      const data = {
        etai_part_id: partETAIRerList,
      };

      partInvenoryPartETAI(data)
        .then((response) => {
          setPartETAIRefDetails(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [partETAIRerList]);

  useEffect(() => {
    if (partETAIPriceList) {
      const data = {
        etai_part_id: partETAIPriceList,
      };

      partInvenoryPartETAIPrice(data)
        .then((response) => {
          setPartETAIPriceDetail(response.data.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [partETAIPriceList]);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "reference",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setReferenceList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (state?.state?.item) {
      setVehicleEngineId({
        vehicle_model_id: state?.state?.item?.vehicle_model_id,
        make_id: state?.state?.item?.make_id,
        vehicle_model_name: state?.state?.item?.vehicle_model,
        vehicle_process_end_date: state?.state?.item?.vehicle_process_end_date,
        id: state?.state?.item?.id,
        vehicle_in_inventory: state?.state?.item?.vehicle_in_inventory,
        variants_id: state?.state?.item?.variants_id,
        vehicle_make_name: state?.state?.vehicle_make_name,
        registration_plate: state?.state?.registration_plate,
        registration: state?.state?.registration,
      });
      setVehicleEngineAuto(state?.state?.item?.vehicle_model);
    }
  }, [state]);

  const excludeIds = vehicleExceedData?.parts?.map((x) =>
    parseInt(x.part_catelog, 10)
  );

  const handleChangeEngine = (e) => {
    const userInput = e.target.value;

    let data = {
      limit: params.limit,
      page: params.page,
      search: userInput,
      is_reload: true,
    };
    if (userInput.length > 0) {
      partInvenoryVehicleSearch(data)
        .then((response) => {
          setFilteredSuggestionsEngine(response.data.data);
        })
        .catch((err) => {});
    }

    setVehicleEngineAuto(userInput);
    setActiveSuggestionIndexEngine(0);
    setShowSuggestionsEngine(true);
  };

  const handleClickEngine = (suggestion) => {
    setVehicleEngineId({
      vehicle_model_id: suggestion?.vehicle_model_id,
      make_id: suggestion?.make_id,
      vehicle_model_name: suggestion?.vehicle_model_name,
      vehicle_process_end_date: suggestion?.vehicle_process_end_date,
      id: suggestion?.id,
      vehicle_in_inventory: suggestion?.vehicle_in_inventory,
      variants_id: suggestion?.variants_id,
      vehicle_make_name: suggestion?.vehicle_make_name,
      registration_plate: suggestion?.registration_plate,
      registration: suggestion?.registration,
    });
    setVehicleEngineAuto(suggestion.vehicle_model_name);
    setFilteredSuggestionsEngine([]);
    setShowSuggestionsEngine(false);
  };

  const handleKeyDownEngine = (e) => {
    if (e.key === "ArrowDown") {
      if (
        filteredSuggestionsEngine &&
        activeSuggestionIndexEngine < filteredSuggestionsEngine?.length - 1
      ) {
        setActiveSuggestionIndexEngine(activeSuggestionIndexEngine + 1);
      }
    } else if (e.key === "ArrowUp") {
      if (activeSuggestionIndexEngine > 0) {
        setActiveSuggestionIndexEngine(activeSuggestionIndexEngine - 1);
      }
    } else if (e.key === "Enter") {
      setVehicleEngineAuto(
        filteredSuggestionsEngine[activeSuggestionIndexEngine]
      );
      setShowSuggestionsEngine(false);
      setFilteredSuggestionsEngine([]);
    }
  };

  const filteredCatalogList = CatalogList?.data?.filter(
    (item) => !excludeIds?.includes(parseInt(item?.id, 10))
  );

  const handleChangeModel = (e) => {
    const userInput = e.target.value;

    let data = {
      limit: params.limit,
      page: params.page,
      search: userInput,
      is_reload: true,
      make_id: 0,
    };
    if (userInput.length > 0) {
      getVehicleAllModalSerachList(data)
        .then((response) => {
          setFilteredSuggestionsModel(response.data.data);
        })
        .catch((err) => {});
    }

    setVehicleModelAuto(userInput);
    setActiveSuggestionIndexModel(0);
    setShowSuggestionsModel(true);
  };

  const handleClickModel = (suggestion) => {
    const isAlreadySelected = selectedModels.some(
      (model) => model.id === suggestion.id
    );

    if (isAlreadySelected) {
      setSelectedModels(
        selectedModels.filter((model) => model.id !== suggestion.id)
      );
    } else {
      setSelectedModels([...selectedModels, suggestion]);
    }

    setVehicleModelAuto("");
    setFilteredSuggestionsModel([]);
    setShowSuggestionsModel(false);
  };

  const handleKeyDownModel = (e) => {
    if (e.key === "ArrowDown") {
      if (
        filteredSuggestionsModel &&
        activeSuggestionIndexModel < filteredSuggestionsModel?.length - 1
      ) {
        setActiveSuggestionIndexModel(activeSuggestionIndexModel + 1);
      }
    } else if (e.key === "ArrowUp") {
      if (activeSuggestionIndexModel > 0) {
        setActiveSuggestionIndexModel(activeSuggestionIndexModel - 1);
      }
    } else if (e.key === "Enter") {
      setVehicleModelAuto(filteredSuggestionsModel[activeSuggestionIndexModel]);
      setShowSuggestionsModel(false);
      setFilteredSuggestionsModel([]);
    }
  };

  const ExpiryModalOpen = () => {
    setModifyExpiry(true);
  };

  const ExpiryModalclose = () => {
    setModifyExpiry(false);
  };

  const handleSkip = () => {
    setCurrentPartIndex((prevIndex) => prevIndex + 1);
    setShowSkip(false);
  };

  const handleOpenSkip = () => {
    setShowSkip(true);
  };

  const handleCloseSkip = () => {
    setShowSkip(false);
  };

  const handleFinish = () => {
    const hasNewImages = images.some((item) => item.file instanceof File);

    if (!hasNewImages) {
      setCurrentStep(4);
    } else if (vehicleAddDetails?.id) {
      const formData = new FormData();
      formData.append("part_id", vehicleAddDetails?.id);
      images.forEach((item) => {
        if (item.file instanceof File) {
          formData.append("files", item.file);
        } else {
          console.error("Invalid file object:", item.file);
        }
      });

      actionPartInventoryImage(formData)
        .then((response) => {
          toast.success(response.data.message);
          setPartImageDataList(response.data.data);

          setImage([]);
          SetPreviewUrl([]);
          if (partsData?.some((part) => part.parts_damage.length > 0)) {
            setCurrentStep(4);
          } else {
            // navigate("/parts-inventory");
            setCurrentStep(5);
          }

          // navigate("/parts-inventory");
        })
        .catch((err) => {
          toast.dismiss();

          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      const formData = new FormData();
      formData.append("part_id", currentPart?.id);
      images.forEach((item) => {
        if (item.file instanceof File) {
          formData.append("files", item.file);
        } else {
          console.error("Invalid file object:", item.file);
        }
      });
      actionPartInventoryImage(formData)
        .then((response) => {
          toast.success(response.data.message);
          setPartImageDataList(response.data.data);

          setImage([]);
          SetPreviewUrl([]);
          if (partsData?.some((part) => part.parts_damage.length > 0)) {
            setCurrentStep(4);
          } else {
            // navigate("/parts-inventory");
            setCurrentStep(5);
          }
        })
        .catch((err) => {
          setbtnImage(false);
          setImageSave(false);
          toast.dismiss();
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  const onvehiclePartDetailsOpen = (e, item) => {
    e.preventDefault();
    setVehiclePartDetailsModal(true);
  };

  const onvehiclePartDetailsClose = () => {
    setVehiclePartDetailsModal(false);
  };

  useEffect(() => {
    const obj = {
      search: "",
      limit: 10,
      page: 1,
      placeName: "",
    };

    getAllBrandSerachList(obj)
      .then((response) => {
        response = response.data;
        let list = [];

        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].descriptionPT,
          });
        }
        SetAllBrandList(list);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (vehicleModalSelectedList?.id) {
      let data = {
        vehicle_id: vehicleModalSelectedList.id,
      };
      getVehiclePartList(data)
        .then((response) => {
          setVehicleExceedData(response.data.data);
        })
        .catch((err) => {
          toast.dismiss();

          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [vehicleModalSelectedList]);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const companyResponse = await getSettingsCompanyDetails();

        setSettingDetails(companyResponse.data.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchSettings();
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "state",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setStateList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "part_inventory",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setInventoryList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "condition",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setConditionList(list);
      })
      .catch((err) => {});
  }, []);

  const currentPart =
    vehicleImageDetails && vehicleImageDetails?.length > currentPartIndex
      ? vehicleImageDetails[currentPartIndex]
      : detailsForImages && detailsForImages?.length > currentPartIndex
      ? detailsForImages[currentPartIndex]
      : null;

  const handleSubmits = (event) => {
    event.preventDefault();
    const hasNewImages = images.some((item) => item.file instanceof File);

    if (vehicleAddDetails?.id && !hasNewImages) {
      setCurrentPartIndex((prevIndex) => prevIndex + 1);
    } else {
      const formData = new FormData();
      formData.append("part_id", currentPart?.id);
      images.forEach((item) => {
        if (item.file instanceof File) {
          formData.append("files", item.file);
        } else {
          console.error("Invalid file object:", item.file);
        }
      });
      actionPartInventoryImage(formData)
        .then((response) => {
          toast.success(response.data.message);
          setImage([]);
          SetPreviewUrl([]);
          setCurrentPartIndex((prevIndex) => prevIndex + 1);
          if (images.length === 1) {
            fileInputRef.current.value = "";
          }
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  const handleAboutFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);

    if (selectedFiles.length > 0) {
      const formattedFiles = selectedFiles.map((file, index) => ({
        id: `${Date.now()}-${index}`,
        file,
        url: URL.createObjectURL(file),
      }));

      setImage((prevImages) => [...prevImages, ...formattedFiles]);

      SetPreviewUrl((prevUrls) => {
        return Array.isArray(prevUrls)
          ? [...prevUrls, ...formattedFiles.map((file) => file.url)]
          : formattedFiles.map((file) => file.url);
      });
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedFiles = Array.from(images);
    const [movedFile] = reorderedFiles.splice(result.source.index, 1);
    reorderedFiles.splice(result.destination.index, 0, movedFile);

    setImage(reorderedFiles);
  };
  const handleSaveOrder = () => {
    let simplifiedFiles = images
      .filter((file) => !/^\d{13}-\d+$/.test(file.id))
      .map((file, index) => ({
        id: file.id,
        sort_order: index + 1,
      }));

    if (simplifiedFiles.length === 0) {
      simplifiedFiles = images.map((_, index) => ({
        id: 0,
        sort_order: index + 1,
      }));
    }

    const data = { sort_list: simplifiedFiles };

    updateVehiclePartImageSort(data)
      .then((response) => {
        toast.success(response.data.message);
        setModifyImportModal(false);
      })
      .catch((err) => toast.error(Utils.getErrorMessage(err)));
  };

  const isLastPart =
    (vehicleImageDetails &&
      currentPartIndex === vehicleImageDetails?.length - 1) ||
    (detailsForImages && currentPartIndex === detailsForImages?.length - 1);

  const handleRemoveFile = (indexToRemove, item) => {
    if (typeof item === "string" && item.includes("-")) {
      setImage((prevFiles) =>
        prevFiles.filter((_, index) => index !== indexToRemove)
      );
      SetPreviewUrl((prevUrls) =>
        prevUrls.filter((_, index) => index !== indexToRemove)
      );
      toast.success("Image removed successfully.");
      return;
    }

    if (item) {
      const data = { id: item };
      destoryPartInventoryImage(data)
        .then((response) => {
          toast.success(response.data.message);

          setImage((prevFiles) =>
            prevFiles.filter((_, index) => index !== indexToRemove)
          );
          SetPreviewUrl((prevUrls) =>
            prevUrls.filter((_, index) => index !== indexToRemove)
          );

          var obj = { id: uuid };
          PartInventoryImageDetails(obj)
            .then((response) => {
              setvehicleImageDetails(response.data.data);
            })
            .catch((err) => {});
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  const handleShowAbout = () => setShowModalAbout(true);
  const handleCloseAbout = () => setShowModalAbout(false);

  let errorsObj = {
    quantity: "",
    part_price: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const handleImportModal = () => {
    setModifyImportModal(true);
    if (vehicleAddDetails?.id) {
      setImage(vehicleImageDetails);
    }
  };
  useEffect(() => {
    if (vehicleAddDetails?.id) {
      setImage(vehicleImageDetails);
      const previewUrls = vehicleImageDetails?.map((img) => img.image_url);
      SetPreviewUrl(previewUrls);
    }
  }, [vehicleAddDetails]);

  const handleImportCloseModal = () => setModifyImportModal(false);

  useEffect(() => {
    const isDuplicatePart = (part, newPart) => {
      return (
        part.part_code === newPart.part_code &&
        part.vehicle_make === newPart.vehicle_make &&
        part.vehicle_model === newPart.vehicle_model &&
        part.vehicle_year === newPart.vehicle_year
      );
    };

    if (vehicleAddDetails?.id) {
      const newPart = {
        parts_internal_id: vehicleAddDetails?.parts_internal_id,
        id: vehicleAddDetails.part_catelog,
        namePartPT: vehicleAddDetails.namePartPT,
        part_code: vehicleAddDetails?.part_code,
        part_name: vehicleAddDetails?.part_name,
        quantity: vehicleAddDetails?.quantity,
        part_price: vehicleAddDetails?.part_price,
        part_description: vehicleAddDetails?.part_description,
        inventory_status: vehicleAddDetails?.inventory_status,
        parts_associate: vehicleAddDetails?.parts_associate,
        vehicle_make: vehicleAddDetails.vehicle_make,
        vehicle_model: vehicleAddDetails.vehicle_model,
        vehicle_model_id: vehicleAddDetails.vehicle_parts_id,
        vehicle_year: vehicleAddDetails.vehicle_year,
        print_label: vehicleAddDetails?.print_label,
        associate_location: vehicleAddDetails?.associate_location,
        state_id: vehicleAddDetails?.state_id,
        condition_id: vehicleAddDetails?.condition_id,
        part_cost_price: vehicleAddDetails?.part_cost_price,
        part_original_price: vehicleAddDetails?.part_original_price,
        is_custom_size: vehicleAddDetails?.is_custom_size,
        parts_weight: vehicleAddDetails?.parts_weight,
        parts_width: vehicleAddDetails?.parts_width,
        parts_height: vehicleAddDetails?.parts_height,
        parts_length: vehicleAddDetails?.parts_length,
        brand_id: vehicleAddDetails?.brand_id,
        addedData: vehicleAddDetails?.parts_reference,
        vat_included: vehicleAddDetails?.vat_included,
        reg_number_dismantler: vehicleAddDetails?.reg_number_dismantler,
        vehicle_model_name: vehicleAddDetails?.vehicle_model_name,
        vehicle_make_name: vehicleAddDetails?.vehicle_make_name,
        is_master_part: vehicleAddDetails?.is_master_part,
        master_part_id: vehicleAddDetails?.master_part_id,
        part_rating: vehicleAddDetails?.part_rating,
        parts_damage: vehicleAddDetails?.parts_damage,
        part_model_stats: vehicleAddDetails?.part_model_stats,
      };
      setSelectedModels(vehicleAddDetails?.parts_associate);
      setCategoryMasterPart(vehicleAddDetails);

      setPartsData((prevData) => {
        if (!prevData.some((part) => isDuplicatePart(part, newPart))) {
          return [...prevData, newPart];
        }
        return prevData;
      });
    } else if (selectedItems && selectedItems.length > 0) {
      const selectedParts = selectedItems?.map((item) => ({
        tempId: item.id,
        temp_id: item.temp_id,
        id: item.catalog_id,
        is_master_part: item.is_master_part,
        part_model_stats: item?.part_model_stats,

        master_part_id: item.master_part_id,
        parts_internal_id: item.parts_internal_id,
        etai_part_id: item?.etai_part_id,
        namePartPT: item.namePartPT,
        part_name: item.namePartPT,
        quantity: "1",
        part_price: "0",
        state_id: settingDetails?.state_id ? settingDetails?.state_id : "",
        condition_id: settingDetails ? settingDetails?.condition_id : 1,
        part_original_price: "0",
        part_cost_price: "0",
        is_custom_size: "0",
        parts_weight: "0",
        parts_width: "0",
        parts_height: "0",
        parts_length: "0",
        inventory_status: "1",
        print_label: settingDetails?.print_label
          ? settingDetails?.print_label
          : "0",
        vat_included: settingDetails?.vat_included
          ? settingDetails?.vat_included
          : "0",
        part_description: "",
        vehicle_make: item.vehicle_make,
        vehicle_model: item.vehicle_model,
        vehicle_model_id: item.vehicle_model_id,
        vehicle_year: item.initial_year,
        brand_id: "0",
        reference_code: "",
        type_id: null,
        is_main: 1,
        condition_id_ref: "",
        addedData: [],
        vehicle_model_name: item.vehicle_model_name,
        vehicle_make_name: item?.vehicle_make_name,
        is_show_engine_record: item?.is_show_engine_record,

        engine_code: "",
        variants_id: item?.variants_id,
        cv: "",
        cc: "",
        kw: "",
        reg_number_dismantler: settingDetails?.reg_number_dismantler
          ? settingDetails?.reg_number_dismantler
          : "0",
        part_rating: "0",
        parts_damage: [],
        associate_location: "0",
      }));

      setPartsData(selectedParts);
    }
  }, [vehicleAddDetails, selectedItems]);

  const handleInputChangePartListRef = (e, pIndex) => {
    const { name, value } = e.target;
    setPartsData((prev) =>
      prev.map((part, index) =>
        index === pIndex ? { ...part, [name]: value } : part
      )
    );
  };

  const handleCheckboxChangeRef = (pIndex) => {
    setPartsData((prev) =>
      prev.map((part, index) =>
        index === pIndex
          ? { ...part, is_main: part.is_main === 0 ? 1 : 0 }
          : part
      )
    );
  };

  const handleAddPartRef = (pIndex) => {
    setPartsData((prev) =>
      prev?.map((part, index) => {
        if (index === pIndex) {
          const referenceCode = selectedReferenceCode || part?.reference_code;

          if (!referenceCode) {
            toast.error("Reference Code is required!");
            return part;
          }

          const isDuplicate = part?.addedData?.some(
            (data) => data?.reference_code === referenceCode
          );
          if (isDuplicate) {
            toast.error("Reference Code already added!");
            return part;
          }

          const selectedCondition = conditionList.find(
            (cond) => cond?.value === part?.condition_id_ref
          ) || { label: "Not Selected", value: "null" };

          const selectedType = referenceList.find(
            (cond) => cond?.value === part?.type_id
          ) || {
            label: "Not Selected",
            value: "null",
          };

          return {
            ...part,
            addedData: [
              ...(part?.addedData || []), // Ensure `addedData` is always an array
              {
                reference_code: referenceCode,
                type_id: part?.type_id,
                is_main: part?.is_main,
                condition_id_ref: part?.condition_id_ref,
                selectedCondition,
                selectedType,
              },
            ],
            reference_code: "",
            type_id: null,
            condition_id_ref: null,
            is_main: 0,
          };
        }
        return part;
      })
    );
    setSelectedReferenceCode(null);
  };

  const handleUpdatePartRef = (pIndex, rowIndex, key, value) => {
    setPartsData((prev) =>
      prev.map((part, index) => {
        if (index !== pIndex) return part;

        const updatedAddedData = part?.addedData?.map((data, idx) => {
          if (key === "is_main") {
            return { ...data, is_main: idx === rowIndex ? value : 0 };
          }

          if (idx !== rowIndex) return data;
          return { ...data, [key]: value };
        });

        return {
          ...part,
          addedData: updatedAddedData || [], // Ensure `addedData` is always valid
        };
      })
    );
  };

  const hanldeEditdeleteRef = () => {
    let data = {
      id: DelteRefId.id,
    };
    partRefDelete(data)
      .then((res) => {
        setPartsData((prev) =>
          prev.map((part, index) =>
            index === DelteRefId.pIndex
              ? {
                  ...part,
                  addedData:
                    part.addedData?.filter(
                      (_, idx) => idx !== DelteRefId.rowIndex
                    ) || [],
                }
              : part
          )
        );
        toast.success(res.data.message);

        setModifyDelteRef(false);
      })
      .catch((error) => console.error("Error deleting partRef:", error));
  };

  const hanldeEditdeleteDamage = () => {
    let data = {
      id: DelteDamageId.id,
    };
    partDamageDelete(data)
      .then((res) => {
        setPartsData((prev) =>
          prev.map((part, index) =>
            index === DelteDamageId.pIndex
              ? {
                  ...part,
                  parts_damage:
                    part.parts_damage?.filter(
                      (_, idx) => idx !== DelteDamageId.rowIndex
                    ) || [],
                }
              : part
          )
        );
        setModifyDelteDamage(false);

        toast.success(res.data.message);
      })
      .catch((error) => console.error("Error deleting partRef:", error));
  };

  const handleDeletePartRef = (pIndex, rowIndex, id) => {
    if (id) {
      setModifyDelteRef(true);

      setDelteRefId({
        pIndex: pIndex,
        rowIndex: rowIndex,
        id: id,
      });
    } else {
      setPartsData((prev) =>
        prev.map((part, index) =>
          index === pIndex
            ? {
                ...part,
                addedData:
                  part.addedData?.filter((_, idx) => idx !== rowIndex) || [],
              }
            : part
        )
      );
    }
  };

  const handleAddPartDamage = (pIndex) => {
    setPartsData((prev) =>
      prev?.map((part, index) => {
        if (index === pIndex) {
          const damageDescription = part?.damage_description;

          if (!damageDescription) {
            toast.error("Damage Description is required!");
            return part;
          }

          const selectedTypeDamage = typeDamageList.find(
            (cond) => cond?.value === part?.type_damage_id
          ) || { label: "Not Selected", value: "null" };

          const selectedLocationDamage = locationDamageList.find(
            (cond) => cond?.value === part?.location_damage_id
          ) || {
            label: "Not Selected",
            value: "null",
          };

          const updatedPart = {
            ...part,
            parts_damage: [
              ...(part?.parts_damage || []),
              {
                damage_description: part?.damage_description,
                type_damage_id: part?.type_damage_id,
                location_damage_id: part?.location_damage_id,
                damage_rating: part?.damage_rating,
                selectedTypeDamage,
                selectedLocationDamage,
              },
            ],
            damage_description: "",
            type_damage_id: null,
            location_damage_id: null,
            damage_rating: 0,
          };

          return updatedPart;
        }
        return part;
      })
    );
  };

  const handleUpdatePartDamage = (pIndex, rowIndex, key, value) => {
    setPartsData((prev) =>
      prev.map((part, index) => {
        if (index !== pIndex) return part;

        const updatedAddedData = part?.parts_damage?.map((data, idx) => {
          if (idx !== rowIndex) return data;
          return { ...data, [key]: value };
        });

        return {
          ...part,
          parts_damage: updatedAddedData || [],
        };
      })
    );
  };

  const handleDeletePartDamage = (pIndex, rowIndex, id) => {
    if (id) {
      setModifyDelteDamage(true);

      setDelteDamageId({
        pIndex: pIndex,
        rowIndex: rowIndex,
        id: id,
      });
    } else {
      setPartsData((prev) =>
        prev.map((part, index) =>
          index === pIndex
            ? {
                ...part,
                parts_damage:
                  part.parts_damage?.filter((_, idx) => idx !== rowIndex) || [],
              }
            : part
        )
      );
    }
  };

  const handleEditPartDamage = (pIndex, rowIndex) => {
    setPartsData((prev) =>
      prev.map((part, index) =>
        index === pIndex
          ? {
              ...part,
              parts_damage:
                part.parts_damage?.map((data, idx) =>
                  idx === rowIndex
                    ? { ...data, isEditing: !data.isEditing }
                    : data
                ) || [],
            }
          : part
      )
    );

    toast.success("Successfully updated!");
  };

  const handleEditPartRef = (pIndex, rowIndex) => {
    setPartsData((prev) =>
      prev.map((part, index) =>
        index === pIndex
          ? {
              ...part,
              addedData:
                part.addedData?.map((data, idx) =>
                  idx === rowIndex
                    ? { ...data, isEditing: !data.isEditing }
                    : data
                ) || [],
            }
          : part
      )
    );

    toast.success("Successfully updated!");
  };

  const handleConditionChangeRef = (id, selectedOption) => {
    setPartsData((prev) =>
      prev.map((part, index) =>
        part?.id === id
          ? { ...part, type_id: selectedOption?.value || null }
          : part
      )
    );
  };

  const handleConditionSChangeRefs = (id, selectedOption) => {
    setPartsData((prev) =>
      prev.map((part, index) =>
        part?.id === id
          ? { ...part, condition_id_ref: selectedOption?.value || null }
          : part
      )
    );
  };

  const handleTypeDamageChange = (pIndex, selectedOption) => {
    setPartsData((prev) =>
      prev.map((part, index) =>
        index === pIndex
          ? { ...part, type_damage_id: selectedOption?.value || null }
          : part
      )
    );
  };

  const hanldeLocationDamageChange = (pIndex, selectedOption) => {
    setPartsData((prev) =>
      prev.map((part, index) =>
        index === pIndex
          ? { ...part, location_damage_id: selectedOption?.value || null }
          : part
      )
    );
  };

  useEffect(() => {
    const obj = {
      search: "",
      limit: 10,
      page: 1,
      park_id: 0,
    };

    getAllViewShelfList(obj)
      .then((response) => {
        response = response.data;
        let list = [];

        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
            bar_code: response.data[i].bar_code,
            place: response.data[i].place,
            from_iels: response.data[i].from_iels,
            to_iels: response.data[i].to_iels,
          });
        }
        SetShelfList(list);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const handleSelfIdChange = (index, selectedOption) => {
    const updatedParts = [...partsData];

    updatedParts[index] = {
      ...updatedParts[index],
      name: selectedOption.label,
      bar_code: selectedOption.bar_code,
      place: selectedOption.place,
      from_iels: selectedOption.from_iels,
      to_iels: selectedOption.to_iels,
    };

    setPartsData(updatedParts);
  };

  const handleInputChangePartList = (e, id) => {
    const { name, value, type, checked } = e.target;
    setPartsData((prevData) =>
      prevData.map((part) =>
        part?.id === id
          ? { ...part, [name]: type === "checkbox" ? (checked ? 1 : 0) : value }
          : part
      )
    );
  };

  const handleInputChangePartLists = (e, tempId) => {
    const { name, value } = e.target;
    const updatedParts = [...partsData];
    const partToUpdate = updatedParts.find((part) => part.tempId === tempId);

    if (partToUpdate) {
      partToUpdate[name] = value;
      setPartsData(updatedParts);

      if (name === "part_price") {
        const originalPrice = parseFloat(value) || 0;

        setPartCostPrice(tempId, originalPrice);
      }
    }
  };

  const handleMasterPartClose = () => {
    setModifyMasterPart(false);
  };

  const handleAddClick = (item) => {
    setCategoryMasterPart(item);

    if (item.is_master_part === "1") {
      setModifyMasterPart(true);
      return;
    } else {
      let partListItem = {
        vehicle_make: vehicleModalSelectedList?.make_id || "",
        vehicle_year: vehicleModalSelectedList?.vehicle_year || "",
        vehicle_model: vehicleModalSelectedList?.vehicle_model_id || "",
        vehicle_variant: vehicleModalSelectedList?.variants_id || "",
        catalog_id: item.id,
        master_part_id: categoryMasterPart?.is_master_part,
      };

      let data = {
        temp_id: tempData?.temp_id || dismantleTempData?.temp_id || "",
        vehicle_id: vehicleModalSelectedList?.id,
        part_list: [partListItem],
      };

      actionPartInventoryTempStore(data)
        .then((response) => {
          setTempData(response.data.data);
        })
        .catch((err) => {
          toast.dismiss();
          toast.error(Utils.getErrorMessage(err));
        });
    }
    if (modifyMasterPart === true) {
      submitData();
    }
  };

  const handleModalSubmit = (e) => {
    e.preventDefault();
    submitData();
  };

  const submitData = () => {
    const partListItems = checkedItems?.map((item) => {
      const referenceId = item.reference_id;
      const masterId = item.parts_catalog_id;
      return {
        vehicle_make: vehicleModalSelectedList?.make_id || "",
        vehicle_year: vehicleModalSelectedList?.vehicle_year || "",
        vehicle_model: vehicleModalSelectedList?.vehicle_model_id || "",
        vehicle_variant: vehicleModalSelectedList?.variants_id || "",
        catalog_id: referenceId,
        master_part_id: masterId,
      };
    });

    let payload = {
      temp_id: tempData?.temp_id || dismantleTempData?.temp_id || "",
      vehicle_id: vehicleModalSelectedList?.id,
      part_list: [
        ...partListItems,
        ...(categoryMasterPart?.is_master_part === "1"
          ? [
              {
                vehicle_make: vehicleModalSelectedList?.make_id || "",
                vehicle_year: vehicleModalSelectedList?.vehicle_year || "",
                vehicle_model: vehicleModalSelectedList?.vehicle_model_id || "",
                vehicle_variant: vehicleModalSelectedList?.variants_id || "",
                catalog_id: categoryMasterPart.id,
                master_part_id: categoryMasterPart?.is_master_part,
              },
            ]
          : []),
      ],
    };

    actionPartInventoryTempStore(payload)
      .then((response) => {
        setTempData(response.data.data);
        setModifyMasterPart(false);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleDeleteClick = (item) => {
    setbtnClosed(true);
    let data = {
      id: item.tempId ? item.tempId : item?.id,
    };

    setTempData(item);

    DestoryPartInventoryTemp(data)
      .then((response) => {
        toast.success(response.data.message);

        const fetchData = async () => {
          try {
            const obj = {
              search: searchQueryTemp,
              limit: tableLengthList[0],
              page: 1,
              temp_id: item?.temp_id,
            };
            const response = await getPartInventoryTempList(obj);
            setSelectedItems(response.data.data);
          } catch (err) {
            console.error("Error fetching part inventory:", err);
          }
        };

        fetchData();
      })
      .catch((err) => {
        toast.dismiss();

        toast.error(Utils.getErrorMessage(err));
      })
      .finally(() => {
        // Reset button state to "close"
        setbtnClosed(false);
      });
  };

  useEffect(() => {
    if (selectedItems?.length === 0) {
      setCurrentStep(1);
    }
  }, [selectedItems]);

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = "";
    } else if (type === "pagination") {
      paramsObj.page = e;
    }

    setParams(paramsObj);
  };

  useEffect(function () {
    var obj = {
      text: "",
      limit: tableLengthList[0],
      page: 1,
    };

    partInvenoryVehicleSearch(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            // value: response.data[i].id,
            // label: response.data[i].vehicle_model,
            // vehicle_make: response.data[i].vehicle_make,
            // vehicle_year: response.data[i].vehicle_end_year,
            // vehicle_model: response.data[i].vehicle_model,
            // vehicle_exp_value: response.data[i].vehicle_exp_value,
            value: response.data[i]?.vehicle_model_name,
            label: `${response.data[i]?.vehicle_make_name || ""} | ${
              response.data[i]?.vehicle_model_name || ""
            } | ${response.data[i]?.registration_plate || ""} | ${
              response.data[i]?.registration || ""
            }
            | ${response.data[i]?.vehicle_internal_id}
         `,
            vehicle_model_name: response.data[i]?.vehicle_model_name,

            vehicle_model_id: response.data[i]?.vehicle_model_id,
            make_id: response.data[i]?.make_id,
            vehicle_process_end_date:
              response.data[i]?.vehicle_process_end_date,
            vehicle_in_inventory: response.data[i]?.vehicle_in_inventory,
            variants_id: response.data[i]?.variants_id,
            vehicle_make_name: response.data[i]?.vehicle_make_name,
            registration_plate: response.data[i]?.registration_plate,
            registration: response.data[i]?.registration,
            id: response.data[i]?.id,
          });
        }
        setVehicleModelAllList(list);
      })
      .catch((err) => {});
  }, []);

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);

    if (inputValue.length > 2) {
      const obj = {
        search: inputValue,
        limit: tableLengthList[0],
        page: 1,
      };

      partInvenoryVehicleSearch(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i]?.vehicle_model_name,
              label: `${response.data[i]?.vehicle_make_name || ""} | ${
                response.data[i]?.vehicle_model_name || ""
              } | ${response.data[i]?.registration_plate || ""} | ${
                response.data[i]?.registration || ""
              }
              | ${response.data[i]?.vehicle_internal_id}
           `,
              vehicle_model_name: response.data[i]?.vehicle_model_name,
              vehicle_model_id: response.data[i]?.vehicle_model_id,
              make_id: response.data[i]?.make_id,
              vehicle_process_end_date:
                response.data[i]?.vehicle_process_end_date,
              vehicle_in_inventory: response.data[i]?.vehicle_in_inventory,
              variants_id: response.data[i]?.variants_id,
              vehicle_make_name: response.data[i]?.vehicle_make_name,
              registration_plate: response.data[i]?.registration_plate,
              registration: response.data[i]?.registration,
              id: response.data[i]?.id,
            });
          }

          setVehicleModelAllList(list);
        })
        .catch((err) => {
          console.error("Error fetching vehicle model list", err);
        });
    }
  };

  useEffect(() => {
    if (vehicleModalSelectedList?.id) {
      const fetchCatalogList = () => {
        let data = {
          limit: params.limit,
          page: params.page,
          search: searchQuery,
          is_reload: false,
          vehicle_id: vehicleModalSelectedList.id,
        };

        getCatalogList(data)
          .then((response) => {
            response = response.data;
            setCatalogList(response);
            setTotalRecords(response.total);
          })
          .catch((err) => {
            console.error("Error fetching catalog list:", err);
          });
      };

      fetchCatalogList();
    }
  }, [searchQuery, params, vehicleModalSelectedList]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchChangeTemp = (e) => {
    setSearchQueryTemp(e.target.value);
  };

  useEffect(() => {
    if (tempData?.temp_id || dismantleTempData?.temp_id) {
      const fetchData = async () => {
        try {
          const tempIdToSend = tempData?.temp_id || dismantleTempData?.temp_id;

          const obj = {
            search: searchQueryTemp,
            limit: tableLengthList[0],
            page: 1,
            temp_id: tempIdToSend,
          };

          const response = await getPartInventoryTempList(obj);
          setSelectedItems(response.data.data);
          setTotalRecords(response.data.total);
        } catch (err) {
          console.error("Error fetching part inventory:", err);
        }
      };

      fetchData();
    }
  }, [searchQueryTemp, tempData, dismantleTempData]);

  const createPayload = () => {
    return partsData.map((part) => ({
      vehicle_id: vehicleModalSelectedList?.id || 0,
      part_catelog: part?.id || "",
      part_name: part?.part_name,
      quantity: part?.quantity,
      part_price: part?.part_price,
      condition_id: part?.condition_id,
      part_cost_price: part?.part_cost_price,
      part_original_price: part?.part_original_price,
      state_id: part?.state_id,
      is_custom_size: part?.is_custom_size,
      parts_weight: part?.parts_weight || 0,
      parts_width: part?.parts_width || 0,
      parts_height: part?.parts_height || 0,
      parts_length: part?.parts_length || 0,
      print_label: part?.print_label ? part?.print_label : 0,
      associate_location: part?.associate_location
        ? part?.associate_location
        : 0,
      vat_included: part?.vat_included ? part?.vat_included : 0,
      inventory_status: part.inventory_status ? part.inventory_status : 1,
      vehicle_make: part?.vehicle_make,
      vehicle_year: part?.vehicle_year,
      vehicle_model: part?.vehicle_model,
      sponsor_price: part?.sponsor_price,
      part_description: part?.part_description,
      parts_associate: [],
      brand_id: part.brand_id || 0,
      parts_reference: part?.addedData?.map((item) => ({
        reference_code: item?.reference_code,
        is_main: item?.is_main,
        type_id: item?.type_id,
        condition_id: item?.condition_id_ref,
      })),
      engine_code: part?.engine_code,
      variants_id: part?.variants_id,
      cv: part?.cv,
      cc: part?.cc,
      kw: part?.kw,
      reg_number_dismantler: part?.reg_number_dismantler,
      is_master_part: part?.is_master_part,
      master_part_id: part?.master_part_id,
      master_part_inventory_id: null,
      part_rating: part?.part_rating,

      parts_damage: part?.parts_damage?.map((item) => ({
        damage_description: item?.damage_description,
        damage_rating: item?.damage_rating,
        location_damage_id: item?.location_damage_id,
        type_damage_id: item?.type_damage_id,
      })),
    }));
  };

  const createPayloads = () => {
    const part = partsData[0];

    return {
      uuid: vehicleAddDetails?.uuid,
      id: vehicleAddDetails?.id,
      vehicle_id: vehicleAddDetails?.vehicle_id || 0,
      part_catelog: part?.id || vehicleAddDetails?.part_catelog,
      part_name: part?.part_name,
      quantity: part?.quantity,
      part_price: part?.part_price,
      condition_id: part?.condition_id,
      part_cost_price: part?.part_cost_price,
      part_original_price: part?.part_original_price,
      state_id: part?.state_id,
      is_custom_size: part?.is_custom_size,
      parts_weight: part?.parts_weight || 0,
      parts_width: part?.parts_width || 0,
      parts_height: part?.parts_height || 0,
      parts_length: part?.parts_length || 0,
      print_label: part?.print_label,
      associate_location: part?.associate_location,
      vat_included: part?.vat_included,
      inventory_status: part.inventory_status ? part.inventory_status : 1,
      vehicle_make: part?.vehicle_make,
      vehicle_year: part?.vehicle_year,
      vehicle_model: part?.vehicle_model,
      part_description: part?.part_description,
      brand_id: part?.brand_id || 0,
      parts_associate:
        selectedModels?.map((model) => ({
          uuid: model?.uuid,
          vehicle_make: model.make_id,
          vehicle_year: model.model_id ? model?.model_id : model?.id,
          vehicle_model: model.model_id ? model?.model_id : model?.id,
        })) || [],
      parts_reference: part?.addedData?.map((item) => ({
        id: item?.id ? item?.id : null,
        parts_id: vehicleAddDetails?.id,
        reference_code: item?.reference_code,
        is_main: item?.is_main,
        type_id: item?.type_id,
        condition_id: item?.condition_id_ref,
      })),
      part_original_price: part?.part_original_price,
      engine_code: part?.engine_code,
      variants_id: part?.variants_id,
      cv: part?.cv,
      cc: part?.cc,
      kw: part?.kw,
      reg_number_dismantler: part?.reg_number_dismantler,
      is_master_part: part?.is_master_part,
      master_part_id: part?.master_part_id,
      master_part_inventory_id: null,
      part_rating: part?.part_rating,

      parts_damage:
        part?.parts_damage?.map((item) => ({
          id: item?.id ? item?.id : null,
          parts_id: vehicleAddDetails?.id,
          damage_description: item?.damage_description,
          damage_rating: item?.damage_rating,
          location_damage_id: item?.location_damage_id,
          type_damage_id: item?.type_damage_id,
        })) || [],
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let error = false;
    let errorObj = { ...errorsObj };
    partsData.forEach((part) => {
      if (!part.quantity) {
        errorObj.quantity = `Part Quantity is required.`;
        error = true;
      }
    });

    setErrors(errorObj);
    if (error) return;

    const payloads = createPayload();
    const payloadsUpdated = createPayloads();
    setbtnLoading(true);
    setbtnSaveNext(true);
    if (vehicleAddDetails?.id) {
      updatePartsInventory(payloadsUpdated)
        .then((response) => {
          SetDetailsForImages(response.data.data);
          toast.success(response.data.message);
          // navigate("/parts-inventory");
          setCurrentStep(2);
          setbtnLoading(false);
          setbtnSaveNext(false);
          setIsSaveClicked(true);
        })
        .catch((err) => {
          setbtnLoading(false);
          toast.dismiss();
          setbtnSaveNext(false);
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      let data = {
        temp_id: tempData?.temp_id,
        part_list: payloads,
      };
      createPartsInventory(data)
        .then((response) => {
          SetDetailsForImages(response.data.data);
          toast.success(response.data.message);
          setCurrentStep(3);
          setbtnLoading(false);
          setbtnSaveNext(false);

          setIsSaveClicked(true);
        })
        .catch((err) => {
          setbtnLoading(false);
          setbtnSaveNext(false);
          toast.dismiss();

          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  const handleSubmitAsPrintLabel = (e) => {
    e.preventDefault();

    let error = false;
    let errorObj = { ...errorsObj };
    partsData.forEach((part) => {
      if (!part.quantity) {
        errorObj.quantity = `Quantity is required.`;
        error = true;
      }
    });

    setErrors(errorObj);
    if (error) return;

    const payloads = createPayload();
    const payloadsUpdated = createPayloads();

    setBtnLoader(true);
    if (vehicleAddDetails?.id) {
      updatePartsInventory(payloadsUpdated)
        .then((response) => {
          toast.success(response.data.message);
          // navigate("/parts-inventory");
          setCurrentStep(2);
          setBtnLoader(false);
        })
        .catch((err) => {
          toast.dismiss();
          setBtnLoader(false);
          toast.dismiss();
          toast.error(Utils.getErrorMessage(err));
        });
    } else {
      let data = {
        temp_id: tempData?.temp_id,
        is_print: 1,
        part_list: payloads,
      };
      createPartsInventory(data)
        .then((response) => {
          SetDetailsForImages(response.data.data);
          toast.success(response.data.message);
          // navigate("/parts-inventory");

          setCurrentStep(3);
          setBtnLoader(false);
        })
        .catch((err) => {
          toast.dismiss();

          toast.error(Utils.getErrorMessage(err));
          setBtnLoader(false);
        });
    }
  };

  const handleNextPart = () => {
    setCurrentStep(2);
    setImageDetailsNot(vehicleAddDetails);
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    setbtnNext(true);

    if (selectedItems?.length === 0) {
      toast.error("Please select a vehicle part.");
      return;
    } else if (vehicleModalSelectedList?.vehicle_process_end_date) {
      setCurrentStep(1);
      setModifyExpiry(true);
    }
  };
  const handleAddPart = (e, index) => {
    e.preventDefault();

    if (selectedVehicleModel) {
      let list = [...partsData];
      let partList = list[index]["parts_associate"];
      const isDuplicate = partList.some(
        (part) => part.value === selectedVehicleModel.value
      );

      let idx = partList.findIndex(
        (x) => x.value == selectedVehicleModel.value
      );
      if (idx === -1) {
        partList.push(selectedVehicleModel);
        list[index]["parts_associate"] = partList;
        setPartsData(list);
      } else {
        toast.error(selectedVehicleModel.label + ` is already added.`);
      }
    } else {
      console.warn("No vehicle model selected.");
    }
  };

  const handleSelectWithoutChange = (option) => {
    setSelectedVehicleModel(option);
  };

  const handleBackButtonClick = () => {
    if (currentStep === 2) {
      setCurrentStep(1);
    } else {
      navigate("/parts-inventory");
    }
  };
  const toggleEnlarge = (index) => {
    if (enlargedIndexes.includes(index)) {
      setEnlargedIndexes(enlargedIndexes.filter((i) => i !== index));
    } else {
      setEnlargedIndexes([...enlargedIndexes, index]);
    }
  };

  const setPartCostPrice = (tempId, price1) => {
    const discountPercentage = settingDetails?.discount_percentage / 100;

    const discountedPrice = price1 * (1 - discountPercentage);

    const updatedParts = [...partsData];
    const partToUpdate = updatedParts.find((part) => part.tempId === tempId);

    if (partToUpdate) {
      partToUpdate.part_original_price = discountedPrice;
      setPartsData(updatedParts);
    }
  };

  const setPartCostPriceEdit = (id, price1) => {
    const discountPercentage = settingDetails?.discount_percentage / 100;

    const discountedPrice = price1 * (1 - discountPercentage);

    const updatedParts = [...partsData];
    const partToUpdate = updatedParts.find((part) => part.id === id);

    if (partToUpdate) {
      partToUpdate.part_original_price = discountedPrice;
      setPartsData(updatedParts);
    }
  };

  const handleInputChangePartPrice1 = (e, id) => {
    const { name, value } = e.target;
    const updatedParts = [...partsData];
    const partToUpdate = updatedParts.find((part) => part?.id === id);

    if (partToUpdate) {
      partToUpdate[name] = value;
      setPartsData(updatedParts);

      if (name === "part_price") {
        const originalPrice = parseFloat(value) || 0;
        setPartCostPriceEdit(id, originalPrice);
      }
    }
  };
  const handleInventoryIdChange = (index, selectedOption) => {
    const updatedParts = [...partsData];

    updatedParts[index] = {
      ...updatedParts[index],
      inventory_status: selectedOption.value,
    };

    setPartsData(updatedParts);
  };

  const handleConditionChange = (index, selectedOption) => {
    const updatedParts = [...partsData];

    updatedParts[index] = {
      ...updatedParts[index],
      condition_id: selectedOption.value,
    };

    setPartsData(updatedParts);
  };

  const handleStateListChange = (index, selectedOption) => {
    const updatedParts = [...partsData];

    updatedParts[index] = {
      ...updatedParts[index],
      state_id: selectedOption.value,
    };

    setPartsData(updatedParts);
  };

  const handleCheckboxCustomChange = (index, e) => {
    const updatedParts = [...partsData];

    updatedParts[index] = {
      ...updatedParts[index],
      is_custom_size: e.target.checked ? "1" : "0", // Update the specific part at that index
    };

    setPartsData(updatedParts);
  };

  const handleCheckboxPrintChanges = (pIndex, event) => {
    const updatedParts = [...partsData];
    const partToUpdate = updatedParts[pIndex];

    partToUpdate.print_label = event.target.checked ? 1 : 0;

    setPartsData(updatedParts);
  };

  const handleCheckboxVatChanges = (pIndex, event) => {
    const updatedParts = [...partsData];
    const partToUpdate = updatedParts[pIndex];

    partToUpdate.vat_included = event.target.checked ? 1 : 0;

    setPartsData(updatedParts);
  };

  const handleCheckboxAssociateLocationChanges = (pIndex, event) => {
    const updatedParts = [...partsData];
    const partToUpdate = updatedParts[pIndex];

    partToUpdate.associate_location = event.target.checked ? 1 : 0;

    setPartsData(updatedParts);
  };

  const handleBrandIdChange = (index, selectedOption) => {
    const updatedParts = [...partsData];

    updatedParts[index] = {
      ...updatedParts[index],
      brand_id: selectedOption.value,
    };

    setPartsData(updatedParts);
  };

  const vehicleDatesExpiry = moment(
    vehicleModalSelectedList?.vehicle_process_end_date
  ).format("YYYY-MM-DD");

  useEffect(() => {
    if (vehicleDatesExpiry) {
      setVehicleData({
        date: vehicleDatesExpiry,
      });
    }
  }, [vehicleDatesExpiry]);

  const ExpiryModalNextclose = () => {
    if (vehicleModalSelectedList?.vehicle_process_end_date) {
      let data = {
        id: vehicleModalSelectedList.id,
        date: vehicleModalSelectedList?.vehicle_process_end_date,
      };

      partVehicleProcessUpdateExpiryDate(data)
        .then((response) => {
          setVehicleExceedData(response.data.data);
          toast.success(response.data.message);

          setModifyExpiry(false);
          setCurrentStep(2);
        })
        .catch((err) => {
          toast.dismiss();

          toast.error(Utils.getErrorMessage(err));
        })
        .finally(() => {
          setbtnNext(false); // Reset loading state
        });
    } else {
      let data = {
        id: vehicleModalSelectedList?.vehicle_model_id,
        date: vehicleData.date,
      };

      if (
        vehicleData?.date < vehicleModalSelectedList?.vehicle_process_start_date
      ) {
        toast.error("Please select Correct date grater than your start date.");
        setbtnNext(false);
      }
      partVehicleProcessUpdateExpiryDate(data)
        .then((response) => {
          setVehicleExceedData(response.data.data);
          toast.success(response.data.message);

          setCurrentStep(2);
        })
        .catch((err) => {
          toast.dismiss();

          toast.error(Utils.getErrorMessage(err));
        })
        .finally(() => {
          setbtnNext(false); // Reset loading state
        });
    }
  };

  const handleMasterEditOpen = () => {
    setModifyMasterPart(true);
  };

  const hanldeLastEditfinish = () => {
    if (vehicleAddDetails?.parts_damage?.length > 0) {
      setCurrentStep(4);
    } else {
      // navigate("/parts-inventory");
      setCurrentStep(5);
    }
  };

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  {/* <a href="#">Home</a> */}
                </li>
                {/* <li className="breadcrumb-item active">Fuel</li> */}
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>

      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary card-primary-Vehicle">
            {vehicleAddDetails?.id ? (
              <>
                {currentStep === 1 && (
                  <>
                    <div className="card-body vehicleparts-table mt-3">
                      <div className="vehicleparts-table-inner">
                        <Form method={`post`} onSubmit={handleSubmit}>
                          <div className="row justify-content-start">
                            {partsData?.map((part, pIndex) => {
                              const PartState = Number(part?.state_id);
                              const PartCustomSize = Number(
                                part.is_custom_size
                              );
                              return (
                                <React.Fragment key={pIndex}>
                                  <div className="col-lg-12" key={part?.id}>
                                    <div className="text-center inventory-addparts-custom">
                                      <Card.Header className="cardHeader">
                                        <div
                                          className="d-flex"
                                          data-bs-toggle="collapse"
                                          href={`#multiCollapseExample-${part?.tempId}`}
                                          role="button"
                                          aria-expanded="true"
                                          aria-controls={`multiCollapseExample-${part?.tempId}`}
                                        >
                                          <div className=""></div>
                                          <div className="AddPh6">
                                            <h6>
                                              {part?.part_name} |{" "}
                                              {
                                                vehicleAddDetails?.parts_internal_id
                                              }{" "}
                                              | {part?.vehicle_make_name} |{" "}
                                              {part?.vehicle_model_name}
                                            </h6>
                                          </div>
                                        </div>
                                      </Card.Header>
                                      <div className="row justify-content-end">
                                        <div
                                          className="col-md-4 mb-4 mt-3"
                                          style={{
                                            display: "flex",

                                            justifyContent: "end",
                                          }}
                                        >
                                          <button
                                            style={{
                                              marginLeft: "12px",
                                            }}
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              e.preventDefault();
                                              handleOpenPartHistory(e, part);
                                            }}
                                          >
                                            {part ? part?.part_model_stats : 0}
                                          </button>
                                          <button
                                            style={{
                                              marginLeft: "10px",
                                            }}
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              e.preventDefault();
                                              handleOpenSponsorPrice(e, part);
                                            }}
                                          >
                                            Statiscal Price
                                          </button>
                                          <button
                                            style={{
                                              marginLeft: "10px",
                                            }}
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              e.preventDefault();
                                              handleOpenEstimatePrice(e, part);
                                            }}
                                          >
                                            Estimate Price
                                          </button>
                                        </div>
                                        <div className="col">
                                          <Card.Body
                                            className="collapse multi-collapse show"
                                            id={`multiCollapseExample-${part.tempId}`}
                                          >
                                            <div className="vehicleThBg tableScroll  tablescroll-custom-two">
                                              <div className="row justify-content-end">
                                                {vehicleAddDetails?.is_master_part ===
                                                  "1" && (
                                                  <>
                                                    {vehicleAddDetails?.vehicle_id ? (
                                                      <>
                                                        <div className="row justify-content-end">
                                                          <button
                                                            type="button"
                                                            className="back-btn"
                                                            style={{
                                                              width: "126px",
                                                              height: "49px",
                                                            }}
                                                            onClick={
                                                              handlMasterVehicleOpen
                                                            }
                                                          >
                                                            <i class="bi bi-arrow-left">
                                                              {" "}
                                                            </i>
                                                            Add More
                                                          </button>{" "}
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <div className="row justify-content-end">
                                                          <button
                                                            type="button"
                                                            className="back-btn"
                                                            style={{
                                                              width: "126px",
                                                              height: "49px",
                                                            }}
                                                            onClick={
                                                              handlMasterVehicleNotOpen
                                                            }
                                                          >
                                                            <i class="bi bi-arrow-left">
                                                              {" "}
                                                            </i>
                                                            Add More
                                                          </button>{" "}
                                                        </div>
                                                      </>
                                                    )}
                                                  </>
                                                )}

                                                <div className="col-lg-12">
                                                  <div className="row justify-content-start">
                                                    <div className="col-lg-3 addPartField">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Quantity
                                                      </label>
                                                      <input
                                                        className="form-control input-search"
                                                        type="number"
                                                        name="quantity"
                                                        value={part?.quantity}
                                                        onChange={(e) =>
                                                          handleInputChangePartList(
                                                            e,
                                                            part?.id
                                                          )
                                                        }
                                                        placeholder="0"
                                                      />

                                                      {errors.quantity && (
                                                        <span className="input-error">
                                                          {errors.quantity}
                                                        </span>
                                                      )}
                                                    </div>
                                                    <div className="col-lg-3 addPartField">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Price 1 (€)
                                                      </label>
                                                      <input
                                                        className="form-control input-search"
                                                        type="number"
                                                        name="part_price"
                                                        value={part.part_price}
                                                        onChange={(e) => {
                                                          handleInputChangePartPrice1(
                                                            e,
                                                            part?.id
                                                          );
                                                        }}
                                                        placeholder="0"
                                                      />

                                                      {errors.part_price && (
                                                        <span className="input-error">
                                                          {errors.part_price}
                                                        </span>
                                                      )}
                                                    </div>
                                                    <div className="col-lg-3 addPartField">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Price 2 (
                                                        {
                                                          settingDetails?.discount_percentage
                                                        }
                                                        % discount) €
                                                      </label>
                                                      <input
                                                        className="form-control input-search"
                                                        type="number"
                                                        name="part_original_price"
                                                        value={
                                                          part.part_original_price
                                                        }
                                                        onChange={(e) =>
                                                          handleInputChangePartList(
                                                            e,
                                                            part.id
                                                          )
                                                        }
                                                        placeholder="0"
                                                      />
                                                    </div>

                                                    <div className="col-lg-3 addPartField">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Part Cost Price €
                                                      </label>
                                                      <input
                                                        className="form-control input-search"
                                                        type="number"
                                                        name="part_cost_price"
                                                        value={
                                                          part?.part_cost_price
                                                        }
                                                        onChange={(e) => {
                                                          handleInputChangePartList(
                                                            e,
                                                            part?.id
                                                          );
                                                        }}
                                                        placeholder="0"
                                                      />
                                                    </div>
                                                    <div className="col-lg-3">
                                                      <div className="addPartField">
                                                        <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                          Inventory Status
                                                        </label>
                                                        <Select
                                                          className="my-Select"
                                                          options={
                                                            inventoryList
                                                          }
                                                          name="inventory_status"
                                                          value={inventoryList.find(
                                                            (option) =>
                                                              option.value ===
                                                              part.inventory_status
                                                          )}
                                                          onChange={(
                                                            selectedOption
                                                          ) =>
                                                            handleInventoryIdChange(
                                                              pIndex,
                                                              selectedOption
                                                            )
                                                          }
                                                          placeholder={
                                                            "Select Inventory Status"
                                                          }
                                                          isSearchable={true}
                                                        ></Select>
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                      <div className="addPartField">
                                                        <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                          Condition
                                                        </label>
                                                        <Select
                                                          className="my-Select"
                                                          options={
                                                            conditionList
                                                          }
                                                          value={conditionList.find(
                                                            (option) =>
                                                              option.value ===
                                                              Number(
                                                                part.condition_id
                                                              )
                                                          )}
                                                          onChange={(
                                                            selectedOption
                                                          ) =>
                                                            handleConditionChange(
                                                              pIndex,
                                                              selectedOption
                                                            )
                                                          }
                                                          isSearchable
                                                          placeholder={
                                                            "Select Condition"
                                                          }
                                                        ></Select>
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                      <div className="addPartField">
                                                        <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                          {t("part")}{" "}
                                                          {t("brand")}
                                                        </label>
                                                        <Select
                                                          className="my-Select"
                                                          options={allBrandList}
                                                          name="brand_id"
                                                          value={allBrandList.find(
                                                            (option) =>
                                                              option.value ===
                                                              part.brand_id
                                                          )}
                                                          onChange={(
                                                            selectedOption
                                                          ) =>
                                                            handleBrandIdChange(
                                                              pIndex,
                                                              selectedOption
                                                            )
                                                          }
                                                          placeholder={
                                                            "Select Part Brand"
                                                          }
                                                          isSearchable={true}
                                                        ></Select>
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                      <div className="addPartField">
                                                        <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                          State
                                                        </label>
                                                        <Select
                                                          className="my-Select"
                                                          options={stateList}
                                                          value={stateList.find(
                                                            (option) =>
                                                              option.value ===
                                                              Number(
                                                                part.state_id
                                                              )
                                                          )}
                                                          onChange={(
                                                            selectedOption
                                                          ) =>
                                                            handleStateListChange(
                                                              pIndex,
                                                              selectedOption
                                                            )
                                                          }
                                                          isSearchable
                                                          placeholder={
                                                            "Select State"
                                                          }
                                                        ></Select>
                                                      </div>
                                                    </div>

                                                    <div className="col-lg-3 ">
                                                      <div className="addPartField">
                                                        <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                          Part Rating
                                                        </label>
                                                        <div className="addPartField">
                                                          <div className="form-check text-left">
                                                            <ReactStars
                                                              size={40}
                                                              count={5}
                                                              activeColor="#ffd700"
                                                              a11y={true}
                                                              isHalf={true}
                                                              emptyIcon={
                                                                <i className="bi bi-star" />
                                                              }
                                                              halfIcon={
                                                                <i className="bi bi-star-half" />
                                                              }
                                                              filledIcon={
                                                                <i className="bi bi-star-fill" />
                                                              }
                                                              value={
                                                                part?.part_rating
                                                              }
                                                              onChange={(
                                                                newRating
                                                              ) =>
                                                                handleRatingChange(
                                                                  newRating,
                                                                  part.id
                                                                )
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-3 addPartField">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Custome Size
                                                      </label>

                                                      <div className="col-lg-12 addPartField">
                                                        <div className="form-check text-left">
                                                          <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={
                                                              PartCustomSize ==
                                                              1
                                                            } // Handles both "1" (string) and 1 (number)
                                                            onChange={(e) =>
                                                              handleCheckboxCustomChange(
                                                                pIndex,
                                                                e
                                                              )
                                                            }
                                                          />
                                                          <label className="form-check-label">
                                                            Custome Size
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-3 addPartField">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Print label
                                                      </label>
                                                      <div className="col-lg-12 addPartField">
                                                        <div className="form-check text-left">
                                                          <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={
                                                              part.print_label ===
                                                              1
                                                            }
                                                            onChange={(e) =>
                                                              handleCheckboxPrintChanges(
                                                                pIndex,
                                                                e
                                                              )
                                                            }
                                                          />
                                                          <label className="form-check-label">
                                                            Print label
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div className="col-lg-3 addPartField">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        VAT included{" "}
                                                      </label>
                                                      <div className="col-lg-12 addPartField">
                                                        <div className="form-check text-left">
                                                          <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={
                                                              part.vat_included ===
                                                              1
                                                            }
                                                            onChange={(e) =>
                                                              handleCheckboxVatChanges(
                                                                pIndex,
                                                                e
                                                              )
                                                            }
                                                          />
                                                          <label className="form-check-label">
                                                            VAT included{" "}
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div className="col-lg-3 addPartField col-lg-3-mob">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Associate Location
                                                      </label>
                                                      <div className="addPartField">
                                                        <div className="form-check text-left">
                                                          <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={
                                                              part?.associate_location ===
                                                              1
                                                            }
                                                            onChange={(e) =>
                                                              handleCheckboxAssociateLocationChanges(
                                                                pIndex,
                                                                e
                                                              )
                                                            }
                                                          />
                                                          <label className="form-check-label">
                                                            Associate Location
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="row justify-content-start">
                                                    <div className="col-lg-6">
                                                      <div className="addPartField">
                                                        <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                          {t("part")}{" "}
                                                          {t("brand")}
                                                        </label>
                                                        <Select
                                                          className="my-Select"
                                                          options={allBrandList}
                                                          name="brand_id"
                                                          value={allBrandList.find(
                                                            (option) =>
                                                              option.value ===
                                                              part.brand_id
                                                          )}
                                                          onChange={(
                                                            selectedOption
                                                          ) =>
                                                            handleBrandIdChange(
                                                              pIndex,
                                                              selectedOption
                                                            )
                                                          }
                                                          placeholder={
                                                            "Select Part Brand"
                                                          }
                                                          isSearchable={true}
                                                        ></Select>
                                                      </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                      <div className="addPartField">
                                                        <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                          Notes
                                                        </label>
                                                        <textarea
                                                          className="form-control input-search"
                                                          type="text"
                                                          name="part_description"
                                                          value={
                                                            part.part_description
                                                          }
                                                          onChange={(e) =>
                                                            handleInputChangePartList(
                                                              e,
                                                              part.id
                                                            )
                                                          }
                                                          placeholder="Notes"
                                                          rows={1}
                                                        />
                                                      </div>
                                                    </div>
                                                    {vehicleAddDetails.vehicle_id ===
                                                      null && (
                                                      <>
                                                        <div className="col-lg-12">
                                                          <label class="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                            Vehicle Modal
                                                          </label>
                                                          <div className="selectDisplay">
                                                            <div className="input-div SelectV selectA">
                                                              <div className="col-xl-12">
                                                                <input
                                                                  className="form-control mt-2 input-search"
                                                                  type="text"
                                                                  maxLength={50}
                                                                  placeholder="Search Vehicle Model"
                                                                  value={
                                                                    vehicleModelAuto
                                                                  }
                                                                  onChange={
                                                                    handleChangeModel
                                                                  }
                                                                  onKeyDown={
                                                                    handleKeyDownModel
                                                                  }
                                                                  onClick={(
                                                                    e
                                                                  ) =>
                                                                    e.stopPropagation()
                                                                  }
                                                                />
                                                                {showSuggestionsModel &&
                                                                  vehicleModelAuto && (
                                                                    <ul className="suggestions">
                                                                      {filteredSuggestionsModel?.length >
                                                                      0 ? (
                                                                        filteredSuggestionsModel.map(
                                                                          (
                                                                            suggestion,
                                                                            index
                                                                          ) => (
                                                                            <li
                                                                              key={
                                                                                suggestion
                                                                              }
                                                                              className={
                                                                                index ===
                                                                                activeSuggestionIndexModel
                                                                                  ? "active"
                                                                                  : ""
                                                                              }
                                                                              onClick={() =>
                                                                                handleClickModel(
                                                                                  suggestion
                                                                                )
                                                                              }
                                                                            >
                                                                              {
                                                                                suggestion.model_name
                                                                              }
                                                                              /
                                                                              {
                                                                                suggestion.initial_year
                                                                              }
                                                                            </li>
                                                                          )
                                                                        )
                                                                      ) : (
                                                                        <li>
                                                                          No
                                                                          suggestions
                                                                          available
                                                                        </li>
                                                                      )}
                                                                    </ul>
                                                                  )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="tableScroll vehicleThBg Vehicle-Modal-tableScroll">
                                                            <div
                                                              style={{
                                                                borderCollapse:
                                                                  "separate",
                                                                borderSpacing:
                                                                  "0 15px",
                                                                marginBottom:
                                                                  "16px",
                                                              }}
                                                              className="striped bordered hover"
                                                            >
                                                              <div className="container-fluid">
                                                                <div className="row row-cols-2 row-cols-lg-3 g-2 g-lg-3 justify-content-start">
                                                                  {selectedModels?.length >
                                                                    0 &&
                                                                    selectedModels?.map(
                                                                      (
                                                                        row,
                                                                        partIndex
                                                                      ) => (
                                                                        <React.Fragment
                                                                          key={
                                                                            partIndex
                                                                          }
                                                                        >
                                                                          <div class="col-xl-4">
                                                                            <div className="d-flex">
                                                                              <div className="lisatingTable mb-1 col vehicleModall text1-line">
                                                                                {
                                                                                  row.model_name
                                                                                }{" "}
                                                                                (
                                                                                {
                                                                                  row.initial_year
                                                                                }

                                                                                )
                                                                              </div>
                                                                              <Button
                                                                                style={{
                                                                                  borderRadius:
                                                                                    "0px",
                                                                                  margin:
                                                                                    "7px",
                                                                                  height:
                                                                                    "30px",
                                                                                  width:
                                                                                    "30px",
                                                                                  color:
                                                                                    "#dd4b4b",
                                                                                  backgroundColor:
                                                                                    "#ffe9e9",
                                                                                  display:
                                                                                    "flex",
                                                                                  justifyContent:
                                                                                    "center",
                                                                                  alignItems:
                                                                                    "center",
                                                                                  border:
                                                                                    "transparent",
                                                                                  marginTop:
                                                                                    "12px",
                                                                                }}
                                                                                className=""
                                                                                onClick={() =>
                                                                                  setSelectedModels(
                                                                                    selectedModels.filter(
                                                                                      (
                                                                                        m
                                                                                      ) =>
                                                                                        m.id !==
                                                                                        row.id
                                                                                    )
                                                                                  )
                                                                                }
                                                                              >
                                                                                <i className="bi bi-trash3"></i>
                                                                              </Button>
                                                                            </div>
                                                                          </div>
                                                                        </React.Fragment>
                                                                      )
                                                                    )}
                                                                </div>
                                                              </div>
                                                              <div></div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </>
                                                    )}
                                                  </div>
                                                </div>

                                                {PartCustomSize === 1 && (
                                                  <>
                                                    <div className="col-lg-3 addPartField">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Weight (kg){" "}
                                                      </label>
                                                      <input
                                                        className="form-control input-search"
                                                        type="number"
                                                        name="parts_weight"
                                                        value={
                                                          part.parts_weight
                                                        }
                                                        onChange={(e) =>
                                                          handleInputChangePartList(
                                                            e,
                                                            part.id
                                                          )
                                                        }
                                                        placeholder="0"
                                                      />
                                                    </div>

                                                    <div className="col-lg-3 addPartField">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Width(cm){" "}
                                                      </label>
                                                      <input
                                                        className="form-control input-search"
                                                        type="number"
                                                        name="parts_width"
                                                        value={part.parts_width}
                                                        onChange={(e) =>
                                                          handleInputChangePartList(
                                                            e,
                                                            part.id
                                                          )
                                                        }
                                                        placeholder="0"
                                                      />

                                                      {errors.parts_width && (
                                                        <span className="input-error">
                                                          {errors.parts_width}
                                                        </span>
                                                      )}
                                                    </div>

                                                    <div className="col-lg-3 addPartField">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Height(cm){" "}
                                                      </label>
                                                      <input
                                                        className="form-control input-search"
                                                        type="number"
                                                        name="parts_height"
                                                        value={
                                                          part.parts_height
                                                        }
                                                        onChange={(e) =>
                                                          handleInputChangePartList(
                                                            e,
                                                            part.id
                                                          )
                                                        }
                                                        placeholder="0"
                                                      />
                                                    </div>

                                                    <div className="col-lg-3 addPartField">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Length(cm){" "}
                                                      </label>
                                                      <input
                                                        className="form-control input-search"
                                                        type="number"
                                                        name="parts_length"
                                                        value={
                                                          part.parts_length
                                                        }
                                                        onChange={(e) =>
                                                          handleInputChangePartList(
                                                            e,
                                                            part.id
                                                          )
                                                        }
                                                        placeholder="0"
                                                      />
                                                    </div>
                                                  </>
                                                )}

                                                {PartState === 2 && (
                                                  <div className="col-lg-3 addPartField col-lg-3-mob">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Reg Number Dismantler
                                                    </label>
                                                    <input
                                                      className="form-control input-search"
                                                      type="text"
                                                      name="reg_number_dismantler"
                                                      value={
                                                        part?.reg_number_dismantler
                                                      }
                                                      onChange={(e) =>
                                                        handleInputChangePartList(
                                                          e,
                                                          part?.id
                                                        )
                                                      }
                                                      placeholder="0"
                                                    />
                                                  </div>
                                                )}
                                              </div>
                                            </div>

                                            <div
                                              className="col-lg-12"
                                              style={{
                                                display: "flex",
                                                justifyContent: "end",
                                                marginBottom: "10px",
                                              }}
                                            >
                                              {part?.etai_part_id && (
                                                <>
                                                  <button
                                                    style={{
                                                      marginTop: "8px",
                                                      marginRight: "10px",
                                                    }}
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={(e) =>
                                                      handleEtaiDetails(
                                                        e,
                                                        part?.etai_part_id,
                                                        pIndex
                                                      )
                                                    }
                                                  >
                                                    View Reference
                                                  </button>

                                                  <button
                                                    style={{
                                                      marginTop: "8px",
                                                    }}
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={(e) =>
                                                      handleEtaiPriceDetails(
                                                        e,
                                                        part?.etai_part_id,
                                                        pIndex
                                                      )
                                                    }
                                                  >
                                                    View Price
                                                  </button>
                                                </>
                                              )}

                                              {part?.addedData?.length > 0 && (
                                                <>
                                                  <button
                                                    style={{
                                                      marginTop: "8px",
                                                      marginRight: "10px",
                                                    }}
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={(e) =>
                                                      handleOpenRefCodeCondition(
                                                        e,
                                                        part
                                                      )
                                                    }
                                                  >
                                                    Parts
                                                  </button>

                                                  <button
                                                    style={{
                                                      marginTop: "8px",
                                                    }}
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={(e) =>
                                                      handleOpenRefCodeEstimate(
                                                        e,
                                                        part
                                                      )
                                                    }
                                                  >
                                                    Estimate Price
                                                  </button>
                                                </>
                                              )}
                                            </div>

                                            <div className="col-lg-12">
                                              <div className="table-responsive-custom-q  table-custom-sticky-inherit">
                                                <table className="table ">
                                                  <tbody>
                                                    <tr>
                                                      <td scope="col">
                                                        <div className="col-lg-12 addPartField">
                                                          <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                            {t(
                                                              "reference_code"
                                                            )}{" "}
                                                          </label>
                                                          <input
                                                            className="form-control input-search"
                                                            type="text"
                                                            name="reference_code"
                                                            value={
                                                              selectedReferenceCode
                                                                ? selectedReferenceCode
                                                                : part?.reference_code
                                                            }
                                                            onChange={(e) =>
                                                              handleInputChangePartListRef(
                                                                e,
                                                                pIndex
                                                              )
                                                            }
                                                            placeholder="Reference Code"
                                                          />
                                                        </div>
                                                      </td>

                                                      <td scope="col">
                                                        <div className="col-lg-12 addPartField">
                                                          <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                            Condition
                                                          </label>
                                                          <Select
                                                            className="my-Select"
                                                            options={
                                                              conditionList
                                                            }
                                                            value={
                                                              conditionList.find(
                                                                (option) =>
                                                                  option.value ===
                                                                    part?.condition_id_ref ||
                                                                  option.label ===
                                                                    part?.selectedCondition
                                                              ) || null
                                                            }
                                                            onChange={(
                                                              selectedOption
                                                            ) =>
                                                              handleConditionSChangeRefs(
                                                                part?.id,
                                                                selectedOption
                                                              )
                                                            }
                                                            isSearchable
                                                            placeholder={
                                                              "Select Condition"
                                                            }
                                                          ></Select>
                                                        </div>
                                                      </td>
                                                      <td scope="col">
                                                        <div className="col-lg-12 addPartField">
                                                          <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                            Reference Type
                                                          </label>
                                                          <Select
                                                            className="my-Select"
                                                            options={
                                                              referenceList
                                                            }
                                                            value={
                                                              referenceList.find(
                                                                (option) =>
                                                                  option.value ===
                                                                    part?.type_id ||
                                                                  option.label ===
                                                                    part?.selectedType
                                                              ) || null
                                                            }
                                                            onChange={(
                                                              selectedOption
                                                            ) =>
                                                              handleConditionChangeRef(
                                                                part?.id,
                                                                selectedOption
                                                              )
                                                            }
                                                            isSearchable
                                                            placeholder="Select Type"
                                                          />
                                                        </div>
                                                      </td>
                                                      <td scope="col"></td>
                                                      <td>
                                                        <button
                                                          style={{
                                                            marginTop: "44px",
                                                          }}
                                                          type="button"
                                                          className="btn btn-primary"
                                                          onClick={() =>
                                                            handleAddPartRef(
                                                              pIndex
                                                            )
                                                          }
                                                        >
                                                          Add Reference{" "}
                                                        </button>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                  <tbody>
                                                    {part?.addedData?.map(
                                                      (data, rowIndex) => {
                                                        const conditionData =
                                                          Number(
                                                            data?.condition_id
                                                          );
                                                        const ReferenceData =
                                                          Number(data?.type_id);
                                                        return (
                                                          <>
                                                            <tr key={rowIndex}>
                                                              <td>
                                                                {/* <input
                                                                  className="form-control"
                                                                  type="text"
                                                                  value={
                                                                    data.reference_code
                                                                  }
                                                                  readOnly
                                                                /> */}

                                                                <div className="col-lg-12 addPartField">
                                                                  <label className="partsLabel col-form-label ml-2">
                                                                    Reference
                                                                    Code
                                                                  </label>
                                                                  <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="reference_code"
                                                                    value={
                                                                      data?.reference_code
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleUpdatePartRef(
                                                                        pIndex,
                                                                        rowIndex,
                                                                        "reference_code",
                                                                        e.target
                                                                          .value
                                                                      )
                                                                    }
                                                                    placeholder="Reference Code"
                                                                  />
                                                                </div>
                                                              </td>

                                                              <td>
                                                                <label className="partsLabel col-form-label ml-2">
                                                                  Condition
                                                                </label>
                                                                <Select
                                                                  className="my-Select"
                                                                  options={
                                                                    conditionList
                                                                  }
                                                                  value={conditionList.find(
                                                                    (option) =>
                                                                      option.value ===
                                                                        data?.condition_id_ref ||
                                                                      option.value ===
                                                                        conditionData
                                                                  )}
                                                                  onChange={(
                                                                    selectedOption
                                                                  ) => {
                                                                    handleUpdatePartRef(
                                                                      pIndex,
                                                                      rowIndex,
                                                                      "condition_id_ref",
                                                                      selectedOption?.value ||
                                                                        null
                                                                    );
                                                                    handleUpdatePartRef(
                                                                      pIndex,
                                                                      rowIndex,
                                                                      "condition_id",
                                                                      selectedOption?.value ||
                                                                        null
                                                                    );
                                                                  }}
                                                                  isSearchable
                                                                  placeholder="Select Condition"
                                                                />
                                                              </td>
                                                              <td>
                                                                <label className="partsLabel col-form-label ml-2">
                                                                  Reference Type
                                                                </label>
                                                                <Select
                                                                  className="my-Select"
                                                                  options={
                                                                    referenceList
                                                                  }
                                                                  value={referenceList.find(
                                                                    (option) =>
                                                                      option.value ===
                                                                      ReferenceData
                                                                  )}
                                                                  onChange={(
                                                                    selectedOption
                                                                  ) =>
                                                                    handleUpdatePartRef(
                                                                      pIndex,
                                                                      rowIndex,
                                                                      "type_id",
                                                                      selectedOption?.value ||
                                                                        null
                                                                    )
                                                                  }
                                                                  isSearchable
                                                                  placeholder="Select Type"
                                                                />
                                                              </td>
                                                              <td>
                                                                <div className="form-check text-left">
                                                                  <label className="partsLabel col-form-label ml-2">
                                                                    Is Main
                                                                  </label>
                                                                  <input
                                                                    style={{
                                                                      marginLeft:
                                                                        "20px",
                                                                    }}
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name={`isMainRadioGroup-${pIndex}-${rowIndex}`}
                                                                    checked={
                                                                      data?.is_main ===
                                                                      1
                                                                    }
                                                                    onChange={() =>
                                                                      handleUpdatePartRef(
                                                                        pIndex,
                                                                        rowIndex,
                                                                        "is_main",
                                                                        1
                                                                      )
                                                                    }
                                                                  />
                                                                </div>
                                                              </td>
                                                              <td>
                                                                <label className="partsLabel col-form-label ml-2">
                                                                  {t("action")}{" "}
                                                                </label>
                                                                {/* <i
                                                                  className="bi bi-pen-fill"
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                    marginRight:
                                                                      "10px",
                                                                  }}
                                                                  onClick={() =>
                                                                    handleEditPartRef(
                                                                      pIndex,
                                                                      rowIndex
                                                                    )
                                                                  }
                                                                /> */}
                                                                <i
                                                                  class="bi bi-trash-fill"
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  onClick={() =>
                                                                    handleDeletePartRef(
                                                                      pIndex,
                                                                      rowIndex,
                                                                      data.id
                                                                    )
                                                                  }
                                                                ></i>{" "}
                                                              </td>
                                                            </tr>
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>

                                            <div className="col-lg-12">
                                              <div className=" table-responsive-custom-q  table-custom-sticky-inherit">
                                                <table className="table">
                                                  <tbody>
                                                    <tr>
                                                      <td scope="col">
                                                        <div
                                                          className="col-lg-12 addPartField"
                                                          style={{
                                                            width: "206px",
                                                          }}
                                                        >
                                                          <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                            Damage Description
                                                          </label>
                                                          <input
                                                            className="form-control input-search"
                                                            type="text"
                                                            name="damage_description"
                                                            value={
                                                              selectedReferenceCode
                                                                ? selectedReferenceCode
                                                                : part?.damage_description
                                                            }
                                                            onChange={(e) =>
                                                              handleInputChangePartListRef(
                                                                e,
                                                                pIndex
                                                              )
                                                            }
                                                            placeholder="Damage Description"
                                                          />
                                                        </div>
                                                      </td>

                                                      <td scope="col">
                                                        <div className="col-lg-12 addPartField">
                                                          <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                            Type Damage
                                                          </label>
                                                          <Select
                                                            className="my-Select"
                                                            options={
                                                              typeDamageList
                                                            }
                                                            value={
                                                              typeDamageList.find(
                                                                (option) =>
                                                                  option.value ===
                                                                    part?.type_damage_id ||
                                                                  option.label ===
                                                                    part?.selectedCondition
                                                              ) || null
                                                            }
                                                            onChange={(
                                                              selectedOption
                                                            ) =>
                                                              handleTypeDamageChange(
                                                                pIndex,
                                                                selectedOption
                                                              )
                                                            }
                                                            isSearchable
                                                            placeholder={
                                                              "Select Type Damage"
                                                            }
                                                          ></Select>
                                                        </div>
                                                      </td>
                                                      <td scope="col">
                                                        <div className="col-lg-12 addPartField">
                                                          <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                            Location Damage
                                                          </label>
                                                          <Select
                                                            className="my-Select"
                                                            options={
                                                              locationDamageList
                                                            }
                                                            value={
                                                              locationDamageList.find(
                                                                (option) =>
                                                                  option.value ===
                                                                    part?.location_damage_id ||
                                                                  option.label ===
                                                                    part?.selectedType
                                                              ) || null
                                                            }
                                                            onChange={(
                                                              selectedOption
                                                            ) =>
                                                              hanldeLocationDamageChange(
                                                                pIndex,
                                                                selectedOption
                                                              )
                                                            }
                                                            isSearchable
                                                            placeholder="Select Location Damage"
                                                          />
                                                        </div>
                                                      </td>
                                                      <td scope="col">
                                                        <div
                                                          className="col-lg-12 addPartField"
                                                          style={{
                                                            width: "226px",
                                                          }}
                                                        >
                                                          <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                            Damage Rating
                                                          </label>
                                                          <ReactStars
                                                            size={40}
                                                            count={5}
                                                            activeColor="#ffd700"
                                                            value={
                                                              part?.damage_rating
                                                            }
                                                            a11y={true}
                                                            isHalf={true}
                                                            emptyIcon={
                                                              <i className="bi bi-star" />
                                                            }
                                                            halfIcon={
                                                              <i className="bi bi-star-half" />
                                                            }
                                                            filledIcon={
                                                              <i className="bi bi-star-fill" />
                                                            }
                                                            onChange={(
                                                              newRating
                                                            ) =>
                                                              handleInputChangePartListRef(
                                                                {
                                                                  target: {
                                                                    name: "damage_rating",
                                                                    value:
                                                                      newRating,
                                                                  },
                                                                },
                                                                pIndex
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                      </td>

                                                      <td scope="col"></td>
                                                      <td>
                                                        <button
                                                          style={{
                                                            marginTop: "44px",
                                                            width: "155px",
                                                          }}
                                                          type="button"
                                                          className="btn btn-primary"
                                                          onClick={() =>
                                                            handleAddPartDamage(
                                                              pIndex
                                                            )
                                                          }
                                                        >
                                                          Add Damage List
                                                        </button>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                  <tbody>
                                                    {part?.parts_damage?.map(
                                                      (data, rowIndex) => {
                                                        return (
                                                          <>
                                                            <tr key={rowIndex}>
                                                              <td>
                                                                <div className="col-lg-12 addPartField">
                                                                  <label className="partsLabel col-form-label ml-2">
                                                                    Damage
                                                                    Description
                                                                  </label>
                                                                  <input
                                                                    className="form-control input-search"
                                                                    type="text"
                                                                    name="damage_description"
                                                                    value={
                                                                      data.damage_description
                                                                    }
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleUpdatePartDamage(
                                                                        pIndex,
                                                                        rowIndex,
                                                                        "damage_description",
                                                                        e.target
                                                                          .value
                                                                      )
                                                                    }
                                                                    placeholder="Damge Description"
                                                                  />
                                                                </div>
                                                              </td>

                                                              <td>
                                                                <label className="partsLabel col-form-label ml-2">
                                                                  Type Damage
                                                                </label>
                                                                <Select
                                                                  className="my-Select"
                                                                  options={
                                                                    typeDamageList
                                                                  }
                                                                  value={typeDamageList.find(
                                                                    (option) =>
                                                                      option.value ===
                                                                      Number(
                                                                        data.type_damage_id
                                                                      )
                                                                  )}
                                                                  onChange={(
                                                                    selectedOption
                                                                  ) =>
                                                                    handleUpdatePartDamage(
                                                                      pIndex,
                                                                      rowIndex,
                                                                      "type_damage_id",
                                                                      selectedOption?.value ||
                                                                        null
                                                                    )
                                                                  }
                                                                  isSearchable
                                                                  placeholder="Select Type Damage"
                                                                />
                                                              </td>
                                                              <td>
                                                                <label className="partsLabel col-form-label ml-2">
                                                                  Location
                                                                  Damage
                                                                </label>
                                                                <Select
                                                                  className="my-Select"
                                                                  options={
                                                                    locationDamageList
                                                                  }
                                                                  value={locationDamageList.find(
                                                                    (option) =>
                                                                      option.value ===
                                                                      Number(
                                                                        data.location_damage_id
                                                                      )
                                                                  )}
                                                                  onChange={(
                                                                    selectedOption
                                                                  ) =>
                                                                    handleUpdatePartDamage(
                                                                      pIndex,
                                                                      rowIndex,
                                                                      "location_damage_id",
                                                                      selectedOption?.value ||
                                                                        null
                                                                    )
                                                                  }
                                                                  isSearchable
                                                                  placeholder="Select Location Damage"
                                                                />
                                                              </td>
                                                              <th scope="col">
                                                                <div className="col-lg-12 addPartField">
                                                                  <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                                    Damage
                                                                    Rating
                                                                  </label>
                                                                  <ReactStars
                                                                    size={40}
                                                                    count={5}
                                                                    activeColor="#ffd700"
                                                                    a11y={true}
                                                                    isHalf={
                                                                      true
                                                                    }
                                                                    emptyIcon={
                                                                      <i className="bi bi-star" />
                                                                    }
                                                                    halfIcon={
                                                                      <i className="bi bi-star-half" />
                                                                    }
                                                                    filledIcon={
                                                                      <i className="bi bi-star-fill" />
                                                                    }
                                                                    value={
                                                                      data?.damage_rating
                                                                    }
                                                                    onChange={(
                                                                      newRating
                                                                    ) =>
                                                                      handleUpdatePartDamage(
                                                                        pIndex,
                                                                        rowIndex,
                                                                        "damage_rating",
                                                                        newRating
                                                                      )
                                                                    }
                                                                  />
                                                                </div>
                                                              </th>
                                                              <td>
                                                                <i
                                                                  class="bi bi-trash-fill"
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  onClick={() =>
                                                                    handleDeletePartDamage(
                                                                      pIndex,
                                                                      rowIndex,
                                                                      data.id
                                                                    )
                                                                  }
                                                                ></i>{" "}
                                                              </td>
                                                            </tr>
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </Card.Body>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </div>
                          <div class="back-footer">
                            <div className="row">
                              <div className="col-sm-6">
                                <button
                                  type="button"
                                  className="next-button btn btn-secondary"
                                  onClick={() => navigate("/parts-inventory")}
                                >
                                  <i class="bi bi-arrow-left"></i> {t("back")}
                                </button>
                              </div>

                              <div className="col-lg-6 btnTextAlign">
                                <div className="d-flex justify-content-end">
                                  {btnSaveNext ? (
                                    <button
                                      type="submit"
                                      disabled={true}
                                      className="btn btn-primary mr-2"
                                    >
                                      Loading...
                                    </button>
                                  ) : (
                                    <button
                                      type="submit"
                                      className="btn btn-primary mr-2"
                                    >
                                      {t("save")}& {t("next")}
                                      <i class="bi bi-save ms-2"></i>
                                    </button>
                                  )}

                                  <button
                                    type="button"
                                    className="btn btn-primary me-2"
                                    onClick={handleNextPart}
                                  >
                                    {t("next")}
                                    <i class="bi bi-arrow-right ms-2"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </>
                )}

                {currentStep === 2 && (
                  <>
                    <div className="card-body vehicleparts-table mt-3">
                      <div className="vehicleparts-table-inner">
                        <div className="row">
                          <>
                            <Form onSubmit={handleSubmits} className="row">
                              <React.Fragment>
                                <div className="col-lg-12">
                                  <Card className="text-center">
                                    <Card.Header className="cardHeader">
                                      <div className="d-flex">
                                        <div className="AddPh6">
                                          <h6>Part Information</h6>
                                        </div>
                                      </div>
                                    </Card.Header>
                                    <Card.Body
                                      className="cardBody"
                                      style={{ padding: "0" }}
                                    >
                                      <div class="container text-justify">
                                        <div
                                          class="row"
                                          style={{
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                          }}
                                        >
                                          <div class="col">Part Name</div>
                                          <div class="col">
                                            {vehicleAddDetails?.part_name}
                                          </div>
                                        </div>
                                        <div
                                          class="row"
                                          style={{
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                          }}
                                        >
                                          <div class="col">
                                            Part Description
                                          </div>
                                          <div class="col">
                                            {
                                              vehicleAddDetails?.part_description
                                            }
                                          </div>
                                        </div>

                                        <div
                                          class="row"
                                          style={{
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                          }}
                                        >
                                          <div class="col">Part Quantity</div>
                                          <div class="col">
                                            {vehicleAddDetails?.quantity || "1"}
                                          </div>
                                        </div>
                                        <div
                                          class="row"
                                          style={{
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                          }}
                                        >
                                          <div class="col">Part Price</div>
                                          <div class="col">
                                            {vehicleAddDetails?.part_price || 0}{" "}
                                            €
                                          </div>
                                        </div>

                                        <div
                                          class="row"
                                          style={{
                                            padding: "8px",
                                            borderBottom: "1px solid #eee",
                                          }}
                                        >
                                          <div
                                            class="col"
                                            style={{ lineHeight: "18px" }}
                                          >
                                            Sponsor Price{" "}
                                          </div>
                                          <div class="col">
                                            {vehicleAddDetails?.sponsor_price ||
                                              0}{" "}
                                            €
                                          </div>
                                        </div>
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </div>

                                <div className="col-lg-12">
                                  <Card className="text-center">
                                    <Card.Header className="cardHeader">
                                      <div className="d-flex">
                                        <div className="AddPh6">
                                          <h6>{t("upload_part_images")}</h6>
                                        </div>
                                      </div>
                                    </Card.Header>
                                    <Card.Body className="cardBody">
                                      <div className="row">
                                        <div className="col-lg-12 addPartField">
                                          <div className="col-lg-12 addPartField">
                                            <label className="partsLabel col-form-label ml-2 Label-my form-label form-label uploadImgs">
                                              {t("upload_images")}{" "}
                                            </label>
                                            <div className="input-div">
                                              <div className="avatar-upload">
                                                <div className="avatar-edit">
                                                  <input
                                                    ref={fileInputRef}
                                                    className="inputFile"
                                                    type="file"
                                                    id="imageuploadAbout"
                                                    onChange={
                                                      handleAboutFileChange
                                                    }
                                                    name="file"
                                                    accept=".jpg,.jpeg,.png"
                                                    multiple
                                                  />
                                                  <div className="input-div input-div-custom">
                                                    {previewUrl?.length > 0 &&
                                                    images?.length > 0 ? (
                                                      <>
                                                        {images?.map(
                                                          (file, index) => (
                                                            <div className="partImages mt-2">
                                                              <div
                                                                key={index}
                                                                className="preview-item file-item-custom"
                                                              >
                                                                <div
                                                                  className="btn-Remove"
                                                                  style={{
                                                                    width:
                                                                      "20px",
                                                                    background:
                                                                      "transparent",
                                                                    right:
                                                                      "auto",
                                                                    left: "13px",
                                                                    top: "0",
                                                                  }}
                                                                >
                                                                  <i
                                                                    className="editImageBtn bi bi-pen-fill"
                                                                    style={{
                                                                      position:
                                                                        "absolute",
                                                                      top: "33px",
                                                                      left: "5px",
                                                                      cursor:
                                                                        "pointer",
                                                                      color:
                                                                        "blue",
                                                                    }}
                                                                    onClick={() =>
                                                                      handleImageSelect(
                                                                        file
                                                                      )
                                                                    }
                                                                  ></i>
                                                                </div>
                                                                <img
                                                                  src={
                                                                    previewUrl[
                                                                      index
                                                                    ]
                                                                  }
                                                                  alt={`Preview ${index}`}
                                                                  style={{
                                                                    width:
                                                                      "100px",
                                                                    margin:
                                                                      "5px",
                                                                    height:
                                                                      "100px",
                                                                    border:
                                                                      "1px solid",
                                                                    borderRadius:
                                                                      "5px",
                                                                    maxWidth:
                                                                      "100px",
                                                                    maxHeight:
                                                                      "100px",
                                                                  }}
                                                                />

                                                                <i
                                                                  className="removeImageBtn bi bi-x"
                                                                  style={{
                                                                    position:
                                                                      "absolute",
                                                                    top: "33px",
                                                                    right:
                                                                      "13px",
                                                                    cursor:
                                                                      "pointer",
                                                                    color:
                                                                      "red",
                                                                  }}
                                                                  onClick={() =>
                                                                    handleRemoveFile(
                                                                      index,
                                                                      file.id
                                                                    )
                                                                  }
                                                                ></i>
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </>
                                                    ) : null}
                                                  </div>
                                                  <label htmlFor="imageuploadAbout"></label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </div>
                              </React.Fragment>
                              <div className="d-flex flex-row mt-3 finishBtn justify-content-between">
                                {/* {!isLastPart && (
                                  <Button
                                    className="me-2 mb-2 btn-primary"
                                    onClick={handleOpenSkip}
                                  >
                                     {t("skip")} & Next
                                  </Button>
                                )}

                                {!isLastPart && (
                                  <Button className="me-2 mb-2 btn-primary" type="submit">
                                                                    {t("save")}
 & Next
                                  </Button>
                                )} */}

                                {/* {isLastPart && ( */}
                                <div class="d-flex">
                                  <button
                                    type="button"
                                    class="back-btn bottomSpace"
                                    onClick={handleBackButtonClick}
                                  >
                                    <i class="bi bi-arrow-left"></i> {t("back")}
                                  </button>
                                </div>
                                <div className="d-flex">
                                  {btnImage ? (
                                    <Button
                                      className="me-2 mb-2 btn-primary"
                                      disabled={true}
                                    >
                                      Loading
                                    </Button>
                                  ) : (
                                    <Button
                                      className="me-2 mb-2 btn-Finish"
                                      onClick={handleFinish}
                                    >
                                      {t("save")}
                                      <i class="bi bi-save"></i>
                                    </Button>
                                  )}

                                  <Button
                                    type="Button"
                                    className="btn btn-primary nxt-btn"
                                    onClick={handleImportModal}
                                  >
                                    Sort By Order{" "}
                                    <i class="bi bi-arrow-right"></i>
                                  </Button>

                                  <Button
                                    className="me-2 mb-2 btn-Finish"
                                    onClick={hanldeLastEditfinish}
                                  >
                                    {t("finish")}
                                    <i class="bi bi-check2"></i>
                                  </Button>
                                </div>

                                {/* )} */}
                                {/* {isLastPart && (
                                  <Button
                                    className="me-2 mb-2"
                                    onClick={() => navigate("/parts-inventory")}
                                  >
                                    Close
                                  </Button>
                                )} */}
                              </div>
                            </Form>
                          </>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {currentStep === 4 && (
                  <AssignedDamageImage
                    partImageDataList={partImageDataList}
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                  />
                )}

                {currentStep === 5 && (
                  <AddPartVideo
                    currentStep={currentStep}
                    currentPart={currentPart}
                    vehicleAddDetails={vehicleAddDetails}
                    setCurrentStep={setCurrentStep}
                    partVideoDataList={partVideoDataList}
                    setPartVideoDataList={setPartVideoDataList}
                  />
                )}

                {currentStep === 6 && (
                  <AssignedDamageVideo
                    partVideoDataList={partVideoDataList}
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                  />
                )}
              </>
            ) : (
              <>
                {currentStep === 1 && (
                  <>
                    <div className="d-flex">
                      <div className="mt-2">
                        <FormGroup>
                          <Label className="col-form-label ml-2">
                            {t("vehicle")}
                          </Label>
                        </FormGroup>
                      </div>
                      <div className="">
                        {vehicleModalSelectedList?.vehicle_in_inventory ===
                          true && (
                          <div className="mt-2 ms-3">
                            <Button
                              className="next-button"
                              type="button"
                              style={{ width: "134px" }}
                              onClick={onvehiclePartDetailsOpen}
                            >
                              Exceed Part
                              <i class="bi bi-tools"></i>
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-7">
                        <div className="card-body vehicleparts-table vehicleparts-table-h-100">
                          <div className="row justify-content-start">
                            <div className="col-md-6">
                              <div className="input-div">
                                <Select
                                  className="my-Select"
                                  options={VehicleModelAllList}
                                  value={
                                    VehicleModelAllList.find(
                                      (item) =>
                                        item.value ==
                                        vehicleModalSelectedList.vehicle_model_name
                                    ) || null
                                  }
                                  onChange={(selectedOption) => {
                                    setVehicleEngineId({
                                      ...vehicleModalSelectedList,
                                      vehicle_model_name: selectedOption
                                        ? selectedOption.value
                                        : "",
                                    });
                                    setVehicleModalSelectedList(selectedOption);
                                  }}
                                  isSearchable={true}
                                  onInputChange={handleInputChange}
                                  inputSelctedValue={inputValue}
                                  placeholder={"Select Vehicle"}
                                />
                              </div>
                            </div>

                            <div
                              className="col-md-6"
                              style={{ marginBottom: "10px" }}
                            >
                              <div className="row">
                                <div className="col-md-10">
                                  <Select
                                    className="my-Select"
                                    options={DismetalList}
                                    value={
                                      DismetalList.find(
                                        (item) =>
                                          item.value ===
                                          dismentalData.dismantle_id
                                      ) || null
                                    }
                                    onChange={
                                      handleSelectWithoutChangeDismetalList
                                    }
                                    placeholder={"Select Dismental"}
                                    isSearchable={true}
                                    onInputChange={
                                      handleInputSelectedChangeDismetalList
                                    }
                                    inputSelctedValue={
                                      inputSelctedDismetalValue
                                    }
                                  />{" "}
                                </div>
                                <div
                                  className="col-md-2"
                                  style={{ marginTop: "10px" }}
                                >
                                  <div
                                    className="addBtn"
                                    style={{ cursor: "pointer" }}
                                    onClick={handleTempDismetalAddClick}
                                  >
                                    <i className="bi bi-plus-lg"></i>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="mb-3  vehicleparts-input">
                                <div className="">
                                  <div className="">
                                    <input
                                      className="form-control input-search"
                                      type="text"
                                      value={searchQuery}
                                      onChange={handleSearchChange}
                                      maxLength={50}
                                      placeholder="Search"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="vehicleparts-table-inner vehicleThBg tableScroll">
                            <table className="rounded-table table table-striped table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th style={{ width: "10%" }}>Part Catalog</th>
                                  {vehicleModalSelectedList?.vehicle_model_id && (
                                    <th style={{ width: "10%" }}>Vehicle</th>
                                  )}
                                  <th style={{ width: "0%" }}>{t("action")}</th>
                                </tr>
                              </thead>

                              <tbody>
                                {(vehicleModalSelectedList?.vehicle_model_id
                                  ? filteredCatalogList
                                  : CatalogList.data
                                )?.length > 0 ? (
                                  (vehicleModalSelectedList?.vehicle_model_id
                                    ? filteredCatalogList
                                    : CatalogList.data
                                  ).map((item, index) => (
                                    <tr key={index}>
                                      <td>
                                        {LangData === "pt"
                                          ? item.namePartPT
                                          : LangData === "fr"
                                          ? item.namePartFR
                                          : LangData === "es"
                                          ? item.namePartES
                                          : LangData === "it"
                                          ? item.namePartIT
                                          : LangData === "de"
                                          ? item.namePartDE
                                          : item.namePartEN}{" "}
                                      </td>
                                      {vehicleModalSelectedList?.vehicle_model_id && (
                                        <td>
                                          {
                                            vehicleModalSelectedList?.vehicle_make_name
                                          }{" "}
                                          |{" "}
                                          {
                                            vehicleModalSelectedList?.vehicle_model_name
                                          }
                                          |{" "}
                                          {
                                            vehicleModalSelectedList?.registration_plate
                                          }{" "}
                                          |{" "}
                                          {
                                            vehicleModalSelectedList?.registration
                                          }
                                        </td>
                                      )}
                                      <td>
                                        <Button
                                          className="addBtn"
                                          onClick={() => handleAddClick(item)}
                                          disabled={
                                            !vehicleModalSelectedList?.vehicle_model_id
                                          }
                                        >
                                          <i className="bi bi-plus-lg"></i>
                                        </Button>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="100%">No results found</td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-5">
                        <div className="card-body vehicleparts-table  vehicleparts-table-h-100">
                          <div className="mb-3  vehicleparts-input">
                            <input
                              className="form-control input-search"
                              type="text"
                              value={searchQueryTemp}
                              onChange={handleSearchChangeTemp}
                              maxLength={50}
                              placeholder="Search"
                            />
                          </div>
                          <div className="spacheheight"></div>
                          <div className="vehicleparts-table-inner vehicleThBg tableScroll">
                            <table className="rounded-table table table-striped table-bordered table-hover scrolldown">
                              <thead>
                                <tr>
                                  <th style={{ width: "24%" }}>
                                    {t("part_catalog")}
                                  </th>
                                  <th style={{ width: "10%" }}>
                                    {t("vehicle_model")}
                                  </th>

                                  <th style={{ width: "0%" }}>{t("action")}</th>
                                </tr>
                              </thead>

                              <tbody>
                                {selectedItems?.length > 0 ? (
                                  selectedItems?.map((item, index) => (
                                    <tr key={index}>
                                      <td>{item.namePartPT}</td>

                                      <td>{item?.vehicle_model_name}</td>

                                      <td>
                                        <Button
                                          className="btn-danger-delete"
                                          onClick={() =>
                                            handleDeleteClick(item)
                                          }
                                        >
                                          <i class="bi bi-trash3"></i>
                                        </Button>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="100%" className="text-center">
                                      {t("no_results_found")}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {tempData?.temp_id && (
                        <div className="col-lg-12">
                          <div className="fuelCF">
                            <div className="card-footer clearfix card-footer-part float-end">
                              <div className="pagination pagination-sm m-0  paginationRight">
                                <Pagination
                                  activePage={params.page}
                                  itemsCountPerPage={parseInt(params.limit)}
                                  totalItemsCount={parseInt(totalRecords)}
                                  pageRangeDisplayed={5}
                                  itemClass={`page-item`}
                                  linkClass={`page-link`}
                                  onChange={(e) =>
                                    handleParams(e, "pagination")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div class="back-footer">
                      <div className="row">
                        <div className="col">
                          <button
                            type="button"
                            className="back-button btn btn-secondary"
                            onClick={handleBackButtonClick}
                          >
                            <i className="bi bi-arrow-left"></i> {t("back")}
                          </button>
                        </div>
                        <div className="d-flex justify-content-end col">
                          {btnNext ? (
                            <Button
                              className="btn-primary"
                              type="button"
                              disabled={true}
                            >
                              Loading...
                            </Button>
                          ) : (
                            <Button
                              className="next-button"
                              type="button"
                              onClick={(e) => handleNextClick(e)}
                              // disabled={
                              //   !vehiclePartsData?.vehicle_make &&
                              //   !tempData?.temp_id
                              // }
                            >
                              {t("next")}
                              <i className="bi bi-arrow-right"></i>
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {currentStep === 2 && (
                  <>
                    <div className="card-body vehicleparts-table mt-3">
                      <div className="vehicleparts-table-inner">
                        <Form method={`post`} onSubmit={handleSubmit}>
                          <div
                            className="row justify-content-start"
                            style={{ justifyContent: "flex-start" }}
                          >
                            {partsData?.map((part, pIndex) => {
                              return (
                                <React.Fragment key={pIndex}>
                                  <div className="col-lg-12" key={part?.id}>
                                    <div className="text-center  inventory-addparts-custom">
                                      <Card.Header className="cardHeader partInfoHeader">
                                        <div
                                          className="partInfo"
                                          data-bs-toggle="collapse"
                                          href={`#multiCollapseExample-${part?.tempId}`}
                                          role="button"
                                          aria-expanded="true"
                                          aria-controls={`multiCollapseExample-${part?.tempId}`}
                                        >
                                          <div className="AddPh6">
                                            <h6>
                                              {part?.part_name} |{" "}
                                              {part?.parts_internal_id} |{" "}
                                              {part?.vehicle_make_name} |{" "}
                                              {part?.vehicle_model_name}
                                            </h6>
                                          </div>
                                          <div className="">
                                            {btnClosed ? (
                                              <button
                                                type="button"
                                                className="btnClose"
                                              >
                                                <i className="bi bi-arrow-repeat"></i>
                                              </button>
                                            ) : (
                                              <button
                                                type="button"
                                                className="btnClose"
                                                onClick={() =>
                                                  handleDeleteClick(part)
                                                }
                                              >
                                                <i className="bi bi-x-lg"></i>
                                              </button>
                                            )}
                                          </div>
                                        </div>
                                      </Card.Header>
                                      <div className="row mb-4 mt-4 justify-content-end text-end">
                                        <div className="col-md-4 mb-4">
                                          <button
                                            style={{
                                              marginLeft: "12px",
                                            }}
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              e.preventDefault();
                                              handleOpenPartHistory(e, part);
                                            }}
                                          >
                                            {part ? part?.part_model_stats : 0}
                                          </button>
                                          <button
                                            style={{
                                              marginLeft: "10px",
                                            }}
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              e.preventDefault();
                                              handleOpenSponsorPrice(e, part);
                                            }}
                                          >
                                            Statiscal Price
                                          </button>
                                          <button
                                            style={{
                                              marginLeft: "10px",
                                            }}
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              e.preventDefault();
                                              handleOpenEstimatePrice(e, part);
                                            }}
                                          >
                                            Estimate Price
                                          </button>
                                        </div>
                                        <div className="col " Reference Code>
                                          <Card.Body
                                            className="cardBody collapse multi-collapse show card"
                                            id={`multiCollapseExample-${part?.tempId}`}
                                          >
                                            <div className="vehicleThBg tableScroll  tablescroll-custom-two">
                                              <div className="row justify-content-start">
                                                <div className="col-lg-3 addPartField">
                                                  <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                    {t("quantity")}
                                                  </label>
                                                  <input
                                                    className="form-control input-search"
                                                    type="number"
                                                    name="quantity"
                                                    value={part?.quantity}
                                                    onChange={(e) =>
                                                      handleInputChangePartLists(
                                                        e,
                                                        part?.tempId
                                                      )
                                                    }
                                                    placeholder="0"
                                                  />

                                                  {errors.quantity && (
                                                    <span className="input-error">
                                                      {errors.quantity}
                                                    </span>
                                                  )}
                                                </div>

                                                <div className="col-lg-3 addPartField">
                                                  <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                    Price 1 (€){" "}
                                                  </label>
                                                  <input
                                                    className="form-control input-search"
                                                    type="number"
                                                    name="part_price"
                                                    value={part?.part_price}
                                                    onChange={(e) =>
                                                      handleInputChangePartLists(
                                                        e,
                                                        part?.tempId
                                                      )
                                                    }
                                                    placeholder="0"
                                                  />

                                                  {errors.part_price && (
                                                    <span className="input-error">
                                                      {errors.part_price}
                                                    </span>
                                                  )}
                                                </div>
                                                <div className="col-lg-3 addPartField">
                                                  <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                    Price 2 (
                                                    {
                                                      settingDetails?.discount_percentage
                                                    }
                                                    % discount) €
                                                  </label>
                                                  <input
                                                    className="form-control input-search"
                                                    type="number"
                                                    name="part_original_price"
                                                    value={
                                                      part?.part_original_price
                                                    }
                                                    onChange={(e) =>
                                                      handleInputChangePartLists(
                                                        e,
                                                        part?.tempId
                                                      )
                                                    }
                                                    placeholder="0"
                                                  />
                                                </div>

                                                <div className="col-lg-3 addPartField">
                                                  <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                    Part Cost Price €
                                                  </label>
                                                  <input
                                                    className="form-control input-search"
                                                    type="number"
                                                    name="part_cost_price"
                                                    value={
                                                      part?.part_cost_price
                                                    }
                                                    onChange={(e) =>
                                                      handleInputChangePartLists(
                                                        e,
                                                        part?.tempId
                                                      )
                                                    }
                                                    placeholder="0"
                                                  />
                                                </div>

                                                <div className="col-lg-3 addPartField">
                                                  <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                    Inventory Status
                                                  </label>
                                                  <Select
                                                    className="my-Select"
                                                    options={inventoryList}
                                                    name="inventory_status"
                                                    value={
                                                      inventoryList.find(
                                                        (item) =>
                                                          item.value ===
                                                          partsData[pIndex]
                                                            ?.inventory_status
                                                      ) ||
                                                      inventoryList.find(
                                                        (item) =>
                                                          item.value === 1
                                                      )
                                                    }
                                                    onChange={(
                                                      selectedOption
                                                    ) =>
                                                      handleInventoryIdChange(
                                                        pIndex,
                                                        selectedOption
                                                      )
                                                    }
                                                    placeholder={
                                                      "Select Inventory Status"
                                                    }
                                                    isSearchable={true}
                                                  ></Select>
                                                </div>

                                                <div className="col-lg-3 addPartField">
                                                  <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                    Condition
                                                  </label>
                                                  <Select
                                                    className="my-Select"
                                                    options={conditionList}
                                                    value={
                                                      conditionList.find(
                                                        (item) =>
                                                          item.value ===
                                                          partsData[pIndex]
                                                            ?.condition_id
                                                      ) ||
                                                      conditionList.find(
                                                        (item) =>
                                                          item.value ===
                                                          settingDetails?.condition_id
                                                      ) ||
                                                      conditionList.find(
                                                        (item) =>
                                                          item.value === 1
                                                      )
                                                    }
                                                    onChange={(
                                                      selectedOption
                                                    ) =>
                                                      handleConditionChange(
                                                        pIndex,
                                                        selectedOption
                                                      )
                                                    }
                                                    isSearchable
                                                    placeholder={
                                                      "Select Condition"
                                                    }
                                                  ></Select>
                                                </div>

                                                <div className="col-lg-3 addPartField">
                                                  <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                    State
                                                  </label>
                                                  <Select
                                                    className="my-Select"
                                                    options={stateList}
                                                    value={
                                                      stateList.find(
                                                        (item) =>
                                                          item.value ===
                                                          partsData[pIndex]
                                                            ?.state_id
                                                      ) ||
                                                      stateList.find(
                                                        (item) =>
                                                          item.value ===
                                                          settingDetails?.state_id
                                                      ) ||
                                                      stateList.find(
                                                        (item) =>
                                                          item.value === 1
                                                      )
                                                    }
                                                    onChange={(
                                                      selectedOption
                                                    ) =>
                                                      handleStateListChange(
                                                        pIndex,
                                                        selectedOption
                                                      )
                                                    }
                                                    isSearchable
                                                    placeholder={
                                                      "Select Condition"
                                                    }
                                                  ></Select>
                                                </div>
                                                <div className="col-lg-3 addPartField">
                                                  <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                    {t("part")} {t("brand")}
                                                  </label>
                                                  <Select
                                                    className="my-Select"
                                                    options={allBrandList}
                                                    name="brand_id"
                                                    value={allBrandList.find(
                                                      (option) =>
                                                        option.value ===
                                                        part?.brand_id
                                                    )}
                                                    onChange={(
                                                      selectedOption
                                                    ) =>
                                                      handleBrandIdChange(
                                                        pIndex,
                                                        selectedOption
                                                      )
                                                    }
                                                    placeholder={
                                                      "Select Part Brand"
                                                    }
                                                    isSearchable={true}
                                                  ></Select>
                                                </div>

                                                <div className="col-lg-12 addPartField">
                                                  <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                    Notes
                                                  </label>
                                                  <textarea
                                                    className="form-control input-search"
                                                    type="text"
                                                    name="part_description"
                                                    value={
                                                      part?.part_description
                                                    }
                                                    onChange={(e) =>
                                                      handleInputChangePartList(
                                                        e,
                                                        part?.id
                                                      )
                                                    }
                                                    placeholder="Notes"
                                                    rows={3}
                                                  />
                                                </div>

                                                <div className="col-lg-2 addPartField">
                                                  <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                    Custome Size
                                                  </label>

                                                  <div className="col-lg-12 addPartField">
                                                    <div className="form-check text-left">
                                                      <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={
                                                          part?.is_custom_size ===
                                                          "1"
                                                        }
                                                        onChange={(e) =>
                                                          handleCheckboxCustomChange(
                                                            pIndex,
                                                            e
                                                          )
                                                        }
                                                      />
                                                      <label className="form-check-label">
                                                        Custome Size
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-lg-2 addPartField">
                                                  <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                    Print label
                                                  </label>
                                                  <div className="col-lg-12 addPartField">
                                                    <div className="form-check text-left">
                                                      <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={
                                                          part?.print_label ===
                                                          1
                                                        }
                                                        onChange={(e) =>
                                                          handleCheckboxPrintChanges(
                                                            pIndex,
                                                            e
                                                          )
                                                        }
                                                      />
                                                      <label className="form-check-label">
                                                        Print label
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-lg-2 addPartField">
                                                  <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                    VAT included{" "}
                                                  </label>
                                                  <div className="col-lg-12 addPartField">
                                                    <div className="form-check text-left">
                                                      <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={
                                                          part?.vat_included ===
                                                          1
                                                        }
                                                        onChange={(e) =>
                                                          handleCheckboxVatChanges(
                                                            pIndex,
                                                            e
                                                          )
                                                        }
                                                      />
                                                      <label className="form-check-label">
                                                        VAT included{" "}
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-lg-3 addPartField col-lg-3-mob">
                                                  <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                    Associate Location
                                                  </label>
                                                  <div className="addPartField">
                                                    <div className="form-check text-left">
                                                      <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        checked={
                                                          part?.associate_location ===
                                                          1
                                                        }
                                                        onChange={(e) =>
                                                          handleCheckboxAssociateLocationChanges(
                                                            pIndex,
                                                            e
                                                          )
                                                        }
                                                      />
                                                      <label className="form-check-label">
                                                        Associate Location
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-lg-3 addPartField col-lg-3-mob">
                                                  <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                    Part Rating
                                                  </label>
                                                  <div className="addPartField">
                                                    <div className="form-check text-left">
                                                      <ReactStars
                                                        size={40}
                                                        count={5}
                                                        activeColor="#ffd700"
                                                        a11y={true}
                                                        isHalf={true}
                                                        emptyIcon={
                                                          <i className="bi bi-star" />
                                                        }
                                                        halfIcon={
                                                          <i className="bi bi-star-half" />
                                                        }
                                                        filledIcon={
                                                          <i className="bi bi-star-fill" />
                                                        }
                                                        value={
                                                          part?.part_rating
                                                        }
                                                        onChange={(newRating) =>
                                                          handleRatingChange(
                                                            newRating,
                                                            part.tempId
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="clearfix"></div>
                                                {part?.is_custom_size ===
                                                  "1" && (
                                                  <>
                                                    <div className="col-lg-3 addPartField">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Weight (kg){" "}
                                                      </label>
                                                      <input
                                                        className="form-control input-search"
                                                        type="number"
                                                        name="parts_weight"
                                                        value={
                                                          part?.parts_weight
                                                        }
                                                        onChange={(e) =>
                                                          handleInputChangePartList(
                                                            e,
                                                            part?.id
                                                          )
                                                        }
                                                        placeholder="0"
                                                      />
                                                    </div>

                                                    <div className="col-lg-3 addPartField">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Width(cm){" "}
                                                      </label>
                                                      <input
                                                        className="form-control input-search"
                                                        type="number"
                                                        name="parts_width"
                                                        value={
                                                          part?.parts_width
                                                        }
                                                        onChange={(e) =>
                                                          handleInputChangePartList(
                                                            e,
                                                            part?.id
                                                          )
                                                        }
                                                        placeholder="0"
                                                      />

                                                      {errors.parts_width && (
                                                        <span className="input-error">
                                                          {errors.parts_width}
                                                        </span>
                                                      )}
                                                    </div>

                                                    <div className="col-lg-3 addPartField">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Height(cm){" "}
                                                      </label>
                                                      <input
                                                        className="form-control input-search"
                                                        type="number"
                                                        name="parts_height"
                                                        value={
                                                          part?.parts_height
                                                        }
                                                        onChange={(e) =>
                                                          handleInputChangePartList(
                                                            e,
                                                            part?.id
                                                          )
                                                        }
                                                        placeholder="0"
                                                      />
                                                    </div>

                                                    <div className="col-lg-3 addPartField">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Length(cm){" "}
                                                      </label>
                                                      <input
                                                        className="form-control input-search"
                                                        type="number"
                                                        name="parts_length"
                                                        value={
                                                          part?.parts_length
                                                        }
                                                        onChange={(e) =>
                                                          handleInputChangePartList(
                                                            e,
                                                            part?.id
                                                          )
                                                        }
                                                        placeholder="0"
                                                      />
                                                    </div>
                                                  </>
                                                )}

                                                {part?.state_id === 2 && (
                                                  <div className="col-lg-3 addPartField col-lg-3-mob">
                                                    <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                      Reg Number Dismantler
                                                    </label>
                                                    <input
                                                      className="form-control input-search"
                                                      type="text"
                                                      name="reg_number_dismantler"
                                                      value={
                                                        part?.reg_number_dismantler
                                                      }
                                                      onChange={(e) =>
                                                        handleInputChangePartList(
                                                          e,
                                                          part?.id
                                                        )
                                                      }
                                                      placeholder="0"
                                                    />
                                                  </div>
                                                )}

                                                <div className="col-lg-6 addPartField"></div>

                                                <div className="col-lg-6 addPartField"></div>

                                                {part?.is_show_engine_record ===
                                                  true && (
                                                  <>
                                                    <div className="col-lg-3 addPartField">
                                                      <div className="col-md-12">
                                                        <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                          Engine Code{" "}
                                                        </label>
                                                        <Select
                                                          className="my-Select"
                                                          options={
                                                            vehicleEngineList
                                                          }
                                                          value={vehicleEngineList.find(
                                                            (item) =>
                                                              item.value ===
                                                              EngineData?.vehicle_variant
                                                          )}
                                                          onChange={
                                                            handleSelectWithoutChangeEngineList
                                                          }
                                                          placeholder={
                                                            "Select Engine"
                                                          }
                                                          isSearchable={true}
                                                          onInputChange={
                                                            handleInputSelectedChangeEngineList
                                                          }
                                                          inputSelctedValue={
                                                            inputSelctedEngineValue
                                                          }
                                                        />
                                                      </div>
                                                    </div>

                                                    <div className="col-lg-3 addPartField">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        Cv{" "}
                                                      </label>
                                                      <input
                                                        className="form-control input-search"
                                                        type="text"
                                                        name="cv"
                                                        value={part?.cv}
                                                        onChange={(e) => {
                                                          handleInputChangePartList(
                                                            e,
                                                            part?.tempId
                                                          );
                                                        }}
                                                        placeholder="0"
                                                      />
                                                    </div>

                                                    <div className="col-lg-3 addPartField">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        CC
                                                      </label>
                                                      <input
                                                        className="form-control input-search"
                                                        type="text"
                                                        name="cc"
                                                        value={part?.cc}
                                                        onChange={(e) => {
                                                          handleInputChangePartList(
                                                            e,
                                                            part?.tempId
                                                          );
                                                        }}
                                                        placeholder="0"
                                                      />
                                                    </div>

                                                    <div className="col-lg-3 addPartField">
                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                        KW
                                                      </label>
                                                      <input
                                                        className="form-control input-search"
                                                        type="text"
                                                        name="kw"
                                                        value={part?.kw}
                                                        onChange={(e) => {
                                                          handleInputChangePartList(
                                                            e,
                                                            part?.tempId
                                                          );
                                                        }}
                                                        placeholder="0"
                                                      />
                                                    </div>
                                                  </>
                                                )}

                                                <div
                                                  className="col-lg-12"
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "end",
                                                    marginBottom: "10px",
                                                  }}
                                                >
                                                  {part?.etai_part_id && (
                                                    <>
                                                      <button
                                                        style={{
                                                          marginTop: "8px",
                                                          marginRight: "10px",
                                                        }}
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={(e) =>
                                                          handleEtaiDetails(
                                                            e,
                                                            part?.etai_part_id,
                                                            pIndex
                                                          )
                                                        }
                                                      >
                                                        View Reference
                                                      </button>

                                                      <button
                                                        style={{
                                                          marginTop: "8px",
                                                          marginRight: "10px",
                                                        }}
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={(e) =>
                                                          handleEtaiPriceDetails(
                                                            e,
                                                            part?.etai_part_id,
                                                            pIndex
                                                          )
                                                        }
                                                      >
                                                        View Price
                                                      </button>
                                                    </>
                                                  )}

                                                  {part?.addedData?.length >
                                                    0 && (
                                                    <>
                                                      <button
                                                        style={{
                                                          marginTop: "8px",
                                                          marginRight: "10px",
                                                        }}
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={(e) =>
                                                          handleOpenRefCodeCondition(
                                                            e,
                                                            part
                                                          )
                                                        }
                                                      >
                                                        Parts
                                                      </button>

                                                      <button
                                                        style={{
                                                          marginTop: "8px",
                                                        }}
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={(e) =>
                                                          handleOpenRefCodeEstimate(
                                                            e,
                                                            part
                                                          )
                                                        }
                                                      >
                                                        Estimate Price
                                                      </button>
                                                    </>
                                                  )}
                                                </div>
                                                <div className="col-lg-12">
                                                  <div className="table-responsive-custom-q  table-custom-sticky-inherit">
                                                    <table className="table ">
                                                      <thead>
                                                        <tr>
                                                          <th scope="col">
                                                            <div className="col-lg-12 addPartField">
                                                              <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                                {t(
                                                                  "reference_code"
                                                                )}{" "}
                                                              </label>
                                                              <input
                                                                className="form-control input-search"
                                                                type="text"
                                                                name="reference_code"
                                                                value={
                                                                  selectedReferenceCode
                                                                    ? selectedReferenceCode
                                                                    : part?.reference_code
                                                                }
                                                                onChange={(e) =>
                                                                  handleInputChangePartListRef(
                                                                    e,
                                                                    pIndex
                                                                  )
                                                                }
                                                                placeholder="Reference Code"
                                                              />
                                                            </div>
                                                          </th>

                                                          <th scope="col">
                                                            <div className="col-lg-12 addPartField">
                                                              <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                                Condition
                                                              </label>
                                                              <Select
                                                                className="my-Select"
                                                                options={
                                                                  conditionList
                                                                }
                                                                value={
                                                                  conditionList.find(
                                                                    (option) =>
                                                                      option.value ===
                                                                        part?.condition_id_ref ||
                                                                      option.label ===
                                                                        part?.selectedCondition
                                                                  ) || null
                                                                }
                                                                onChange={(
                                                                  selectedOption
                                                                ) =>
                                                                  handleConditionSChangeRefs(
                                                                    part?.id,
                                                                    selectedOption
                                                                  )
                                                                }
                                                                isSearchable
                                                                placeholder={
                                                                  "Select Condition"
                                                                }
                                                              ></Select>
                                                            </div>
                                                          </th>
                                                          <th scope="col">
                                                            <div className="col-lg-12 addPartField">
                                                              <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                                Reference Type
                                                              </label>
                                                              <Select
                                                                className="my-Select"
                                                                options={
                                                                  referenceList
                                                                }
                                                                value={
                                                                  referenceList.find(
                                                                    (option) =>
                                                                      option.value ===
                                                                        part?.type_id ||
                                                                      option.label ===
                                                                        part?.selectedType
                                                                  ) || null
                                                                }
                                                                onChange={(
                                                                  selectedOption
                                                                ) =>
                                                                  handleConditionChangeRef(
                                                                    part?.id,
                                                                    selectedOption
                                                                  )
                                                                }
                                                                isSearchable
                                                                placeholder="Select Type"
                                                              />
                                                            </div>
                                                          </th>
                                                          <th scope="col"></th>
                                                          <th>
                                                            <button
                                                              style={{
                                                                marginTop:
                                                                  "77px",
                                                              }}
                                                              type="button"
                                                              className="btn btn-primary"
                                                              onClick={() =>
                                                                handleAddPartRef(
                                                                  pIndex
                                                                )
                                                              }
                                                            >
                                                              Add Reference{" "}
                                                            </button>
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {part?.addedData?.map(
                                                          (data, rowIndex) => {
                                                            return (
                                                              <>
                                                                <tr
                                                                  key={rowIndex}
                                                                >
                                                                  <td>
                                                                    {/* <input
                                                                  className="form-control"
                                                                  type="text"
                                                                  value={
                                                                    data.reference_code
                                                                  }
                                                                  readOnly
                                                                /> */}

                                                                    <div className="col-lg-12 addPartField">
                                                                      <label className="partsLabel col-form-label ml-2">
                                                                        Reference
                                                                        Code
                                                                      </label>
                                                                      <input
                                                                        className="form-control"
                                                                        type="text"
                                                                        name="reference_code"
                                                                        value={
                                                                          data?.reference_code
                                                                        }
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          handleUpdatePartRef(
                                                                            pIndex,
                                                                            rowIndex,
                                                                            "reference_code",
                                                                            e
                                                                              .target
                                                                              .value
                                                                          )
                                                                        }
                                                                        placeholder="Reference Code"
                                                                      />
                                                                    </div>
                                                                  </td>

                                                                  <td>
                                                                    <label className="partsLabel col-form-label ml-2">
                                                                      Condition
                                                                    </label>
                                                                    <Select
                                                                      className="my-Select"
                                                                      options={
                                                                        conditionList
                                                                      }
                                                                      value={conditionList.find(
                                                                        (
                                                                          option
                                                                        ) =>
                                                                          option.value ===
                                                                          data?.condition_id_ref
                                                                      )}
                                                                      onChange={(
                                                                        selectedOption
                                                                      ) =>
                                                                        handleUpdatePartRef(
                                                                          pIndex,
                                                                          rowIndex,
                                                                          "condition_id_ref",
                                                                          selectedOption?.value ||
                                                                            null
                                                                        )
                                                                      }
                                                                      isSearchable
                                                                      placeholder="Select Condition"
                                                                    />
                                                                  </td>
                                                                  <td>
                                                                    <label className="partsLabel col-form-label ml-2">
                                                                      Reference
                                                                      Type
                                                                    </label>
                                                                    <Select
                                                                      className="my-Select"
                                                                      options={
                                                                        referenceList
                                                                      }
                                                                      value={referenceList.find(
                                                                        (
                                                                          option
                                                                        ) =>
                                                                          option.value ===
                                                                          data?.type_id
                                                                      )}
                                                                      onChange={(
                                                                        selectedOption
                                                                      ) =>
                                                                        handleUpdatePartRef(
                                                                          pIndex,
                                                                          rowIndex,
                                                                          "type_id",
                                                                          selectedOption?.value ||
                                                                            null
                                                                        )
                                                                      }
                                                                      isSearchable
                                                                      placeholder="Select Type"
                                                                    />
                                                                  </td>
                                                                  <td>
                                                                    <div className="form-check text-left">
                                                                      <label className="partsLabel col-form-label ml-2">
                                                                        Is Main
                                                                      </label>
                                                                      <input
                                                                        style={{
                                                                          marginLeft:
                                                                            "20px",
                                                                        }}
                                                                        className="form-check-input"
                                                                        type="radio" // Changed from checkbox to radio
                                                                        name={`isMainRadioGroup-${pIndex}-${rowIndex}`} // Unique name for each radio button group (to handle multiple groups)
                                                                        checked={
                                                                          data?.is_main ===
                                                                          1
                                                                        }
                                                                        onChange={() =>
                                                                          handleUpdatePartRef(
                                                                            pIndex,
                                                                            rowIndex,
                                                                            "is_main",
                                                                            1
                                                                          )
                                                                        }
                                                                      />
                                                                    </div>
                                                                  </td>
                                                                  <td>
                                                                    <label className="partsLabel col-form-label ml-2">
                                                                      {t(
                                                                        "action"
                                                                      )}{" "}
                                                                    </label>
                                                                    {/* <i
                                                                      className="bi bi-pen-fill"
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                        marginRight:
                                                                          "10px",
                                                                      }}
                                                                      onClick={() =>
                                                                        handleEditPartRef(
                                                                          pIndex,
                                                                          rowIndex
                                                                        )
                                                                      }
                                                                    /> */}
                                                                    <i
                                                                      class="bi bi-trash-fill"
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() =>
                                                                        handleDeletePartRef(
                                                                          pIndex,
                                                                          rowIndex
                                                                        )
                                                                      }
                                                                    ></i>{" "}
                                                                    {/* <button
                                                                  className="btn btn-danger"
                                                                  onClick={() =>
                                                                    handleDeletePartRef(
                                                                      pIndex,
                                                                      rowIndex
                                                                    )
                                                                  }
                                                                >
                                                                  Delete
                                                                </button> */}
                                                                  </td>
                                                                </tr>
                                                              </>
                                                            );
                                                          }
                                                        )}
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>

                                                <div className="col-lg-12">
                                                  <div className=" table-responsive-custom-q  table-custom-sticky-inherit">
                                                    <table className="table">
                                                      <thead>
                                                        <tr>
                                                          <th scope="col">
                                                            <div
                                                              className="col-lg-12 addPartField"
                                                              style={{
                                                                width: "206px",
                                                              }}
                                                            >
                                                              <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                                Damage
                                                                Description
                                                              </label>
                                                              <input
                                                                className="form-control input-search"
                                                                type="text"
                                                                name="damage_description"
                                                                value={
                                                                  part?.damage_description
                                                                }
                                                                onChange={(e) =>
                                                                  handleInputChangePartListRef(
                                                                    e,
                                                                    pIndex
                                                                  )
                                                                }
                                                                placeholder="Damage Description"
                                                              />
                                                            </div>
                                                          </th>

                                                          <th scope="col">
                                                            <div className="col-lg-12 addPartField">
                                                              <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                                Type Damage
                                                              </label>
                                                              <Select
                                                                className="my-Select"
                                                                options={
                                                                  typeDamageList
                                                                }
                                                                value={
                                                                  typeDamageList.find(
                                                                    (option) =>
                                                                      option.value ===
                                                                        part?.type_damage_id ||
                                                                      option.label ===
                                                                        part?.selectedCondition
                                                                  ) || null
                                                                }
                                                                onChange={(
                                                                  selectedOption
                                                                ) =>
                                                                  handleTypeDamageChange(
                                                                    pIndex,
                                                                    selectedOption
                                                                  )
                                                                }
                                                                isSearchable
                                                                placeholder={
                                                                  "Select Type Damage"
                                                                }
                                                              ></Select>
                                                            </div>
                                                          </th>
                                                          <th scope="col">
                                                            <div className="col-lg-12 addPartField">
                                                              <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                                Location Damage
                                                              </label>
                                                              <Select
                                                                className="my-Select"
                                                                options={
                                                                  locationDamageList
                                                                }
                                                                value={
                                                                  locationDamageList.find(
                                                                    (option) =>
                                                                      option.value ===
                                                                        part?.location_damage_id ||
                                                                      option.label ===
                                                                        part?.selectedType
                                                                  ) || null
                                                                }
                                                                onChange={(
                                                                  selectedOption
                                                                ) =>
                                                                  hanldeLocationDamageChange(
                                                                    pIndex,
                                                                    selectedOption
                                                                  )
                                                                }
                                                                isSearchable
                                                                placeholder="Select Location Damage"
                                                              />
                                                            </div>
                                                          </th>
                                                          <th scope="col">
                                                            <div
                                                              className="col-lg-12 addPartField"
                                                              style={{
                                                                width: "192px",
                                                              }}
                                                            >
                                                              <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                                Damage Rating
                                                              </label>
                                                              <ReactStars
                                                                size={40}
                                                                count={5}
                                                                activeColor="#ffd700"
                                                                a11y={true}
                                                                isHalf={true}
                                                                emptyIcon={
                                                                  <i className="bi bi-star" />
                                                                }
                                                                halfIcon={
                                                                  <i className="bi bi-star-half" />
                                                                }
                                                                filledIcon={
                                                                  <i className="bi bi-star-fill" />
                                                                }
                                                                value={
                                                                  part?.damage_rating
                                                                }
                                                                onChange={(
                                                                  newRating
                                                                ) =>
                                                                  handleInputChangePartListRef(
                                                                    {
                                                                      target: {
                                                                        name: "damage_rating",
                                                                        value:
                                                                          newRating,
                                                                      },
                                                                    },
                                                                    pIndex
                                                                  )
                                                                }
                                                              />
                                                            </div>
                                                          </th>

                                                          <th scope="col"></th>
                                                          <th>
                                                            <button
                                                              style={{
                                                                marginTop:
                                                                  "77px",
                                                              }}
                                                              type="button"
                                                              className="btn btn-primary"
                                                              onClick={() =>
                                                                handleAddPartDamage(
                                                                  pIndex
                                                                )
                                                              }
                                                            >
                                                              Add Damage List
                                                            </button>
                                                          </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {part?.parts_damage?.map(
                                                          (data, rowIndex) => {
                                                            return (
                                                              <>
                                                                <tr
                                                                  key={rowIndex}
                                                                >
                                                                  <td>
                                                                    <div className="col-lg-12 addPartField">
                                                                      <label className="partsLabel col-form-label ml-2">
                                                                        Damage
                                                                        Description
                                                                      </label>
                                                                      <input
                                                                        className="form-control input-search"
                                                                        type="text"
                                                                        name="damage_description"
                                                                        value={
                                                                          data.damage_description
                                                                        }
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          handleUpdatePartDamage(
                                                                            pIndex,
                                                                            rowIndex,
                                                                            "damage_description",
                                                                            e
                                                                              .target
                                                                              .value
                                                                          )
                                                                        }
                                                                        placeholder="Damge Description"
                                                                      />
                                                                    </div>
                                                                  </td>

                                                                  <td>
                                                                    <label className="partsLabel col-form-label ml-2">
                                                                      Type
                                                                      Damage
                                                                    </label>
                                                                    <Select
                                                                      className="my-Select"
                                                                      options={
                                                                        typeDamageList
                                                                      }
                                                                      value={typeDamageList.find(
                                                                        (
                                                                          option
                                                                        ) =>
                                                                          option.value ===
                                                                          data.type_damage_id
                                                                      )}
                                                                      onChange={(
                                                                        selectedOption
                                                                      ) =>
                                                                        handleUpdatePartDamage(
                                                                          pIndex,
                                                                          rowIndex,
                                                                          "type_damage_id",
                                                                          selectedOption?.value ||
                                                                            null
                                                                        )
                                                                      }
                                                                      isSearchable
                                                                      placeholder="Select Type Damage"
                                                                    />
                                                                  </td>
                                                                  <td>
                                                                    <label className="partsLabel col-form-label ml-2">
                                                                      Location
                                                                      Damage
                                                                    </label>
                                                                    <Select
                                                                      className="my-Select"
                                                                      options={
                                                                        locationDamageList
                                                                      }
                                                                      value={locationDamageList.find(
                                                                        (
                                                                          option
                                                                        ) =>
                                                                          option.value ===
                                                                          data.location_damage_id
                                                                      )}
                                                                      onChange={(
                                                                        selectedOption
                                                                      ) =>
                                                                        handleUpdatePartDamage(
                                                                          pIndex,
                                                                          rowIndex,
                                                                          "location_damage_id",
                                                                          selectedOption?.value ||
                                                                            null
                                                                        )
                                                                      }
                                                                      isSearchable
                                                                      placeholder="Select Location Damage"
                                                                    />
                                                                  </td>
                                                                  <th scope="col">
                                                                    <div className="col-lg-12 addPartField">
                                                                      <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                                                        Damage
                                                                        Rating
                                                                      </label>
                                                                      <ReactStars
                                                                        size={
                                                                          40
                                                                        }
                                                                        count={
                                                                          5
                                                                        }
                                                                        activeColor="#ffd700"
                                                                        a11y={
                                                                          true
                                                                        }
                                                                        isHalf={
                                                                          true
                                                                        }
                                                                        emptyIcon={
                                                                          <i className="bi bi-star" />
                                                                        }
                                                                        halfIcon={
                                                                          <i className="bi bi-star-half" />
                                                                        }
                                                                        filledIcon={
                                                                          <i className="bi bi-star-fill" />
                                                                        }
                                                                        value={
                                                                          data?.damage_rating
                                                                        }
                                                                        onChange={(
                                                                          newRating
                                                                        ) =>
                                                                          handleUpdatePartDamage(
                                                                            pIndex,
                                                                            rowIndex,
                                                                            "damage_rating",
                                                                            newRating
                                                                          )
                                                                        }
                                                                      />
                                                                    </div>
                                                                  </th>
                                                                  <td>
                                                                    <i
                                                                      class="bi bi-trash-fill"
                                                                      style={{
                                                                        cursor:
                                                                          "pointer",
                                                                      }}
                                                                      onClick={() =>
                                                                        handleDeletePartDamage(
                                                                          pIndex,
                                                                          rowIndex
                                                                        )
                                                                      }
                                                                    ></i>{" "}
                                                                  </td>
                                                                </tr>
                                                              </>
                                                            );
                                                          }
                                                        )}
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Card.Body>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            })}
                          </div>
                          <div className="back-footer">
                            <div className="row">
                              <div class="col-lg-6">
                                <button
                                  type="button"
                                  class="back-button btn btn-primary"
                                  onClick={handleBackButtonClick}
                                >
                                  <i class="bi bi-arrow-left"></i> {t("back")}
                                </button>
                              </div>

                              <div class="col-lg-6 saveasLBtns">
                                {btnLoader === true ? (
                                  <button
                                    type="button"
                                    disabled={true}
                                    className="btn btn-primary"
                                  >
                                    Loading...
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleSubmitAsPrintLabel}
                                    disabled={isSaveClicked}
                                  >
                                    {t("save_as_label")}
                                    <i className="bi bi-save ms-2" />
                                  </button>
                                )}

                                <div>
                                  {btnLoading === true ? (
                                    <button
                                      type="button"
                                      disabled={true}
                                      className="btn btn-primary"
                                    >
                                      Loading...
                                    </button>
                                  ) : (
                                    <button
                                      type="submit"
                                      class="btn btn-primary addPArtsSave"
                                    >
                                      {t("save")}
                                      <i className="bi bi-save ms-2"></i>
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                        {/* <div className="card-footer clearfix card-footer-part">
                          <div className="pagination pagination-sm m-0 float-right">
                            <Pagination
                              activePage={params.page}
                              itemsCountPerPage={parseInt(params.limit)}
                              totalItemsCount={parseInt(totalRecords)}
                              pageRangeDisplayed={5}
                              itemClass={`page-item`}
                              linkClass={`page-link`}
                              onChange={(e) => handleParams(e, "pagination")}
                            />
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </>
                )}

                {currentStep === 3 && (
                  <>
                    <div className="card-body vehicleparts-table mt-3">
                      <div className="vehicleparts-table-inner">
                        <div className="row">
                          <>
                            <Form onSubmit={handleSubmits} className="row">
                              <React.Fragment>
                                <div className="col-lg-6">
                                  <Card className="text-center">
                                    <Card.Header className="cardHeader">
                                      <div className="d-flex">
                                        <div className="AddPh6">
                                          <h6>Part Information</h6>
                                        </div>
                                      </div>
                                    </Card.Header>
                                    <Card.Body
                                      className="cardBody"
                                      style={{ padding: "0" }}
                                    >
                                      <div class="container text-justify">
                                        <div class="row pt-2 pb-2 border-bottom">
                                          <div class="col">Part Name</div>
                                          <div class="col">
                                            {currentPart?.part_name}
                                          </div>
                                        </div>
                                        <div class="row pt-2 pb-2 border-bottom">
                                          <div class="col">
                                            Part Description
                                          </div>
                                          <div class="col">
                                            {currentPart?.part_description}
                                          </div>
                                        </div>

                                        <div class="row pt-2 pb-2 border-bottom">
                                          <div class="col">Part Quantity</div>
                                          <div class="col">
                                            {currentPart?.quantity}
                                          </div>
                                        </div>
                                        <div class="row pt-2 pb-2 border-bottom">
                                          <div class="col">Part Price</div>
                                          <div class="col">
                                            € {currentPart?.part_price}
                                          </div>
                                        </div>
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </div>

                                <div className="col-lg-6">
                                  <Card className="text-center">
                                    <Card.Header className="cardHeader">
                                      <div className="d-flex">
                                        <div className="AddPh6">
                                          <h6> {t("upload_part_images")} </h6>
                                        </div>
                                      </div>
                                    </Card.Header>
                                    <Card.Body className="cardBody">
                                      <div className="row">
                                        <div className="col-lg-12 addPartField">
                                          <div
                                            className="col-lg-12 addPartField"
                                            style={{
                                              height: "221px",
                                              overflow: "hidden auto",
                                            }}
                                          >
                                            <label className="partsLabel col-form-label ml-2 Label-my form-label form-label">
                                              {t("upload_images")}{" "}
                                              {/* {currentPart.part_name} */}
                                            </label>
                                            <div className="input-div">
                                              <div className="avatar-upload">
                                                <div className="avatar-edit">
                                                  <div className="input-div">
                                                    <input
                                                      ref={fileInputRef}
                                                      className="inputFile"
                                                      type="file"
                                                      id="imageuploadAbout"
                                                      onChange={
                                                        handleAboutFileChange
                                                      }
                                                      name="file"
                                                      accept=".jpg,.jpeg,.png"
                                                      multiple
                                                    />
                                                    <div className="partImages partImages_mob mt-2">
                                                      {images?.map(
                                                        (file, index) => {
                                                          return (
                                                            <>
                                                              <div
                                                                key={index}
                                                                className="preview-item"
                                                              >
                                                                <div
                                                                  className="btn-Remove"
                                                                  style={{
                                                                    position:
                                                                      "absolute",
                                                                    width:
                                                                      "20px",
                                                                    height:
                                                                      "20px",
                                                                  }}
                                                                >
                                                                  <i
                                                                    className="editImageBtn bi bi-pen-fill"
                                                                    style={{
                                                                      position:
                                                                        "absolute",
                                                                      top: "5px",
                                                                      cursor:
                                                                        "pointer",
                                                                      color:
                                                                        "blue",
                                                                    }}
                                                                    onClick={() =>
                                                                      handleImageSelect(
                                                                        file
                                                                      )
                                                                    }
                                                                  ></i>
                                                                  <img
                                                                    onClick={() =>
                                                                      toggleEnlarge(
                                                                        index
                                                                      )
                                                                    }
                                                                    className={`image ${
                                                                      enlargedIndexes.includes(
                                                                        index
                                                                      )
                                                                        ? "enlarged"
                                                                        : ""
                                                                    }`}
                                                                    src={
                                                                      previewUrl[
                                                                        index
                                                                      ]
                                                                    }
                                                                    alt={`Preview ${index}`}
                                                                    style={{
                                                                      width:
                                                                        "100px",
                                                                      margin:
                                                                        "5px",
                                                                      height:
                                                                        "100px",
                                                                      border:
                                                                        "1px solid",
                                                                      borderRadius:
                                                                        "5px",
                                                                      maxWidth:
                                                                        "100px",
                                                                      maxHeight:
                                                                        "100px",
                                                                    }}
                                                                  />

                                                                  <i
                                                                    className="removeImageBtn bi bi-x"
                                                                    style={{
                                                                      position:
                                                                        "absolute",
                                                                      top: "5px",
                                                                      right:
                                                                        "5px",
                                                                      cursor:
                                                                        "pointer",
                                                                      color:
                                                                        "red",
                                                                    }}
                                                                    onClick={() =>
                                                                      handleRemoveFile(
                                                                        index
                                                                      )
                                                                    }
                                                                  ></i>
                                                                </div>
                                                              </div>
                                                            </>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                  <label htmlFor="imageuploadAbout"></label>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </div>
                              </React.Fragment>
                              <div className="addPartsBtns flex-row mt-3 justify-content-end">
                                {!isLastPart && (
                                  <Button
                                    className="me-2 mb-2 btn-primary"
                                    onClick={handleOpenSkip}
                                  >
                                    {t("skip")} & {t("next")}
                                  </Button>
                                )}

                                {!isLastPart && (
                                  <Button
                                    className="me-2 mb-2 btn-primary"
                                    type="submit"
                                  >
                                    {t("save")}& {t("next")}
                                  </Button>
                                )}

                                {!isLastPart && (
                                  <Button
                                    type="Button"
                                    className="btn btn-primary nxt-btn"
                                    onClick={handleImportModal}
                                  >
                                    Sort By Order{" "}
                                    <i class="bi bi-arrow-right"></i>
                                  </Button>
                                )}

                                {isLastPart && (
                                  <>
                                    {btnImageSave ? (
                                      <Button
                                        className="mb-2 btn-primary"
                                        disabled={true}
                                      >
                                        Loading...
                                      </Button>
                                    ) : (
                                      <Button
                                        className="mb-2 next-button"
                                        onClick={handleFinish}
                                        style={{ marginRight: "10px" }}
                                      >
                                        {t("save")}
                                        <i class="bi bi-save ms-2"></i>
                                      </Button>
                                    )}

                                    <Button
                                      type="Button"
                                      className="btn btn-primary nxt-btn"
                                      onClick={handleImportModal}
                                    >
                                      Sort By Order{" "}
                                      <i class="bi bi-arrow-right"></i>
                                    </Button>

                                    <Button
                                      className="mb-2 next-button"
                                      onClick={() =>
                                        navigate("/parts-inventory")
                                      }
                                    >
                                      {t("finish")}
                                      <i class="bi bi-check2"></i>
                                    </Button>
                                  </>
                                )}
                              </div>
                            </Form>
                          </>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {currentStep === 4 && (
                  <AssignedDamageImage
                    partImageDataList={partImageDataList}
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                  />
                )}

                {currentStep === 5 && (
                  <AddPartVideo
                    currentStep={currentStep}
                    currentPart={currentPart}
                    vehicleAddDetails={vehicleAddDetails}
                    setCurrentStep={setCurrentStep}
                    currentPartIndex={currentPartIndex}
                    setCurrentPartIndex={setCurrentPartIndex}
                    isLastPart={isLastPart}
                    showSkip={showSkip}
                    setShowSkip={setShowSkip}
                    setPartVideoDataList={setPartVideoDataList}
                    partVideoDataList={partVideoDataList}
                    partsData={partsData}
                  />
                )}

                {currentStep === 6 && (
                  <AssignedDamageVideo
                    partVideoDataList={partVideoDataList}
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                  />
                )}
              </>
            )}
          </div>
        </div>

        <Modal isOpen={modifyImportModal} toggle={handleImportCloseModal}>
          <ModalHeader toggle={handleImportCloseModal}>
            Sort By Order
          </ModalHeader>
          <ModalBody>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="fileList" direction="vertical">
                {(provided) => (
                  <div
                    className="file-list"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    {images?.map((file, index) => (
                      <Draggable
                        key={file.id.toString() ? file.id.toString() : file.id}
                        draggableId={
                          file.id.toString() ? file.id.toString() : file.id
                        }
                        index={index}
                      >
                        {(provided) => (
                          <div
                            className="file-item"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                              border: "1px solid #ddd",
                              padding: "10px",
                              borderRadius: "5px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={file.image_url ? file.image_url : file.url}
                              alt="Preview"
                              style={{
                                width: "50px",
                                height: "50px",
                                objectFit: "cover",
                                borderRadius: "5px",
                              }}
                            />
                            {/* <span>{file.file.name}</span> */}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleImportCloseModal}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSaveOrder}
            >
              {t("save_order")}
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modifyMasterPart} size="lg">
          <ModalHeader>
            {categoryMasterPart ? categoryMasterPart?.label : ""}
          </ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <FormGroup>
                    <div
                      className="vehicleparts-table-inner vehicleThBg tableScroll table-scroll-modal"
                      style={{
                        width: "530px",

                        marginLeft: "100px",
                      }}
                    >
                      <table className="rounded-table table table-striped table-bordered table-hover">
                        <thead>
                          <tr>
                            <th style={{ width: "10%" }}>Select</th>

                            <th style={{ width: "0%" }}>Part</th>
                          </tr>
                        </thead>
                        <tbody>
                          {categoryMasterPart?.part_list?.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={checkedItems.includes(item)}
                                  onChange={() => handleCheck(item)}
                                />
                              </td>
                              <td>{item?.namePartPT}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </FormGroup>
                </div>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-secondary"
              onClick={handleMasterPartClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-block btn-primary"
              style={{ width: "100px", marginTop: "10px" }}
              onClick={handleModalSubmit}
            >
              Submit
            </button>
          </ModalFooter>
        </Modal>

        {vehicleAddDetails?.vehicle_id && (
          <>
            {filteredData.length > 0 ? (
              <>
                <Modal isOpen={modifyMasterVehiclePart} size="lg">
                  <ModalHeader>
                    {categoryMasterPart ? categoryMasterPart?.label : ""}
                  </ModalHeader>

                  <ModalBody>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="col-md-12">
                          <FormGroup>
                            {categoryMasterPart?.part_list?.map(
                              (item, index) => (
                                <tr key={index}>
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={checkedItems.includes(item)}
                                      onChange={() => handleCheck(item)}
                                    />
                                  </td>
                                  <td>
                                    <div className="input-div SelectV">
                                      <div className="col-md-12">
                                        {item?.namePartPT}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )
                            )}
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                  </ModalBody>

                  <ModalFooter>
                    <button
                      type="button"
                      className="btn btn-block btn-secondary"
                      onClick={handlMasterVehicleClose}
                      style={{ width: "100px", marginTop: "10px" }}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-block btn-primary"
                      style={{ width: "100px", marginTop: "10px" }}
                      onClick={handleModalSubmit}
                    >
                      Submit
                    </button>
                  </ModalFooter>
                </Modal>
              </>
            ) : (
              <>
                <Modal isOpen={modifyMasterVehiclePart} size="lg">
                  <ModalHeader>Part List</ModalHeader>

                  <ModalBody>
                    <div className="row">
                      No part was found, as it already exists{" "}
                    </div>
                  </ModalBody>

                  <ModalFooter>
                    <button
                      type="button"
                      className="btn btn-block btn-secondary"
                      onClick={handlMasterVehicleClose}
                      style={{ width: "100px", marginTop: "10px" }}
                    >
                      Close
                    </button>
                  </ModalFooter>
                </Modal>
              </>
            )}
          </>
        )}

        <Modal isOpen={modifyMasterVehicleNotPart} size="lg">
          <ModalHeader>Part List</ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <div className="vehicleThBg table-responsive">
                    <table className="rounded-table table table-striped table-bordered table-hover">
                      <thead>
                        <tr>
                          <th style={{ width: "10%" }}></th>

                          <th style={{ width: "10%" }}>Part Name</th>

                          <th style={{ width: "10%" }}>Year</th>

                          <th style={{ width: "0%" }}>Vehicle Make</th>
                          <th style={{ width: "0%" }}>Vehicle Modal</th>
                          <th style={{ width: "0%" }}>Vehicle Variant</th>
                        </tr>
                      </thead>
                      <tbody>
                        {categoryMasterPart?.part_list?.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedItemsCheckBox.some(
                                  (selected) => selected.index === index
                                )}
                                onChange={() =>
                                  handleCheckboxChangeMaster(index, item)
                                }
                              />
                            </td>
                            <td>
                              <div className="input-div SelectV">
                                <div className="col-md-12">
                                  {item?.namePartPT}
                                </div>
                              </div>
                            </td>

                            <td>{vehicleAddDetails?.vehicle_year}</td>

                            <td>{vehicleAddDetails?.vehicle_make_name}</td>

                            <td>{vehicleAddDetails?.vehicle_model_name}</td>

                            <td>{vehicleAddDetails?.vehicle_variant_name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-secondary"
              onClick={handlMasterVehicleNotClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-block btn-primary"
              style={{ width: "100px", marginTop: "10px" }}
              onClick={handleSubmitAdd}
            >
              Submit
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={showModalAbout}>
          <ModalHeader>"Part Image"</ModalHeader>
          <ModalBody>
            <div className="preview-container">
              {images.map(({ file, id }, index) => (
                <div key={id} className="preview-item">
                  <img
                    src={previewUrl[index]}
                    alt={`Preview ${index}`}
                    style={{ width: "100px", margin: "5px" }}
                  />
                  <div className="btn-Remove">
                    <i
                      className="bi bi-x"
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => handleRemoveFile(index)}
                    ></i>
                  </div>
                </div>
              ))}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" onClick={handleCloseAbout}>
              Close
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={showSkip}>
          <ModalHeader></ModalHeader>
          <ModalBody>
            <div className="row">Are you sure you want to skip this part?</div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={handleCloseSkip}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-x-lg"></i>
            </button>

            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={handleSkip}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Yes <i class="bi bi-x-lg"></i>
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={vehiclePartDetailsModal}>
          <ModalHeader>{`Part Details`}</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="vehicleThBg table-responsive">
                  <table className="rounded-table table table-striped table-bordered table-hover">
                    <thead>
                      <tr>
                        <th style={{ width: "10%" }}>Part Name</th>

                        <th style={{ width: "10%" }}>Part quantity</th>
                      </tr>
                    </thead>

                    <tbody>
                      {vehicleExceedData?.parts?.length > 0 ? (
                        vehicleExceedData?.parts?.map((item, index) => (
                          <tr key={index}>
                            <td>{item.part_name}</td>
                            <td>{item.quantity}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="100%">No results found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={onvehiclePartDetailsClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-x-lg"></i>
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modifyExpiry}>
          <ModalHeader>{`Vehicle Expiry Date`}</ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <FormGroup>
                    <Label className="col-form-label">{"Expiry Date"}</Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <input
                          type="date"
                          id="date"
                          className="form-control"
                          value={vehicleData?.date}
                          onChange={(e) => {
                            setVehicleData({
                              ...vehicleData,
                              date: e.target.value,
                            });
                          }}
                          placeholder="Expiry Date"
                        />
                      </div>
                    </div>
                  </FormGroup>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={ExpiryModalNextclose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Submit <i class="bi bi-check2"></i>
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={partEtaiModify}>
          <ModalHeader>{t("reference_code")}</ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <FormGroup>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>{t("reference_code")}</th>
                          <th>{t("product_label")}</th>
                          <th>{t("action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {partETAIRefDetails?.map((item, index) => (
                          <tr key={index}>
                            <td>{item?.reference_code}</td>
                            <td>{item?.product_label}</td>
                            <td>
                              <input
                                type="radio"
                                className="form-check-input"
                                name="radio-select"
                                checked={
                                  selectedReferenceCode === item?.reference_code
                                }
                                onChange={() =>
                                  handleRadioChange(item?.reference_code)
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </FormGroup>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={handlePartEtaiClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              {t("submit")} <i class="bi bi-check2"></i>
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={partEtaiPriceModify}>
          <ModalHeader>{t("price")}</ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <FormGroup>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>{t("price")}</th>
                          <th>{t("product_label")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {partETAIPriceDetail &&
                        partETAIPriceDetail.length > 0 ? (
                          partETAIPriceDetail.map((item, index) => (
                            <tr key={index}>
                              <td>€ {item?.source}</td>
                              <td>{item?.label}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="2" className="text-center">
                              {t("no_record_found")}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </FormGroup>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={handlePartEtaiPriceClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              {t("close")} <i class="bi bi-check2"></i>
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modifyDelteRef}>
          <ModalHeader>{`Delete Reference `}</ModalHeader>
          <ModalBody>
            <p
              className={`mb-0`}
            >{`Are you sure you want to delete this Reference code?`}</p>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-primary"
              onClick={handleRefClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-x-lg"></i>
            </button>

            <button
              type="button"
              className="btn btn-block bg-red"
              style={{ width: "100px" }}
              onClick={hanldeEditdeleteRef}
            >
              Delete <i class="bi bi-trash3"></i>
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modifyDelteDamage}>
          <ModalHeader>{`Delete Part Damage`}</ModalHeader>
          <ModalBody>
            <p
              className={`mb-0`}
            >{`Are you sure you want to delete this part damage?`}</p>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-primary"
              onClick={handleDamageClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-x-lg"></i>
            </button>

            <button
              type="button"
              className="btn btn-block bg-red"
              style={{ width: "100px" }}
              onClick={hanldeEditdeleteDamage}
            >
              Delete <i class="bi bi-trash3"></i>
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modalStateHistory} size="lg">
          <ModalHeader>{`Part Details`}</ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <div
                    className="mb-3"
                    style={{ overflowX: "auto", height: "200px" }}
                  >
                    <FormGroup>
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Part Name</th>
                            <th>Make</th>
                            <th>Modal</th>
                            <th>Variant</th>
                            <th>Year</th>
                            <th>quantity</th>
                            <th>action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {catalogModalStateDataList?.map((item, index) => (
                            <tr key={index}>
                              <td>{item?.part_name}</td>
                              <td>{item?.vehicle_make_name}</td>
                              <td>{item?.vehicle_model_name}</td>
                              <td>{item?.vehicle_variant_name}</td>
                              <td>{item?.vehicle_year}</td>
                              <td>{item?.quantity}</td>
                              <td>
                                <div
                                  style={{ cursor: "pointer" }}
                                  className="edit-btn"
                                  onClick={() =>
                                    window.open(
                                      `/part-details/${item.uuid}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  <i class="bi bi-eye-fill"></i>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={handleClosePartHistory}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-check2"></i>
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modalSponsorPrice} size="lg">
          <ModalHeader>{`Statiscal Price`}</ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <FormGroup>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Price1 Avg</th>
                          <th>Price1 Max</th>
                          <th>Price1 Min</th>
                          <th>Price2 Min</th>
                          <th>Price2 Price Avg</th>
                          <th>Price2 Price Max</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sponsorPricDataList &&
                        sponsorPricDataList.length > 0 ? (
                          sponsorPricDataList.map((item, index) => (
                            <tr key={index}>
                              <td>€ {item?.price1_avg}</td>
                              <td>€ {item?.price1_max}</td>
                              <td>€ {item?.price1_min}</td>
                              <td>€ {item?.price2_min}</td>
                              <td>€ {item?.price2_price_avg}</td>
                              <td>€ {item?.price2_price_max}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" style={{ textAlign: "center" }}>
                              No record found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </FormGroup>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={handleCloseSponsorPrice}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-check2"></i>
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modalEstimatePrice} size="lg">
          <ModalHeader>{`Estimate Price`}</ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <FormGroup>
                    <div
                      className="row"
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <h6
                        style={{
                          marginBottom: "-1px",
                          marginLeft: "-8px",
                        }}
                      >
                        Vehicle Model
                      </h6>
                      <div className="col-md-6">
                        Avg :
                        {estimatePricDataList?.stats?.stats_for_model
                          ?.price_avg || 0}
                      </div>

                      <div className="col-md-6">
                        Max :
                        {estimatePricDataList?.stats?.stats_for_model
                          ?.price_max || 0}
                      </div>

                      <div className="col-md-6">
                        Min :
                        {estimatePricDataList?.stats?.stats_for_model
                          ?.price_min || 0}
                      </div>

                      <div className="col-md-6">
                        Quantity. :
                        {estimatePricDataList?.stats?.stats_for_model
                          ?.quantity || 0}
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <h6
                        style={{
                          marginBottom: "-1px",
                          marginLeft: "-8px",
                        }}
                      >
                        Vehicle Variants
                      </h6>

                      <div className="col-md-6">
                        Avg :
                        {estimatePricDataList?.stats?.stats_for_variant
                          ?.price_avg || 0}
                      </div>

                      <div className="col-md-6">
                        Max :
                        {estimatePricDataList?.stats?.stats_for_variant
                          ?.price_max || 0}
                      </div>

                      <div className="col-md-6">
                        Min :
                        {estimatePricDataList?.stats?.stats_for_variant
                          ?.price_min || 0}
                      </div>

                      <div className="col-md-6">
                        Quantity. :
                        {estimatePricDataList?.stats?.stats_for_variant
                          ?.quantity || 0}
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <h6
                        style={{
                          marginBottom: "-1px",
                          marginLeft: "-8px",
                        }}
                      >
                        Part/Model
                      </h6>
                      <div className="col-md-6">
                        Avg :
                        {estimatePricDataList?.stats?.stats_for_part_and_model
                          ?.price_avg || 0}
                      </div>

                      <div className="col-md-6">
                        Max :
                        {estimatePricDataList?.stats?.stats_for_part_and_model
                          ?.price_max || 0}
                      </div>

                      <div className="col-md-6">
                        Min :
                        {estimatePricDataList?.stats?.stats_for_part_and_model
                          ?.price_min || 0}
                      </div>

                      <div className="col-md-6">
                        Quantity. :
                        {estimatePricDataList?.stats?.stats_for_part_and_model
                          ?.quantity || 0}
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <h6
                        style={{
                          marginBottom: "-1px",
                          marginLeft: "-8px",
                        }}
                      >
                        {" "}
                        Engine Code
                      </h6>
                      <div className="col-md-6">
                        Avg :
                        {estimatePricDataList?.stats?.stats_for_engine_code
                          ?.price_avg || 0}
                      </div>

                      <div className="col-md-6">
                        Max :
                        {estimatePricDataList?.stats?.stats_for_engine_code
                          ?.price_max || 0}
                      </div>

                      <div className="col-md-6">
                        Min :
                        {estimatePricDataList?.stats?.stats_for_engine_code
                          ?.price_min || 0}
                      </div>

                      <div className="col-md-6">
                        Quantity. :
                        {estimatePricDataList?.stats?.stats_for_engine_code
                          ?.quantity || 0}
                      </div>
                    </div>
                  </FormGroup>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={handleCloseEstimatePrice}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-check2"></i>
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={modalRefCodeCondition} size="lg">
          <ModalHeader>{`Part Details`}</ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <FormGroup>
                    <table className="table table-bordered table-striped align-middle">
                      <thead className="table-dark">
                        <tr>
                          <th>Ref Code</th>
                          <th>Price1 Avg</th>
                          <th>Price1 Max</th>
                          <th>Price1 Min</th>
                          <th>Price2 Min</th>
                          <th>Price2 Avg</th>
                          <th>Price2 Max</th>
                          {/* <th>View</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {refCodeConditionDataList?.partsList?.map(
                          (part, index) => (
                            <tr key={index}>
                              <td>
                                <strong>{part.ref_code}</strong>
                              </td>
                              <td>€ {part?.prices?.price1_avg || 0}</td>
                              <td>€ {part?.prices?.price1_max || 0}</td>
                              <td>€ {part?.prices?.price1_min || 0}</td>
                              <td>€ {part?.prices?.price2_min || 0}</td>
                              <td>€ {part?.prices?.price2_price_avg || 0}</td>
                              <td>€ {part?.prices?.price2_price_max || 0}</td>
                              {/* <td>
                                    <div
                                      style={{ cursor: "pointer" }}
                                      className="text-primary"
                                      // onClick={() =>
                                      //   window.open(
                                      //     `/part-details/${part.uuid}`,
                                      //     "_blank"
                                      //   )
                                      // }
                                    >
                                      <i className="bi bi-eye-fill fs-5"></i>
                                    </div>
                                  </td> */}
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </FormGroup>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={handleCloseRefCodeCondition}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-check2"></i>
            </button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={isModalOpen} toggle={closeModal} size="lg">
          <Form method="post">
            <ModalHeader toggle={closeModal}>Image Edit</ModalHeader>
            {selectedImage && (
              <ModalBody>
                <div className="modal-body" style={{ marginBottom: "100px" }}>
                  <div
                    style={{
                      width: "100%",
                      height: "400px",
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        display: "inline-block",
                        width: "100%",
                        height: "394px",
                      }}
                    >
                      {/* Cropping Section */}
                      <ReactCrop
                        crop={crop}
                        onImageLoaded={onImageLoad}
                        onChange={(newCrop) => setCrop(newCrop)}
                        onComplete={(c) => setCompletedCrop(c)}
                        disabled={!isCropping}
                      >
                        <img
                          src={selectedImage.url}
                          ref={imgRef}
                          style={{
                            transform: `rotate(${rotation}deg)`,
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "cover",
                            height: "394px",
                            display: selectedImage ? "block" : "none",
                          }}
                          alt="Crop preview"
                          onClick={onImageClick}
                        />
                      </ReactCrop>
                      {isDrawing && !isCropping && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <ReactSketchCanvas
                            ref={canvasRef}
                            style={{
                              width: "100%",
                              height: "100%",
                              position: "absolute",
                              top: 0,
                              left: 0,
                            }}
                            canvasColor="transparent"
                            allowOnlyPointerType="all"
                          />
                        </div>
                      )}
                    </div>

                    <div className="row" style={{ marginTop: "40px" }}>
                      <div className="col-md-2">
                        <i
                          class="bi bi-crop"
                          style={{
                            fontSize: "22px",
                            cursor: "pointer",
                            color: isCropping ? "blue" : "black",
                          }}
                          onClick={handleCrop}
                        ></i>
                      </div>

                      <div className="col-md-2">
                        <i
                          className="bi bi-brush"
                          style={{
                            fontSize: "22px",
                            cursor: "pointer",
                            color: isDrawing ? "blue" : "black",
                          }}
                          onClick={() => saveDrawing(selectedImage.id)}
                        ></i>
                      </div>

                      <div className="col-md-2">
                        <i
                          class="bi bi-arrow-counterclockwise"
                          style={{ fontSize: "22px", cursor: "pointer" }}
                          onClick={() => handleRotate(selectedImage.id)}
                        ></i>
                      </div>

                      <div className="col-md-2">
                        <i
                          class="bi bi-image"
                          style={{ fontSize: "22px", cursor: "pointer" }}
                          onClick={() =>
                            handleRemoveBgImageeForm(selectedImage.id)
                          }
                          disabled={isLoading}
                        ></i>
                      </div>

                      <div className="col-md-2">
                        <i
                          class="bi bi-image"
                          style={{ fontSize: "22px", cursor: "pointer" }}
                          onClick={(e) =>
                            handleAddBgImageForm(selectedImage.id)
                          }
                          disabled={isLoadings}
                        ></i>
                      </div>

                      <div className="col-md-2">
                        <i
                          class="bi bi-paint-bucket"
                          style={{ fontSize: "22px", cursor: "pointer" }}
                          onClick={() =>
                            handleAddBgColoreForm(selectedImage.id)
                          }
                        ></i>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-2">
                        <span>Crop</span>
                      </div>

                      <div className="col-md-2">
                        <span>Brush</span>
                      </div>

                      <div className="col-md-2">
                        <span>Rotate</span>
                      </div>

                      <div className="col-md-2">
                        <span>
                          {isLoading ? "Processing..." : "Remove Back"}
                        </span>
                      </div>

                      <div className="col-md-2">
                        <span>
                          {" "}
                          {isLoadings ? "Processing..." : "Add Back"}
                        </span>
                      </div>

                      <div className="col-md-2">
                        <span>
                          {isLoadingColor ? "Processing..." : "Add Color"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalBody>
            )}

            <ModalFooter>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={getCroppedImage}
              >
                Apply Changes
              </button>
            </ModalFooter>
          </Form>
        </Modal>

        <Modal isOpen={modalRefCodeEstimate} size="lg">
          <ModalHeader>{`Estimate Price`}</ModalHeader>

          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <FormGroup>
                    <div
                      className="row"
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <h6
                        style={{
                          marginBottom: "-1px",
                          marginLeft: "-8px",
                        }}
                      >
                        Vehicle Model
                      </h6>
                      <div className="col-md-6">
                        Avg :
                        {refCodeEstimateDataList?.stats?.stats_for_model
                          ?.price_avg || 0}
                      </div>

                      <div className="col-md-6">
                        Max :
                        {refCodeEstimateDataList?.stats?.stats_for_model
                          ?.price_max || 0}
                      </div>

                      <div className="col-md-6">
                        Min :
                        {refCodeEstimateDataList?.stats?.stats_for_model
                          ?.price_min || 0}
                      </div>

                      <div className="col-md-6">
                        Quantity. :
                        {refCodeEstimateDataList?.stats?.stats_for_model
                          ?.quantity || 0}
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <h6
                        style={{
                          marginBottom: "-1px",
                          marginLeft: "-8px",
                        }}
                      >
                        Vehicle Variants
                      </h6>

                      <div className="col-md-6">
                        Avg :
                        {refCodeEstimateDataList?.stats?.stats_for_variant
                          ?.price_avg || 0}
                      </div>

                      <div className="col-md-6">
                        Max :
                        {refCodeEstimateDataList?.stats?.stats_for_variant
                          ?.price_max || 0}
                      </div>

                      <div className="col-md-6">
                        Min :
                        {refCodeEstimateDataList?.stats?.stats_for_variant
                          ?.price_min || 0}
                      </div>

                      <div className="col-md-6">
                        Quantity. :
                        {refCodeEstimateDataList?.stats?.stats_for_variant
                          ?.quantity || 0}
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <h6
                        style={{
                          marginBottom: "-1px",
                          marginLeft: "-8px",
                        }}
                      >
                        Part/Model
                      </h6>
                      <div className="col-md-6">
                        Avg :
                        {refCodeEstimateDataList?.stats
                          ?.stats_for_part_and_model?.price_avg || 0}
                      </div>

                      <div className="col-md-6">
                        Max :
                        {refCodeEstimateDataList?.stats
                          ?.stats_for_part_and_model?.price_max || 0}
                      </div>

                      <div className="col-md-6">
                        Min :
                        {refCodeEstimateDataList?.stats
                          ?.stats_for_part_and_model?.price_min || 0}
                      </div>

                      <div className="col-md-6">
                        Quantity. :
                        {refCodeEstimateDataList?.stats
                          ?.stats_for_part_and_model?.quantity || 0}
                      </div>
                    </div>

                    <div
                      className="row"
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      <h6
                        style={{
                          marginBottom: "-1px",
                          marginLeft: "-8px",
                        }}
                      >
                        {" "}
                        Engine Code
                      </h6>
                      <div className="col-md-6">
                        Avg :
                        {refCodeEstimateDataList?.stats?.stats_for_engine_code
                          ?.price_avg || 0}
                      </div>

                      <div className="col-md-6">
                        Max :
                        {refCodeEstimateDataList?.stats?.stats_for_engine_code
                          ?.price_max || 0}
                      </div>

                      <div className="col-md-6">
                        Min :
                        {refCodeEstimateDataList?.stats?.stats_for_engine_code
                          ?.price_min || 0}
                      </div>

                      <div className="col-md-6">
                        Quantity. :
                        {refCodeEstimateDataList?.stats?.stats_for_engine_code
                          ?.quantity || 0}
                      </div>
                    </div>
                  </FormGroup>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={handleCloseRefCodeEstimate}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-check2"></i>
            </button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default VehiclePartsAdd;
