import React, { useRef, useState, useEffect } from "react";
import {
  createVehicleVideo,
  deleteVehiclevideo,
  getVehicleVideoSort,
} from "../../services/VehicleService";
import { getSettingsDetails } from "../../services/settingsServices";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { useTranslation } from "react-i18next";
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";

const SortableItem = SortableElement(({ video }) => {
  const videoSrc =
    video instanceof File ? URL.createObjectURL(video) : video.url;
  return (
    <div className="m-2 p-2 bg-light border rounded d-flex align-items-center">
      <span className="me-2">☰</span>
      <video width="150" controls>
        <source src={videoSrc} type={video.type || "video/mp4"} />
        Your browser does not support the video tag.
      </video>
    </div>
  );
});

const SortableList = SortableContainer(({ videos }) => (
  <div>
    {videos.map((video, index) => (
      <SortableItem key={`item-${index}`} index={index} video={video} />
    ))}
  </div>
));

const VehicleVideo = ({ setActiveTab, vehicleProcessList, activeTab }) => {
  const fileInputRef = useRef(null);
  const [videos, setVideos] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDelete, setIsModalDelete] = useState(false);
  const [videoDataId, setvideoDataId] = useState("");
  const [deleteIndex, setDeleteIndex] = useState("");

  const [settingDetails, setSettingDetails] = useState("");
  const [btnLoader, setbtnLoading] = useState(false);

  const vehicleId = localStorage.getItem("vehicleId");
  const { t } = useTranslation();

  const handleDeleteClose = () => {
    setIsModalDelete(false);
  };

  useEffect(() => {
    if (
      Array.isArray(vehicleProcessList?.vehicle_video) &&
      activeTab === "vehicle_video"
    ) {
      const initialFiles = vehicleProcessList.vehicle_video.map((document) => ({
        url: document.vehicle_video,
        id: document.id,
        sequence: document.sort_order,
      }));
      setVideos(initialFiles);
    }
  }, [vehicleProcessList, activeTab]);

  useEffect(() => {
    getSettingsDetails()
      .then((response) => {
        setSettingDetails(response.data.data);
      })
      .catch((err) => {});
  }, []);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files).map((file) => ({
      file,
      id: 0,
      sequence: videos.length + 1,
    }));
    setVideos((prevVideos) => [...prevVideos, ...files]);
  };

  const handleRemoveVideo = (index, id) => {
    if (id) {
      setvideoDataId(id);
      setIsModalDelete(true);
      setDeleteIndex(index);
    } else {
      const newVideos = videos.filter((_, i) => i !== index);
      setVideos(newVideos);

      if (newVideos.length === 0 && fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const handleEditRemoveVideo = async () => {
    let data = { id: videoDataId };

    deleteVehiclevideo(data)
      .then((response) => {
        toast.success(response.data.message);

        setVideos((prevVideos) =>
          prevVideos.filter((_, i) => i !== deleteIndex)
        );

        setIsModalDelete(false);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setVideos((prevVideos) =>
      arrayMoveImmutable(prevVideos, oldIndex, newIndex)
    );
  };

  const handleSaveOrder = async () => {
    const sortList = videos.map((video, index) => ({
      id: video.id || 0,
      sort_order: index + 1,
    }));

    try {
      await getVehicleVideoSort({ sort_list: sortList });
      toast.success("Sort order updated successfully!");
      setIsModalOpen(false);
    } catch (err) {
      toast.error(Utils.getErrorMessage(err));
    }
  };

  const handleVehicleVideoForm = async (e) => {
    e.preventDefault();

    const newFiles = videos.filter((video) => video.file);
    const part_video_size = settingDetails?.part_video_size * 1024 * 1024;

    if (newFiles.length > settingDetails?.part_video_limit) {
      toast.error(
        `You can only upload up to ${settingDetails?.part_video_limit} videos.`
      );
      return;
    }

    const oversizedFiles = newFiles.filter(
      (video) => video.file.size > part_video_size
    );
    if (oversizedFiles.length > 0) {
      toast.error(
        `Each video must be under ${settingDetails?.part_video_limit}MB.`
      );
      return;
    }

    if (newFiles.length === 0) {
      setActiveTab("vehicle_extra_info");
      return;
    }

    setbtnLoading(true);

    const formData = new FormData();
    newFiles.forEach((video) => formData.append("files[]", video.file));
    formData.append("vehicle_id", vehicleId);

    try {
      const response = await createVehicleVideo(formData);
      toast.success(response.data.message);
      setVideos([]);
      setActiveTab("vehicle_extra_info");
    } catch (err) {
      toast.error(Utils.getErrorMessage(err));
      setbtnLoading(false);
    }
  };

  return (
    <div>
      <div className="card card-primary">
        <Form method="post" onSubmit={handleVehicleVideoForm}>
          <div className="card p-3">
            <h5>Upload Multiple Videos</h5>

            <input
              type="file"
              accept="video/*"
              multiple
              onChange={handleFileChange}
              className="form-control mt-2"
              ref={fileInputRef}
              style={{ width: "213px" }}
            />

            {videos?.length > 0 && (
              <div className="mt-3">
                <div className="d-flex flex-wrap">
                  {videos.map((video, index) => (
                    <div key={index} className="m-2 position-relative">
                      <button
                        className="btn btn-danger btn-sm position-absolute"
                        style={{ top: "5px", right: "5px", zIndex: 10 }}
                        onClick={() => handleRemoveVideo(index, video.id)}
                        type="button"
                      >
                        ✖
                      </button>
                      <video width="200" controls>
                        <source
                          src={video.url || URL.createObjectURL(video.file)}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="back-footer">
            <div className="buttonsB">
              <Button
                type="button"
                className="back-btn"
                onClick={() => setActiveTab("vehicle_images")}
              >
                <i className="bi bi-arrow-left"></i> {t("back")}
              </Button>
              <div className="d-flex justify-content-end gap-2 mt-3">
                <Button
                  color="primary"
                  onClick={() => setIsModalOpen(true)}
                  type="button"
                >
                  Sort Order
                </Button>

                {btnLoader === true ? (
                  <button type="submit" className="btn  btn-primary">
                    Loading...
                  </button>
                ) : (
                  <button type="submit" className="btn  btn-primary">
                    {t("finish")}

                    <i class="bi bi-arrow-right"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </Form>
      </div>
      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(false)}>
        <ModalHeader toggle={() => setIsModalOpen(false)}>
          Sort Videos
        </ModalHeader>
        <ModalBody>
          <SortableList videos={videos} onSortEnd={onSortEnd} axis="y" />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSaveOrder}>
            Save Order
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={isModalDelete} toggle={handleDeleteClose}>
        <ModalHeader toggle={handleDeleteClose}>Delete Video</ModalHeader>
        <ModalBody>
          <div>{"Are you sure you want to delete this video?"}</div>
        </ModalBody>

        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            onClick={handleDeleteClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            {t("close")}
          </button>

          <button
            type="button"
            className="btn btn-block bg-red"
            style={{ width: "100px" }}
            onClick={handleEditRemoveVideo}
          >
            {t("delete")}
          </button>
        </ModalFooter>
      </Modal>{" "}
    </div>
  );
};

export default VehicleVideo;
