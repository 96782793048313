import configDb from "../config";

class Utils {
  static getErrorMessage(err) {
    let error = "Oops...something went wrong.";
    if (err.response && err.response.data) {
      error = err.response.data.message;
    } else if (err.message) {
      error = err.message;
    }
    return error;
  }

  static setDetail(data) {
    data = JSON.stringify(data);
    localStorage.setItem(configDb.data.login_data, data);
  }

  static loginDetail() {
    const data = localStorage.getItem(configDb.data.login_data);
    if (data) {
      return JSON.parse(data);
    } else {
      return {};
    }
  }

  static getRecordForCurrentPath(location) {
    const data = localStorage.getItem(configDb?.data?.login_data);
    const RoleData = JSON.parse(data);

    const record = RoleData?.menus_data?.filter(
      (x) => x.url === location.pathname
    );

    return record;
  }

  static focusError() {
    setTimeout(function () {
      let classList = document.getElementsByClassName("input-error");
      if (classList.length > 0) {
        let scrollDiv =
          classList[0].getBoundingClientRect().top + window.scrollY - 200;
        window.scrollTo({ top: scrollDiv, behavior: "smooth" });
      }
    }, 0);
  }
}

export default Utils;
