import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";

import {
  getFuelList,
  actionAddFuel,
  actionUpdateFuel,
  actionDelFuel,
  importFuelType,
  exportFuelType,
} from "../../services/FuelServices";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";

const FuelType = () => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);
  const location = useLocation();
  const RolePermission = Utils.getRecordForCurrentPath(location);
  const LangData = localStorage.getItem("lng");
  const { t } = useTranslation();

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [modifyModal, setModifyModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [fuelTypeList, setFuelTypeList] = useState([]);
  const [fuelType, setFuelType] = useState({
    uuid: "",
    description: "",
    descriptionPT: "",
    descriptionES: "",
    descriptionFR: "",
    descriptionIT: "",
    descriptionDE: "",
    descriptionEN: "",
  });
  const [uuid, setFuelId] = useState("");
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  let errorsObj = { fuletype: "" };
  const [errors, setErrors] = useState(errorsObj);

  let errorsObjImport = {
    file: "",
  };
  const [errorsImport, setErrorsImport] = useState(errorsObjImport);
  const [modifyImportModal, setModifyImportModal] = useState(false);
  const [file, setFile] = useState(null);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  const onCloseViewFuelType = () => {
    setViewModal(false);
  };

  const onViewFuelType = (e, item) => {
    setViewModal(true);
    setFuelType({
      description: item.description,
      descriptionPT: item.descriptionPT,
      descriptionES: item.descriptionES,
      descriptionFR: item.descriptionFR,
      descriptionIT: item.descriptionIT,
      descriptionDE: item.descriptionDE,
      descriptionEN: item.descriptionEN,
    });
  };

  const handleImportModal = () => {
    setModifyImportModal(true);
  };

  const handleImportCloseModal = () => {
    setModifyImportModal(false);
    setErrorsImport("");
    setFile(null);
  };

  const handleAddModal = () => {
    setModifyModal(true);
    ClereForm();
  };

  const handleDeleteModal = (e, uuid) => {
    setFuelId(uuid.uuid);

    setDeleteModal(true);
  };
  const handleCloseModal = () => {
    setModifyModal(false);
  };
  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const onEditFuelType = (e, item) => {
    setModifyModal(true);
    setFuelType({
      description: item.description,
      descriptionPT: item.descriptionPT,
      descriptionES: item.descriptionES,
      descriptionFR: item.descriptionFR,
      descriptionIT: item.descriptionIT,
      descriptionDE: item.descriptionDE,
      descriptionEN: item.descriptionEN,
    });
    setFuelId(item?.uuid);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errorObj = { ...errorsObj };
    let error = false;
    if (!fuelType.description) {
      errorObj.description = "Fule description is required";
      error = true;
    }

    if (!fuelType.descriptionPT) {
      errorObj.descriptionPT = `Description PT is required.`;
      error = true;
    }
    if (!fuelType.descriptionES) {
      errorObj.descriptionES = `Description ES is required.`;
      error = true;
    }
    if (!fuelType.descriptionFR) {
      errorObj.descriptionFR = `Description FR is required.`;
      error = true;
    }
    if (!fuelType.descriptionIT) {
      errorObj.descriptionIT = `Description IT is required.`;
      error = true;
    }
    if (!fuelType.descriptionDE) {
      errorObj.descriptionDE = `Description DE is required.`;
      error = true;
    }
    if (!fuelType.descriptionEN) {
      errorObj.descriptionEN = `Description EN is required.`;
      error = true;
    }

    setErrors(errorObj);
    Utils.focusError();

    if (error) return;

    let obj = {
      description: fuelType.description,
      descriptionPT: fuelType.descriptionPT,
      descriptionES: fuelType.descriptionES,
      descriptionFR: fuelType.descriptionFR,
      descriptionIT: fuelType.descriptionIT,
      descriptionDE: fuelType.descriptionDE,
      descriptionEN: fuelType.descriptionEN,
    };

    let Updateobj = {
      description: fuelType.description,
      descriptionPT: fuelType.descriptionPT,
      descriptionES: fuelType.descriptionES,
      descriptionFR: fuelType.descriptionFR,
      descriptionIT: fuelType.descriptionIT,
      descriptionDE: fuelType.descriptionDE,
      descriptionEN: fuelType.descriptionEN,
      uuid: uuid,
    };
    setIsLoading(true);

    if (uuid) {
      actionUpdateFuel(Updateobj)
        .then((response) => {
          toast.success(response.data.message);
          ClereForm();
          setModifyModal(false);
          let data = { ...params };
          LoadList(data);
        })
        .catch((error) => {
          toast.error(Utils.getErrorMessage(error));
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      actionAddFuel(obj)
        .then((response) => {
          toast.success(response.data.message);
          ClereForm();
          setModifyModal(false);
          let data = { ...params };
          LoadList(data);
        })
        .catch((error) => {
          toast.error(Utils.getErrorMessage(error));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleDeleteSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    let obj = {
      uuid: uuid,
    };
    actionDelFuel(obj)
      .then((response) => {
        toast.success(response.data.message);
        setDeleteModal(false);
        let data = { ...params };

        LoadList(data);
      })
      .catch((error) => {
        toast.error(Utils.getErrorMessage(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  useEffect(
    function () {
      let data = { ...params };

      LoadList(data);
    },
    [params]
  );
  const LoadList = (data) => {
    // setIsLoading(true);
    getFuelList(data)
      .then((response) => {
        setFuelTypeList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
    // .finally(() => {
    //   setIsLoading(false);
    // });
  };
  function ClereForm() {
    setFuelType("");
    setFuelId("");
  }

  const handleImportGroup = (e) => {
    e.preventDefault();

    let error = false;
    let errorObjImport = { ...errorsObjImport };
    if (!file) {
      errorObjImport.file = `File is required.`;
      error = true;
    }

    setErrorsImport(errorObjImport);
    if (error) return;

    setIsModifyButtonLoad(true);
    const formData = new FormData();
    formData.append("files", file);

    importFuelType(formData)
      .then((response) => {
        toast.success(response.data.message);
        let data = { ...params };
        LoadList(data);
        setModifyImportModal(false);
        setIsModifyButtonLoad(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
        setIsModifyButtonLoad(false);
      });
  };

  const fetchExportFuelType = () => {
    let data = { ...params };
    exportFuelType(data)
      .then((response) => {
        const worksheet = XLSX.utils.json_to_sheet(response.data.data);
        const csv = XLSX.utils.sheet_to_csv(worksheet);

        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "VehicleType.csv");
        link.style.visibility = "hidden";

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleExport = () => {
    let data = { ...params };
    fetchExportFuelType(data);
  };

  return (
    <div>
      {isLoading ? (
        <div className="text-center pageLoader">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">{/* <h1>Fuel</h1> */}</div>

                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"></li>
                  </ol>
                </div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>
          <section className="content">
            <div className="container-fluid container-custom-height-new">
              <div className="row">
                <div className="col-md-12">
                  <div className="card fuelCard">
                    <div className="card-header">
                      <div
                        className="card-title m-0 float-left"
                        style={{ width: "50%" }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          value={params.search}
                          onChange={(e) => handleParams(e, "search")}
                          placeholder="Search"
                        ></input>
                      </div>

                      <div className="card-title m-0 float-right d-flex">
                        {RolePermission?.findIndex(
                          (e) => e.permission_name === "import_fuel"
                        ) > -1 && (
                          <button
                            type="button"
                            className="btn  btn-icon-text mr-2"
                            onClick={handleImportModal}
                          >
                            <i className="bi bi-file-earmark-excel-fill"></i>
                            <span className="ms-2">{t("import")}</span>
                          </button>
                        )}
                        {RolePermission?.findIndex(
                          (e) => e.permission_name === "export_fuel"
                        ) > -1 && (
                          <button
                            type="button"
                            className="btn  btn-icon-text mr-2"
                            onClick={handleExport}
                          >
                            <i className="bi bi-file-earmark-excel-fill"></i>
                            <span className="ms-2">{t("export")}</span>
                          </button>
                        )}
                        {RolePermission?.findIndex(
                          (e) => e.permission_name === "add_fuel"
                        ) > -1 && (
                          <button
                            type="button"
                            className="btn  btn-icon-text mr-2"
                            onClick={handleAddModal}
                          >
                            <i className="bi bi-plus-lg"></i>
                            <span className="ms-2">{t("Add")}</span>
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="card-body fuelCardB">
                      {isLoading ? (
                        <div className="text-center">
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <div className="vehicleThBg">
                          <table className="table table-bordered rounded-table table table-striped table-hover">
                            <thead>
                              <tr>
                                <th style={{ width: "0%" }}>#</th>
                                <th
                                  className="col-form-label ml-2 Label-my form-label form-label"
                                  style={{ width: "10%" }}
                                >
                                  {t("fuel_type")}{" "}
                                </th>

                                <th
                                  className="col-form-label ml-2 Label-my form-label form-label"
                                  style={{ width: "10%" }}
                                >
                                  {LangData === "pt"
                                    ? "Fuel Type Description(PT)"
                                    : LangData === "fr"
                                    ? "Fuel Type Description(FR)"
                                    : LangData === "es"
                                    ? "Fuel Type Description(ES)"
                                    : LangData === "it"
                                    ? "Fuel Type Description(IT)"
                                    : LangData === "de"
                                    ? "Fuel Type Description(DE)"
                                    : "Fuel Type Description(EN)"}
                                </th>

                                <th style={{ width: "0%" }}>{t("action")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {fuelTypeList?.map((item, index) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{++index}</td>
                                      <td>{item.fuel_type}</td>
                                      <td>
                                        {LangData === "pt"
                                          ? item.descriptionPT
                                          : LangData === "fr"
                                          ? item.descriptionFR
                                          : LangData === "es"
                                          ? item.descriptionES
                                          : LangData === "it"
                                          ? item.descriptionIT
                                          : LangData === "de"
                                          ? item.descriptionDE
                                          : item.descriptionEN}
                                      </td>

                                      <td className="d-flex">
                                        <Dropdown
                                          className="iconDropdown"
                                          onToggle={(isOpen) => {
                                            if (isOpen) {
                                              // This logic runs when the dropdown is opened
                                              const tableResponsive =
                                                document.querySelector(
                                                  ".table-responsive"
                                                );
                                              if (tableResponsive) {
                                                tableResponsive.style.overflowX =
                                                  "inherit !important";
                                                tableResponsive.style.overflowY =
                                                  "inherit !important";
                                              }
                                            }
                                          }}
                                        >
                                          <Dropdown.Toggle
                                            variant="success"
                                            id="dropdown-basic"
                                            className="iconToggle"
                                          >
                                            <i className="bi bi-three-dots-vertical"></i>
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu>
                                            {item.is_import_record === 1 ? (
                                              <>
                                                <Dropdown.Item
                                                  href="#/action-1"
                                                  className="d-flex "
                                                  onClick={(e) =>
                                                    onViewFuelType(e, item)
                                                  }
                                                >
                                                  <div className="edit-btn">
                                                    <i class="bi bi-eye-fill"></i>
                                                  </div>
                                                  <span>{t("view")}</span>
                                                </Dropdown.Item>
                                              </>
                                            ) : (
                                              <>
                                                <Dropdown.Item
                                                  href="#/action-1"
                                                  className="d-flex "
                                                  onClick={(e) =>
                                                    onEditFuelType(e, item)
                                                  }
                                                >
                                                  {(item.company_id > 0 ||
                                                    companyId.role_id == 1) &&
                                                    RolePermission?.findIndex(
                                                      (e) =>
                                                        e.permission_name ===
                                                        "modify_fuel"
                                                    ) > -1 && (
                                                      <div className="edit-btn">
                                                        <i className="bi bi-pencil"></i>
                                                      </div>
                                                    )}

                                                  <span>{t("edit")}</span>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  href="#/action-2"
                                                  className="d-flex "
                                                  onClick={(e) =>
                                                    handleDeleteModal(e, item)
                                                  }
                                                >
                                                  {(item.company_id > 0 ||
                                                    companyId.role_id == 1) &&
                                                    RolePermission?.findIndex(
                                                      (e) =>
                                                        e.permission_name ===
                                                        "delete_fuel"
                                                    ) > -1 && (
                                                      <div className="trash-btn">
                                                        <div className="delete-btn">
                                                          <i className="bi bi-trash3"></i>
                                                        </div>
                                                      </div>
                                                    )}

                                                  <span>{t("delete")}</span>
                                                </Dropdown.Item>
                                              </>
                                            )}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}
                            </tbody>
                            {fuelTypeList?.length === 0 && (
                              <tr>
                                <td colSpan={7} className={`text-center`}>
                                  {" "}
                                  {t("no_record_found")}
                                </td>
                              </tr>
                            )}
                          </table>
                        </div>
                      )}
                    </div>
                    {/* /.card-body */}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer card-footer-part clearfix fuelCF">
              <div className="pagination pagination-sm m-0 float-right">
                <Pagination
                  activePage={params.page}
                  itemsCountPerPage={parseInt(params.limit)}
                  totalItemsCount={parseInt(totalRecords)}
                  pageRangeDisplayed={5}
                  itemClass={`page-item`}
                  linkClass={`page-link`}
                  onChange={(e) => handleParams(e, "pagination")}
                />
              </div>
            </div>

            <Modal isOpen={modifyModal}>
              <ModalHeader>{t("fuel_type")}</ModalHeader>
              <Form method={`post`} onSubmit={handleSubmit}>
                <ModalBody>
                  <div className="row">
                    <div className="col-md-6">
                      <FormGroup>
                        <Label className="col-form-label ml-2 Label-my">
                          {t("description")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control input-search"
                              type="text"
                              value={fuelType.description}
                              onChange={(e) => {
                                setFuelType({
                                  ...fuelType,
                                  description: e.target.value,
                                });
                              }}
                              name={`description`}
                              maxLength={50}
                              placeholder="Description"
                            />
                          </div>
                          {errors.description && (
                            <span className="input-error">
                              {errors.description}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-6">
                      <FormGroup>
                        <Label className="col-form-label ml-2 Label-my">
                          {t("description")} PT
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control input-search"
                              type="text"
                              value={fuelType.descriptionPT}
                              onChange={(e) => {
                                setFuelType({
                                  ...fuelType,
                                  descriptionPT: e.target.value,
                                });
                              }}
                              name={`descriptionPT`}
                              maxLength={50}
                              placeholder="Description PT"
                            />
                          </div>
                          {errors.descriptionPT && (
                            <span className="input-error">
                              {errors.descriptionPT}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-6">
                      <FormGroup>
                        <Label className="col-form-label ml-2 Label-my">
                          {t("description")} ES
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control input-search"
                              type="text"
                              value={fuelType.descriptionES}
                              onChange={(e) => {
                                setFuelType({
                                  ...fuelType,
                                  descriptionES: e.target.value,
                                });
                              }}
                              name={`descriptionES`}
                              maxLength={50}
                              placeholder="Description ES"
                            />
                          </div>
                          {errors.descriptionES && (
                            <span className="input-error">
                              {errors.descriptionES}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-6">
                      <FormGroup>
                        <Label className="col-form-label ml-2 Label-my">
                          {t("description")} FR
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control input-search"
                              type="text"
                              value={fuelType.descriptionFR}
                              onChange={(e) => {
                                setFuelType({
                                  ...fuelType,
                                  descriptionFR: e.target.value,
                                });
                              }}
                              name={`descriptionFR`}
                              maxLength={50}
                              placeholder="Description FR"
                            />
                          </div>
                          {errors.descriptionFR && (
                            <span className="input-error">
                              {errors.descriptionFR}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-6">
                      <FormGroup>
                        <Label className="col-form-label ml-2 Label-my">
                          {t("description")} IT
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control input-search"
                              type="text"
                              value={fuelType.descriptionIT}
                              onChange={(e) => {
                                setFuelType({
                                  ...fuelType,
                                  descriptionIT: e.target.value,
                                });
                              }}
                              name={`descriptionIT`}
                              maxLength={50}
                              placeholder="Description IT"
                            />
                          </div>
                          {errors.descriptionIT && (
                            <span className="input-error">
                              {errors.descriptionIT}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-6">
                      <FormGroup>
                        <Label className="col-form-label ml-2 Label-my">
                          {t("description")} DE
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control input-search"
                              type="text"
                              value={fuelType.descriptionDE}
                              onChange={(e) => {
                                setFuelType({
                                  ...fuelType,
                                  descriptionDE: e.target.value,
                                });
                              }}
                              name={`descriptionDE`}
                              maxLength={50}
                              placeholder="Description DE"
                            />
                          </div>
                          {errors.descriptionDE && (
                            <span className="input-error">
                              {errors.descriptionDE}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-6">
                      <FormGroup>
                        <Label className="col-form-label ml-2 Label-my">
                          {t("description")} EN
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control input-search"
                              type="text"
                              value={fuelType.descriptionEN}
                              onChange={(e) => {
                                setFuelType({
                                  ...fuelType,
                                  descriptionEN: e.target.value,
                                });
                              }}
                              name={`descriptionEN`}
                              maxLength={40}
                              placeholder="Description EN"
                            />
                          </div>
                          {errors.descriptionEN && (
                            <span className="input-error">
                              {errors.descriptionEN}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>

                    <div className="col-md-6"></div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <button
                    type="button"
                    class="btn btn-block btn-primary"
                    disabled={isModifyButtonLoad}
                    onClick={handleCloseModal}
                    style={{ width: "100px", marginTop: "10px" }}
                  >
                    {t("close")} <i class="bi bi-x-lg"></i>
                  </button>
                  {!isModifyButtonLoad && (
                    <button
                      type="submit"
                      class="btn btn-block btn-primary"
                      style={{ width: "100px", marginTop: "10px" }}
                    >
                      {t("submit")} <i class="bi bi-check2"></i>
                    </button>
                  )}
                  {isModifyButtonLoad && (
                    <Button
                      color="success"
                      type={`button`}
                      disabled={true}
                      className={`btn-square`}
                    >
                      <i className="fa fa-circle-o-notch fa-spin"></i>{" "}
                      {`Loading...`}
                    </Button>
                  )}
                </ModalFooter>
              </Form>
            </Modal>

            <Modal isOpen={deleteModal}>
              <ModalHeader>{t("delete_fuel_type")}</ModalHeader>
              <ModalBody>
                <p className={`mb-0`}>
                  {t("are_you_sure_you_want_to_delete_this_fuel_type")}
                </p>
              </ModalBody>
              <ModalFooter>
                <button
                  type="button"
                  class="btn btn-block btn-primary"
                  disabled={isDelButtonLoad}
                  onClick={handleCloseDeleteModal}
                  style={{ width: "100px", marginTop: "10px" }}
                >
                  {t("close")} <i class="bi bi-x-lg"></i>
                </button>

                {!isDelButtonLoad && (
                  <button
                    type="button"
                    className="btn btn-block bg-red"
                    style={{ width: "100px" }}
                    onClick={handleDeleteSubmit}
                  >
                    {t("delete")} <i class="bi bi-trash3"></i>
                  </button>
                )}
                {isDelButtonLoad && (
                  <button
                    color="secondary"
                    type={`button`}
                    disabled={true}
                    className={`btn-square`}
                  >
                    <i className="fa fa-circle-o-notch fa-spin"></i>{" "}
                    {`Loading...`}
                  </button>
                )}
              </ModalFooter>
            </Modal>

            <Modal isOpen={modifyImportModal}>
              <Form method={`post`} onSubmit={handleImportGroup}>
                <ModalHeader>{t("import_fuel_type")}</ModalHeader>
                <ModalBody>
                  <div className="row">
                    <div className="col-md-12">
                      <FormGroup>
                        <Label className="col-form-label">
                          {t("select_file")}
                        </Label>
                        <div className="col-xl-12">
                          <div className="input-div">
                            <input
                              className="form-control"
                              type="file"
                              onChange={(e) => setFile(e.target.files[0])}
                              name="file"
                              accept=".csv,.xlsx"
                            />
                          </div>
                          {errorsImport.file && (
                            <span className="input-error">
                              {errorsImport.file}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <button
                    type="button"
                    class="btn btn-block btn-secondary"
                    disabled={isModifyButtonLoad}
                    onClick={handleImportCloseModal}
                    style={{ width: "100px", marginTop: "10px" }}
                  >
                    {t("close")}
                  </button>
                  {!isModifyButtonLoad && (
                    <button
                      type="submit"
                      class="btn btn-block btn-primary"
                      style={{ width: "100px", marginTop: "10px" }}
                    >
                      {t("import")}
                    </button>
                  )}
                  {isModifyButtonLoad && (
                    <Button
                      color="success"
                      type={`button`}
                      disabled={true}
                      className={`btn-square`}
                    >
                      <i className="fa fa-circle-o-notch fa-spin"></i>{" "}
                      {`Loading...`}
                    </Button>
                  )}
                </ModalFooter>
              </Form>
            </Modal>

            <Modal isOpen={viewModal} size="lg" className="rounded-0">
              <div className="border m-3 p-3">
                <ModalHeader className="p-0 pb-2">
                  {t("fuel_type_details")}{" "}
                </ModalHeader>

                <ModalBody>
                  <table className="fuelTable">
                    <tbody className="row">
                      <tr className="col-md-6">
                        <td>{t("description")}</td>
                        <td>:</td>
                        <td>{fuelType?.description}</td>
                      </tr>
                      <tr className="col-md-6 ">
                        <td>{t("description")}PT</td>
                        <td>:</td>
                        <td>{fuelType?.descriptionPT}</td>
                      </tr>
                      <tr className="col-md-6">
                        <td>{t("description")}ES</td>
                        <td>:</td>
                        <td>{fuelType?.descriptionES}</td>
                      </tr>
                      <tr className="col-md-6">
                        <td>{t("description")}FR</td>
                        <td>:</td>
                        <td>{fuelType?.descriptionFR}</td>
                      </tr>
                      <tr className="col-md-6">
                        <td>{t("description")}IT</td>
                        <td>:</td>
                        <td>{fuelType?.descriptionIT}</td>
                      </tr>
                      <tr className="col-md-6">
                        <td>{t("description")}DE</td>
                        <td>:</td>
                        <td>{fuelType?.descriptionDE}</td>
                      </tr>
                      <tr className="col-md-6">
                        <td>{t("description")}EN</td>
                        <td>:</td>
                        <td>{fuelType?.descriptionEN}</td>
                      </tr>
                      <tr className="col-md-6"></tr>
                    </tbody>
                  </table>
                </ModalBody>
                <ModalFooter className="p-0">
                  <button
                    type="button"
                    class="btn btn-block btn-primary"
                    disabled={isModifyButtonLoad}
                    onClick={onCloseViewFuelType}
                    style={{ width: "100px", marginTop: "10px" }}
                  >
                    {t("close")} <i class="bi bi-x-lg"></i>
                  </button>

                  {isModifyButtonLoad && (
                    <Button
                      color="success"
                      type={`button`}
                      disabled={true}
                      className={`btn-square`}
                    >
                      <i className="fa fa-circle-o-notch fa-spin"></i>{" "}
                      {`Loading...`}
                    </Button>
                  )}
                </ModalFooter>
              </div>
            </Modal>
          </section>
        </div>
      )}
    </div>
  );
};

export default FuelType;
