import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import Select from "react-select";
import Utils from "../../utils";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import {
  actionSettingAdd,
  actionSettingCompanyAdd,
  deleteCompanySetting,
  getCommonSearchList,
  getCompanyList,
  getCompanyPlanChange,
  getSettingsCompanyDetails,
  getSettingsDetails,
} from "../../services/settingsServices";
import ConfigDB from "../../config";
import { getViewAllParkList } from "../../services/ParkServices";
import moment from "moment";
import { getSubscriptionList } from "../../services/AuthServices";
import Pagination from "react-js-pagination";
import { useTranslation } from "react-i18next";
import { SketchPicker } from "react-color";

const Settings = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [settingData, setSettingData] = useState({
    uuid: "",
    team_size: "",
    about_description: "",
    contact_description: "",
    address: "",
    email: "",
    experience: "",
    mobile: "",
    facebook_url: "",
    instagram_url: "",
    youtube_url: "",
    whatsapp_url: "",
    linkedin_url: "",
    google_play_url: "",
    app_store_url: "",
    footer_description: "",
    our_services_description: "",
    part_image_limit: "",
    cron_job_ping_time: "",
    provider_code: "",
    secret: "",
    salt: "",
    part_video_size: "",
    part_video_limit: "",
  });

  const [settingCompanyData, setSettingCompanyData] = useState({
    id: "",
    is_force_cron_job: 0,
    state_id: "",
    condition_id: "",
    discount_percentage: "",
    print_label: 0,
    park_id: "",
    part_column_ids: "",
    vat_included: 0,
    reg_number_dismantler: "",
    is_enable_ecommerce_access: 0,
    default_color: "",
    default_image: "",
    printer_api: "",
    paper_size: "",
    printer_name: "",
  });

  const tableLengthList = ConfigDB.data.dataTableLength;

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
  });

  const state = useLocation();
  const [previewUrl, setPreviewUrl] = useState(null);
  const [image, setImage] = useState(null);
  const [previewUrlAbout, setPreviewUrlAbout] = useState(null);
  const [AboutImage, setAboutImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalAbout, setShowModalAbout] = useState(false);
  const [settingDetails, setSettingDetails] = useState("");
  const [settingCompanyDetails, setSettingCompanyDetails] = useState("");

  const [inputFields, setInputFields] = useState([
    { id: "", id_sirapa: "", vat_number: "" },
  ]);

  const [selectedRadio, setSelectedRadio] = useState("1");
  console.log("selectedRadio", selectedRadio);
  const [stateList, setStateList] = useState([]);

  const [conditionList, setConditionList] = useState([]);
  const [parkList, setParkList] = useState([]);
  const [partColumnList, setPartColumnList] = useState([]);
  const [inputValueLer, setInputValueLer] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [companystatus, setCompanystatus] = useState(false);
  const [companystatusData, setCompanystatusData] = useState("");
  const [SubscriptionList, setSubscriptionList] = useState([]);
  const [CompanyPlanData, SetCompanyPlanData] = useState({
    subscription_id: "",
    subscription_expiry: "",
    is_subscribed: "",
    plan_id: "",
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [imageDefualt, setImageDefualt] = useState(null);
  const [previewUrlDefualt, setPreviewUrlDefualt] = useState(null);

  const [color, setColor] = useState("#ffffff");

  const handleChangeColor = (newColor) => {
    setColor(newColor.hex);
  };

  const handleFileChangeDefualt = (event) => {
    const selectedFiles = event.target.files[0];

    if (selectedFiles) {
      setImageDefualt(selectedFiles);
      const imageUrl = URL.createObjectURL(selectedFiles);
      setPreviewUrlDefualt(imageUrl);
    }
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const formatDate = (isoString) => {
    return isoString ? new Date(isoString).toISOString().split("T")[0] : "";
  };

  const handleCheckboxChangeProfile = (e) => {
    setSettingCompanyData({
      ...settingCompanyData,
      is_enable_ecommerce_access: e.target.checked ? 1 : 0,
    });
  };

  useEffect(() => {
    if (companystatusData) {
      SetCompanyPlanData({
        subscription_id: companystatusData?.subscription_id,
        subscription_expiry: companystatusData?.subscription_expiry,
        is_subscribed: companystatusData?.is_subscribed,
      });
    }
  }, [companystatusData]);

  const handleCloseCompany = () => {
    setCompanystatus(false);
  };

  const handleCheckboxChangeIsSubscribe = (e) => {
    SetCompanyPlanData({
      ...CompanyPlanData,
      is_subscribed: e.target.checked ? 1 : 0,
    });
  };

  useEffect(() => {
    getSubscriptionList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setSubscriptionList(list);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  }, []);

  const handleOpenCompany = (e, item) => {
    setCompanystatusData(item);
    setCompanystatus(true);
  };

  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.value);
  };

  const data = localStorage.getItem(ConfigDB.data.login_data);
  const isAdmin = JSON.parse(data);

  const handleAddInput = () => {
    setInputFields([...inputFields, { id_sirapa: "", vat_number: "" }]);
  };

  const handleRemoveInput = (index) => {
    const credentialId = inputFields[index]?.id;

    const newInputFields = inputFields.filter((_, i) => i !== index);

    setInputFields(newInputFields);

    if (credentialId) {
      const data = {
        id: credentialId,
      };

      deleteCompanySetting(data)
        .then((response) => {
          toast.dismiss();
          toast.success(response.data.message);
        })
        .catch((err) => {
          toast.dismiss();
          toast.error(Utils.getErrorMessage(err));
        });
    }
  };

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "state",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setStateList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "condition",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setConditionList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(function () {
    var obj = {
      search: "",
      limit: 10,
      page: 1,
    };
    getViewAllParkList(obj)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].place,
          });
        }
        setParkList(list);
      })
      .catch((err) => {});
  }, []);

  const handleChange = (index, event) => {
    const newInputFields = [...inputFields];
    const { name, value } = event.target;

    if (name?.startsWith("id_sirapa")) {
      newInputFields[index].id_sirapa = value;
    } else if (name?.startsWith("vat_number")) {
      newInputFields[index].vat_number = value;
    }

    setInputFields(newInputFields);
  };

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleShowAbout = () => setShowModalAbout(true);
  const handleCloseAbout = () => setShowModalAbout(false);

  useEffect(() => {
    if (settingDetails) {
      setSettingData({
        team_size: settingDetails?.team_size,
        about_description: settingDetails?.about_description,
        contact_description: settingDetails?.contact_description,
        address: settingDetails?.address,
        email: settingDetails?.email,
        mobile: settingDetails?.mobile,
        facebook_url: settingDetails?.facebook_url,
        instagram_url: settingDetails?.instagram_url,
        youtube_url: settingDetails?.youtube_url,
        whatsapp_url: settingDetails?.whatsapp_url,
        linkedin_url: settingDetails?.linkedin_url,
        google_play_url: settingDetails?.google_play_url,
        app_store_url: settingDetails?.app_store_url,
        footer_description: settingDetails?.footer_description,
        experience: settingDetails?.experience,
        our_services_description: settingDetails?.our_services_description,
        part_image_limit: settingDetails?.part_image_limit,
        cron_job_ping_time: settingDetails?.cron_job_ping_time,
        provider_code: settingDetails?.provider_code,
        secret: settingDetails?.secret,
        salt: settingDetails?.salt,
        part_video_limit: settingDetails?.part_video_limit,
        part_video_size: settingDetails?.part_video_size,

        is_force_cron_job: settingDetails?.is_force_cron_job,
      });
      setPreviewUrl(settingDetails?.header_image);
      setPreviewUrlAbout(settingDetails?.about_image);
      setImage(settingDetails?.header_image);
      setAboutImage(settingDetails?.about_image);
    }
  }, [settingDetails]);

  useEffect(() => {
    if (settingCompanyDetails) {
      setSettingCompanyData({
        is_force_cron_job: settingCompanyDetails?.is_force_cron_job,
        label_machine: settingCompanyDetails?.label_machine,
        state_id: settingCompanyDetails?.state_id,
        condition_id: settingCompanyDetails?.condition_id,
        discount_percentage: settingCompanyDetails?.discount_percentage,
        print_label: settingCompanyDetails?.print_label,

        park_id: settingCompanyDetails?.park_id,
        part_column_ids: settingCompanyDetails?.part_column_ids,
        vat_included: settingCompanyDetails?.vat_included,
        reg_number_dismantler: settingCompanyDetails?.reg_number_dismantler,

        is_enable_ecommerce_access:
          settingCompanyDetails?.is_enable_ecommerce_access,

        printer_api: settingCompanyDetails?.printer_api,
        paper_size: settingCompanyDetails?.paper_size,
        printer_name: settingCompanyDetails?.printer_name,
      });
      setInputFields(settingCompanyDetails?.credential);
      setSelectedRadio(String(settingCompanyDetails?.label_machine));
      setColor(settingCompanyDetails?.default_color);
      setImageDefualt(settingCompanyDetails?.default_image);
      setPreviewUrlDefualt(settingCompanyDetails?.default_image);
    }
  }, [settingCompanyDetails]);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        if (isAdmin.role_id === "1") {
          const settingsResponse = await getSettingsDetails();
          setSettingDetails(settingsResponse.data.data);
        }

        const companyResponse = await getSettingsCompanyDetails();
        setSettingCompanyDetails(companyResponse.data.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchSettings();
  }, []);

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files[0];

    if (selectedFiles) {
      setImage(selectedFiles);
      const imageUrl = URL.createObjectURL(selectedFiles);
      setPreviewUrl(imageUrl);
    }
  };

  const handleAboutFileChange = (event) => {
    const selectedFiles = event.target.files[0];

    if (selectedFiles) {
      setAboutImage(selectedFiles);
      const imageUrl = URL.createObjectURL(selectedFiles);
      setPreviewUrlAbout(imageUrl);
    }
  };

  const handleSettingForm = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("header_image", image);
    formData.append("about_description", settingData?.about_description);
    formData.append("about_image", AboutImage);
    formData.append("experience", settingData?.experience);
    formData.append("team_size", settingData?.team_size);
    formData.append("contact_description", settingData?.contact_description);
    formData.append("address", settingData?.address);
    formData.append("email", settingData?.email);
    formData.append("mobile", settingData?.mobile);
    formData.append("facebook_url", settingData?.facebook_url);
    formData.append("instagram_url", settingData?.instagram_url);
    formData.append("youtube_url", settingData?.youtube_url);
    formData.append("whatsapp_url", settingData?.whatsapp_url);
    formData.append("linkedin_url", settingData?.linkedin_url);
    formData.append("google_play_url", settingData?.google_play_url);
    formData.append("app_store_url", settingData?.app_store_url);
    formData.append("footer_description", settingData?.footer_description);
    formData.append(
      "our_services_description",
      settingData?.our_services_description
    );
    formData.append("part_image_limit", settingData?.part_image_limit);
    formData.append("cron_job_ping_time", settingData?.cron_job_ping_time);
    formData.append("provider_code", settingData?.provider_code);
    formData.append("secret", settingData?.secret);
    formData.append("salt", settingData?.salt);
    formData.append("part_video_limit", settingData?.part_video_limit);
    formData.append("part_video_size", settingData?.part_video_size);

    actionSettingAdd(formData)
      .then((response) => {
        toast.success(response.data.message);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleSettingPrintLabel = (e) => {
    e.preventDefault();

    if (!settingCompanyDetails?.printer_api) {
      toast.error(
        "Printer API not found. Install the app, copy the URL, and update settings."
      );
      return;
    }

    const apiUrl = settingCompanyDetails.printer_api;

    const data = {
      printerName: "Brother QL-800",
      printData: "Hello this is test print from api",
    };

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((result) => {
        toast.success(result.message || "Print request sent successfully.");
      })
      .catch((error) => {
        toast.error(`Print failed: ${error.message}`);
      });
  };

  const handleSettingCompanyForm = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("is_force_cron_job", settingCompanyData?.is_force_cron_job);
    formData.append("credential", JSON.stringify(inputFields));
    formData.append("label_machine", selectedRadio);
    formData.append("state_id", settingCompanyData?.state_id);
    formData.append("condition_id", settingCompanyData?.condition_id);
    formData.append(
      "discount_percentage",
      settingCompanyData?.discount_percentage
    );
    formData.append("print_label", settingCompanyData?.print_label);
    formData.append("park_id", settingCompanyData?.park_id);
    formData.append("part_column_ids", settingCompanyData?.part_column_ids);
    formData.append("vat_included", settingCompanyData?.vat_included);
    formData.append(
      "reg_number_dismantler",
      settingCompanyData?.reg_number_dismantler
    );
    formData.append(
      "is_enable_ecommerce_access",
      settingCompanyData?.is_enable_ecommerce_access || 0
    );
    formData.append(
      "reg_number_dismantler",
      settingCompanyData?.reg_number_dismantler
    );
    formData.append("default_color", color);
    formData.append("default_image", imageDefualt);
    formData.append("printer_name", settingCompanyData?.printer_name);
    formData.append("paper_size", settingCompanyData?.paper_size);
    formData.append("printer_api", settingCompanyData?.printer_api);

    actionSettingCompanyAdd(formData)
      .then((response) => {
        toast.success(response.data.message);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleChangePlanSubmit = (e) => {
    e.preventDefault();

    const data = {
      subscription_id: CompanyPlanData?.subscription_id,
      subscription_expiry: CompanyPlanData?.subscription_expiry,
      is_subscribed: CompanyPlanData?.is_subscribed,
      company_id: companystatusData?.id,
    };
    getCompanyPlanChange(data)
      .then((response) => {
        toast.success(response.data.message);
        setCompanystatus(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleCheckboxChange = (e) => {
    setSettingCompanyData({
      ...settingCompanyData,
      is_force_cron_job: e.target.checked ? 1 : 0,
    });
  };

  const handleCheckboxChangePrint = (e) => {
    setSettingCompanyData({
      ...settingCompanyData,
      print_label: e.target.checked ? 1 : 0,
    });
  };

  const handleCheckboxChangeVat = (e) => {
    setSettingCompanyData({
      ...settingCompanyData,
      vat_included: e.target.checked ? 1 : 0,
    });
  };

  useEffect(function () {
    let data = {
      limit: 10,
      page: 1,
      search: "",
      type: "part_column",
    };
    getCommonSearchList(data)
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }

        setPartColumnList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(
    function () {
      let data = {
        ...params,
      };
      getCompanyList(data)
        .then((response) => {
          setCompanyList(response.data.data);
          setTotalRecords(response?.data?.total);
        })
        .catch((err) => {});
    },
    [params]
  );

  const handleInputChangeLER = (inputValueLer) => {
    setInputValueLer(inputValueLer);

    if (inputValueLer.length > 2) {
      const obj = {
        search: inputValueLer,
        limit: 10,
        page: 1,
        type: "part_column",
      };

      getCommonSearchList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }

          setPartColumnList(list);
        })
        .catch((err) => {});
    } else {
      setPartColumnList([]);
      const obj = {
        search: "",
        limit: 10,
        page: 1,
        type: "part_column",
      };

      getCommonSearchList(obj)
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }

          setPartColumnList(list);
        })
        .catch((err) => {});
    }
  };

  const handleSelectLERChange = (selectedOptions) => {
    // Map the selected options to an array of ids (if any) and join them into a string
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value).join(",")
      : "";

    // Update the state with the new comma-separated string
    setSettingCompanyData({
      ...settingCompanyData,
      part_column_ids: selectedValues,
    });
  };

  const handleSelectPlanChange = (selectedOption) => {
    if (!selectedOption) {
      SetCompanyPlanData({
        ...CompanyPlanData,
        subscription_id: "",
        subscription_expiry: "",
      });
      return;
    }

    const currentDate = new Date();

    currentDate.setMonth(currentDate.getMonth() + 1);

    const formattedDate = currentDate.toISOString().split("T")[0];

    SetCompanyPlanData({
      ...CompanyPlanData,
      subscription_id: selectedOption.value,
      subscription_expiry: formattedDate,
    });
  };

  return (
    <div>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Settings</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                {/* <li className="breadcrumb-item">
                  <a href="/part-catalog">Part Catalog</a>
                </li> */}
                {/* <li className="breadcrumb-item active">Add PartCatalog</li> */}
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-primary">
                {/* <div className="card-header">
                  <h3 className="card-title">Update Profile</h3>
                </div> */}

                {isAdmin?.role_id === "1" ? (
                  <>
                    <Form method={`post`} onSubmit={handleSettingForm}>
                      <div className="card-body">
                        <div className="col-form-label ml-2 Label-my form-label form-label">
                          <h3>Header</h3>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                {"Header Image"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <div className="avatar-upload">
                                    <div className="avatar-edit">
                                      <div className="input-div">
                                        <input
                                          className="inputFile"
                                          type="file"
                                          id="imageUpload"
                                          onChange={handleFileChange}
                                          name="file"
                                          accept=".jpg,.jpeg,.png"
                                        />
                                        {previewUrl && (
                                          <i
                                            class="bi bi-eye eyeIcon"
                                            onClick={handleShow}
                                          ></i>
                                        )}
                                      </div>
                                      <label htmlFor="imageUpload"></label>
                                    </div>
                                    {/* <div className="avatar-preview profile-user">
                                  {previewUrl && (
                                    <i
                                      class="bi bi-eye"
                                      onClick={handleShow}
                                    ></i>
                                  )}
                                </div> */}
                                  </div>
                                </div>
                              </div>
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                {"Part Image Limit"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <input
                                    className="form-control input-search"
                                    type="number"
                                    value={settingData?.part_image_limit}
                                    onChange={(e) => {
                                      setSettingData({
                                        ...settingData,
                                        part_image_limit: e.target.value,
                                      });
                                    }}
                                    name={`part_image_limit`}
                                    placeholder="Part Image Limit"
                                  />
                                </div>{" "}
                              </div>
                            </FormGroup>
                          </div>
                        </div>
                        <div className="col-form-label ml-2 Label-my form-label form-label">
                          <h3>About</h3>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                {"About Description"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <textarea
                                    className="form-control input-search"
                                    type="text"
                                    value={settingData.about_description}
                                    onChange={(e) => {
                                      setSettingData({
                                        ...settingData,
                                        about_description: e.target.value,
                                      });
                                    }}
                                    name={`about_description`}
                                    placeholder="About description"
                                    rows={4}
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </div>

                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                {"About Image"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <div className="avatar-upload">
                                    <div className="avatar-edit">
                                      <div className="input-div">
                                        <input
                                          className="inputFile"
                                          type="file"
                                          id="imageuploadAbout"
                                          onChange={handleAboutFileChange}
                                          name="file"
                                          accept=".jpg,.jpeg,.png"
                                        />
                                        {previewUrlAbout && (
                                          <i
                                            style={{
                                              position: "absolute",
                                              top: "6px",
                                              right: "12px",
                                              fontSize: "20px",
                                              color: "#81bb42",
                                            }}
                                            class="bi bi-eye"
                                            onClick={handleShowAbout}
                                          ></i>
                                        )}
                                      </div>

                                      <label htmlFor="imageuploadAbout"></label>
                                    </div>
                                    {/* <div className="avatar-preview profile-user">
                                  <img
                                    src={previewUrlAbout}
                                    style={{
                                      width: "500px",
                                      height: "225px",
                                      bordeRadius: "75px",
                                      marginLeft: "15px",
                                      marginBottom: "20px",
                                    }}
                                  />
                                </div> */}
                                  </div>
                                </div>
                              </div>
                            </FormGroup>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                {"Experience"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <input
                                    className="form-control input-search"
                                    type="text"
                                    value={settingData.experience}
                                    onChange={(e) => {
                                      setSettingData({
                                        ...settingData,
                                        experience: e.target.value,
                                      });
                                    }}
                                    name={`experience`}
                                    placeholder="Experience"
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </div>

                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                {"Team Size"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <input
                                    className="form-control input-search"
                                    type="text"
                                    value={settingData.team_size}
                                    onChange={(e) => {
                                      setSettingData({
                                        ...settingData,
                                        team_size: e.target.value,
                                      });
                                    }}
                                    name={`team_size`}
                                    placeholder="Team Size"
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </div>
                        </div>

                        <div className="col-form-label ml-2 Label-my form-label form-label">
                          <h3>Contact</h3>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                {"Contact Description"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <textarea
                                    className="form-control input-search"
                                    type="text"
                                    value={settingData.contact_description}
                                    onChange={(e) => {
                                      setSettingData({
                                        ...settingData,
                                        contact_description: e.target.value,
                                      });
                                    }}
                                    name={`contact_description`}
                                    placeholder="Contact description"
                                    rows={4}
                                  />
                                </div>
                              </div>
                            </FormGroup>

                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                {t("e_mail")}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <input
                                    className="form-control input-search"
                                    type="text"
                                    value={settingData.email}
                                    onChange={(e) => {
                                      setSettingData({
                                        ...settingData,
                                        email: e.target.value,
                                      });
                                    }}
                                    name={`email`}
                                    placeholder="Email"
                                  />
                                </div>
                              </div>
                            </FormGroup>

                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                {"Facebook URL"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <input
                                    className="form-control input-search"
                                    type="text"
                                    value={settingData.facebook_url}
                                    onChange={(e) => {
                                      setSettingData({
                                        ...settingData,
                                        facebook_url: e.target.value,
                                      });
                                    }}
                                    name={`facebook_url`}
                                    placeholder="Facebook URL"
                                  />
                                </div>
                              </div>
                            </FormGroup>

                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                {"Youtube URL"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <input
                                    className="form-control input-search"
                                    type="text"
                                    value={settingData.youtube_url}
                                    onChange={(e) => {
                                      setSettingData({
                                        ...settingData,
                                        youtube_url: e.target.value,
                                      });
                                    }}
                                    name={`youtube_url`}
                                    placeholder="Youtube URL"
                                  />
                                </div>
                              </div>
                            </FormGroup>

                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                {"Linkedin URL"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <input
                                    className="form-control input-search"
                                    type="text"
                                    value={settingData.linkedin_url}
                                    onChange={(e) => {
                                      setSettingData({
                                        ...settingData,
                                        linkedin_url: e.target.value,
                                      });
                                    }}
                                    name={`linkedin_url`}
                                    placeholder="Linkedin URL"
                                  />
                                </div>
                              </div>
                            </FormGroup>

                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                {"App Store URL"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <input
                                    className="form-control input-search"
                                    type="text"
                                    value={settingData.app_store_url}
                                    onChange={(e) => {
                                      setSettingData({
                                        ...settingData,
                                        app_store_url: e.target.value,
                                      });
                                    }}
                                    name={`app_store_url`}
                                    placeholder="App Store URL"
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </div>

                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                {"Address"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <textarea
                                    className="form-control input-search"
                                    type="text"
                                    value={settingData.address}
                                    onChange={(e) => {
                                      setSettingData({
                                        ...settingData,
                                        address: e.target.value,
                                      });
                                    }}
                                    name={`address`}
                                    placeholder="Address"
                                    rows={4}
                                  />
                                </div>
                              </div>
                            </FormGroup>

                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                {"Mobile"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <input
                                    className="form-control input-search"
                                    type="text"
                                    value={settingData.mobile}
                                    onChange={(e) => {
                                      setSettingData({
                                        ...settingData,
                                        mobile: e.target.value,
                                      });
                                    }}
                                    name={`mobile`}
                                    maxLength={10}
                                    placeholder="Mobile"
                                  />
                                </div>
                              </div>
                            </FormGroup>

                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                {"Instagram URL"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <input
                                    className="form-control input-search"
                                    type="text"
                                    value={settingData.instagram_url}
                                    onChange={(e) => {
                                      setSettingData({
                                        ...settingData,
                                        instagram_url: e.target.value,
                                      });
                                    }}
                                    name={`instagram_url`}
                                    placeholder="Instagram URL"
                                  />
                                </div>
                              </div>
                            </FormGroup>

                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                {"Whatsapp URL"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <input
                                    className="form-control input-search"
                                    type="text"
                                    value={settingData.whatsapp_url}
                                    onChange={(e) => {
                                      setSettingData({
                                        ...settingData,
                                        whatsapp_url: e.target.value,
                                      });
                                    }}
                                    name={`whatsapp_url`}
                                    placeholder="Whatsapp URL"
                                  />
                                </div>
                              </div>
                            </FormGroup>

                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                {"Google play URL"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <input
                                    className="form-control input-search"
                                    type="text"
                                    value={settingData.google_play_url}
                                    onChange={(e) => {
                                      setSettingData({
                                        ...settingData,
                                        google_play_url: e.target.value,
                                      });
                                    }}
                                    name={`google_play_url`}
                                    placeholder="Google play URL"
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </div>
                        </div>

                        <div className="col-form-label ml-2 Label-my form-label form-label">
                          <h3>Footer</h3>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                {"Footer Description"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <textarea
                                    className="form-control input-search"
                                    type="text"
                                    value={settingData.footer_description}
                                    onChange={(e) => {
                                      setSettingData({
                                        ...settingData,
                                        footer_description: e.target.value,
                                      });
                                    }}
                                    name={`footer_description`}
                                    placeholder="Footer description"
                                    rows={4}
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </div>

                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                {"Our Services Description"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <textarea
                                    className="form-control input-search"
                                    type="text"
                                    value={settingData.our_services_description}
                                    onChange={(e) => {
                                      setSettingData({
                                        ...settingData,
                                        our_services_description:
                                          e.target.value,
                                      });
                                    }}
                                    name={`our_services_description`}
                                    placeholder="Our Services Description"
                                    rows={4}
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </div>
                        </div>

                        <div className="col-form-label ml-2 Label-my form-label form-label">
                          <div className="row">
                            <div className="col-md-6">
                              <FormGroup>
                                <Label className="col-form-label ml-2 Label-my form-label form-label">
                                  {"Minute"}
                                </Label>
                                <div className="col-md-3">
                                  <div className="input-div">
                                    <input
                                      className="form-control input-search form-control"
                                      type="text"
                                      value={settingData.cron_job_ping_time}
                                      onChange={(e) => {
                                        setSettingData({
                                          ...settingData,
                                          cron_job_ping_time: e.target.value,
                                        });
                                      }}
                                      name={`cron_job_ping_time`}
                                      maxLength={9}
                                      placeholder="minute"
                                    />
                                  </div>
                                </div>
                              </FormGroup>
                            </div>

                            <div className="col-md-6">
                              <FormGroup>
                                <Label className="col-form-label ml-2 Label-my form-label form-label">
                                  {"Provider Code"}
                                </Label>
                                <div className="col-xl-6">
                                  <div className="input-div">
                                    <input
                                      className="form-control input-search form-control"
                                      type="text"
                                      value={settingData.provider_code}
                                      onChange={(e) => {
                                        setSettingData({
                                          ...settingData,
                                          provider_code: e.target.value,
                                        });
                                      }}
                                      name={`provider_code`}
                                      placeholder="Provider Code"
                                    />
                                  </div>
                                </div>
                              </FormGroup>
                            </div>

                            <div className="col-md-6">
                              <FormGroup>
                                <Label className="col-form-label ml-2 Label-my form-label form-label">
                                  {"Secret"}
                                </Label>
                                <div className="col-xl-6">
                                  <div className="input-div">
                                    <input
                                      className="form-control input-search form-control"
                                      type="text"
                                      value={settingData.secret}
                                      onChange={(e) => {
                                        setSettingData({
                                          ...settingData,
                                          secret: e.target.value,
                                        });
                                      }}
                                      name={`secret`}
                                      placeholder="secret"
                                    />
                                  </div>
                                </div>
                              </FormGroup>
                            </div>

                            <div className="col-md-6">
                              <FormGroup>
                                <Label className="col-form-label ml-2 Label-my form-label form-label">
                                  {"Salt"}
                                </Label>
                                <div className="col-xl-6">
                                  <div className="input-div">
                                    <input
                                      className="form-control input-search form-control"
                                      type="text"
                                      value={settingData.salt}
                                      onChange={(e) => {
                                        setSettingData({
                                          ...settingData,
                                          salt: e.target.value,
                                        });
                                      }}
                                      name={`salt`}
                                      maxLength={9}
                                      placeholder="Salt"
                                    />
                                  </div>
                                </div>
                              </FormGroup>
                            </div>
                          </div>

                          <div className="col-md-12 ms-2">
                            <Button
                              type="button"
                              onClick={handleAddInput}
                              color="primary"
                            >
                              <i className="bi bi-plus-lg"></i>
                            </Button>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label">
                                {"Part Video Size(MB)"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div d-flex">
                                  <input
                                    className="form-control"
                                    type="number"
                                    min="0"
                                    step="0.1"
                                    value={settingData.part_video_size}
                                    onChange={(e) => {
                                      setSettingData({
                                        ...settingData,
                                        part_video_size: e.target.value,
                                      });
                                    }}
                                    name={`part_video_size `}
                                    placeholder="Part Video Size"
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </div>

                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label">
                                {"Part video limit"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div d-flex">
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={settingData.part_video_limit}
                                    onChange={(e) => {
                                      setSettingData({
                                        ...settingData,
                                        part_video_limit: e.target.value,
                                      });
                                    }}
                                    name={`part_video_limit `}
                                    placeholder="Part video limit"
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </div>
                        </div>

                        <div className="col-form-label ml-2 Label-my form-label form-label">
                          <h3>Company List</h3>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div
                              className="col-md-6"
                              style={{ marginBottom: "10px" }}
                            >
                              <input
                                type="text"
                                className="form-control"
                                value={params.search}
                                onChange={(e) => handleParams(e, "search")}
                                placeholder="Search by company name &  email"
                              ></input>
                            </div>
                            <div className="vehicleparts-table-inner vehicleThBg tableScroll table-scroll-modal">
                              <table className="rounded-table table table-striped table-bordered table-hover">
                                <thead>
                                  <tr>
                                    <th style={{ width: "10%" }}>
                                      Company Name
                                    </th>
                                    <th style={{ width: "10%" }}>Email </th>
                                    <th style={{ width: "10%" }}>
                                      Created Date
                                    </th>
                                    <th style={{ width: "10%" }}>Plan Name</th>

                                    <th style={{ width: "10%" }}>
                                      Expriry Date
                                    </th>
                                    <th style={{ width: "10%" }}>Subscribed</th>
                                    <th style={{ width: "0%" }}>
                                      {t("action")}
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {companyList?.length > 0 ? (
                                    companyList?.map((item, index) => (
                                      <tr key={index}>
                                        <td>{item.name}</td>
                                        <td>{item.email}</td>
                                        <td>
                                          {moment(item.created_at).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </td>

                                        <td>{item.subscription_name}</td>
                                        <td>
                                          {moment(
                                            item.subscription_expiry
                                          ).format("DD/MM/YYYY")}
                                        </td>
                                        <td
                                          style={{
                                            color:
                                              item.is_subscribed === 1
                                                ? "green"
                                                : "red",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {item.is_subscribed
                                            ? "Subscribed"
                                            : "Not Subscribed"}
                                        </td>

                                        <td>
                                          <button
                                            className="login-btn"
                                            type="button"
                                            onClick={(e) =>
                                              handleOpenCompany(e, item)
                                            }
                                            style={{ width: "103px" }}
                                          >
                                            Change Plan
                                          </button>
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan="100%">No results found</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                            <div className="fuelCF">
                              <div className="card-footer card-footer-part clearfix">
                                <div className="pagination pagination-sm m-0 float-right">
                                  <Pagination
                                    activePage={params.page}
                                    itemsCountPerPage={parseInt(params.limit)}
                                    totalItemsCount={parseInt(totalRecords)}
                                    pageRangeDisplayed={5}
                                    itemClass={`page-item`}
                                    linkClass={`page-link`}
                                    onChange={(e) =>
                                      handleParams(e, "pagination")
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col"></div>
                        <div className="col">
                          <div
                            className="d-flex justify-content-end"
                            style={{ padding: "10px" }}
                          >
                            <button type="submit" className="btn btn-primary">
                              Submit <i class="bi bi-check2"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="card-footer"></div>
                    </Form>
                  </>
                ) : (
                  <Form method={`post`} onSubmit={handleSettingCompanyForm}>
                    <div className="card-body">
                      <div className="col-form-label ml-2 Label-my form-label form-label">
                        <h3>Credentials</h3>
                        <span
                          style={{
                            background: "#a11212",
                            color: "#fff",
                            padding: "6px",
                          }}
                        >
                          {" "}
                          <strong>Note:</strong> read the vehicles certificates
                        </span>
                      </div>
                      <div className="row justify-content-start">
                        {inputFields?.map((inputField, index) => (
                          <>
                            <div className="col-md-6" key={index}>
                              <FormGroup>
                                <Label className="col-form-label ml-2 Label-my form-label">
                                  {"id Sirapa"}
                                </Label>
                                <div className="col-xl-12">
                                  <div className="input-div d-flex">
                                    <input
                                      className="form-control input-search"
                                      type="text"
                                      value={inputField.id_sirapa}
                                      onChange={(e) => handleChange(index, e)}
                                      name={`id_sirapa_${index}`}
                                      placeholder="id Sirapa"
                                    />
                                  </div>
                                </div>
                              </FormGroup>
                            </div>

                            <div className="col-md-6" key={index}>
                              <FormGroup>
                                <Label className="col-form-label ml-2 Label-my form-label">
                                  {"Vat Number"}
                                </Label>
                                <div className="col-xl-12">
                                  <div className="input-div d-flex input-sett-custom">
                                    <input
                                      className="form-control input-search"
                                      type="text"
                                      value={inputField.vat_number}
                                      onChange={(e) => handleChange(index, e)}
                                      name={`vat_number_${index}`}
                                      placeholder="Vat Number"
                                    />
                                    <Button
                                      type="button"
                                      onClick={() => handleRemoveInput(index)}
                                      color="danger"
                                      className="setting-btn"
                                    >
                                      <i className="bi bi-trash3"></i>
                                    </Button>
                                  </div>
                                </div>
                              </FormGroup>
                            </div>
                          </>
                        ))}

                        <div className="col-md-12 ms-2">
                          <Button
                            type="button"
                            onClick={handleAddInput}
                            color="primary"
                          >
                            <i className="bi bi-plus-lg"></i>
                          </Button>
                        </div>
                        <div className="row justify-content-start">
                          <div className="col-md-4">
                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                Is Force Cron Job
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={
                                        settingCompanyData.is_force_cron_job ===
                                        1
                                      }
                                      onChange={handleCheckboxChange}
                                    />
                                    <label className="form-check-label">
                                      Is Force Cron Job
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </FormGroup>
                          </div>
                          <div className="col-md-4">
                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label">
                                {"Label Machine"}
                              </Label>
                              <div style={{ display: "flex" }}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadio"
                                    id="flexRadio1"
                                    value="1"
                                    checked={selectedRadio === "1"}
                                    onChange={handleRadioChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexRadio1"
                                  >
                                    Zebra Label
                                  </label>
                                </div>

                                <div
                                  className="form-check"
                                  style={{ marginLeft: "10px" }}
                                >
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadio"
                                    id="flexRadio2"
                                    value="2"
                                    checked={selectedRadio === "2"}
                                    onChange={handleRadioChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexRadio2"
                                  >
                                    Brothers Label
                                  </label>
                                </div>
                              </div>
                            </FormGroup>
                          </div>
                          <div className="col-md-4"></div>
                        </div>

                        <div className="row justify-content-start">
                          <div className="col-md-4">
                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                {"Select State"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div ">
                                  <Select
                                    options={stateList}
                                    value={stateList.find(
                                      (option) =>
                                        option.value ===
                                        settingCompanyData.state_id
                                    )}
                                    onChange={(selectedOption) =>
                                      setSettingCompanyData({
                                        ...settingCompanyData,
                                        state_id: selectedOption.value,
                                      })
                                    }
                                    placeholder={"Select State"}
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </div>

                          <div className="col-md-4">
                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                {"Select Condition"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <Select
                                    options={conditionList}
                                    value={conditionList.find(
                                      (option) =>
                                        option.value ===
                                        settingCompanyData.condition_id
                                    )}
                                    onChange={(selectedOption) =>
                                      setSettingCompanyData({
                                        ...settingCompanyData,
                                        condition_id: selectedOption.value,
                                      })
                                    }
                                    placeholder={"Select Condition"}
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </div>
                          <div className="col-md-4">
                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                {"Select Park"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <Select
                                    options={parkList}
                                    value={parkList.find(
                                      (option) =>
                                        option.value ===
                                        settingCompanyData.park_id
                                    )}
                                    onChange={(selectedOption) =>
                                      setSettingCompanyData({
                                        ...settingCompanyData,
                                        park_id: selectedOption.value,
                                      })
                                    }
                                    placeholder={"Select Park"}
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                {"Select Part Column"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <Select
                                    options={partColumnList}
                                    value={partColumnList.filter((option) =>
                                      (settingCompanyData.part_column_ids || "")
                                        .split(",")
                                        .includes(String(option.value))
                                    )}
                                    onChange={handleSelectLERChange}
                                    isSearchable={true}
                                    isClearable={true}
                                    isMulti
                                    placeholder={"Select Part"}
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label">
                                {"Discount Percentage(%)"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div d-flex">
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={
                                      settingCompanyData.discount_percentage
                                    }
                                    onChange={(e) => {
                                      setSettingCompanyData({
                                        ...settingCompanyData,
                                        discount_percentage: e.target.value,
                                      });
                                    }}
                                    name={`discount_percentage`}
                                    maxLength={3}
                                    placeholder="Discount Percentage(%)"
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label">
                                {"Register Number dismetal"}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div d-flex">
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={
                                      settingCompanyData.reg_number_dismantler
                                    }
                                    onChange={(e) => {
                                      setSettingCompanyData({
                                        ...settingCompanyData,
                                        reg_number_dismantler: e.target.value,
                                      });
                                    }}
                                    name={`reg_number_dismantler`}
                                    placeholder="Register Number dismetal"
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </div>
                          <div className="col-md-3">
                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                Print Label{" "}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={
                                        settingCompanyData.print_label === 1
                                      }
                                      onChange={handleCheckboxChangePrint}
                                    />
                                    <label className="form-check-label">
                                      Print Label
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </FormGroup>
                          </div>

                          <div className="col-md-3">
                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                VAT included{" "}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={
                                        settingCompanyData.vat_included === 1
                                      }
                                      onChange={handleCheckboxChangeVat}
                                    />
                                    <label className="form-check-label">
                                      VAT included{" "}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </FormGroup>
                          </div>

                          <div className="col-md-6">
                            <FormGroup>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                Is enable ecommerce access{" "}
                              </Label>
                              <div className="col-xl-12">
                                <div className="input-div">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={
                                        settingCompanyData?.is_enable_ecommerce_access ===
                                        1
                                      }
                                      onChange={handleCheckboxChangeProfile}
                                    />
                                    <label className="form-check-label">
                                      E-commerce Products{" "}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </FormGroup>
                          </div>

                          <div className="col-md-6">
                            <div>
                              <Label className="col-form-label ml-2 Label-my form-label form-label">
                                Color Picker{" "}
                              </Label>
                              <SketchPicker
                                color={color}
                                onChange={handleChangeColor}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <Label className="col-form-label ml-2 Label-my form-label form-label">
                              {"Image"}
                            </Label>
                            <div className="avatar-upload">
                              <div className="avatar-edit">
                                <div className="input-div">
                                  <input
                                    className="inputFile"
                                    type="file"
                                    id="imageUploads"
                                    onChange={handleFileChangeDefualt}
                                    name="file"
                                    accept=".jpg,.jpeg,.png"
                                  />
                                </div>
                                <label htmlFor="imageUploads"></label>
                              </div>
                              <div className="avatar-preview profile-user">
                                <img
                                  src={previewUrlDefualt}
                                  // className="preview-image"

                                  style={{
                                    maxHeight: "100%",
                                    maxWidth: "100%",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="row justify-content-start"
                          style={{ marginTop: "10px" }}
                        >
                          <div class="accordion">
                            <div class="accordion-item">
                              <h2
                                class="accordion-header"
                                id="printerSettingsHeading"
                              >
                                <div
                                  class="accordion-button"
                                  style={{ background: "#81bb42" }}
                                  aria-expanded="false"
                                  aria-controls="printerSettings"
                                >
                                  Printer Settings
                                </div>
                              </h2>
                              <div
                                id="collapseOne"
                                class="accordion-collapse collapse show"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample"
                              >
                                <div class="accordion-body">
                                  <div className="row">
                                    <div
                                      className="col-md-4"
                                      style={{ display: "none" }}
                                    >
                                      {/* <FormGroup>
                                        <Label className="col-form-label ml-2 Label-my form-label">
                                          {"Which Printers"}
                                        </Label>
                                        <div style={{ display: "flex" }}>
                                          <div className="form-check">
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="flexRadio"
                                              id="flexRadio1"
                                              value="1"
                                              checked={selectedRadio === "1"}
                                              onChange={handleRadioChange}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="flexRadio1"
                                            >
                                              Zebra Label
                                            </label>
                                          </div>

                                          <div
                                            className="form-check"
                                            style={{ marginLeft: "10px" }}
                                          >
                                            <input
                                              className="form-check-input"
                                              type="radio"
                                              name="flexRadio"
                                              id="flexRadio2"
                                              value="2"
                                              checked={selectedRadio === "2"}
                                              onChange={handleRadioChange}
                                            />
                                            <label
                                              className="form-check-label"
                                              htmlFor="flexRadio2"
                                            >
                                              Brothers Label
                                            </label>
                                          </div>
                                        </div>
                                      </FormGroup> */}
                                    </div>

                                    <div
                                      className="col-md-4"
                                      style={{ display: "none" }}
                                    >
                                      <FormGroup>
                                        <Label className="col-form-label ml-2 Label-my form-label">
                                          {"Printer Name"}
                                        </Label>
                                        <div className="col-xl-12">
                                          <div className="input-div">
                                            <input
                                              className="form-control"
                                              type="text"
                                              value={
                                                settingCompanyData.printer_name
                                              }
                                              onChange={(e) => {
                                                setSettingCompanyData({
                                                  ...settingCompanyData,
                                                  printer_name: e.target.value,
                                                });
                                              }}
                                              name={`printer_name`}
                                              placeholder="Printer Name"
                                            />
                                          </div>
                                        </div>
                                      </FormGroup>
                                    </div>

                                    <div className="col-md-4">
                                      <FormGroup>
                                        <Label className="col-form-label ml-2 Label-my form-label">
                                          {"Paper Size"}
                                        </Label>
                                        <div className="col-xl-12">
                                          <div className="input-div">
                                            <input
                                              className="form-control"
                                              type="text"
                                              value={
                                                settingCompanyData.paper_size
                                              }
                                              onChange={(e) => {
                                                setSettingCompanyData({
                                                  ...settingCompanyData,
                                                  paper_size: e.target.value,
                                                });
                                              }}
                                              name={`paper_size`}
                                              placeholder="Paper Size"
                                            />
                                          </div>
                                        </div>
                                      </FormGroup>
                                    </div>

                                    <div className="col-md-4">
                                      <FormGroup>
                                        <Label className="col-form-label ml-2 Label-my form-label">
                                          {"Printer API"}
                                        </Label>
                                        <div className="col-xl-12">
                                          <div className="input-div">
                                            <input
                                              className="form-control"
                                              type="text"
                                              value={
                                                settingCompanyData.printer_api
                                              }
                                              onChange={(e) => {
                                                setSettingCompanyData({
                                                  ...settingCompanyData,
                                                  printer_api: e.target.value,
                                                });
                                              }}
                                              name={`printer_api`}
                                              placeholder="Printer API"
                                            />
                                          </div>
                                        </div>
                                      </FormGroup>
                                    </div>
                                    <div
                                      className="col-md-4"
                                      style={{ marginTop: "40px" }}
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={handleSettingPrintLabel}
                                      >
                                        Print Test Label{" "}
                                        <i class="bi bi-check2"></i>
                                      </button>
                                    </div>
                                    <div className="col-md-4"></div>
                                    <div className="col-md-4"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col"></div>
                      <div className="col">
                        <div
                          className="d-flex justify-content-end"
                          style={{ padding: "10px" }}
                        >
                          <button type="submit" className="btn btn-primary">
                            Submit <i class="bi bi-check2"></i>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="card-footer"></div>
                  </Form>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={showModal}>
          <ModalHeader>Header Image</ModalHeader>
          <ModalBody>
            <img
              src={previewUrl}
              alt="Preview"
              style={{ width: "100%", height: "auto" }}
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={showModalAbout}>
          <ModalHeader>About Image</ModalHeader>
          <ModalBody>
            <img
              src={previewUrlAbout}
              alt="Preview"
              style={{ width: "100%", height: "auto" }}
            />
          </ModalBody>
          <ModalFooter>
            <Button variant="secondary" onClick={handleCloseAbout}>
              Close
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={companystatus}>
          <ModalHeader>Change Plan</ModalHeader>
          <Form method={`post`} onSubmit={handleChangePlanSubmit}>
            <ModalBody>
              <div className="row">
                <div className="col-md-12">
                  <FormGroup>
                    <Label className="col-form-label Label-my ml-2">
                      {"Plan"}
                    </Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <Select
                          options={SubscriptionList}
                          value={SubscriptionList.find(
                            (option) =>
                              option.value ===
                              Number(CompanyPlanData.subscription_id)
                          )}
                          onChange={handleSelectPlanChange}
                          isSearchable={true}
                          isClearable={true}
                          placeholder={"Select Plan"}
                        />
                      </div>
                    </div>
                  </FormGroup>
                </div>

                <div className="col-md-12">
                  <FormGroup>
                    <Label className="col-form-label ml-2 Label-my">
                      {"Expiry Date"}
                    </Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <input
                          type="date"
                          id="subscription_expiry"
                          className="form-control"
                          value={formatDate(
                            CompanyPlanData.subscription_expiry
                          )}
                          onChange={(e) => {
                            SetCompanyPlanData({
                              ...CompanyPlanData,
                              subscription_expiry: e.target.value, // Directly set the YYYY-MM-DD format
                            });
                          }}
                          placeholder="Expiry Date"
                        />
                      </div>
                    </div>
                  </FormGroup>
                </div>

                <div className="col-md-12">
                  <FormGroup>
                    <Label className="col-form-label ml-2 Label-my">
                      {"is Subscribed"}
                    </Label>
                    <div className="col-xl-12">
                      <div className="input-div">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={CompanyPlanData?.is_subscribed === 1}
                            onChange={handleCheckboxChangeIsSubscribe}
                          />
                          <label className="form-check-label">
                            is Subscribed{" "}
                          </label>
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                class="btn btn-block btn-primary"
                onClick={handleCloseCompany}
                style={{ width: "100px", marginTop: "10px" }}
              >
                Close <i class="bi bi-x-lg"></i>
              </button>
              <button
                type="submit"
                class="btn btn-block btn-primary"
                style={{ width: "100px", marginTop: "10px" }}
              >
                Submit <i class="bi bi-check2"></i>
              </button>
            </ModalFooter>
          </Form>
        </Modal>
      </section>
    </div>
  );
};

export default Settings;
