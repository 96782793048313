import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Dropdown from "react-bootstrap/Dropdown";
import {
  createCompanyEcommerceHash,
  deleteCompanyEcommerceHash,
  getCompanyEcommerceHashList,
  updateCompanyEcommerceHash,
} from "../../services/CompanyEcommerceHashService";
import { useTranslation } from "react-i18next";

const CompanyEcommerceHash = () => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);
  const { t } = useTranslation();

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);

  const [partGroupList, setPartGroupList] = useState([]);

  const [delModal, setDelModal] = useState(false);

  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [delpart, setDelPart] = useState("");
  const [modifyModal, setModifyModal] = useState(false);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [partGroupData, setPartGroupData] = useState({
    id: "",
    ecommerce_name: "",
    token: "",
  });
  let errorsObj = {
    ecommerce_name: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const handleAddModal = () => {
    setModifyModal(true);
    setPartGroupData("");
  };

  const handleCloseModal = () => {
    setModifyModal(false);
    setPartGroupData("");
    setErrors("");
  };

  useEffect(() => {}, []);

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const fetchPartLocation = () => {
    let data = { ...params };
    getCompanyEcommerceHashList(data)
      .then((response) => {
        setPartGroupList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };
      fetchPartLocation(data);
    },
    [params]
  );

  const onDeleteModalClose = () => {
    setDelModal(false);
  };

  const onEditPartType = (e, item) => {
    setModifyModal(true);
    setPartGroupData({
      id: item?.id,
      ecommerce_name: item?.ecommerce_name,
      token: item?.token,
    });
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelPart(item.id);
    setDelModal(true);
  };

  const onDestroyPartType = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { id: delpart };
    deleteCompanyEcommerceHash(data)
      .then((response) => {
        let data = { ...params };

        fetchPartLocation(data);
        toast.success(response.data.message);
        setDelPart("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handleCompanyEcommerce = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };
    if (!partGroupData.ecommerce_name) {
      errorObj.ecommerce_name = `name is required.`;
      error = true;
    }

    setErrors(errorObj);
    if (error) return;

    const generateToken = () => {
      return [...Array(34)].map(() => Math.random().toString(36)[2]).join("");
    };

    const token = generateToken();

    setIsModifyButtonLoad(true);
    let data = {
      name: partGroupData.ecommerce_name,
      token: token,
    };

    let updateData = {
      id: partGroupData.id,
      name: partGroupData.ecommerce_name,
      token: partGroupData?.token,
    };

    if (partGroupData?.id) {
      updateCompanyEcommerceHash(updateData)
        .then((response) => {
          toast.dismiss();
          toast.success(response.data.message);
          let data = { ...params };
          fetchPartLocation(data);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
        })
        .catch((err) => {
          toast.dismiss();
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    } else {
      createCompanyEcommerceHash(data)
        .then((response) => {
          toast.dismiss();
          toast.success(response.data.message);
          let data = { ...params };
          fetchPartLocation(data);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
        })
        .catch((err) => {
          toast.dismiss();
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    }
  };

  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {/* <h1 className="page-title">Part Type</h1> */}
              </div>
              <div className="col-sm-6"></div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        <section className="content">
          <div className="container-fluid container-custom-height-new ">
            <div className="row">
              <div className="col-md-12">
                <div className="card fuelCard">
                  <div className="card-header">
                    <div
                      className="card-title m-0 float-left"
                      style={{ width: "50%" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        value={params.search}
                        onChange={(e) => handleParams(e, "search")}
                        placeholder="Search"
                      ></input>
                    </div>
                    <div className="card-title m-0 float-right d-flex">
                      {/* {RolePermission?.findIndex(
                          (e) => e.permission_name === "add_part_type"
                        ) > -1 && ( */}
                      <button
                        type="button"
                        className="btn  btn-icon-text mr-2"
                        onClick={handleAddModal}
                      >
                        <i className="bi bi-plus-lg"></i>
                        <span className="ms-2">{t("Add")}</span>
                      </button>
                      {/* )} */}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="vehicleThBg table-responsive table-responsive-vehicle">
                      <table className="table table-bordered rounded-table table-striped table-hover">
                        <thead>
                          <tr>
                            <th style={{ width: "1%" }}>#</th>
                            <th
                              className="col-form-label ml-2 Label-my form-label form-label"
                              style={{ width: "10%" }}
                            >
                              {t("ecommerce_name")}
                            </th>

                            <th
                              className="col-form-label ml-2 Label-my form-label form-label"
                              style={{ width: "10%" }}
                            >
                              {t("token")}
                            </th>
                            <th
                              className="col-form-label ml-2 Label-my form-label form-label"
                              style={{ textAlign: "center", width: "0%" }}
                            >
                              {t("action")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {partGroupList?.map((item, index) => (
                            <tr key={item.id}>
                              {" "}
                              <td>{++index}</td>
                              <td>{item?.ecommerce_name}</td>
                              <td>{item?.token}</td>
                              <td>
                                <div className="d-flex">
                                  <Dropdown
                                    className="iconDropdown"
                                    onToggle={(isOpen) => {
                                      if (isOpen) {
                                        // This logic runs when the dropdown is opened
                                        const tableResponsive =
                                          document.querySelector(
                                            ".table-responsive"
                                          );
                                        if (tableResponsive) {
                                          tableResponsive.style.overflowX =
                                            "inherit !important";
                                          tableResponsive.style.overflowY =
                                            "inherit !important";
                                        }
                                      }
                                    }}
                                  >
                                    <Dropdown.Toggle
                                      variant="success"
                                      id="dropdown-basic"
                                      className="iconToggle"
                                    >
                                      <i className="bi bi-three-dots-vertical"></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        href="#/action-1"
                                        className="d-flex"
                                        onClick={(e) => onEditPartType(e, item)}
                                      >
                                        <div className="edit-btn">
                                          <i className="bi bi-pencil"></i>
                                        </div>
                                        <span>{t("edit")}</span>
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        href="#"
                                        className="d-flex align-items-center"
                                        onClick={(e) =>
                                          onDeleteModalOpen(e, item)
                                        }
                                      >
                                        <div className="trash-btn">
                                          <i className="bi bi-trash3"></i>
                                        </div>
                                        <span className="ms-2">
                                          {t("delete")}
                                        </span>
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </td>
                            </tr>
                          ))}
                          {partGroupList && partGroupList.length === 0 && (
                            <tr>
                              <td colSpan={4} className="text-center">
                                {t("no_record_found")}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer card-footer-part clearfix">
            <div className="pagination pagination-sm m-0 float-right">
              <Pagination
                activePage={params.page}
                itemsCountPerPage={parseInt(params.limit)}
                totalItemsCount={parseInt(totalRecords)}
                pageRangeDisplayed={5}
                itemClass={`page-item`}
                linkClass={`page-link`}
                onChange={(e) => handleParams(e, "pagination")}
              />
            </div>
          </div>
        </section>
      </div>
      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete E-commerce Products`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this E-commerce Products?`}</p>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDelButtonLoad}
            onClick={onDeleteModalClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            {t("close")} <i class="bi bi-x-lg"></i>
          </button>

          {!isDelButtonLoad && (
            <button
              type="button"
              className="btn btn-block bg-red"
              style={{ width: "100px" }}
              onClick={onDestroyPartType}
            >
              {t("delete")} <i class="bi bi-trash3"></i>
            </button>
          )}
          {isDelButtonLoad && (
            <button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
            </button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyModal}>
        <ModalHeader>{t("company_ecommerce_hash")}</ModalHeader>
        <Form method={`post`} onSubmit={handleCompanyEcommerce}>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {t("ecommerce_name")}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={partGroupData.ecommerce_name}
                        onChange={(e) => {
                          setPartGroupData({
                            ...partGroupData,
                            ecommerce_name: e.target.value,
                          });
                        }}
                        name={`ecommerce_name`}
                        maxLength={50}
                        placeholder="Ecommerce Name"
                      />
                    </div>
                    {errors.ecommerce_name && (
                      <span className="input-error">
                        {errors.ecommerce_name}
                      </span>
                    )}
                  </div>
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-primary"
              disabled={isModifyButtonLoad}
              onClick={handleCloseModal}
              style={{ width: "100px", marginTop: "10px" }}
            >
              {t("close")} <i class="bi bi-x-lg"></i>
            </button>
            {!isModifyButtonLoad && (
              <button
                type="submit"
                class="btn btn-block btn-primary"
                style={{ width: "100px", marginTop: "10px" }}
              >
                {t("submit")} <i class="bi bi-check2"></i>
              </button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default CompanyEcommerceHash;
