import { useState } from "react";
import { actionAddContactUs } from "../../services/settingsServices";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { Form } from "reactstrap";
import validator from "validator";
import { useTranslation } from "react-i18next";

const ContactForm = ({ settingDetails }) => {
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });
  const { t } = useTranslation();

  let errorsObj = {
    name: "",
    email: "",
    mobile: "",
    message: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const handleContact = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };
    if (!contactData.name) {
      errorObj.name = toast.error(`Name is required`);
      error = true;
    } else if (!contactData.email) {
      errorObj.email = toast.error("Email address is required");
      error = true;
    } else if (!validator.isEmail(contactData.email)) {
      errorObj.email = toast.error("Please enter valid email address");

      error = true;
    } else if (!contactData.mobile) {
      errorObj.mobile = toast.error(`Mobile number is required.`);
      error = true;
    } else if (!validator.isMobilePhone(contactData.mobile, "any")) {
      errorObj.mobile = toast.error(`Mobile number is invalid.`);
      error = true;
    } else if (!/^\d+$/.test(contactData.mobile)) {
      errorObj.mobile = toast.error(`Mobile number must contain only numbers.`);
      error = true;
    } else if (!contactData.message) {
      errorObj.message = toast.error(`Message is required.`);
      error = true;
    }

    setErrors(errorObj);
    Utils.focusError();

    if (error) return;

    let data = {
      name: contactData?.name,
      email: contactData?.email,
      mobile: contactData?.mobile,
      message: contactData?.message,
    };

    actionAddContactUs(data)
      .then((response) => {
        toast.success(response.data.message);
        setContactData({
          name: "",
          email: "",
          mobile: "",
          message: "",
        });
      })
      .catch((err) => {});
  };

  return (
    <>
      <div className="container mt-5 contactForm" id="contactUS">
        <img src="img/shape.png" className="square" alt="" />
        <div className="form">
          <div className="contact-info">
            <h3 className="title">Let's get in touch</h3>
            <p className="text">
              {settingDetails?.contact_description
                ? settingDetails?.contact_description
                : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe dolorum adipisci recusandae praesentium dicta!"}
            </p>

            <div className="info">
              <div className="information">
                <i className="bi bi-geo-alt"></i>

                <span className="ms-2">
                  {settingDetails?.address
                    ? settingDetails?.address
                    : "92 Cherry Drive Uniondale,NY 11553"}
                </span>
              </div>
              <div className="information">
                <i className="bi bi-envelope"></i>
                <span className="ms-2">
                  {settingDetails?.email
                    ? settingDetails?.email
                    : "lorem123@gmail.com"}
                </span>
              </div>
              <div className="information">
                <i className="bi bi-phone"></i>
                <span className="ms-2">
                  {settingDetails?.mobile
                    ? settingDetails?.mobile
                    : "852145212"}
                </span>
              </div>
            </div>

            <div className="social-media">
              {settingDetails?.facebook_url ||
                settingDetails?.youtube_url ||
                settingDetails?.instagram_url ||
                (settingDetails?.whatsapp_url && (
                  <span>Connect with us :</span>
                ))}
              <div className="social-icons">
                {settingDetails?.facebook_url && (
                  <a href={settingDetails?.facebook_url}>
                    <i className="bi bi-facebook"></i>
                  </a>
                )}
                {settingDetails?.youtube_url && (
                  <a href={settingDetails?.youtube_url}>
                    <i className="bi bi-youtube"></i>
                  </a>
                )}
                {settingDetails?.instagram_url && (
                  <a href={settingDetails?.instagram_url}>
                    <i className="bi bi-instagram"></i>
                  </a>
                )}
                {settingDetails?.whatsapp_url && (
                  <a href={settingDetails?.whatsapp_url}>
                    <i className="bi bi-whatsapp"></i>
                  </a>
                )}
              </div>
            </div>
          </div>

          <div className="contact-form">
            <span className="circle one"></span>
            <span className="circle two"></span>
            <Form method={`post`} onSubmit={handleContact}>
              <h3 className="title">Contact us</h3>
              <div className="input-container">
                <label
                  for=""
                  className="col-form-label ml-2 Label-my form-label form-label"
                >
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  className="form-control input-search form-control"
                  value={contactData.name}
                  onChange={(e) => {
                    setContactData({
                      ...contactData,
                      name: e.target.value,
                    });
                  }}
                  maxLength={40}
                  placeholder="Name"
                />
              </div>
              <div className="input-container">
                <label
                  for=""
                  className="col-form-label ml-2 Label-my form-label form-label"
                >
                  {t("e_mail")}
                </label>
                <input
                  type="email"
                  className="form-control input-search form-control"
                  value={contactData.email}
                  onChange={(e) => {
                    setContactData({
                      ...contactData,
                      email: e.target.value,
                    });
                  }}
                  maxLength={40}
                  placeholder="Email"
                />
              </div>
              <div className="input-container">
                <label
                  for=""
                  className="col-form-label ml-2 Label-my form-label form-label"
                >
                  Phone
                </label>
                <input
                  type="text"
                  className="form-control input-search form-control"
                  value={contactData.mobile}
                  onChange={(e) => {
                    setContactData({
                      ...contactData,
                      mobile: e.target.value,
                    });
                  }}
                  name="mobile"
                  maxLength={10}
                  placeholder="Phone"
                />
              </div>
              <div className="input-container textarea">
                <label
                  for=""
                  className="col-form-label ml-2 Label-my form-label form-label"
                >
                  Message
                </label>
                <textarea
                  name="message"
                  className="form-control input-search form-control"
                  value={contactData.message}
                  onChange={(e) => {
                    setContactData({
                      ...contactData,
                      message: e.target.value,
                    });
                  }}
                  maxLength={100}
                  placeholder="Message"
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary btnSubmitCU">
                Submit <i className="bi bi-check2-square"></i>
              </button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactForm;
