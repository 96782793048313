import axiosInstance from "./AxiosInstance";

export function getPartDetailsInternalDetails(data) {
  return axiosInstance.post(`/parts-details-internal`, data);
}

export function getPartUpdateLocation(data) {
  return axiosInstance.post(`/update-parts-locations`, data);
}

export function getPartListAssociateLocation(data) {
  return axiosInstance.post(`/part-list-associate-location`, data);
}
