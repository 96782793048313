import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Utils from "../../utils";
import {
  getpublicPartList,
  getVehiclePartList,
} from "../../services/VehicleService";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { partInvenoryPartDelete } from "../../services/PartsInventory";
import { useTranslation } from "react-i18next";

const PartDetailsPublic = () => {
  const state = useLocation();
  const { t } = useTranslation();

  const { uuid } = useParams(); // Get the uuid from URL

  const [vehiclePartDetails, setVehiclePartDetails] = useState([]);
  const [delModal, setDelModal] = useState(false);
  const [delpart, setDelPart] = useState("");

  useEffect(() => {
    if (uuid) {
      let data = {
        uuid: uuid,
      };

      getpublicPartList(data)
        .then((response) => {
          setVehiclePartDetails(response.data.data);
          //   setTotalRecords(response.data);
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
        });
    }
  }, [uuid]);

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelPart(item.id);
    setDelModal(true);
  };

  const onDestroyPartType = (e) => {
    e.preventDefault();

    let data = { id: delpart, delete_type: 1 };
    partInvenoryPartDelete(data)
      .then((response) => {
        if (uuid) {
          let data = {
            uuid: uuid,
          };

          getpublicPartList(data)
            .then((response) => {
              setVehiclePartDetails(response.data.data);
              //   setTotalRecords(response.data);
            })
            .catch((err) => {
              toast.error(Utils.getErrorMessage(err));
            });
        }
        toast.success(response.data.message);
        setDelPart("");
        setDelModal(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const onDestroyassociatePartType = (e) => {
    e.preventDefault();

    let data = { id: delpart, delete_type: 2 };
    partInvenoryPartDelete(data)
      .then((response) => {
        if (uuid) {
          let data = {
            uuid: uuid,
          };

          getpublicPartList(data)
            .then((response) => {
              setVehiclePartDetails(response.data.data);
              //   setTotalRecords(response.data);
            })
            .catch((err) => {
              toast.error(Utils.getErrorMessage(err));
            });
        }
        toast.success(response.data.message);
        setDelPart("");
        setDelModal(false);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const onDeleteModalClose = () => {
    setDelModal(false);
  };

  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">{/* <h1>Vehicle</h1> */}</div>
              <div className="col-sm-6"></div>
            </div>
          </div>
        </section>
        <section className="content">
          <>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="card fuelCard">
                    <div className="cardHeader card-header card-header-custom">
                      <p>Part Details</p>
                    </div>
                    <div className="card-body card-body-custom">
                      <div className="row justify-content-start">
                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b> Part Name:</b>{" "}
                            <address>
                              {vehiclePartDetails?.part_name || "-"}
                            </address>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="invoice-col">
                            <b> {t("Internal Id")} :</b>{" "}
                            <address>
                              {vehiclePartDetails?.parts_internal_id || "-"}
                            </address>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b> Part Code:</b>{" "}
                            <address>
                              {vehiclePartDetails?.part_code || "-"}
                            </address>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b> Description:</b>{" "}
                            <address>
                              {vehiclePartDetails?.part_description || "-"}
                            </address>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b> Original Price:</b>{" "}
                            <address>
                              € {vehiclePartDetails?.part_original_price || "0"}
                            </address>
                          </div>
                        </div>
                        {/* <div className="col-sm-3">
                          <div className="invoice-col">
                            <b> Cost Price:</b>{" "}
                            <address>
                              € {vehiclePartDetails?.part_cost_price}
                            </address>
                          </div>
                        </div> */}
                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b> Part Price:</b>{" "}
                            <address>
                              € {vehiclePartDetails?.part_price || "0"}
                            </address>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b>
                              {t("brand")}
                              {t("name")}:
                            </b>{" "}
                            <address>
                              {vehiclePartDetails.brand_name || "-"}
                            </address>
                          </div>
                        </div>
                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b>Condition Name:</b>{" "}
                            <address>
                              {vehiclePartDetails?.condition_name || "-"}
                            </address>
                          </div>
                        </div>

                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b>Inventory Status Name:</b>{" "}
                            <address>
                              {vehiclePartDetails?.inventory_status_name || "-"}
                            </address>
                          </div>
                        </div>

                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b>Height:</b>{" "}
                            <address>
                              {vehiclePartDetails?.parts_height || "-"}
                            </address>
                          </div>
                        </div>

                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b>Length:</b>{" "}
                            <address>
                              {vehiclePartDetails?.parts_length || "-"}
                            </address>
                          </div>
                        </div>

                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b>Width:</b>{" "}
                            <address>
                              {vehiclePartDetails?.parts_width || "-"}
                            </address>
                          </div>
                        </div>

                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b>Weight:</b>{" "}
                            <address>
                              {vehiclePartDetails?.parts_weight || "-"}
                            </address>
                          </div>
                        </div>

                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b>Print Label:</b>{" "}
                            <address>
                              {vehiclePartDetails?.print_label || "-"}
                            </address>
                          </div>
                        </div>

                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b>Modal Name:</b>{" "}
                            <address>
                              {vehiclePartDetails?.vehicle_model_name || "-"}
                            </address>
                          </div>
                        </div>

                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b>Year:</b>{" "}
                            <address>
                              {vehiclePartDetails?.initial_year || "-"}
                            </address>
                          </div>
                        </div>

                        <div className="col-sm-3">
                          <div className="invoice-col">
                            <b>Make Name:</b>{" "}
                            <address>
                              {vehiclePartDetails?.vehicle_make_name || "-"}
                            </address>
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="invoice-col">
                            <b>Part Rating:</b>{" "}
                            <address>
                              {vehiclePartDetails?.part_rating || 0}
                            </address>
                          </div>
                        </div>
                      </div>
                    </div>

                    {vehiclePartDetails?.parts_damage?.length > 0 && (
                      <>
                        <div className="card-custom-heading">
                          <p>Part Damage List</p>
                        </div>
                        <div className="card-body card-body-custom">
                          <div className="vehicleThBg table-responsive">
                            <table className="table table-bordered rounded-table table table-striped table-hover">
                              <thead>
                                <tr>
                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                    Damage Description
                                  </th>

                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                    Location Damage
                                  </th>

                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                    Type Damage
                                  </th>

                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                    Damage Rating
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {vehiclePartDetails?.parts_damage?.map(
                                  (items) => {
                                    return (
                                      <>
                                        <tr>
                                          <td>
                                            {items?.damage_description || "-"}
                                          </td>
                                          <td>
                                            {items?.location_damage_name || "-"}
                                          </td>
                                          <td>
                                            {items?.type_damage_name || "-"}
                                          </td>
                                          <td>{items?.damage_rating || "-"}</td>
                                        </tr>
                                      </>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                    {vehiclePartDetails?.part_qty_list?.length > 0 && (
                      <>
                        <div className="card-custom-heading">
                          <p>Part Quntity List</p>
                        </div>
                        <div className="card-body card-body-custom">
                          <div className="vehicleThBg table-responsive">
                            <table className="table table-bordered rounded-table table table-striped table-hover">
                              <thead>
                                <tr>
                                  {/* <th className="col-form-label ml-2 Label-my form-label form-label">
                                    Rating
                                  </th> */}
                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                    Location Name
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {vehiclePartDetails?.part_qty_list?.map(
                                  (vehiclePartDetailss) => {
                                    return (
                                      <>
                                        <tr>
                                          {/* <td>
                                            {vehiclePartDetailss?.part_rating}
                                          </td> */}
                                          <td>
                                            {vehiclePartDetailss?.location_name ||
                                              "-"}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}

                    {vehiclePartDetails?.parts_reference?.length > 0 && (
                      <>
                        <div className="card-custom-heading">
                          <p>Part Reference List</p>
                        </div>
                        <div className="card-body card-body-custom">
                          <div className="vehicleThBg table-responsive">
                            <table className="table table-bordered rounded-table table table-striped table-hover">
                              <thead>
                                <tr>
                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                    Condition Name
                                  </th>
                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                    Type Name
                                  </th>

                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                    Is Main
                                  </th>

                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                    Reference Code{" "}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {vehiclePartDetails?.parts_reference?.map(
                                  (vehiclePartDetailss) => {
                                    return (
                                      <>
                                        <tr>
                                          <td>
                                            {
                                              vehiclePartDetailss?.condition_name
                                            }
                                          </td>
                                          <td>
                                            {vehiclePartDetailss?.type_name}
                                          </td>
                                          <td>
                                            {vehiclePartDetailss?.is_main}
                                          </td>
                                          <td>
                                            {
                                              vehiclePartDetailss?.reference_code
                                            }
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}

                    {vehiclePartDetails?.parts_associate?.length > 0 && (
                      <>
                        <div className="card-custom-heading">
                          <p>Part Associate</p>
                        </div>
                        <div className="card-body card-body-custom">
                          <div className="vehicleThBg table-responsive">
                            <table className="table table-bordered rounded-table table table-striped table-hover">
                              <thead>
                                <tr>
                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                    Modal Name
                                  </th>
                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                    Make Name
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {vehiclePartDetails?.parts_associate?.map(
                                  (items) => {
                                    return (
                                      <>
                                        <tr>
                                          <td>{items?.model_name}</td>
                                          <td>{items?.make_name}</td>

                                          {/* <td>
                                                  <div
                                                    className="button-group"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      gap: "10px",
                                                    }}
                                                  >
                                                    <div className="trash-btn">
                                                      <a
                                                        className="delete-btn"
                                                        onClick={(e) =>
                                                          onDeleteModalOpen(
                                                            e,
                                                            item
                                                          )
                                                        }
                                                        style={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                        }}
                                                      >
                                                        <i className="bi bi-trash3"></i>
                                                      </a>
                                                    </div>
                                                    <a
                                                      className="edit-btn"
                                                      href="#"
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                      }}
                                                      onClick={() =>
                                                        navigate(
                                                          `/part/details/main/${item.id}`,
                                                          { state: item }
                                                        )
                                                      }
                                                    >
                                                      <i className="bi bi-card-checklist"></i>
                                                    </a>
                                                  </div>
                                                </td> */}
                                        </tr>
                                      </>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}

                    {vehiclePartDetails?.parts_assoicate_list?.length > 0 && (
                      <>
                        <div className="card-custom-heading">
                          <p>Part Associate List</p>
                        </div>
                        <div className="card-body card-body-custom">
                          <div className="vehicleThBg table-responsive">
                            <table className="table table-bordered rounded-table table table-striped table-hover">
                              <thead>
                                <tr>
                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                    Part Name
                                  </th>
                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                    Modal Name
                                  </th>
                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                    Make Name
                                  </th>

                                  <th className="col-form-label ml-2 Label-my form-label form-label">
                                    {t("action")}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {vehiclePartDetails?.parts_assoicate_list?.map(
                                  (items) => {
                                    return (
                                      <>
                                        <tr>
                                          <td>{items?.part_name}</td>

                                          <td>{items?.model_name}</td>
                                          <td>{items?.make_name}</td>
                                          <td>
                                            <div className="trash-btn">
                                              <a
                                                className="delete-btn"
                                                onClick={(e) =>
                                                  onDeleteModalOpen(e, items)
                                                }
                                              >
                                                <i className="bi bi-trash3"></i>
                                              </a>
                                            </div>
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}

                    {vehiclePartDetails?.image_list?.length > 0 && (
                      <>
                        <div className="card-custom-heading">
                          <p>Part images</p>
                        </div>
                        <div className="card-body card-body-custom">
                          <div className="row justify-content-start">
                            {vehiclePartDetails?.image_list?.map((image) => {
                              return (
                                <>
                                  <div className="col-sm-3">
                                    <div className="column-img">
                                      <img src={image?.image_url} alt="" />
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </>
                    )}

                    {vehiclePartDetails?.video_list?.length > 0 && (
                      <>
                        <div className="card-custom-heading">
                          <p>Part Video</p>
                        </div>
                        <div className="card-body card-body-custom">
                          <div className="row justify-content-start">
                            {vehiclePartDetails?.video_list?.map((items) => {
                              return (
                                <>
                                  <div className="col-sm-3">
                                    <div className="column-img">
                                      <video
                                        src={items.video_url}
                                        width="200"
                                        controls
                                        style={{
                                          borderRadius: "5px",
                                          margin: "5px",
                                          marginLeft: "60px",
                                        }}
                                      >
                                        Your browser does not support the video
                                        tag.
                                      </video>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </>
                    )}

                    {vehiclePartDetails?.image_list &&
                      vehiclePartDetails?.image_list.some(
                        (item) => item.damage_list.length > 0
                      ) && (
                        <>
                          <div className="card-custom-heading">
                            <p>Part Damage images</p>
                          </div>
                          <div className="card-body card-body-custom">
                            <div className="row justify-content-start">
                              <table className="table table-bordered rounded-table table table-striped table-hover">
                                <tbody>
                                  {vehiclePartDetails.image_list
                                    ?.filter(
                                      (item) => item.damage_list.length > 0
                                    )
                                    .map((item, index) => (
                                      <React.Fragment key={index}>
                                        <tr>
                                          <td>
                                            <div className="col-sm-3">
                                              <div className="column-img">
                                                <img
                                                  src={item?.image_url}
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                          </td>
                                        </tr>

                                        {item?.damage_list?.map(
                                          (damage, damageIndex) => (
                                            <tr key={damageIndex}>
                                              <td
                                                colSpan="2"
                                                style={{
                                                  paddingLeft: "50px",
                                                }}
                                              >
                                                <strong>
                                                  Damage Description:
                                                </strong>{" "}
                                                {damage.damage_description}{" "}
                                                <br />
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </React.Fragment>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                      )}

                    {vehiclePartDetails?.video_list &&
                      vehiclePartDetails?.video_list.some(
                        (item) => item.damage_list.length > 0
                      ) && (
                        <>
                          <div className="card-custom-heading">
                            <p>Part Damage images</p>
                          </div>
                          <div className="card-body card-body-custom">
                            <div className="row justify-content-start">
                              <table className="table table-bordered rounded-table table table-striped table-hover">
                                <tbody>
                                  {vehiclePartDetails?.video_list
                                    ?.filter(
                                      (item) => item.damage_list.length > 0
                                    )
                                    .map((item, index) => (
                                      <React.Fragment key={index}>
                                        <tr>
                                          <td>
                                            <div className="col-sm-3">
                                              <div className="column-img">
                                                <video
                                                  src={item.video_url}
                                                  width="200"
                                                  controls
                                                  style={{
                                                    borderRadius: "5px",
                                                    margin: "5px",
                                                  }}
                                                >
                                                  Your browser does not support
                                                  the video tag.
                                                </video>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>

                                        {item?.damage_list?.map(
                                          (damage, damageIndex) => (
                                            <tr key={damageIndex}>
                                              <td
                                                colSpan="2"
                                                style={{
                                                  paddingLeft: "50px",
                                                }}
                                              >
                                                <strong>
                                                  Damage Description:
                                                </strong>{" "}
                                                {damage.damage_description}{" "}
                                                <br />
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </React.Fragment>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </>
        </section>
      </div>

      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete Part`}</ModalHeader>
        <ModalBody>
          <p className={`mb-0`}>{`Are you sure you want to delete?`}</p>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            onClick={onDeleteModalClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>

          <button
            type="button"
            className="btn btn-block bg-red"
            style={{ width: "153px" }}
            onClick={onDestroyPartType}
          >
            Delete From Inventory <i class="bi bi-trash3"></i>
          </button>

          <button
            type="button"
            className="btn btn-block bg-red"
            style={{ width: "197px" }}
            onClick={onDestroyassociatePartType}
          >
            Delete from Associate Part <i class="bi bi-trash3"></i>
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PartDetailsPublic;
