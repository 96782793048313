import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import validator from "validator";
import {
  actionCityList,
  actionCountryList,
  actionStateList,
} from "../../services/CommonService";
import {
  getSubscriptionList,
  resetPasswordToken,
  signUp,
} from "../../services/AuthServices";
import Utils from "../../utils";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import Select from "react-select";

const Signup = ({
  signUpModal,
  setSignUpModal,
  setLoginModal,
  setSubscriptionModal,
  setSubscriptionList,
  setUserUUID,
  setUserCompanyId,
}) => {
  const [zipCode, setZipCode] = useState("");

  const [signupData, setSignUpData] = useState({
    country: "",
    state: "",
    city: "",
  });

  let signUpErrorsObj = {
    name: "",
    email: "",
    password: "",
    address: "",
    address_proof: "",
    company_registration_number: "",
    state: "",
    country: "",
    zipCode: "",
    city: "",
  };

  const [signUpErrors, setSignUpErrors] = useState(signUpErrorsObj);
  const [address, setAddress] = useState("");
  const [address_proof, setAddressProof] = useState("");
  const [company_registration_number, setCompanyRegistrationNumber] =
    useState("");

  const [selectedFileName, setSelectedFileName] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");

  const onLoginModal = (e) => {
    e.preventDefault();
    setSignUpModal(false);
    setLoginModal(true);
  };

  function LoadList() {
    getSubscriptionList()
      .then((response) => {
        setSubscriptionList(response.data.data);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  }

  useEffect(function () {
    actionCountryList()
      .then((response) => {
        response = response.data;
        let list = [];
        for (let i = 0; i < response.data.length; i++) {
          list.push({
            value: response.data[i].id,
            label: response.data[i].name,
          });
        }
        setCountryList(list);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (signupData?.country) {
      actionStateList({ country_id: signupData?.country })
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }
          setStateList(list);
        })
        .catch((err) => {});
    }
  }, [signupData]);

  useEffect(() => {
    if (signupData?.state) {
      actionCityList({ state_id: signupData?.state })
        .then((response) => {
          response = response.data;
          let list = [];
          for (let i = 0; i < response.data.length; i++) {
            list.push({
              value: response.data[i].id,
              label: response.data[i].name,
            });
          }
          setCityList(list);
        })
        .catch((err) => {});
    }
  }, [signupData]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setAddressProof(selectedFile);

    if (selectedFile) {
      setSelectedFileName(selectedFile.name);
    } else {
      setSelectedFileName("");
    }
  };

  const validateImage = (file) => {
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    if (!allowedTypes.includes(file.type)) {
      return false;
    }

    if (file.size > 5 * 1024 * 1024) {
      return false;
    }

    return true;
  };

  const onSignUpModalClose = (e) => {
    e.preventDefault();
    setSignUpModal(false);
  };

  const onSignUp = (e) => {
    e.preventDefault();
    let errorObj = { ...signUpErrorsObj };
    let error = false;

    if (!name) {
      errorObj.name = "Name is required";
      error = true;
    }

    if (!email) {
      errorObj.email = "Email address is required";
      error = true;
    } else if (!validator.isEmail(email)) {
      errorObj.email = "Please enter valid email address";
      error = true;
    }

    if (!password) {
      errorObj.password = "Password is required";
      error = true;
    }

    if (!address) {
      errorObj.address = "Address is required";
      error = true;
    }

    if (!address_proof) {
      errorObj.address_proof = "Address proof is required";
      error = true;
    } else if (!validateImage(address_proof)) {
      errorObj.address_proof =
        "Invalid file. Please upload a valid image. Size must be < 5mb";
      error = true;
    }

    if (!signupData.country) {
      errorObj.country = "Country is required";
      error = true;
    }
    if (!signupData.state) {
      errorObj.state = "State is required";
      error = true;
    }
    if (!signupData.city) {
      errorObj.city = "City is required";
      error = true;
    }
    if (!zipCode) {
      errorObj.zipCode = "ZipCode is required";
      error = true;
    }

    if (!company_registration_number) {
      errorObj.company_registration_number =
        "Company registration number is required";
      error = true;
    }

    setSignUpErrors(errorObj);
    if (error) return;

    const formData = new FormData();
    formData.append("company_name", name);
    formData.append("email", email);
    formData.append("address", address);
    formData.append("password", password);
    formData.append("company_registration_number", company_registration_number);
    formData.append("address_proof", address_proof);
    formData.append("country", signupData?.country);
    formData.append("state", signupData?.state);
    formData.append("zipcode", zipCode);
    formData.append("city", signupData?.city);

    signUp(formData)
      .then((response) => {
        let userData = response?.data?.data;
        setUserUUID(userData?.id);
        setUserCompanyId(userData?.company_id);
        setSubscriptionModal(true);
        LoadList();
        setSignUpModal(false);
      })
      .catch((error) => {
        toast.error(Utils.getErrorMessage(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal isOpen={signUpModal} className="modal-lg">
      <ModalBody>
        <button type="button" className="close" onClick={onSignUpModalClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="form">
          <div className="heading mt-0">Create An Account</div>
          <form className="mt-3" method={`post`} onSubmit={onSignUp}>
            <div className="row">
              <div className="col-md-4">
                <div className="padding-bottom--24">
                  <label
                    htmlFor="name"
                    className="col-form-label ml-2 Label-my form-label form-label form-label"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="form-control input-my input-search"
                    placeholder="Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  {signUpErrors.name && (
                    <span className="input-error">{signUpErrors.name}</span>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="padding-bottom--24">
                  <label
                    htmlFor="email"
                    className="col-form-label ml-2 Label-my form-label form-label form-label"
                  >
                    E-Mail
                  </label>
                  <input
                    type="text"
                    id="email"
                    className="form-control input-my input-search"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your mail"
                  />
                  {signUpErrors.email && (
                    <span className="input-error">{signUpErrors.email}</span>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="padding-bottom--24">
                  <label
                    htmlFor="password"
                    className="label-my col-form-label ml-2 Label-my form-label form-label form-label"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    className="form-control input-my input-search"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter you password"
                  />
                  {signUpErrors.password && (
                    <span className="input-error">{signUpErrors.password}</span>
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="padding-bottom--24">
                  <label
                    htmlFor="country"
                    className="label-my col-form-label ml-2 Label-my form-label form-label form-label"
                  >
                    {"Select Country"}
                  </label>

                  <Select
                    className="my-select-v"
                    options={countryList}
                    value={countryList.find(
                      (option) => option.value === Number(signupData.country)
                    )}
                    onChange={(selectedOption) =>
                      setSignUpData({
                        ...signupData,
                        country: selectedOption.value,
                      })
                    }
                  />

                  {signUpErrors.country && (
                    <span className="input-error">{signUpErrors.country}</span>
                  )}
                </div>
              </div>

              <div className="col-md-4">
                <div className="padding-bottom--24">
                  <label
                    htmlFor="state"
                    className="col-form-label ml-2 Label-my form-label form-label form-label"
                  >
                    {"Select State"}
                  </label>

                  <Select
                    className="my-select-v"
                    options={stateList}
                    value={stateList.find(
                      (option) => option.value === Number(signupData.state)
                    )}
                    onChange={(selectedOption) =>
                      setSignUpData({
                        ...signupData,
                        state: selectedOption.value,
                      })
                    }
                  />

                  {signUpErrors.state && (
                    <span className="input-error">{signUpErrors.state}</span>
                  )}
                </div>
              </div>
              <div className="col-md-4">
                <div className="padding-bottom--24">
                  <label
                    htmlFor="city"
                    className="col-form-label ml-2 Label-my form-label form-label form-label"
                  >
                    {"Select City"}
                  </label>
                  <Select
                    className="my-select-v"
                    options={cityList}
                    value={cityList.find(
                      (option) => option.value === Number(signupData.city)
                    )}
                    onChange={(selectedOption) => {
                      setSignUpData({
                        ...signupData,
                        city: selectedOption.value,
                      });
                    }}
                  />

                  {signUpErrors.city && (
                    <span className="input-error">{signUpErrors.city}</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="padding-bottom--24">
                  <label
                    htmlFor="zipCode"
                    className="col-form-label ml-2 Label-my form-label form-label form-label"
                  >
                    ZipCode
                  </label>
                  <input
                    type="text"
                    id="zipCode"
                    className="form-control input-my input-search"
                    placeholder="Enter Zipcode"
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                  />
                  {signUpErrors.zipCode && (
                    <span className="input-error">{signUpErrors.zipCode}</span>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="padding-bottom--24">
                  <label
                    htmlFor="company_registration_number"
                    className="label-my col-form-label ml-2 Label-my form-label form-label form-label"
                  >
                    Company Reg.Number
                  </label>
                  <input
                    type="text"
                    id="company_registration_number"
                    className="form-control input-my input-search"
                    placeholder="Enter your company registration number"
                    value={company_registration_number}
                    onChange={(e) =>
                      setCompanyRegistrationNumber(e.target.value)
                    }
                  />
                  {signUpErrors.company_registration_number && (
                    <span className="input-error">
                      {signUpErrors.company_registration_number}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="padding-bottom--24">
                  <label
                    htmlFor="address_proof"
                    className="label-my col-form-label ml-2 Label-my form-label form-label form-label"
                  >
                    Address Proof
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    name="address_proof"
                    id="address_proof"
                    className="form-control input-my input-search"
                    onChange={handleFileChange}
                  />
                  {signUpErrors.address_proof && (
                    <span className="input-error">
                      {signUpErrors.address_proof}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="padding-bottom--24">
                  <label
                    htmlFor="address"
                    className="label-my col-form-label ml-2 Label-my form-label form-label form-label"
                  >
                    Address
                  </label>
                  <textarea
                    name="address"
                    className="form-control input-my input-search"
                    id="address"
                    cols="30"
                    rows="2"
                    placeholder="Enter your address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  ></textarea>
                  {signUpErrors.address && (
                    <span className="input-error">{signUpErrors.address}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-4">
              <button className="login-btn" type="submit" disabled={isLoading}>
                <span className="text">
                  {isLoading ? "Logging in..." : "Submit"}
                </span>
                <span className="icon">
                  <i className="bi bi-arrow-right-short"></i>
                </span>
              </button>
            </div>
            <hr className="my-3"></hr>
          </form>
          <div className="d-flex justify-content-center">
            <p>
              {" "}
              Have an account ?{" "}
              <Link
                to={"#"}
                onClick={(e) => {
                  onLoginModal(e);
                }}
              >
                {" "}
                Login{" "}
              </Link>
            </p>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default Signup;
