import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useNavigate } from "react-router-dom";
import {
  deleteCertificate,
  geCertificateList,
} from "../../services/CertificateServices";
import moment from "moment";
import { useTranslation } from "react-i18next";

const Certificate = () => {
  const { t } = useTranslation();

  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);
  const navigate = useNavigate();

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);

  const [certificateList, setCertificateList] = useState([]);
  const [delModal, setDelModal] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [delpart, setDelPart] = useState("");

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const fetchDataPark = () => {
    let data = { ...params };
    geCertificateList(data)
      .then((response) => {
        setCertificateList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };

      fetchDataPark(data);
    },
    [params]
  );

  const onDeleteModalClose = () => {
    setDelModal(false);
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelPart(item.uuid);
    setDelModal(true);
  };

  const onDestroyPartType = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delpart };
    deleteCertificate(data)
      .then((response) => {
        let data = { ...params };
        fetchDataPark(data);
        toast.success(response.data.message);
        setDelPart("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {/* <h1 className="page-title">Part Side</h1> */}
              </div>
              <div className="col-sm-6"></div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>

        <section className="content">
          <div className="container-fluid container-custom-height-new">
            <div className="row">
              <div className="col-md-12">
                <div className="card fuelCard">
                  <div className="">
                    <div className="">
                      <div className="card-header">
                        <div
                          className="card-title m-0 float-left"
                          style={{ width: "50%" }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={params.search}
                            onChange={(e) => handleParams(e, "search")}
                            placeholder="Search"
                          ></input>
                        </div>
                        <div className="card-title m-0 float-right d-flex"></div>
                      </div>
                      <div className="card-body">
                        <div className="vehicleThBg table-responsive table-responsive-vehicle">
                          <table className="table table-bordered rounded-table table table-striped table-hover">
                            <thead>
                              <tr>
                                <th style={{ width: "1%" }}>#</th>
                                <th className="col-form-label ml-2 Label-my form-label form-label">
                                  {t("name")}
                                </th>

                                <th className="col-form-label ml-2 Label-my form-label form-label">
                                  {t("vat_number")}
                                </th>

                                <th className="col-form-label ml-2 Label-my form-label form-label">
                                  {t("certificate_date")}
                                </th>

                                <th className="col-form-label ml-2 Label-my form-label form-label">
                                  {t("registration_plate")}
                                </th>
                                <th
                                  className="col-form-label ml-2 Label-my form-label form-label"
                                  style={{ width: "0%" }}
                                >
                                  {" "}
                                  {t("action")}{" "}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {" "}
                              {certificateList?.map((item, index) => {
                                console.log(
                                  "item",
                                  item?.certificate_sync === 1
                                );
                                return (
                                  <>
                                    <tr>
                                      <td
                                        style={{
                                          background:
                                            item?.certificate_sync === 1
                                              ? "gray"
                                              : "",
                                        }}
                                      >
                                        {++index}
                                      </td>
                                      <td
                                        style={{
                                          background:
                                            item?.certificate_sync === 1
                                              ? "gray"
                                              : "",
                                        }}
                                      >
                                        {item.name}
                                      </td>
                                      <td
                                        style={{
                                          background:
                                            item?.certificate_sync === 1
                                              ? "gray"
                                              : "",
                                        }}
                                      >
                                        {item.vat_Number}
                                      </td>
                                      <td
                                        style={{
                                          background:
                                            item?.certificate_sync === 1
                                              ? "gray"
                                              : "",
                                        }}
                                      >
                                        {item.certificate_date === null
                                          ? "-"
                                          : moment(
                                              item.certificate_date
                                            ).format("DD/MM/YYYY")}
                                      </td>
                                      <td
                                        style={{
                                          background:
                                            item?.certificate_sync === 1
                                              ? "gray"
                                              : "",
                                        }}
                                      >
                                        {item.registration_plate}
                                      </td>
                                      <td
                                        className="d-flex"
                                        style={{
                                          background:
                                            item?.certificate_sync === 1
                                              ? "gray"
                                              : "",
                                        }}
                                      >
                                        {" "}
                                        <a
                                          className="edit-btn"
                                          href="#"
                                          onClick={() => {
                                            localStorage.removeItem(
                                              "ownerList"
                                            );
                                            localStorage.removeItem(
                                              "vehicleId"
                                            );

                                            if (item?.certificate_sync === 1) {
                                              navigate(
                                                `/vehicle/${item.Vehicle_id}`,
                                                {
                                                  state: item?.Vehicle_id,
                                                }
                                              );
                                            } else {
                                              navigate("/vehicle", {
                                                state: item,
                                              });
                                            }
                                          }}
                                        >
                                          {" "}
                                          <i class="bi bi-car-front-fill"></i>
                                        </a>
                                        <div className="trash-btn">
                                          <a
                                            className="delete-btn"
                                            href="#"
                                            onClick={(e) =>
                                              onDeleteModalOpen(e, item)
                                            }
                                          >
                                            {" "}
                                            <i className="bi bi-trash3"></i>
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                );
                              })}{" "}
                            </tbody>{" "}
                            {certificateList &&
                              certificateList?.length === 0 && (
                                <tr>
                                  <td colSpan={7} className={`text-center`}>
                                    {" "}
                                    {t("no_record_found")}
                                  </td>
                                </tr>
                              )}
                          </table>
                        </div>
                      </div>{" "}
                      {/* /.card-body */}{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fuelCF">
            <div className="card-footer card-footer-part clearfix">
              <div className="pagination pagination-sm m-0 float-right">
                <Pagination
                  activePage={params.page}
                  itemsCountPerPage={parseInt(params.limit)}
                  totalItemsCount={parseInt(totalRecords)}
                  pageRangeDisplayed={5}
                  itemClass={`page-item`}
                  linkClass={`page-link`}
                  onChange={(e) => handleParams(e, "pagination")}
                />
              </div>
            </div>
          </div>
        </section>

        <Modal isOpen={delModal}>
          <ModalHeader>{t("delete_certificate")}</ModalHeader>
          <ModalBody>
            <p className={`mb-0`}>
              {t("Are you sure you want to delete this certificate?")}
            </p>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-primary"
              disabled={isDelButtonLoad}
              onClick={onDeleteModalClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              {t("close")}
            </button>

            {!isDelButtonLoad && (
              <button
                type="button"
                className="btn btn-block bg-red"
                style={{ width: "100px" }}
                onClick={onDestroyPartType}
              >
                {t("delete")}
              </button>
            )}
            {isDelButtonLoad && (
              <button
                color="secondary"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
              </button>
            )}
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default Certificate;
