import React, { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { toast } from "react-toastify";
import Utils from "../../utils";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import {
  actionDelDismental,
  getDismentalList,
} from "../../services/DismentalServices";
import { useTranslation } from "react-i18next";

const Dismantle = () => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const navigate = useNavigate();
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const { t } = useTranslation();

  const companyId = JSON.parse(data);

  const [totalRecords, setTotalRecords] = useState(0);
  const [partList, setPartList] = useState([]);

  const [delModal, setDelModal] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [delpart, setDelPart] = useState("");

  let errorsObj = {
    ler_code: "",
    item_weight: "",
    item_weight_unit: "",
  };

  const [percentageModal, setPercentageModal] = useState(false);

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
    ...(percentageModal === true ? { pages: 1 } : { page: 1 }),
  });

  const [vehicleVariantsAuto, setVehicleVariantsAuto] = useState("");

  const [partETAIVariant, setPartETAIVariant] = useState("");

  useEffect(() => {
    if (partETAIVariant) {
      setVehicleVariantsAuto(partETAIVariant?.etai_part_name);
    }
  }, [partETAIVariant]);

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    } else if (type === "paginations") {
      paramsObj.pages = e;
    }
    setParams(paramsObj);
  };

  const fetchPartCatLog = () => {
    let data = { ...params };
    getDismentalList(data)
      .then((response) => {
        setPartList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };
      fetchPartCatLog(data);
    },
    [params]
  );

  const onDeleteModalClose = () => {
    setDelModal(false);
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelPart(item.id);
    setDelModal(true);
  };

  const onDestroyPartType = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { id: delpart };
    actionDelDismental(data)
      .then((response) => {
        let datas = { ...params };
        fetchPartCatLog(datas);
        toast.success(response.data.message);
        setDelPart("");
        setDelModal(false);
        setIsDelButtonLoad(false);
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  return (
    <div>
      <>
        <div>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6"></div>
                <div className="col-sm-6"></div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid container-custom-height-new">
              <div className="row">
                <div className="col-md-12">
                  <div className="card fuelCard">
                    <div className="card-header">
                      <div
                        className="card-title m-0 float-left"
                        style={{ width: "50%" }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          value={params.search}
                          onChange={(e) => handleParams(e, "search")}
                          placeholder="Search"
                        />
                      </div>
                      <div className="card-title m-0 float-right d-flex">
                        <button
                          type="button"
                          className="btn btn-icon-text mr-2"
                          onClick={() => navigate("/add-dismental")}
                        >
                          <i className="bi bi-plus-lg"></i>
                          <span className="ms-2">{t("Add")}</span>
                        </button>
                        {/* )} */}
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="vehicleThBg table-responsive table-responsive-vehicle">
                        <table className="table table-bordered rounded-table table table-striped table-hover">
                          <thead>
                            <tr>
                              <th style={{ width: "0%" }}>#</th>
                              <th style={{ width: "10%" }}> {t("name")}</th>

                              <th style={{ width: "0%" }}>{t("action")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {partList?.map((item, index) => (
                              <tr key={item.id}>
                                <td>{++index}</td>

                                <td>{item.name}</td>

                                <td>
                                  <div className="d-flex">
                                    <Dropdown
                                      className="iconDropdown"
                                      onToggle={(isOpen) => {
                                        if (isOpen) {
                                          // This logic runs when the dropdown is opened
                                          const tableResponsive =
                                            document.querySelector(
                                              ".table-responsive"
                                            );
                                          if (tableResponsive) {
                                            tableResponsive.style.overflowX =
                                              "inherit !important";
                                            tableResponsive.style.overflowY =
                                              "inherit !important";
                                          }
                                        }
                                      }}
                                    >
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                        className="iconToggle"
                                      >
                                        <i className="bi bi-three-dots-vertical"></i>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          href="#/action-1"
                                          className="d-flex"
                                          onClick={() =>
                                            navigate(
                                              `/add-dismental/${item.id}`
                                            )
                                          }
                                        >
                                          <div className="edit-btn">
                                            <i className="bi bi-pencil"></i>
                                          </div>
                                          <span>{t("edit")}</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#/action-2"
                                          className="d-flex"
                                          onClick={(e) =>
                                            onDeleteModalOpen(e, item)
                                          }
                                        >
                                          <div className="">
                                            <a className="" href="#"></a>
                                          </div>
                                          <div className="trash-btn delete-btn">
                                            <i className="bi bi-trash3"></i>
                                          </div>
                                          <span>{t("delete")}</span>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </td>
                              </tr>
                            ))}
                            {partList && partList.length === 0 && (
                              <tr>
                                <td colSpan={5} className="text-center">
                                  {t("no_record_found")}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer clearfix">
              <div className="pagination pagination-sm m-0 float-right card-footer-part">
                <Pagination
                  activePage={params.page}
                  itemsCountPerPage={parseInt(params.limit)}
                  totalItemsCount={parseInt(totalRecords)} // Total available records
                  pageRangeDisplayed={5} // Number of page numbers to show
                  itemClass={`page-item`}
                  linkClass={`page-link`}
                  onChange={(e) => handleParams(e, "pagination")}
                />
              </div>
            </div>
          </section>
        </div>
        {/* )} */}

        <Modal isOpen={delModal}>
          <ModalHeader>{t("delete_dismetal")}</ModalHeader>
          <ModalBody>
            <p className={`mb-0`}>
              {t("are_you_sure_you_want_to_delete_this_dismetal")}
            </p>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-block btn-primary"
              disabled={isDelButtonLoad}
              onClick={onDeleteModalClose}
              style={{ width: "100px", marginTop: "10px" }}
            >
              {t("close")} <i className="bi bi-x-lg"></i>
            </button>

            {!isDelButtonLoad && (
              <button
                type="button"
                className="btn btn-block bg-red"
                style={{ width: "100px" }}
                onClick={onDestroyPartType}
              >
                {t("delete")} <i className="bi bi-trash3"></i>
              </button>
            )}
            {isDelButtonLoad && (
              <button
                color="secondary"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
              </button>
            )}
          </ModalFooter>
        </Modal>
      </>
    </div>
  );
};

export default Dismantle;
