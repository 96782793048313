import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import {
  actionTermsConditionAddUpdate,
  destroyTermsConditionList,
  getTermsConditionList,
} from "../../services/TermsConditionService";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";

const TermsConditionAdmin = () => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);
  const { t } = useTranslation();

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
  });

  const [totalRecords, setTotalRecords] = useState(0);
  const [TCDataList, seTCDataList] = useState([]);
  const [delModal, setDelModal] = useState(false);
  const [isDelButtonLoad, setIsDelButtonLoad] = useState(false);
  const [delpart, setDelPart] = useState("");
  const [modifyModal, setModifyModal] = useState(false);
  const [isModifyButtonLoad, setIsModifyButtonLoad] = useState(false);
  const [TCData, setTCData] = useState({
    uuid: "",
    title: "",
    description: "",
    sequence: "",
  });

  const [editorData, setEditorData] = useState("");

  const handleChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };

  let errorsObj = {
    title: "",
    description: "",
    sequence: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  const handleAddModal = () => {
    setModifyModal(true);
  };

  const handleCloseModal = () => {
    setModifyModal(false);
    setTCData("");
    setErrors("");
  };

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const fetchTCList = () => {
    let data = { ...params };
    getTermsConditionList(data)
      .then((response) => {
        seTCDataList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };
      fetchTCList(data);
    },
    [params]
  );

  const onDeleteModalClose = () => {
    setDelModal(false);
  };

  const onEditFAQ = (e, item) => {
    setModifyModal(true);
    setTCData({
      uuid: item?.uuid,
      title: item?.title,
      description: item?.description,
      sequence: item?.sequence,
    });
    setEditorData(item?.description);
  };

  const onDeleteModalOpen = (e, item) => {
    e.preventDefault();
    setDelPart(item.uuid);
    setDelModal(true);
  };

  const onDestroyFAQ = (e) => {
    e.preventDefault();
    setIsDelButtonLoad(true);
    let data = { uuid: delpart };
    destroyTermsConditionList(data)
      .then((response) => {
        let data = { ...params };

        fetchTCList(data);
        toast.success(response.data.message);
        setDelPart("");
        setDelModal(false);
        setIsDelButtonLoad(false);
        setTCData({
          uuid: "",
          title: "",
          description: "",
          sequence: "",
        });
        setEditorData("");
      })
      .catch((err) => {
        setIsDelButtonLoad(false);
        toast.error(Utils.getErrorMessage(err));
      });
  };

  const handlePartLocationForm = (e) => {
    e.preventDefault();
    let error = false;
    let errorObj = { ...errorsObj };
    if (!TCData.title) {
      errorObj.title = `Title is required.`;
      error = true;
    }

    // if (!TCData.description) {
    //   errorObj.description = `Description is required.`;
    //   error = true;
    // }

    if (!TCData.sequence) {
      errorObj.sequence = `sequence is required.`;
      error = true;
    }

    setErrors(errorObj);
    Utils.focusError();

    if (error) return;

    setIsModifyButtonLoad(true);
    let data = {
      description: editorData,
      sequence: TCData.sequence,
      title: TCData.title,
    };

    let Updatedata = {
      uuid: TCData?.uuid,
      description: editorData,
      sequence: TCData.sequence,
      title: TCData.title,
    };

    if (TCData?.uuid) {
      actionTermsConditionAddUpdate(Updatedata)
        .then((response) => {
          toast.success(response.data.message);
          let data = { ...params };
          fetchTCList(data);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
          setTCData({
            uuid: "",
            title: "",
            description: "",
            sequence: "",
          });
          setEditorData("");
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    } else {
      actionTermsConditionAddUpdate(data)
        .then((response) => {
          toast.success(response.data.message);
          let data = { ...params };
          fetchTCList(data);
          setModifyModal(false);
          setIsModifyButtonLoad(false);
          setTCData({
            uuid: "",
            title: "",
            description: "",
            sequence: "",
          });
          setEditorData("");
        })
        .catch((err) => {
          toast.error(Utils.getErrorMessage(err));
          setIsModifyButtonLoad(false);
        });
    }
  };
  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6"></div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right"></ol>
              </div>
            </div>
          </div>
        </section>
        <section className="content">
          <div className="container-fluid container-custom-height-new">
            <div className="row">
              <div className="col-md-12">
                <div className="card fuelCard">
                  <div className="card-header">
                    <div
                      className="card-title m-0 float-left"
                      style={{ width: "50%" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        value={params.search}
                        onChange={(e) => handleParams(e, "search")}
                        placeholder="Search"
                      ></input>
                    </div>
                    <div className="card-title m-0 float-right d-flex">
                      <button
                        type="button"
                        className="btn  btn-icon-text mr-2"
                        onClick={handleAddModal}
                      >
                        <i className="bi bi-plus-lg"></i>
                        <span className="ms-2">{t("Add")}</span>
                      </button>
                    </div>
                  </div>
                  <div className="card-body fuelCardB">
                    <div className="vehicleThBg table-responsive">
                      <table className="table table-bordered rounded-table table table-striped table-hover">
                        <thead>
                          <tr>
                            <th style={{ width: "10%" }}>#</th>
                            <th
                              className="col-form-label ml-2 Label-my form-label form-label"
                              style={{ width: "10%" }}
                            >
                              Sequence
                            </th>
                            <th
                              className="col-form-label ml-2 Label-my form-label form-label"
                              style={{ width: "10%" }}
                            >
                              Question
                            </th>

                            <th
                              className="col-form-label ml-2 Label-my form-label form-label"
                              style={{ width: "10%" }}
                            >
                              {t("action")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {TCDataList?.map((item, index) => {
                            return (
                              <>
                                <tr>
                                  <td>{++index}</td>
                                  <td>{item?.sequence}</td>
                                  <td>{item?.title}</td>

                                  <td className="d-flex">
                                    <Dropdown className="iconDropdown">
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                        className="iconToggle"
                                      >
                                        <i className="bi bi-three-dots-vertical"></i>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          href="#/action-1"
                                          className="d-flex"
                                          onClick={(e) => onEditFAQ(e, item)}
                                        >
                                          <div className="edit-btn">
                                            <i className="bi bi-pencil"></i>
                                          </div>
                                          <span>Edit</span>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          href="#/action-2"
                                          className="d-flex"
                                          onClick={(e) =>
                                            onDeleteModalOpen(e, item)
                                          }
                                        >
                                          <div className="trash-btn delete-btn">
                                            <i className="bi bi-trash3"></i>
                                          </div>
                                          <span>Delete</span>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                        {TCDataList && TCDataList?.length === 0 && (
                          <tr>
                            <td colSpan={7} className={`text-center`}>
                              {" "}
                              {t("no_record_found")}
                            </td>
                          </tr>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fuelCF">
            <div className="card-footer clearfix card-footer-part">
              <div className="pagination pagination-sm m-0 float-right">
                <Pagination
                  activePage={params.page}
                  itemsCountPerPage={parseInt(params.limit)}
                  totalItemsCount={parseInt(totalRecords)}
                  pageRangeDisplayed={5}
                  itemClass={`page-item`}
                  linkClass={`page-link`}
                  onChange={(e) => handleParams(e, "pagination")}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal isOpen={delModal}>
        <ModalHeader>{`Delete FAQ`}</ModalHeader>
        <ModalBody>
          <p
            className={`mb-0`}
          >{`Are you sure you want to delete this FAQ?`}</p>
        </ModalBody>
        <ModalFooter>
          <button
            type="button"
            class="btn btn-block btn-primary"
            disabled={isDelButtonLoad}
            onClick={onDeleteModalClose}
            style={{ width: "100px", marginTop: "10px" }}
          >
            Close <i class="bi bi-x-lg"></i>
          </button>

          {!isDelButtonLoad && (
            <button
              type="button"
              className="btn btn-block bg-red"
              style={{ width: "100px" }}
              onClick={onDestroyFAQ}
            >
              Delete <i class="bi bi-trash3"></i>
            </button>
          )}
          {isDelButtonLoad && (
            <button
              color="secondary"
              type={`button`}
              disabled={true}
              className={`btn-square`}
            >
              <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
            </button>
          )}
        </ModalFooter>
      </Modal>

      <Modal isOpen={modifyModal}>
        <ModalHeader>Terms & Condition</ModalHeader>
        <Form method={`post`} onSubmit={handlePartLocationForm}>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label Label-my ml-2">
                    {"Title"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="text"
                        value={TCData.title}
                        onChange={(e) => {
                          setTCData({
                            ...TCData,
                            title: e.target.value,
                          });
                        }}
                        name={`title`}
                        placeholder="Title"
                      />
                    </div>
                    {errors.title && (
                      <span className="input-error">{errors.title}</span>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Answer"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      {/* <textarea
                        className="form-control input-search"
                        type="text"
                        value={TCData.description}
                        onChange={(e) => {
                          setTCData({
                            ...TCData,
                            description: e.target.value,
                          });
                        }}
                        name={`description`}
                        placeholder="Answer"
                        rows={4}
                      /> */}
                      <CKEditor
                        editor={ClassicEditor}
                        data={editorData}
                        onChange={handleChange}
                      />
                    </div>
                    {/* {errors.description && (
                      <span className="input-error">{errors.description}</span>
                    )} */}
                  </div>
                </FormGroup>
              </div>

              <div className="col-md-12">
                <FormGroup>
                  <Label className="col-form-label ml-2 Label-my">
                    {"Sequence"}
                  </Label>
                  <div className="col-xl-12">
                    <div className="input-div">
                      <input
                        className="form-control input-search"
                        type="number"
                        value={TCData.sequence}
                        onChange={(e) => {
                          setTCData({
                            ...TCData,
                            sequence: e.target.value,
                          });
                        }}
                        name={`sequence`}
                        maxLength={10}
                        placeholder="Sequence"
                      />
                    </div>
                    {errors.sequence && (
                      <span className="input-error">{errors.sequence}</span>
                    )}
                  </div>
                </FormGroup>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              class="btn btn-block btn-primary"
              disabled={isModifyButtonLoad}
              onClick={handleCloseModal}
              style={{ width: "100px", marginTop: "10px" }}
            >
              Close <i class="bi bi-x-lg"></i>
            </button>
            {!isModifyButtonLoad && (
              <button
                type="submit"
                class="btn btn-block btn-primary"
                style={{ width: "100px", marginTop: "10px" }}
              >
                Submit <i class="bi bi-check2"></i>
              </button>
            )}
            {isModifyButtonLoad && (
              <Button
                color="success"
                type={`button`}
                disabled={true}
                className={`btn-square`}
              >
                <i className="fa fa-circle-o-notch fa-spin"></i> {`Loading...`}
              </Button>
            )}
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default TermsConditionAdmin;
