import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import ConfigDB from "../../config";
import { toast } from "react-toastify";
import Utils from "../../utils";

import { useTranslation } from "react-i18next";
import { getEcommerceCompanyList } from "../../services/SalesServices";

const EcommarceCompany = () => {
  const tableLengthList = ConfigDB.data.dataTableLength;
  const data = localStorage.getItem(ConfigDB.data.login_data);
  const companyId = JSON.parse(data);
  const { t } = useTranslation();

  const [params, setParams] = useState({
    limit: tableLengthList[0],
    page: 1,
    search: "",
    is_reload: false,
    company_id: companyId.company_id || 0,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [ecommerceCompanyList, setEcommerceCompanyList] = useState([]);

  useEffect(() => {}, []);

  const handleParams = (e, type) => {
    let paramsObj = { ...params };
    if (type === "length") {
      paramsObj.limit = e.target.value;
    } else if (type === "search") {
      paramsObj.page = 1;
      paramsObj.search = e.target.value;
    } else if (type === "pagination") {
      paramsObj.page = e;
    }
    setParams(paramsObj);
  };

  const fetchPartLocation = () => {
    let data = { ...params };
    getEcommerceCompanyList(data)
      .then((response) => {
        setEcommerceCompanyList(response.data.data);
        setTotalRecords(response.data.total);
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(Utils.getErrorMessage(err));
      });
  };

  useEffect(
    function () {
      let data = { ...params };

      fetchPartLocation(data);
    },
    [params]
  );

  return (
    <div>
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {/* <h1 className="page-title">Part Type</h1> */}
              </div>
              <div className="col-sm-6"></div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        <section className="content">
          <div className="container-fluid container-custom-height-new ">
            <div className="row">
              <div className="col-md-12">
                <div className="card fuelCard">
                  <div className="card-header">
                    <div
                      className="card-title m-0 float-left"
                      style={{ width: "50%" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        value={params.search}
                        onChange={(e) => handleParams(e, "search")}
                        placeholder="Search"
                      ></input>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="vehicleThBg table-responsive table-responsive-vehicle">
                      <table className="table table-bordered rounded-table table-striped table-hover">
                        <thead>
                          <tr>
                            <th style={{ width: "1%" }}>#</th>
                            <th
                              className="col-form-label ml-2 Label-my form-label form-label"
                              style={{ width: "10%" }}
                            >
                              {t("name")}
                            </th>
                            <th
                              className="col-form-label ml-2 Label-my form-label form-label"
                              style={{ width: "10%" }}
                            >
                              {t("email")}
                            </th>{" "}
                            <th
                              className="col-form-label ml-2 Label-my form-label form-label"
                              style={{ width: "10%" }}
                            >
                              {t("Company Registration Number")}
                            </th>{" "}
                          </tr>
                        </thead>
                        <tbody>
                          {ecommerceCompanyList?.map((item, index) => (
                            <tr key={item.id}>
                              {" "}
                              <td>{++index}</td>
                              <td>{item?.name}</td>
                              <td>{item?.email}</td>
                              <td>{item?.company_registration_number}</td>
                            </tr>
                          ))}
                          {ecommerceCompanyList &&
                            ecommerceCompanyList.length === 0 && (
                              <tr>
                                <td colSpan={4} className="text-center">
                                  {t("no_record_found")}
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer card-footer-part clearfix">
            <div className="pagination pagination-sm m-0 float-right">
              <Pagination
                activePage={params.page}
                itemsCountPerPage={parseInt(params.limit)}
                totalItemsCount={parseInt(totalRecords)}
                pageRangeDisplayed={5}
                itemClass={`page-item`}
                linkClass={`page-link`}
                onChange={(e) => handleParams(e, "pagination")}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default EcommarceCompany;
